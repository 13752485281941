import { parseJson } from 'core/common/jsonUtils';
import { addBit, removeBit } from 'core/engine/common/utils';
import { ContentAvatarLabel, ContentAvatarSize, ContentAvatarType } from 'core/engine/content-avatar/constants';
import {
  ContentAttribute,
  ContentAttributeOptions,
  ContentDisplayType,
  ContentDisplayTypeLabel,
  ContentDisplayTypeOptions,
  ContentType,
  ContentTypeLabel,
  ContentTypeOptions,
} from 'core/engine/content/constants';
import ContentFormItemAttributeTag from 'core/modules/contents/components/form/attribute/ContentFormItemAttributeTag';
import ContentFormItemDistributionGroup from 'core/modules/contents/components/form/distribution-group/ContentFormItemDistributionGroup';
import ContentFormItemRedirectLink from 'core/modules/contents/components/form/redirect-link/ContentFormItemRedirectLink';
import ContentFormItemSeoEntry from './components/form/ContentFormItemSeoEntry';
import { ContentFieldLabel, ContentHookAction } from 'core/modules/contents/constants';
import actionRegistry from 'core/registries/actions';
import React from 'react';
import ContentFormItemAuthor from './components/form/author/ContentFormItemAuthor';
import ContentFormItemAvatar from './components/form/ContentFormItemAvatar';
import ContentFormItemDistributionDate from './components/form/ContentFormItemDistributionDate';
import ContentFormItemKeywords from './components/form/ContentFormItemKeywords';
import ContentFormItemRelatedTopic from './components/form/ContentFormItemRelatedTopic';
import ContentFormItemVerticalAvatar from './components/form/ContentFormItemVerticalAvatar';
import ContentFormItemVideoUrl from './components/form/video-url/ContentFormItemVideoUrl';

export function start() {
  actionRegistry.addAction(ContentHookAction.BEFORE_GET_CONTENT, beforeGetContentAction);
  actionRegistry.addAction(ContentHookAction.FORM_INPUTS, formInputsAction);
  actionRegistry.addAction(ContentHookAction.FORMDATA_TO_CONTENT, formDataToContentAction);

  ContentAttribute.RAW_IMAGE = 131072;

  ContentAttributeOptions.push(
    { label: 'Có ảnh', value: ContentAttribute.HAS_PHOTO },
    { label: 'Có video', value: ContentAttribute.HAS_VIDEO },
    { label: 'Có audio', value: ContentAttribute.HAS_AUDIO },
    { label: 'Tin hot', value: ContentAttribute.IS_HIGHLIGHT },
    { label: 'Tin trực tiếp', value: ContentAttribute.LIVE },
    { label: 'Tin cập nhật', value: ContentAttribute.UPDATING },
    { label: 'Tắt bình luận', value: ContentAttribute.NO_COMMENT },
    { label: 'Tắt quảng cáo', value: ContentAttribute.NO_ADS },
    { label: 'Tắt ảnh đại diện', value: ContentAttribute.NO_AVATAR },
    { label: 'Ẩn tiêu đề (Bài Longform)', value: ContentAttribute.NO_TITLE },
    { label: 'Ẩn TTS', value: ContentAttribute.NO_AUDIO },
    { label: 'Dùng ảnh gốc', value: ContentAttribute.RAW_IMAGE, cmd: 'content.raw_image' },
  );

  ContentTypeOptions.push(
    ...[
      ContentType.OTHERS,
      ContentType.SAN_XUAT,
      ContentType.TIN_DICH,
      ContentType.TIN_TONG_HOP,
      ContentType.BAO_IN,
      ContentType.QUANG_CAO,
      ContentType.DANG_LAI,
      ContentType.TIN_HO_TRO,
    ].map(type => ({
      value: type,
      label: ContentTypeLabel[type],
    }))
  );

  ContentDisplayTypeOptions.push(
    ...[
      ContentDisplayType.NORMAL,
      ContentDisplayType.INFOGRAPHIC,
      ContentDisplayType.LONGFORM,
    ].map(type => ({
      value: type,
      label: ContentDisplayTypeLabel[type],
    }))
  );

  ContentFieldLabel.verticalAvatar = 'Ảnh đại diện dọc';

  initContentAvatarType();
}

function initContentAvatarType() {
  ContentAvatarType.RATIO_1x1 = 1;
  ContentAvatarType.RATIO_3x2 = 2;
  ContentAvatarType.RATIO_4x3 = 3;
  ContentAvatarType.RATIO_5x3 = 4;
  ContentAvatarType.RATIO_16x9 = 5;
  ContentAvatarType.RATIO_FB = 20;

  ContentAvatarLabel[ContentAvatarType.RATIO_1x1] = 'Tỉ lệ 1x1';
  ContentAvatarLabel[ContentAvatarType.RATIO_3x2] = 'Tỉ lệ 3x2';
  ContentAvatarLabel[ContentAvatarType.RATIO_4x3] = 'Tỉ lệ 4x3';
  ContentAvatarLabel[ContentAvatarType.RATIO_5x3] = 'Tỉ lệ 5x3';
  ContentAvatarLabel[ContentAvatarType.RATIO_16x9] = 'Tỉ lệ 16x9';
  ContentAvatarLabel[ContentAvatarType.RATIO_FB] = 'Tỉ lệ FB';

  ContentAvatarSize[ContentAvatarType.RATIO_1x1] = { width: 600, height: 600 };
  ContentAvatarSize[ContentAvatarType.RATIO_3x2] = { width: 600, height: 400 };
  ContentAvatarSize[ContentAvatarType.RATIO_4x3] = { width: 600, height: 450 };
  ContentAvatarSize[ContentAvatarType.RATIO_5x3] = { width: 600, height: 360 };
  ContentAvatarSize[ContentAvatarType.RATIO_16x9] = { width: 600, height: 338 };
  ContentAvatarSize[ContentAvatarType.RATIO_FB] = { width: 600, height: 315 };
}

function beforeGetContentAction({ getFields }) {
  getFields.push('RedirectLink', 'DistributionTopics', 'VideoUrl');
}

function formInputsAction({ content, form, inputMap }) {
  inputMap.Attributes = (
    <ContentFormItemAttributeTag content={content} form={form} />
  );

  inputMap.SeoEntry = (
    <ContentFormItemSeoEntry content={content} form={form} />
  );

  inputMap.RedirectLink = (
    <ContentFormItemRedirectLink content={content} form={form} />
  );

  inputMap.avatar = (
    <ContentFormItemAvatar content={content} form={form} />
  );

  inputMap.verticalAvatar = (
    <ContentFormItemVerticalAvatar content={content} form={form} />
  );

  inputMap.distributionGroup = (
    <ContentFormItemDistributionGroup content={content} form={form} />
  );

  inputMap.Author = (
    <ContentFormItemAuthor content={content} form={form} />
  );

  inputMap.Keywords = (
    <ContentFormItemKeywords content={content} form={form} />
  );

  inputMap.DistributionDate = (
    <ContentFormItemDistributionDate content={content} form={form} />
  );

  inputMap.VideoUrl = (
    <ContentFormItemVideoUrl content={content} form={form} />
  );

  inputMap.RelatedTopics = (
    <ContentFormItemRelatedTopic content={content} form={form} />
  );
}

function formDataToContentAction({ content, formData }) {
  content.RegionID = 0; // since the database field is not null...

  if (formData.VideoUrl) {
    content.Attributes = addBit(formData.Attributes, ContentAttribute.HAS_AUDIO);
  } else {
    content.Attributes = removeBit(formData.Attributes, ContentAttribute.HAS_AUDIO);
  }

  if (formData.verticalAvatar) {
    content.Meta = parseJson(content.Meta, {});
    content.Meta.VerticalAvatarUrl = formData.verticalAvatar.url;
    content.Meta = JSON.stringify(content.Meta);
    delete content.verticalAvatar;
  }

  delete content.hasVideoUrl;
}
