import { Button, Input, Modal } from 'antd';
import { emptyObject } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import useDialog from 'core/hooks/useDialog';
import useForm from 'core/hooks/useForm';
import React, { Fragment, useCallback } from 'react';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 700,
    marginBottom: 8,
    display: 'block',
  },
  info: {
    marginTop: 10,
  },
}));

export default function ContentFormToolbarReturnButton({
  getFormData,
  action,
  onAction,
  className,
  loading,
  modalProps = emptyObject,
  requireNote = true,
}) {
  const classes = useStyles();

  const { open, handleClose, handleOpen } = useDialog();
  const { formData, setFieldsData, handleChange } = useForm({});

  const handleClick = useCallback(() => {
    getFormData(false).then((data) => {
      if (data.contentNote) {
        setFieldsData({ note: data.contentNote });
      }

      handleOpen();
    });
  }, [getFormData, handleOpen, setFieldsData]);

  const handleOk = useCallback(() => {
    handleClose();
    onAction(action.name, formData);
  }, [formData, handleClose, onAction, action]);

  return (
    <Fragment>
      <Button
        name={action.name}
        title={action.label}
        className={className}
        onClick={handleClick}
        icon={action.icon}
        loading={loading}
      >
        {action.label}
      </Button>
      <Modal
        visible={open}
        title="Trả lại tin này?"
        onCancel={handleClose}
        onOk={handleOk}
        okText="Trả lại"
        okButtonProps={{ disabled: requireNote && !formData.note }}
        destroyOnClose
        keyboard={false}
        maskClosable={false}
        {...modalProps}
      >
        <span className={classes.label}>Ghi chú:</span>
        <Input.TextArea
          placeholder="Nhập lý do trả lại tin"
          name="note"
          value={formData.note}
          onChange={handleChange}
          autoFocus
        />
      </Modal>
    </Fragment>
  );
}
