export const AdsOptionType = {
  Image: 0,
  //Flash: 1,
  Link: 2,
  Script: 3,
};
export const AdsOptionTypeLabel = {
  [AdsOptionType.Image]: 'Hình ảnh',
  [AdsOptionType.Link]: 'Link tài trợ',
  [AdsOptionType.Script]: 'Mã quảng cáo',
};
