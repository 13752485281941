import React from 'react';
import lodash from 'core/common/lodash';
import AccountDisplay from 'core/components/account/AccountDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import TopicZoneDisplay from 'core/components/topic/TopicZoneDisplay';
import DraggableTable from 'core/components/table/DraggableTable';
import { Button } from 'antd';

export default function SelectedRelatedTopicsTab({ value, onChange }) {
  const columns = [
    {
      Header: 'ID',
      accessor: 'TopicID',
      width: 50,
      style: { justifyContent: 'center' },
    },
    {
      Header: 'Tên',
      accessor: 'Name',
      minWidth: 250,
    },
    {
      Header: 'Chuyên mục',
      width: 120,
      Cell: ({ original }) => (<TopicZoneDisplay topic={original} />),
    },
    {
      Header: 'Ngày tạo',
      width: 120,
      Cell: ({ original }) => <DateTimeDisplay timestamp={original.CreatedDate} />,
    },
    {
      Header: 'Người tạo',
      width: 120,
      Cell: ({ original }) => <AccountDisplay id={original.CreatorID} />,
    },
    {
      Cell: ({ original }) => (
        <Button
          size="small"
          title="Bỏ chọn"
          icon="delete"
          style={{ color: 'red' }}
          onClick={() => onUnselect(original)}
        />
      ),
      width: 80,
      style: { justifyContent: 'center' },
    },
  ];

  return (
    <DraggableTable
      idField="TopicID"
      columns={columns}
      data={value}
      pageSize={Math.max(value.length, 23)}
      showPagination={false}
      onMove={onChange}
      showDragIcon="left"
    />
  );

  function onUnselect(item) {
    onChange(lodash.pull(value.slice(), item));
  }
}
