import { sortingToSortBy } from 'core/common/listUtils';
import lodash from 'core/common/lodash';
import AccountDisplay from 'core/components/account/AccountDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import FlexPushRight from 'core/components/common/FlexPushRight';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import SelectableTable from 'core/components/table/SelectableTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import TopicZoneDisplay from 'core/components/topic/TopicZoneDisplay';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import topicApi from 'core/engine/topic/api';
import { TopicType } from 'core/engine/topic/constants';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { Fragment, useEffect } from 'react';

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return topicApi.getList({ limit, offset, filters: listFilters, sortBy: sortingToSortBy(sorting) });
}

const SEARCH_TYPE_OPTIONS = [
  { label: 'Tên', value: 'Name' },
  { label: 'URL', value: 'Url' },
];

const columns = [
  {
    Header: 'ID',
    accessor: 'TopicID',
    width: 50,
    style: { justifyContent: 'center' },
  },
  {
    Header: 'Tên',
    accessor: 'Name',
    minWidth: 250,
  },
  {
    Header: 'Chuyên mục',
    width: 120,
    Cell: ({ original }) => (<TopicZoneDisplay topic={original} />),
  },
  {
    Header: 'Ngày tạo',
    width: 120,
    Cell: ({ original }) => <DateTimeDisplay timestamp={original.CreatedDate} />,
  },
  {
    Header: 'Người tạo',
    width: 120,
    Cell: ({ original }) => <AccountDisplay id={original.CreatorID} />,
  },
];

export default function SelectableRelatedTopicsTab({ value, onChange }) {
  const listProps = useList({
    listFn,
    defaultPageSize: 20,
    defaultFilters: {
      Enabled: true,
      Type: TopicType.NORMAL,
      search: { type: SEARCH_TYPE_OPTIONS[0].value },
    },
    defaultSorting: {
      CreatedDate: 'DESC',
    },
  });

  useEffect(() => {
    setTimeout(listProps.refresh, 300); // after tab transition
  }, []);

  return (
    <Fragment>
      <TableToolbarWrapper>
        <ZoneSelector
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={useCreateOnFilterChange(listProps, 'ZoneID')}
          allowClear
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={SEARCH_TYPE_OPTIONS}
          search={listProps.filters.search}
          onSearch={useCreateOnFilterChange(listProps, 'search')}
        />
      </TableToolbarWrapper>
      <SelectableTable
        {...useListProps(listProps)}
        idField="TopicID"
        columns={columns}
        selected={value}
        onSelect={onSelect}
        onUnselect={onUnselect}
        heightAdjust={42}
      />
    </Fragment>
  );

  function onSelect(item) {
    const newValue = value.slice();
    newValue.push(item);
    onChange(newValue);
  }

  function onUnselect(item) {
    const newValue = value.slice();
    lodash.remove(newValue, item);
    onChange(newValue);
  }
}
