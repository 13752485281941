import { Icon } from 'antd';
import React, { useCallback, useRef, Fragment } from 'react';
import FileManagerDialog from './FileManagerDialog';

function DefaultTriggerComponent({ onClick }) {
  return (
    <Icon type="cloud-upload" onClick={onClick} />
  );
}

export default function FileChooserButton({
  triggerComponent: TriggerComponent = DefaultTriggerComponent,
  ...props
}) {
  const dialogRef = useRef();

  const handleClick = useCallback(() => {
    dialogRef.current.open();
  }, []);

  return (
    <Fragment>
      <TriggerComponent onClick={handleClick} />
      <FileManagerDialog ref={dialogRef} {...props} />
    </Fragment>
  );
}
