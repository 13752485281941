import React, { useEffect, useState } from 'react';
import BaseDialog from './BaseDialog';
import useWebSocket from 'core/hooks/useWebSocket';
import makeStyles from 'core/common/makeStyles';
import { EventType } from 'core/engine/tool/constants';
import useServerConfig from 'core/hooks/useServerConfig';
import { SettingSystemKeys } from 'core/engine/setting/constants';
import useDialog from 'core/hooks/useDialog';
import { APP_VERSION } from 'core/config';

const useStyles = makeStyles(() => ({
  buttonName: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  container: {
    fontSize: 15,
  },
}));

export default function RefreshClientDialog() {
  const classes = useStyles();

  const [countdown, setCountdown] = useState(0);
  const { open, handleClose, handleOpen } = useDialog();

  const { [SettingSystemKeys.ClientVersion]: currentVersion } = useServerConfig();

  useEffect(() => {
    if (!currentVersion) return;

    if (APP_VERSION !== currentVersion) {
      handleDialogOpen(.25);
    }
  }, [currentVersion]);

  useWebSocket({
    topic: EventType.CLIENT_FORCE_REFRESH,
    onMessage: ((data) => {
      if (data.force) {
        handleReloadClient();
      } else {
        handleDialogOpen(data.countdown);
      }
    }),
  });

  return (
    <BaseDialog
      visible={open}
      title="CMS đã có phiên bản mới"
      onCancel={handleClose}
      onOk={handleReloadClient}
      okText="Cập nhật"
      cancelText="Bỏ qua"
      keyboard
      maskClosable
      width={400}
    >
      <div className={classes.container}>
        Đã có phiên bản mới của CMS.
        <br />
        <span className={classes.buttonName}>Cập nhật</span> để tải lại trang.
        <br />
        {countdown ? (
          <span>Tự động tải lại sau <span className={classes.buttonName}>{countdown} giây.</span></span>
        ) : (
          <>
            <span className={classes.buttonName}>Bỏ qua</span> để dùng <b>Ctrl + F5</b> tự cập nhật sau.
          </>
        )}
      </div>
    </BaseDialog>
  );

  function handleReloadClient() {
    window.location.reload();
  }

  function handleDialogOpen(countdownMinutes) {
    if (countdownMinutes) {
      setCountdown(countdownMinutes * 60);
      setInterval(() => setCountdown((seconds) => {
        if (--seconds <= 0) {
          handleReloadClient();
        }

        return seconds;
      }), 1000);
    }

    handleOpen();
  }
}
