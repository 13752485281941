import { Checkbox, Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import Selector from 'core/components/common/Selector';
import { VoteAttributes, VoteAttributesLabel } from 'core/engine/vote/constants';
import React from 'react';

export default function VoteForm({ form, vote }) {
  const { getFieldDecorator } = form;

  getFieldDecorator('VoteID', { initialValue: vote.VoteID });

  return (
    <Form {...commonStyles.formItemLayout}>
      <Form.Item label="Tiêu đề">
        {getFieldDecorator('Title', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 2000, message: 'Tối đa 2000 kí tự!' }],
          initialValue: vote.Title,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Câu hỏi">
        {getFieldDecorator('Description', {
          initialValue: vote.Description,
          rules: [{ max: 2000, message: 'Tối đa 2000 kí tự!' }],
        })(
          <Input.TextArea />
        )}
      </Form.Item>
      <Form.Item label="Hiển thị">
        {getFieldDecorator('Attributes', {
          initialValue: isNullOrUndefined(vote.Attributes) ? VoteAttributes.DETAIL : vote.Attributes,
          rules: [{ required: true, message: 'Bắt buộc!' }],
        })(
          <Selector options={VoteAttributesLabel} valueIsNumber />
        )}
      </Form.Item>
      <Form.Item label="Sử dụng">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: isNullOrUndefined(vote.Enabled) ? true : vote.Enabled,
        })(
          <Checkbox />
        )}
      </Form.Item>

    </Form>
  );
}
