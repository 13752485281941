import { IS_MOBILE } from '@bm/common';
import { Checkbox } from 'antd';
import { commonColumns, sortingToSortBy } from 'core/common/listUtils';
import AccountDisplay from 'core/components/account/AccountDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import BaseTable from 'core/components/table/BaseTable';
import liveStreamLog from 'core/engine/live-stream-log/api';
import { LiveStreamStatusLabel } from 'core/engine/live-stream/constants';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { Fragment } from 'react';
import LiveStreamDisplay from '../LiveStreamDisplay';
import LiveStreamIcon from '../LiveStreamIcon/LiveStreamIcon';
import { DISABLE_HOT_LIVESTREAM_OPTION } from 'core/config';

const columns = [
  !IS_MOBILE && {
    Cell: ({ original }) => <LiveStreamIcon typeName={original.typeName} />,
    width: 80,
  },
  {
    Header: 'Nội dung',
    Cell: ({ original }) => <LiveStreamDisplay liveStream={original} />,
  },
  {
    Header: 'Trạng thái',
    accessor: 'Status',
    Cell: ({ value }) => <span>{LiveStreamStatusLabel[value]}</span>,
    width: 85,
  },
  {
    Header: 'Người sửa',
    headerStyle: { justifyContent: 'center' },
    style: { flexDirection: 'column' },
    Cell: ({ original }) => (
      <Fragment>
        <AccountDisplay id={original.EditorID} />
        <DateTimeDisplay timestamp={original.ModifiedDate} />
      </Fragment>
    ),
    width: 120,
  },
  !IS_MOBILE && {
    Header: 'Thời gian',
    accessor: 'Date',
    Cell: ({ value }) => <DateTimeDisplay timestamp={value} />,
    width: 120,
  },
  !DISABLE_HOT_LIVESTREAM_OPTION && {
    ...commonColumns.allCentered,
    Header: 'Nổi bật',
    accessor: 'IsHot',
    Cell: ({ value }) => <Checkbox checked={value} />,
    width: 50,
  },
].filter(Boolean);

function listFn({ offset, limit, filters, sorting }) {
  return liveStreamLog.getList({
    offset,
    limit,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default function LiveStreamLog({ liveStreamId }) {
  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultPageSize: 10,
    defaultSorting: {
      ModifiedDate: 'DESC',
    },
    defaultFilters: {
      StreamID: liveStreamId,
    },
  });

  return (
    <BaseTable
      columns={columns}
      {...useListProps(listProps)}
    />
  );
}
