import React, { useMemo } from 'react';
import lodash from 'core/common/lodash';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import useContentColumns from 'core/hooks/useContentColumns';
import ContentEditButtonForList from 'core/components/content/ContentEditButtonForList';
import ContentGetBackButtonForList from 'core/components/content/ContentGetBackButtonForList';
import ContentLogButtonForList from 'core/components/content/ContentLogButtonForList';
import ContentPreviewButtonForList from 'core/components/content/ContentPreviewButtonForList';
import BaseTable from 'core/components/table/BaseTable';
import { ContentStatus } from 'core/engine/content/constants';
import useAuth from 'core/hooks/useAuth';

export default function ContentMyList({
  showEditButton = true,
  showEditorColumn = false,
  showDeployerColumn = false,
  showOwnerColumn = false,
  showRoyalty = false,
  showViewCountColumn = false,
  showCTRColumn = false,
  approveZoneIds,
  deployZoneIds,
  ...props
}) {
  const ContentColumns = useContentColumns();
  const { loggedInUser } = useAuth();

  const columns = useMemo(() => {
    return [
      ContentColumns.ContentID,
      ContentColumns.Avatar,
      ContentColumns.Title,
      ContentColumns.Writer,
      showDeployerColumn && ContentColumns.Deployer,
      showOwnerColumn && ContentColumns.Owner,
      showEditorColumn && ContentColumns.Editor,
      ContentColumns.Status,
      showRoyalty && {
        Header: 'Nhuận bút',
        accessor: 'Royalty',
        Cell: ({ value }) => lodash.sumBy(value, 'Money'),
        maxWidth: 100,
      },
      showViewCountColumn && {
        ...ContentColumns.ViewCount,
        show: true,
        sortable: true,
      },
      showCTRColumn && {
        ...ContentColumns.CTR,
        show: true,
      },
      ContentColumns.CheckIn,
      {
        width: 150,
        Cell: ({ original }) => {
          const editable = original.Status === ContentStatus.DRAFT
            || (original.Status === ContentStatus.APPROVED && approveZoneIds.includes(original.ZoneID))
            || (original.Status === ContentStatus.DEPLOYED && deployZoneIds.includes(original.ZoneID))
            || original.Status === ContentStatus.SUBMITTED_ITEM_RETURNED;

          return (
            <ActionsCellWrapper>
              {(original.Status === ContentStatus.SUBMITTED || original.Status === ContentStatus.APPROVED) && (
                <ContentGetBackButtonForList
                  contentId={original.ContentID}
                  disabled={original.EditorID !== loggedInUser.id}
                />
              )}
              {showEditButton && editable && (
                <ContentEditButtonForList content={original} />
              )}
              <ContentLogButtonForList contentId={original.ContentID} />
              <ContentPreviewButtonForList noiseId={original.NoiseID} />
            </ActionsCellWrapper>
          );
        },
      },
    ].filter(Boolean);
  }, [ContentColumns, showEditorColumn, showDeployerColumn, showOwnerColumn, showRoyalty, showViewCountColumn]);

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
