import SettingView from 'core/modules/managements/settings/components/SettingView';
import React from 'react';
import BDSSettingLayoutForm from './bds/BDSSettingLayoutForm';
import BDSSettingWebForm from './bds/BDSSettingWebForm';

const tabs = [
  {
    name: 'Giao diện',
    Form: BDSSettingLayoutForm,
  },
  {
    name: 'Web',
    Form: BDSSettingWebForm,
  },
];

export default function BDSSettingView() {
  return (
    <SettingView settingTabs={tabs} />
  );
}
