import React, { forwardRef } from 'react';
import AdsPositionSelectButton from 'core/components/ads-option/AdsPositionSelectButton';

export default forwardRef(function AdsWidgetQuickInsertDialog({ onInsert, widget }, ref) {
  return (
    <AdsPositionSelectButton ref={ref} onDone={handleOk} multiple />
  );

  function handleOk(adPositions) {
    onInsert(adPositions.map((position) => ({
      Title: '[Quảng cáo] ' + position.Name,
      Type: widget.type,
      PreDefined: false,
      Meta: JSON.stringify({ PositionCode: position.Code }),
    })));
  }
});
