import { AutoComplete } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useAuth from 'core/hooks/useAuth';
import lodash from 'core/common/lodash';

export default function ContentFormItemAuthorFreeAuthorInput({ value, onChange }) {
  const { loggedInUser } = useAuth();
  const [internalValue, setInternalValue] = useState();

  const pseudonyms = useMemo(() => (
    loggedInUser.pseudonym.split(/[,;]/).map(lodash.trim).filter(Boolean)
  ), [loggedInUser]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleBlur = useCallback(() => onChange(internalValue), [internalValue, onChange]);

  return (
    <AutoComplete
      value={internalValue}
      onChange={setInternalValue}
      dataSource={pseudonyms}
      onBlur={handleBlur}
      filterOption={(inputValue, option) => (
        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      )}
      placeholder="Nhập tên tác giả vãng lai"
      maxLength={250}
    />
  );
}
