import { Input, Select } from 'antd';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import classnames from 'core/common/classnames';
import makeStyles from 'core/common/makeStyles';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import { GroupRoleUp } from 'core/engine/group/constants';
import { getListEnabledZoneByAccountCache, getListEnabledZoneCache } from 'core/engine/zone/caching';
import { DEFAULT_CONTENT_MAX_LEVEL } from 'core/config';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex !important',
  },
  zone: {
    width: 'calc(100% - 50px) !important',
  },
  level: {
    width: '55px !important',
  },
  primary: {
    fontWeight: 700,
  },
}));

export default forwardRef(function ContentFormSingleZoneInput({
  value,
  onChange,
  maxLevel = DEFAULT_CONTENT_MAX_LEVEL,
  isPrimary = false,
  showLevel = true,
  roles = GroupRoleUp.WRITER,
}, ref) {
  const classes = useStyles();

  const [zoneMap, setZoneMap] = useState();

  const levelOptions = useMemo(() => {
    return Array(maxLevel).fill('').map((_, index) => ({
      label: index + 1,
      value: index + 1,
    }));
  }, [maxLevel]);

  useEffect(() => {
    (roles.length ? getListEnabledZoneByAccountCache(roles) : getListEnabledZoneCache())
      .then((zones) => {
        const map = zones.reduce((previous, current) => {
          previous[current.ZoneID] = true;
          return previous;
        }, {});

        setZoneMap(map);
      });
  }, [roles]);

  useEffect(() => {
    if (zoneMap && value && value.ZoneID && !zoneMap[value.ZoneID]) {
      onChange({ Order: maxLevel, ZoneID: null, disabled: true });
    }
  }, [zoneMap, value]);

  return (
    <Input.Group ref={ref} compact className={classes.container}>
      <ZoneSelector
        allowClear
        roles={roles}
        className={classnames(showLevel && classes.zone, isPrimary && classes.primary)}
        value={value.ZoneID}
        onChange={handleZoneChange}
        emptyLabel={isPrimary ? '-- Chuyên mục chính --' : '-- Chọn chuyên mục --'}
        disableRootSubSite={isPrimary}
      />
      {showLevel && (
        <Select
          className={classes.level}
          defaultValue={1}
          value={value.Order}
          onChange={handleLevelChange}
        >
          {levelOptions.map(level => (
            <Select.Option key={level.value} value={level.value}>{level.label}</Select.Option>
          ))}
        </Select>
      )}
    </Input.Group>
  );

  function handleZoneChange(zoneId) {
    onChange({ ...value, ZoneID: zoneId, disabled: false });
  }

  function handleLevelChange(level) {
    onChange({ ...value, Order: level, disabled: false });
  }
});
