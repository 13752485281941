import { SERVICE_WEB_DOMAIN } from 'core/config';
import { Sites, SubSiteDomain, SubSiteZone } from 'core/engine/site/constants';

export function start() {
  Sites.BDS = 1;

  SubSiteZone[Sites.BDS] = 227;

  SubSiteDomain[Sites.BDS] = SERVICE_WEB_DOMAIN;

}
