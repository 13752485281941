import React from 'react';
import { ContentStatusLabel } from 'core/engine/content/constants';

export const CustomStatusLabel = {
};

export default function ContentStatusDisplay({ content }) {
  const customKeys = Object.keys(CustomStatusLabel);

  for (let i = 0; i < customKeys.length; i++) {
    const keys = customKeys[i].split(',');
    const status = Number(keys[0]);
    const attributes = Number(keys[1]);

    if (content.Status === status && (content.Attributes & attributes)) {
      return CustomStatusLabel[customKeys[i]];
    }
  }

  return (
    <span>{ContentStatusLabel[content.Status]}</span>
  );
}
