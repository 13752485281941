import { IS_MOBILE } from '@bm/common';
import { BackTop, Col, Form, Row } from 'antd';
import { emptyObject } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import OnBlurTextArea from 'core/components/common/OnBlurTextArea';
import {
  CONTENT_FORM_MAIN_INPUT_LIST,
  CONTENT_FORM_SIDEBAR_INPUT_LIST,
  DEFAULT_CONTENT_MAX_LENGTH_DESCRIPTION,
  DEFAULT_CONTENT_MAX_LENGTH_TITLE,
  REQUIRE_SPELL_CHECKER,
} from 'core/config';
import { ContentStatus } from 'core/engine/content/constants';
import useStickyComponent from 'core/hooks/useStickyComponent';
import useWindowUnloadAlert from 'core/hooks/useWindowUnloadAlert';
import actionRegistry from 'core/registries/actions';
import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { ContentFieldLabel, ContentHookAction } from '../../constants';
import ContentFormItemAuthor from './author/ContentFormItemAuthor';
import ContentFormItemContentType from './content-type/ContentFormItemContentType';
import ContentFormNotesDisplay from './ContentFormNotesDisplay';
import ContentFormItemDescription from './description/ContentFormItemDescription';
import ContentFormItemDisplayType from './display-type/ContentFormItemDisplayType';
import ContentFormItemDistributionDate from './distribution-date/ContentFormItemDistributionDate';
import ContentFormItemDistributions from './distributions/ContentFormItemDistributions';
import ContentFormItemUrl from './custom-url/ContentFormItemUrl';
import ContentFormAttributeInput from './input/ContentFormAttributeInput';
import ContentFormAvatarInput from './input/ContentFormAvatarInput';
import ContentFormRoyaltyInput from './input/ContentFormRoyaltyInput';
import ContentFormSeoEntryInput from './input/ContentFormSeoEntryInput';
import ContentFormItemKeywords from './keywords/ContentFormItemKeywords';
import ContentFormItemSubTitle from './sub-title/ContentFormItemSubTitle';
import ContentFormItemTitle from './title/ContentFormItemTitle';
import ContentFormToolbar from './toolbar/ContentFormToolbar';
import ContentFormItemRelatedContents from './related-contents/ContentFormItemRelatedContents';
import ContentFormItemEmbeddedCSS from './embedded-styles/ContentFormItemEmbeddedCSS';
import ContentFormItemEmbeddedJS from './embedded-styles/ContentFormItemEmbeddedJS';
import ContentFormItemFrameLink from './frame-link/ContentFormItemFrameLink';
import ContentFormItemSource from './source/ContentFormItemSource';
import ContentFormItemAvatarInput from './content-avatar/ContentFormItemAvatarInput';
import ContentFormItemRedirectLink from './redirect-link/ContentFormItemRedirectLink';
import ContentFormItemLongformAvatar from './longform-avatar/ContentFormItemLongformAvatar';
import ContentFormItemRelatedTopics from './related-topics/ContentFormItemRelatedTopics';
import ContentFormContentInputGroup from './input/ContentFormContentInputGroup';
import useNodeRef from 'core/hooks/useNodeRef';
import { AwareInputTypes } from './input/ContentFormLengthAwareInput';
import { useElementRectWithDebounce } from 'core/hooks/useElementRect';

const useStyles = makeStyles(() => ({
  container: {
    '& .ant-form-item': {
      marginBottom: 4,
    },
  },
  rightColumn: {
    height: '100%',

    '& > .ant-form-item': {
      backgroundColor: '#f9f9f9',
      padding: 10,
      paddingTop: 0,
      border: '1px solid #d4d4d4',
      borderRadius: 4,

      '&:not(:first-child)': {
        marginTop: 10,
      },
    },
  },
  formItemBody: {
    marginBottom: '0 !important',
  },
  formItemNoTitle: {
    paddingTop: '10px !important',
  },
  toolbar: {
    bottom: 0,
    position: 'sticky',
    zIndex: 1, // must be smaller than editor's toolbar zIndex because if equal, this may overlaps editor's tooltip.
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backTop: {
    zIndex: 2, // backTop Button of ContentForm Page, not suppose to visible in full-size editor
  },
}));

export default function ContentForm({
  content,
  form,
  bodyEditorRef,
  descriptionEditorRef,
  handleAction,
  getFormData,
  visibleActions,
  maxLengthTitle = DEFAULT_CONTENT_MAX_LENGTH_TITLE,
  maxLengthTitleType = AwareInputTypes[0],
  maxLengthSubTitle = DEFAULT_CONTENT_MAX_LENGTH_TITLE,
  maxLengthDescription = DEFAULT_CONTENT_MAX_LENGTH_DESCRIPTION,
  maxLengthDescriptionType = AwareInputTypes[0],
  maxLengthSEOTitle = DEFAULT_CONTENT_MAX_LENGTH_TITLE,
  maxLengthSEODescription = DEFAULT_CONTENT_MAX_LENGTH_DESCRIPTION,
  descriptionPrefix = '',
  mainInputList = CONTENT_FORM_MAIN_INPUT_LIST,
  sidebarInputList = CONTENT_FORM_SIDEBAR_INPUT_LIST,
  changeAvatar,
}) {
  const classes = useStyles();
  const avatarDescriptionEditorRef = useRef();

  const { getFieldDecorator, resetFields } = form;

  const normalizedAvatar = useMemo(() => {
    return {
      url: content.AvatarUrl,
      description: content.AvatarDescription,
    };
  }, [content]);

  const scrollContainer = useMemo(() => document.querySelector('[data-sticky-container]'), []);

  useEffect(resetFields, [content]);

  useEffect(() => {
    if (!mainInputList.includes('ContentBody')) {
      mainInputList.push('ContentBody');
    }
  }, [mainInputList]);

  useWindowUnloadAlert();

  getFieldDecorator('ContentID', { initialValue: content.ContentID });
  getFieldDecorator('Status', { initialValue: content.Status || ContentStatus.DRAFT });
  getFieldDecorator('DisplayType', { initialValue: content.DisplayType });
  getFieldDecorator('ContentType', { initialValue: content.ContentType });
  getFieldDecorator('extraData', { initialValue: content.extraData || emptyObject });
  getFieldDecorator('Meta', { initialValue: content.Meta || emptyObject });
  getFieldDecorator('spellChecker', {
    initialValue: REQUIRE_SPELL_CHECKER ? undefined : true,
    rules: [{ required: true, message: 'Bắt buộc!' }],
  });

  const toolbarRef = useNodeRef();
  const toolbarHeight = useElementRectWithDebounce(toolbarRef.current, 300).height;
  const sidebarRef = useNodeRef();
  const sidebarHeight = useElementRectWithDebounce(sidebarRef.current, 300).height;

  useStickyComponent({
    container: scrollContainer,
    target: sidebarRef.current,
    offsetBottom: 30,
  });

  const inputMap = {
    Title: (
      <ContentFormItemTitle
        content={content}
        form={form}
        maxLength={maxLengthTitle}
        maxLengthType={maxLengthTitleType}
      />
    ),
    SubTitle: (
      <ContentFormItemSubTitle content={content} form={form} maxLength={maxLengthSubTitle} />
    ),
    Description: (
      <ContentFormItemDescription
        form={form}
        editorRef={descriptionEditorRef}
        content={content}
        prefix={descriptionPrefix}
        maxLength={maxLengthDescription}
        maxLengthType={maxLengthDescriptionType}
      />
    ),
    Attributes: (
      <Form.Item className={classes.formItemNoTitle}>
        {getFieldDecorator('Attributes', {
          initialValue: content.Attributes || 0,
        })(
          <ContentFormAttributeInput />,
        )}
      </Form.Item>
    ),
    avatar: (
      <Form.Item label={ContentFieldLabel.avatar}>
        {getFieldDecorator('avatar', {
          initialValue: normalizedAvatar,
          rules: [
            { validator: avatarValidator },
          ],
        })(
          <ContentFormAvatarInput editorRef={avatarDescriptionEditorRef} />,
        )}
      </Form.Item>
    ),
    longformAvatar: (
      <ContentFormItemLongformAvatar content={content} form={form} />
    ),
    draggableAvatar: (
      <ContentFormItemAvatarInput content={content} form={form} />
    ),
    Distributions: (
      <ContentFormItemDistributions content={content} form={form} />
    ),
    Keywords: (
      <ContentFormItemKeywords content={content} form={form} bodyEditorRef={bodyEditorRef} />
    ),
    SeoEntry: (
      <Form.Item label={ContentFieldLabel.SeoEntry}>
        {getFieldDecorator('SeoEntry', {
          initialValue: content.SeoEntry,
        })(
          <ContentFormSeoEntryInput
            contentId={content.ContentID}
            maxLengthTitle={maxLengthSEOTitle}
            maxLengthDescription={maxLengthSEODescription}
          />,
        )}
      </Form.Item>
    ),
    DistributionDate: (
      <ContentFormItemDistributionDate content={content} form={form} />
    ),

    Source: (
      <ContentFormItemSource content={content} form={form} />
    ),

    Url: (
      <ContentFormItemUrl content={content} form={form} />
    ),

    Author: (
      <ContentFormItemAuthor content={content} form={form} />
    ),

    RelatedContents: (
      <ContentFormItemRelatedContents content={content} form={form} bodyEditorRef={bodyEditorRef} />
    ),

    RelatedTopics: (
      <ContentFormItemRelatedTopics content={content} form={form} />
    ),

    Royalty: (
      <Form.Item label={ContentFieldLabel.Royalty}>
        {getFieldDecorator('Royalty', {
          initialValue: content.Royalty,
        })(
          <ContentFormRoyaltyInput />,
        )}
      </Form.Item>
    ),

    contentNote: (
      <Form.Item label={ContentFieldLabel.contentNote}>
        {getFieldDecorator('contentNote', {
          initialValue: content.contentNote || '',
        })(
          <OnBlurTextArea placeholder="Thêm ghi chú bài viết..." maxLength={2000} />,
        )}
      </Form.Item>
    ),
    RedirectLink: (
      <ContentFormItemRedirectLink content={content} form={form} />
    ),
    DisplayType: (
      <ContentFormItemDisplayType content={content} form={form} />
    ),
    ContentType: (
      <ContentFormItemContentType content={content} form={form} />
    ),
    EmbeddedCSS: (
      <ContentFormItemEmbeddedCSS content={content} form={form} />
    ),
    EmbeddedJS: (
      <ContentFormItemEmbeddedJS content={content} form={form} />
    ),
    FrameLink: (
      <ContentFormItemFrameLink content={content} form={form} />
    ),
    ContentBody: (
      <ContentFormContentInputGroup
        content={content}
        form={form}
        bodyEditorRef={bodyEditorRef}
        descriptionEditorRef={descriptionEditorRef}
        avatarDescriptionEditorRef={avatarDescriptionEditorRef}
        changeAvatar={changeAvatar}
        sidebarHeight={sidebarHeight}
        actionToolbarHeight={toolbarHeight}
      />
    ),
  };

  actionRegistry.doAction(ContentHookAction.FORM_INPUTS,
    { content, form, editorRef: bodyEditorRef, descriptionEditorRef, inputMap });

  return (
    <Form className={classes.container}>
      <Row gutter={24} type="flex">
        <Col span={24} lg={17} xxl={16}>
          <ContentFormNotesDisplay notes={content.ContentNote} />
          {mainInputList.filter(inputKey => inputMap[inputKey]).map(inputKey => (
            <Fragment key={inputKey}>{inputMap[inputKey]}</Fragment>
          ))}
        </Col>
        <Col span={24} lg={7} xxl={8} className={classes.rightColumn} ref={sidebarRef}>
          {sidebarInputList.filter(inputKey => inputMap[inputKey]).map(inputKey => (
            <Fragment key={inputKey}>{inputMap[inputKey]}</Fragment>
          ))}
        </Col>
        <Col span={24} className={!IS_MOBILE && classes.toolbar} ref={toolbarRef}>
          <ContentFormToolbar
            content={content}
            form={form}
            editorRef={bodyEditorRef}
            descriptionEditorRef={descriptionEditorRef}
            avatarDescriptionEditorRef={avatarDescriptionEditorRef}
            getFormData={getFormData}
            onAction={handleAction}
            visibleActions={visibleActions}
          />
        </Col>
      </Row>
      <BackTop
        className={classes.backTop}
        target={() => scrollContainer}
        visibilityHeight={window.innerHeight}
      />
    </Form>
  );
}

function avatarValidator(rule, value, callback) {
  if (!value || !value.url) {
    return callback();
  }

  if (value.url.length > 250) {
    return callback(new Error('Tối đa 250 kí tự!'));
  }

  if (value && value.error) {
    return callback(new Error('Đường dẫn ảnh không chính xác!'));
  }

  callback();
}
