import React from 'react';
import { AdsOptionTypeLabel, AdsOptionType } from 'core/engine/ads-option/constants';
import { Icon } from 'antd';

const iconMap = {
  [AdsOptionType.Image]: 'picture',
  [AdsOptionType.Link]: 'link',
  [AdsOptionType.Script]: 'code',
};

const iconStyle = { fontSize: 18 };

export default function AdsOptionTypeDisplay({ type, useIcon = false }) {
  if (useIcon) {
    return (
      <Icon style={iconStyle} type={iconMap[type]} title={AdsOptionTypeLabel[type]} />
    );
  }

  return (
    <span>{AdsOptionTypeLabel[type]}</span>
  );
}
