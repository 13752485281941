import { Button, Form } from 'antd';
import React, { Fragment, useRef } from 'react';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import topicApi from 'core/engine/topic/api';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import TopicForm from './TopicForm';
import { emptyObject } from 'core/common/empty';

function TopicFormDialogButton({ form, topic = emptyObject, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });
  const editorRef = useRef();

  return (
    <Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={isEditMode ? 'Sửa chủ đề tin' : 'Tạo chủ đề tin'}
        onClick={handleOpen}
      >
        {isEditMode ? '' : 'Tạo chủ đề'}
      </Button>
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa chủ đề tin' : 'Tạo chủ đề tin'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <TopicForm form={form} topic={topic} editorRef={editorRef} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    formData.SeoEntry = JSON.stringify(formData.SeoEntry);
    formData.Description = editorRef.current.getNormalizedValue();

    if (formData.Meta) {
      formData.Meta = JSON.stringify(formData.Meta);
    }

    if (formData.ParentIDs) {
      formData.ParentIDs = formData.ParentIDs.join();
    }

    if (isEditMode) {
      return asyncAction('Sửa chủ đề tin', () => {
        return topicApi.update(formData)
          .then(handleDone);
      });
    } else {
      return asyncAction('Tạo chủ đề tin', () => {
        return topicApi.create(formData)
          .then(handleDone);
      });
    }

  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'TopicFormDialogButton' })(TopicFormDialogButton);
