import { Form, DatePicker } from 'antd';
import React, { useEffect, useMemo } from 'react';
import commonStyles from 'core/common/commonStyles';
import moment from 'moment';
import { InterviewAnswerStatus, InterviewAnswerStatusLabel } from 'core/engine/interview-answer/constants';
import Selector from 'core/components/common/Selector';
import lodash from 'core/common/lodash';
import FullEditor from 'core/components/editor/FullEditor';
import useInterviewGuestLoader from '../../hooks/useInterviewGuestLoader';
import HtmlDisplay from 'core/components/common/HtmlDisplay';

export default function InterviewAnswerForm({
  form,
  onSubmit,
  interview,
  answer,
  editorRef,
}) {
  const { getFieldDecorator, resetFields } = form;
  const date = useMemo(() => answer.Date ? moment(answer.Date) : moment(), [answer.Date]);
  const { guests } = useInterviewGuestLoader(interview);
  useEffect(() => resetFields, [answer]);

  getFieldDecorator('AnswerID', { initialValue: answer.AnswerID });
  getFieldDecorator('InterviewID', { initialValue: interview.InterviewID });
  getFieldDecorator('QuestionID', { initialValue: answer.QuestionID });

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Người gửi">
        {lodash.get(answer, 'InterviewQuestion.UserDisplayName', null)}
      </Form.Item>
      <Form.Item label="Câu hỏi">
        <HtmlDisplay html={lodash.get(answer, 'InterviewQuestion.Question', null)} />
      </Form.Item>
      <Form.Item label="Khách mời">
        {getFieldDecorator('GuestID', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: answer.GuestID,
        })(
          <Selector
            placeholder="Chọn khách mời"
            valueField="GuestID"
            labelField="Name"
            valueIsNumber
            options={guests}
          />
        )}
      </Form.Item>
      <Form.Item label="Thời gian">
        {getFieldDecorator('Date', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: date,
        })(
          <DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
        )}
      </Form.Item>
      <Form.Item label="Trạng thái">
        {getFieldDecorator('Status', {
          initialValue: answer.Status ? answer.Status : InterviewAnswerStatus.PENDING,
        })(
          <Selector
            disabled={!interview.isAdmin}
            options={InterviewAnswerStatusLabel}
            valueIsNumber
          />
        )}
      </Form.Item>
      <Form.Item label="Nội dung trả lời">
        {getFieldDecorator('HtmlAnswer', {
          rules: [{
            required: true,
            message: 'Bắt buộc!',
            transform: () => editorRef.current.getValue(),
          }],
          initialValue: answer.HtmlAnswer ? answer.HtmlAnswer : '<p></p>',
        })(
          <FullEditor
            placeholder="Nhập nội dung trả lời"
            height="auto"
            minHeight={544}
            editorRef={editorRef}
            options={{
              pluginsOptions: {
                SpellCheckerPlugin: {
                  getContent() {
                    return {
                      form: form,
                      ContentID: 0,
                      Title: '',
                      Description: '',
                      HtmlBody: editorRef.current.getValue(),
                      AvatarDescription: '',
                    };
                  },
                },
              },
            }}
          />
        )}
      </Form.Item>
    </Form>
  );
}
