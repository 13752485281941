import { Checkbox, Col, DatePicker, Form, Input, Row } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { emptyArray, emptyObject } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import Selector from 'core/components/common/Selector';
import moment from 'moment';
import React, { useEffect } from 'react';
import FullEditor from 'core/components/editor/FullEditor';
import LiveStreamTypeSelector from 'core/components/live-stream/LiveStreamTypeSelector';
import { LiveStreamStatus, LiveStreamStatusLabel } from 'core/engine/live-stream/constants';
import { useLiveStreamAuthz } from '../hooks';
import { DISABLE_HOT_LIVESTREAM_OPTION } from 'core/config';
import useWindowUnloadAlert from 'core/hooks/useWindowUnloadAlert';

const useStyles = makeStyles(() => ({
  container: {
    '& .ant-form-item': {
      marginBottom: 4,
    },
  },
  rightColumn: {
    height: '100%',

    '& > .ant-form-item': {
      backgroundColor: '#f9f9f9',
      padding: 10,
      paddingTop: 0,
      border: '1px solid #d4d4d4',
      borderRadius: 4,

      '&:not(:first-child)': {
        marginTop: 10,
      },
    },
  },
  formItemNoTitle: {
    paddingTop: '5px !important',
    paddingBottom: '5px !important',
  },
}));

export default function LiveStreamForm({
  form,
  onSubmit,
  liveStream = emptyObject,
  editorRef,
}) {
  const classes = useStyles();
  const { getFieldDecorator, resetFields } = form;

  const { isApprover } = useLiveStreamAuthz(liveStream);

  useEffect(() => resetFields(), [liveStream]);

  useWindowUnloadAlert(!!liveStream.StreamID);

  getFieldDecorator('StreamID', { initialValue: liveStream.StreamID });

  return (
    <Form className={classes.container} onSubmit={onSubmit}>
      <Row gutter={24} type="flex">
        <Col span={24} lg={17} xxl={16}>
          <Form.Item wrapperCol={commonStyles.wrapperCol}>
            {getFieldDecorator('HtmlDetail', {
              initialValue: liveStream.HtmlDetail || '<p></p>',
              rules: [{
                required: true,
                message: 'Bắt buộc!',
                transform: () => editorRef.current.getValue(),
              }],
            })(
              <FullEditor
                placeholder="Nội dung tường thuật"
                height={455}
                editorRef={editorRef}
                options={{
                  pluginsOptions: {
                    SpellCheckerPlugin: {
                      getContent() {
                        return {
                          form: form,
                          ContentID: 0,
                          Title: form.getFieldValue('Title'),
                          Description: '',
                          HtmlBody: editorRef.current.getValue(),
                          AvatarDescription: '',
                        };
                      },
                    },
                  },
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={24} lg={7} xxl={8} className={classes.rightColumn}>
          {!DISABLE_HOT_LIVESTREAM_OPTION &&
            <Form.Item className={classes.formItemNoTitle}>
              {getFieldDecorator('IsHot', {
                valuePropName: 'checked',
                initialValue: liveStream.IsHot,
              })(
                <Checkbox>Nổi bật</Checkbox>
              )}
            </Form.Item>
          }
          <Form.Item label="Tiêu đề">
            {getFieldDecorator('Title', {
              initialValue: liveStream.Title,
            })(
              <Input placeholder="Tiêu đề" />
            )}
          </Form.Item>
          <Form.Item label="Loại tường thuật">
            {getFieldDecorator('Type', {
              initialValue: liveStream.Type || 1,
            })(
              <LiveStreamTypeSelector />
            )}
          </Form.Item>
          <Form.Item label="Trạng thái">
            {getFieldDecorator('Status', {
              initialValue: liveStream.Status || LiveStreamStatus.DRAFT,
            })(
              <Selector
                disabledOptionValues={isApprover ? emptyArray : [LiveStreamStatus.APPROVED]}
                options={LiveStreamStatusLabel}
                valueIsNumber
                disabled={liveStream.StreamID && !isApprover}
              />
            )}
          </Form.Item>
          <Form.Item label="Tác giả">
            {getFieldDecorator('AuthorName', {
              initialValue: liveStream.AuthorName,
            })(
              <Input placeholder="Tác giả" />
            )}
          </Form.Item>
          <Form.Item label="Thời gian">
            {getFieldDecorator('Date', {
              initialValue: liveStream.Date ? moment(parseInt(liveStream.Date)) : null,
            })(
              <DatePicker placeholder="Bây giờ" showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
