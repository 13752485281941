import React from 'react';
import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import Container from 'core/components/common/Container';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import voteApi from 'core/engine/vote/api';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import VoteFormDialogButton from './VoteFormDialogButton';
import VoteList from './VoteList';

function listFn({ limit, offset, filters, sorting }) {
  return voteApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default function VoteListView() {
  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: {
      VoteID: 'DESC',
    },
  });

  return (
    <Container pageTitle="Bình chọn">
      <TableToolbarWrapper>
        <VoteFormDialogButton isEditMode={false} onDone={listProps.refresh} />
      </TableToolbarWrapper>
      <VoteList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdate={listProps.refresh}
        onDelete={handleDelete}
      />
    </Container>
  );

  function handleDelete(vote) {
    return asyncAction('Xóa bình chọn', () => {
      return voteApi.delete(vote.VoteID).then(() => {
        listProps.removeItem(vote, 'VoteID');
      });
    });
  }
}
