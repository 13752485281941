import { green } from '@ant-design/colors';
import { Badge, Tabs } from 'antd';
import React, { Fragment } from 'react';
import makeStyles from 'core/common/makeStyles';
import Separator from 'core/components/common/Separator';
import SelectedRelatedTopicsTab from './SelectedRelatedTopicsTab';
import { Sites } from 'core/engine/site/constants';

const { TabPane } = Tabs;

const useStyles = makeStyles(() => ({
  tab: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > .ant-tabs-content': {
      height: 'calc(100% - 71px)',
    },
  },
}));

export default function RelatedTopicsSelector({ value, onChange, SelectableTab, siteId = Sites.ALL }) {
  const classes = useStyles();

  return (
    <Tabs
      defaultActiveKey={value.length > 0 ? 'list' : 'add'}
      className={classes.tab}
      tabBarStyle={{ width: 'calc(100% - 32px)' }}
    >
      <TabPane
        tab={
          <Fragment>
            Danh sách chủ đề đã chọn
            <Separator />
            <Badge
              count={value.length}
              style={{ backgroundColor: green[5] }}
            />
          </Fragment>
        }
        key="list"
      >
        <SelectedRelatedTopicsTab value={value} onChange={onChange} />
      </TabPane>
      <TabPane tab="Thêm chủ đề liên quan" key="add">
        <SelectableTab value={value} onChange={onChange} siteId={siteId} />
      </TabPane>
    </Tabs>
  );
}
