import { httpGet, httpPost } from 'core/common/http-client';

const contentAvatarApi = {
  getItems(contentId) {
    const qs = { limit: 50, offset: 0, ContentID: contentId, Enabled: true };
    return httpGet('/content-avatars/get/items-by-range', { qs });
  },

  upload(contentId, data) {
    return httpPost('/content-avatars/post/upload', {
      ContentID: contentId,
      data: JSON.stringify(data),
    });
  },
};

export default contentAvatarApi;
