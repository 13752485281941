import { Button, Input, Modal } from 'antd';
import React, { useCallback, useMemo } from 'react';
import makeStyles from 'core/common/makeStyles';
import AccountSelector from 'core/components/account/AccountSelector';
import { ContentAction } from 'core/engine/content/constants';
import useAsyncAction from 'core/hooks/useAsyncAction';
import useDialog from 'core/hooks/useDialog';
import useForm from 'core/hooks/useForm';
import useAuth from 'core/hooks/useAuth';
import { Fragment } from 'react-is';
import { showContentFormError } from 'core/modules/contents/services';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 700,
    marginBottom: 8,
    display: 'block',

    '&:not(:first-child)': {
      marginTop: 10,
    },
  },
}));

export default function ContentFormToolbarForwardButton({
  getFormData,
  onAction,
  className,
  isMoveButton = false,
}) {
  const classes = useStyles();
  const action = isMoveButton ? ContentAction.MOVE : ContentAction.FOWARD;
  const actionTitle = isMoveButton ? 'Cho tin' : 'Chuyển tiếp';

  const { loggedInUser } = useAuth();

  const { open, handleClose, handleOpen } = useDialog();
  const { formData, setFieldsData, handleChange } = useForm({});
  const { isLoading, performAction } = useAsyncAction();

  const formValid = useMemo(() => {
    return formData.account && formData.note && formData.account !== loggedInUser.id;
  }, [loggedInUser, formData.account, formData.note]);

  const handleClick = useCallback(() => {
    getFormData(true, action).then((formData) => {
      if (formData.contentNote) {
        setFieldsData({ note: formData.contentNote });
      }

      handleOpen();
    }, showContentFormError);
  }, [getFormData, handleOpen, setFieldsData]);

  const handleOk = useCallback(() => {
    performAction('', () => {
      return onAction(action, formData).then(() => handleClose());
    }, { silent: true });
  }, [formData, handleClose, onAction]);

  return (
    <Fragment>
      <Button
        title={actionTitle}
        onClick={handleClick}
        icon="arrow-right"
        className={className}
      >
        {actionTitle}
      </Button>
      <Modal
        visible={open}
        title={actionTitle}
        onCancel={handleClose}
        onOk={handleOk}
        okText={actionTitle}
        okButtonProps={{ disabled: !formValid }}
        confirmLoading={isLoading}
        destroyOnClose
        keyboard={false}
        maskClosable={false}
      >
        <span className={classes.label}>Tài khoản nhận tin:</span>
        <AccountSelector
          value={formData.account}
          onChange={value => handleChange('account', value)}
          emptyLabel="-- Chọn tài khoản --"
        />

        <span className={classes.label}>Ghi chú:</span>
        <Input.TextArea placeholder="Nhập ghi chú chuyển tin" name="note" value={formData.note} onChange={handleChange} />
      </Modal>
    </Fragment>
  );
}
