import React, { memo, useMemo, Fragment, useContext, useState, useEffect } from 'react';
import ZoneDisplay from '../zone/ZoneDisplay';
import ContentEditLink from './ContentEditLink';
import makeStyles from 'core/common/makeStyles';
import AccountSiteDisplay from '../account/AccountSiteDisplay';
import { ContentStatus } from 'core/engine/content/constants';
import ContentViewLink from './ContentViewLink';
import ContentAttributeDisplay from './ContentAttributeDisplay';
import { parseJson } from 'core/common/jsonUtils';
import classnames from 'core/common/classnames';
import { Icon, message } from 'antd';
import commonStyles from 'core/common/commonStyles';
import ContentNoteDisplayAsIcon from './ContentNoteDisplayAsIcon';
import RegionDisplay from '../region/RegionDisplay';
import moment from 'moment';
import ContentPreviewLink from './ContentPreviewLink';
import { CustomComponentContext } from 'core/components/layout';
import { buildShortContentUrl } from 'core/common/urlHelper';
import { Sites } from 'core/engine/site/constants';
import siteUtils from 'core/engine/site/utils';

const useStyles = makeStyles(() => ({
  zone: {
    fontSize: 'small',
    color: '#757171',

    '&:not(:last-child)': {
      marginRight: 2,
      '&:after': {
        content: '","',
        fontWeight: 500, // to not be bolded when zone is primary
      },
    },
  },
  primaryZone: {
    fontWeight: 700,
  },
  schedule: {
    opacity: '0.5',
    fontStyle: 'italic',
  },
  clock: {
    marginRight: 4,
    fontSize: 15,
  },
  link: {
    marginLeft: 4,
    fontSize: 15,
  },
  zoneContainer: {
    display: 'inline',
  },
  wordCount: {
    ...commonStyles.code,
    marginLeft: 5,
  },
}));

function ContentTitleDisplayForList({
  content,
  isPreview = false,
  withZone = true,
  withAttributes = true,
  withNote = true,
  withRegion = true,
  now = moment.now(),
  ...props
}) {
  const customComponentContext = useContext(CustomComponentContext);

  const classes = useStyles();

  const [siteId, setSiteId] = useState(Sites.ALL);
  useEffect(() => {
    siteUtils.getSiteId(content.ZoneID).then(siteId => setSiteId(siteId));
  }, [content]);

  const distributionZoneIds = useMemo(() => {
    const ids = parseJson(content.Distributions, []).map(dist => dist.ZoneID).filter(Boolean);
    return ids.length ? ids : [content.ZoneID];
  }, [content]);

  const regionIds = useMemo(() => {
    return (content.ContentRegion || []).map(contentRegion => contentRegion.RegionID);
  }, [content]);

  const isScheduled = useMemo(() => {
    return content.DistributionDate > now;
  }, [content, now]);

  if (customComponentContext && customComponentContext.ExtContentTitleDisplayForList) {
    return (
      <customComponentContext.ExtContentTitleDisplayForList
        content={content}
        isPreview={isPreview}
        withZone={withZone}
        withAttributes={withAttributes}
        withNote={withNote}
        withRegion={withRegion}
        now={now}
        siteId={siteId}
        {...props}
      />
    );
  }

  const title = (
    <Fragment>
      {isScheduled && <Icon className={classes.clock} type="clock-circle" title="Tin hẹn giờ đăng" />}
      {content.Title}
      {withNote && <ContentNoteDisplayAsIcon content={content} />}
      {withAttributes && <ContentAttributeDisplay content={content} />}
    </Fragment>
  );

  return (
    <div className={isScheduled ? classes.schedule : null}>
      <AccountSiteDisplay id={content.WriterID} />
      {isPreview ? (
        <ContentPreviewLink noiseId={content.NoiseID} siteId={siteId}>
          {title}
        </ContentPreviewLink>
      ) : content.Status === ContentStatus.DEPLOYED ? (
        <Fragment>
          <ContentViewLink content={content} siteId={siteId}>
            {title}
          </ContentViewLink>
          {isScheduled && (
            <Icon
              type="link"
              className={classes.link}
              onClick={() => {
                navigator.clipboard.writeText(buildShortContentUrl(content.ContentID));
                message.success(`Đã copy đường dẫn: ${content.Title}`);
              }}
              title="Copy đường dẫn bài viết"
            />
          )}
        </Fragment>
      ) : (
        <ContentEditLink contentId={content.ContentID}>
          {title}
        </ContentEditLink>
      )}
      {withZone && (
        <Fragment>
          <br />
          <div className={classes.zoneContainer}>
            {distributionZoneIds.map(zoneId => (
              <ZoneDisplay
                key={zoneId}
                className={classnames(classes.zone, zoneId === content.ZoneID && classes.primaryZone)}
                id={zoneId}
              />
            ))}
          </div>
        </Fragment>
      )}
      {content.WordCount ? (
        <span className={classes.wordCount}>{content.WordCount} từ</span>
      ) : null}
      {withRegion && (
        <Fragment>
          <br />
          <div className={classes.zoneContainer}>
            {regionIds.map(regionId => (
              <RegionDisplay key={regionId} id={regionId} className={classes.zone} />
            ))}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default memo(ContentTitleDisplayForList);
