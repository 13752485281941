import React, { useMemo } from 'react';
import { Result } from 'antd';
import GoBackButton from 'core/components/common/GoBackButton';

export default function InterviewAuthz({ interview, requireAdmin, children }) {
  const isAllowed = useMemo(() => {
    return interview && (requireAdmin ? interview.isAdmin : (interview.isAdmin || interview.isEditor));
  }, [interview, requireAdmin]);

  if (interview && interview.InterviewID) {
    return isAllowed ? children : <Result status="403" title="Không có quyền truy cập!" extra={<GoBackButton />} />;
  } else {
    return <Result status="404" title="Giao lưu trực tuyến không tồn tại" extra={<GoBackButton />} />;
  }
}
