import { IS_MOBILE } from '@bm/common';
import { Button, Tabs } from 'antd';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import makeStyles from 'core/common/makeStyles';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import SelectContentTab from './SelectContentTab';
import SelectTopicTab from './SelectTopicTab';
import { Sites } from 'core/engine/site/constants';

const useStyles = makeStyles(() => ({
  tab: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > .ant-tabs-content': {
      height: 'calc(100% - 60px)',
    },
  },
  button: {
    position: 'absolute',
    top: '5px',
    right: '60px',
    lineHeight: 1.5,
  },
}));

const { TabPane } = Tabs;

export default forwardRef(function LinkContentDialog({
  multiple,
  onSelect,
  onCancel,
  siteId = Sites.ALL,
}, ref) {
  const classes = useStyles();
  const { open, handleClose, handleOpen } = useDialog();
  const [collapsed, setCollapsed] = useState(false);
  const [isContentTab, setIsContentTab] = useState(true);
  const [items, setItems] = useState([]);

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  const footerProps = useMemo(() => {
    return multiple ? {
      onOk: () => onSelect(items),
      okText: items.length ? `Chèn ${items.length} ${isContentTab ? 'bài viết' : 'chủ đề'}` : 'Chèn',
      okButtonProps: { disabled: !items.length },
    } : { footer: null };
  }, [multiple, onSelect, items, isContentTab]);

  return (
    <BaseDialog
      focusTriggerAfterClose={false}
      keyboard
      maskClosable
      fullHeightFixed
      paddingTop={0}
      visible={open}
      title={null}
      width={900}
      onCancel={onCancel}
      {...footerProps}
    >
      <Tabs className={classes.tab} onChange={onChangeTab}>
        <TabPane tab="Chèn liên kết bài viết" key="link-content">
          {isContentTab && !IS_MOBILE && (
            <Button
              title={collapsed ? 'Hiện tìm kiếm' : 'Ẩn tìm kiếm'}
              className={classes.button}
              onClick={toggleCollapsed}>
              {collapsed ? 'Hiện tìm kiếm' : 'Ẩn tìm kiếm'}
            </Button>
          )}
          <SelectContentTab
            value={items}
            onChange={handleChange}
            hideToolbar={collapsed}
            siteId={siteId}
          />
        </TabPane>
        <TabPane tab="Chèn liên kết chủ đề " key="link-topic">
          <SelectTopicTab
            value={items}
            onChange={handleChange}
            siteId={siteId}
          />
        </TabPane>
      </Tabs>
    </BaseDialog>
  );

  function handleChange(selectedItems) {
    if (multiple) {
      setItems(selectedItems);
    } else {
      onSelect(selectedItems);
    }
  }

  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }

  function onChangeTab(activeKey) {
    setIsContentTab(activeKey === 'link-content');
    setItems([]);
  }
});
