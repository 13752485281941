import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import BaseTable from 'core/components/table/BaseTable';
import React, { useCallback, useMemo } from 'react';
import makeStyles from 'core/common/makeStyles';
import ReactTable from 'react-table';
import classnames from 'classnames';
import { emptyFunction } from 'core/common/empty';
import { Button, Icon } from 'antd';
import { IS_FIREFOX } from '@bm/common';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import { commonColumns } from 'core/common/listUtils';
import lodash from 'core/common/lodash';

const useStyle = makeStyles({
  table: {
    '& .rt-tbody': {
      overflow: 'visible !important', // fix nested scroll container issue of react-beautiful-dnd. For more details: https://github.com/atlassian/react-beautiful-dnd/issues/131
    },
  },
  dragging: {
    backgroundColor: '#fff',
  },
});

function DropTbodyComponent({ children, style }) {
  return (
    <Droppable droppableId="droppable">
      {(droppableProvided) => (
        <div
          ref={droppableProvided.innerRef}
          style={{ overflow: 'inherit', ...(IS_FIREFOX && { overflowX: 'hidden' }), ...style }} // Because "overflow: overlay" doesn't work on Firefox and "overflow-x: hidden" breaks other browser's DnD animation, we add "overflow-x: hidden" for Firefox only.
        >
          <ReactTable.defaultProps.TbodyComponent>
            {children}
          </ReactTable.defaultProps.TbodyComponent>
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

function DragTrComponent({ children, rowInfo, isDragDisabled, className, onRowClick = emptyFunction }) {
  const classes = useStyle();

  if (rowInfo) {
    const { index } = rowInfo;

    return (
      <Draggable key={index} index={index} draggableId={'draggable-' + index} isDragDisabled={isDragDisabled}>
        {(draggableProvided, draggableSnapshot) => (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            className={draggableSnapshot.isDragging ? classes.dragging : null}
            onClick={event => onRowClick(event, rowInfo)}
          >
            <ReactTable.defaultProps.TrComponent style={{ width: '100%' }} className={className}>
              {children}
            </ReactTable.defaultProps.TrComponent>
          </div>
        )}
      </Draggable>
    );
  }

  return (
    <ReactTable.defaultProps.TrComponent>
      {children}
    </ReactTable.defaultProps.TrComponent>
  );
}

const dragIconColumn = {
  width: 50,
  filterable: false,
  style: { justifyContent: 'center' },
  Cell: <Icon type="drag" title="Sắp xếp" style={{ fontSize: 24, color: '#999' }} />,
};

DraggableTable.MOVE_COLUMN = 'MOVE_COLUMN';

export default function DraggableTable({
  data,
  columns,
  onMove,
  isDragDisabled,
  showDragIcon = 'none',
  onRowClick,
  className,
  ...props
}) {
  const classes = useStyle();

  const onDragEnd = useCallback((result) => {
    // dropped outside the list or order not change
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }

    handleMove(result.source.index, result.destination.index);
  }, [handleMove]);

  const moveColumn = {
    Header: 'Sắp xếp',
    Cell: ({ index }) => (
      <ActionsCellWrapper>
        <Button
          title="Lên"
          icon="arrow-up"
          disabled={index < 1}
          onClick={() => handleMove(index, index - 1, true)}
        />
        <Button
          title="Xuống"
          icon="arrow-down"
          disabled={index > data.length - 2}
          onClick={() => handleMove(index, index + 1, true)}
        />
      </ActionsCellWrapper>
    ),
    maxWidth: 100,
    ...commonColumns.allCentered,
  };

  const draggableColumns = useMemo(() => {
    const moveColumnIndex = lodash.indexOf(columns, DraggableTable.MOVE_COLUMN);
    let newColumns = [...columns];

    if (isDragDisabled) {
      moveColumnIndex > -1 && newColumns.splice(moveColumnIndex, 1);
      return newColumns;
    }

    if (moveColumnIndex > -1) {
      newColumns.splice(moveColumnIndex, 1, moveColumn);
    }

    if (showDragIcon !== 'none') {
      newColumns = showDragIcon === 'left' ? [dragIconColumn, ...newColumns] : [...newColumns, dragIconColumn];
    }

    return newColumns;
  }, [columns, isDragDisabled, showDragIcon, moveColumn]);

  const getTrProps = (state, rowInfo) => {
    return { rowInfo, isDragDisabled, onRowClick };
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <BaseTable
        className={classnames(classes.table, className)}
        TrComponent={DragTrComponent}
        TbodyComponent={DropTbodyComponent}
        getTrProps={getTrProps}
        columns={draggableColumns}
        data={data}
        showPagination={false}
        pageSize={data.length || 6} // to not be collapsed when data is empty
        {...props}
      />
    </DragDropContext>
  );

  function handleMove(fromIndex, toIndex, fromUpDown = false) {
    let newList = data;

    if (toIndex >= 0 && toIndex < data.length) {
      newList = data.slice();
      const [removed] = newList.splice(fromIndex, 1);
      newList.splice(toIndex, 0, removed);
    }

    return onMove(newList, fromIndex, toIndex, fromUpDown);
  }
}
