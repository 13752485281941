import React from 'react';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import useContentColumns from 'core/hooks/useContentColumns';
import ContentEditButtonForList from 'core/components/content/ContentEditButtonForList';
import ContentFilterableAccount from 'core/components/content/ContentFilterableAccount';
import ContentLogButtonForList from 'core/components/content/ContentLogButtonForList';
import ContentPreviewButtonForList from 'core/components/content/ContentPreviewButtonForList';
import CheckableTable from 'core/components/table/CheckableTable';

export default function ContentDeletedList({
  onWriterSelected,
  ...props
}) {
  const ContentColumns = useContentColumns();

  const columns = [
    ContentColumns.ContentID,
    ContentColumns.Avatar,
    ContentColumns.Title,
    {
      Header: 'Người tạo',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <ContentFilterableAccount
            id={original.WriterID}
            onClick={() => onWriterSelected(original.WriterID)}
            title="Lọc tin tạo bởi tài khoản này"
          />
          <br />
          <i><DateTimeDisplay timestamp={original.CreatedDate} /></i>
        </div>
      ),
    },
    ContentColumns.Editor,
    ContentColumns.CheckIn,
    {
      width: 150,
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <ContentEditButtonForList content={original} />
          <ContentLogButtonForList contentId={original.ContentID} />
          <ContentPreviewButtonForList noiseId={original.NoiseID} />
        </ActionsCellWrapper>
      ),
    },
  ];

  return (
    <CheckableTable
      selectOnLeft
      idField="ContentID"
      columns={columns}
      {...props}
    />
  );
}
