import { Input } from 'antd';
import SettingForm from 'core/modules/managements/settings/components/SettingForm';
import React from 'react';

const settings = [
  { separator: 'Desktop' },
  {
    key: 'StringConfig_WebDesktopScript',
    label: 'Main Script',
    input: <Input />,
  },
  {
    key: 'StringConfig_WebDesktopStyle',
    label: 'Main CSS',
    input: <Input />,
  },
  {
    key: 'StringConfig_WebDesktopDetailScript',
    label: 'Detail Script',
    input: <Input />,
  },
  { separator: 'Mobile' },
  {
    key: 'StringConfig_WebMobileScript',
    label: 'Main Script',
    input: <Input />,
  },
  {
    key: 'StringConfig_WebMobileStyle',
    label: 'Main CSS',
    input: <Input />,
  },
  {
    key: 'StringConfig_WebMobileDetailScript',
    label: 'Detail Script',
    input: <Input />,
  },
  { separator: 'LOG' },
  {
    key: 'StringConfig_WebLogScript',
    label: 'Log Script',
    input: <Input />,
  },
];

export default function SettingWebForm({ form, onSubmit }) {
  return (
    <SettingForm form={form} onSubmit={onSubmit} settings={settings} />
  );
}
