import { CMS_TITLE } from 'core/config';

export const SET_PAGETITLE = 'SET_PAGETITLE';
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';

export function setPageTitle(pageTitle) {
  return {
    type: SET_PAGETITLE,
    pageTitle,
  };
}

export function setTitle(pageTitle) {
  return (dispatch) => {
    document.title = pageTitle ? pageTitle + ' - ' + CMS_TITLE : CMS_TITLE;
    dispatch(setPageTitle(pageTitle));
  };
}

export function setSidebarOpened(opened) {
  return {
    type: SET_SIDEBAR_OPEN,
    opened,
  };
}

