import { escapeRegex } from '@bm/common';
import { Button, Dropdown, Menu, Typography } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { emptyFunction } from 'core/common/empty';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import AudioPlayer from 'core/components/common/AudioPlayer';
import FlexDiv from 'core/components/common/FlexDiv';
import VideoPlayer from 'core/components/common/VideoPlayer';
import { FileType } from 'core/engine/file/constants';
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BASE_STREAMING_URL } from 'src/config';
import { AUDIO_EXTENSIONS } from 'src/engine/file-extend/constants';
import ContentFormItemVideoUrlDialog from './ContentFormItemVideoUrlDialog';
import VideoAudioManagerDialog from './VideoAudioManagerDialog';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:only-child': {
      marginBottom: -10,
    },
  },
}));

const DropdownButton = {
  SELECT: 'select',
  INPUT: 'input',
  CLEAR: 'clear',
};

export default forwardRef(function ContentFormItemVideoUrlInput({
  value,
  onChange,
  duration,
  onDurationChange = emptyFunction,
}, ref) {
  const classes = useStyles();

  const isSelected = useMemo(() => {
    return new RegExp(`^${escapeRegex(BASE_STREAMING_URL)}/`, 'g').test(value);
  }, [value]);

  const [fileType, setFileType] = useState(isSelected
    ? new RegExp(`(${AUDIO_EXTENSIONS.join('|')})$`).test(value) ? FileType.AUDIO : FileType.VIDEO
    : null);

  const selectDialogRef = useRef();
  const inputDialogRef = useRef();

  const [inputVideo, setInputVideo] = useState({});

  useEffect(() => {
    setInputVideo(isSelected ? {} : { url: value, duration: duration });
  }, [isSelected, value, duration]);

  const handleSelect = useCallback((file) => {
    onChange(file.url);
    setFileType(file.Type);
    const videoDuration = Math.round(lodash.get(file, 'Meta.duration'));
    onDurationChange(isNaN(videoDuration) ? 0 : videoDuration);
  }, [onChange, onDurationChange]);

  const handleInput = useCallback((url, videoDuration) => {
    onChange(url);
    onDurationChange(videoDuration);
  }, [onChange, onDurationChange]);

  return (
    <div ref={ref} className={classes.container}>
      <FlexDiv className={classes.header}>
        <span>Podcast Url:</span>
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu onClick={handleClick}>
              <Menu.Item key={DropdownButton.SELECT}>Chọn từ thư viện</Menu.Item>
              <Menu.Item key={DropdownButton.INPUT}>Nhập đường dẫn</Menu.Item>
              {value && <Menu.Item key={DropdownButton.CLEAR}>Xóa</Menu.Item>}
            </Menu>
          )}
        >
          <Button
            title="Sửa Podcast"
            type="primary"
            shape="circle"
            size="small"
            icon="edit"
          />
        </Dropdown>
        <VideoAudioManagerDialog
          title="Chọn Podcast"
          ref={selectDialogRef}
          onSelect={handleSelect}
        />
        <ContentFormItemVideoUrlDialog
          ref={inputDialogRef}
          value={inputVideo}
          onDone={handleInput}
        />
      </FlexDiv>
      {value ? (isSelected ? (
        fileType === FileType.VIDEO ? (
          <VideoPlayer
            src={value}
            videoProps={{ alt: 'videoAvatar', preload: 'metadata' }}
          />
        ) : (
          <AudioPlayer src={value} audioProps={{ style: { width: '100%' } }} />
        )
      ) : (
        <iframe frameBorder="0" src={value} title="videoAvatar" height={300} />
      )) : (
        <Typography.Text type="danger"><i>Chưa có Podcast Url</i></Typography.Text>
      )}
    </div>
  );

  function handleClick(event) {
    if (event.key === DropdownButton.SELECT) {
      selectDialogRef.current.open();
    } else if (event.key === DropdownButton.INPUT) {
      inputDialogRef.current.open();
    } else if (event.key === DropdownButton.CLEAR) {
      handleClearVideoUrl();
    }
  }

  function handleClearVideoUrl() {
    onChange('');
    onDurationChange(0);
  }
});
