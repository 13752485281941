import BasePlugin from 'core/components/editor/plugins/BasePlugin';
import ZDocs, { Config } from '@zdocs/core';

export default class DeleteContentPlugin extends BasePlugin {
  static install() {
    Config.prototype.controls.deleteContent = {
      tooltip: 'Xóa nội dung',
      icon: 'deleteContent',
      exec: (zdocs, node) => {
        const currentBox = ZDocs.modules.Dom.closest(
          node,
          (element) => element && element.parentNode === zdocs.editor,
          zdocs.editor
        );

        if (node && currentBox) {
          currentBox.innerHTML = '<p><br></p>';
        }

        zdocs.events.fire('hidePopup');
      },
    };
  }

  get name() {
    return 'DeleteContentPlugin';
  }
}
