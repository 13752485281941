import { Icon, Input, Tag, Tooltip } from 'antd';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { emptyFunction } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import lodash from 'core/common/lodash';

const useStyles = makeStyles(() => ({
  newTag: {
    background: '#fff',
    borderStyle: 'dashed',
    marginRight: 0,
  },
  input: {
    width: 80,
  },
}));

export default forwardRef(function TagGroupInput({ value, onChange = emptyFunction }, ref) {
  const classes = useStyles();
  const inputRef = useRef();
  const [inputVisible, setInputVisible] = useState(false);
  const [tags, setTags] = useState([]);

  const handleRemove = useCallback(removedTag => {
    setTags(tags => {
      const newTags = tags.filter(tag => tag !== removedTag);

      onChange(newTags.join());

      return newTags;
    });
  }, [onChange]);

  const handleShowInput = useCallback(() => {
    setInputVisible(true);
  }, []);

  const handleInputConfirm = useCallback(() => {
    const inputValue = inputRef.current.state.value;

    if (inputValue) {
      const newTags = lodash.uniq([...inputValue.split(/[,;]/).map(lodash.trim).filter(Boolean), ...tags]);

      setTags(newTags);
      onChange(newTags.join());
    }

    inputRef.current.value = '';
    setInputVisible(false);
  }, [onChange, tags]);

  useEffect(() => {
    setTags((tags) => {
      if (value === tags.join()) {
        return tags;
      }

      if (!value) {
        return [];
      }

      return value.split(',').map(tag => String(tag).trim()).filter(Boolean);
    });
  }, [value]);

  return (
    <div ref={ref}>
      {tags.map((tag) => {
        const isLongTag = tag.length > 20;
        const tagElem = (
          <Tag key={tag} closable onClose={() => handleRemove(tag)}>
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        );

        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}

      {inputVisible && (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          className={classes.input}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
          autoFocus
        />
      )}

      {!inputVisible && (
        <Tag onClick={handleShowInput} className={classes.newTag}>
          <Icon type="plus" /> Thêm thẻ
        </Tag>
      )}
    </div>
  );
});
