import { IS_MOBILE } from '@bm/common';
import { Descriptions } from 'antd';
import { asyncAction } from 'core/common/async';
import { commonColumns, sortingToSortBy } from 'core/common/listUtils';
import AccountSelector from 'core/components/account/AccountSelector';
import AsyncButton from 'core/components/common/AsyncButton';
import Container from 'core/components/common/Container';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import FeatureFlip from 'core/components/common/FeatureFlip';
import FlexPushRight from 'core/components/common/FlexPushRight';
import NumberDisplay from 'core/components/common/NumberDisplay';
import RangeDateSelector from 'core/components/common/RangeDateSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import ContentFilterableAccount from 'core/components/content/ContentFilterableAccount';
import { ContentLink } from 'core/components/content/ContentLink';
import BaseTable from 'core/components/table/BaseTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneDisplay from 'core/components/zone/ZoneDisplay';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import { ContentSearchOptions } from 'core/engine/content/constants';
import reportApi from 'core/engine/report/api';
import { useElementRectWithDebounce } from 'core/hooks/useElementRect';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import useNodeRef from 'core/hooks/useNodeRef';
import useContentFilterHandlers from 'core/modules/contents/hooks/useContentFilterHandlers';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Sites } from 'core/engine/site/constants';
import ContentTypeSelector from 'core/components/content/ContentTypeSelector';
import useServerFeature from 'core/hooks/useServerFeature';
import TopicSelector from 'core/components/topic/TopicSelector';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';

const { Item } = Descriptions;

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.rangeDate[0] && filters.rangeDate[1]) {
    listFilters.startDate = filters.rangeDate[0].format('YYYYMMDD');
    listFilters.endDate = filters.rangeDate[1].format('YYYYMMDD');
    listFilters.siteId = filters.siteId;
  }

  delete listFilters.rangeDate;

  if (filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return reportApi.getContentTrending({
    offset,
    limit,
    filters: listFilters,
    sortBy: sortingToSortBy(sorting),
  });
}

export default function TrendingView({ siteId = Sites.ALL, filterByTopic = false }) {
  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: { ViewCount: 'DESC' },
    defaultFilters: {
      rangeDate: [moment(), moment()],
      search: { type: ContentSearchOptions[0].value },
      siteId: siteId,
    },
  });

  const hasComment = useServerFeature('has-comment', true);

  const filterHandlers = useContentFilterHandlers(listProps);
  const descriptionsRef = useNodeRef();
  const descriptionsHeight = useElementRectWithDebounce(descriptionsRef.current, 300).height;
  const onTopicFilter = useCreateOnFilterChange(listProps, 'TopicID');

  useEffect(listProps.refresh, []);

  const columns = [
    {
      Header: 'Tiêu đề',
      Cell: ({ original }) => (
        <ContentLink id={original.ContentID}>{original.Title}</ContentLink>
      ),
      minWidth: 300,
    },
    {
      Header: 'Chuyên mục',
      maxWidth: 200,
      minWidth: 130,
      Cell: ({ original }) => (
        <ZoneDisplay id={original.ZoneID} />
      ),
    },
    {
      Header: 'Người viết',
      maxWidth: 150,
      minWidth: 120,
      Cell: ({ original }) => (
        <ContentFilterableAccount
          id={original.WriterID}
          onClick={() => filterHandlers.onWriterFilter(original.WriterID)}
          title="Lọc tin tạo bởi tài khoản này"
        />
      ),
    },
    {
      Header: 'Ngày đăng',
      accessor: 'DistributionDate',
      Cell: ({ value }) => <DateTimeDisplay timestamp={value} />,
      style: { justifyContent: 'center' },
      width: 150,
      sortable: true,
    },
    {
      ...commonColumns.formatNumber,
      accessor: 'ViewCount',
      Header: 'Lượt xem',
      maxWidth: 130,
      minWidth: 80,
      sortable: true,
    },
    hasComment && {
      ...commonColumns.formatNumber,
      accessor: 'CommentCount',
      Header: 'Thảo luận',
      maxWidth: 130,
      minWidth: 80,
      sortable: true,
    },
  ].filter(Boolean);

  return (
    <Container pageTitle="Thống kê bài viết">
      <TableToolbarWrapper>
        <ZoneSelector
          allowClear
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
          siteId={siteId}
        />
        {filterByTopic &&
          <TopicSelector
            allowClear
            emptyLabel="-- Chủ đề --"
            value={listProps.filters.TopicID}
            onChange={onTopicFilter}
          />
        }
        <AccountSelector
          emptyLabel="-- Người tạo --"
          value={listProps.filters.WriterID}
          onChange={filterHandlers.onWriterFilter}
          allowClear
        />
        <ContentTypeSelector
          emptyLabel="-- Loại tin --"
          value={listProps.filters.ContentType}
          onChange={filterHandlers.onContentTypeFilter}
          allowClear
        />
        <RangeDateSelector
          value={listProps.filters.rangeDate}
          onChange={filterHandlers.onRangeDateFilter}
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={ContentSearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
        <FeatureFlip name="export-report">
          <AsyncButton
            icon="export"
            type="primary"
            onClick={handleExport}
            title="Xuất Excel"
          >
            Xuất Excel
          </AsyncButton>
        </FeatureFlip>
      </TableToolbarWrapper>
      <Descriptions bordered={true} ref={descriptionsRef}>
        <Item label="Số lượng"><NumberDisplay value={listProps.total} /></Item>
        <Item label="Tổng xem"><NumberDisplay value={listProps.extraData ? listProps.extraData.views : 0} /></Item>
        {hasComment && (
          <Item label="Tổng thảo luận"><NumberDisplay value={listProps.extraData ? listProps.extraData.comments : 0} /></Item>
        )}
      </Descriptions>
      <br />
      <BaseTable
        columns={columns}
        {...useListProps(listProps)}
        heightAdjust={63 + descriptionsHeight}
        fullHeight={!IS_MOBILE}
      />
    </Container>
  );

  function handleExport() {
    const listFilters = {
      group: listProps.filters.groupBy,
      startDate: listProps.filters.rangeDate[0].format('YYYYMMDD'),
      endDate: listProps.filters.rangeDate[1].format('YYYYMMDD'),
      siteId: listProps.filters.siteId,
    };

    return asyncAction('Xuất thống kê bài viết', () => {
      return reportApi.exportContentTrending({
        filters: listFilters,
        filename: `Thống kê bài viết từ ${listProps.filters.rangeDate[0].format('DD-MM-YYYY')} đến ${listProps.filters.rangeDate[1].format('DD-MM-YYYY')}.xlsx`,
      });
    });
  }
}
