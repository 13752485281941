import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import logger from 'core/common/logger';
import fileApi from 'core/engine/file/api';
import FileVideoAvatarPickerInVideo from './FileVideoAvatarPickerInVideo';
import VideoPosterSuggestionSelector from './VideoPosterSuggestionSelector';

export default function FileVideoAvatarPicker({ playableUrl, onPick }) {
  const [loadingSuggestion, setLoadingSuggestion] = useState(false);
  const [suggestionPosters, setSuggestionPosters] = useState([]);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    setLoadingSuggestion(true);
    setSuggestionPosters([]);

    fileApi.suggestVideoPosters(playableUrl)
      .then((resp) => {
        setSuggestionPosters(respToThumbUrl(resp));
        setLoadingSuggestion(false);
      })
      .catch((err) => {
        logger.error('Failed to load suggestion posters:', err);
        setSuggestionPosters([]);
        setLoadingSuggestion(false);
      });
  }, [playableUrl]);

  return (
    <div>
      <Divider>Ảnh từ video</Divider>
      <FileVideoAvatarPickerInVideo
        playableUrl={playableUrl}
        imageUrl={imageUrl}
        onPick={handlePick}
      />

      <Divider>Ảnh được đề xuất</Divider>
      <VideoPosterSuggestionSelector
        loading={loadingSuggestion}
        posters={suggestionPosters}
        onSelect={handlePick}
      />
    </div>
  );

  function handlePick(imageUrl) {
    setImageUrl(imageUrl);
    onPick(imageUrl);
  }

  function respToThumbUrl(resp) {
    return resp.thumbnails.map(thumbnail => {
      return { url: 'data:image/jpeg;base64,' + thumbnail.image_data };
    });
  }
}
