import { escapeHtml } from '@bm/common';
import remoteLogApi from 'core/engine/remote-log/api';
import striptags from 'striptags';
import BasePlugin from '../BasePlugin';
import { isHTML } from 'core/common/is';

export default class CustomPastePlugin extends BasePlugin {
  get name() {
    return 'CustomPastePlugin';
  }

  get defaultOptions() {
    return {
      trimHtml: false,
    };
  }

  constructor(zdocs) {
    super(zdocs);

    this.onBeforePaste = this.onBeforePaste.bind(this);
    this.onProcessPaste = this.onProcessPaste.bind(this);
    this.textToParagraph = this.textToParagraph.bind(this);

    zdocs.events.on('beforePaste', this.onBeforePaste);
    zdocs.events.on('processPaste', this.onProcessPaste);
  }

  onBeforePaste() {
  }

  onProcessPaste(evt, clipboardHtml, type) {
    remoteLogApi.logPasteEditor(clipboardHtml, type);

    if (this.options.trimHtml === 'all') {
      return striptags(clipboardHtml, [], ' ')
        .replace(/\s+/g, ' ')
        .replace(/(&nbsp;)+/ig, ' ');
    }

    return this.normalizeHTML(clipboardHtml, type);
  }

  normalizeHTML(html, type) {
    let convertedString;

    if (!html) {
      return html;
    }

    if (/text\/html/i.test(type)) {
      html = html.replace(/<div([^>]*)>/ig, '<p$1>');
      html = html.replace(/<\/div>/ig, '</p>');
      html = html.replace(/(alt|title|style)\s*=\s*('|")[^'"]*\2/ig, '');
      html = html.replace(/<(meta|span|abbr|font)([^>]*)>/ig, '');
      html = html.replace(/<\/(meta|span|abbr|font)>/ig, '');
      html = html.replace(/(&nbsp;)+/ig, ' ');

      if (!isHTML(html) && !/text\/rtf/i.test(type) && html.includes('\n')) {
        html = html.split('\n').map(text => `<p>${text}</p>`).join('');
      }

      html = html.replace(/\s+/g, ' ');

      this.log.debug(this.name, 'normalizeHTML | html ', html);

      const div = document.createElement('output');
      div.innerHTML = html;

      const marks = [];

      if (!div.firstChild) {
        return html;
      }

      this.Dom.all(div, node => {
        if (!node) {
          return;
        }

        if (this.Dom.isEmpty(node)) {
          return;
        }

        switch (node.nodeType) {
          case Node.ELEMENT_NODE:
            switch (node.nodeName) {
              case 'TITLE':
                marks.push(node);
                break;
              case 'B':
                this.Dom.replace(node, 'strong', false, false, this.zdocs.editorDocument);
                break;
              case 'I':
                this.Dom.replace(node, 'em', false, false, this.zdocs.editorDocument);
                break;
              case 'ARTICLE':
              case 'DIV':
              case 'FIGURE':
              case 'FIGCAPTION':
                this.Dom.replace(node, 'P', false, false, this.zdocs.editorDocument);
                break;
              case 'FONT':
              case 'STYLE':
              case 'ABBR':
                marks.push(node);
                break;
              case 'SPAN':
              case 'STRONGR':
              case 'STRONGODY':
              case 'STRONGBODY':
              case 'A':
                this.Dom.unwrap(node);
                break;
              case 'IMG':
                const fileRegex = /^file:\/\/\//g;

                if (fileRegex.test(node.href) || fileRegex.test(node.src)) {
                  this.Dom.unwrap(node);
                }

                break;
              default:
                break;
            }

            break;
          case Node.TEXT_NODE:
            break;
          default:
            marks.push(node);
            break;
        }
      });

      marks.forEach(this.Dom.safeRemove);

      const crNode = this.zdocs.selection.current();

      if (div.firstChild && div.firstChild.nodeName === 'P' && (crNode.nodeName === 'P' || crNode.parentNode.nodeName === 'P')) {
        this.insertHTMLToEditor(div.firstChild.innerHTML, false, false);
        div.removeChild(div.firstChild);
      }

      convertedString = div.innerHTML.trim();

      if (div.firstChild && div.firstChild === div.lastChild
        && !this.Dom.isImage(div.firstChild, this.zdocs.editorWindow)
        && !this.Dom.isBlock(div.firstChild, this.zdocs.editorWindow)) {
        convertedString = div.firstChild.innerHTML || div.firstChild;
      }
    } else if (/text\/plain/i.test(type)) {
      convertedString = this.textToParagraph(escapeHtml(html || ''));
    }

    return convertedString;
  }

  textToParagraph(text) {
    return text.split(/\r?\n/)
      .map((chunk) => {
        if (chunk.trim()) {
          return `<p>${chunk}</p>`;
        }

        return '<p></p>';
      })
      .join('\n');
  }
}
