import React, { useEffect, useState } from 'react';
import { getMapZoneCache } from 'core/engine/zone/caching';

export default function ZoneDisplay({ id, ...props }) {
  const [name, setName] = useState('Loading...');

  useEffect(() => {
    getMapZoneCache().then((map) => setName(map[id] ? map[id].Name : '#'));
  }, [id]);

  return (
    <span {...props}>{name}</span>
  );
}
