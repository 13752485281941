import BaseDialog from 'core/components/common/BaseDialog';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { getMapZoneCache } from 'core/engine/zone/caching';
import useDialog from 'core/hooks/useDialog';

export default forwardRef(function ZoneWidgetQuickInsertDialog({ onInsert, widget }, ref) {
  const [zoneIds, setZoneIds] = useState([]);
  const [zoneMap, setZoneMap] = useState();
  const { open, handleClose, handleOpen } = useDialog();

  useEffect(() => {
    getMapZoneCache().then(items => {
      setZoneMap(items);
    });
  }, []);

  useImperativeHandle(ref, () => ({
    close: handleCleanClose,
    open: handleOpen,
  }));

  return (
    <BaseDialog
      maskClosable={true}
      keyboard={true}
      title="Thêm chuyên mục"
      width={500}
      visible={open}
      onCancel={handleCleanClose}
      onOk={handleInsert}
      okText="Thêm"
    >
      <ZoneSelector
        placeholder="Chọn chuyên mục"
        value={zoneIds}
        onChange={setZoneIds}
        mode="multiple"
        allowClear
      />
    </BaseDialog>
  );

  function handleInsert() {
    onInsert(zoneIds.map((zoneId) => ({
      Title: '[Chuyên mục] ' + zoneMap[zoneId].Name,
      Type: widget.type,
      PreDefined: false,
      Meta: JSON.stringify({ ZoneID: zoneId }),
    })));
    handleCleanClose();
  }

  function handleCleanClose() {
    setZoneIds([]);
    handleClose();
  }
});
