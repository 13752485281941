import { IS_MOBILE } from '@bm/common';
import React, { Fragment, useMemo } from 'react';
import { commonColumns } from 'core/common/listUtils';
import AccountDisplay from '../components/account/AccountDisplay';
import DateTimeDisplay from '../components/common/DateTimeDisplay';
import ContentAvatar from '../components/content/ContentAvatar';
import ContentCheckinDisplay from '../components/content/ContentCheckinDisplay';
import ContentStatusDisplay from '../components/content/ContentStatusDisplay';
import ContentTitleDisplayForList from '../components/content/ContentTitleDisplayForList';
import ContentTypeDisplay from '../components/content/ContentTypeDisplay';
import ContentViewCountForList from 'core/components/content/ContentViewCountForList';
import useAuth from 'core/hooks/useAuth';
import { ExtendedRight } from 'core/engine/extended-right/constants';
import useServerFeature from 'core/hooks/useServerFeature';
import { CONTENT_ALWAYS_SHOW_AVATAR, CONTENT_RATE_TYPE } from 'core/config';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import { blue, red, yellow } from '@ant-design/colors';
import { Icon } from 'antd';
import NumberDisplay from 'core/components/common/NumberDisplay';
import ContentDisplayTypeDisplay from 'core/components/content/ContentDisplayTypeDisplay';
import { ContentRateType } from 'core/engine/content/constants';

const useStyles = makeStyles(() => ({
  textRight: {
    textAlign: 'right',
  },
  ctr: {
    color: '#757171',
  },
  like: {
    color: blue[5],
  },
  dislike: {
    color: red[5],
  },
  star: {
    color: yellow[5],
  },
}));

export default function useContentColumns() {
  const classes = useStyles();
  const { loggedInUser, hasExtendedRight } = useAuth();
  const hasAuthzViewCount = useServerFeature('authz-view-count');

  const showViewCount = useMemo(() => {
    return hasAuthzViewCount ? (
      loggedInUser.isAdmin || hasExtendedRight(ExtendedRight.READ_VIEW_COUNT)
    ) : true;
  }, [hasAuthzViewCount, loggedInUser, hasExtendedRight]);

  const showContentRate = useMemo(() => {
    return loggedInUser.isAdmin || hasExtendedRight(ExtendedRight.READ_CONTENT_RATE);
  }, [loggedInUser, hasExtendedRight]);

  return {
    ContentID: {
      ...commonColumns.allCentered,
      Header: 'ID',
      width: 60,
      Cell: ({ original }) => original.ContentID,
    },
    Avatar: {
      ...commonColumns.allCentered,
      show: CONTENT_ALWAYS_SHOW_AVATAR || !IS_MOBILE,
      Header: 'Avatar',
      width: 64,
      Cell: ({ original }) => (<ContentAvatar content={original} />),
    },
    Title: {
      Header: 'Tiêu đề',
      Cell: ({ original }) => (
        <ContentTitleDisplayForList content={original} />
      ),
      minWidth: 225,
    },
    ViewCount: {
      ...commonColumns.formatNumber,
      show: showViewCount,
      Header: 'Xem',
      accessor: 'ViewCount',
      width: 80,
      Cell: ({ original }) => (
        <ContentViewCountForList content={original} />
      ),
    },
    DistributionDate: {
      Header: 'Đăng lúc',
      width: 120,
      Cell: ({ original }) => <DateTimeDisplay timestamp={original.DistributionDate} />,
    },
    CheckIn: {
      ...commonColumns.allCentered,
      Header: 'Check-in',
      width: 80,
      Cell: ({ original }) => (
        <ContentCheckinDisplay checkinId={original.CheckinID} />
      ),
    },
    Status: {
      ...commonColumns.allCentered,
      Header: 'Trạng thái',
      width: 125,
      Cell: ({ original }) => <ContentStatusDisplay content={original} />,
    },
    Writer: {
      Header: 'Người tạo',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <AccountDisplay id={original.WriterID} />
          <br />
          <i><DateTimeDisplay timestamp={original.CreatedDate} /></i>
        </div>
      ),
    },
    Editor: {
      Header: 'Người sửa',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <AccountDisplay id={original.EditorID} />
          <br />
          <i><DateTimeDisplay timestamp={original.ModifiedDate} /></i>
        </div>
      ),
    },
    Approver: {
      Header: 'Người duyệt',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <AccountDisplay id={original.ApproverID} />
          <br />
          <i><DateTimeDisplay timestamp={original.ApprovalDate} /></i>
        </div>
      ),
    },
    Deployer: {
      Header: 'Người đăng',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <AccountDisplay id={original.DeployerID} />
          <br />
          <i><DateTimeDisplay timestamp={original.DistributionDate} /></i>
        </div>
      ),
    },
    Owner: {
      Header: 'Người giữ',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <AccountDisplay id={original.OwnerID} />
        </div>
      ),
    },
    ContentType: {
      Header: 'Loại tin',
      width: 80,
      Cell: ({ original }) => (
        <ContentTypeDisplay type={original.ContentType} />
      ),
    },
    DisplayType: {
      Header: 'Kiểu hiển thị',
      width: 80,
      Cell: ({ original }) => (
        <ContentDisplayTypeDisplay type={original.DisplayType} />
      ),
    },
    CTR: {
      Header: 'CTR',
      accessor: 'CTRStats',
      show: showViewCount,
      headerStyle: { justifyContent: 'right' },
      style: { justifyContent: 'flex-end' },
      Cell: ({ value }) => {
        const click = Number(lodash.get(value, '0.Click', 0));
        const imps = Number(lodash.get(value, '0.Imps', 0));
        const ctr = (100 * click / imps).toFixed(2);

        return (
          <div title={`Lượt click: ${click}. Lượt hiển thị: ${imps}`} className={classes.textRight}>
            <span>{click} / {imps}</span>
            {click !== 0 && imps !== 0 && (
              <Fragment>
                <br />
                <span className={classes.ctr}><i><b>{ctr} %</b></i></span>
              </Fragment>
            )}
          </div>
        );
      },
      width: 80,
    },
    Rate: {
      Header: 'Tương tác',
      accessor: 'ContentRate',
      show: showContentRate,
      Cell: ({ value }) => {
        const rate = getRate(value, CONTENT_RATE_TYPE);
        const likeRate = getRate(value, ContentRateType.LIKE);

        switch (CONTENT_RATE_TYPE) {
          case ContentRateType.VOTE:
            return (
              <span>
                <Icon type="star" theme="filled" className={classes.star} />&nbsp;
                <NumberDisplay value={(rate.Count ? (rate.Score / rate.Count) : 0).toFixed(1)} />&nbsp;
                (<NumberDisplay value={rate.Count} />)
              </span>
            );
          case ContentRateType.LIKE:
            return (
              <span>
                <Icon type="like" className={classes.like} />&nbsp;&nbsp;
                <NumberDisplay value={rate.Count} />
              </span>
            );
          case ContentRateType.DISLIKE:
            return (
              <span>
                <Icon type="like" className={classes.like} />&nbsp;&nbsp;
                <NumberDisplay value={likeRate.Count} /><br />
                <Icon type="dislike" className={classes.dislike} />&nbsp;&nbsp;
                <NumberDisplay value={rate.Count} />
              </span>
            );
          default:
            return null;
        }
      },
      width: 95,
    },
  };

  function getRate(rates, rateType) {
    const listRate = (rates || []).filter(rate => rate.Type === rateType);
    return listRate.length ? listRate[0] : { Score: 0, Count: 0 };
  }
}
