import { Config } from '@zdocs/core';
import BaseDialogPlugin from 'core/components/editor/plugins/BaseDialogPlugin';
import { normalizeEditorValue } from 'core/common/editor';

export default class ReformatPlugin extends BaseDialogPlugin {

  static install() {
    Config.prototype.controls.reformat = {
      tooltip: 'Chuẩn hóa định dạng',
      icon: 'copyformat',
    };
  }

  get name() {
    return 'ReformatPlugin';
  }

  constructor(zdocs) {
    super(zdocs);

    this.callback = this.callback.bind(this);

    zdocs.registerCommand('reformat', this.callback);
  }

  callback() {
    this.zdocs.setEditorValue(normalizeEditorValue(this.zdocs.getEditorValue()));
  }

}
