import { useEffect, useState } from 'react';
import useAuth from 'core/hooks/useAuth';
import interviewServices from '../services';
import interviewApi from 'core/engine/interview/api';
import { message } from 'antd';

export default function useInterviewLoader(interviewId) {
  const { loggedInUser } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [interview, setInterview] = useState({});

  useEffect(() => {
    setIsLoading(true);

    interviewApi.get(interviewId)
      .then((resp) => {
        const newInterview = resp.data.item;

        newInterview.isAdmin = interviewServices.isAdmin(newInterview, loggedInUser);
        newInterview.isEditor = interviewServices.isEditor(newInterview, loggedInUser);

        setInterview(newInterview);
      })
      .catch(() => {
        message.error('Lấy dữ liệu giao lưu trực tuyến thất bại!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [interviewId, loggedInUser]);

  return { interview, isLoading };
}
