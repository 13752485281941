import { Checkbox, Button } from 'antd';
import React from 'react';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DeleteButton from 'core/components/common/DeleteButton';
import { OnEnterFilterForList } from 'core/components/common/OnEnterFilter';
import BaseTable from 'core/components/table/BaseTable';
import GroupCommandFormDialogButton from './GroupCommandFormDialogButton';
import GroupFormDialogButton from './GroupFormDialogButton';
import { commonColumns } from 'core/common/listUtils';

export default function GroupList({ onUpdate, onDelete, ...props }) {
  const columns = [
    {
      Header: 'ID',
      accessor: 'GroupID',
      maxWidth: 80,
      minWidth: 50,
      filterable: true,
      Filter: OnEnterFilterForList,
      headerStyle: { justifyContent: 'center' },
      style: { justifyContent: 'center' },
    },
    {
      Header: 'Nhóm',
      accessor: 'Name',
      filterable: true,
      Filter: OnEnterFilterForList,
    },
    {
      Header: 'Tên hiệu',
      accessor: 'Alias',
      filterable: true,
      Filter: OnEnterFilterForList,
      minWidth: 150,
    },
    {
      Header: 'Phân quyền theo mục',
      accessor: 'CustomByZone',
      Cell: ({ original }) => (<Checkbox checked={original.CustomByZone} />),
      width: 150,
      ...commonColumns.allCentered,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <GroupCommandFormDialogButton group={original} />
          <GroupFormDialogButton group={original} onDone={onUpdate} />
          {
            original.GroupID > 3
              ?
              <DeleteButton title={`Xóa nhóm: ${original.Name}?`} onDelete={() => onDelete(original)} />
              :
              <Button icon="delete" disabled/>
          }
        </ActionsCellWrapper>
      ),
      maxWidth: 150,
    }];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
