import { Checkbox } from 'antd';
import { asyncAction } from 'core/common/async';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import AsyncButton from 'core/components/common/AsyncButton';
import Container from 'core/components/common/Container';
import FeatureFlip from 'core/components/common/FeatureFlip';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateWithTypeSelector from 'core/components/common/RangeDateWithTypeSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import contentApi from 'core/engine/content/api';
import { ContentListType, ContentSearchOptions } from 'core/engine/content/constants';
import { GroupRoleUp } from 'core/engine/group/constants';
import { getListEnabledZoneByAccountCache } from 'core/engine/zone/caching';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useContentConfig from '../../hooks/useContentConfig';
import useContentFilterHandlers from '../../hooks/useContentFilterHandlers';
import { handleLimitSortRangeDateChange, handleSortedChangeByRangeDate } from '../../services';
import ContentSelectionToolbar from '../ContentSelectionToolbar';
import ContentDeployList from './ContentDeployedList';
import AccountSelector from 'core/components/account/AccountSelector';
import { CONTENT_LIST_CTR_STATS, CONTENT_LIST_FILTER_PARENT_AUTHOR, CONTENT_RATE_TYPE } from 'core/config';
import AuthorIdSelector from 'core/components/author/AuthorIdSelector';
import ContentDisplayTypeSelector from 'core/components/content/ContentDisplayTypeSelector';
import ContentTypeSelector from 'core/components/content/ContentTypeSelector';

const GET_FIELDS = ['EditorID', 'ModifiedDate', 'DeployerID', 'CreatedDate', 'ViewCount', 'Order', 'GAViewCount'];

const defaultRangeDate = [null, null];

const useStyles = makeStyles(() => ({
  checkbox: {
    minWidth: 120,
    marginTop: 5,
  },
}));

function listFn({ limit, offset, filters, sorting }) {
  const lookup = ['ZOAContents.ContentID'];

  if (CONTENT_LIST_CTR_STATS) {
    lookup.push('CTRStats.ContentID', 'CTRStats.Click', 'CTRStats.Imps');
  }

  if (CONTENT_RATE_TYPE) {
    lookup.push('ContentRate.ContentID', 'ContentRate.Type', 'ContentRate.Score', 'ContentRate.Count');
  }

  return contentApi.getList({
    limit,
    offset,
    fields: GET_FIELDS,
    lookup: lookup,
    sortBy: buildSorting(sorting, filters),
    filters: buildFilter(filters),
  });
}

function buildFilter(filters) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;

  if (rangeDate.range[0] && rangeDate.range[1]) {
    listFilters[rangeDate.type] = dateRangeToFilter(rangeDate.range);
  }

  if (listFilters.isHideScheduleContent) {
    const now = moment().format('x');

    if (rangeDate.type === 'DistributionDate' && rangeDate.range[0] && rangeDate.range[1]) {
      const from = rangeDate.range[0].startOf('day').format('x');
      const to = rangeDate.range[1].endOf('day').format('x') - 1;

      listFilters.DistributionDate = 'bw:' + from + ',' + (now < to ? now : to);
    } else {
      listFilters.DistributionDate = 'lte:' + now;
    }
  }

  delete listFilters.rangeDate;

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return listFilters;
}

function buildSorting(sorting, filters) {
  const rangeDate = filters.rangeDate;
  let listSorting = { ...sorting, [rangeDate.type]: rangeDate.desc ? 'DESC' : 'ASC' };

  if (filters.ZoneID && lodash.isEmpty(sorting)) {
    listSorting = { Order: 'ASC', ...listSorting };
  }

  return sortingToSortBy(listSorting);
}

export default function ContentDeployedListView() {
  const classes = useStyles();
  const { deployedListDefaultDateOrder } = useContentConfig();

  const listProps = useList({
    listId: 'ContentDeployedListView',
    listFn,
    defaultFilters: {
      search: { type: ContentSearchOptions[0].value },
      rangeDate: { type: deployedListDefaultDateOrder || 'DistributionDate', range: defaultRangeDate, desc: true },
      listType: ContentListType.DEPLOYED,
    },
  });
  const filterHandlers = useContentFilterHandlers(listProps);
  const handleFilterIsHideScheduleContent = useCreateOnFilterChange(listProps, 'isHideScheduleContent');
  const handleFilterContentDisplayType = useCreateOnFilterChange(listProps, 'DisplayType');

  const [selectedIds, setSelectedIds] = useState([]);

  const [deployZoneIds, setDeployZoneIds] = useState([]);

  useEffect(() => {
    getListEnabledZoneByAccountCache(GroupRoleUp.DEPLOYER)
      .then(zone => setDeployZoneIds(zone.map(zone => zone.ZoneID)));
  }, []);

  useEffect(listProps.refresh, []);

  let toolbar;

  if (selectedIds.length) {
    toolbar = (
      <ContentSelectionToolbar
        selectedIds={selectedIds}
        contents={listProps.items}
        onClear={handleClearSelectedContents}
        onCheckout={handleCheckoutSelectedContents}
        onUnpublish={handleUnpublishSelectedContents}
        onUnlockEdit={handleUnlockEditSelectedContents}
      />
    );
  } else {
    toolbar = (
      <>
        <TableToolbarWrapper>
          <ZoneSelector
            allowClear
            roles={GroupRoleUp.APPROVER}
            emptyLabel="-- Chuyên mục --"
            value={listProps.filters.ZoneID}
            onChange={filterHandlers.onZoneFilter}
            width={200}
          />
          <ContentTypeSelector
            emptyLabel="-- Loại tin --"
            value={listProps.filters.ContentType}
            onChange={filterHandlers.onContentTypeFilter}
            width={200}
            allowClear
          />
          <ContentDisplayTypeSelector
            value={listProps.filters.DisplayType}
            onChange={handleFilterContentDisplayType}
            emptyLabel="-- Kiểu hiển thị --"
            allowClear
          />
          <RangeDateWithTypeSelector
            value={listProps.filters.rangeDate}
            options={{ CreatedDate: 'Ngày tạo', ModifiedDate: 'Ngày sửa', DistributionDate: 'Ngày đăng' }}
            onChange={(rangeDate) => handleLimitSortRangeDateChange(rangeDate, listProps)}
          />
          <Checkbox
            checked={listProps.filters.isHideScheduleContent}
            onChange={(event) => handleFilterIsHideScheduleContent(event.target.checked)}
            className={classes.checkbox}
          >
            Ẩn tin hẹn giờ
          </Checkbox>
          <FlexPushRight />
          <FeatureFlip name="content-deployedlist-export">
            <AsyncButton
              icon="export"
              type="primary"
              onClick={handleExport}
              title="Xuất Excel"
            >
              Xuất Excel
            </AsyncButton>
          </FeatureFlip>
        </TableToolbarWrapper>
        <TableToolbarWrapper>
          <AccountSelector
            width={200}
            emptyLabel="-- Người tạo --"
            value={listProps.filters.WriterID}
            onChange={filterHandlers.onWriterFilter}
            allowClear
          />
          <AccountSelector
            width={200}
            emptyLabel="-- Người đăng --"
            value={listProps.filters.DeployerID}
            onChange={filterHandlers.onDeployerFilter}
            allowClear
          />
          <AccountSelector
            width={200}
            emptyLabel="-- Người sửa --"
            value={listProps.filters.EditorID}
            onChange={filterHandlers.onEditorFilter}
            allowClear
          />
          {CONTENT_LIST_FILTER_PARENT_AUTHOR &&
            <AuthorIdSelector
              width={200}
              emptyValue={null}
              value={listProps.filters.AuthorID}
              onChange={value => listProps.addFilter('AuthorID', value)}
              parentOnly
            />
          }
          <FlexPushRight />
          <SearchFieldWithType
            typeOptions={ContentSearchOptions}
            search={listProps.filters.search}
            onSearch={filterHandlers.onSearchFilter}
          />
        </TableToolbarWrapper>
      </>
    );
  }

  return (
    <Container pageTitle="Tin đã đăng">
      {toolbar}
      <ContentDeployList
        {...useListProps(listProps)}
        selectedIds={selectedIds}
        onSelectedChange={setSelectedIds}
        onEditorSelected={filterHandlers.onEditorFilter}
        onWriterSelected={filterHandlers.onWriterFilter}
        onDeployerSelected={filterHandlers.onDeployerFilter}
        onContentLevelChange={onContentLevelChange}
        onSortedChange={(sorting) => handleSortedChangeByRangeDate(sorting, listProps)}
        onPushZOA={handlePushZOA}
        heightAdjust={84}
        filteredZoneId={listProps.filters.ZoneID}
        deployZoneIds={deployZoneIds}
      />
    </Container>
  );

  function handleClearSelectedContents() {
    setSelectedIds([]);
  }

  function handleCheckoutSelectedContents() {
    return asyncAction('Đóng các tin đã chọn', () => {
      return contentApi.multiCheckout(selectedIds)
        .then(() => {
          setSelectedIds([]);
          listProps.refresh();
        });
    });
  }

  function handleUnpublishSelectedContents(contentNote) {
    return asyncAction('Gỡ các tin đã chọn', () => {
      return contentApi.multiUnpublish(selectedIds, contentNote)
        .then(() => {
          setSelectedIds([]);
          listProps.refresh();
        });
    });
  }

  function handleUnlockEditSelectedContents() {
    return asyncAction('Mở khóa các tin đã chọn', () => {
      return contentApi.multiUnlockEdit(selectedIds)
        .then(() => {
          setSelectedIds([]);
          listProps.refresh();
        });
    });
  }

  function onContentLevelChange(contentId, zoneId, level) {
    return asyncAction('Sửa cấp độ tin', () => {
      return contentApi.updateLevel(contentId, zoneId, level).then(listProps.refresh);
    });
  }

  function handlePushZOA(contentId) {
    return asyncAction('Đẩy tin lên ZOA', () => {
      return contentApi.pushZOA(contentId).then(listProps.refresh);
    });
  }

  function handleExport() {
    const rangeDate = listProps.filters.rangeDate.range;
    const filename = rangeDate[0] && rangeDate[1]
      ? `Danh sách tin đã đăng từ ${rangeDate[0].format('DD-MM-YYYY')} đến ${rangeDate[1].format('DD-MM-YYYY')}.xlsx`
      : 'Danh sách tin đã đăng.xlsx';

    return asyncAction('Xuất danh sách tin đã đăng', () => {
      return contentApi.exportContentList({
        filters: buildFilter(listProps.filters),
        sortBy: buildSorting(listProps.sorting, listProps.filters),
        fields: ['ContentID', 'Title', 'ZoneID', 'DistributionDate', 'ViewCount', 'WriterID', 'DeployerID', 'ContentType', 'Source', 'SourceUrl'],
        lookup: ['ContentAuthor.ContentID', 'ContentAuthor.AuthorName', 'ContentNote.ContentID', 'ContentNote.CreatorID', 'ContentNote.Note'],
        filename,
      });
    });
  }
}
