import ZDocs, { Config } from '@zdocs/core';
import BasePlugin from '../BasePlugin';

export default class NewLinePlugin extends BasePlugin {
  static install() {
    Config.prototype.controls.newline = {
      tooltip: 'Thêm dòng mới',
      icon: 'enter',
      list: Object.values(this.Position),
      exec: this.addNewLine,
    };
  }

  get name() {
    return 'NewLinePlugin';
  }

  static addNewLine(zdocs, node, control) {
    if (!control) {
      return;
    }

    const command = control.args ? (typeof control.args[1] === 'string' ? control.args[1] : '') : control;

    const crNode = node || zdocs.selection.current();
    const Dom = ZDocs.modules.Dom;

    const currentBlock = Dom.closest(
      crNode,
      (element) => element && element.parentNode === zdocs.editor,
      zdocs.editor
    );

    if (currentBlock) {
      if (command === NewLinePlugin.Position.TOP) {
        zdocs.selection.setCursorBefore(currentBlock);
      } else {
        zdocs.selection.setCursorAfter(currentBlock);
      }

      const newNode = zdocs.create.inside.element(zdocs.options.enter);

      zdocs.selection.insertHTML(newNode);
      zdocs.selection.setCursorIn(newNode);

      BasePlugin.cleanAfterSetCursor(zdocs, Dom);
    }

    zdocs.events.fire('hidePopup');
  }

  static Position = {
    TOP: 'Thêm dòng trên',
    BOTTOM: 'Thêm dòng dưới',
  };
}
