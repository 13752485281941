import { Button, Typography } from 'antd';
import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import { arrayShallowEqual } from 'core/common/arrayHelper';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import BaseDialog from 'core/components/common/BaseDialog';
import FlexDiv from 'core/components/common/FlexDiv';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import ContentViewLink from 'core/components/content/ContentViewLink';
import contentApi from 'core/engine/content/api';
import useDialog from 'core/hooks/useDialog';
import RelatedContentsSelector from './RelatedContentsSelector';
import { Sites } from 'core/engine/site/constants';

const ID_FIELD = 'ContentID';

const useStyles = makeStyles(() => ({
  list: {
    paddingLeft: 20,

    '& > li': {
      lineHeight: 'initial',
    },

    '& > li:not(:last-child)': {
      marginBottom: 5,
    },
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default forwardRef(function ContentFormRelatedContentsInput({ value, onChange, getSuggestedContent, siteId = Sites.ALL }, ref) {
  const classes = useStyles();
  const { open, handleClose, handleOpen } = useDialog();
  const [displayItems, setDisplayItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [modifyingItems, setModifyingItems] = useState([]);

  useEffect(() => {
    if (value) {
      const ids = value.split(',').map(item => parseInt(item));
      const sortedIds = ids.slice().sort();

      if (arrayShallowEqual(sortedIds, modifyingItems.map(c => c[ID_FIELD]).sort())) {
        setDisplayItems(modifyingItems);
        return;
      }

      setIsLoading(true);
      contentApi.getDeployedList({
        limit: ids.length,
        filters: {
          [ID_FIELD]: `in:${value}`,
        },
      })
        .then(resp => {
          setDisplayItems(lodash.sortBy(resp.data.items, item => ids.indexOf(item[ID_FIELD]))); // sort items to correct order because server result is not following original order
          setIsLoading(false);
        });
    } else {
      setDisplayItems([]);
    }
  }, [value]);

  useEffect(() => {
    if (open) {
      setModifyingItems(displayItems);
    }
  }, [displayItems, open]);

  return (
    <Fragment>
      <FlexDiv className={classes.header}>
        <span ref={ref}>Tin liên quan:</span>
        <Button title="Sửa tin liên quan" type="primary" shape="circle" size="small" icon="edit" onClick={handleOpen} />
      </FlexDiv>
      <LoadingWrapper loading={isLoading} center>
        {displayItems.length > 0 ?
          <ul className={classes.list}>
            {displayItems.map(item => (
              <li key={item[ID_FIELD]}>
                <ContentViewLink content={item}>{item.Title}</ContentViewLink>
              </li>
            ))}
          </ul> :
          <Typography.Text type="danger"><i>Chưa có tin liên quan</i></Typography.Text>
        }
      </LoadingWrapper>
      <BaseDialog
        fullHeightFixed
        noVerticalPadding
        visible={open}
        title={null}
        onOk={onOk}
        onCancel={handleClose}
        width={900}
      >
        <RelatedContentsSelector
          value={modifyingItems}
          onChange={setModifyingItems}
          getSuggestedContent={getSuggestedContent}
          siteId={siteId}
        />
      </BaseDialog>
    </Fragment>
  );

  function onOk() {
    onChange(modifyingItems.map(c => c[ID_FIELD]).join());
    handleClose();
  }
});
