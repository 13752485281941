import topicApi from 'core/engine/topic/api';
import useDebounce from 'core/hooks/useDebounce';
import React, { forwardRef, useEffect, useState } from 'react';
import Selector from '../common/Selector';

function getTopics(limit, type, search) {
  return topicApi.getList({
    limit,
    offset: 0,
    filters: { Enabled: true, Type: type, Name: search },
  });
}

export default forwardRef(function TopicSelector({
  value,
  onChange,
  topicType,
  defaultSize = 500,
  defaultSearchSize = 50,
  ...props
}, ref) {
  const [options, setOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    getTopics(defaultSize, topicType).then((resp) => {
      setDefaultOptions(resp.data.items);
    });
  }, [topicType, defaultSize]);

  useEffect(() => {
    if (debouncedSearch) {
      getTopics(defaultSearchSize, topicType, debouncedSearch)
        .then((resp) => {
          if (resp.data && resp.data.items) {
            setOptions(resp.data.items);
          } else {
            setOptions([]);
          }
        });
    } else {
      setOptions([]);
    }
  }, [debouncedSearch, topicType, defaultSearchSize]);

  useEffect(() => {
    if (value > 0 && defaultOptions.length && !defaultOptions.find((option) => option.TopicID === parseInt(value))) {
      topicApi.get(value).then(resp => {
        setDefaultOptions([resp.data.item, ...defaultOptions]);
      });
    }
  }, [value, defaultOptions]);

  return (
    <Selector
      ref={ref}
      showSearch
      autoClearSearchValue
      value={value && typeof value === 'string' ? parseInt(value) : value}
      onSearch={handleSearch}
      onChange={onChange}
      onBlur={onBlur}
      filterOption={false}
      emptyLabel="-- Chọn chủ đề --"
      options={search ? options : defaultOptions}
      valueField="TopicID"
      labelField="Name"
      {...props}
    />
  );

  function handleSearch(value) {
    setSearch(value);
  }

  function onBlur() {
    setSearch('');
  }
});
