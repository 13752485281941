import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const defaultStyle = {
  maxWidth: 300,
  minWidth: 220,
};

const defaultPlaceholder = ['Từ ngày', 'Đến ngày'];

const rangePresets = {
  '3 ngày trước  ': [moment().add(-3, 'day').startOf('day'), moment().endOf('day')],
  '1 tuần trước': [moment().add(-1, 'week').startOf('day'), moment().endOf('day')],
  '1 tháng trước': [moment().add(-1, 'month').startOf('day'), moment().endOf('day')],
  '3 tháng trước': [moment().add(-3, 'month').startOf('day'), moment().endOf('day')],
};

export default function RangeDateSelector({
  value,
  onChange,
  format = 'DD/MM/YYYY',
  showTime = false,
  maxPastDays = 0,
  defaultValue,
  style,
  className,
  placeholder = defaultPlaceholder,
  alignedBy, // day, week, month, year
  ...props
}) {

  const [clearValue, setClearValue] = useState([]);

  const lastPastDayMoment = useMemo(() => {
    if (maxPastDays) {
      return moment().add(-maxPastDays, 'days');
    }

    return null;
  }, [maxPastDays]);

  const alignRange = useCallback((value) => {
    if (alignedBy) {
      return [value[0].startOf(alignedBy), value[1].endOf(alignedBy)];
    }

    return value;
  }, [alignedBy]);

  const disabledDate = useCallback((currentDate) => {
    if (!lastPastDayMoment) return false;
    return lastPastDayMoment.isAfter(currentDate, 'day');
  }, [lastPastDayMoment]);

  useEffect(() => {
    if (defaultValue) {
      setClearValue(defaultValue);
    } else if (!clearValue.length && value) {
      setClearValue(value);
    }
  }, [value]);

  return (
    <RangePicker
      defaultValue={defaultValue}
      onChange={(value) => {
        onChange(value.length ? alignRange(value) : clearValue);
      }}
      value={value}
      format={format}
      showTime={showTime}
      style={style || defaultStyle}
      className={className}
      disabledDate={disabledDate}
      placeholder={placeholder}
      ranges={rangePresets}
      {...props}
    />
  );
}
