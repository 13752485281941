import React, { useState, useEffect, forwardRef, useMemo } from 'react';
import { Select } from 'antd';
import commandApi from 'core/engine/command/api';
import { buildTreeList } from 'core/common/utils';

const { Option } = Select;

export default forwardRef(function CommandSelector({
  value,
  onChange,
  allowEmpty,
  extendItems = [],
  excludedIds = [],
  excludeDescendant = true,
  ...props
}, ref) {
  const [commands, setCommands] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      loadCommands();
    }
  }, [open]);

  useEffect(loadCommands, []);

  function loadCommands() {
    setCommands([]);
    setIsLoading(true);

    commandApi.getListAll()
      .then((resp) => {
        return buildTreeList(resp.data.items, { idField: 'CommandID' });
      })
      .then(setCommands)
      .then(() => setIsLoading(false));
  }

  const displayCommands = useMemo(() => {
    let descendantIds = [];

    if (excludeDescendant) {
      excludedIds.forEach((id) => {
        descendantIds.push(id);
        descendantIds.push(...getDescendantIds(commands, id));
      });
    } else {
      descendantIds = excludedIds;
    }

    return commands.filter(command => descendantIds.indexOf(command.CommandID) === -1);
  }, [commands, excludedIds]);

  return (
    <Select
      ref={ref}
      showSearch
      onChange={value => onChange(parseInt(value))}
      style={{ width: '100%' }}
      value={value}
      open={open}
      onDropdownVisibleChange={setOpen}
      loading={isLoading}
      optionFilterProp="children"
      {...props}
    >
      {allowEmpty && <Option key="0" value={0} style={{ fontStyle: 'italic' }}>-- None --</Option>}
      {extendItems.map((item) => (
        <Option key={item.value} value={item.value}>{item.text}</Option>
      ))}

      {displayCommands.map((command) => (
        <Option key={command.CommandID} value={command.CommandID}>{' ---- '.repeat(command.Level) + command.Name}</Option>
      ))}
    </Select>
  );
});

function getDescendantIds(commands, commandId) {
  const descendantIds = [];
  const toScanIds = [commandId];

  while (toScanIds.length > 0) {
    const currentId = toScanIds.shift();
    const childrenIds = commands.filter(command => command.ParentID === currentId).map(child => child.CommandID);

    descendantIds.push(...childrenIds);
    toScanIds.push(...childrenIds);
  }

  return descendantIds;
}
