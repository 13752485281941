import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import { CONTENT_CLASS } from 'core/constants';
import React, { memo } from 'react';
import HtmlDisplay from 'core/components/common/HtmlDisplay';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    ...commonStyles.flexRow,
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  detail: {
    fontSize: 16,
    color: '#222',
  },
}));

function LiveStreamDisplay({ liveStream }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={CONTENT_CLASS}>
        <div className={classes.title}>
          {liveStream.Title}
        </div>
        <HtmlDisplay className={classes.detail} html={liveStream.HtmlDetail} />
      </div>
    </div>
  );
}

export default memo(LiveStreamDisplay);
