import { Button, message } from 'antd';
import lodash from 'core/common/lodash';
import logger from 'core/common/logger';
import BaseDialog from 'core/components/common/BaseDialog';
import voteOptionApi from 'core/engine/vote-option/api';
import voteApi from 'core/engine/vote/api';
import useAsyncAction from 'core/hooks/useAsyncAction';
import useDialog from 'core/hooks/useDialog';
import React, { Fragment, useEffect, useState } from 'react';
import VoteOptionList from './VoteOptionList';

export default function VoteOptionListDialogButton({ vote = {} }) {

  const { open, handleOpen, handleClose } = useDialog();
  const { isLoading, performAction } = useAsyncAction();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!open) return;

    voteOptionApi.getList({
      limit: 100,
      filters: { VoteID: vote.VoteID },
      sortBy: 'Order:ASC',
    })
      .then((resp) => {
        setOptions(resp.data.items);
      })
      .catch((err) => {
        message.error('Có lỗi xảy ra!');
        logger.error('Error while loading vote options:', err);
      });
  }, [open]);

  return (
    <Fragment>
      <Button
        icon="unordered-list"
        onClick={handleOpen}
      />
      <BaseDialog
        visible={open}
        title="Lựa chọn"
        onCancel={handleClose}
        okText="Lưu"
        onOk={handleSubmit}
        width={900}
        okButtonProps={{ loading: isLoading }}
        fullHeight
      >
        <VoteOptionList options={options} onChange={handleChange} onDelete={handleDelete} />
      </BaseDialog>
    </Fragment>
  );

  function handleSubmit() {
    return performAction('Lưu', () => {
      return voteApi.setOptions(vote.VoteID, options).then(handleClose);
    });
  }

  function handleDelete(optionId) {
    const newOptions = options.slice();
    lodash.remove(newOptions, { OptionID: optionId });
    setOptions(newOptions);
  }

  function handleChange(value) {
    setOptions(value);
  }
}
