import Search from 'antd/lib/input/Search';
import { sortingToSortBy } from 'core/common/listUtils';
import lodash from 'core/common/lodash';
import AccountDisplay from 'core/components/account/AccountDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import FlexPushRight from 'core/components/common/FlexPushRight';
import SelectableTable from 'core/components/table/SelectableTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import { PlatformLabel } from 'core/engine/common/constants';
import embeddedStyleApi from 'core/engine/embedded-styles/api';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import EmbeddedStyleFormDialogButton from 'core/modules/operations/embedded-styles/components/EmbeddedStyleFormDialogButton';
import React, { Fragment, useEffect } from 'react';

function listFn({ limit, offset, filters, sorting }) {
  return embeddedStyleApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

const columns = [
  {
    Header: 'ID',
    accessor: 'StyleID',
    width: 50,
    style: { justifyContent: 'center' },
  },
  {
    Header: 'Tên',
    accessor: 'Title',
    minWidth: 250,
  },
  {
    Header: 'Nền tảng',
    accessor: 'Platform',
    Cell: ({ value }) => PlatformLabel[value],
    maxWidth: 100,
  },
  {
    Header: 'Ngày sửa',
    width: 120,
    Cell: ({ original }) => <DateTimeDisplay timestamp={original.ModifiedDate} />,
  },
  {
    Header: 'Người sửa',
    width: 120,
    Cell: ({ original }) => <AccountDisplay id={original.EditorID} />,
  },
];

export default function SelectableEmbeddedStyleTab({ type, value, onChange }) {
  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: {
      StyleID: 'DESC',
    },
    defaultFilters: {
      Type: type,
    },
  });

  useEffect(() => {
    setTimeout(listProps.refresh, 300); // after tab transition
  }, []);

  const handleTitleFilterChange = useCreateOnFilterChange(listProps, 'Title');

  return (
    <Fragment>
      <TableToolbarWrapper>
        <EmbeddedStyleFormDialogButton type={type} isEditMode={false} onDone={listProps.refresh} />
        <FlexPushRight />
        <Search
          placeholder="Tìm kiếm..."
          onSearch={handleTitleFilterChange}
          allowClear
        />
      </TableToolbarWrapper>
      <SelectableTable
        {...useListProps(listProps)}
        idField="StyleID"
        columns={columns}
        selected={value}
        onSelect={onSelect}
        onUnselect={onUnselect}
        heightAdjust={42}
      />
    </Fragment>
  );

  function onSelect(item) {
    const newValue = value.slice();
    newValue.push(item);
    onChange(newValue);
  }

  function onUnselect(item) {
    const newValue = value.slice();
    lodash.remove(newValue, item);
    onChange(newValue);
  }
}
