import { useMemo, useEffect } from 'react';
import ClipboardJS from 'clipboard';
import { emptyFunction } from 'core/common/empty';
import { message } from 'antd';

let counter = 0;

export default function useClipboard({ text, onSuccess = emptyFunction, onError = emptyFunction, notification = true }) {
  const className = useMemo(() => {
    return 'useClipboard-' + counter++;
  }, []);

  useEffect(() => {
    const clipboard = new ClipboardJS('.' + className, {
      text: () => {
        if (typeof text === 'function') {
          return text();
        }

        return text;
      },
    });

    clipboard.on('success', () => {
      notification && message.success('Đã sao chép');
      onSuccess();
    });

    clipboard.on('error', () => {
      notification && message.error('Có lỗi khi sao chép');
      onError();
    });

    return () => clipboard.destroy();
  }, [text]);

  return {
    className,
  };
}
