/* eslint-disable import/namespace */
import ZDocs from '@zdocs/core';
import lodash from 'core/common/lodash';
import { EditorHookAction } from 'core/components/editor/constants';
import actionRegistry from 'core/registries/actions';
import Plugins from './plugins';

export function init() {
  //Plugins
  Object.keys(Plugins)
    .forEach((key) => {
      const Plugin = Plugins[key];
      ZDocs.plugins.add(key, Plugin);
      Plugin.install && Plugin.install();
    });

  actionRegistry.addAction(EditorHookAction.BEFORE_RENDER_EDITOR, beforeRenderEditor);
}

function beforeRenderEditor({ options }) {
  // customize all editors' options
  options.pluginsOptions = {
    ...options.pluginsOptions,
    LinkContentPlugin: {
      contentLinkTemplate:
        `<related-content data-cid="$Id">
          <div class="img"><img src="$AvatarUrl"/></div>
          <div class="info">
            <a href="$Url">$Text</a>
            <div class="date">$Date</div>
          </div>
        </related-content>`,
    },
  };

  const { buttons } = options;
  lodash.pull(buttons, 'gallery', 'BeforeAfterImage');
}
