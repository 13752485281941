import { Button } from 'antd';
import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import { parseQuery } from 'core/common/urlHelper';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import tagApi from 'core/engine/tag/api';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import TagFormDialogButton from './TagFormDialogButton';
import TagList from './TagList';

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return tagApi.getList({
    limit,
    offset,
    fields: ['Description', 'Url', 'ParentID', 'CreatorID'],
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

const TagSearchOptions = [
  { value: 'Name', label: 'Tên' },
  { value: 'Url', label: 'Url' },
  { value: 'TagID', label: 'ID' },
];

export default function TagListView() {
  const [parentId, setParentId] = useState(0);
  const location = useLocation();
  const { search } = location;

  const tagId = useMemo(() => {
    return parseQuery(search).tagId;
  }, [search]);

  const listProps = useList({
    listFn,
    defaultFilters: {
      search: { type: 'Name' },
    },
    defaultSorting: {
      TagID: 'DESC',
    },
  });

  const handleSearchFilterChange = useCreateOnFilterChange(listProps, 'search');

  useEffect(() => {
    setParentId(tagId);
    listProps.addFilter('ParentID', tagId);
  }, [tagId]);

  return (
    <Container pageTitle="Quản lý từ khóa">
      <TableToolbarWrapper>
        <TagFormDialogButton parentId={parentId} isEditMode={false} onDone={listProps.refresh} />
        {parentId > 0 && (
          <Link to="/operations/tags"><Button icon="menu-fold">Quay lại</Button></Link>
        )}
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={TagSearchOptions}
          search={listProps.filters.search}
          onSearch={handleSearchFilterChange}
        />
      </TableToolbarWrapper>
      <TagList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdate={listProps.refresh}
        onDelete={handleDelete}
      />
    </Container>
  );

  function handleDelete(tag) {
    return asyncAction('Xóa từ khóa', () => {
      return tagApi.delete(tag.TagID).then(() => {
        listProps.removeItem(tag, 'TagID');
      });
    });
  }
}
