import React from 'react';
import { Link } from 'react-router-dom';

export default function ContentEditLink({ contentId, children, ...props }) {
  return (
    <Link to={{ pathname: '/contents/compose', search: `?contentId=${contentId}` }} {...props} >
      {children}
    </Link>
  );
}
