import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import epaperApi from 'src/engine/epaper/api';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React from 'react';
import EPaperFormDialogButton from './EPaperFormDialogButton';
import EPaperList from './EPaperList';

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return epaperApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

const EPaperSearchOptions = [
  { value: 'Title', label: 'Tiêu đề' },
  { value: 'EPaperID', label: 'ID' },
];

export default function EPaperView() {
  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultFilters: {
      search: { type: EPaperSearchOptions[0].value },
    },
    defaultSorting: {
      EPaperID: 'DESC',
    },
  });

  const handleSearchFilter = useCreateOnFilterChange(listProps, 'search');

  return (
    <Container pageTitle="Ấn phẩm">
      <TableToolbarWrapper>
        <EPaperFormDialogButton isEditMode={false} onDone={listProps.refresh} />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={EPaperSearchOptions}
          search={listProps.filters.search}
          onSearch={handleSearchFilter}
        />
      </TableToolbarWrapper>
      <EPaperList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdate={listProps.refresh}
        onDelete={handleDelete}
      />
    </Container>
  );

  function handleDelete(epaper) {
    return asyncAction('Xóa ấn phẩm', () => {
      return epaperApi.delete(epaper.EPaperID).then(() => {
        listProps.removeItem(epaper, 'EPaperID');
      });
    });
  }
}
