import lodash from 'core/common/lodash';
import adsPositionApi from './api';

export const getAdsPositionCache = lodash.memoize((positionId) =>
  adsPositionApi.get(positionId).then(resp => resp.data)
);

export const getListAdsPositionCache = lodash.memoize(() =>
  adsPositionApi.getListAll().then(resp => resp.data.items)
);

export const getMapAdsPositionCache = lodash.memoize(() =>
  getListAdsPositionCache().then(items => lodash.keyBy(items, 'PositionID'))
);

export const getNameByPositionCodeCache = lodash.memoize((code) =>
  getListAdsPositionCache()
    .then(items =>
      lodash.get(lodash.keyBy(items, 'Code'), code + '.Name')
    )
);
