import React, { forwardRef } from 'react';
import WidgetFormDialogButton from '../WidgetFormDialogButton';

export default forwardRef(function WidgetDefaultQuickInsertDialog({ onInsert, widget }, ref) {
  return (
    <WidgetFormDialogButton
      wrappedComponentRef={ref}
      widget={{
        Title: widget.name,
        Type: widget.type,
        PreDefined: false,
      }}
      onInsert={onInsert}
      availableWidgets={[widget]}
      hasButton={false}
    />
  );
});
