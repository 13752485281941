import { Button, Form } from 'antd';
import { asyncAction } from 'core/common/async';
import { emptyObject } from 'core/common/empty';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import React, { Fragment } from 'react';
import epaperApi from 'src/engine/epaper/api';
import EPaperForm from './EPaperForm';

function EPaperFormDialogButton({ form, epaper = emptyObject, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={isEditMode ? 'Sửa ấn phẩm' : 'Tạo ấn phẩm'}
        onClick={handleOpen}>
        {isEditMode ? '' : 'Tạo ấn phẩm'}
      </Button>
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa ấn phẩm' : 'Tạo ấn phẩm'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <EPaperForm form={form} epaper={epaper} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    if (formData.Date) {
      formData.Date = formData.Date.toDate().getTime();
    }

    if (isEditMode) {
      return asyncAction('Sửa ấn phẩm', () => {
        return epaperApi.update(formData)
          .then(handleDone);
      });
    }

    return asyncAction('Tạo ấn phẩm', () => {
      return epaperApi.create(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'EPaperFormDialogButton' })(EPaperFormDialogButton);
