import { buildCdnPhotoUrl } from 'core/common/cdn';
import { getImageDimensions } from 'core/common/imageUtils';
import logger from 'core/common/logger';
import { useEffect, useState } from 'react';

export default function useImageSize(url = '') {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!url) {
      setSize({ width: 0, height: 0 });
      setIsLoading(false);
      setError(null);
      return;
    }

    const imageUrl = /^https?:\/\//.test(url) ? url : buildCdnPhotoUrl(url);
    let active = true;

    setIsLoading(true);

    getImageDimensions(imageUrl)
      .then((size) => {
        if (active) {
          setSize(size);
          setIsLoading(false);
          setError(null);
        }
      })
      .catch((err) => {
        logger.error('Error while getting image dimensions', err);
        setSize({ width: 0, height: 0, error: err });
        setIsLoading(false);
        setError(err);
      });

    return () => active = false;
  }, [url]);

  return {
    size,
    isLoading,
    error,
  };
}
