import React from 'react';
import { Button } from 'antd';
import makeStyles from 'core/common/makeStyles';
import commonStyles from 'core/common/commonStyles';
import Selector from './Selector';
import RangeDateSelector from './RangeDateSelector';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexRow,
    width: 400,
  },
  sort: {
    minWidth: 32,
    marginLeft: 5,
    marginRight: 5,
  },
  rangeDate: {
    minWidth: 220,
  },
}));

const defaultOptions = { CreatedDate: 'Ngày tạo', ModifiedDate: 'Ngày sửa' };

export default function RangeDateWithTypeSelector({
  value,
  onChange,
  options = defaultOptions,
  ...props
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Selector
        value={value.type}
        onChange={handleTypeChange}
        options={options}
      />
      <Button
        className={classes.sort}
        title={value.desc ? 'Mới nhất' : 'Cũ nhất'}
        icon={value.desc ? 'down-circle' : 'up-circle' } onClick={toggleSort}
      />
      <RangeDateSelector className={classes.rangeDate} value={value.range} onChange={handleRangeChange} {...props} />
    </div>
  );

  function toggleSort() {
    onChange({ ...value, desc: !value.desc });
  }

  function handleTypeChange(type) {
    onChange({ ...value, type });
  }

  function handleRangeChange(range) {
    onChange({ ...value, range });
  }
}
