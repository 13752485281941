import { Icon, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import FileChooserButton from 'core/components/file-manager/FileChooserButton';
import React, { forwardRef, useCallback } from 'react';
import ImageDisplay from './ImageDisplay';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
  },
  avatar: {
    marginBottom: 10,
  },
  captionInput: {
    marginTop: 5,
  },
}));

export default forwardRef(function ImagePickerInput({
  value,
  onChange,
  width = 150,
  showImage = true,
}, ref) {
  const classes = useStyles();

  const handleSelect = useCallback((file) => {
    onChange(file.Path);
  }, [onChange]);

  return (
    <div className={classes.container}>
      {showImage && value && <ImageDisplay src={value} width={width} alt="avatar" className={classes.avatar} />}
      <Input
        ref={ref}
        placeholder="Ảnh đại diện"
        value={value}
        onChange={handleChange}
        addonAfter={
          <FileChooserButton
            title="Chọn ảnh đại diện"
            triggerComponent={TriggerComponent}
            onSelect={handleSelect}
          />
        }
        allowClear
      />
    </div>
  );

  function handleChange(event) {
    if (!event.target.value) {
      onChange('');
    }
  }
});

function TriggerComponent({ onClick }) {
  return (
    <Icon type="cloud-upload" onClick={onClick} />
  );
}
