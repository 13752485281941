import { Config } from '@zdocs/core';
import BasePlugin from 'core/components/editor/plugins/BasePlugin';

export default class SearchPlugin extends BasePlugin {

  static install(config) {
    const defaultList = {
      find: 'Tìm kiếm',
      replace: 'Thay thế',
    };

    const list = config && config.list ? (
      typeof config.list === 'function' ? config.list(defaultList) : config.list
    ) : defaultList;

    Config.prototype.controls.search = {
      list,
      tooltip: 'Tìm kiếm',
      icon: 'search',
      exec: (zdocs, event, control) => {
        const value = control.args && control.args[0];

        switch (value) {
          case 'replace':
            zdocs.execCommand('openReplaceDialog');
            break;

          default:
            zdocs.execCommand('openSearchDialog');
        }
      },
    };
  }

  get name() {
    return 'search';
  }
}
