import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import topicApi from 'core/engine/topic/api';
import { TopicType } from 'core/engine/topic/constants';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { Fragment, useMemo } from 'react';
import TopicFormDialogButton from './TopicFormDialogButton';
import TopicList from './TopicList';

function listFn({ limit, offset, filters, sorting }) {
  return topicApi.getList({
    limit,
    offset,
    fields: ['Type', 'AvatarUrl', 'Layout', 'ParentIDs', 'Meta'],
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default function TopicListDisplay({ type = TopicType.NORMAL }) {
  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultFilters: {
      Enabled: true,
      Type: type,
    },
    defaultSorting: {
      CreatedDate: 'DESC',
    },
  });

  const initialTopic = useMemo(() => ({ Type: type }), [type]);

  return (
    <Fragment>
      <TableToolbarWrapper>
        <TopicFormDialogButton isEditMode={false} topic={initialTopic} onDone={listProps.refresh} />
      </TableToolbarWrapper>
      <TopicList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdate={listProps.refresh}
        onDelete={handleDelete}
        type={type}
      />
    </Fragment>
  );

  function handleDelete(topic) {
    return asyncAction('Xóa chủ đề tin', () => {
      return topicApi.delete(topic.TopicID).then(() => {
        listProps.removeItem(topic, 'TopicID');
      });
    });
  }
}
