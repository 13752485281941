import { buildCdnPhotoUrl } from 'core/common/cdn';
import lodash from 'core/common/lodash';
import { ContentAvatarSize, ContentAvatarType } from './constants';

const MAX_IMAGE_SIZE = 2000;

export function getModifiedAvatarData(contentAvatars, url) {
  const promises = [];

  Object.values(ContentAvatarType).forEach((position) => {
    promises[position] = new Promise((resolve) => {
      if (!contentAvatars[position] || !contentAvatars[position].isModified) {
        resolve();
        return;
      }

      const originalWidth = lodash.get(contentAvatars, `[${position}].Meta.width`);
      const originalHeight = lodash.get(contentAvatars, `[${position}].Meta.height`);

      if (!originalWidth || !originalHeight) {
        resolve();
      }

      const canvas = document.createElement('canvas');

      const imageObj = new Image();
      imageObj.crossOrigin = 'anonymous';

      imageObj.onload = function () {
        if (ContentAvatarSize[position].width > ContentAvatarSize[position].height) {
          canvas.width = Math.min(MAX_IMAGE_SIZE, imageObj.width);
          canvas.height = ContentAvatarSize[position].height * canvas.width / ContentAvatarSize[position].width;
        } else {
          canvas.height = Math.min(MAX_IMAGE_SIZE, imageObj.height);
          canvas.width = ContentAvatarSize[position].width * canvas.height / ContentAvatarSize[position].height;
        }

        if (!Number.isFinite(canvas.width) || !canvas.width || !Number.isFinite(canvas.height) || !canvas.height) {
          resolve();
        }

        const context = canvas.getContext('2d');

        const scale = contentAvatars[position].isScrollHorizontal
          ? canvas.height / contentAvatars[position].backgroundSize.height
          : canvas.width / contentAvatars[position].backgroundSize.width;

        context.drawImage(
          imageObj,
          0,
          0,
          imageObj.width,
          imageObj.height,
          contentAvatars[position].x * scale,
          contentAvatars[position].y * scale,
          contentAvatars[position].backgroundSize.width * scale,
          contentAvatars[position].backgroundSize.height * scale,
        );

        resolve({ ...lodash.cloneDeep(contentAvatars[position]), data: canvas.toDataURL('image/jpeg') });
      };

      imageObj.src = buildCdnPhotoUrl(lodash.get(contentAvatars, `[${position}].Meta.originImage`, url));
    });
  });

  return Promise.all(promises);
}
