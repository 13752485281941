import { Checkbox, Form, Input, InputNumber } from 'antd';
import commonStyles from 'core/common/commonStyles';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import FeatureFlip from 'core/components/common/FeatureFlip';
import Selector from 'core/components/common/Selector';
import { AccountSiteOption } from 'core/engine/account/constants';
import React, { Fragment } from 'react';

export function AccountFormRaw({ form, onSubmit, account, isEditMode = true }) {
  const { getFieldDecorator } = form;

  getFieldDecorator('ZaloID', { initialValue: account.ZaloID || '' });
  getFieldDecorator('FailedLoginCount', { initialValue: account.FailedLoginCount || 0 });
  getFieldDecorator('IsLockedOut', { initialValue: account.IsLockedOut || 0 });

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      {account.AccountID &&
        (<Form.Item label="ID">
          {getFieldDecorator('AccountID', {
            initialValue: account.AccountID,
          })(
            <Input
              disabled
              type="number"
            />
          )}
        </Form.Item>)}
      <Form.Item label="Tài khoản">
        {getFieldDecorator('Username', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
          initialValue: account.Username,
        })(
          <Input disabled={isEditMode} autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator('Email', {
          rules: [
            {
              type: 'email',
              message: 'Email không hợp lệ!',
            },
            {
              required: true,
              message: 'Bắt buộc!',
            },
            {
              max: 250,
              message: 'Tối đa 250 kí tự!',
            },
          ],
          initialValue: account.Email,
        })(
          <Input />)}
      </Form.Item>
      <Form.Item label="Thông tin">
        {getFieldDecorator('Title', {
          initialValue: account.Title,
          rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <Input />)}
      </Form.Item>
      <Form.Item label="Bút danh">
        {getFieldDecorator('Pseudonym', {
          initialValue: account.Pseudonym,
          rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <Input />)}
      </Form.Item>
      <Form.Item label="Số điện thoại">
        {getFieldDecorator('Phone', {
          initialValue: account.Phone || '',
          rules: [
            {
              required: false,
              pattern: new RegExp('^[0-9]*$'),
              message: 'Số điện thoại không hợp lệ!',
            },
            {
              max: 12,
              message: 'Tối đa 12 kí tự!',
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Cơ sở">
        {getFieldDecorator('Site', {
          initialValue: account.Site,
        })(
          <Selector
            options={AccountSiteOption}
            emptyLabel="-- Cơ sở --"
          />
        )}
      </Form.Item>
      <Form.Item label="Mật khẩu mới">
        {getFieldDecorator('NewPassword', {
          rules: [
            {
              required: !isEditMode,
              message: 'Bắt buộc!',
            },
            {
              max: 250,
              message: 'Tối đa 250 kí tự!',
            },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/g,
              message: 'Mật khẩu cần ít nhất 8 kí tự, chứa ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số và 1 ký tự đặc biệt',
            },
          ],
        })(
          <Input type="password" autoComplete="new-password" />)}
      </Form.Item>
      {isEditMode && (
        <Form.Item label="Đăng nhập lỗi">
          {getFieldDecorator('FailedLoginCount', {
            initialValue: account.FailedLoginCount || 0,
            rules: [
              { required: true, message: 'Bắt buộc!' },
            ],
          })(
            <InputNumber />
          )}
        </Form.Item>
      )}
      <Form.Item label="Đã duyệt">
        {getFieldDecorator('IsApproved', {
          valuePropName: 'checked',
          initialValue: account.IsApproved || 0,
        })(
          <Checkbox />)}
      </Form.Item>
      <Form.Item label="Là Admin">
        {getFieldDecorator('IsAdministrator', {
          valuePropName: 'checked',
          initialValue: account.IsAdministrator || 0,
        })(
          <Checkbox />)}
      </Form.Item>
      <Form.Item label="Khóa">
        {getFieldDecorator('IsLockedOut', {
          valuePropName: 'checked',
          initialValue: account.IsLockedOut || false,
        })(<Checkbox />)}
      </Form.Item>
      <FeatureFlip name="otp_ignore">
        <Form.Item label="Bỏ qua OTP">
          {getFieldDecorator('NoOtp', {
            valuePropName: 'checked',
            initialValue: account.NoOtp || false,
          })(<Checkbox />)}
        </Form.Item>
      </FeatureFlip>
      <FeatureFlip name="captcha_ignore">
        <Form.Item label="Bỏ qua Captcha">
          {getFieldDecorator('NoCaptcha', {
            valuePropName: 'checked',
            initialValue: account.NoCaptcha || false,
          })(<Checkbox />)}
        </Form.Item>
      </FeatureFlip>
      {isEditMode && (
        <Fragment>
          <Form.Item label="Ngày tạo">
            {account.CreatedDate ? <DateTimeDisplay timestamp={account.CreatedDate} /> : ''}
          </Form.Item>
          <Form.Item label="Đăng nhập cuối" >
            {account.LastLoginDate ? <DateTimeDisplay timestamp={account.LastLoginDate} /> : ''}
          </Form.Item>
          <Form.Item label="Hoạt động cuối" >
            {account.LastActivityDate ? <DateTimeDisplay timestamp={account.LastActivityDate} /> : ''}
          </Form.Item>
          <Form.Item label="Thay mật khẩu cuối">
            {account.LastPasswordChangedDate ? <DateTimeDisplay timestamp={account.LastPasswordChangedDate} /> : ''}
          </Form.Item>
          <Form.Item label="Lần khóa cuối cùng" >
            {account.LastLockedOutDate ? <DateTimeDisplay timestamp={account.LastLockedOutDate} /> : ''}
          </Form.Item>
        </Fragment>
      )}
    </Form >
  );
}
