import { IS_SAFARI } from '@bm/common';
import { Button, message } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { buildPreviewUrlById } from 'core/common/urlHelper';
import contentApi from 'core/engine/content/api';
import useAsyncAction from 'core/hooks/useAsyncAction';
import { formDataToContent } from 'core/modules/contents/services';
import useAuth from 'core/hooks/useAuth';

export default function ContentFormToolbarPreviewButton({
  getFormData,
}) {
  const { loggedInUser, hasExtendedRight } = useAuth();

  const canPreview = useMemo(() => (
    loggedInUser.isAdmin || hasExtendedRight('preview')
  ), [loggedInUser, hasExtendedRight]);

  const { isLoading, performAction } = useAsyncAction();

  const handleClick = useCallback(() => {
    if (IS_SAFARI) {
      window.open('', 'preview');
    }

    getFormData(false).then((formData) => {
      const content = formDataToContent(formData);

      if (!content.ZoneID) {
        message.error('Vui lòng kiểm tra lại các trường sau: Chuyên mục chính');
        return;
      }

      performAction('Mở trang xem trước', () => {
        return contentApi.preview(content).then(resp => {
          const windowProxy = window.open(buildPreviewUrlById('preview_id', resp.data.NoiseID), 'preview');

          if (windowProxy) {
            windowProxy.focus();
          }
        });
      }, { silentSuccess: true });
    });
  }, [getFormData, performAction]);

  return canPreview ? (
    <Button
      title="Xem trước"
      onClick={handleClick}
      icon="eye"
      loading={isLoading}
    >
      Xem trước
    </Button>
  ) : null;
}
