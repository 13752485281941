import { Button, Checkbox, Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { emptyObject } from 'core/common/empty';
import { isNullOrUndefined } from 'core/common/is';
import { FileType } from 'core/engine/file/constants';
import React, { useEffect, Fragment, useMemo } from 'react';
import ImagePickerInput from '../common/ImagePickerInput';
import AccountDisplay from '../account/AccountDisplay';
import DateTimeDisplay from '../common/DateTimeDisplay';
import { getCurrentStore } from 'core/redux/store';

export function FileManagerAlbumForm({ form, onSubmit, album = emptyObject, submitOnEnter = true, isEditMode = true }) {
  const { getFieldDecorator, resetFields } = form;

  const currentUser = useMemo(() => {
    return getCurrentStore().getState().auth.user;
  }, []);

  const disableIsPrivateField = useMemo(() => {
    return isEditMode && currentUser && !currentUser.isAdmin && currentUser.id !== album.CreatorID;
  }, [currentUser, album, isEditMode]);

  useEffect(() => resetFields(), [album]);

  getFieldDecorator('Type', { initialValue: isNullOrUndefined(album.Type) ? FileType.IMAGE : album.Type });
  getFieldDecorator('ParentID', { initialValue: album.ParentID || 0 });

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      {album.AlbumID &&
        (<Form.Item label="ID">
          {getFieldDecorator('AlbumID', {
            initialValue: album.AlbumID,
          })(
            <Input
              disabled
              type="number"
            />
          )}
        </Form.Item>)}
      <Form.Item label="Tên">
        {getFieldDecorator('Name', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: album.Name,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Riêng tư">
        {getFieldDecorator('IsPrivate', {
          valuePropName: 'checked',
          initialValue: album.IsPrivate || 0,
        })(
          <Checkbox disabled={disableIsPrivateField} />
        )}
      </Form.Item>
      <Form.Item label="Ảnh đại diện">
        {getFieldDecorator('AvatarUrl', {
          initialValue: album.AvatarUrl,
        })(
          <ImagePickerInput showImage />
        )}
      </Form.Item>

      {isEditMode && (
        <Fragment>
          <Form.Item label="Người tạo">
            {album.CreatorID ? <AccountDisplay id={album.CreatorID} /> : ''}
          </Form.Item>

          <Form.Item label="Ngày tạo">
            {album.CreatedDate ? <DateTimeDisplay timestamp={album.CreatedDate} /> : ''}
          </Form.Item>
        </Fragment>
      )}

      {submitOnEnter && <Button htmlType="submit" style={commonStyles.hidden} />}
    </Form >
  );
}
