import React, { forwardRef, useEffect, useState } from 'react';
import settingApi from 'core/engine/setting/api';
import { parseJson } from 'core/common/jsonUtils';
import Selector from 'core/components/common/Selector';

export default forwardRef(function ActivitySelector({ value, onChange, ...props }, ref) {
  const [activityPathLabel, setActivityPathLabel] = useState({});

  useEffect(() => {
    settingApi.get('ActivityPathLabel').then(resp => setActivityPathLabel(parseJson(resp.data.item.Value, {})));
  }, []);

  return (
    <Selector
      ref={ref}
      options={activityPathLabel}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
});
