import { Icon } from 'antd';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import CardWidget from 'core/components/common/CardWidget';
import { MediaQuery } from 'core/constants';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatNumber } from 'core/common/string';

const useStyles = makeStyles(() => ({
  card: {
    paddingBottom: 0,
    paddingTop: 0,

    [MediaQuery.md]: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff !important', // to override link style
  },
  icon: {
    '& > svg': {
      width: 18,
      height: 18,
    },

    [MediaQuery.md]: {
      fontSize: 48,

      '& > svg': {
        width: 60,
        height: 60,
      },
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    lineHeight: 'initial',
    alignItems: 'center',
    marginLeft: 10,

    [MediaQuery.md]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    ...commonStyles.ellipsis,
    fontSize: 18,
    width: '100%',
    marginBottom: 0,
  },
  number: {
    fontSize: 20,
    lineHeight: '20px',
    marginBottom: 0,

    [MediaQuery.md]: {
      fontSize: 32,
      lineHeight: '32px',
    },
  },
}));

export default function DashBoardWidgetNumber({ title, link, number, icon, bgColor }) {
  const classes = useStyles();

  return (
    <CardWidget height="auto" bgColor={bgColor} className={classes.card}>
      <Link to={link ? link : '#'} className={classes.link}>
        {typeof icon === 'function' && <Icon className={classes.icon} component={icon} />}
        {typeof icon === 'string' && <Icon className={classes.icon} type={icon} />}
        <div className={classes.content}>
          {title && <p className={classes.title}>{title}</p>}
          <p className={classes.number}>
            {formatNumber(number <= 0 ? 0 : number)}
          </p>
        </div>
      </Link>
    </CardWidget>
  );
}
