import { Spin } from 'antd';
import ErrorBoundary from 'core/components/common/ErrorBoundary';
import React, { forwardRef, Suspense } from 'react';

export default function lazyComponent(WrappedComponent, { withRef = false } = {}) {
  if (withRef) {
    return forwardRef((props, ref) => (
      <ErrorBoundary>
        <Suspense fallback={<Spin />}>
          <WrappedComponent ref={ref} {...props} />
        </Suspense>
      </ErrorBoundary>
    ));
  }

  return (props) => (
    <ErrorBoundary>
      <Suspense fallback={<Spin />}>
        <WrappedComponent {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}
