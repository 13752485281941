import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import makeStyles from 'core/common/makeStyles';
import { breadcrumpsSelector } from 'core/redux/selectors/menu';

const useStyle = makeStyles(() => ({
  container: {
    padding: '10px 0',
  },
}));

export default function BreadCrump() {
  const classes = useStyle();
  const breadcrumps = useSelector(breadcrumpsSelector);

  return (
    <Breadcrumb className={classes.container}>
      {breadcrumps.map(item => (
        <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}
