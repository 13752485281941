import { Checkbox } from 'antd';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import makeStyles from 'core/common/makeStyles';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateWithTypeSelector from 'core/components/common/RangeDateWithTypeSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import Selector from 'core/components/common/Selector';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import contentApi from 'core/engine/content/api';
import { ContentListType, ContentSearchOptions, ContentStatusLabel } from 'core/engine/content/constants';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import moment from 'moment';
import React, { useMemo } from 'react';
import useContentFilterHandlers from '../../hooks/useContentFilterHandlers';
import { handleLimitSortRangeDateChange, handleSortedChangeByRangeDate } from '../../services';
import ContentAllList from './ContentAllList';
import AuthorIdSelector from 'core/components/author/AuthorIdSelector';
import AccountSelector from 'core/components/account/AccountSelector';
import { CONTENT_LIST_FILTER_PARENT_AUTHOR } from 'core/config';

const GET_FIELDS = ['EditorID', 'ModifiedDate', 'DeployerID', 'CreatedDate', 'ViewCount', 'GAViewCount'];

const defaultRangeDate = [null, null];

const useStyles = makeStyles(() => ({
  checkbox: {
    minWidth: 120,
    marginTop: 5,
  },
  selector: {
    minWidth: 150,
  },
}));

function listFn({ limit, offset, filters, sorting }) {
  return contentApi.getList({
    limit,
    offset,
    fields: GET_FIELDS,
    sortBy: buildSorting(sorting, filters),
    filters: buildFilter(filters),
  });
}

function buildFilter(filters) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;

  if (rangeDate.range[0] && rangeDate.range[1]) {
    listFilters[rangeDate.type] = dateRangeToFilter(rangeDate.range);
  }

  if (listFilters.isHideScheduleContent) {
    const now = moment().format('x');

    if (rangeDate.type === 'DistributionDate' && rangeDate.range[0] && rangeDate.range[1]) {
      const from = rangeDate.range[0].startOf('day').format('x');
      const to = rangeDate.range[1].endOf('day').format('x') - 1;

      listFilters.DistributionDate = 'bw:' + from + ',' + (now < to ? now : to);
    } else {
      listFilters.DistributionDate = 'lte:' + now;
    }
  }

  delete listFilters.rangeDate;

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return listFilters;
}

function buildSorting(sorting, filters) {
  const rangeDate = filters.rangeDate;
  const listSorting = { ...sorting, [rangeDate.type]: rangeDate.desc ? 'DESC' : 'ASC' };

  return sortingToSortBy(listSorting);
}

export default function ContentAllListView() {
  const classes = useStyles();

  const listProps = useList({
    listId: 'ContentAllListView',
    listFn,
    defaultFilters: {
      search: { type: ContentSearchOptions[0].value },
      rangeDate: { type: 'ModifiedDate', range: defaultRangeDate, desc: true },
      listType: ContentListType.ALL,
    },
    autoLoad: true,
  });
  const filterHandlers = useContentFilterHandlers(listProps);
  const handleFilterIsHideScheduleContent = useCreateOnFilterChange(listProps, 'isHideScheduleContent');

  const contentStatusOptions = useMemo(() => {
    const options = [];

    Object.keys(ContentStatusLabel).forEach(key => {
      options.push({ value: key, label: ContentStatusLabel[key] });
    });

    return options;
  }, []);

  const toolbar = (
    <>
      <TableToolbarWrapper>
        <ZoneSelector
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
          width={200}
          allowClear
        />
        <Selector
          options={contentStatusOptions}
          value={listProps.filters.Status}
          onChange={filterHandlers.onStatusFilter}
          width={170}
          emptyLabel="-- Trạng thái --"
          allowClear
        />
        <RangeDateWithTypeSelector
          value={listProps.filters.rangeDate}
          options={{ CreatedDate: 'Ngày tạo', ModifiedDate: 'Ngày sửa', DistributionDate: 'Ngày đăng' }}
          onChange={(rangeDate) => handleLimitSortRangeDateChange(rangeDate, listProps)}
        />
        <Checkbox
          checked={listProps.filters.isHideScheduleContent}
          onChange={(event) => handleFilterIsHideScheduleContent(event.target.checked)}
          className={classes.checkbox}
        >
          Ẩn tin hẹn giờ
        </Checkbox>
      </TableToolbarWrapper>
      <TableToolbarWrapper>
        <AccountSelector
          width={200}
          emptyLabel="-- Người tạo --"
          value={listProps.filters.WriterID}
          onChange={filterHandlers.onWriterFilter}
          allowClear
        />
        <AccountSelector
          width={200}
          emptyLabel="-- Người đăng --"
          value={listProps.filters.DeployerID}
          onChange={filterHandlers.onDeployerFilter}
          allowClear
        />
        <AccountSelector
          width={200}
          emptyLabel="-- Người sửa --"
          value={listProps.filters.EditorID}
          onChange={filterHandlers.onEditorFilter}
          allowClear
        />
        {CONTENT_LIST_FILTER_PARENT_AUTHOR &&
          <AuthorIdSelector
            width={200}
            emptyValue={null}
            value={listProps.filters.AuthorID}
            onChange={value => listProps.addFilter('AuthorID', value)}
            parentOnly
          />
        }
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={ContentSearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
      </TableToolbarWrapper>
    </>
  );

  return (
    <Container pageTitle="Tất cả tin">
      {toolbar}
      <ContentAllList
        {...useListProps(listProps)}
        onEditorSelected={filterHandlers.onEditorFilter}
        onWriterSelected={filterHandlers.onWriterFilter}
        onDeployerSelected={filterHandlers.onDeployerFilter}
        onSortedChange={(sorting) => handleSortedChangeByRangeDate(sorting, listProps)}
        heightAdjust={84}
      />
    </Container>
  );
}
