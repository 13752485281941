import React, { useEffect, useState, useCallback, Fragment } from 'react';
import moment from 'moment';
import reportApi from 'core/engine/report/api';
import ChartViewer from './ChartViewer';
import CopyToClipboardButton from 'core/components/common/CopyToClipboardButton';
import { Button } from 'antd';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import commonStyles from 'core/common/commonStyles';
import Separator from 'core/components/common/Separator';
import RangeDateSelector from 'core/components/common/RangeDateSelector';
import classnames from 'core/common/classnames';
import AsyncButton from '../common/AsyncButton';
import { asyncAction } from 'core/common/async';
import FeatureFlip from '../common/FeatureFlip';
import NumberDisplay from '../common/NumberDisplay';

const useStyles = makeStyles(() => ({
  actions: {
    ...commonStyles.flexRow,
    position: 'absolute',
    top: 0,
  },
  dateFilter: {
    right: 0,
  },
  export: {
    left: 0,
  },
  totalReport: {
    textAlign: 'center',
  },
}));

export default function ChartDailyWithFilter({
  title = 'Biểu đồ truy cập theo ngày',
  keys = [],
  value,
  startDate = moment().add(-1, 'months'),
  endDate = moment(),
  sum = true,
  showFilter = true,
  siteId,
}) {
  const classes = useStyles();

  const [rangeDate, setRangeDate] = useState([startDate, endDate]);
  const [totalTraffic, setTotalTraffic] = useState(0);
  const [data, setData] = useState([]);

  function fetchData() {
    if (keys.length === 0 || value === 0) {
      setData();
      return;
    }

    reportApi.getDaily(
      {
        keys: keys.join(),
        value: value,
        startDate: rangeDate[0].format('YYYYMMDD'),
        endDate: rangeDate[1].format('YYYYMMDD'),
        siteId: siteId,
      }
    ).then((resp) =>
      setData(resp.data.items),
    );
  }

  const chartDataToText = useCallback(() => {
    if (!data || !data.length) {
      return 'Không có dữ liệu :(';
    }

    const columns = Object.keys(data[0]).sort();

    let text = columns.join('\t');

    data.forEach((row) => {
      text += '\n' + columns.map(column => row[column]).join('\t');
    });

    return text;
  }, [data]);

  useEffect(fetchData, [keys, value, rangeDate, siteId]);

  useEffect(() => {
    setTotalTraffic(lodash.sum(data.map(item => item.web_all + item.mobile_all)));
  }, [data]);

  return (
    <div style={{ position: 'relative' }}>
      <ChartViewer
        title={title}
        xAxisKey={'Date'}
        data={data}
        sum={sum}
      />
      {showFilter && (
        <Fragment>
          <FeatureFlip name="export-report">
            <div className={classnames(classes.actions, classes.export)}>
              <AsyncButton
                icon="export"
                type="primary"
                onClick={handleExport}
                title="Xuất Excel"
              >
                Xuất Excel
              </AsyncButton>
            </div>
          </FeatureFlip>
          <div className={classnames(classes.actions, classes.dateFilter)}>
            <RangeDateSelector
              value={rangeDate}
              onChange={(value) => setRangeDate(value)}
            />
            <Separator />
            <CopyToClipboardButton text={chartDataToText}>
              <Button title="Copy dữ liệu biểu đồ" icon="copy" />
            </CopyToClipboardButton>
          </div>
        </Fragment>
      )}
      <div className={classes.totalReport}>
        Tổng lượt truy cập: <NumberDisplay value={totalTraffic} />
      </div>
    </div >
  );

  function handleExport() {
    return asyncAction('Xuất thống kê truy cập theo ngày', () => {
      return reportApi.exportTrafficDaily({
        filters: {
          keys: keys.join(),
          value: value,
          startDate: rangeDate[0].format('YYYYMMDD'),
          endDate: rangeDate[1].format('YYYYMMDD'),
          siteId: siteId,
        },
        filename: `Thống kê truy cập theo ngày từ ${rangeDate[0].format('DD-MM-YYYY')} đến ${rangeDate[1].format('DD-MM-YYYY')}.xlsx`,
      });
    });
  }
}
