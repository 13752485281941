import { IS_SAFARI } from '@bm/common';
import { Button, message } from 'antd';
import React, { useCallback, useContext } from 'react';
import { buildPreviewUrlById } from 'core/common/urlHelper';
import contentApi from 'core/engine/content/api';
import useAsyncAction from 'core/hooks/useAsyncAction';
import { formDataToContent } from '../../../services';
import { CustomComponentContext } from 'core/components/layout';

export default function ContentFormToolbarPreviewButton({
  getFormData,
}) {
  const customComponentContext = useContext(CustomComponentContext);

  const { isLoading, performAction } = useAsyncAction();

  const handleClick = useCallback(() => {
    if (IS_SAFARI) {
      window.open('', 'preview');
    }

    getFormData(false).then((formData) => {
      const content = formDataToContent(formData);

      if (!content.ZoneID) {
        message.error('Vui lòng kiểm tra lại các trường sau: Chuyên mục chính');
        return;
      }

      performAction('Mở trang xem trước', () => {
        return contentApi.preview(content).then(resp => {
          const windowProxy = window.open(buildPreviewUrlById('preview_id', resp.data.NoiseID), 'preview');

          if (windowProxy) {
            windowProxy.focus();
          }
        });
      }, { silentSuccess: true });
    });
  }, [getFormData, performAction]);

  if (customComponentContext && customComponentContext.ContentFormToolbarPreviewButton) {
    return <customComponentContext.ContentFormToolbarPreviewButton getFormData={getFormData} />;
  }

  return (
    <Button
      title="Xem trước"
      onClick={handleClick}
      icon="eye"
      loading={isLoading}
    >
      Xem trước
    </Button>
  );
}
