import { blue } from '@ant-design/colors';
import { Dropdown, Icon, Menu, Tag } from 'antd';
import makeStyles from 'core/common/makeStyles';
import { ContentAttributeOptions } from 'core/engine/content/constants';
import contentUtils from 'core/engine/content/utils';
import useAuth from 'core/hooks/useAuth';
import React, { forwardRef, useMemo } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 10,
    lineHeight: '30px',

    '& .anticon-close': {
      lineHeight: '14px',
      fontSize: '14px !important', // override ant's style
    },
  },
  add: {
    background: '#fff',
    borderStyle: 'dashed',

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default forwardRef(function ContentFormAttributeTagInput({
  value: sum,
  onChange,
  options = ContentAttributeOptions,
}, ref) {
  const classes = useStyles();
  const { loggedInUser, hasExtendedRight } = useAuth();

  const permissions = useMemo(() => {
    const ret = {};
    options.forEach(option => {
      ret[option.value] = loggedInUser.isAdmin || !option.cmd || hasExtendedRight(option.cmd);
    });

    return ret;
  }, [loggedInUser, hasExtendedRight, options]);

  const selectedOptions = useMemo(() => {
    return options.filter(option =>
      contentUtils.hasAttribute(sum, option.value),
    );
  }, [sum, options]);

  const unSelectedOptions = useMemo(() => {
    return options.filter(option =>
      !contentUtils.hasAttribute(sum, option.value),
    );
  }, [sum, options]);

  const menu = (
    <Menu onClick={handleSelect}>
      {unSelectedOptions.map(option => permissions[option.value] && (
        <Menu.Item key={option.value} title={option.tooltip || option.label} disabled={option.disabled}>
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={classes.container} ref={ref}>
      {selectedOptions.map(option => permissions[option.value] && (
        <Tag
          key={option.value}
          title={option.tooltip || option.label}
          color={blue.primary}
          closable={!option.disabled}
          onClose={() => handleUnselect(option.value)}
        >{option.label}</Tag>
      ))}
      {unSelectedOptions.length > 0 && (
        <Dropdown overlay={menu} trigger={['hover', 'click']}>
          <Tag className={classes.add}>
            <Icon type="plus" /> Thêm
          </Tag>
        </Dropdown>
      )}

    </div>
  );

  function handleSelect({ key }) {
    onChange(addBit(sum, parseInt(key)));
  }

  function handleUnselect(bit) {
    onChange(removeBit(sum, bit));
  }
});

function removeBit(sum, bit) {
  if ((sum & bit) === bit) {
    return sum - bit;
  }

  return sum;
}

function addBit(sum, bit) {
  if ((sum & bit) === bit) {
    return sum;
  }

  return sum + bit;
}
