import { Button, Typography } from 'antd';
import { arrayShallowEqual } from 'core/common/arrayHelper';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import BaseDialog from 'core/components/common/BaseDialog';
import FlexDiv from 'core/components/common/FlexDiv';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import TopicViewLink from 'core/components/topic/TopicViewLink';
import topicApi from 'core/engine/topic/api';
import useDialog from 'core/hooks/useDialog';
import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import RelatedTopicsSelector from './RelatedTopicsSelector';
import SelectableRelatedTopicsTab from 'core/modules/contents/components/form/related-topics/SelectableRelatedTopicsTab';
import { Sites } from 'core/engine/site/constants';

const ID_FIELD = 'TopicID';

const useStyles = makeStyles(() => ({
  list: {
    paddingLeft: 20,

    '& > li': {
      lineHeight: 'initial',
    },

    '& > li:not(:last-child)': {
      marginBottom: 5,
    },
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default forwardRef(function ContentFormRelatedTopicsInput({
  value,
  onChange,
  SelectableTab = SelectableRelatedTopicsTab,
  siteId = Sites.ALL,
}, ref) {
  const classes = useStyles();
  const { open, handleClose, handleOpen } = useDialog();
  const [displayItems, setDisplayItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [modifyingItems, setModifingItems] = useState([]);

  useEffect(() => {
    if (value) {
      const ids = value.split(',').map(item => parseInt(item));
      const sortedIds = ids.slice().sort();

      if (arrayShallowEqual(sortedIds, modifyingItems.map(c => c[ID_FIELD]).sort())) {
        setDisplayItems(modifyingItems);
        return;
      }

      setIsLoading(true);
      topicApi.getList({
        limit: sortedIds.length,
        filters: {
          Enabled: true,
          [ID_FIELD]: `in:${value}`,
        },
      })
        .then(resp => {
          setDisplayItems(lodash.sortBy(resp.data.items, item => ids.indexOf(item[ID_FIELD]))); // sort items to correct order because server result is not following original order
          setIsLoading(false);
        });
    } else {
      setDisplayItems([]);
    }
  }, [value]);

  useEffect(() => {
    if (open) {
      setModifingItems(displayItems);
    }
  }, [displayItems, open]);

  return (
    <Fragment>
      <FlexDiv className={classes.header}>
        <span ref={ref}>Chủ đề liên quan:</span>
        <Button
          title="Sửa chủ đề liên quan"
          type="primary"
          shape="circle"
          size="small"
          icon="edit"
          onClick={handleOpen}
        />
      </FlexDiv>
      <LoadingWrapper loading={isLoading} center>
        {displayItems.length > 0 ?
          <ul className={classes.list}>
            {displayItems.map(item => (
              <li key={item[ID_FIELD]}>
                <TopicViewLink id={item[ID_FIELD]} name={item.Name}>{item.Name}</TopicViewLink>
              </li>
            ))}
          </ul> :
          <Typography.Text type="danger"><i>Chưa có chủ đề liên quan</i></Typography.Text>
        }
      </LoadingWrapper>
      <BaseDialog
        fullHeightFixed
        noVerticalPadding
        visible={open}
        title={null}
        onOk={onOk}
        onCancel={handleClose}
        width={900}
      >
        <RelatedTopicsSelector value={modifyingItems} onChange={setModifingItems} SelectableTab={SelectableTab} siteId={siteId} />
      </BaseDialog>
    </Fragment>
  );

  function onOk() {
    onChange(modifyingItems.map(c => c[ID_FIELD]).join());
    handleClose();
  }
});
