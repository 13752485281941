import { red } from '@ant-design/colors';
import { Modal, Result } from 'antd';
import { asyncAction } from 'core/common/async';
import { emptyFunction, emptyObject } from 'core/common/empty';
import { sortingToSortBy } from 'core/common/listUtils';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import AsyncButton from 'core/components/common/AsyncButton';
import FlexPushRight from 'core/components/common/FlexPushRight';
import GoBackButton from 'core/components/common/GoBackButton';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import { CONTENT_LIST_CTR_STATS, TOPIC_SYSTEM_ORDERED_ITEMS } from 'core/config';
import { getAccountNameCache } from 'core/engine/account/caching';
import topicContentApi from 'core/engine/topic-content/api';
import topicApi from 'core/engine/topic/api';
import { TopicType } from 'core/engine/topic/constants';
import useAuth from 'core/hooks/useAuth';
import useList from 'core/hooks/useList';
import useWindowUnloadAlert from 'core/hooks/useWindowUnloadAlert';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import TopicAddContentButton from '../TopicAddContentButton';
import TopicSystemContentList from './TopicSystemContentList';
import moment from 'moment';
import FeatureFlip from 'core/components/common/FeatureFlip';
import { Sites } from 'core/engine/site/constants';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  border: {
    borderColor: red.primary,
  },
  topicName: {
    '& > span': {
      fontWeight: 'bold',
    },
  },
}));

const IGNORED_ORDER = 100;

function listFn({ offset, filters, sorting }) {
  const lookup = [
    'Content.ContentID',
    'Content.ZoneID',
    'Content.Title',
    'Content.Url',
    'Content.AvatarUrl',
    'Content.DistributionDate',
    'Content.WriterID',
    'Content.DeployerID',
    'Content.Status',
    'ContentStat.ContentID',
    'ContentStat.ViewCount',
  ];

  if (CONTENT_LIST_CTR_STATS) {
    lookup.push('CTRStats.ContentID', 'CTRStats.Click', 'CTRStats.Imps');
  }

  return topicContentApi.getList({
    limit: TOPIC_SYSTEM_ORDERED_ITEMS,
    offset,
    sortBy: sortingToSortBy(sorting),
    lookup,
    filters,
  });
}

export default function TopicSystemContentListDisplay({
  topic = emptyObject,
  systemExtButtons = emptyFunction,
  contentEditable = false,
  siteId = Sites.ALL,
}) {
  const classes = useStyles();

  const [isModifying, setModifying] = useState(false);
  const [originList, setOriginList] = useState([]);
  const [list, setList] = useState([]);
  const [checkinUser, setCheckinUser] = useState('');
  const { loggedInUser, hasExtendedRight } = useAuth();

  const listProps = useList({
    autoLoad: true,
    listFn,
    defaultSorting: {
      Order: 'ASC',
      ContentID: 'DESC',
    },
    defaultFilters: {
      TopicID: topic.TopicID,
      Order: 'bw:1,' + (IGNORED_ORDER - 1),
    },
  });

  const canForceCheckout = useMemo(() => {
    return loggedInUser.isAdmin || hasExtendedRight('topic.force-checkout');
  }, [loggedInUser, hasExtendedRight]);

  useEffect(() => {
    const items = listProps.items.map(item => ({ ...item }));
    normalizeList(items, false);
    setList(items);
    setOriginList(items);
  }, [listProps.items]);

  useEffect(() => {
    if (topic.CheckinID === loggedInUser.id) {
      setModifying(true);
    }
  }, [topic, loggedInUser]);

  useEffect(() => {
    if (topic.CheckinID) {
      getAccountNameCache(topic.CheckinID).then(setCheckinUser);
    }
  }, [topic.CheckinID]);

  useWindowUnloadAlert(isModifying);

  if (topic.Type !== TopicType.SYSTEM) {
    return (
      <Result status="warning" title="Không phải chủ đề hệ thống" extra={<GoBackButton />} />
    );
  }

  return (
    <div className={classes.container}>
      <Prompt
        when={isModifying}
        message="Bạn đang sửa chủ đề, vui lòng đóng chủ đề trước khi chuyển trang."
      />
      <TableToolbarWrapper>
        {isModifying ? (
          <Fragment>
            <AsyncButton
              title="Đóng"
              icon="close-circle"
              onClick={handleClose}
            >Đóng</AsyncButton>
            <TopicAddContentButton
              list={list}
              onInsert={handleInsert}
              onDelete={handleDelete}
              defaultZoneID={topic.ZoneID || null}
              siteId={siteId}
            />
            {systemExtButtons(topic.TopicID, list, setList)}
            <AsyncButton
              icon="save"
              type="primary"
              onClick={handleSave}
              title="Lưu thay đổi"
            >Lưu thay đổi</AsyncButton>
            <FeatureFlip name="system-topic-save-and-exit">
              <AsyncButton
                title="Lưu và thoát"
                icon="close-square"
                type="primary"
                onClick={handleSaveAndExit}
              >
                Lưu và thoát
              </AsyncButton>
            </FeatureFlip>
          </Fragment>
        ) : (
          <Fragment>
            <AsyncButton
              title="Sửa danh sách tin"
              hidden={isModifying}
              icon="edit"
              onClick={handleStartModify}
              disabled={canForceCheckout && checkinUser}
            >{checkinUser ? `Đang được sửa bởi ${checkinUser}` : 'Sửa danh sách tin'}</AsyncButton>
            {canForceCheckout && checkinUser && (
              <AsyncButton
                title={`Đóng quyền sửa của: ${checkinUser}`}
                onClick={handleForceCheckout}
                className={classes.border}
              >
                Đóng quyền sửa của: {checkinUser}
              </AsyncButton>
            )}
          </Fragment>
        )}
        <FlexPushRight />
        <div className={classes.topicName}>Chủ đề: <span>{topic.Name}</span></div>
      </TableToolbarWrapper>
      <TopicSystemContentList
        list={list}
        loading={listProps.isFetching}
        onMove={handleMove}
        onDelete={handleDelete}
        onDateChange={onDateChange}
        isModifying={isModifying}
        contentEditable={contentEditable}
        siteId={siteId}
      />
    </div>
  );

  function handleClose() {
    if (JSON.stringify(list) === JSON.stringify(originList)) {
      return closeTopic();
    }

    return new Promise((resolve) => {
      Modal.confirm({
        title: 'Đóng chủ đề?',
        content: 'Tất cả thay đổi chưa được lưu sẽ mất.',
        okText: 'Đóng',
        onOk() {
          closeTopic().then(resolve);
        },
        onCancel() {
          resolve();
        },
      });
    });
  }

  function closeTopic() {
    return asyncAction('Đóng chủ đề', () => {
      return topicApi.checkout(topic.TopicID).then(() => {
        listProps.refresh();
        setModifying(false);
      });
    }, { silentSuccess: true });
  }

  function handleStartModify() {
    return asyncAction('Mở chủ đề', () => {
      return topicApi.checkin(topic.TopicID).then(() => {
        setModifying(true);
      });
    }, { silentSuccess: true });
  }

  function handleForceCheckout() {
    return asyncAction('Đóng sắp tin', () => {
      return topicApi.forceCheckout(topic.TopicID).then(() => {
        setCheckinUser();
      });
    });
  }

  function handleInsert(item) {
    const newItem = {
      TopicID: topic.TopicID,
      ContentID: item.ContentID,
      Order: 1,
      Content: item,
      ContentStat: { ViewCount: item.ViewCount, GAViewCount: item.GAViewCount },
    };

    const newList = list.slice();
    newList.unshift(newItem);

    normalizeList(newList, isScheduled(newItem));
    setList(newList);
  }

  function handleDelete(item) {
    const newList = list.slice();
    lodash.remove(newList, { ContentID: item.ContentID });
    normalizeList(newList, isScheduled(item));
    setList(newList);
  }

  function handleSave() {
    const currentList = listProps.items.map(stripData);
    const currentMap = lodash.keyBy(currentList, 'ContentID');
    const dataList = list.map(stripData);
    const dataMap = lodash.keyBy(dataList, 'ContentID');

    const insertItems = [];
    const updateItems = [];
    const deleteItems = currentList.filter((item) => !dataMap[item.ContentID]);

    dataList.forEach((topicContent) => {
      const curTopicContent = currentMap[topicContent.ContentID];
      topicContent.Order = topicContent.Order > TOPIC_SYSTEM_ORDERED_ITEMS ? IGNORED_ORDER : topicContent.Order;

      if (curTopicContent) {
        if (topicContent.Order !== curTopicContent.Order
          || topicContent.StartDate !== curTopicContent.StartDate
          || topicContent.EndDate !== curTopicContent.EndDate) {
          updateItems.push(topicContent);
        }
      } else {
        insertItems.push(topicContent);
      }
    });

    return asyncAction('Cập nhật danh sách tin', () => {
      return topicContentApi.submit(topic.TopicID, { insertItems, updateItems, deleteItems })
        .then(() => {
          const newList = list.slice(0, TOPIC_SYSTEM_ORDERED_ITEMS);
          setOriginList(newList);
          setList(newList);
        });
    });
  }

  function handleMove(_, fromIndex, toIndex, fromUpDown) {
    if (!list[toIndex]) { // that moving out of the list should not happen
      return;
    }

    const movedList = list.slice();
    const movedItem = movedList[fromIndex];
    const scheduled = isScheduled(movedItem);

    if (fromUpDown) {
      const newOrder = movedItem.Order + toIndex - fromIndex;

      if (isScheduled(movedItem)) {
        movedItem.Order = newOrder;
      } else {
        const targetItem = movedList.find(item => !isScheduled(item) && item.Order === newOrder);

        if (!targetItem) {
          return;
        }

        targetItem.Order = movedItem.Order;
        movedItem.Order = newOrder;
      }

      sort(movedList);
    } else {
      movedList.splice(fromIndex, 1);
      movedList.splice(toIndex, 0, movedItem);
    }

    normalizeList(movedList, scheduled);
    setList(movedList);
  }

  function onDateChange(item) {
    const items = list.slice();

    const index = lodash.findIndex(items, { ContentID: item.ContentID });

    if (index > -1) {
      items[index] = item;
    }

    normalizeList(items, isScheduled(item));
    setList(items);
  }

  function handleSaveAndExit() {
    handleSave().then(closeTopic);
  }
}

export function normalizeList(items = [], scheduledItemChanged) {
  if (scheduledItemChanged) {
    normalizeOrder(items);
  } else {
    // change to non-scheduled item must not affect scheduled items
    // here we backup the order then restore them later
    const backupOrderMap = items.reduce((previous, current, index) => {
      if (isScheduled(current)) {
        previous[index] = current.Order;
      }

      return previous;
    }, {});

    normalizeOrder(items);

    Object.keys(backupOrderMap).forEach((index) => {
      items[index].Order = backupOrderMap[index];
    });
  }

  sort(items);
}

function isScheduled(item) {
  const now = moment.now();

  return getDistributionDate(item) > now || (item.StartDate && item.StartDate > now);
}

function normalizeOrder(items = []) {
  let currentOrder = 1;

  items.forEach((item) => {
    item.Order = currentOrder;

    if (!isScheduled(item)) {
      currentOrder++;
    }
  });
}

function sort(items = []) {
  items.sort((a, b) => {
    if (a.Order === b.Order) {
      return Math.max(getDistributionDate(b), b.StartDate || 0) - Math.max(getDistributionDate(a), a.StartDate || 0);
    }

    return a.Order - b.Order;
  });
}

function getDistributionDate(item) {
  return lodash.get(item, 'Content.DistributionDate', 0);
}

function stripData(item) {
  return {
    TopicID: item.TopicID,
    ContentID: item.ContentID,
    Order: item.Order,
    StartDate: item.StartDate ? item.StartDate : undefined,
    EndDate: item.EndDate ? item.EndDate : undefined,
  };
}
