import React from 'react';
import { Form, Input, Button } from 'antd';
import Selector from 'core/components/common/Selector';
import commonStyles from 'core/common/commonStyles';
import { AccountSiteOption } from 'core/engine/account/constants';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export function ChangeProfileForm({
  form,
  onSubmit,
  account,
  submitOnEnter = true,
}) {
  const { getFieldDecorator } = form;

  return (
    <Form {...formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Email">
        {getFieldDecorator('Email', {
          rules: [
            {
              type: 'email',
              message: 'Email không hợp lệ!',
            },
            {
              required: true,
              message: 'Bắt buộc!',
            },
            {
              max: 250,
              message: 'Tối đa 250 kí tự!',
            },
          ],
          initialValue: account.Email,
        })(
          <Input />)}
      </Form.Item>
      <Form.Item label="Thông tin">
        {getFieldDecorator('Title', {
          initialValue: account.Title,
          rules: [
            {
              max: 250,
              message: 'Tối đa 250 kí tự!',
            },
          ],
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Bút danh">
        {getFieldDecorator('Pseudonym', {
          initialValue: account.Pseudonym,
          rules: [
            {
              max: 250,
              message: 'Tối đa 250 kí tự!',
            },
          ],
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Số điện thoại">
        {getFieldDecorator('Phone', {
          initialValue: account.Phone || '',
          rules: [
            {
              required: false,
              pattern: new RegExp('^[0-9]*$'),
              message: 'Số điện thoại không hợp lệ!',
            },
            {
              max: 12,
              message: 'Tối đa 12 kí tự!',
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Cơ sở">
        {getFieldDecorator('Site', {
          initialValue: account.Site,
        })(
          <Selector
            options={AccountSiteOption}
            emptyLabel="-- Cơ sở --"
            emptyValue=""
          />
        )}
      </Form.Item>
      {submitOnEnter && <Button style={commonStyles.hidden} htmlType="submit"></Button>}
    </Form>
  );
}
