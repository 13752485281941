import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import locationService from 'core/common/locationService';

export default function SyncLastLocation() {
  const { pathname, search } = useLocation();
  const lastLocationRef = useRef();

  useEffect(() => {
    if (lastLocationRef.current) {
      locationService.saveLastLocation(lastLocationRef.current);
    }

    lastLocationRef.current = { pathname, search };
  }, [pathname, search]);

  return null;
}
