import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LoginFormRaw } from './LoginForm';
import { Modal, Form, Typography, Icon, message } from 'antd';
import useAuth from 'core/hooks/useAuth';
import authApi from 'core/engine/auth/api';
import { setSessionExpired } from 'core/redux/actions/auth';
import useDialog from 'core/hooks/useDialog';
import locationService from 'core/common/locationService';
import useLoginForm from 'core/hooks/useLoginForm';

function LoginDialog({ form }) {
  const { open, handleClose, handleOpen } = useDialog();
  const { loggedInUser, sessionExpired } = useAuth();
  const dispatch = useDispatch();
  const { handleSubmit, isSubmitting, formRef } = useLoginForm({ form, submitFn });

  useEffect(() => {
    if (loggedInUser && sessionExpired) {
      handleOpen();
    } else if (open) {
      handleClose();
    }
  }, [loggedInUser, sessionExpired]);

  return (
    <Modal
      destroyOnClose
      keyboard={false}
      maskClosable={false}
      title="Đăng nhập để tiếp tục..."
      visible={open}
      onCancel={onCancel}
      onOk={handleSubmit}
      confirmLoading={isSubmitting}
      width={400}
    >
      <LoginFormRaw
        form={form}
        onSubmit={handleSubmit}
        username={loggedInUser ? loggedInUser.name : ''}
        ref={formRef}
      />
      <Typography.Text style={{ float: 'right' }} type="danger"><Icon type="warning" />
        &nbsp;Trang sẽ được tải lại sau khi đăng nhập thành công. Tất cả thay đổi chưa được lưu sẽ mất.
      </Typography.Text>
      <br />
    </Modal>
  );

  function onCancel() {
    dispatch(setSessionExpired(false));
  }

  function submitFn({ formData }) {
    return authApi.login(formData)
      .then(() => {
        locationService.reload();
      })
      .catch((err) => {
        message.error(err.error_message);
      });
  }
}

export default Form.create({ name: 'LoginDialog' })(LoginDialog);
