import { useEffect } from 'react';
import remoteLogApi from 'core/engine/remote-log/api';
import useAuth from 'core/hooks/useAuth';

export default function ErrorLogger() {
  const { loggedInUser } = useAuth();

  useEffect(() => {
    if (loggedInUser) {
      const handleError = (event) => {
        remoteLogApi.logUncaughtException(
          (event.error && event.error.stack) || `${event.message} at ${event.filename}:${event.lineno}:${event.colno}`
        );
      };

      window.addEventListener('error', handleError);

      return () => {
        window.removeEventListener('error', handleError);
      };
    }
  }, [loggedInUser]);

  return null;
}
