import { Form } from 'antd';
import LinkInput from 'core/components/common/LinkInput';
import useAuth from 'core/hooks/useAuth';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React, { useMemo } from 'react';

export default function ContentFormItemFrameLink({ content, form }) {
  const { getFieldDecorator } = form;
  const { loggedInUser, hasExtendedRight } = useAuth();

  const canEditFrameLink = useMemo(() => {
    return loggedInUser.isAdmin || hasExtendedRight('content.edit-frame-link');
  }, [loggedInUser, hasExtendedRight]);

  return (
    <Form.Item label={ContentFieldLabel.FrameLink}>
      {getFieldDecorator('FrameLink', {
        initialValue: content.FrameLink,
      })(
        <LinkInput placeholder="http://..." maxLength={255} disabled={!canEditFrameLink} />
      )}
    </Form.Item>
  );
}
