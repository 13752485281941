import React, { useState, useEffect } from 'react';
import { getAccountSiteCache } from 'core/engine/account/caching';
import makeStyles from 'core/common/makeStyles';
import { grey } from '@ant-design/colors';

const useStyles = makeStyles(() => ({
  site: {
    textTransform: 'uppercase',
    fontWeight: 600,
    paddingRight: 5,
    color: grey[5],
  },
}));

export default function AccountSiteDisplay({ id, ...props }) {
  const classes = useStyles();

  const [site, setSite] = useState('');

  useEffect(() => {
    getAccountSiteCache(id).then((resp) => setSite(resp));
  }, [id]);

  return (
    site ? <span className={classes.site} {...props}>({site})</span> : ''
  );
}
