import { Button, Form, Input, message, Skeleton, Typography } from 'antd';
import commonStyles from 'core/common/commonStyles';
import logger from 'core/common/logger';
import makeStyles from 'core/common/makeStyles';
import Container from 'core/components/common/Container';
import HtmlDisplay from 'core/components/common/HtmlDisplay';
import Separator from 'core/components/common/Separator';
import ContentBodyDisplay from 'core/components/content/ContentBodyDisplay';
import { CONTENT_CLASS, MediaQuery } from 'core/constants';
import toolApi from 'core/engine/tool/api';
import useForm from 'core/hooks/useForm';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import importContentService from '../services';
import { IMAGE_PROCESSING_ORIGIN_URL } from 'core/config';
import ImageDisplay from 'core/components/common/ImageDisplay';
import classnames from 'classnames';

const useStyles = makeStyles(() => ({
  form: {
    ...commonStyles.flex,
    flexWrap: 'wrap',
  },
  input: {
    width: '100%',
    marginBottom: 10,

    [MediaQuery.lg]: {
      width: 'calc(100% - 200px)',
    },
  },
  load: {
    [MediaQuery.lg]: {
      marginLeft: 'auto',
    },
  },
  contentWrapper: {
    height: 'calc(100% - 84px)',
    marginTop: 10,
    paddingBottom: 20,
    overflow: 'auto',

    [MediaQuery.lg]: {
      height: 'calc(100% - 42px)',
    },
  },
  wrapper: {
    maxWidth: 660,
    display: 'block',
    margin: '0 auto',
  },
  avatarImage: {
    marginBottom: 20,
  },
}));

export default function ImportContentView() {
  const classes = useStyles();

  const history = useHistory();

  const { formData, handleChange } = useForm({ url: '' });
  const [isLoading, setIsLoading] = useState(false);

  const [content, setContent] = useState();
  const contentRef = useRef();
  const avatarImageRef = useRef();

  useEffect(() => {
    if (content && !isLoading && contentRef.current) {
      contentRef.current.querySelectorAll('img').forEach((element) => {
        const imageUrl = element.getAttribute('src');

        element.onerror = () => {
          element.onerror = null;
          element.setAttribute('src', `${IMAGE_PROCESSING_ORIGIN_URL}?imageUrl=${imageUrl}`);
        };
      });
    }
  }, [content, isLoading]);

  return (
    <Container pageTitle="Lấy tin tự động">
      <Form className={classes.form} layout="inline" onSubmit={handleSubmit}>
        <Input
          placeholder="Nhập URL..."
          className={classes.input}
          type="url"
          name="url"
          value={formData.url}
          onChange={handleChange}
          required
          autoFocus
        />
        <Button className={classes.load} htmlType="submit" disabled={!formData.url || isLoading}>Lấy tin</Button>
        <Separator />
        <Button type="primary" icon="edit" onClick={handleCompose} disabled={!content}>Soạn tin</Button>
      </Form>

      {isLoading && <Skeleton />}

      {content && !isLoading && (
        <div className={classes.contentWrapper} ref={contentRef}>
          <Typography.Text className={classes.wrapper} type="secondary" strong>{content.SubTitle}</Typography.Text>
          <Typography.Title className={classes.wrapper} level={3}>{content.Title}</Typography.Title>
          <ImageDisplay
            ref={avatarImageRef}
            className={classnames(classes.wrapper, classes.avatarImage)}
            src={content.AvatarUrl}
          />
          <Typography.Text className={classes.wrapper} strong>
            <HtmlDisplay html={content.Description} />
          </Typography.Text>

          <ContentBodyDisplay content={content.HtmlBody} />
        </div>
      )}
    </Container>
  );

  function handleSubmit(event) {
    event && event.preventDefault();

    setIsLoading(true);
    setContent(null);

    toolApi.crawl(formData.url)
      .then((resp) => {
        setIsLoading(false);
        resp.data.HtmlBody = importContentService.normalize(resp.data.HtmlBody);
        setContent(resp.data);
      })
      .catch((err) => {
        logger.error('Error while import content:', err);
        message.error('Có lỗi xảy ra!');
        setIsLoading(false);
      });
  }

  function handleCompose() {
    const parsedContent = { ...content };

    if (avatarImageRef.current) {
      parsedContent.AvatarUrl = avatarImageRef.current.src;
    }

    if (contentRef.current) {
      parsedContent.HtmlBody = contentRef.current.querySelector('.' + CONTENT_CLASS).innerHTML;
    }

    history.push({ pathname: '/contents/compose', state: { content: parsedContent } });
  }
}
