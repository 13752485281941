import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import { parseQuery } from 'core/common/urlHelper';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import GoBackButton from 'core/components/common/GoBackButton';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import interviewGuestApi from 'core/engine/interview-guest/api';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useInterviewLoader from '../../hooks/useInterviewLoader';
import InterviewAuthz from '../InterviewAuthz';
import InterviewGuestFormDialogButton from './InterviewGuestFormDialogButton';
import InterviewGuestList from './InterviewGuestList';
import { INTERVIEW_MAX_GUESTS } from 'core/config';

function listFn({ offset, filters, sorting }) {
  return interviewGuestApi.getList({
    limit: INTERVIEW_MAX_GUESTS,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default function InterviewGuestListView() {
  const location = useLocation();
  const interviewId = useMemo(() => parseQuery(location.search).interviewId, []);
  const { interview, isLoading } = useInterviewLoader(interviewId);

  useEffect(() => {
    if (!isLoading) {
      listProps.addFilter('InterviewID', interviewId);
    }
  }, [isLoading, interviewId]);

  const listProps = useList({
    listFn,
    defaultSorting: {
      Order: 'ASC',
    },
  });

  return (
    <Container pageTitle="Khách mời" loading={isLoading}>
      <InterviewAuthz interview={interview}>
        <TableToolbarWrapper>
          <GoBackButton />
          {interview.isAdmin ? (
            <InterviewGuestFormDialogButton
              guest={{
                InterviewID: interviewId,
                Order: listProps.items.length + 1,
              }}
              isEditMode={false}
              onDone={listProps.refresh}
            />
          ) : null}
          <FlexPushRight />
          <span>Giao lưu trực tuyến: {interview.Title}</span>
        </TableToolbarWrapper>
        <InterviewGuestList
          {...useListProps(listProps)}
          onUpdate={listProps.refresh}
          onMultiUpdate={handleMultiUpdate}
          onDelete={handleDelete}
          interview={interview}
        />
      </InterviewAuthz>
    </Container>
  );

  function handleDelete(guest) {
    return asyncAction('Xóa khách mời khỏi giao lưu trực tuyến', () => interviewGuestApi.delete(guest).then(listProps.refresh));
  }

  function handleMultiUpdate(guests) {
    return asyncAction('Cập nhật thứ tự khách mời', () => interviewGuestApi.update(guests).then(listProps.refresh));
  }
}
