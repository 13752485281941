import { Form } from 'antd';
import React from 'react';
import ContentFormItemAuthorMultipleInput from './ContentFormItemAuthorMultipleInput';

export default function ContentFormItemAuthor({ content, form }) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item>
      {getFieldDecorator('contentAuthors', {
        initialValue: content.ContentAuthor || [{ AuthorID: 0, isManaged: true }, { AuthorID: 0 }],
      })(
        <ContentFormItemAuthorMultipleInput />
      )}
    </Form.Item>
  );
}
