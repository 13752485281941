import { Config, ToolbarIcon } from '@zdocs/core';
import lodash from 'core/common/lodash';
import FileManagerDialog from 'core/components/file-manager/FileManagerDialog';
import { FileType } from 'core/engine/file/constants';
import BaseDialogPlugin from 'core/components/editor/plugins/BaseDialogPlugin';
import React from 'react';
import icon from './icon.svg';

export default class AddAudioPlugin extends BaseDialogPlugin {

  static install() {
    ToolbarIcon.setIcon('AddAudio', icon);

    Config.prototype.controls.audio = {
      tooltip: 'Chèn audio',
      icon: 'AddAudio',
    };
  }

  get name() {
    return 'AddAudioPlugin';
  }

  get command() {
    return 'audio';
  }

  get defaultOptions() {
    return {
      template: `
        <table class="audio">
          <tbody>
            <tr>
              <td><audio controls data-audio-id="$AudioId" data-duration="$Duration" style="width: 100%"><source src="$Src" type="$Type"></source>Trình duyệt của bạn không hỗ trợ hiển thị audio.</audio></td>
            </tr>
            <tr>
              <td class="caption"><p></p></td>
            </tr>
          </tbody>
        </table>`,
    };
  }

  renderDialog(props) {
    return (
      <FileManagerDialog
        focusTriggerAfterClose={false}
        title="Chèn audio"
        multiple
        fileType={FileType.AUDIO}
        {...props}
      />
    );
  }

  onSelect(files) {
    super.onSelect();

    const html = files.map(file => {

      return this.options.template
        .replace('$AudioId', file.FileID)
        .replace('$Src', file.url)
        .replace('$Type', file.MimeType)
        .replace('$Duration', lodash.get(file, 'Meta.duration') || 0);
    }).join('');

    this.insertHTMLToEditor(html, true, false, '.caption');
  }
}
