import { responseToJson } from 'core/common/http-client';
import { LIBRE_CAPTCHA_URL } from 'core/config';

const DEFAULT_PARAMS = {
  level: 'medium',
  media: 'image/png',
  size: '270x80',
  input_type: 'text',
};

const libreCaptchaApi = {
  getCaptcha(customParams = {}) {
    const params = { ...DEFAULT_PARAMS, ...customParams };

    return fetch(LIBRE_CAPTCHA_URL + '/v2/captcha', {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(responseToJson);
  },
};

export default libreCaptchaApi;
