export function removeBitField(form, field, bit) {
  const { getFieldValue, setFieldsValue } = form;
  let sum = getFieldValue(field);

  if (hasBit(sum, bit)) {
    sum -= bit;
    setFieldsValue({ [field]: sum });
    return sum;
  }

  return sum;
}

export function addBitField(form, field, bit) {
  const { getFieldValue, setFieldsValue } = form;
  let sum = getFieldValue(field);

  if (hasBit(sum, bit)) {
    return sum;
  }

  sum += bit;
  setFieldsValue({ [field]: sum });
  return sum;
}

export function removeBit(sum, bit) {
  if (hasBit(sum, bit)) {
    sum -= bit;
    return sum;
  }

  return sum;
}

export function addBit(sum, bit) {
  if (hasBit(sum, bit)) {
    return sum;
  }

  sum += bit;
  return sum;
}

export function hasBit(sum, bit) {
  return (sum & bit) === bit;
}
