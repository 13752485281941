/* eslint-disable import/namespace */
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import logger from 'core/common/logger';
import ZDocs from '@zdocs/core';
import './styles/styles.less';
import '@zdocs/core/dist/zdocs.min.css';
import Plugins from './plugins';
import * as Icons from './styles/icons';
import makeStyles from 'core/common/makeStyles';
import actionRegistry from 'core/registries/actions';
import { EditorHookAction } from 'core/components/editor/constants';
import { normalizeEditorValue } from 'core/common/editor';

// Icons
Object.keys(Icons)
  .forEach((key) => {
    ZDocs.modules.ToolbarIcon.icons[key] = Icons[key];
  });

//Plugins
Object.keys(Plugins)
  .forEach((key) => {
    const Plugin = Plugins[key];
    ZDocs.plugins.add(key, Plugin);
    Plugin.install && Plugin.install();
  });

const useStyles = makeStyles(() => ({
  disableStatusbar: {
    '& .zd_statusbar': {
      display: 'none !important',
    },
  },
}));

export default forwardRef(function Editor({
  value,
  editorRef,
  name,
  options,
  workplaceClassName,
  containerClassName,
  showStatusBar = true,
  onValueChange,
  onBlur,
// eslint-disable-next-line no-unused-vars
}, ref) {
  const classes = useStyles();
  const containerRef = useRef();
  const zdocsRef = useRef();

  useImperativeHandle(editorRef, () => ({
    getValue: () => zdocsRef.current.getEditorValue(),
    getNormalizedValue: () => normalizeEditorValue(zdocsRef.current.getEditorValue()),
    getValueAsText: () => zdocsRef.current.editor.innerText,
    setValue: (value) => zdocsRef.current.setEditorValue(value),
    getEditorElement: () => zdocsRef.current.editor,
    registerEvent: (eventName, callback) => {
      zdocsRef.current.events.on(eventName, callback);
    },
    unregisterEvent: (eventName, callback) => {
      zdocsRef.current.events.off(eventName, callback);
    },
    setMinHeight: (minHeight = 0) => {
      const zdocs = zdocsRef.current;

      zdocs.container.style.minHeight = minHeight + 'px';
      zdocs.events.fire('setMinHeight', minHeight);
      const workplaceMinHeight = minHeight - zdocs.toolbar.container.offsetHeight - zdocs.statusbar.container.offsetHeight;
      zdocs.workplace.style.minHeight = workplaceMinHeight + 'px';
      zdocs.events.fire('setMinHeight', workplaceMinHeight);
      zdocs.editor.style.minHeight = (workplaceMinHeight - 2) + 'px';
      zdocs.events.fire('setMinHeight', (workplaceMinHeight - 2));
    },
    setStatusBarStickyOffset: (offset) => {
      const stickyContainer = zdocsRef.current.options.toolbarStickyContainer;

      if (stickyContainer) {
        zdocsRef.current.options.statusBarStickyOffset = offset;
        zdocsRef.current.events.fire(stickyContainer, 'scroll');
      }
    },
  }));

  useEffect(() => {
    const extendedOptions = {
      ...options,
      image: {
        autoFillSize: false,
      },
      cleanHTML: {
        denyTags: {
          base: true,
          script: true,
        },
      },
    };

    actionRegistry.doAction(EditorHookAction.BEFORE_RENDER_EDITOR, { name, options: extendedOptions });

    const zdocs = new ZDocs(containerRef.current, extendedOptions);

    if (workplaceClassName) {
      zdocs.workplace.classList.add(workplaceClassName);
    }

    if (containerClassName) {
      zdocs.container.classList.add(containerClassName);
    }

    if (!showStatusBar) {
      zdocs.container.classList.add(classes.disableStatusbar);
    }

    zdocsRef.current = zdocs;

    return () => {
      if (zdocs.isFullSize()) zdocs.toggleFullSize();
    };
  }, []);

  useEffect(() => {
    if (onValueChange) {
      zdocsRef.current.events.on(ZDocs.EVENTS.EDITOR_CHANGE, onValueChange);
      return () => zdocsRef.current.events.off(ZDocs.EVENTS.EDITOR_CHANGE, onValueChange);
    }
  }, [onValueChange]);

  useEffect(() => {
    if (onBlur) {
      zdocsRef.current.events.on('blur', onBlur);
      return () => zdocsRef.current.events.off('blur', onBlur);
    }
  }, [onBlur]);

  useEffect(() => {
    zdocsRef.current.value = value;
    logger.debug('Replaced editor content:', value);
  }, [value]);

  return (
    <textarea ref={containerRef} />
  );
});
