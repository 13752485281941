import { Icon } from 'antd';
import React, { useCallback, useMemo, useRef, Fragment } from 'react';
import { buildCdnPhotoUrl } from 'core/common/cdn';
import PhotoEditorDialog from 'core/components/photo-editor/PhotoEditorDialog';
import fileApi from 'core/engine/file/api';
import { asyncAction } from 'core/common/async';

export default function FilePhotoEditButton({ file, onSaved }) {
  const dialogRef = useRef();

  const photoUrl = useMemo(() => {
    if (file) {
      return buildCdnPhotoUrl(file.Path);
    }
  }, [file]);

  const handleClick = useCallback(() => {
    dialogRef.current.open();
  }, []);

  const handleSave = useCallback((image) => {
    const data = {
      width: image.width,
      height: image.height,
      OriginID: file.FileID,
    };

    return asyncAction('Lưu ảnh', () => {
      return fileApi.uploadPhotoFromUrl(image.data, file.Name, data)
        .then((resp) => {
          onSaved(resp.data.item);
        });
    });
  }, [file]);

  return (
    <Fragment>
      <Icon type="edit" title="Chỉnh sửa ảnh" onClick={handleClick} />
      <PhotoEditorDialog ref={dialogRef} title={`Sửa ảnh: ${file.Name}`} src={photoUrl} onSave={handleSave} />
    </Fragment>
  );
}
