import { getAuthorCache } from 'core/engine/author/caching';
import React, { useEffect, useState } from 'react';

export default function AuthorDisplay({ id, ...props }) {
  const [name, setName] = useState('#');

  useEffect(() => {
    if (id > 0) {
      getAuthorCache(id).then((author) => setName(author.FullName));
    } else {
      setName('#');
    }
  }, [id]);

  return (
    <span {...props}>{name}</span>
  );
}
