import React from 'react';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DeleteButton from 'core/components/common/DeleteButton';
import { Button } from 'antd';
import DraggableTable from 'core/components/table/DraggableTable';
import { commonColumns } from 'core/common/listUtils';
import { widgetRegistry } from 'core/modules/operations/widgets/registry';
import WidgetTypeDisplay from './common/WidgetTypeDisplay';
import WidgetFormDialogButton from './WidgetFormDialogButton';

export default function WidgetDistributionsList({
  onMove,
  onUpdate,
  onDelete,
  list,
}) {
  const columns = [
    commonColumns.order,
    {
      Header: 'Phân loại',
      accessor: 'Widget.Type',
      Cell: ({ value }) => <WidgetTypeDisplay type={value} />,
      width: 120,
    },
    {
      Header: 'Tên Widget',
      accessor: 'Widget.Title',
      minWidth: 250,
    },
    DraggableTable.MOVE_COLUMN,
    {
      Cell: ({ original }) => {
        const widget = widgetRegistry.get(original.Widget && original.Widget.Type);
        const FormItemMeta = widget && widget.FormItemMeta;

        return (
          <ActionsCellWrapper>
            {FormItemMeta && !original.Widget.PreDefined ? (
              <WidgetFormDialogButton
                widget={original.Widget}
                onUpdate={(data) => onUpdate(data, original.DistributionID)}
                availableWidgets={[widget]}
                isEditMode
              />
            ) : <Button icon="edit" title="Widget định sẵn. Sửa trong trang Widgets." disabled />}
            <DeleteButton
              title="Xóa widget khỏi giao diện?"
              onDelete={() => onDelete(original)}
            />
          </ActionsCellWrapper>
        );
      },
      width: 100,
    },
  ];

  return (
    <DraggableTable
      columns={columns}
      onMove={onMove}
      data={list}
      fullHeight={false}
      maxHeightAdjust={40}
    />
  );
}
