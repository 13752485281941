import { commonColumns, sortingToSortBy } from 'core/common/listUtils';
import AccountDisplay from 'core/components/account/AccountDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import BaseTable from 'core/components/table/BaseTable';
import voteLogApi from 'core/engine/vote-log/api';
import { VoteAttributesLabel } from 'core/engine/vote/constants';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React from 'react';
import VoteOptionDisplay from './VoteOptionDisplay';

function listFn({ offset, limit, filters, sorting }) {
  return voteLogApi.getList({
    offset,
    limit,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default function VoteLog({ voteId }) {
  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultPageSize: 10,
    defaultSorting: {
      ModifiedDate: 'DESC',
    },
    defaultFilters: {
      VoteID: voteId,
    },
  });

  const columns = [
    {
      Header: 'Tiêu đề',
      accessor: 'Title',
      minWidth: 200,
    },
    {
      headerStyle: { justifyContent: 'center' },
      Header: 'Câu hỏi',
      accessor: 'Description',
      minWidth: 160,
    },
    {
      ...commonColumns.allCentered,
      Header: 'Loại',
      accessor: 'Attributes',
      Cell: ({ value }) => VoteAttributesLabel[value],
      width: 120,
    },
    {
      Header: 'Lựa chọn (Lượt chọn)',
      Cell: ({ original }) => <VoteOptionDisplay voteOptions={original.Options} />,
      minWidth: 200,
    },
    {
      ...commonColumns.allCentered,
      Header: 'Người sửa',
      accessor: 'EditorID',
      Cell: ({ value }) => <AccountDisplay id={value} />,
      width: 85,
    },
    {
      Header: 'Ngày sửa',
      accessor: 'ModifiedDate',
      Cell: ({ value }) => <DateTimeDisplay timestamp={value} />,
      width: 130,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...useListProps(listProps)}
    />
  );
}
