import Selector from '../common/Selector';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import interviewApi from 'core/engine/interview/api';
import { getMapAccountCache } from 'core/engine/account/caching';

export default forwardRef(function InterviewEditorSelector({ interviewId, value, onChange, ...props }, ref) {
  const [editorAccounts, setEditorAccounts] = useState([]);

  useEffect(() => {
    interviewApi.get(interviewId).then(resp => {
      const editorIds = resp.data.item.Editors;

      if (editorIds) {
        getMapAccountCache().then(map => setEditorAccounts(editorIds.split(',').map(id => map[id]).filter(Boolean)));
      }
    });
  }, [interviewId]);

  const ids = useMemo(() => value ? value.split(',').map(item => parseInt(item)) : [], [value]);

  return (
    <Selector
      ref={ref}
      onChange={handleChange}
      valueIsNumber
      value={ids}
      options={editorAccounts}
      {...props}
    />
  );

  function handleChange(newValue) {
    onChange(newValue.join(','));
  }
});
