import { IS_MOBILE } from '@bm/common';
import { BackTop } from 'antd';
import lodash from 'core/common/lodash';
import { EditorButtonPreset } from 'core/components/editor/constants';
import Editor from 'core/components/editor/Editor';
import { HEADER_HEIGHT } from 'core/constants';
import React, { forwardRef, Fragment, useEffect } from 'react';
import { Sites } from 'core/engine/site/constants';

const BOTTOM_PADDING = 24;

export default forwardRef(function ContentFormContentInput({
  value,
  editorRef,
  descriptionEditorRef,
  avatarDescriptionEditorRef,
  stickyContainer,
  sidebarHeight,
  actionToolbarHeight,
  form,
  siteId = Sites.ALL,
}, ref) { // eslint-disable-line no-unused-vars
  useEffect(() => {
    if (sidebarHeight) {
      editorRef.current.setMinHeight(sidebarHeight);
    }
  }, [sidebarHeight]);

  useEffect(() => {
    if (actionToolbarHeight) {
      editorRef.current.setStatusBarStickyOffset(BOTTOM_PADDING + actionToolbarHeight);
    }
  }, [actionToolbarHeight]);

  const options = {
    toolbarStickyContainer: stickyContainer,
    zIndex: 0,
    readonly: false,
    editorCssClass: 'cms-editor',
    placeholder: 'Nội dung bài viết',
    width: 'auto',
    height: 'auto',
    minHeight: 800,
    disablePlugins: [],
    buttons: EditorButtonPreset.FULL,
    buttonsMD: EditorButtonPreset.FULL,
    buttonsXS: EditorButtonPreset.FULL,
    buttonsSM: EditorButtonPreset.FULL,
    toolbarSticky: !IS_MOBILE,
    toolbarStickyOffset: HEADER_HEIGHT,
    statusBarSticky: !IS_MOBILE,
    statusBarStickyOffset: BOTTOM_PADDING + 42, // initial length of the toolbar
    pluginsOptions: {
      SpellCheckerPlugin: {
        getContent() {
          return {
            form: form,
            ContentID: form.getFieldValue('ContentID') || 0,
            Title: form.getFieldValue('Title'),
            Description: descriptionEditorRef.current ? descriptionEditorRef.current.getValue() : form.getFieldValue('Description'),
            HtmlBody: editorRef.current.getValue(),
            AvatarDescription: lodash.get(avatarDescriptionEditorRef, 'current.state.value', '')
                                || lodash.get(form.getFieldValue('avatar'), 'description', ''),
          };
        },
      },
    },
  };

  return (
    <Fragment>
      <Editor
        value={value}
        editorRef={editorRef}
        name="content-body"
        options={options}
        siteId={siteId}
        workplaceClassName="cms-workplace"
      />
      {editorRef.current ? (
        <BackTop target={editorRef.current.getEditorElement} visibilityHeight={0} />
      ) : null}
    </Fragment>
  );
});
