const interviewServices = {
  isAdmin: (interview, user) => {
    return (interview.Admins || '').split(',').map(Number).includes(user.id);
  },
  isEditor: (interview, user) => {
    return (interview.Editors || '').split(',').map(Number).includes(user.id);
  },
  isInterviewer: (interviewGuest, user) => {
    return (interviewGuest.Interviewers || '').split(',').map(Number).includes(user.id);
  },
};

export default interviewServices;
