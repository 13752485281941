import { getMapZoneCache } from './caching';

const zoneUtils = {
  getSubsByRootId(zoneId) {
    return getMapZoneCache().then(resp => getSubsByMapRootId(resp, zoneId));
  },
};

export function getSubsByMapRootId(map, zoneId) {
  const zoneMap = { ...map };
  const zone = zoneMap[zoneId];

  if (!zone) {
    return [];
  }

  const subZones = [zone];
  const subZoneIds = [zone.ZoneID];

  let descendantIds = [];

  do {
    subZoneIds.forEach(zoneId => delete zoneMap[zoneId]);

    descendantIds = Object.keys(zoneMap).filter(zoneId => subZoneIds.includes(zoneMap[zoneId].ParentID)).map(Number);

    subZoneIds.push(...descendantIds);
    subZones.push(...descendantIds.map(zoneId => zoneMap[zoneId]));
  } while (descendantIds.length);

  return subZones;
}

export default zoneUtils;
