import React, { useState, useMemo, useEffect, forwardRef } from 'react';
import { getListAccountCache } from 'core/engine/account/caching';
import Selector from '../common/Selector';
import { getCurrentStore } from 'core/redux/store';

const AccountSelector = forwardRef(function AccountSelector({ value, onChange, extendItems = [], allAccounts = false, ...props }, ref) {
  const [options, setOptions] = useState([]);

  const currentUser = useMemo(() => {
    return getCurrentStore().getState().auth.user;
  }, []);

  useEffect(() => {
    let active = true;

    getListAccountCache().then((resp) => {
      if (!active) {
        return;
      }

      const options = [
        ...extendItems.map((item) => ({ AccountID: item.value, UserName: item.label })),
        ...resp.data.items
          .filter((account) => allAccounts || account.IsApproved)
          .map((item) => ({ AccountID: item.AccountID, UserName: item.Username })),
      ];

      if (currentUser) {
        options.forEach((item, index) => {
          if (item.AccountID === currentUser.id) {
            options.splice(index, 1);
            options.unshift(item);
          }
        });
      }

      setOptions(options);
    });

    return () => active = false;
  }, []);

  return (
    <Selector
      ref={ref}
      valueField="AccountID"
      labelField="UserName"
      options={options}
      value={value}
      onChange={onChange}
      valueIsNumber={true}
      {...props}
    />
  );
});

export default AccountSelector;

export function AccountFilterForList({ filter, onChange }) {
  return (
    <AccountSelector value={filter ? filter.value : null} selectProps={{ allowClear: true }} onChange={onChange} />
  );
}
