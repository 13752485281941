import React, { useMemo } from 'react';
import { Avatar } from 'antd';
import { buildCdnPhotoUrl } from 'core/common/cdn';

export default function AvatarDisplay({ url, thumbWidth = 150, thumbHeight = 150, size = 50, ...props }) {
  const avatarUrl = useMemo(() => {
    return url ? (
      url.startsWith('/') ? buildCdnPhotoUrl(url, thumbWidth, thumbHeight) : url
    ) : undefined;
  }, [url]);

  return <Avatar src={avatarUrl} shape="square" size={size} {...props} />;
}
