import { startApp } from 'core/index';
import 'src/styles/content/index.less';
import 'src/styles/editor/theme.less';
import * as editor from './components/editor';
import TopicForm from './components/topic/TopicForm';
import ZoneFormRaw from './components/zone/ZoneFormRaw';
import * as modules from './modules';
import ContentPreviewButtonForList from 'src/components/contents/ContentPreviewButtonForList';
import ContentFormToolbarPreviewButton
from 'src/modules/contents/components/form/toolbar/ContentFormToolbarPreviewButton';

editor.init();
modules.register();
startApp({
  ComponentList: {
    ExtTopicForm: TopicForm,
    ExtZoneFormRaw: ZoneFormRaw,
    ContentPreviewButtonForList: ContentPreviewButtonForList,
    ContentFormToolbarPreviewButton: ContentFormToolbarPreviewButton,
  },
});
