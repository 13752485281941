import React from 'react';
import makeStyles from 'core/common/makeStyles';
import commonStyles from 'core/common/commonStyles';
import classnames from 'classnames';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flex,
  },
}));

export default function FlexDiv({ className, style, children, ...props }) {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.container, className)}
      style={style}
      {...props}
    >{children}</div>
  );
}
