import React from 'react';
import _ from 'lodash';
import useList from 'core/hooks/useList';
import OrderCellForList from 'core/components/common/OrderCellForList';
import NumberDisplay from 'core/components/common/NumberDisplay';

export function listConnectHook({ listFn, listId, ...userListProps }, Component) {
  return (props) => {
    const listProps = useList({ listFn, listId, ...userListProps });

    return (
      <Component {...listProps} {...props} />
    );
  };
}

export function fromToDateToFilter(from, to) {
  return 'bw:' + from + ',' + (to - 1);
}

export function dateRangeToFilter(range) {
  const from = range[0].startOf('day').format('x');
  const to = range[1].endOf('day').format('x');

  return fromToDateToFilter(from, to);
}

export function sortingToSortBy(sorting) {
  return _.map(sorting, (value, key) => `${key}:${value}`).join(',');
}

export const commonColumns = {
  order: {
    maxWidth: 50,
    filterable: false,
    style: { justifyContent: 'center' },
    Cell: OrderCellForList,
  },

  formatNumber: {
    className: 'format-number',
    Cell: (row) => <NumberDisplay value={row.value} />,
    headerStyle: { justifyContent: 'flex-end' },
  },

  allCentered: {
    headerStyle: { justifyContent: 'center' },
    style: { justifyContent: 'center' },
  },
};

export function reactTableToText() {
  let text = '';

  const headerElement = document.querySelector('.rt-table .rt-thead.-header .rt-tr');
  const bodyElement = document.querySelector('.rt-table .rt-tbody');

  if (headerElement) {
    text += Array.prototype.map.call(headerElement.querySelectorAll('.rt-th'), (header) => {
      return trimNewlineAndTab(header.innerText);
    }).join('\t');
  }

  if (bodyElement) {
    text += '\n' + Array.prototype.map.call(bodyElement.querySelectorAll('.rt-tr'), (row) => {
      return Array.prototype.map.call(row.querySelectorAll('.rt-td'), (column) => {
        return trimNewlineAndTab(column.innerText);
      }).join('\t');
    }).join('\n');
  }

  return text || 'No table data :(';
}

function trimNewlineAndTab(str = '') {
  return str.replace(/(\r?\n)+/g, '|').replace(/\t+/g, '|');
}

export function getDescendantZoneIds(zones, zoneId) {
  const output = [zoneId];
  const toScanZoneIds = [zoneId];

  while (toScanZoneIds.length > 0) {
    const currentZoneId = toScanZoneIds.pop();
    const childZoneIds = zones.filter(zone => zone.ParentID === currentZoneId).map(zone => zone.ZoneID);
    output.push(...childZoneIds);
    toScanZoneIds.push(...childZoneIds);
  }

  return output;
}

export function getAncestorZoneIds(zones, zoneId) {
  const zone = _.find(zones, { ZoneID: zoneId });

  if (!zone) {
    return [];
  }

  const output = [zoneId];
  let parent = _.find(zones, { ZoneID: zone.ParentID });

  while (parent) {
    output.push(parent.ZoneID);
    parent = _.find(zones, { ZoneID: parent.ParentID });
  }

  return output;
}
