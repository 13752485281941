import { USER_AGENT } from '@bm/common';
import { httpPost } from 'core/common/http-client';

const remoteLogApi = {
  logPasteEditor(data, mime) {
    return httpPost('/remote-logs/post/record', { type: 'paste', ua: USER_AGENT, data, mime });
  },

  logUncaughtException(data) {
    return httpPost('/remote-logs/post/record', { type: 'exception', ua: USER_AGENT, data });
  },
};

export default remoteLogApi;
