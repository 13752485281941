import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState, Fragment } from 'react';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import makeStyles from 'core/common/makeStyles';
import ImageBox from './ImageBox';
import FileManagerDialog from 'core/components/file-manager/FileManagerDialog';
import { Button } from 'antd';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: 300,
  },
  helper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    backgroundColor: '#FFF',
    color: '#888',
    paddingTop: 10,
    '& > *': {
      marginBottom: 10,
    },
  },
}));

export default forwardRef(function BeforeAfterImageDialog({ title, onSelect, onCancel }, ref) {
  const classes = useStyles();
  const { open, handleClose, handleOpen } = useDialog();
  const photoDialogRef = useRef();
  const [beforeImage, setBeforeImage] = useState();
  const [afterImage, setAfterImage] = useState();

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  const handleClick = useCallback(() => {
    photoDialogRef.current.open();
  }, []);

  return (
    <BaseDialog
      focusTriggerAfterClose={false}
      keyboard
      maskClosable
      visible={open}
      title={title}
      width={900}
      okText="Chèn"
      okButtonProps={{ disabled: !beforeImage || !afterImage }}
      onCancel={onCancel}
      onOk={() => onSelect([beforeImage, afterImage])}
      fullHeight
    >
      <div className={classes.container}>
        {beforeImage ? (
          <Fragment>
            <ImageBox
              image={beforeImage}
              placeholder="Ảnh Trước"
              onChangeImage={setBeforeImage}
            />
            <ImageBox
              image={afterImage}
              placeholder="Ảnh Sau"
              onChangeImage={setAfterImage}
            />
          </Fragment>
        ) : (
          <div className={classes.helper}>
            <Button size="large" title="Chọn ảnh" type="primary" ghost onClick={handleClick}>
              Chọn ảnh
            </Button>
            <div>Chọn 2 ảnh để làm ảnh trước và ảnh sau</div>
          </div>
        )}
      </div>
      <FileManagerDialog
        title={title}
        multiple
        ref={photoDialogRef}
        onSelect={handleImagesChange}
      />
    </BaseDialog>
  );

  function handleImagesChange(selectedImages) {
    if (selectedImages.length > 0) {
      setBeforeImage(selectedImages[0]);

      if (selectedImages.length > 1) {
        setAfterImage(selectedImages[1]);
      }
    }
  }
});
