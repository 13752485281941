import ZDocs, { Plugin } from '@zdocs/core';
import { IS_ANY_SAFARI } from '@bm/common';

export default class BasePlugin extends Plugin {

  static install() {
  }

  constructor(zdocs) {
    super(zdocs);
    this.Dom = ZDocs.modules.Dom;

    zdocs.events
      .on('afterInit', this.afterInit.bind(this, zdocs))
      .on('beforeDestruct', this.destruct);
  }

  get name() {
    return '';
  }

  insertHTMLToEditor(html, inCurrentBlock = false, addNewLine = true, focusSelector = '') {
    const selection = this.zdocs.selection;
    const crNode = selection.current();

    if (crNode) {
      const block = this.Dom.closest(crNode, node => this.Dom.isBlock(node, window), this.zdocs.editor);

      if (block && block !== this.zdocs.editor) {
        if (this.Dom.isEmpty(block)) {
          this.Dom.safeRemove(block);
        } else if (selection.isCollapsed()) {
          if (!inCurrentBlock || selection.cursorInTheEdge(false, block)) {
            selection.setCursorAfter(block);
          } else if (selection.cursorInTheEdge(true, block)) {
            selection.setCursorBefore(block);
          } else if (this.Dom.canSplitBlock(block, window)) {
            const curRange = selection.sel.getRangeAt(0);
            const leftRange = document.createRange();
            leftRange.setStartBefore(block);
            leftRange.setEnd(curRange.startContainer, curRange.startOffset);

            const rightRange = document.createRange();
            rightRange.setEndAfter(block);
            rightRange.setStart(curRange.endContainer, curRange.endOffset);

            const leftFragment = leftRange.extractContents();
            const rightFragment = rightRange.extractContents();

            if (block.parentNode) {
              !this.Dom.isEmpty(leftFragment) && block.parentNode.insertBefore(leftFragment, block);
              !this.Dom.isEmpty(rightFragment) && block.parentNode.insertBefore(rightFragment, block.nextSibling);
            }

            selection.setCursorAfter(block);
            this.Dom.safeRemove(block);
          }
        }
      }
    }

    selection.insertHTML(html);

    if (addNewLine) {
      const newline = this.zdocs.create.inside.element(this.zdocs.options.enter);

      selection.insertHTML(newline);
      focusSelector ? selection.setCursorBefore(newline) : selection.setCursorIn(newline);
    }

    if (focusSelector) {
      const currentNode = selection.current();
      const insertedElement = currentNode.previousElementSibling;

      if (insertedElement) {
        const focusElement = insertedElement.querySelector(focusSelector);

        if (focusElement) {
          const range = this.zdocs.editorDocument.createRange();
          range.selectNodeContents(focusElement);
          selection.selectRange(range);
        }
      }
    }

    BasePlugin.cleanAfterSetCursor(this.zdocs, this.Dom);
  }

  static cleanAfterSetCursor(zdocs, dom) {
    IS_ANY_SAFARI && (zdocs.editor.childNodes ? Array.prototype.slice.call(zdocs.editor.childNodes) : []).forEach(node => {
      if (dom.isEmptyTextNode(node)) {
        const nextSib = node.nextSibling;
        const prevSib = node.previousSibling;

        if (zdocs.selection.current() === node) {
          if (nextSib) {
            zdocs.selection.setCursorIn(nextSib);
          } else if (prevSib) {
            zdocs.selection.setCursorIn(prevSib, true);
          }
        }

        dom.safeRemove(node);
      }
    });
  }

  afterInit() {
  }

  destruct() {
    super.destruct();
  }
}
