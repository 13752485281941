import { Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import SeoEntryForm from 'core/components/seo-entry/SeoEntryForm';
import TagSelector from 'core/components/tag/TagSelector';
import { SeoEntryType } from 'core/engine/seo-entry/constants';
import React, { useCallback, useState } from 'react';

export default function TagForm({ form, onSubmit, tag }) {
  const { getFieldDecorator } = form;
  const [showUrl, setShowUrl] = useState(!tag.TagID || tag.ParentID === 0);

  const handleParentIDChange = useCallback((value) => setShowUrl(value <= 0), []);

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      {tag.TagID && (
        <Form.Item label="ID">
          {getFieldDecorator('TagID', {
            initialValue: tag.TagID,
          })(
            <Input disabled type="number" />
          )}
        </Form.Item>
      )}
      <Form.Item label="Từ khóa">
        {getFieldDecorator('Name', {
          rules: [
            { required: true, message: 'Bắt buộc!' },
            { max: 250, message: 'Tối đa 250 kí tự!' },
          ],
          initialValue: tag.Name,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Mục cha">
        {getFieldDecorator('ParentID', {
          initialValue: tag.ParentID || 0,
        })(
          <TagSelector onChange={handleParentIDChange} />
        )}
      </Form.Item>
      {showUrl && (
        <Form.Item label="Đường dẫn">
          {getFieldDecorator('Url', {
            rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
            initialValue: tag.Url,
          })(
            <Input />
          )}
        </Form.Item>
      )}
      <Form.Item label="Mô tả">
        {getFieldDecorator('Description', {
          rules: [{ max: 2000, message: 'Tối đa 2000 kí tự!' }],
          initialValue: tag.Description,
        })(
          <Input.TextArea />
        )}
      </Form.Item>
      <SeoEntryForm form={form} type={SeoEntryType.TAG} id={tag.TagID} />
    </Form>
  );
}
