import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
} from 'antd';
import commonStyles from 'core/common/commonStyles';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export function ChangePasswordFormRaw({
  form,
  onSubmit,
  submitOnEnter = true,
}) {
  const { getFieldDecorator } = form;

  const [confirmDirty, setConfirmDirty] = useState();
  const [isPassword, setIsPassword] = useState();

  return (
    <Form {...formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Mật khẩu cũ">
        {getFieldDecorator('OldPassword', {
          rules: [
            {
              required: true,
              message: 'Bạn chưa nhập mật khẩu cũ.',
            },
            {
              max: 250,
              message: 'Tối đa 250 kí tự!',
            },
          ],
        })(<Input.Password placeholder="Mật khẩu cũ" autoFocus />)}
      </Form.Item>
      <Form.Item label="Mật khẩu mới">
        {getFieldDecorator('NewPassword', {
          initialValue: '',
          rules: [
            {
              required: true,
              message: 'Bạn chưa nhập mật khẩu mới.',
            },
            {
              max: 250,
              message: 'Tối đa 250 kí tự!',
            },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/g,
              message: 'Mật khẩu cần ít nhất 8 kí tự, chứa ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số và 1 ký tự đặc biệt',
            },
            {
              validator: validateToNextPassword,
            },
          ],
        })(<Input.Password onBlur={handlePasswordBlur} placeholder="Mật khẩu mới" />)}
      </Form.Item>
      <Form.Item label="Nhập lại mật khẩu mới">
        {getFieldDecorator('ConfirmNewPassword', {
          initialValue: '',
          rules: [
            {
              required: isPassword,
              message: 'Hãy nhập lại mật khẩu',
            },
            {
              max: 250,
              message: 'Tối đa 250 kí tự!',
            },
            {
              validator: compareToFirstPassword,
            },
          ],
        })(<Input.Password onBlur={handleConfirmBlur} placeholder="Nhập lại mật khẩu mới" />)}
      </Form.Item>
      {submitOnEnter && <Button style={commonStyles.hidden} htmlType="submit"/>}
    </Form>
  );

  function handlePasswordBlur(e) {
    const { value } = e.target;
    setIsPassword(isPassword || !!value);
  }

  function handleConfirmBlur(e) {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  }

  function compareToFirstPassword(rule, value, callback) {
    if (value && value !== form.getFieldValue('NewPassword')) {
      callback('Mật khẩu bạn nhập không giống nhau');
    } else {
      callback();
    }
  }

  function validateToNextPassword(rule, value, callback) {
    if (value && confirmDirty) {
      form.validateFields(['ConfirmNewPassword'], { force: true });
    }

    callback();
  }
}
