import { Button } from 'antd';
import React, { useCallback, useState } from 'react';
import useMountedState from 'core/hooks/useMountedState';

export default function AsyncButton({ onClick, children, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMountedState();

  const handleClick = useCallback((event) => {
    event.persist();
    setIsLoading(true);

    Promise.resolve()
      .then(() => onClick(event))
      .then((data) => {
        isMounted() && setIsLoading(false);
        return data;
      }, (err) => {
        isMounted() && setIsLoading(false);
        return Promise.reject(err);
      });
  }, [onClick]);

  return (
    <Button onClick={handleClick} loading={isLoading} {...props}>{children}</Button>
  );
}
