import React from 'react';

export const BellIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
    <path fill="#FFF" d="M9 3.555l1.048.257c2.183.533 3.782 2.527 3.782 4.744v5.466l.395.395.533.55H3.242l.533-.55.395-.395V8.556c0-2.217 1.582-4.228 3.782-4.743L9 3.554zm0-3.18c-.79 0-1.375.653-1.375 1.46v.654c-2.733.636-4.83 3.111-4.83 6.067v4.916L.922 15.397v.962h16.156v-.962l-1.873-1.925V8.556c0-2.956-2.097-5.431-4.83-6.084v-.619c0-.687-.378-1.306-1.031-1.444C9.224.375 9.104.375 9 .375zm1.89 17.36H7.11c0 1.065.824 1.89 1.89 1.89 1.066 0 1.89-.825 1.89-1.89z" />
  </svg>
);

export const CommentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
    <path fill="#FFF" d="M8.983 1.733a6.873 6.873 0 0 1 6.875 6.875c0 3.214-2.286 6.05-5.414 6.72l-.602.12-.31.533-.532.911-.516-.91-.309-.534-.602-.12c-3.162-.653-5.465-3.489-5.465-6.737 0-3.782 3.076-6.858 6.875-6.858zm0-1.375a8.252 8.252 0 0 0-8.25 8.25 8.259 8.259 0 0 0 6.565 8.078l1.72 2.973 1.718-2.973c3.712-.808 6.497-4.108 6.497-8.061 0-4.572-3.696-8.267-8.25-8.267zM4.875 7.25c-.756 0-1.375.619-1.375 1.375S4.119 10 4.875 10 6.25 9.381 6.25 8.625 5.631 7.25 4.875 7.25zM9 7.25c-.756 0-1.375.619-1.375 1.375S8.244 10 9 10s1.375-.619 1.375-1.375S9.756 7.25 9 7.25zm4.125 0c-.756 0-1.375.619-1.375 1.375S12.369 10 13.125 10 14.5 9.381 14.5 8.625s-.619-1.375-1.375-1.375z" />
  </svg>
);

export const ErrorContentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill="#FFF" d="M10 1.75A8.252 8.252 0 0 1 18.25 10 8.252 8.252 0 0 1 10 18.25 8.252 8.252 0 0 1 1.75 10 8.252 8.252 0 0 1 10 1.75zm0-1.375C4.69.375.375 4.689.375 10c0 5.31 4.314 9.625 9.625 9.625 5.31 0 9.625-4.314 9.625-9.625 0-5.31-4.314-9.625-9.625-9.625zm-.688 13.75h1.376V15.5H9.312v-1.375zm0-9.625h1.376v8.25H9.312V4.5z" />
  </svg>
);

export const WaitApproveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
    <path fill="#FFF" d="M12.5 3.125H9.75A2.758 2.758 0 0 0 7 .375a2.758 2.758 0 0 0-2.75 2.75H1.5C.744 3.125.125 3.744.125 4.5v13.75c0 .756.619 1.375 1.375 1.375h11c.756 0 1.375-.619 1.375-1.375V4.5c0-.756-.619-1.375-1.375-1.375zM7 2.437a.69.69 0 0 1 .688.688.69.69 0 0 1-.688.688.69.69 0 0 1-.688-.688A.69.69 0 0 1 7 2.437zm5.5 15.813h-11V4.5h1.375v1.375h8.25V4.5H12.5v13.75zm-1.272-8.697l-5.362 5.345-.98-.98 5.345-5.345.997.98zm-7.305 1.461l2.922 2.922-.98.98-2.921-2.922.98-.98z" />
  </svg>

);

export const WaitPublicIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
    <path fill="#FFF" d="M12.6 2.857H9.8C9.8 1.286 8.54 0 7 0 5.46 0 4.2 1.286 4.2 2.857H1.4C.63 2.857 0 3.5 0 4.286V18.57C0 19.357.63 20 1.4 20h11.2c.77 0 1.4-.643 1.4-1.429V4.286c0-.786-.63-1.429-1.4-1.429zM7 2.143c.385 0 .7.321.7.714a.71.71 0 0 1-.7.714.71.71 0 0 1-.7-.714.71.71 0 0 1 .7-.714zm5.6 16.428H1.4V4.286h1.4v1.428h8.4V4.286h1.4V18.57zm-2.1-5.714H7.7v2.857H6.3v-2.857H3.5V11.43h2.8V8.57h1.4v2.858h2.8v1.428z" />
  </svg>
);

export const DraftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 26">
    <path fill="#FFF" d="M27.125 25.125H.875V23.25h26.25v1.875zm-2.156-20.11l-2.461 2.462-1.336 1.335L10.25 19.735l-5.625 1.641 1.64-5.625 14.72-14.719a.63.63 0 0 1 .421-.187c.117 0 .235.047.305.117l3.305 3.305c.21.21.164.515-.047.75zm-5.11 2.462L18.523 6.14 7.93 16.734l-.54 1.875 1.876-.539L19.859 7.477zm2.86-2.86l-1.336-1.336-1.524 1.524 1.336 1.336 1.524-1.524z" />
  </svg>
);

export const ReturnApproveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28">
    <path fill="#FFF" d="M17.5 4.625h-3.75A3.761 3.761 0 0 0 10 .875a3.761 3.761 0 0 0-3.75 3.75H2.5A1.88 1.88 0 0 0 .625 6.5v18.75A1.88 1.88 0 0 0 2.5 27.125h15a1.88 1.88 0 0 0 1.875-1.875V6.5A1.88 1.88 0 0 0 17.5 4.625zM10 3.687a.94.94 0 0 1 .938.938.94.94 0 0 1-.938.938.94.94 0 0 1-.938-.938.94.94 0 0 1 .938-.938zm7.5 21.563h-15V6.5h1.875v1.875h11.25V6.5H17.5v18.75zM9 20.625h1.375V22H9v-1.375zM9 11h1.375v8.25H9V11z" />
  </svg>
);

export const ReturnSubmitIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="28" viewBox="0 0 24 28">
    <path fill="#FFF" d="M4.5 18.875V6.5h1.875v1.875h8.438l3.75-3.75H15.75A3.761 3.761 0 0 0 12 .875a3.761 3.761 0 0 0-3.75 3.75H4.5A1.88 1.88 0 0 0 2.625 6.5v14.063L4.5 18.875zM12 3.687a.94.94 0 0 1 .938.938.94.94 0 0 1-.938.938.94.94 0 0 1-.938-.938.94.94 0 0 1 .938-.938zm11.602 1.266l-1.336-1.336L.188 25.695l1.335 1.336 1.196-1.195c.234.75.96 1.312 1.781 1.312h15a1.88 1.88 0 0 0 1.875-1.875V7.156l2.227-2.203zM19.5 25.25h-15v-1.172l15-15.21V25.25z" />
  </svg>
);
