import { Form } from 'antd';
import React from 'react';
import { Prompt } from 'react-router-dom';
import Container from 'core/components/common/Container';
import useContentForm from '../../hooks/useContentForm';
import useContentLocationSync from '../../hooks/useContentLocationSync';
import ContentForm from '../form/ContentForm';
import ContentComposeErrorBox from './ContentComposeErrorBox';
import useDataLoader from 'core/hooks/useDataLoader';
import settingApi from 'core/engine/setting/api';
import { SettingSystemKeys } from 'core/engine/setting/constants';

function loadFn() {
  return settingApi.multiGet([
    SettingSystemKeys.LimitedTitleCharacter,
    SettingSystemKeys.LimitedInputType(SettingSystemKeys.LimitedTitleCharacter),
    SettingSystemKeys.LimitedDescCharacter,
    SettingSystemKeys.LimitedInputType(SettingSystemKeys.LimitedDescCharacter),
    SettingSystemKeys.DescriptionPrefix,
  ])
    .then((resp) => {
      return resp.data.reduce((previous, current) => {
        previous[current.Key] = current.Value;
        return previous;
      }, {});
    });
}

function ContentComposeView({ form }) {
  const {
    content,
    setContent,
    resetContent,
    visibleActions,
    handleAction,
    getFormData,
    bodyEditorRef,
    descriptionEditorRef,
    isBlocking,
  } = useContentForm({ form });

  const { isLoading, error } = useContentLocationSync({ content, setContent, resetContent });

  const { data: config } = useDataLoader({ loadFn, defaultData: {} });

  if (error) {
    return (
      <Container>
        <ContentComposeErrorBox error={error} />
      </Container>
    );
  }

  return (
    <Container pageTitle={content.ContentID ? `Sửa tin: ${content.Title}` : 'Tạo tin mới'} loading={isLoading}>
      <Prompt
        when={isBlocking}
        message="Bạn đang biên tập tin, vui lòng đóng tin trước khi chuyển trang."
      />
      <ContentForm
        form={form}
        content={content}
        handleAction={handleAction}
        getFormData={getFormData}
        visibleActions={visibleActions}
        bodyEditorRef={bodyEditorRef}
        descriptionEditorRef={descriptionEditorRef}
        maxLengthTitle={config[SettingSystemKeys.LimitedTitleCharacter]}
        maxLengthTitleType={config[SettingSystemKeys.LimitedInputType(SettingSystemKeys.LimitedTitleCharacter)]}
        maxLengthDescription={config[SettingSystemKeys.LimitedDescCharacter]}
        maxLengthDescriptionType={config[SettingSystemKeys.LimitedInputType(SettingSystemKeys.LimitedDescCharacter)]}
        maxLengthSEOTitle={config[SettingSystemKeys.LimitedSEOTitleCharacter]}
        maxLengthSEODescription={config[SettingSystemKeys.LimitedSEODescCharacter]}
        descriptionPrefix={config[SettingSystemKeys.DescriptionPrefix]}
      />
    </Container>
  );
}

export default Form.create()(ContentComposeView);
