import { Input, InputNumber } from 'antd';
import makeStyles from 'core/common/makeStyles';
import { formatNumber } from 'core/common/string';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex !important',
  },
  input: {
    flex: 1,
  },
  addon: {
    backgroundColor: '#fafafa',
    border: '1px solid #d9d9d9',
    borderRadius: 4,
    height: 32,
    lineHeight: '32px',
    textAlign: 'center',
    paddingLeft: 11,
    paddingRight: 11,
  },
}));

export default forwardRef(function MoneyInput({ value, onChange, showCurrency = true, addonBefore, ...props }, ref) {
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState();

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = useCallback(value => setInternalValue(parseInt(value, 10) || 0), []);
  const handleBlur = useCallback(() => onChange(internalValue), [internalValue, onChange]);

  return (
    <Input.Group compact className={classes.container}>
      {addonBefore ? <span className={classes.addon}>{addonBefore}</span> : null}
      <InputNumber
        min={0}
        className={classes.input}
        ref={ref}
        value={internalValue}
        onChange={handleChange}
        onBlur={handleBlur}
        formatter={formatNumber}
        parser={value => value.replace(/,/g, '')}
        {...props}
      />
      {showCurrency && <span className={classes.addon}>VNĐ</span>}
    </Input.Group>
  );
});
