import React, { forwardRef, useContext } from 'react';
import { CustomComponentContext } from '../layout';
import { EditorButtonPreset } from './constants';
import Editor from './Editor';

export default forwardRef(function SimpleEditor({
  value,
  placeholder,
  className,
  height,
  minHeight,
  buttons = EditorButtonPreset.SIMPLE,
  ...props
}, ref) {
  const customComponentContext = useContext(CustomComponentContext);

  if (customComponentContext && customComponentContext.ExtSimpleEditor) {
    return (
      <customComponentContext.ExtSimpleEditor
        value={value}
        placeholder={placeholder}
        className={className}
        height={height}
        minHeight={minHeight}
        buttons={buttons}
        {...props}
      />
    );
  }

  const options = {
    zIndex: 0,
    readonly: false,
    placeholder: placeholder,
    editorCssClass: className,
    width: 'auto',
    height: height,
    minHeight: minHeight,
    disablePlugins: [],
    toolbarButtonSize: 'small',
    buttons: buttons,
    buttonsMD: buttons,
    buttonsXS: buttons,
    buttonsSM: buttons,
    toolbarSticky: false,
    pluginsOptions: {
      CustomPastePlugin: { trimHtml: 'all' },
    },
  };

  return (
    <Editor
      value={value}
      options={options}
      ref={ref}
      {...props}
    />
  );
});
