import { InputNumber } from 'antd';
import { asyncAction } from 'core/common/async';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import lodash from 'core/common/lodash';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import AsyncButton from 'core/components/common/AsyncButton';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateSelector from 'core/components/common/RangeDateSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import useContentColumns from 'core/hooks/useContentColumns';
import BaseTable from 'core/components/table/BaseTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import contentApi from 'core/engine/content/api';
import { ContentListType, ContentSearchOptions } from 'core/engine/content/constants';
import topicContentApi from 'core/engine/topic-content/api';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import useContentFilterHandlers from 'core/modules/contents/hooks/useContentFilterHandlers';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import TopicAddContentButton from '../TopicAddContentButton';
import GoBackButton from 'core/components/common/GoBackButton';
import { TOPIC_NORMAL_DEFAULT_ORDER } from 'core/config';
import makeStyles from 'core/common/makeStyles';

const useStyles = makeStyles(() => ({
  topicName: {
    textAlign: 'center',
    margin: '10px auto',
    '& > span': {
      fontWeight: 'bold',
    },
  },
}));

function listFn({ limit, offset, sorting, filters }) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;

  if (rangeDate[0] && rangeDate[1]) {
    listFilters.DistributionDate = dateRangeToFilter(rangeDate);
  }

  delete listFilters.rangeDate;

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return contentApi.getList({
    limit,
    offset,
    fields: ['ViewCount', 'Order'],
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

const defaultRangeDate = [null, null];

export default function TopicNormalContentListDisplay({ topic }) {
  const classes = useStyles();

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultPageSize: 20,
    defaultSorting: {
      Order: 'ASC',
      DistributionDate: 'DESC',
    },
    defaultFilters: {
      TopicID: topic.TopicID,
      search: { type: ContentSearchOptions[0].value },
      rangeDate: defaultRangeDate,
      listType: ContentListType.TOPIC_CONTENTS,
    },
  });
  const ContentColumns = useContentColumns();

  const [orderMap, setOrderMap] = useState({});

  const changedItemCount = useMemo(() => {
    return Object.values(orderMap).reduce((previous, current) => {
      return previous + (current.changed ? 1 : 0);
    }, 0);
  }, [orderMap]);

  useEffect(() => {
    setOrderMap(listProps.items.reduce((previous, current) => {
      previous[current.ContentID] = lodash.pick(current, 'ContentID', 'Order');
      return previous;
    }, {}));
  }, [listProps.items]);

  const filterHandlers = useContentFilterHandlers(listProps);

  const columns = [
    ContentColumns.Avatar,
    ContentColumns.Title,
    {
      ...ContentColumns.DistributionDate,
      accessor: 'DistributionDate',
      sortable: true,
    },
    ContentColumns.ViewCount,
    {
      Header: 'Thứ tự',
      accessor: 'Order',
      sortable: true,
      Cell: ({ original }) => (
        <InputNumber
          min={0}
          max={100000}
          value={lodash.get(orderMap[original.ContentID], 'Order')}
          onChange={value => handleOrderChange(original, value)}
        />
      ),
      width: 100,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <AsyncButton icon="delete" title="Xóa tin khỏi chủ đề" onClick={() => handleUnselect(original)} />
        </ActionsCellWrapper>
      ),
      width: 100,
    },
  ];

  return (
    <Fragment>
      <TableToolbarWrapper>
        <GoBackButton />
        <TopicAddContentButton
          list={listProps.items}
          onInsert={handleSelect}
          onDelete={handleUnselect}
          onDone={handleDone}
        />
        <AsyncButton
          type="primary"
          disabled={changedItemCount === 0}
          onClick={handleUpdateOrder}
        >Lưu thứ tự</AsyncButton>
        <ZoneSelector
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
          allowClear
        />
        <RangeDateSelector
          value={listProps.filters.rangeDate}
          onChange={filterHandlers.onRangeDateFilter}
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={ContentSearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
      </TableToolbarWrapper>
      <div className={classes.topicName}>Chủ đề: <span>{topic.Name}</span></div>
      <BaseTable
        {...useListProps(listProps)}
        columns={columns}
        heightAdjust={78}
      />
    </Fragment>
  );

  function handleSelect(item) {
    return asyncAction('Thêm tin vào chủ đề', () => {
      return topicContentApi.create({
        TopicID: topic.TopicID,
        ContentID: item.ContentID,
      });
    }).then(() => listProps.addItem(item));
  }

  function handleUnselect(item) {
    return asyncAction('Xóa tin khỏi chủ đề', () => {
      return topicContentApi.delete(item.ContentID, topic.TopicID)
        .then(() => listProps.removeItem(item, 'ContentID'));
    });
  }

  function handleDone() {
    // the list might be changed so refresh it
    if (listProps.items.length !== listProps.pageSize) {
      listProps.refresh();
    }
  }

  function handleOrderChange(item, newOrder) {
    setOrderMap(old => ({
      ...old,
      [item.ContentID]: { ContentID: item.ContentID, Order: newOrder || TOPIC_NORMAL_DEFAULT_ORDER, changed: true },
    }));
  }

  function handleUpdateOrder() {
    const changedTopicContents = Object.values(orderMap)
      .filter(item => item.changed)
      .map(item => lodash.pick(item, 'ContentID', 'Order'));

    return asyncAction('Cập nhật thứ tự', () => {
      return topicContentApi.updateOrder(topic.TopicID, changedTopicContents)
        .then(listProps.refresh);
    });
  }
}
