import { Button, Input, Modal } from 'antd';
import React, { useCallback, Fragment } from 'react';
import makeStyles from 'core/common/makeStyles';
import { ContentAction } from 'core/engine/content/constants';
import useDialog from 'core/hooks/useDialog';
import useForm from 'core/hooks/useForm';
import { red } from '@ant-design/colors';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 700,
    marginBottom: 8,
    display: 'block',
  },
  info: {
    marginTop: 10,
    color: red.primary,
  },
}));

export default function ContentFormToolbarDeleteButton({
  getFormData,
  action,
  onAction,
  className,
  loading,
}) {
  const classes = useStyles();

  const { open, handleClose, handleOpen } = useDialog();
  const { formData, setFieldsData, handleChange } = useForm({});

  const handleClick = useCallback(() => {
    getFormData(false).then((data) => {
      if (data.contentNote) {
        setFieldsData({ note: data.contentNote });
      }

      handleOpen();
    });
  }, [getFormData, handleOpen, setFieldsData]);

  const handleOk = useCallback(() => {
    handleClose();
    onAction(ContentAction.DELETE, formData);
  }, [formData, handleClose, onAction]);

  return (
    <Fragment>
      <Button
        name={action.name}
        title={action.label}
        className={className}
        onClick={handleClick}
        icon={action.icon}
        loading={loading}
      >
        {action.label}
      </Button>
      <Modal
        visible={open}
        title="Xóa tin này?"
        onCancel={handleClose}
        onOk={handleOk}
        okText={action.label}
        okButtonProps={{ disabled: !formData.note }}
        okType="danger"
        destroyOnClose
        keyboard={false}
        maskClosable={false}
      >
        <span className={classes.label}>Ghi chú:</span>
        <Input.TextArea
          placeholder="Nhập lý do xóa tin"
          name="note"
          value={formData.note}
          onChange={handleChange}
          autoFocus
        />
        <p className={classes.info}>Tin đã xóa sẽ không thể lấy lại được.</p>
      </Modal>
    </Fragment>
  );
}
