import { httpGet } from 'core/common/http-client';

const seoEntryApi = {
  get(type, id) {
    const qs = { Type: type, EntryID: id };
    return httpGet('/seo-entries/get/item', { qs });
  },
};

export default seoEntryApi;
