export const ContentAction = {
  CHECKOUT: 'checkout',
  CHECKIN: 'checkin',
  SAVE: 'save',
  SUBMIT: 'submit',
  SUBMIT_RETURN: 'submit_return',
  APPROVE: 'approve',
  APPROVE_RETURN: 'approve_return',
  PUBLISH: 'publish',
  UNPUBLISH: 'unpublish',
  DELETE: 'delete',
  FOWARD: 'forward',
  PREVIEW: 'preview',
  RESET: 'reset',
  MOVE: 'move',
};

export const ContentAttribute = {
  UPDATING: 1,
  HAS_PHOTO: 2,
  HAS_VIDEO: 4,
  LIVE: 8,
  ONLY_ZONE: 16,
  INTERVIEW: 32,
  HAS_AUDIO: 64,
  NO_COMMENT: 128,
  NO_ADS: 256,
  NO_AVATAR: 512,
  NO_ROYALTY: 1024,
  NO_TITLE: 2048,
  IS_HIGHLIGHT: 4096,
  IS_EXCLUSIVE: 8192,
  IS_ANALYSIS: 16384,
  NO_DESCRIPTION: 32768,
  NO_AUDIO: 65536,
};

export const ContentAttributeOptions = [
  // CMS will add its options
];

export const ContentStatus = {
  DRAFT: 0, // Dang bien tap
  SUBMITTED: 1, // Da gui bai
  SUBMITTED_ITEM_RETURNED: 2, // Yeu cau bien tap lai bai
  APPROVED: 3, // Da duyet bai, cho dang
  APPROVED_ITEM_RETURNED: 4, // Bi tra lai khi dang
  DEPLOYED: 5, // Da dang bai
  DISCONTINUED: 6, // Da go bai xuong
  DELETED: 7, // Da xoa bai
};

export const ContentStatusLabel = {
  [ContentStatus.DRAFT]: 'Đang biên tập',
  [ContentStatus.SUBMITTED]: 'Đã gửi',
  [ContentStatus.SUBMITTED_ITEM_RETURNED]: 'Yêu cầu biên tập lại',
  [ContentStatus.APPROVED]: 'Đã duyệt',
  [ContentStatus.APPROVED_ITEM_RETURNED]: 'Yêu cầu duyệt lại',
  [ContentStatus.DEPLOYED]: 'Đã đăng',
  [ContentStatus.DISCONTINUED]: 'Đã gỡ xuống',
  [ContentStatus.DELETED]: 'Đã xóa',
};

export const ContentDisplayType = {
  NORMAL: 0,
  PICTURE: 1,
  VIDEO: 2,
  GALLERY: 3,
  INFOGRAPHIC: 4,
  INTERACTIVE: 5,
  LIVE: 6,
  INTERVIEW: 7,
  LONGFORM: 8,
  FULL_LAYOUT: 9,
  PREVIEW: 10,
  PICTURE_SMALL: 11,
};

export const ContentDisplayTypeLabel = {
  [ContentDisplayType.NORMAL]: 'Bình thường',
  [ContentDisplayType.PICTURE]: 'Tin ảnh',
  [ContentDisplayType.VIDEO]: 'Tin video',
  [ContentDisplayType.GALLERY]: 'Tin Gallery',
  [ContentDisplayType.INFOGRAPHIC]: 'Tin Infographic',
  [ContentDisplayType.INTERACTIVE]: 'Tin Interactive',
  [ContentDisplayType.LIVE]: 'Tin tường thuật',
  [ContentDisplayType.INTERVIEW]: 'Tin giao lưu trực tuyến',
  [ContentDisplayType.LONGFORM]: 'Tin LongForm',
  [ContentDisplayType.FULL_LAYOUT]: 'Tin tràn trang',
  [ContentDisplayType.PREVIEW]: 'Tin Preview',
  [ContentDisplayType.PICTURE_SMALL]: 'Tin ảnh nhỏ',
};

export const ContentDisplayTypeOptions = []; // CMS will add its options

export const ContentType = {
  OTHERS: 0,
  SAN_XUAT: 1,
  TIN_DICH: 2,
  TIN_TONG_HOP: 3,
  BAO_IN: 4,
  QUANG_CAO: 5,
  DANG_LAI: 6,
  TIN_QUYEN_LOI: 7,
  TIN_HO_TRO: 8,
  TEXT: 9,
  VIDEO: 10,
  CHUM_ANH: 11,
  CHUYEN_SAU: 12,
  INFOGRAPHIC: 13,
  LONGFORM: 14,
};

export const ContentTypeLabel = {
  [ContentType.OTHERS]: 'Chưa phân loại',
  [ContentType.SAN_XUAT]: 'Tin sản xuất',
  [ContentType.TIN_DICH]: 'Tin dịch',
  [ContentType.TIN_TONG_HOP]: 'Tin tổng hợp',
  [ContentType.BAO_IN]: 'Tin báo giấy',
  [ContentType.QUANG_CAO]: 'Tin quảng cáo',
  [ContentType.DANG_LAI]: 'Tin đăng lại',
  [ContentType.TIN_QUYEN_LOI]: 'Tin quyền lợi',
  [ContentType.TIN_HO_TRO]: 'Tin hỗ trợ',
  [ContentType.TEXT]: 'Tin text',
  [ContentType.VIDEO]: 'Tin video',
  [ContentType.CHUM_ANH]: 'Chùm ảnh',
  [ContentType.CHUYEN_SAU]: 'Tin chuyên sâu',
  [ContentType.INFOGRAPHIC]: 'Tin Infographic',
  [ContentType.LONGFORM]: 'Tin LongForm',
};

export const ContentTypeOptions = []; // CMS will add its options

export const ContentSearchOptions = [
  { label: 'Tiêu đề', value: 'Title' },
  { label: 'Tác giả', value: 'Author' },
  { label: 'ID bài viết', value: 'ContentID' },
  { label: 'Tất cả', value: 'ftSearch' },
];

export const TopicSearchOptions = [
  { label: 'Tên', value: 'Name' },
  { label: 'URL', value: 'Url' },
];

export const ContentListType = {
  MY_LIST: 0,
  MY_TRANSFERED_LIST: 1,
  APPROVAL_WAITING: 2,
  DEPLOY_WAITING: 3,
  DEPLOYED: 4,
  APPROVED_RETURN: 5,
  SUBMITTED_RETURN: 6,
  DISCONTINUED: 7,
  DEPLOYED_LIST: 8,
  TOPIC_CONTENTS: 10,
  DELETED: 12,
  CONTENT_AUTHORS: 13,
  APPROVED: 14,
  ALL: 15,
};

export const ContentRateType = {
  VOTE: 1,
  LIKE: 2,
  DISLIKE: 3,
};
