import React, { forwardRef, useEffect, useState } from 'react';
import CommandPermissionTable from 'core/components/command/CommandPermissionTable';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import accountApi from 'core/engine/account/api';

export default forwardRef(function AccountCommandTable({ accountId }, ref) {
  const [isLoading, setIsLoading] = useState(true);
  const [accountCommands, setAccountCommands] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    accountApi.getCommandRoles(accountId)
      .then(resp => resp.data)
      .then((accountCommands) => {
        setAccountCommands(accountCommands);
        setIsLoading(false);
      });
  }, [accountId]);

  return (
    <LoadingWrapper center loading={isLoading}>
      <CommandPermissionTable permissions={accountCommands} ref={ref} />
    </LoadingWrapper>
  );
});
