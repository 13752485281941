import { Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import { ContentLink } from 'core/components/content/ContentLink';
import React, { useMemo } from 'react';
import lodash from 'core/common/lodash';

export default function CommentForm({ form, comment }) {
  const { getFieldDecorator } = form;

  const { contentId, contentTitle } = useMemo(() => {
    return {
      contentId: lodash.get(comment, 'Object.ContentID'),
      contentTitle: lodash.get(comment, 'Object.Title'),
    };
  }, [comment]);

  getFieldDecorator('CommentID', { initialValue: comment.CommentID });
  getFieldDecorator('StartDate', { initialValue: comment.StartDate });

  return (
    <Form {...commonStyles.formItemLayout}>
      {contentId && (
        <Form.Item label="Bài viết">
          <ContentLink id={contentId}>
            {contentTitle}
          </ContentLink>
        </Form.Item>
      )}
      <Form.Item label="Ngày tạo">
        <DateTimeDisplay timestamp={comment.CreatedDate} />
      </Form.Item>
      <Form.Item label="Người gửi">
        {getFieldDecorator('UserDisplayName', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: comment.UserDisplayName,
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator('UserEmail', {
          rules: [
            { required: true, message: 'Bắt buộc!' },
            { type: 'email', message: 'Email không hợp lệ!' },
            { max: 250, message: 'Tối đa 250 kí tự!' },
          ],
          initialValue: comment.UserEmail,
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Nội dung">
        {getFieldDecorator('Comment', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: comment.Comment,
        })(
          <Input.TextArea autoSize={{ minRows: 2 }} autoFocus />
        )}
      </Form.Item>
    </Form>
  );
}
