import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

export default function InterviewQuestionButtonForList({ interviewId, guestId, disabled }) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push({
      pathname: '/operations/interviews/questions',
      search: `?interviewId=${interviewId}` + (guestId ? `&guestId=${guestId}` : ''),
    });
  }, [interviewId, guestId]);

  return (
    <Button title="Danh sách câu hỏi" icon="question" onClick={handleClick} disabled={disabled} />
  );
}
