import { Button, Checkbox } from 'antd';
import { commonColumns } from 'core/common/listUtils';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import BaseTable from 'core/components/table/BaseTable';
import React from 'react';
import AccountFormDialogButton from './AccountFormDialogButton';
import AccountRightsDialogButton from './rights/AccountRightsDialogButton';

export default function AccountList({ onUpdate, onSendMail, onViewQRCode, ...props }) {
  const columns = [
    commonColumns.order,
    {
      Header: 'ID',
      accessor: 'AccountID',
      width: 50,
    },
    {
      Header: 'Tài khoản',
      accessor: 'Username',
      sortable: true,
    },
    {
      Header: 'Email',
      accessor: 'Email',
      minWidth: 200,
    },
    {
      Header: 'Duyệt',
      accessor: 'IsApproved',
      sortable: true,
      Cell: ({ original }) => {
        return (<Checkbox checked={original.IsApproved} />);
      },
      width: 60,
      ...commonColumns.allCentered,
    },
    {
      Header: 'Admin',
      accessor: 'IsAdministrator',
      sortable: true,
      Cell: ({ original }) => {
        return (<Checkbox checked={original.IsAdministrator} />);
      },
      width: 60,
      ...commonColumns.allCentered,
    },
    {
      Header: 'Khóa',
      accessor: 'IsLockedOut',
      sortable: true,
      Cell: ({ original }) => {
        return (<Checkbox checked={original.IsLockedOut} />);
      },
      width: 60,
      ...commonColumns.allCentered,
    },
    {
      Header: 'Đăng nhập cuối',
      accessor: 'LastLoginDate',
      sortable: true,
      Cell: ({ original }) => original.LastLoginDate ?
        (<DateTimeDisplay timestamp={original.LastLoginDate} />) : 'Chưa đăng nhập',
      width: 120,
    },
    {
      Header: 'Hoạt động cuối',
      accessor: 'LastActivityDate',
      sortable: true,
      Cell: ({ original }) => original.LastActivityDate ? (
        <DateTimeDisplay timestamp={original.LastActivityDate} />) : 'Chưa đăng nhập',
      width: 120,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <AccountRightsDialogButton account={original} />
          <AccountFormDialogButton account={original} onDone={onUpdate} />
          <Button title="Gửi QR code" icon="qrcode" onClick={() => onSendMail(original)} />
          <Button title="Xem QR Code" icon="eye" onClick={() => onViewQRCode(original)} />
        </ActionsCellWrapper>
      ),
      width: 175,
    }];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
