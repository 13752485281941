import domUtils from 'core/common/domUtils';
import { addBit, hasBit, removeBit } from '../common/utils';
import { ContentAttribute } from './constants';

const MINIMUM_IMAGE_TO_HAS_IMG_ATTRIBUTE = 3;

const PHOTO_SELECTORS = ['img:not(related-content img)'];
const VIDEO_SELECTORS = ['video source', 'iframe[src*="youtube.com"]', 'iframe[src*="youtu.be"]', 'iframe[src*="/watch/"]', 'iframe[src*="facebook.com/plugins/video"]'];
const AUDIO_SELECTOSS = ['audio source'];

const contentUtils = {
  hasAttribute(attributes, value) {
    return hasBit(attributes || 0, value);
  },

  fillAttribute(content, formData) {
    let attributes = formData.Attributes;

    if (this.countPhoto(formData.HtmlBody) >= MINIMUM_IMAGE_TO_HAS_IMG_ATTRIBUTE) {
      attributes = addBit(attributes, ContentAttribute.HAS_PHOTO);
    } else {
      attributes = removeBit(attributes, ContentAttribute.HAS_PHOTO);
    }

    if (this.getFirstVideoUrl(formData.HtmlBody)) {
      attributes = addBit(attributes, ContentAttribute.HAS_VIDEO);
    } else {
      attributes = removeBit(attributes, ContentAttribute.HAS_VIDEO);
    }

    if (this.getFirstAudioUrl(formData.HtmlBody)) {
      attributes = addBit(attributes, ContentAttribute.HAS_AUDIO);
    } else {
      attributes = removeBit(attributes, ContentAttribute.HAS_AUDIO);
    }

    content.Attributes = attributes;
  },

  countPhoto(htmlBody) {
    return this.getBodyElement(htmlBody).querySelectorAll(PHOTO_SELECTORS.join()).length;
  },

  countVideo(htmlBody) {
    return this.getBodyElement(htmlBody).querySelectorAll(VIDEO_SELECTORS.join()).length;
  },

  countAudio(htmlBody) {
    return this.getBodyElement(htmlBody).querySelectorAll(AUDIO_SELECTOSS.join()).length;
  },

  getFirstPhotoUrl(htmlBody) {
    const image = this.getBodyElement(htmlBody).querySelector(PHOTO_SELECTORS.join());
    return image && image.getAttribute('src');
  },

  getFirstVideoUrl(htmlBody) {
    const video = this.getBodyElement(htmlBody).querySelector(VIDEO_SELECTORS.join());
    return video && video.getAttribute('src');
  },

  getFirstAudioUrl(htmlBody) {
    const audio = this.getBodyElement(htmlBody).querySelector(AUDIO_SELECTOSS.join());
    return audio && audio.getAttribute('src');
  },

  getFirstVideoAudioUrl(htmlBody) {
    const videoAudio = this.getBodyElement(htmlBody).querySelector(VIDEO_SELECTORS.concat(AUDIO_SELECTOSS).join());
    return videoAudio && videoAudio.getAttribute('src');
  },

  getBodyElement(htmlBody) {
    return domUtils.createElementFromHtml(htmlBody || '<div></div>').parentElement;
  },
};

export default contentUtils;
