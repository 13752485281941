import { Checkbox, Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import ImagePickerInput from 'core/components/common/ImagePickerInput';
import SimpleEditor from 'core/components/editor/SimpleEditor';
import InterviewEditorSelector from 'core/components/interview/InterviewEditorSelector';
import React, { useEffect } from 'react';

export default function InterviewGuestForm({
  form,
  onSubmit,
  guest,
  editorRef,
}) {
  const { getFieldDecorator, resetFields } = form;

  useEffect(() => resetFields(), [guest]);

  getFieldDecorator('InterviewID', { initialValue: guest.InterviewID });
  getFieldDecorator('GuestID', { initialValue: guest.GuestID });
  getFieldDecorator('Order', { initialValue: guest.Order });

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Tên khách mời">
        {getFieldDecorator('Name', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
          initialValue: guest.Name,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Ảnh đại diện">
        {getFieldDecorator('AvatarUrl', {
          initialValue: guest.AvatarUrl,
          rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <ImagePickerInput />
        )}
      </Form.Item>
      <Form.Item label="Phụ trách">
        {getFieldDecorator('Interviewers', {
          initialValue: guest.Interviewers,
        })(
          <InterviewEditorSelector
            placeholder="Chọn người phụ trách"
            valueField="AccountID"
            labelField="Username"
            valueIsNumber
            interviewId={guest.InterviewID}
            mode="multiple"
          />
        )}
      </Form.Item>
      <Form.Item label="Thông tin khách mời">
        {getFieldDecorator('Description', {
          rules: [{
            max: 2000,
            message: 'Thông tin khách mời không quá 2000 ký tự',
            transform: () => editorRef.current.getValue(),
          }],
          initialValue: guest.Description,
        })(
          <SimpleEditor placeholder="Nhập thông tin khách mời" editorRef={editorRef} />
        )}
      </Form.Item>
      <Form.Item label="Hiển thị">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: isNullOrUndefined(guest.Enabled) ? true : guest.Enabled,
        })(
          <Checkbox />
        )}
      </Form.Item>
    </Form>
  );
}
