import { Checkbox } from 'antd';
import { connectSelectorFilterForList } from 'core/common/hocs';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import CheckboxFilterForList from 'core/components/common/CheckboxFilterForList';
import DeleteButton from 'core/components/common/DeleteButton';
import { OnEnterFilterForList } from 'core/components/common/OnEnterFilter';
import BaseTable from 'core/components/table/BaseTable';
import { VoteAttributesLabel } from 'core/engine/vote/constants';
import useAuth from 'core/hooks/useAuth';
import React, { useMemo } from 'react';
import VoteLogDialogButton from './log/VoteLogDialogButton';
import VoteOptionListDialogButton from './vote-option/VoteOptionListDialogButton';
import VoteFormDialogButton from './VoteFormDialogButton';

export default function VoteList({ onUpdate, onDelete, ...props }) {
  const { loggedInUser, hasExtendedRight } = useAuth();

  const canViewLog = useMemo(() =>
    loggedInUser.isAdmin || hasExtendedRight('vote.view-log')
  , [loggedInUser, hasExtendedRight]);

  const columns = [
    {
      Header: 'ID',
      accessor: 'VoteID',
      maxWidth: 100,
      minWidth: 50,
      filterable: true,
      Filter: OnEnterFilterForList,
      headerStyle: { justifyContent: 'center' },
      style: { justifyContent: 'center' },
    },
    {
      Header: 'Tên bình chọn',
      accessor: 'Title',
      filterable: true,
      Filter: OnEnterFilterForList,
      headerStyle: { justifyContent: 'center' },
      minWidth: 200,
    },
    {
      Header: 'Câu hỏi',
      accessor: 'Description',
      filterable: false,
      minWidth: 300,
      headerStyle: { justifyContent: 'center' },
    },
    {
      Header: 'Loại',
      accessor: 'Attributes',
      Cell: (row) => (VoteAttributesLabel[row.original.Attributes]),
      filterable: true,
      Filter: connectSelectorFilterForList({ options: VoteAttributesLabel, valueIsNumber: true }),
      width: 120,
      headerStyle: { justifyContent: 'center' },
    },
    {
      Header: 'Sử dụng',
      accessor: 'Enabled',
      Cell: ({ original }) => <Checkbox checked={original.Enabled} />,
      filterable: true,
      Filter: CheckboxFilterForList,
      maxWidth: 120,
      minWidth: 80,
      headerStyle: { justifyContent: 'center' },
      style: { justifyContent: 'center' },
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <VoteOptionListDialogButton vote={original} />
          <VoteFormDialogButton vote={original} onDone={onUpdate} />
          {canViewLog && (
            <VoteLogDialogButton voteId={original.VoteID} />
          )}
          <DeleteButton title={`Xóa bình chọn: ${original.Name}?`} onDelete={() => onDelete(original)} />
        </ActionsCellWrapper>
      ),
      width: 150,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
