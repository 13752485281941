import { IS_MOBILE } from '@bm/common';
import React, { useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import classnames from 'core/common/classnames';
import TablePagination from './TablePagination';

export default function BaseTable({
  filtered,
  sorted,
  page,
  pageSize,
  columns,
  data,
  pages,
  total,
  loading,
  onPageChange,
  onPageSizeChange,
  onFilteredChange,
  onSortedChange,
  onRefresh,
  showRefreshButton = true,
  showPageSizeOptions = !IS_MOBILE,
  showTotal = !IS_MOBILE,
  showPageJump = !IS_MOBILE,
  manual = true,
  striped = true,
  highlight = true,
  fullHeight = true,
  heightAdjust = 0,
  maxHeightAdjust = 0,
  className,
  pageText = 'Trang',
  ofText = '/',
  rowsText = 'dòng',
  previousText = 'Trước',
  nextText = 'Sau',
  totalText = 'Tổng số',
  refreshText = 'Làm mới',
  ...props
}) {
  const style = useMemo(() => {
    const style = {};
    style.height = fullHeight ? heightAdjust ? `calc(100% - ${heightAdjust}px)` : '100%' : {};
    style.maxHeight = maxHeightAdjust ? `calc(100% - ${maxHeightAdjust}px)` : {};
    return style;
  }, [fullHeight, heightAdjust, maxHeightAdjust]);

  const tableRef = useRef();
  const scrollTopOnLoadedRef = useRef(false);

  useEffect(() => {
    scrollTopOnLoadedRef.current = true;
  }, [page, pages, filtered, sorted]);

  useEffect(() => {
    if (scrollTopOnLoadedRef.current && !loading) {
      scrollTopOnLoadedRef.current = false;
      ReactDOM.findDOMNode(tableRef.current).getElementsByClassName('rt-tbody')[0].scrollTop = 0;
    }
  }, [loading]);

  return (
    <ReactTable
      ref={tableRef}
      resizable={false}
      sortable={false}
      filtered={filtered}
      sorted={sorted}
      page={page}
      pageSize={pageSize}
      columns={columns}
      data={data}
      pages={pages}
      loading={loading}
      manual={manual}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onFilteredChange={onFilteredChange}
      onSortedChange={onSortedChange}
      style={style}
      className={classnames(className, striped && '-striped', highlight && '-highlight')}
      PaginationComponent={TablePagination}
      pageText={pageText}
      ofText={ofText}
      rowsText={rowsText}
      previousText={previousText}
      nextText={nextText}
      getPaginationProps={getPaginationProps}
      showPageSizeOptions={showPageSizeOptions}
      showPageJump={showPageJump}
      {...props}
    />
  );

  function getPaginationProps() {
    return {
      showTotal,
      totalText,
      total,
      refreshText,
      showRefreshButton,
      onRefresh,
    };
  }
}
