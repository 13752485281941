import React, { useEffect, useMemo } from 'react';
import { asyncAction } from 'core/common/async';
import Container from 'core/components/common/Container';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import interviewQuestionApi from 'core/engine/interview-question/api';
import GoBackButton from 'core/components/common/GoBackButton';
import { useLocation } from 'react-router-dom';
import { InterviewQuestionStatus, InterviewQuestionStatusLabel } from 'core/engine/interview-question/constants';
import InterviewQuestionList from './InterviewQuestionList';
import InterviewQuestionFormDialogButton from './InterviewQuestionFormDialogButton';
import { sortingToSortBy } from 'core/common/listUtils';
import InterviewAuthz from '../InterviewAuthz';
import lodash from 'lodash';
import { parseQuery } from 'core/common/urlHelper';
import useInterviewLoader from '../../hooks/useInterviewLoader';
import useInterviewGuestLoader from '../../hooks/useInterviewGuestLoader';
import FlexPushRight from 'core/components/common/FlexPushRight';
import Selector from 'core/components/common/Selector';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return interviewQuestionApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

const InterviewQuestionSearchOptions = [
  { value: 'Question', label: 'Câu hỏi' },
  { value: 'UserDisplayName', label: 'Người gửi' },
  { value: 'QuestionID', label: 'ID' },
];

export default function InterviewQuestionListView() {
  const location = useLocation();
  const guestId = useMemo(() => parseInt(parseQuery(location.search).guestId), []);
  const interviewId = useMemo(() => parseQuery(location.search).interviewId, []);

  const { interview, isLoading } = useInterviewLoader(interviewId);
  const { guests, allGuests, isLoading: isGuestLoading } = useInterviewGuestLoader(interview);

  useEffect(() => {
    if (!isLoading && !isGuestLoading) {
      let filterByGuestId;

      if (interview.isAdmin) {
        filterByGuestId = guestId;
      } else if (interview.isEditor) {
        filterByGuestId = lodash.find(guests, { GuestID: guestId }) ? guestId : lodash.get(guests, '[0].GuestID');
      }

      filterByGuestId ? listProps.addFilter('GuestID', filterByGuestId) : listProps.refresh();
    }
  }, [isLoading, isGuestLoading, guestId, guests]);

  const listProps = useList({
    listFn,
    defaultSorting: {
      QuestionID: 'DESC',
    },
    defaultFilters: {
      InterviewID: interviewId,
      search: { type: InterviewQuestionSearchOptions[0].value },
    },
  });

  const handleSearchFilter = useCreateOnFilterChange(listProps, 'search');
  const handleStatusFilter = useCreateOnFilterChange(listProps, 'Status');
  const handleGuestFilter = useCreateOnFilterChange(listProps, 'GuestID');

  return (
    <Container pageTitle="Danh sách câu hỏi" loading={isLoading}>
      <InterviewAuthz interview={interview}>
        <div style={{ marginBottom: 10 }}>Giao lưu trực tuyến: {interview.Title}</div>
        <TableToolbarWrapper>
          <GoBackButton />
          <InterviewQuestionFormDialogButton
            interview={interview}
            question={{
              InterviewID: interviewId,
              Status: InterviewQuestionStatus.NEW,
              Guests: listProps.filters.GuestID ? [{ GuestID: listProps.filters.GuestID }] : [],
            }}
            isEditMode={false}
            onDone={listProps.refresh}
          />
          <Selector
            width={150}
            emptyLabel="-- Trạng thái --"
            value={listProps.filters.Enabled}
            options={InterviewQuestionStatusLabel}
            onChange={handleStatusFilter}
            showDisabledOptions
            allowClear
            showSearch={false}
            valueIsNumber
          />
          <Selector
            emptyLabel="-- Khách mời --"
            value={listProps.filters.GuestID}
            options={guests}
            valueField="GuestID"
            labelField="Name"
            onChange={handleGuestFilter}
            showDisabledOptions
            allowClear={interview.isAdmin}
            valueIsNumber
          />
          <FlexPushRight />
          <SearchFieldWithType
            typeOptions={InterviewQuestionSearchOptions}
            search={listProps.filters.search}
            onSearch={handleSearchFilter}
            autoFocus
          />
        </TableToolbarWrapper>
        <InterviewQuestionList
          {...useListProps(listProps)}
          heightAdjust={73}
          onUpdate={listProps.refresh}
          onDelete={handleDelete}
          interview={interview}
          guests={guests}
          allGuests={allGuests}
        />
      </InterviewAuthz>
    </Container>
  );

  function handleDelete(question) {
    return asyncAction('Xóa câu hỏi khỏi giao lưu trực tuyến', () => interviewQuestionApi.delete(question).then(listProps.refresh));
  }
}
