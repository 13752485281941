import { useCallback, useEffect, useState } from 'react';
import { WEB_SOCKET_URL } from 'core/config';
import { emptyFunction } from 'core/common/empty';
import { parseJson } from 'core/common/jsonUtils';
import useAuth from 'core/hooks/useAuth';

export default function useWebSocket({
  topic = '',
  isPersonal = false,
  autoConnect = true,
  onOpen = emptyFunction,
  onMessage = emptyFunction,
  onClose = emptyFunction,
  onError = emptyFunction,
}) {
  const [ws, setWS] = useState(null);
  const { loggedInUser } = useAuth();

  const disconnect = useCallback(() => {
    ws && ws.close();
  }, []);

  const connect = useCallback(() => {
    setWS(new WebSocket(WEB_SOCKET_URL
      + (topic ? '_' + topic : '')
      + (isPersonal && loggedInUser && loggedInUser.id ? '_account_' + loggedInUser.id : '')));
  }, [topic, isPersonal, loggedInUser]);

  useEffect(() => {
    if (!ws && autoConnect && (!isPersonal || (loggedInUser && loggedInUser.id))) {
      connect();
    }

    return disconnect;
  }, [connect]);

  const handleMessage = useCallback((event) => {
    onMessage((event.data && parseJson(event.data, {})) || {});
  }, [onMessage]);

  useEffect(() => {
    if (ws) {
      ws.onopen = onOpen;
      ws.onclose = onClose;
      ws.onmessage = handleMessage;
      ws.onerror = onError;
    }
  }, [onOpen, onMessage, onClose, onError, ws]);

  return {
    ws,
    connect,
    disconnect,
  };
}
