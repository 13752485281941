import { Checkbox } from 'antd';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import { Sites, SubSiteZone } from 'core/engine/site/constants';
import siteUtils from 'core/engine/site/utils';
import { getMapZoneCache, getTreeListEnabledZoneCache } from 'core/engine/zone/caching';
import React, { forwardRef, useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  checkAllBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginBottom: 12,
    borderBottom: '1px solid #E9E9E9',
  },
  checkBox: {
    marginLeft: '0px !important',
  },
  containerItem: {
    width: '50%',
    float: 'left',
  },
}));

export default forwardRef(function ZoneGroupCheckbox({ value = [], onChange }, ref) {
  const classes = useStyles();

  const [checkedList, setCheckedList] = useState(value);
  const [firstZoneOptions, setFirstZoneOptions] = useState([]);
  const [secondZoneOptions, setSecondZoneOptions] = useState([]);
  const [zoneMap, setZoneMap] = useState({});
  const [siteZonesOption, setSiteZonesOption] = useState({});
  const [siteCheckAll, setSiteCheckAll] = useState({});

  const extendItems = [{ value: -1, label: 'Toàn trang' }, { value: 0, label: 'Trang chủ' }];

  useEffect(
    () => {
      getTreeListEnabledZoneCache().then((items) => {
        const zoneOptions = [
          ...extendItems,
          ...items.map((item) => ({ value: item.ZoneID, label: '----'.repeat(item.Level) + item.Name })),
        ];
        const size = zoneOptions.length / 2;

        setFirstZoneOptions(zoneOptions.slice(0, size));
        setSecondZoneOptions(zoneOptions.slice(size));

        const siteZonesMap = siteUtils.getSiteZonesMap(items, true);

        const siteZoneIdsMap = {};
        Object.keys(siteZonesMap).forEach(siteId => {
          siteZoneIdsMap[siteId] = siteZonesMap[siteId].map(zone => zone.ZoneID);
        });

        siteZoneIdsMap[Sites.ALL].push(-1, 0); // add 'Tất cả các mục', 'Trang chủ'

        if (siteZoneIdsMap[Sites.MAIN]) {
          siteZoneIdsMap[Sites.MAIN].push(0); // add 'Trang chủ'
        }

        setSiteZonesOption(siteZoneIdsMap);
        updateSiteCheckAllMap(value, siteZoneIdsMap);
      });

      getMapZoneCache().then((map) => setZoneMap(map));
    }, []);

  return (
    <div ref={ref}>
      <div className={classes.checkAllBox}>
        {Object.keys(siteZonesOption).sort().map(siteId =>
          <Checkbox
            key={siteId}
            className={classes.checkBox}
            checked={siteCheckAll[siteId]}
            onChange={(event) => handleCheckSiteChange(event, siteId)}>
            {getOptionTitle(siteId)}
          </Checkbox>
        )}
      </div>
      <Checkbox.Group value={checkedList} onChange={handleChange} style={{ width: '100%' }}>
        <div className={classes.containerItem}>
          {firstZoneOptions.map(option =>
            <div key={option.value}>
              <Checkbox value={option.value}>{option.label}</Checkbox>
            </div>
          )}
        </div>
        <div className={classes.containerItem}>
          {secondZoneOptions.map(option =>
            <div key={option.value}>
              <Checkbox value={option.value}>{option.label}</Checkbox>
            </div>
          )}
        </div>
      </Checkbox.Group>
    </div>
  );

  function handleChange(checkedList) {
    setCheckedList(checkedList);
    updateSiteCheckAllMap(checkedList);

    onChange(checkedList);
  }

  function handleCheckSiteChange(event, siteId) {
    const checkedList = event.target.checked ? siteZonesOption[siteId] : [];
    handleChange(checkedList);
  }

  function updateSiteCheckAllMap(checkedZones = checkedList, siteZones = siteZonesOption) {
    const siteCheckAll = {};

    Object.keys(siteZones).forEach(siteId => {
      siteCheckAll[siteId] = siteZones[siteId].every(zoneId => checkedZones.includes(zoneId));
    });

    setSiteCheckAll(siteCheckAll);
  }

  function getOptionTitle(option) {
    switch (parseInt(option)) {
      case Sites.ALL:
        return 'Chọn tất cả';
      case Sites.MAIN:
        return 'Trang chính';
      default:
        return lodash.get(zoneMap, `${SubSiteZone[option]}.Name`, '');
    }
  }
});
