import { Form } from 'antd';
import lodash from 'core/common/lodash';
import { EmbeddedStyleType } from 'core/engine/embedded-styles/constants';
import React from 'react';
import ContentFormEmbeddedStyleInput from './components/ContentFormEmbeddedStyleInput';

export default function ContentFormItemEmbeddedJS({ content, form }) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item>
      {getFieldDecorator('jsEmbededIDs', {
        initialValue: lodash.get(content.Meta, 'JSEmbededIDs'),
      })(
        <ContentFormEmbeddedStyleInput type={EmbeddedStyleType.JS} />
      )}
    </Form.Item>
  );
}
