import { httpGet, httpPost } from 'core/common/http-client';

const accountApi = {
  get(id) {
    return httpGet(`/accounts/get/item?AccountID=${id}`);
  },

  getList({ limit = 50, offset = 0, filters, sortBy }) {
    const qs = { limit, offset, sortBy, ...filters };
    return httpGet('/accounts/get/items-by-range', { qs });
  },

  getListAll() {
    return httpGet('/accounts/get/items');
  },

  create(data) {
    return httpPost('/accounts/post/insert', data);
  },

  update(data) {
    return httpPost('/accounts/post/update', data);
  },

  updateProfile(data) {
    return httpPost('/accounts/post/update-profile', data);
  },

  changePassword(data) {
    return httpPost('/accounts/post/change-password', data);
  },

  delete() {
    return Promise.reject(new Error('Not allowed'));
  },

  getGroups(id) {
    return httpGet(`/accounts/get/groups?AccountID=${id}`);
  },

  setGroups(id, groups) {
    return httpPost('/accounts/post/set-groups', {
      AccountID: id,
      groups,
    });
  },

  getCommandRoles(id) {
    return httpGet(`/accounts/get/command-roles?AccountID=${id}`);
  },

  setCommandRoles(id, roles) {
    return httpPost('/accounts/post/set-command-roles', {
      AccountID: id,
      roles,
    });
  },
};

export default accountApi;
