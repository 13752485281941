import { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import useDebounce from 'core/hooks/useDebounce';

export default function useElementRect(element) {
  const observerRef = useRef();
  const [contentRect, setContentRect] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (element) {
      if (!observerRef.current) {
        observerRef.current = new ResizeObserver(entries => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }

          const { width, height } = entries[0].contentRect;
          setContentRect({ width, height });
        });
      }

      observerRef.current.observe(element);
      return () => observerRef.current.unobserve(element);
    }
  }, [element]);

  return contentRect;
}

export function useElementRectWithDebounce(element, delay) {
  return useDebounce(useElementRect(element), delay);
}
