import commonStyles from 'core/common/commonStyles';
import { emptyObject } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import OnBlurTextArea from 'core/components/common/OnBlurTextArea';
import React, { forwardRef, useCallback } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
  },
  item: {
    marginTop: 10,
  },
}));

export default forwardRef(function ContentFormSeoEntryInput({
  value: seoEntry = emptyObject,
  onChange,
  maxLengthTitle,
  maxLengthDescription,
}, ref) {
  const classes = useStyles();

  const handleChange = useCallback((key, value) => {
    onChange({
      ...seoEntry,
      [key]: value,
    });
  }, [seoEntry, onChange]);

  return (
    <div ref={ref} className={classes.container}>
      <OnBlurTextArea
        value={seoEntry.Title}
        onChange={(e) => handleChange('Title', e)}
        placeholder="SEO Title"
        maxLength={maxLengthTitle}
      />
      <OnBlurTextArea
        className={classes.item}
        value={seoEntry.Description}
        onChange={(e) => handleChange('Description', e)}
        placeholder="SEO Description"
        maxLength={maxLengthDescription}
      />
      <OnBlurTextArea
        className={classes.item}
        value={seoEntry.Keywords}
        onChange={(e) => handleChange('Keywords', e)}
        placeholder="SEO Keywords"
      />
    </div>
  );
});
