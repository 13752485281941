import { Icon, Input } from 'antd';
import FileChooserButton from 'core/components/file-manager/FileChooserButton';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { FileType } from 'core/engine/file/constants';
import makeStyles from 'core/common/makeStyles';
import commonStyles from 'core/common/commonStyles';
import { buildCdnPhotoUrl } from 'core/common/cdn';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
  },
  link: {
    ...commonStyles.ellipsis,
    maxWidth: 'fit-content',
  },
}));

export default forwardRef(function FilePickerInput({
  value,
  onChange,
  showFile = true,
}, ref) {
  const classes = useStyles();
  const [name, setName] = useState();

  const url = useMemo(() => {
    if (/^http/.test(value)) {
      return value;
    }

    return buildCdnPhotoUrl(value);
  }, [value]);

  const fileName = useMemo(() => name || url.substring(url.lastIndexOf('/') + 1), [name, url]);

  const handleSelect = useCallback((file) => {
    onChange(file.Path);
    setName(file.Name);
  }, [onChange]);

  return (
    <div className={classes.container}>
      {showFile && value && (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
          title={fileName}
          download
        >
          <Icon type="paper-clip" />
          {fileName}
        </a>
      )}
      <Input
        ref={ref}
        placeholder="Tệp đính kèm"
        value={value}
        addonAfter={
          <FileChooserButton
            focusTriggerAfterClose={false}
            fileType={FileType.FILE}
            grid={false}
            title="Chọn tệp đính kèm"
            triggerComponent={TriggerComponent}
            onSelect={handleSelect}
          />
        }
      />
    </div>
  );
});

function TriggerComponent({ onClick }) {
  return (
    <Icon type="cloud-upload" onClick={onClick} />
  );
}
