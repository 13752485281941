import { Button, Input, InputNumber } from 'antd';
import { commonColumns } from 'core/common/listUtils';
import lodash from 'core/common/lodash';
import BaseTable from 'core/components/table/BaseTable';
import useIdGenerate from 'core/hooks/useIdGenerate';
import React, { Fragment } from 'react';

export default function PollOptionList({ options, onChange, onDelete }) {
  const genId = useIdGenerate(-1, true);

  const columns = [
    commonColumns.order,
    {
      Header: 'Lựa chọn',
      Cell: ({ original, index }) => (
        <Input
          value={original.Option}
          onChange={createHandleChange(index, 'Option')}
          autoFocus
        />
      ),
    },
    {
      Header: 'Sắp xếp',
      Cell: ({ original, index }) => (
        <InputNumber
          min={0}
          value={original.Order}
          onChange={createHandleChange(index, 'Order')}
        />
      ),
      width: 100,
    },
    {
      Header: 'Số lần vote',
      Cell: ({ original, index }) => (
        <InputNumber
          min={0}
          value={original.VoteCount}
          onChange={createHandleChange(index, 'VoteCount')}
        />
      ),
      width: 100,
    },
    {
      Cell: ({ original }) => <Button title="Xóa" icon="delete" onClick={() => onDelete(original.OptionID)} />,
      width: 50,
    },
  ];

  return (
    <Fragment>
      <BaseTable
        columns={columns}
        data={options}
        pageSize={Math.max(options.length, 6)}
        showPagination={false}
      />
      <br />
      <Button
        icon="plus"
        size="small"
        onClick={handleAdd}
        title="Thêm mới"
      >
        Thêm mới
      </Button>
    </Fragment>
  );

  function createHandleChange(index, name) {
    return (eventOrValue) => {
      const newOptions = options.slice();
      newOptions[index][name] = eventOrValue.target ? eventOrValue.target.value : eventOrValue;
      onChange(newOptions);
    };
  }

  function handleAdd() {
    onChange([
      ...options,
      {
        OptionID: genId(),
        Option: '',
        Order: lodash.get(lodash.maxBy(options, 'Order'), 'Order', 0) + 1,
        VoteCount: 0,
      },
    ]);
  }

}
