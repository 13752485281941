import lodash from 'core/common/lodash';
import { getWindowSize } from 'core/common/windowUtils';
import { ContentAvatarSize } from 'core/engine/content-avatar/constants';
import React, { useMemo, useState } from 'react';

export default function DraggableBackgroundImage({
  type,
  imageSrc,
  size,
  contentAvatars,
  setContentAvatars,
}) {
  const currContentAvatar = useMemo(() => {
    return contentAvatars[type] || getDefaultAvatar(type, imageSrc, size);
  }, [contentAvatars, type, imageSrc, size]);

  const windowWidth = useMemo(() => getWindowSize().width, [getWindowSize]);

  const width = Math.min(ContentAvatarSize[type].width, windowWidth - 60);
  const ratio = 1.0 * width / ContentAvatarSize[type].width;
  const height = ContentAvatarSize[type].height * ratio;
  const isScrollHorizontal = currContentAvatar.isScrollHorizontal;

  const [isMovingBackground, setIsMovingBackground] = useState(false);
  const [origin, setOrigin] = useState({
    x: currContentAvatar.x * ratio || 0,
    y: currContentAvatar.y * ratio || 0,
  });
  const [start, setStart] = useState({
    x: currContentAvatar.x * ratio || 0,
    y: currContentAvatar.y * ratio || 0,
  });

  const bounds = useMemo(() => {
    return {
      width: currContentAvatar.backgroundSize.width * ratio - width,
      height: currContentAvatar.backgroundSize.height * ratio - height,
    };
  }, [width, height, ratio, currContentAvatar.backgroundSize]);

  return (
    <div
      style={{
        width: width,
        height: height,
        backgroundImage: 'url(' + imageSrc + ')',
        backgroundPosition: currContentAvatar.x * ratio + 'px ' + currContentAvatar.y * ratio + 'px',
        backgroundSize: isScrollHorizontal
          ? 'auto ' + height + 'px'
          : width + 'px auto',
        backgroundRepeat: 'no-repeat',
        cursor: isScrollHorizontal ? 'col-resize' : 'row-resize',
        touchAction: 'none',
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onTouchMove={handleMouseMove}
    />
  );

  function handleMouseDown(event) {
    if (event.type.includes('touch')) {
      setOrigin({ x: event.touches[0].clientX, y: event.touches[0].clientY });
    }

    setIsMovingBackground(true);
  }

  function handleMouseMove(event) {
    const clientX = event.clientX || lodash.get(event, 'touches.0.clientX', 0);
    const clientY = event.clientY || lodash.get(event, 'touches.0.clientY', 0);

    const offset = {
      x: isScrollHorizontal ? start.x - (origin.x - clientX) : 0,
      y: isScrollHorizontal ? 0 : start.y - (origin.y - clientY),
    };

    if (isMovingBackground
      && offset.x <= 0 && (offset.x * -1) <= bounds.width
      && offset.y <= 0 && (offset.y * -1) <= bounds.height
    ) {
      event.target.style.backgroundPosition = start.x + 'px ' + start.y + 'px';
      setStart({ x: offset.x, y: offset.y });
    }

    setOrigin({ x: clientX, y: clientY });
  }

  function handleMouseUp() {
    const listContentAvatar = lodash.cloneDeep(contentAvatars);
    listContentAvatar[type] = {
      ...currContentAvatar,
      width: Math.round((bounds.width + width) / ratio),
      height: Math.round((bounds.height + height) / ratio),
      x: Math.round(start.x / ratio),
      y: Math.round(start.y / ratio),
      isModified: true,
    };

    setContentAvatars(listContentAvatar);
    setIsMovingBackground(false);
  }

  function getDefaultAvatar(type, imageSrc, size) {
    const avatarSize = ContentAvatarSize[type];
    const isScrollHorizontal = size.width / size.height > avatarSize.width / avatarSize.height;

    const backgroundSize = {
      width: isScrollHorizontal ?
        (size.width * avatarSize.height / size.height)
        : avatarSize.width,
      height: isScrollHorizontal
        ? avatarSize.height
        : (size.height * avatarSize.width / size.width),
    };

    return {
      x: Math.round(isScrollHorizontal ? (-1 * (backgroundSize.width - avatarSize.width) / 2) : 0),
      y: 0,
      isScrollHorizontal: isScrollHorizontal,
      backgroundSize: backgroundSize,
      Meta: {
        originImage: imageSrc,
        width: size.width,
        height: size.height,
      },
    };
  }
}
