import { ObjectType } from 'core/engine/common/constants';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import React, { Fragment } from 'react';
import Selector from 'core/components/common/Selector';
import TopicSelector from 'core/components/topic/TopicSelector';
import RegionSelector from 'core/components/region/RegionSelector';

export default function WidgetObjectSelector({
  value,
  onChange,
  objectTypes,
}) {
  const { objectType, objectId } = value;
  let widgetObjectIdSelector;

  switch (objectType) {
    case ObjectType.ZONE:
      widgetObjectIdSelector = (
        <ZoneSelector
          emptyLabel="Mặc định chuyên mục"
          emptyValue={0}
          value={objectId}
          onChange={handleIdChange}
        />
      );
      break;
    case ObjectType.TOPIC:
      widgetObjectIdSelector = (
        <TopicSelector
          emptyLabel="Mặc định chủ đề"
          emptyValue={0}
          value={objectId}
          onChange={handleIdChange}
        />
      );
      break;
    case ObjectType.REGION:
      widgetObjectIdSelector = (
        <RegionSelector
          emptyLabel="Mặc định địa phương"
          emptyValue={0}
          value={objectId}
          onChange={handleIdChange}
        />
      );
      break;
    default:
      widgetObjectIdSelector = null;
  }

  return (
    <Fragment>
      {objectTypes && (
        <Selector
          value={objectType}
          onChange={handleTypeChange}
          options={objectTypes}
          valueIsNumber={true}
          width={150}
        />
      )}
      {widgetObjectIdSelector}
    </Fragment>
  );

  function handleTypeChange(value) {
    return onChange({ objectType: value, objectId: 0 });
  }

  function handleIdChange(value) {
    return onChange({ objectType: objectType, objectId: value });
  }
}
