import { Form, Input } from 'antd';
import makeStyles from 'core/common/makeStyles';
import { countChar, countCharAfterStripHtml, countWord, countWordAfterStripHtml } from 'core/common/string';
import SimpleEditor from 'core/components/editor/SimpleEditor';
import { CustomComponentContext } from 'core/components/layout';
import { CHAPEAU_RICH_EDITOR } from 'core/config';
import useServerConfig from 'core/hooks/useServerConfig';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React, { useContext, useMemo } from 'react';
import ContentFormLengthAwareInput, { AwareInputTypeLabels, AwareInputTypes } from '../input/ContentFormLengthAwareInput';

const useStyles = makeStyles(() => ({
  hasLength: {
    '& .ant-form-explain': {
      clear: 'initial',
    },
  },
  descriptionInput: {
    fontSize: 'larger',
  },
}));

export default function ContentFormItemDescription({ content, form, prefix, maxLength, maxLengthType, editorRef, ...props }) {
  const customComponentContext = useContext(CustomComponentContext);
  const classes = useStyles();
  const { getFieldDecorator } = form;
  const config = useServerConfig();

  const awareInputType = useMemo(() => maxLengthType || config.LimitedInputType || AwareInputTypes[0], [maxLengthType, config]);

  if (customComponentContext && customComponentContext.ExtDashboard) {
    return (
      <customComponentContext.ExtContentFormItemDescription
        content={content}
        form={form}
        prefix={prefix}
        maxLength={maxLength}
        editorRef={editorRef}
        {...props}
      />
    );
  }

  return (
    <Form.Item className={classes.hasLength} title={ContentFieldLabel.Description}>
      {getFieldDecorator('Description', {
        initialValue: content.Description || prefix || '',
        rules: [{ validator }],
      })(
        <ContentFormLengthAwareInput
          placeholder={ContentFieldLabel.Description}
          Component={CHAPEAU_RICH_EDITOR ? SimpleEditor : Input.TextArea}
          maxLength={maxLength}
          maxLengthType={awareInputType}
          className={classes.descriptionInput}
          showStatusBar={false}
          richTextEditor={CHAPEAU_RICH_EDITOR}
          editorRef={editorRef}
        />
      )}
    </Form.Item>
  );

  function validator(rule, value, callback) {
    if (!parseInt(maxLength)) {
      return callback();
    }

    let countToCompare;

    if (CHAPEAU_RICH_EDITOR && awareInputType === AwareInputTypes.CHAR) {
      countToCompare = countCharAfterStripHtml(editorRef.current.getValue());
    } else if (CHAPEAU_RICH_EDITOR && awareInputType === AwareInputTypes.WORD) {
      countToCompare = countWordAfterStripHtml(editorRef.current.getValue());
    } else if (awareInputType === AwareInputTypes.CHAR) {
      countToCompare = countChar(value);
    } else {
      countToCompare = countWord(value);
    }

    if (countToCompare === 0) {
      return callback(new Error('Bắt buộc!'));
    }

    if (countToCompare > maxLength) {
      return callback(new Error(`Tối đa ${maxLength} ${AwareInputTypeLabels[awareInputType].toLowerCase()}`));
    }

    callback();
  }
}
