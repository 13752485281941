import { trimAccents } from '@bm/common';
import queryString from 'query-string';
import { SERVICE_WEB_DOMAIN, SHORT_CONTENT_URL_TEMPLATE, TOPIC_URL_TEMPLATE, CONTENT_URL_TEMPLATE, TAG_URL_TEMPLATE } from 'core/config';
import logger from './logger';
import { Sites, SubSiteDomain } from 'core/engine/site/constants';

export function parseQuery(query) {
  query = query || window.location.search;
  return queryString.parse(query);
}

export function buildQueryString(search) {
  return queryString.stringify(search);
}

export function buildSlug(str) {
  return trimAccents(str)
    .toLowerCase()
    .replace(/đ/g, 'd')
    .replace(/[^a-z0-9]/g, ' ')
    .trim()
    .replace(/\s+/g, '-');
}

export function buildPreviewUrlById(paramName = '', noiseId, siteId = Sites.ALL) {
  if (paramName !== '') {
    return (siteId > 0 ? SubSiteDomain[siteId] : SERVICE_WEB_DOMAIN) + `/contents/preview?${paramName}=${noiseId}`;
  }

  return '#';
}

export function buildContentUrl(id, title = '', zone = '', siteId = Sites.ALL) {
  const slugZone = zone === '' ? 'p1' : buildSlug(zone);
  const slugTitle = title === '' ? 'p2' : buildSlug(title);

  return (siteId > 0 ? SubSiteDomain[siteId] : SERVICE_WEB_DOMAIN) + CONTENT_URL_TEMPLATE.replace('<slugZone>', slugZone).replace('<slugTitle>', slugTitle).replace('<id>', id);
}

export function buildShortContentUrl(id, siteId = Sites.ALL) {
  return (siteId > 0 ? SubSiteDomain[siteId] : SERVICE_WEB_DOMAIN) + SHORT_CONTENT_URL_TEMPLATE.replace('<id>', id);
}

export function buildTopicUrl(id, title, siteId = Sites.ALL) {
  const slugTitle = title ? buildSlug(title) : 't';

  return (siteId > 0 ? SubSiteDomain[siteId] : SERVICE_WEB_DOMAIN) + TOPIC_URL_TEMPLATE.replace('<slugTitle>', slugTitle).replace('<id>', id);
}

export function buildTagUrl(id, name, url, siteId = Sites.ALL) {
  const slugTitle = url ? url : buildSlug(name);
  return (siteId > 0 ? SubSiteDomain[siteId] : SERVICE_WEB_DOMAIN) + TAG_URL_TEMPLATE.replace('<slugTitle>', slugTitle).replace('<id>', id);
}

export function trimUrlProtocol(url = '') {
  if (url.startsWith('//')) {
    url = 'http:' + url;
  }

  try {
    const urlObject = new URL(url);

    if (urlObject.protocol) {
      return url.substring(urlObject.protocol.length);
    }
  } catch {
    logger.error('Error while trim url protocol. Url =  ' + url);
  }

  return url;
}
