export class Registry {
  constructor({ idField = 'id' } = {}) {
    this.idField = idField;
    this.itemsById = {};
  }

  register(item) {
    const id = item[this.idField];

    if (id === undefined) {
      throw new Error(`${this.idField} is required`);
    }

    if (this.itemsById[id]) {
      throw new Error(`${this.idField}=${id} already exists`);
    }

    this.itemsById[id] = item;
  }

  registerMultiple(items = []) {
    items.forEach(item => this.register(item));
  }

  unregister(item) {
    const id = item[this.idField];

    if (id === undefined) {
      throw new Error(`${this.idField} is required`);
    }

    delete this.itemsById[id];
  }

  getAll() {
    return Object.values(this.itemsById);
  }

  get(idValue) {
    return this.itemsById[idValue];
  }
}
