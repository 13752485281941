import ui from './ui';
import auth from './auth';
import menu from './menu';
import config from './config';

export default {
  ui,
  auth,
  menu,
  config,
};
