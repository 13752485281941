import { useCallback, useEffect, useReducer } from 'react';
import { getWindowSize } from 'core/common/windowUtils';
import { clampDrag, clampResize } from 'core/components/dialog/utils/clampUtils';

const DEFAULT_STATE = {
  windowWidth: getWindowSize().width,
  windowHeight: getWindowSize().height,
  x: 0,
  y: 0,
  width: 800,
  height: 800,
  open: false,
};

const buildInitialState = ({ width, height }) => {
  return {
    ...DEFAULT_STATE,
    width,
    height,
  };
};

const ACTION_TYPE = {
  DRAG: 1,
  WINDOW_RESIZE: 2,
  MOUNT: 3,
  SHOW: 4,
  HIDE: 5,
  RESIZE: 6,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.DRAG:
      const clampedXY = clampDrag({
        windowWidth: state.windowWidth,
        windowHeight: state.windowHeight,
        x: action.x,
        y: action.y,
        width: state.width,
        height: state.height,
      });

      return {
        ...state,
        ...clampedXY,
      };
    case ACTION_TYPE.WINDOW_RESIZE:
      const newSize = {
        windowWidth: action.size.width,
        windowHeight: action.size.height,
      };

      if (!state.open) {
        return {
          ...state,
          ...newSize,
        };
      }

      const position = clampDrag({
        windowWidth: newSize.windowWidth,
        windowHeight: newSize.windowHeight,
        x: state.x,
        y: state.y,
        width: state.width,
        height: state.height,
      });
      const clampedSize = clampResize({
        windowWidth: newSize.windowWidth,
        windowHeight: newSize.windowHeight,
        x: position.x,
        y: position.y,
        width: state.width,
        height: state.height,
      });

      return {
        ...state,
        ...newSize,
        ...clampedSize,
        ...position,
      };
    case ACTION_TYPE.MOUNT:
      return {
        ...state,
        x: state.windowWidth / 2 - action.initWidth / 2,
        y: state.windowHeight / 2 - action.initHeight / 2,
      };
    case ACTION_TYPE.SHOW:
      const centerX = state.windowWidth / 2 - state.width / 2;
      const centerY = state.windowHeight / 2 - state.height / 2;
      const pos = clampDrag({
        windowWidth: state.windowWidth,
        windowHeight: state.windowHeight,
        x: centerX,
        y: centerY,
        width: state.width,
        height: state.height,
      });
      const size = clampResize({
        windowWidth: state.windowWidth,
        windowHeight: state.windowHeight,
        x: pos.x,
        y: pos.y,
        width: state.width,
        height: state.height,
      });

      return {
        ...state,
        ...pos,
        ...size,
        open: true,
      };
    case ACTION_TYPE.HIDE:
      return {
        ...state,
        open: false,
      };
    case ACTION_TYPE.RESIZE:
      const modalSize = clampResize({
        windowWidth: state.windowWidth,
        windowHeight: state.windowHeight,
        x: action.x,
        y: action.y,
        width: action.width,
        height: action.height,
      });

      return {
        ...state,
        ...modalSize,
      };
    default:
      throw new Error();
  }
};

export default function useDraggableDialog({
  width: initWidth = 800,
  height: initHeight = 800,
  visible,
}) {
  const [state, dispatch] = useReducer(
    reducer,
    { width: initWidth, height: initHeight },
    buildInitialState
  );

  useEffect(() => {
    if (visible) {
      dispatch({ type: ACTION_TYPE.SHOW });
    } else {
      dispatch({ type: ACTION_TYPE.HIDE });
    }

  }, [dispatch, visible]);

  const onMount = useCallback(() => {
    dispatch({ type: ACTION_TYPE.MOUNT, initWidth, initHeight });
  }, [dispatch, initWidth, initHeight]);

  const onDragModal = useCallback((x, y) => {
    dispatch({ type: ACTION_TYPE.DRAG, x, y });
  }, [dispatch]);

  const onResizeModal = useCallback((x, y, width, height) => {
    dispatch({ type: ACTION_TYPE.RESIZE, x, y, width, height });
  }, [dispatch]);

  const onWindowResize = useCallback(() => {
    dispatch({ type: ACTION_TYPE.WINDOW_RESIZE, size: getWindowSize() });
  }, [dispatch, getWindowSize]);

  return {
    ...state,
    onMount,
    onDragModal,
    onResizeModal,
    onWindowResize,
  };
}
