import { Button } from 'antd';
import React, { Fragment } from 'react';
import { emptyObject } from 'core/common/empty';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import AuthorContentList from './AuthorContentList';

export default function AuthorContentListButtonForList({ author = emptyObject }) {
  const { open, handleClose, handleOpen } = useDialog();

  return (
    <Fragment>
      <Button
        title="Danh sách tin"
        icon="database"
        onClick={handleOpen}
      />
      <BaseDialog
        keyboard
        maskClosable
        fullHeightFixed
        paddingTop={0}
        visible={open}
        title={author.FullName}
        footer={null}
        onCancel={handleClose}
        width={900}
      >
        <AuthorContentList authorId={author.AuthorID} />
      </BaseDialog>
    </Fragment>
  );
}
