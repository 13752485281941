import { trimHtml } from '@bm/common';
import { message } from 'antd';
import { parseJson } from 'core/common/jsonUtils';
import logger from 'core/common/logger';
import makeStyles from 'core/common/makeStyles';
import Separator from 'core/components/common/Separator';
import contentDraftApi from 'core/engine/content-draft/api';
import React, { useEffect, useRef, useState } from 'react';
import ContentAutoDraftRestoreButton from './ContentAutoDraftRestoreButton';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  list: {
    marginBottom: 10,
  },
}));

const SAVE_INTERVAL = 30000;

export default function ContentAutoDraft({
  contentId = 0,
  form,
  editorRef,
  descriptionRef,
}) {
  const classes = useStyles();

  const [lastDraft, setLastDraft] = useState();
  const [currentDraft, setCurrentDraft] = useState();

  const initialDataTextRef = useRef();

  const lastDraftDataRef = useRef();
  const currentDraftDataRef = useRef();

  const currentDraftIdRef = useRef();

  useEffect(() => {
    setLastDraft(null);
    lastDraftDataRef.current = null;
    setCurrentDraft(null);
    currentDraftDataRef.current = null;
    currentDraftIdRef.current = null;

    contentDraftApi.getList({
      fields: ['ContentDraftID', 'ModifiedDate', 'JsonData'],
      filters: { ContentID: contentId },
      sortBy: 'ModifiedDate:DESC',
    })
      .then(resp => resp.data.items)
      .then((drafts) => {
        const lastDraft = drafts.shift();

        if (lastDraft) {
          setLastDraft(lastDraft);
          lastDraftDataRef.current = lastDraft.JsonData;
        }

        if (drafts.length > 0) {
          drafts.reduce((previous, current) => {
            return previous.then(() =>
              contentDraftApi.delete(current.ContentDraftID));
          }, Promise.resolve())
            .then(() => {
              logger.debug(`Removed ${drafts.length} old drafts`);
            })
            .catch((err) => {
              logger.error('Failed to remove old drafts:', err);
            });
        }
      });
  }, [contentId]);

  useEffect(() => {
    // wait until editor loaded
    const intervalId = setInterval(() => {
      if (editorRef.current) {
        clearInterval(intervalId);
        initialDataTextRef.current = getDataAsText();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [contentId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentDataAsText = getDataAsText();

      if (currentDataAsText === initialDataTextRef.current) {
        logger.debug('Ignore draft saving because user does not change anything');
        return;
      }

      const jsonData = JSON.stringify({
        Title: form.getFieldValue('Title'),
        SubTitle: form.getFieldValue('SubTitle'),
        Description: descriptionRef.current ? descriptionRef.current.getValue() : form.getFieldValue('Description'),
        HtmlBody: editorRef.current.getValue(),
      });

      if (jsonData === lastDraftDataRef.current) {
        logger.debug('Ignore draft saving because nothing changed since the last draft');
        return;
      }

      if (jsonData === currentDraftDataRef.current) {
        logger.debug('Ignore draft saving because nothing changed since the last save');
        return;
      }

      logger.debug('Data changed, saving draft...');

      let promise;

      if (currentDraftIdRef.current) {
        promise = contentDraftApi.update({
          ContentDraftID: currentDraftIdRef.current,
          JsonData: jsonData,
        });
      } else {
        promise = contentDraftApi.create({
          ContentID: contentId,
          JsonData: jsonData,
        })
          .then((resp) => {
            currentDraftIdRef.current = resp.data.generatedId;
          });
      }

      promise
        .then(() => {
          currentDraftDataRef.current = jsonData;
          setCurrentDraft((old) => ({
            ...old,
            ContentDraftID: currentDraftIdRef.current,
            JsonData: jsonData,
            ModifiedDate: moment.now(),
          }));
        })
        .catch((err) => {
          logger.error('Error while saving draft:', err);
        });
    }, SAVE_INTERVAL);

    logger.debug('Audo draft timer started');

    return () => clearInterval(intervalId);
  }, [contentId]);

  if (!lastDraft && !currentDraft) {
    return null;
  }

  return (
    <div className={classes.list}>
      Tự động lưu lúc
      <Separator />
      {lastDraft && (
        <ContentAutoDraftRestoreButton draft={lastDraft} onRestore={handleRestore} />
      )}
      {currentDraft && (
        <ContentAutoDraftRestoreButton draft={currentDraft} onRestore={handleRestore} recent />
      )}
    </div>
  );

  function getDataAsText() {
    return [
      form.getFieldValue('Title'),
      form.getFieldValue('SubTitle'),
      descriptionRef.current ? descriptionRef.current.getValue() : form.getFieldValue('Description'),
      editorRef.current.getValue(),
    ]
      .map(text => trimHtml(text))
      .join();
  }

  function handleRestore(draft) {
    const data = parseJson(draft.JsonData, {});

    form.setFieldsValue(data);

    editorRef.current.setValue(data.HtmlBody);
    descriptionRef.current && descriptionRef.current.setValue(data.Description);

    message.success('Khôi phục bản lưu tự động thành công!');
  }
}
