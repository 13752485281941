import { Button, Form } from 'antd';
import { asyncAction } from 'core/common/async';
import { emptyObject } from 'core/common/empty';
import BaseDialog from 'core/components/common/BaseDialog';
import embeddedStyleApi from 'core/engine/embedded-styles/api';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import React from 'react';
import EmbeddedStyleForm from './EmbeddedStyleForm';

function EmbeddedStyleFormDialogButton({ form, style = emptyObject, type, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <React.Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={isEditMode ? 'Sửa giao diện: ' + style.Title : 'Tạo giao diện'}
        onClick={handleOpen}
      >
        {isEditMode ? '' : 'Tạo giao diện'}
      </Button>
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa giao diện: ' + style.Title : 'Tạo giao diện'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <EmbeddedStyleForm form={form} style={style} type={type} />
      </BaseDialog>
    </React.Fragment>
  );

  function submitFn({ formData }) {
    if (type) {
      formData.Type = type;
    }

    if (isEditMode) {
      return asyncAction('Sửa giao diện', () => {
        return embeddedStyleApi.update(formData)
          .then(handleDone);
      });
    }

    return asyncAction('Tạo giao diện', () => {
      return embeddedStyleApi.create(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'EmbeddedStyleFormDialogButton' })(EmbeddedStyleFormDialogButton);
