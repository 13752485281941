import { Checkbox } from 'antd';
import { commonColumns, sortingToSortBy } from 'core/common/listUtils';
import BaseDialog from 'core/components/common/BaseDialog';
import CheckboxFilterForList from 'core/components/common/CheckboxFilterForList';
import { OnEnterFilterForList } from 'core/components/common/OnEnterFilter';
import SelectableTable from 'core/components/table/SelectableTable';
import interviewApi from 'core/engine/interview/api';
import useDialog from 'core/hooks/useDialog';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { forwardRef, useImperativeHandle } from 'react';

const columns = [
  {
    Header: 'ID',
    accessor: 'InterviewID',
    maxWidth: 75,
    filterable: true,
    Filter: OnEnterFilterForList,
    ...commonColumns.allCentered,
  },
  {
    Header: 'Tiêu đề',
    accessor: 'Title',
    filterable: true,
    Filter: OnEnterFilterForList,
    minWidth: 200,
  },
  {
    Header: 'Hiển thị',
    id: 'Enabled',
    Cell: ({ original }) => <Checkbox checked={original.Enabled} />,
    filterable: true,
    Filter: CheckboxFilterForList,
    width: 80,
    ...commonColumns.allCentered,
  },
  {
    ...commonColumns.formatNumber,
    Header: 'Câu hỏi',
    width: 60,
    accessor: 'QuestionCount',
  },
  {
    ...commonColumns.formatNumber,
    Header: 'Trả lời',
    width: 60,
    accessor: 'AnswerCount',
  },
];

function listFn({ limit, offset, filters, sorting }) {
  return interviewApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default forwardRef(function InterviewDialog({ title, onSelect, onCancel }, ref) {
  const { open, handleClose, handleOpen } = useDialog();

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: {
      InterviewID: 'DESC',
    },
  });

  return (
    <BaseDialog
      focusTriggerAfterClose={false}
      keyboard
      maskClosable
      fullHeightFixed
      paddingTop={0}
      visible={open}
      title={title}
      width={900}
      onCancel={onCancel}
      footer={null}
    >
      <SelectableTable
        {...useListProps(listProps)}
        idField="InterviewID"
        onSelect={onSelect}
        columns={columns}
      />
    </BaseDialog>
  );
});
