import { Checkbox, DatePicker, Form, Input, InputNumber } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import AdsPositionSelector from 'core/components/ads-option/AdsPositionSelector';
import ImagePickerInput from 'core/components/common/ImagePickerInput';
import Selector from 'core/components/common/Selector';
import { MAX_INTEGER } from 'core/constants';
import { AdsOptionType, AdsOptionTypeLabel } from 'core/engine/ads-option/constants';
import { getAdsPositionCache } from 'core/engine/ads-position/caching';
import useServerConfig from 'core/hooks/useServerConfig';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AdsPositionFormTopicsInput from './AdsPositionFormTopicsInput';
import ZoneGroupCheckbox from './ZoneGroupCheckbox';

export default function AdsOptionForm({ form, onSubmit, adsOption }) {
  const { getFieldDecorator } = form;
  const config = useServerConfig();

  const [showImageUpload, setShowImageUpload] = useState(adsOption === null || adsOption.Type !== AdsOptionType.Script);
  const [positionWidth, setPositionWidth] = useState();
  const [positionHeight, setPositionHeight] = useState();

  const defaultStartDate = useMemo(() => moment(), []);
  const defaultEndDate = useMemo(() => moment().add(1, 'months'), []);
  const zoneIds = useMemo(() => {
    if (!isNullOrUndefined(adsOption.AdsZone)) {
      return adsOption.AdsZone.map(item => item.ZoneID);
    }
  }, [adsOption.AdsZone]);

  const topicIds = useMemo(() => {
    return (adsOption.AdsTopic || []).map(item => item.TopicID);
  }, [adsOption]);

  const infiniteEndTimeAdsPositionIds = useMemo(() => {
    return config.InfiniteEndTimeAdsPositionIDs ? config.InfiniteEndTimeAdsPositionIDs.split(',').map(Number) : [];
  }, [config.InfiniteEndTimeAdsPositionIDs]);

  const positionId = form.getFieldValue('PositionID');
  useEffect(() => {
    if (positionId) {
      getAdsPositionCache(positionId)
        .then((resp) => {
          if (resp && resp.item) {
            setPositionWidth(resp.item.Width);
            setPositionHeight(resp.item.Height);
          }
        });
    }
  }, [positionId]);

  const handleOptionTypeChange = useCallback((value) => {
    if (value === AdsOptionType.Script) {
      setShowImageUpload(false);
    } else {
      setShowImageUpload(true);
    }
  }, []);

  getFieldDecorator('OptionID', { initialValue: adsOption.OptionID });
  getFieldDecorator('CreatorID', { initialValue: adsOption.CreatorID });

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Tên quảng cáo">
        {getFieldDecorator('Title', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
          initialValue: adsOption.Title,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Loại quảng cáo">
        {getFieldDecorator('Type', {
          initialValue: adsOption.Type || AdsOptionType.Image,
        })(
          <Selector options={AdsOptionTypeLabel} valueIsNumber onChange={handleOptionTypeChange} />
        )}
      </Form.Item>

      {showImageUpload && (
        <Form.Item label="Ảnh đại diện">
          {getFieldDecorator('ImageUrl', {
            initialValue: adsOption.ImageUrl,
            rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
          })(
            <ImagePickerInput />
          )}
        </Form.Item>
      )}

      <Form.Item label="Mã quảng cáo">
        {getFieldDecorator('BodyCode', {
          initialValue: adsOption.BodyCode,
          rules: [{ max: 2000, message: 'Tối đa 2000 kí tự!' }],
        })(
          <Input.TextArea placeholder="Dùng cho quảng cáo loại Script" />
        )}
      </Form.Item>

      <Form.Item label="URL">
        {getFieldDecorator('Url', {
          initialValue: adsOption.Url,
        })(
          <Input placeholder="Link đến trang của đối tác: http://..." />
        )}
      </Form.Item>

      <Form.Item label="Vị trí">
        {getFieldDecorator('PositionID', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: adsOption.PositionID,
        })(
          <AdsPositionSelector emptyLabel="-- Chọn vị trí --" emptyValue="" />
        )}
      </Form.Item>
      <Form.Item label="Rộng">
        {getFieldDecorator('Width', {
          initialValue: positionWidth ? positionWidth : adsOption.Width || 0,
          rules: [{ required: true, message: 'Bắt buộc!' }],
        })(
          <InputNumber />
        )}
      </Form.Item>
      <Form.Item label="Cao">
        {getFieldDecorator('Height', {
          initialValue: positionHeight ? positionHeight : adsOption.Height || 0,
          rules: [{ required: true, message: 'Bắt buộc!' }],
        })(
          <InputNumber />
        )}
      </Form.Item>
      <Form.Item label="Ngày bắt đầu">
        {getFieldDecorator('StartDate', {
          initialValue: adsOption.StartDate ? moment(parseInt(adsOption.StartDate)) : defaultStartDate,
          rules: [{ validator: startDateValidator }],
        })(<DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />)}
      </Form.Item>
      <Form.Item label="Ngày kết thúc">
        {getFieldDecorator('EndDate', {
          initialValue: adsOption.EndDate
            ? moment(parseInt(adsOption.EndDate))
            : infiniteEndTimeAdsPositionIds.includes(form.getFieldValue('PositionID')) ? null : defaultEndDate,
          rules: [{ validator: endDateValidator }],
        })(<DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />)}
      </Form.Item>
      <Form.Item label="Phân phối">
        {getFieldDecorator('zoneIds', {
          initialValue: zoneIds,
        })(
          <ZoneGroupCheckbox />
        )}
      </Form.Item>
      <Form.Item label="Chủ đề">
        {getFieldDecorator('topicIds', {
          initialValue: topicIds,
        })(
          <AdsPositionFormTopicsInput />
        )}
      </Form.Item>
      <Form.Item label="Thứ tự">
        {getFieldDecorator('Order', {
          initialValue: adsOption.Order || 0,
          rules: [
            { required: true, message: 'Bắt buộc!' },
          ],
        })(
          <InputNumber />
        )}
      </Form.Item>
      <Form.Item label="Hiển thị">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: isNullOrUndefined(adsOption.Enabled) ? true : adsOption.Enabled,
        })(
          <Checkbox />
        )}
      </Form.Item>
    </Form>
  );

  function startDateValidator(rule, value, callback) {
    if (!value || !value.toDate() || !value.toDate().getTime()) {
      return callback('Bắt buộc!');
    }

    if (value.toDate().getTime() / 1000 > MAX_INTEGER) {
      return callback('Ngày bắt đầu quá lớn');
    }

    callback();
  }

  function endDateValidator(rule, value, callback) {
    const required = !infiniteEndTimeAdsPositionIds.includes(form.getFieldValue('PositionID'));

    if (!value || !value.toDate() || !value.toDate().getTime()) {
      if (required) {
        return callback('Bắt buộc!');
      }

      return callback();
    }

    if (value.toDate().getTime() / 1000 > MAX_INTEGER) {
      return callback('Ngày kết thúc quá lớn');
    }

    callback();
  }
}
