import React from 'react';
import classnames from 'core/common/classnames';
import makeStyles from 'core/common/makeStyles';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    alignItems: 'start',
    marginBottom: 5,
    height: 37,
    overflowX: 'auto',
    overflowY: 'hidden',

    '& > .ant-select': {
      maxWidth: 250,
    },

    '& > .ant-input-search': {
      maxWidth: 250,
    },

    '& > *:not(:last-child)': {
      marginRight: 10,
    },
    '&::-webkit-scrollbar': {
      height: 3,
    },
  },
  hide: {
    display: 'none',
  },
}));

export default function TableToolbarWrapper({ children, className, hidden }) {
  const classes = useStyles();

  return (
    <div className={classnames(classes.toolbar, hidden && classes.hide, className)}>{children}</div>
  );
}
