import { Form, Input } from 'antd';
import makeStyles from 'core/common/makeStyles';
import { countChar } from 'core/common/string';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React from 'react';
import ContentFormLengthAwareInput from '../input/ContentFormLengthAwareInput';

const useStyles = makeStyles(() => ({
  hasLength: {
    '& .ant-form-explain': {
      clear: 'initial',
    },
  },
}));

export default function ContentFormItemSubTitle({ content, form, maxLength }) {
  const classes = useStyles();
  const { getFieldDecorator } = form;

  return (
    <Form.Item className={classes.hasLength} title={ContentFieldLabel.SubTitle}>
      {getFieldDecorator('SubTitle', {
        initialValue: content.SubTitle,
        rules: [
          { validator },
        ],
      })(
        <ContentFormLengthAwareInput
          placeholder={ContentFieldLabel.SubTitle}
          Component={Input.TextArea}
          maxLength={maxLength}
          autoSize
        />
      )}
    </Form.Item>
  );

  function validator(rule, value, callback) {
    if (!parseInt(maxLength)) {
      return callback();
    }

    if (countChar(value) > maxLength) {
      return callback(new Error(`Tối đa ${maxLength} ký tự`));
    }

    callback();
  }
}
