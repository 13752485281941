import BasePlugin from 'core/components/editor/plugins/BasePlugin';
import { Config, ToolbarIcon } from '@zdocs/core';
import icon from './icon.svg';
import domUtils from 'core/common/domUtils';
import ContextMenuPlugin from '../ContextMenuPlugin';

export default class DropCapPlugin extends BasePlugin {

  static install() {
    ToolbarIcon.setIcon('dropCap', icon);

    Config.prototype.controls.dropCap = {
      tooltip: 'Drop Cap',
      icon: 'dropCap',
      exec: (zdocs, current) => {
        zdocs.execCommand('dropCap', current);
      },
    };
  }

  constructor(zdocs) {
    super(zdocs);

    this.callback = this.callback.bind(this);

    zdocs.registerCommand('dropCap', this.callback);

    zdocs.events.fire(ContextMenuPlugin.Events.ADD_BUTTON, {
      order: 40,
      icon: 'dropCap',
      title: 'Drop Cap',
      exec: () => {
        this.zdocs.execCommand('dropCap');
      },
    });
  }

  get name() {
    return 'DropCapPlugin';
  }

  callback() {
    const currentNode = this.Dom.closest(this.zdocs.selection.current(), 'P', this.zdocs.editor);

    domUtils.toggleClass(currentNode, 'dropcap');
  }
}
