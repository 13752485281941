import { useEffect, useState, useCallback } from 'react';
import logger from 'core/common/logger';

export default function useDataLoader({ defaultData, loadFn, autoLoad = true }) {
  const [data, setData] = useState(defaultData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (autoLoad) {
      loadData();
    }
  }, [autoLoad]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    return Promise.resolve()
      .then(() => loadFn())
      .then((resp) => {
        setData(resp);
        setIsLoading(false);
      })
      .catch((err) => {
        logger.error('Error while loading data:', err);
        setError(err);
        setIsLoading(false);
      });
  }, [loadFn]);

  return {
    data,
    isLoading,
    setIsLoading,
    error,
    loadData,
    setData,
  };
}
