import { Button, Form } from 'antd';
import React from 'react';
import { asyncAction } from 'core/common/async';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import CommentForm from './CommentForm';
import BaseDialog from 'core/components/common/BaseDialog';
import commentApi from 'core/engine/comment/api';
import { emptyObject } from 'core/common/empty';

function CommentFormDialogButton({ form, comment = emptyObject, onDone }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <React.Fragment>
      <Button icon="edit" title="Sửa bình luận" onClick={handleOpen}>
        Sửa
      </Button>
      <BaseDialog
        visible={open}
        title="Sửa bình luận"
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <CommentForm form={form} comment={comment} />
      </BaseDialog>
    </React.Fragment>
  );

  function submitFn({ formData }) {
    return asyncAction('Sửa bình luận', () => {
      return commentApi.update(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'CommentFormDialogButton' })(CommentFormDialogButton);
