import { Button, Form, Input, message } from 'antd';
import { asyncAction } from 'core/common/async';
import commonStyles from 'core/common/commonStyles';
import BaseDialog from 'core/components/common/BaseDialog';
import activityLogApi from 'core/engine/activity-log/api';
import useDialog from 'core/hooks/useDialog';
import React, { useState } from 'react';

export default function ActivityAddLabelButton() {
  const { open, handleOpen, handleClose } = useDialog();

  const [path, setPath] = useState('');
  const [label, setLabel] = useState('');

  return (
    <React.Fragment>
      <Button
        icon="plus"
        title="Thêm nhãn"
        onClick={handleOpen}
        type="primary"
      >
        Thêm nhãn
      </Button>
      <BaseDialog
        visible={open}
        title="Thêm nhãn"
        onCancel={handleDone}
        onOk={handleSubmit}
        okText="Lưu"
        width={600}
        fullHeight
      >
        <Form {...commonStyles.formItemLayout}>
          <Form.Item label="Đường dẫn">
            <Input value={path} onChange={event => setPath(event.target.value)} autoFocus />
          </Form.Item>
          <Form.Item label="Nhãn">
            <Input value={label} onChange={event => setLabel(event.target.value)} />
          </Form.Item>
        </Form>
      </BaseDialog>
    </React.Fragment>
  );

  function handleSubmit() {
    if (!path || !label) {
      message.error('Chưa gán nhãn');
      return;
    }

    return asyncAction('Thêm nhãn', () => {
      return activityLogApi.addLabel(path.trim(), label.trim()).then(handleDone);
    });
  }

  function handleDone() {
    setPath('');
    setLabel('');
    handleClose();
  }
}
