import { Result } from 'antd';
import logger from 'core/common/logger';
import remoteLogApi from 'core/engine/remote-log/api';
import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.error('Error while render tree:', error, errorInfo);
    remoteLogApi.logUncaughtException(
      `Error while render tree: ${error.message || String(error)}\n${errorInfo.componentStack || String(errorInfo)}`
    );
  }

  render() {
    if (this.state.hasError) {
      if (this.props.simple === false) {
        return (
          <Result
            status="error"
            title="Có lỗi xảy ra!"
            subTitle="Vui lòng liên hệ quản trị viên CMS để được hỗ trợ."
          />
        );
      }

      return <p>Có lỗi xảy ra! Vui lòng liên hệ quản trị viên CMS để được hỗ trợ.</p>;
    }

    return this.props.children;
  }
}
