import { Config, ZDocs } from '@zdocs/core';
import FileManagerDialog from 'core/components/file-manager/FileManagerDialog';
import { buildCdnPhotoUrl, trimCdnPhotoUrl } from 'core/common/cdn';
import BaseDialogPlugin from 'core/components/editor/plugins/BaseDialogPlugin';
import React from 'react';
import lodash from 'core/common/lodash';
import { BASE_PHOTO_URL } from 'core/config';

export default class AddPhotoPlugin extends BaseDialogPlugin {

  static install() {
    Config.prototype.controls.image = {
      tooltip: 'Chèn ảnh',
      icon: 'image',
    };
  }

  get name() {
    return 'AddPhotoPlugin';
  }

  get command() {
    return 'image';
  }

  get defaultOptions() {
    return {
      template: `
        <table class="picture">
          <tbody>
              <tr>
                <td class="pic">$Images</td>
              </tr>
              <tr>
                <td class="caption"><p>$Caption</p></td>
              </tr>
            </tbody>
        </table>`,
      imageTemplate: '<img data-image-id="$ImageId" src="$Src" $ExtAttributes/>',
    };
  }

  constructor(zdocs) {
    super(zdocs);

    this.insertMultiImages = this.insertMultiImages.bind(this);
    this.insertImages = this.insertImages.bind(this);
    this.imageToHtml = this.imageToHtml.bind(this);
    this.remove = this.remove.bind(this);
    this.onRechange = this.onRechange.bind(this);

    zdocs.events.on('image_remove', this.remove);
    zdocs.events.on('image_rechange', this.onRechange);
  }

  renderDialog(props) {
    return (
      <FileManagerDialog
        focusTriggerAfterClose={false}
        title="Chèn ảnh"
        okText="Chèn ảnh"
        multiple
        options={this.options.buttons}
        {...props}
      />
    );
  }

  onSelect(images, buttonId) {
    super.onSelect();
    let html;

    const button = lodash.keyBy(this.options.buttons, 'id')[buttonId];
    const template = button ? button.template : this.options.template;
    const imageTemplate = button ? button.imageTemplate : this.options.imageTemplate;

    if (button && !button.multiInsert) {
      html = this.insertImages(template, imageTemplate, images);
    } else {
      html = this.insertMultiImages(template, imageTemplate, images);
    }

    if (button && button.getClass && html.includes('$Class')) {
      html = html.replace('$Class', button.getClass(images));
    }

    this.insertHTMLToEditor(html, true, false, '.caption');
  }

  insertMultiImages(template, imageTemplate, images) {
    return images.map(image => this.insertImages(template, imageTemplate, [image])).join('');
  }

  insertImages(template = this.options.template, imageTemplate, images) {
    return template
      .replace('$Caption', lodash.get(images, '0.Caption') || '')
      .replace('$Images', images.map((image) => this.imageToHtml(imageTemplate, image)).join(''));
  }

  imageToHtml(imageTemplate = this.options.imageTemplate, image) {
    const width = lodash.get(image, 'Meta.width');
    const height = lodash.get(image, 'Meta.height');

    const extAttributes = width && height && `data-width="${width}" data-height="${height}"`;

    return imageTemplate
      .replace('$ExtAttributes', extAttributes || '')
      .replace('$ImageId', image.FileID)
      .replace('$Src', buildCdnPhotoUrl(image.Path));
  }

  remove(node) {
    if (!node) {
      return;
    }

    const table = this.Dom.closest(
      node,
      'table',
      this.zdocs.editor,
    );

    if (table) {
      const imgSize = table.querySelectorAll('img').length;

      if (imgSize <= 1 && table.classList.contains('picture')) {
        this.zdocs.execCommand('tablebin', false, table);
        this.Dom.safeRemove(table);
      }
    }
  }

  onRechange(image, dialog) {
    if (!image || !dialog) {
      return;
    }

    const helper = ZDocs.modules.Helpers;

    const imageSrc = helper.$$('.imageSrc', dialog.dialogbox_content)[0];
    const imageViewSrc = helper.$$('.imageViewSrc', dialog.dialogbox_content)[0];

    const onChangeImage = (file) => {
      const src = buildCdnPhotoUrl(file.Path);
      imageViewSrc.src = src;
      imageSrc.value = src;
    };

    this.openDialog({
      title: 'Thay đổi ảnh',
      onSelect: onChangeImage,
      multiple: false,
      currentFileUrl: imageSrc.value.startsWith(BASE_PHOTO_URL) && trimCdnPhotoUrl(imageSrc.value),
    });
  }
}
