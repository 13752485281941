import { httpGet, httpPost } from 'core/common/http-client';

const zoneApi = {
  get(id) {
    return httpGet(`/zones/get/item?ZoneID=${id}`);
  },

  getListAll() {
    return httpGet('/zones/get/items');
  },

  getListByAccount(roles = []) {
    const qs = { roles: roles.join() };
    return httpGet('/zones/get/items-by-account', { qs });
  },

  getListEnabled() {
    return httpGet('/zones/get/items-enabled');
  },

  create(data) {
    return httpPost('/zones/post/insert', data);
  },

  update(data) {
    return httpPost('/zones/post/update', data);
  },

  delete(id) {
    return httpPost('/zones/post/delete', { ZoneID: id });
  },
};

export default zoneApi;
