import React from 'react';
import { Form, Input, Checkbox } from 'antd';

const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
};

export default function GroupForm({ form, onSubmit, group }) {
  const { getFieldDecorator } = form;

  return (
    <Form {...formItemLayout} onSubmit={onSubmit}>
      {group.GroupID && (
        <Form.Item label="GroupID">
          {getFieldDecorator('GroupID', {
            initialValue: group.GroupID,
          })(
            <Input
              disabled
              type="number"
            />
          )}
        </Form.Item>
      )}
      <Form.Item label="Nhóm">
        {getFieldDecorator('Name', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
          initialValue: group.Name,
        })(<Input autoFocus/>)}
      </Form.Item>
      <Form.Item label="Tên hiệu">
        {getFieldDecorator('Alias', {
          initialValue: group.Alias,
          rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Phân quyền theo chuyên mục">
        {getFieldDecorator('CustomByZone', {
          valuePropName: 'checked',
          initialValue: group.CustomByZone || false,
        })(<Checkbox />)}
      </Form.Item>
    </Form>
  );
}
