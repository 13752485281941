import { Button, Form } from 'antd';
import React, { Fragment } from 'react';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import adsPositionApi from 'core/engine/ads-position/api';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import AdsPositionForm from './AdsPositionForm';

function AdsPositionFormDialogButton({ form, adsPosition = {}, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={isEditMode ? 'Sửa vị trí quảng cáo' : 'Tạo vị trí quảng cáo'}
        onClick={handleOpen}>
        {isEditMode ? '' : 'Tạo vị trí quảng cáo'}
      </Button>
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa vị trí quảng cáo' : 'Tạo vị trí quảng cáo'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <AdsPositionForm form={form} adsPosition={adsPosition} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    if (isEditMode) {
      return asyncAction('Sửa vị trí quảng cáo', () => {
        return adsPositionApi.update(formData)
          .then(handleDone);
      });
    }

    return asyncAction('Tạo vị trí quảng cáo', () => {
      return adsPositionApi.create(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'AdsPositionFormDialogButton' })(AdsPositionFormDialogButton);
