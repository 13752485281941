import { blue, gold, green, grey, orange, red, volcano, yellow } from '@ant-design/colors';
import { Button } from 'antd';
import { commonMixins } from 'core/common/commonStyles';
import { emptyArray, emptyFunction } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import FlexDiv from 'core/components/common/FlexDiv';
import Separator from 'core/components/common/Separator';
import { ContentAction } from 'core/engine/content/constants';
import { ContentHookAction } from 'core/modules/contents/constants';
import actionRegistry from 'core/registries/actions';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import ContentFormToolbarDeleteButton from './ContentFormToolbarDeleteButton';
import ContentFormToolbarForwardButton from './ContentFormToolbarForwardButton';
import ContentFormToolbarPreviewButton from './ContentFormToolbarPreviewButton';
import ContentFormToolbarPublishButton from './ContentFormToolbarPublishButton';
import ContentFormToolbarResetButton from './ContentFormToolbarResetButton';
import ContentFormToolbarReturnButton from './ContentFormToolbarReturnButton';
import ContentFormToolbarUnpublishButton from './ContentFormToolbarUnpublishButton';

const useStyles = makeStyles(() => ({
  container: {
    flexWrap: 'wrap',
    background: '#fff',

    '& > *': {
      marginTop: 10,
    },
  },
  checkout: {
    ...commonMixins.buttonColor('inital', orange[6], orange[6]),
  },
  save: {
    ...commonMixins.buttonColor(blue[6]),
  },
  submit: {
    ...commonMixins.buttonColor(yellow[6]),
  },
  approve: {
    ...commonMixins.buttonColor(orange[6]),
  },
  publish: {
    ...commonMixins.buttonColor(green[6]),
  },
  unpublish: {
    ...commonMixins.buttonColor(volcano[6]),
  },
  return: {
    ...commonMixins.buttonColor(gold[8]),
  },
  delete: {
    ...commonMixins.buttonColor(red[6]),
  },
  reset: {
    ...commonMixins.buttonColor(grey[6]),
  },
  forward: {
    ...commonMixins.buttonColor(grey[4]),
  },
}));

const actions = [
  { name: ContentAction.CHECKOUT, label: 'Đóng', className: 'checkout', icon: 'left-circle' },
  { name: ContentAction.SAVE, label: 'Lưu tin', className: 'save', icon: 'to-top' },
  { name: ContentAction.SUBMIT, label: 'Gửi tin', className: 'submit', icon: 'up-circle' },
  { name: ContentAction.APPROVE, label: 'Duyệt tin', className: 'approve', icon: 'check-circle' },
  { name: ContentAction.PUBLISH, label: 'Đăng tin', className: 'publish', icon: 'cloud-upload' },
  { name: ContentAction.SUBMIT_RETURN, label: 'Trả lại biên tập', className: 'return', icon: 'rollback' },
  { name: ContentAction.APPROVE_RETURN, label: 'Trả lại người duyệt', className: 'return', icon: 'rollback' },
  { name: ContentAction.UNPUBLISH, label: 'Gỡ tin', className: 'unpublish', icon: 'cloud-download' },
  { name: ContentAction.DELETE, label: 'Xóa tin', className: 'delete', icon: 'delete' },
  { name: ContentAction.RESET, label: 'Viết lại', className: 'reset', icon: 'reload' },
  { name: ContentAction.FOWARD, label: 'Chuyển tiếp', className: 'forward', icon: 'arrow-right' },
  { name: ContentAction.MOVE, label: 'Cho tin', className: 'forward', icon: 'arrow-right' },
  { name: ContentAction.PREVIEW, label: 'Xem trước', className: '', icon: 'eye' },
];

export default function ContentFormToolbar({
  content,
  form,
  editorRef,
  descriptionEditorRef,
  avatarDescriptionEditorRef,
  visibleActions = emptyArray,
  onAction = emptyFunction,
  getFormData = emptyFunction,
}) {
  const classes = useStyles();
  const [loadingAction, setLoadingAction] = useState();
  const extActions = [];

  const displayActions = useMemo(() => {
    return actions.filter(action => visibleActions.includes(action.name));
  }, [visibleActions]);

  const handleClick = useCallback((event) => {
    const action = event.target.name;

    setLoadingAction(action);

    Promise.resolve()
      .then(() => onAction(action))
      .then(() => setLoadingAction())
      .catch(() => setLoadingAction());
  }, [onAction]);

  actionRegistry.doAction(ContentHookAction.EXT_TOOLBAR_ACTIONS, {
    content,
    form,
    editorRef,
    descriptionEditorRef,
    avatarDescriptionEditorRef,
    extActions,
    onAction,
    getFormData,
  });

  return (
    <FlexDiv className={classes.container}>
      {displayActions.map(action => (
        <Fragment key={action.name}>
          {renderButton(action)}
          <Separator size="small" />
        </Fragment>
      ))}
      {extActions.map((action, index) => (
        <Fragment key={index}>
          {action}
          <Separator size="small" />
        </Fragment>
      ))}
    </FlexDiv>
  );

  function renderButton(action) {
    switch (action.name) {
      case ContentAction.MOVE:
        return (
          <ContentFormToolbarForwardButton getFormData={getFormData} onAction={onAction} className={classes[action.className]} isMoveButton={true} />
        );
      case ContentAction.FOWARD:
        return (
          <ContentFormToolbarForwardButton getFormData={getFormData} onAction={onAction} className={classes[action.className]} />
        );
      case ContentAction.PUBLISH:
        return (
          <ContentFormToolbarPublishButton
            getFormData={getFormData}
            action={action}
            onAction={onAction}
            className={classes[action.className]}
          />
        );
      case ContentAction.PREVIEW:
        return (
          <ContentFormToolbarPreviewButton getFormData={getFormData} />
        );
      case ContentAction.UNPUBLISH:
        return (
          <ContentFormToolbarUnpublishButton
            getFormData={getFormData}
            action={action}
            onAction={onAction}
            className={classes[action.className]}
            loading={loadingAction === action.name}
          />
        );
      case ContentAction.DELETE:
        return (
          <ContentFormToolbarDeleteButton
            getFormData={getFormData}
            action={action}
            onAction={onAction}
            className={classes[action.className]}
            loading={loadingAction === action.name}
          />
        );
      case ContentAction.SUBMIT_RETURN:
      case ContentAction.APPROVE_RETURN:
        return (
          <ContentFormToolbarReturnButton
            getFormData={getFormData}
            action={action}
            onAction={onAction}
            className={classes[action.className]}
            loading={loadingAction === action.name}
            modalProps={{ okText: action.label }}
          />
        );
      case ContentAction.RESET:
        return (
          <ContentFormToolbarResetButton
            action={action}
            onAction={onAction}
            className={classes[action.className]}
            loading={loadingAction === action.name}
          />
        );
      default:
        return (
          <Button
            name={action.name}
            title={action.label}
            className={classes[action.className]}
            onClick={handleClick}
            icon={action.icon}
            loading={loadingAction === action.name}
          >
            {action.label}
          </Button>
        );
    }
  }
}
