import { Form } from 'antd';
import lodash from 'core/common/lodash';
import { ContentDisplayType } from 'core/engine/content/constants';
import ContentFormAvatarInput from 'core/modules/contents/components/form/input/ContentFormAvatarInput';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React, { useMemo } from 'react';

export default function ContentFormItemLongformAvatar({ content, form, ...props }) {
  const { getFieldValue, getFieldDecorator } = form;

  const initialValue = useMemo(() => {
    return {
      url: lodash.get(content.Meta, 'LongformAvatarUrl'),
    };
  }, [content]);

  return getFieldValue('DisplayType') === ContentDisplayType.LONGFORM && (
    <Form.Item label={ContentFieldLabel.LongformAvatar}>
      {getFieldDecorator('LongformAvatar', {
        rules: [
          { validator: avatarValidator },
        ],
        initialValue,
      })(
        <ContentFormAvatarInput {...props} />
      )}
    </Form.Item>
  );
}

function avatarValidator(rule, value, callback) {
  if (!value || !value.url) {
    return callback();
  }

  if (value.url.length > 250) {
    return callback(new Error('Tối đa 250 kí tự!'));
  }

  if (value && value.error) {
    return callback(new Error('Đường dẫn ảnh không chính xác!'));
  }

  callback();
}
