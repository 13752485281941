import { Checkbox, DatePicker, Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import AccountTransfer from 'core/components/account/AccountTransfer';
import SimpleEditor from 'core/components/editor/SimpleEditor';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';

export default function InterviewForm({ form, interview, editorRef }) {
  const { getFieldDecorator, resetFields } = form;

  const startDate = useMemo(() => {
    return interview.StartDate ? moment(interview.StartDate) : moment();
  }, [interview.StartDate]);

  const endDate = useMemo(() => {
    return interview.EndDate ? moment(interview.EndDate) : moment().add(1, 'day');
  }, [interview.EndDate]);

  useEffect(() => resetFields(), [interview]);

  getFieldDecorator('InterviewID', { initialValue: interview.InterviewID });

  return (
    <Form {...commonStyles.formItemLayout}>
      <Form.Item label="Tiêu đề">
        {getFieldDecorator('Title', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: interview.Title,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Ngày bắt đầu">
        {getFieldDecorator('StartDate', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: startDate,
        })(
          <DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
        )}
      </Form.Item>
      <Form.Item label="Ngày kết thúc">
        {getFieldDecorator('EndDate', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: endDate,
        })(
          <DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
        )}
      </Form.Item>
      <Form.Item label="Mô tả">
        {getFieldDecorator('Description', {
          initialValue: interview.Description,
        })(
          <SimpleEditor placeholder="Nhập mô tả..." editorRef={editorRef} />
        )}
      </Form.Item>
      <Form.Item label="Hiển thị">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: isNullOrUndefined(interview.Enabled) ? true : interview.Enabled,
        })(
          <Checkbox />
        )}
      </Form.Item>
      <Form.Item label="Quản lý">
        {getFieldDecorator('Admins', {
          initialValue: interview.Admins ? interview.Admins : '',
        })(
          <AccountTransfer showSearch={true} lazy={false} listStyle={{ width: 270, height: 290 }} />
        )}
      </Form.Item>
      <Form.Item label="Người phỏng vấn">
        {getFieldDecorator('Editors', {
          initialValue: interview.Editors ? interview.Editors : '',
        })(
          <AccountTransfer showSearch={true} lazy={false} listStyle={{ width: 270, height: 290 }} />
        )}
      </Form.Item>

    </Form>
  );
}
