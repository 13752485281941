import lodash from 'core/common/lodash';
import { buildTreeList } from 'core/common/utils';
import zoneApi from './api';
import zoneUtils from './utils';

export const getListZoneCache = lodash.memoize(() =>
  zoneApi.getListAll().then(resp => resp.data.items)
);

export const getListEnabledZoneCache = lodash.memoize(() =>
  zoneApi.getListEnabled().then(resp => resp.data.items)
);

export const getListParentEnabledZoneCache = lodash.memoize(() =>
  zoneApi.getListEnabled().then(resp => resp.data.items.filter(item => item.ParentID === 0))
);

export const getListEnabledZoneByAccountCache = lodash.memoize((roles) =>
  zoneApi.getListByAccount(roles).then(resp => resp.data.items)
);

export const getTreeListZoneCache = lodash.memoize(() =>
  getListZoneCache().then(items => buildTreeList(items, { idField: 'ZoneID' }))
);

export const getMapZoneCache = lodash.memoize(() =>
  getListZoneCache().then(items => lodash.keyBy(items, 'ZoneID'))
);

export const getZoneNameCache = lodash.memoize((zoneId) =>
  getMapZoneCache().then(zoneMap => lodash.get(zoneMap, `${zoneId}.Name`, '#'))
);

export const getTreeListZoneByAccountCache = lodash.memoize(
  roles => zoneApi.getListByAccount(roles)
    .then(resp => addDisabledParents(resp.data.items))
    .then(items => buildTreeList(items, { idField: 'ZoneID' })),
  roles => roles && roles.join(),
);

export const getTreeListEnabledZoneCache = lodash.memoize((rootZoneId) =>
  zoneApi.getListEnabled()
    .then(resp => rootZoneId ? zoneUtils.getSubsByRootId(rootZoneId) : resp.data.items)
    .then(items => buildTreeList(items, { idField: 'ZoneID' }))
);

const addDisabledParents = async function (zones) {
  if (!zones || zones.length === 0) {
    return zones;
  }

  const mapAllZones = await getMapZoneCache();

  const zoneIds = zones.map(zone => zone.ZoneID);

  zones.map(zone => zone.ParentID).forEach((zoneId) => {
    if (zoneId && !zoneIds.includes(zoneId) && mapAllZones[zoneId]) {
      // add parent to tree list but disable them because of display purpose only
      zones.push({ ...mapAllZones[zoneId], disabled: true });
    }
  });

  return zones;
};
