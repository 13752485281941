import { IS_MOBILE } from '@bm/common';

export const LoginStatus = {
  LOGGED_IN: 1,
  NOT_LOGGED_IN: 2,
};

export const ServerErrorCode = {
  INVALID_SESSION: -413,
  SESSION_EXPIRED: -423,
  SESSION_EMPTY: -424,
  FILE_ALREADY_EXISTS: -2030,
  NOT_EXIST: -403,
  NOT_FOUND: -404,
  PERMISSION_DENIED: -431,
};

export const MAX_INTEGER = 2147483647;

export const CONTENT_CLASS = 'content-body';

export const HEADER_HEIGHT = IS_MOBILE ? 48 : 64;

export const HookAction = {
  CHANGE_VIEW_COMPONENT: 'CHANGE_VIEW_COMPONENT',
  NORMALIZE_HTML: 'NORMALIZE_HTML',
};

export const ScreenBreakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const MediaQuery = {
  xs: `@media (max-width: ${ScreenBreakpoints.sm}px)`,
  sm: `@media (min-width: ${ScreenBreakpoints.sm}px)`,
  md: `@media (min-width: ${ScreenBreakpoints.md}px)`,
  lg: `@media (min-width: ${ScreenBreakpoints.lg}px)`,
  xl: `@media (min-width: ${ScreenBreakpoints.xl}px)`,
  xxl: `@media (min-width: ${ScreenBreakpoints.xxl}px)`,
};
