import { httpGet, httpPost } from 'core/common/http-client';

const contentDraftApi = {
  getList({ limit = 20, offset = 0, fields = [], sortBy, filters }) {
    const qs = { limit, offset, fields, sortBy, ...filters };
    return httpGet('/content-drafts/get/items-by-range', { qs });
  },

  create(data) {
    return httpPost('/content-drafts/post/insert', data);
  },

  update(data) {
    return httpPost('/content-drafts/post/update', data);
  },

  delete(id) {
    return httpPost('/content-drafts/post/delete', { ContentDraftID: id });
  },
};

export default contentDraftApi;
