import { blue, grey } from '@ant-design/colors';
import classnames from 'core/common/classnames';
import { isNullOrUndefined } from 'core/common/is';
import makeStyles from 'core/common/makeStyles';
import NumberDisplay from 'core/components/common/NumberDisplay';
import { ExtendedRight } from 'core/engine/extended-right/constants';
import useAuth from 'core/hooks/useAuth';
import useServerFeature from 'core/hooks/useServerFeature';
import React, { useContext, useMemo } from 'react';
import { CustomComponentContext } from '../layout';

const useStyle = makeStyles(() => ({
  container: {
    width: '100%',
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  indicator: {
    fontSize: 9,
    fontWeight: 700,
    padding: '2px 4px',
    borderRadius: 3,
    color: '#fff',
  },
  cms: {
    backgroundColor: grey[4],
  },
  ga: {
    backgroundColor: blue[6],
  },
}));

export default function ContentViewCountForList({ content }) {
  const customComponentContext = useContext(CustomComponentContext);

  const { loggedInUser, hasExtendedRight } = useAuth();
  const showGAView = useServerFeature('content-show-ga-view-count');

  const showGAViewCount = useMemo(() => {
    return showGAView ? (
      loggedInUser.isAdmin || hasExtendedRight(ExtendedRight.READ_GA_VIEW_COUNT)
    ) : false;
  }, [showGAView, loggedInUser, hasExtendedRight]);

  const classes = useStyle();

  if (customComponentContext && customComponentContext.ExtContentViewCountForList) {
    return <customComponentContext.ExtContentViewCountForList content={content} />;
  }

  const ViewCount = content.ContentStat ? content.ContentStat.ViewCount : content.ViewCount;
  const GAViewCount = showGAViewCount
    ? content.ContentStat ? content.ContentStat.GAViewCount : content.GAViewCount
    : null;

  if (!showGAViewCount && (isNullOrUndefined(ViewCount) || isNullOrUndefined(GAViewCount))) {
    return <NumberDisplay value={ViewCount || GAViewCount} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.itemContainer}>
        <span className={classnames(classes.indicator, classes.cms)}>CMS</span><NumberDisplay value={ViewCount} />
      </div>
      <div className={classes.itemContainer}>
        <span className={classnames(classes.indicator, classes.ga)}>GA</span><NumberDisplay value={GAViewCount} />
      </div>
    </div>
  );
}
