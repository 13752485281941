import { httpGet } from 'core/common/http-client';

const dashboardApi = {
  getInfo() {
    return httpGet('/dashboards/get/info');
  },

  getStats() {
    return httpGet('/dashboards/get/stats');
  },
};

export default dashboardApi;
