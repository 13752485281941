import { Empty, Spin } from 'antd';
import React, { useCallback, useRef, useState, useMemo, useImperativeHandle, forwardRef } from 'react';
import commonStyles from 'core/common/commonStyles';
import { emptyArray, emptyFunction, emptyObject } from 'core/common/empty';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import FileRowItem from './FileRowItem';
import FileBoxItem from './FileBoxItem';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexRow,
    width: '100%',
    flexWrap: 'wrap',
  },
  emptyContainer: {
    ...commonStyles.flexAllCentered,
    width: '100%',
    height: '100%',
  },
}));

const FileGrid = forwardRef(function FileGrid({
  loading = true,
  files = emptyArray,
  multiple = false,
  onSelect = emptyFunction,
  editingFile = emptyObject,
  grid = true,
  currentFileUrl,
}, ref) {
  const classes = useStyles();
  const [selectedMap, setSelectedMap] = useState({});
  const selectedFilesRef = useRef([]);

  useImperativeHandle(ref, () => ({
    fileSelect: handleFileSelect,
  }));

  const FileItemDisplay = useMemo(() => grid ? FileBoxItem : FileRowItem, [grid]);

  const handleFileSelect = useCallback((file, isDoubleClick) => {
    if (multiple) {
      const matcher = f => f.FileID === file.FileID;
      const findIndex = selectedFilesRef.current.findIndex(matcher);

      if (isDoubleClick) {
        //double click -> clear selected, choose only current file and handleOK
        selectedFilesRef.current = [];
        selectedFilesRef.current.push(file);
      } else if (findIndex > -1) {
        lodash.remove(selectedFilesRef.current, matcher);
      } else {
        selectedFilesRef.current.push(file);
      }

      onSelect(selectedFilesRef.current, isDoubleClick);

      const newSelectedMap = {};

      selectedFilesRef.current.forEach((item, index) => {
        newSelectedMap[item.FileID] = index + 1;
      });

      setSelectedMap(newSelectedMap);
    } else {
      selectedFilesRef.current = file;
      onSelect(file, isDoubleClick);
      setSelectedMap({
        [file.FileID]: true,
      });
    }
  }, [multiple, onSelect]);

  if (loading) {
    return (
      <div className={classes.emptyContainer}><Spin /></div>
    );
  }

  if (files.length === 0) {
    return (
      <div className={classes.emptyContainer}><Empty /></div>
    );
  }

  return (
    <div className={classes.container}>
      {files.map(file => (
        <FileItemDisplay
          key={file.FileID}
          file={file}
          onSelect={handleFileSelect}
          selected={selectedMap[file.FileID] || (currentFileUrl && files.length === 1)}
          editing={editingFile.FileID === file.FileID}
        />
      ))}
    </div>
  );
});

export default FileGrid;
