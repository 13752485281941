import { httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_FIELDS = [
  'StyleID',
  'Title',
  'Type',
  'Platform',
  'EditorID',
  'ModifiedDate',
];

const embeddedStyleApi = {
  getList({ limit = 20, offset = 0, sortBy, lookup = [], fields = [], filters }) {
    const qs = { limit, offset, sortBy, lookup, fields: [...DEFAULT_FIELDS, ...fields], ...filters };
    return httpGet('/embedded-styles/get/items-by-range', { qs });
  },

  create(data) {
    return httpPost('/embedded-styles/post/insert', data);
  },

  update(data) {
    return httpPost('/embedded-styles/post/update', data);
  },

  delete(id) {
    return httpPost('/embedded-styles/post/delete', { StyleID: id });
  },
};

export default embeddedStyleApi;
