import { Button } from 'antd';
import { emptyObject } from 'core/common/empty';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import React, { Fragment } from 'react';
import TopicContentLog from './TopicContentLog';

export default function TopicContentLogDialogButton({ topic = emptyObject, modifiedDate }) {
  const { open, handleOpen, handleClose } = useDialog();

  return (
    <Fragment>
      <Button icon="ordered-list" title="Truy vết danh sách tin" onClick={handleOpen} />
      <BaseDialog
        visible={open}
        title={`Truy vết danh sách tin: ${topic.Name}`}
        onCancel={handleClose}
        width={600}
        footer={[
          <Button key="ok" type="primary" onClick={handleClose}>OK</Button>,
        ]}
        fullHeightFixed={true}
      >
        <TopicContentLog contentIds={topic.Contents} modifiedDate={modifiedDate} />
      </BaseDialog>
    </Fragment>
  );
}
