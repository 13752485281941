import { message, Result } from 'antd';
import logger from 'core/common/logger';
import Container from 'core/components/common/Container';
import GoBackButton from 'core/components/common/GoBackButton';
import topicApi from 'core/engine/topic/api';
import { TopicType } from 'core/engine/topic/constants';
import React, { useEffect, useState } from 'react';
import TopicNormalContentListDisplay from './topic-normal/TopicNormalContentListDisplay';
import TopicSystemContentListDisplay from './topic-system/TopicSystemContentListDisplay';
import { Sites } from 'core/engine/site/constants';

export default function TopicDetailView({ topicId, systemContentEditable = true, siteId = Sites.ALL }) {
  const [topic, setTopic] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!topicId) {
      setTopic(null);
      setIsLoading(false);
      return;
    }

    let active = true;

    setIsLoading(true);

    topicApi.get(topicId)
      .then((resp) => {
        if (active) {
          setTopic(resp.data.item);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        logger.error('Error while loading topic:', err);
        message.error('Có lỗi xảy ra!');
      });

    return () => active = false;
  }, [topicId]);

  return (
    <Container loading={isLoading} pageTitle={topic ? `Danh sách tin chủ đề: ${topic.Name}` : 'Chủ đề'}>
      {topic ? (topic.Type === TopicType.SYSTEM ? (
        <TopicSystemContentListDisplay topic={topic} contentEditable={systemContentEditable} siteId={siteId} />
      ) : (
        <TopicNormalContentListDisplay topic={topic} siteId={siteId} />
      )) : (
        <Result status="404" title="Chủ đề không tồn tại" extra={<GoBackButton />} />
      )}
    </Container>
  );
}
