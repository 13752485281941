import React, { forwardRef, useEffect, useState } from 'react';
import CommandPermissionTable from 'core/components/command/CommandPermissionTable';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import groupApi from 'core/engine/group/api';

export default forwardRef(function GroupCommandTable({ groupId }, ref) {
  const [isLoading, setIsLoading] = useState(true);
  const [groupCommands, setGroupCommands] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    groupApi.getCommandRoles(groupId)
      .then(resp => resp.data)
      .then((groupCommands) => {
        setGroupCommands(groupCommands);
        setIsLoading(false);
      });
  }, [groupId]);

  return (
    <LoadingWrapper center loading={isLoading}>
      <CommandPermissionTable permissions={groupCommands} ref={ref} />
    </LoadingWrapper>
  );
});
