import { Form, Input } from 'antd';
import AuthzCheck from 'core/components/common/AuthzCheck';
import React from 'react';

export default function ContentFormItemUrl({ content, form }) {
  const { getFieldDecorator } = form;

  return (
    <AuthzCheck extendedRight="content.edit-custom-url">
      <Form.Item label="Custom Url">
        {getFieldDecorator('Url', {
          initialValue: content.Url,
          rules: [
            { pattern: /^[a-zA-Z0-9-]+$/, message: 'Chỉ được phép dùng các kí tự không dấu, số và dấu gạch ngang!' },
          ],
        })(
          <Input placeholder="Đường dẫn tùy biến của bài viết..." />
        )}
      </Form.Item>
    </AuthzCheck>
  );
}
