import { Input, InputNumber } from 'antd';
import React, { forwardRef, useState, useEffect } from 'react';
import makeStyles from 'core/common/makeStyles';
import { formatNumber } from 'core/common/string';
import { isNullOrUndefined } from 'core/common/is';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  input: {
    width: 'calc(100% - 50px)',
    transition: 'none',
  },
  currency: {
    backgroundColor: '#fafafa',
    border: '1px solid #d9d9d9',
    borderRadius: 4,
    height: 32,
    lineHeight: '32px',
    textAlign: 'center',
    width: 50,
  },
}));

export default forwardRef(function NumberAddonInput({ value, onChange, addon, ...props }, ref) {
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState();

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Input.Group compact className={classes.container}>
      <InputNumber
        className={classes.input}
        ref={ref}
        value={internalValue}
        onChange={onChange}
        formatter={formatNumber}
        parser={value => value.replace(/,/g, '')}
        {...props}
      />
      {!isNullOrUndefined(addon) && (<span className={classes.currency}>{addon}</span>)}
    </Input.Group>
  );
});
