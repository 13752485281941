import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { Input } from 'antd';

const defaultAutoSize = { minRows: 1, maxRows: 4 };

export default forwardRef(function OnBlurTextArea({ value, onChange, ...props }, ref) {
  const [internalValue, setInternalValue] = useState();

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = useCallback(event => setInternalValue(event.target.value), []);
  const handleBlur = useCallback(() => onChange(internalValue), [internalValue, onChange]);

  return (
    <Input.TextArea
      ref={ref}
      autoSize={defaultAutoSize}
      value={internalValue}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
});
