import { Button, Form, message } from 'antd';
import React, { Fragment, useMemo, useRef } from 'react';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import InterviewGuestForm from './InterviewGuestForm';
import interviewGuestApi from 'core/engine/interview-guest/api';
import { INTERVIEW_MAX_GUESTS } from 'core/config';

function InterviewGuestFormDialogButton({ form, guest, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });
  const editorRef = useRef();

  const title = useMemo(() => isEditMode ? 'Thay đổi thông tin khách mời' : 'Thêm khách mời', [isEditMode]);

  return (
    <Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={title}
        onClick={handleOpen}
      >
        {isEditMode ? '' : title}
      </Button>
      <BaseDialog
        visible={open}
        title={title}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
      >
        <InterviewGuestForm form={form} guest={guest} editorRef={editorRef} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    formData.Description = editorRef.current.getNormalizedValue();

    if (isEditMode) {
      return asyncAction(title, () => {
        return interviewGuestApi.update(formData)
          .then(handleDone);
      });
    } else {
      if (guest.Order > INTERVIEW_MAX_GUESTS) {
        return message.error('Đạt mức tối đa ' + INTERVIEW_MAX_GUESTS + ' khách mời.');
      }

      return asyncAction(title, () => {
        return interviewGuestApi.create(formData)
          .then(handleDone);
      });
    }
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'InterviewGuestFormDialogButton' })(InterviewGuestFormDialogButton);
