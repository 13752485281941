import { emptyFunction } from 'core/common/empty';
import lodash from 'core/common/lodash';
import { CAPTCHA_KEY } from 'core/config';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Recaptcha from 'react-recaptcha';

export default forwardRef(function CaptchaInput({ onChange = emptyFunction }, ref) {
  const containerRef = useRef();
  const [style, setStyle] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const actualWidth = containerRef.current.offsetWidth;
      const renderElement = containerRef.current.querySelector('#g-recaptcha div');
      const renderWidth = parseInt(renderElement ? renderElement.style.width : 0);

      let scale = (renderWidth && actualWidth) ? actualWidth / renderWidth : 1;

      // avoid scaling up since it might break the layout
      if (scale > 1) {
        scale = 1;
      }

      setStyle({
        transform: `scale(${scale})`,
        WebkitTransform: `scale(${scale})`,
        transformOrigin: '0 0',
        WebkitTransformOrigin: '0 0',
      });
    };

    const handleResizeDebounced = lodash.debounce(handleResize, 100);

    handleResize();
    window.addEventListener('resize', handleResizeDebounced);

    return () => window.removeEventListener('resize', handleResizeDebounced);
  }, []);

  return (
    <div ref={containerRef} style={style}>
      <Recaptcha
        ref={ref}
        render="explicit"
        sitekey={CAPTCHA_KEY}
        onloadCallback={emptyFunction}
        verifyCallback={handleVerify}
        expiredCallback={handleExpired}
        hl="vi"
      />
    </div>
  );

  function handleVerify(data) {
    onChange(data);
  }

  function handleExpired() {
    onChange();
  }
});
