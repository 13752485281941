import React from 'react';
import ConfirmButton from './ConfirmButton';
import { Button } from 'antd';
import { emptyFunction } from 'core/common/empty';

const defaultAnchorElement = ({ onClick, disabled, loading }) => (
  <Button title="Xóa" icon="delete" onClick={onClick} loading={loading} disabled={disabled} />
);

export default function DeleteButton({
  anchorElement = defaultAnchorElement,
  title = 'Xóa?',
  onDelete = emptyFunction,
  disabled = false,
}) {
  return (
    <ConfirmButton
      anchorElement={anchorElement}
      dialogActionText="Xóa"
      dialogTitleText={title}
      dialogBodyText="Dữ liệu đã xóa sẽ không phục hồi lại."
      handleSubmit={onDelete}
      danger
      disabled={disabled}
    />
  );
}
