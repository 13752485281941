import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import accountApi from 'core/engine/account/api';
import otpApi from 'core/engine/otp/api';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React from 'react';
import AccountFormDialogButton from './AccountFormDialogButton';
import AccountList from './AccountList';
import GroupSelector from './group/GroupSelector';
import ZoneSelector from 'core/components/zone/ZoneSelector';

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return accountApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

export const AccountSearchOptions = [
  { value: 'Username', label: 'Tài khoản' },
  { value: 'Email', label: 'Email' },
  { value: 'AccountID', label: 'ID' },
];

export default function AccountsListView() {
  const listProps = useList({
    listId: 'AccountsList',
    listFn,
    autoLoad: true,
    defaultFilters: {
      search: { type: AccountSearchOptions[0].value },
    },
    defaultSorting: {
      AccountID: 'ASC',
    },
  });

  const handleSearchFilter = useCreateOnFilterChange(listProps, 'search');
  const handleGroupFilter = useCreateOnFilterChange(listProps, 'GroupID');
  const handleZoneFilter = useCreateOnFilterChange(listProps, 'ZoneID');

  return (
    <Container pageTitle="Quản lý người dùng">
      <TableToolbarWrapper>
        <AccountFormDialogButton isEditMode={false} onDone={listProps.refresh} />
        <GroupSelector
          width={200}
          emptyLabel="-- Nhóm --"
          value={listProps.filters.GroupID}
          onChange={handleGroupFilter}
          allowClear
        />
        {listProps.filters.GroupID &&
          <ZoneSelector
            width={200}
            emptyLabel="-- Chuyên mục --"
            value={listProps.filters.ZoneID}
            onChange={handleZoneFilter}
            allowClear
          />
        }
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={AccountSearchOptions}
          search={listProps.filters.search}
          onSearch={handleSearchFilter}
          autoFocus
        />
      </TableToolbarWrapper>
      <AccountList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdate={listProps.refresh}
        onSendMail={handleSendMail}
        onViewQRCode={handleViewQR}
      />
    </Container>
  );
}

export function handleSendMail(account) {
  return asyncAction('Gửi QR code', () => {
    return otpApi.sendMail(account.Username, account.Email);
  });
}

export function handleViewQR(account) {
  return asyncAction('Xem QR code', () => {
    otpApi.getQrCodeLink(account.Username).then(resp => {
      setTimeout(() => {
        window.open(resp.data.item, '_blank', 'noopener,noreferrer');
      });
    });
  });
}
