import { DatePicker, Form } from 'antd';
import useAuth from 'core/hooks/useAuth';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import moment from 'moment';
import React, { useMemo } from 'react';

const LOCKED_START_DATE = moment('2022-07-06');
const LOCKED_END_DATE = moment('2022-10-06');
const EXTENDED_RIGHT_EDIT_DISTRIBUTION_DATE = 'content.edit-distribution-date';

export default function ContentFormItemDistributionDate({ content, form }) {
  const { loggedInUser, hasExtendedRight } = useAuth();
  const { getFieldDecorator } = form;

  const canEditDistributionDate = useMemo(() => {
    return loggedInUser.isAdmin || hasExtendedRight(EXTENDED_RIGHT_EDIT_DISTRIBUTION_DATE);
  }, [loggedInUser, hasExtendedRight]);

  return (
    <Form.Item label={ContentFieldLabel.DistributionDate}>
      {getFieldDecorator('DistributionDate', {
        initialValue: content.DistributionDate ? moment(parseInt(content.DistributionDate)) : null,
      })(
        <DatePicker
          showTime={{ format: 'HH:mm' }}
          format="DD/MM/YYYY HH:mm"
          disabled={!canEditDistributionDate}
          disabledDate={current => current >= LOCKED_START_DATE && current <= LOCKED_END_DATE}
        />
      )}
    </Form.Item>
  );
}
