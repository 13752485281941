import React, { useEffect } from 'react';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import AccountSelector from 'core/components/account/AccountSelector';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import contentApi from 'core/engine/content/api';
import { ContentListType, ContentSearchOptions } from 'core/engine/content/constants';
import { GroupRoleUp } from 'core/engine/group/constants';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import useContentFilterHandlers from '../../hooks/useContentFilterHandlers';
import ContentSubmittedReturnList from './ContentSubmittedReturnList';
import moment from 'moment';
import RangeDateWithTypeSelector from 'core/components/common/RangeDateWithTypeSelector';
import { CONTENT_LIST_FILTER_PARENT_AUTHOR, CONTENT_LIST_WITH_NOTE } from 'core/config';
import AuthorIdSelector from 'core/components/author/AuthorIdSelector';

const defaultRangeDate = [moment().add(-3, 'months'), moment().add(1, 'day')];

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;
  let listSorting = { ...sorting };

  if (rangeDate.range[0] && rangeDate.range[1]) {
    listFilters[rangeDate.type] = dateRangeToFilter(rangeDate.range);
    listSorting = { [rangeDate.type]: rangeDate.desc ? 'DESC' : 'ASC', ...listSorting };
  }

  delete listFilters.rangeDate;

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return contentApi.getList({
    limit,
    offset,
    fields: ['WriterID', 'CreatedDate', 'EditorID', 'ModifiedDate'],
    lookup: CONTENT_LIST_WITH_NOTE ? ['ContentNote.ContentID', 'ContentNote.CreatorID', 'ContentNote.CreatedDate', 'ContentNote.Note'] : null,
    sortBy: sortingToSortBy(listSorting),
    filters: listFilters,
  });
}

export default function ContentSubmittedReturnListView() {
  const listProps = useList({
    listFn,
    listId: 'ContentSubmittedReturnList',
    defaultFilters: {
      search: { type: ContentSearchOptions[0].value },
      rangeDate: { type: 'ModifiedDate', range: defaultRangeDate, desc: true },
      listType: ContentListType.SUBMITTED_RETURN,
    },
  });
  const filterHandlers = useContentFilterHandlers(listProps);

  useEffect(listProps.refresh, []);

  return (
    <Container pageTitle="Tin từ chối duyệt">
      <TableToolbarWrapper>
        <ZoneSelector
          allowClear
          roles={GroupRoleUp.WRITER}
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
        />
        <AccountSelector
          width={200}
          emptyLabel="-- Người tạo --"
          value={listProps.filters.WriterID}
          onChange={filterHandlers.onWriterFilter}
          valueIsNumber
          allowClear
        />
        {CONTENT_LIST_FILTER_PARENT_AUTHOR &&
          <AuthorIdSelector
            width={200}
            emptyValue={null}
            value={listProps.filters.AuthorID}
            onChange={value => listProps.addFilter('AuthorID', value)}
            parentOnly
          />
        }
        <RangeDateWithTypeSelector
          value={listProps.filters.rangeDate}
          onChange={filterHandlers.onRangeDateFilter}
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={ContentSearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
      </TableToolbarWrapper>
      <ContentSubmittedReturnList
        {...useListProps(listProps)}
        heightAdjust={42}
        onWriterSelected={onWriterSelected}
      />
    </Container>
  );

  function onWriterSelected(writerId) {
    return listProps.addFilter('WriterID', writerId);
  }
}
