import { useCallback, useRef } from 'react';
import useFormHandler from 'core/hooks/useFormHandler';

export default function useLoginForm({ form, submitFn }) {
  const formRef = useRef();

  const submitFnWithCaptcha = useCallback((...args) => {
    return submitFn(...args).then(() => formRef.current && formRef.current.resetCaptcha());
  }, [submitFn]);

  const formHandler = useFormHandler({ form, submitFn: submitFnWithCaptcha });

  return {
    ...formHandler,
    formRef,
  };
}
