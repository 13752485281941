import { Input } from 'antd';
import TopicTransfer from 'core/components/topic/TopicTransfer';
import SettingForm from 'core/modules/managements/settings/components/SettingForm';
import React from 'react';

const settings = [
  { separator: 'Desktop' },
  {
    key: 'StringConfig_BDSWebDesktopScript',
    label: 'Main Script',
    input: <Input />,
  },
  {
    key: 'StringConfig_BDSWebDesktopStyle',
    label: 'Main CSS',
    input: <Input />,
  },
  {
    key: 'StringConfig_BDSWebDesktopDetailScript',
    label: 'Detail Script',
    input: <Input />,
  },
  { separator: 'Mobile' },
  {
    key: 'StringConfig_BDSWebMobileScript',
    label: 'Main Script',
    input: <Input />,
  },
  {
    key: 'StringConfig_BDSWebMobileStyle',
    label: 'Main CSS',
    input: <Input />,
  },
  {
    key: 'StringConfig_BDSWebMobileDetailScript',
    label: 'Detail Script',
    input: <Input />,
  },
  { separator: 'Cache' },
  {
    key: 'StringConfig_BDSHomeTopicIDs',
    label: 'Home Topics',
    input: <TopicTransfer />,
  },
];

export default function BDSSettingWebForm({ form, onSubmit }) {
  return (
    <SettingForm form={form} onSubmit={onSubmit} settings={settings} />
  );
}
