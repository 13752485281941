export function getImageDimensions(imageUrl) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => resolve({
      width: image.width,
      height: image.height,
    });

    image.onerror = () => reject(new Error('Image load failed'));

    image.src = imageUrl;
  });
}

export function getImageDimensionsFromFile(file) {
  return new Promise((resolve, reject) => {
    const src = URL.createObjectURL(file);

    getImageDimensions(src)
      .then((data) => {
        URL.revokeObjectURL(src);
        resolve(data);
      })
      .catch((err) => {
        URL.revokeObjectURL(src);
        reject(err);
      });
  });
}

export function imageUrlToBlob(url) {
  return fetch(url).then(resp => resp.blob());
}

export function getFileNameFromUrl(url) {
  return url.split('#').shift().split('?').shift().split('/').pop();
}
