import { blue } from '@ant-design/colors';
import { Icon } from 'antd';
import React, { memo } from 'react';
import classnames from 'core/common/classnames';
import makeStyles from 'core/common/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    flexShrink: 0,
    width: 20,
    height: 20,
    backgroundColor: blue.primary,
    color: '#FFF !important', // override outer css
    borderRadius: '50%',
    lineHeight: '20px',
    fontSize: '13px !important', // override outer css
    textAlign: 'center',
    fontWeight: 700,
  },
}));

export default memo(function FileSelectedDot({ number, className }) {
  const classes = useStyles();

  if (number === true) {
    return (
      <Icon className={className} type="check-circle" theme="twoTone" twoToneColor={blue.primary} />
    );
  }

  return (
    <span className={classnames(classes.container, className)}>{number}</span>
  );
});
