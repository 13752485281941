export const InterviewQuestionStatus = {
  NEW: 0,
  ASSIGNED: 1,
  SKIPPED: 2,
  ANSWERED: 3,
};
export const InterviewQuestionStatusLabel = {
  [InterviewQuestionStatus.NEW]: 'Mới nhận',
  [InterviewQuestionStatus.ASSIGNED]: 'Đã phân phối',
  [InterviewQuestionStatus.SKIPPED]: 'Bỏ qua',
  [InterviewQuestionStatus.ANSWERED]: 'Đã trả lời',
};
