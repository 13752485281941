import { asyncAction } from 'core/common/async';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import AccountSelector from 'core/components/account/AccountSelector';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateWithTypeSelector from 'core/components/common/RangeDateWithTypeSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import { CONTENT_LIST_FILTER_PARENT_AUTHOR, CONTENT_LIST_WITH_NOTE } from 'core/config';
import contentApi from 'core/engine/content/api';
import { ContentListType, ContentSearchOptions } from 'core/engine/content/constants';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import moment from 'moment';
import React, { useState } from 'react';
import useContentFilterHandlers from '../../hooks/useContentFilterHandlers';
import ContentSelectionToolbar from '../ContentSelectionToolbar';
import ContentApprovedList from './ContentApprovedList';
import AuthorIdSelector from 'core/components/author/AuthorIdSelector';

const defaultRangeDate = [moment().add(-3, 'months'), moment().add(1, 'day')];

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;
  let listSorting = { ...sorting };

  if (rangeDate.range[0] && rangeDate.range[1]) {
    listFilters[rangeDate.type] = dateRangeToFilter(rangeDate.range);
    listSorting = { [rangeDate.type]: rangeDate.desc ? 'DESC' : 'ASC', ...listSorting };
  }

  delete listFilters.rangeDate;

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return contentApi.getList({
    limit,
    offset,
    fields: ['EditorID', 'ModifiedDate', 'WriterID', 'CreatedDate', 'ApprovalDate', 'ViewCount'],
    lookup: CONTENT_LIST_WITH_NOTE ? ['ContentNote.ContentID', 'ContentNote.CreatorID', 'ContentNote.CreatedDate', 'ContentNote.Note'] : null,
    sortBy: sortingToSortBy(listSorting),
    filters: listFilters,
  });
}

export default function ContentApprovedListView() {
  const listProps = useList({
    listFn,
    autoLoad: true,
    listId: 'ContentApprovedList',
    defaultFilters: {
      search: { type: ContentSearchOptions[0].value },
      rangeDate: { type: 'ApprovalDate', range: defaultRangeDate, desc: true },
      listType: ContentListType.APPROVED,
    },
  });
  const filterHandlers = useContentFilterHandlers(listProps);

  const [selectedIds, setSelectedIds] = useState([]);

  let toolbar;

  if (selectedIds.length) {
    toolbar = (
      <ContentSelectionToolbar
        selectedIds={selectedIds}
        onClear={handleClearSelectedContents}
        onCheckout={handleCheckoutSelectedContents}
      />
    );
  } else {
    toolbar = (
      <TableToolbarWrapper>
        <ZoneSelector
          allowClear
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
        />
        <AccountSelector
          width={200}
          emptyLabel="-- Người tạo --"
          value={listProps.filters.WriterID}
          onChange={filterHandlers.onWriterFilter}
          valueIsNumber
          allowClear
        />
        {CONTENT_LIST_FILTER_PARENT_AUTHOR &&
          <AuthorIdSelector
            width={200}
            emptyValue={null}
            value={listProps.filters.AuthorID}
            onChange={value => listProps.addFilter('AuthorID', value)}
            parentOnly
          />
        }
        <RangeDateWithTypeSelector
          value={listProps.filters.rangeDate}
          options={{ CreatedDate: 'Ngày tạo', ModifiedDate: 'Ngày sửa', ApprovalDate: 'Ngày duyệt' }}
          onChange={filterHandlers.onRangeDateFilter}
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={ContentSearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
      </TableToolbarWrapper>
    );
  }

  return (
    <Container pageTitle="Tin bạn đã duyệt">
      {toolbar}
      <ContentApprovedList
        {...useListProps(listProps)}
        heightAdjust={42}
        selectedIds={selectedIds}
        onSelectedChange={setSelectedIds}
        onWriterSelected={onWriterSelected}
      />
    </Container>
  );

  function handleClearSelectedContents() {
    setSelectedIds([]);
  }

  function handleCheckoutSelectedContents() {
    return asyncAction('Đóng các tin đã chọn', () => {
      return contentApi.multiCheckout(selectedIds)
        .then(() => {
          setSelectedIds([]);
          listProps.refresh();
        });
    });
  }

  function onWriterSelected(writerId) {
    return listProps.addFilter('WriterID', writerId);
  }

}
