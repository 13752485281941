import { getMapRegionCache } from 'core/engine/region/caching';
import React, { useEffect, useState } from 'react';

export default function RegionDisplay({ id, ...props }) {
  const [name, setName] = useState('Loading...');

  useEffect(() => {
    getMapRegionCache().then(map => setName(map[id] ? map[id].Name : '#'));
  }, [id]);

  return (
    <span {...props}>{name}</span>
  );
}
