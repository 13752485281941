import React, { Fragment } from 'react';
import { emptyArray } from 'core/common/empty';
import BaseTable from 'core/components/table/BaseTable';
import CardWidget from 'core/components/common/CardWidget';
import { ContentLink } from 'core/components/content/ContentLink';
import { Icon } from 'antd';
import Separator from 'core/components/common/Separator';

export default function DashBoardWidgetLatestComments({ comments = emptyArray, loading }) {

  const columns = [
    {
      Header: 'Bình luận',
      Cell: ({ original }) => (
        <span>
          {original.Comment}
          {original.Object && original.Object.ContentID && (
            <Fragment>
              <Separator />
              <ContentLink id={original.Object.ContentID} postfix={'#comment' + original.Object.ContentID}>
                <Icon style={{ transform: 'rotate(90deg)' }} type="select" title={original.Object.Title} />
              </ContentLink>
            </Fragment>
          )}
        </span>
      ),
    },
  ];

  return (
    <CardWidget title="Bình luận mới nhất">
      <BaseTable
        data={comments}
        loading={loading}
        columns={columns}
        showPagination={false}
        pageSize={comments.length}
      />
    </CardWidget>
  );
}
