import serverConfigApi from 'core/engine/server-config/api';
import moment from 'moment';

export const SET_SERVER_CONFIG = 'SET_SERVER_CONFIG';

export function setServerConfig(serverConfig) {
  // get server time to use in client
  const offset = new Date(serverConfig.current_time).getTime() - Date.now();
  moment.now = () => offset + Date.now();

  return {
    type: SET_SERVER_CONFIG,
    serverConfig,
  };
}

export function loadServerConfig() {
  return (dispatch) => {
    serverConfigApi.getCommon()
      .then(resp => dispatch(setServerConfig(resp.data || {})));
  };
}
