import { createSelector } from 'reselect';
import _ from 'lodash';
import { pageTitleSelector } from './ui';

export const itemsSelector = state => state.menu.items;
export const selectedItemIdSelector = state => state.menu.selectedItemId;
export const filterSelector = state => state.menu.filter;
export const menuItemsLoadedSelector = state => state.menu.loaded;

export const menuItemMapSelector = createSelector(
  itemsSelector,
  items => _.keyBy(items, 'Command')
);

export const visibleItemsSelector = createSelector(
  itemsSelector,
  items => items.filter(item => item.Visible)
);

export const visibleFilteredItemsSelector = createSelector(
  visibleItemsSelector,
  filterSelector,
  (items, filter) => {
    if (!filter) {
      return items;
    }

    filter = filter.toUpperCase();

    const foundItems = items.filter((item) => {
      return item.CommandID === filter ||
        (item.Command && item.Command.toUpperCase().indexOf(filter) > -1) ||
        item.Name.toUpperCase().indexOf(filter) > -1 ||
        item.AltName.toUpperCase().indexOf(filter) > -1;
    });

    foundItems.forEach((item) => {
      if (item.ParentID) {
        foundItems.push(...findAllParents(items, item));
      } else {
        foundItems.push(..._.filter(items, { ParentID: item.CommandID }));
      }
    });

    return _.uniqBy(foundItems, 'CommandID');
  }
);

export const breadcrumpsSelector = createSelector(
  itemsSelector,
  selectedItemIdSelector,
  pageTitleSelector,
  (items, selectedItemId, pageTitle) => {
    const breadcrumps = [];

    const selectedItem = _.find(items, { CommandID: parseInt(selectedItemId) });

    if (selectedItem) {
      breadcrumps.push(
        ...findAllParents(items, selectedItem).map(item => item.Name),
        selectedItem.Name,
      );
    } else {
      breadcrumps.push(pageTitle);
    }

    return breadcrumps;
  }
);

function findAllParents(items, currentItem) {
  const parents = [];
  let currentParentId = currentItem.ParentID;

  while (true) {
    const parentCommand = _.find(items, { CommandID: currentParentId });

    if (parentCommand) {
      parents.unshift(parentCommand);
      currentParentId = parentCommand.ParentID;
    } else {
      break;
    }
  }

  return parents;
}
