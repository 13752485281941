import { emptyArray } from 'core/common/empty';
import Selector from 'core/components/common/Selector';
import React, { forwardRef, useEffect, useState } from 'react';
import { getTreeListRegionCache } from 'core/engine/region/caching';

export default forwardRef(function RegionSelector(
  { value, onChange, extendItems = emptyArray, ...props },
  ref
) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getTreeListRegionCache().then((items) => {
      setOptions([
        ...extendItems,
        ...items.map(item => ({ value: item.RegionID, label: ' ---- '.repeat(item.Level) + item.Name })),
      ]);
    });
  }, []);

  return (
    <Selector
      ref={ref}
      value={value}
      options={options}
      onChange={onChange}
      valueIsNumber
      {...props}
    />
  );
});
