import React from 'react';
import Container from 'core/components/common/Container';
import { TrafficKeys } from 'core/engine/report/constants';
import ChartHourly from 'core/components/chart/ChartHourly';
import ChartDailyWithFilter from 'core/components/chart/ChartDailyWithFilter';
import { Sites } from 'core/engine/site/constants';

function sumFn(item) {
  return (item.web_all || 0) + (item.mobile_all || 0);
}

export default function TrafficContentView({ siteId = Sites.ALL }) {
  return (
    <Container pageTitle="Thống kê tổng quan">
      <ChartHourly
        keys={TrafficKeys.Content}
        siteId={siteId}
        sum={sumFn}
      />
      <br />
      <ChartDailyWithFilter
        keys={TrafficKeys.Content}
        siteId={siteId}
        sum={sumFn}
      />
    </Container>
  );
}
