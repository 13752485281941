import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import contentApi from 'core/engine/content/api';
import AsyncButton from '../common/AsyncButton';

export default function ContentGetBackButtonForList({ contentId, disabled }) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    return contentApi.getBack(contentId)
      .then(() => {
        history.push({
          pathname: '/contents/compose',
          search: `?contentId=${contentId}`,
        });
      })
      .catch((err) => {
        message.error(err.error_message, 5);
      });
  }, [contentId]);

  return (
    <AsyncButton title="Lấy lại" icon="rollback" onClick={handleClick} disabled={disabled} />
  );
}
