import { Button, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { asyncAction } from 'core/common/async';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import Container from 'core/components/common/Container';
import Selector from 'core/components/common/Selector';
import { ObjectType } from 'core/engine/common/constants';
import useFormHandler from 'core/hooks/useFormHandler';
import React, { useMemo } from 'react';
import removeCacheApi from 'core/engine/manual-cache/api';

const useStyles = makeStyles({
  short: {
    width: '200px',
  },
});

function RemoveCacheForm({ form }) {
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });
  const classes = useStyles();
  const { getFieldDecorator } = form;

  const allObjectTypes = useMemo(() => Object.entries(ObjectType).map(([field, value]) => ({ key: value, value: value, label: field })));

  return (
    <Container pageTitle="Xóa cache tay">
      <Form {...commonStyles.formItemLayout} onSubmit={handleSubmit}>
        <Form.Item label="Url">
          {getFieldDecorator('Url', {
            initialValue: '',
          })(<TextArea autoFocus rows={5} />)}
        </Form.Item>
        <Form.Item label="Object Type">
          {getFieldDecorator('ObjectType', {
            initialValue: ObjectType.CONTENT,
          })(
            <Selector
              width={200}
              valueIsNumber={true}
              options={allObjectTypes}
            />)}
        </Form.Item>
        <Form.Item label="Object ID">
          {getFieldDecorator('ObjectId')(<Input className={classes.short} valueIsNumber />)}
        </Form.Item>
        <Form.Item {...commonStyles.tailFormItemLayout}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            onClick={handleSubmit}
            title="Xóa"
          >Xóa</Button>
        </Form.Item>
      </Form>
    </Container>
  );

  function submitFn({ formData }) {
    return asyncAction('Xóa cache', () => {
      return removeCacheApi.clear(formData);
    });
  }

}

export default Form.create()(RemoveCacheForm);
