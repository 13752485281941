import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

export default function ContentLogButtonForList({ contentId }) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push({
      pathname: '/contents/loglist',
      search: `?contentId=${contentId}`,
    });
  }, [contentId]);

  return (
    <Button title="Truy vết" icon="history" onClick={handleClick} />
  );
}
