import React, { useEffect, Fragment, useState, useMemo } from 'react';
import contentLogApi from 'core/engine/content-log/api';
import { useLocation, Link } from 'react-router-dom';
import { parseQuery } from 'core/common/urlHelper';
import { Descriptions, Button } from 'antd';
import ContentAvatar from 'core/components/content/ContentAvatar';
import ContentBodyDisplay from 'core/components/content/ContentBodyDisplay';
import AccountDisplay from 'core/components/account/AccountDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import ZoneDisplay from 'core/components/zone/ZoneDisplay';
import makeStyles from 'core/common/makeStyles';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import GoBackButton from 'core/components/common/GoBackButton';
import { parseJson } from 'core/common/jsonUtils';

const { Item } = Descriptions;

const useStyles = makeStyles(() => ({
  body: {
    height: 400,
    overflow: 'auto',
    border: '1px solid #d4d4d4',
    borderRadius: 4,
    padding: 20,

    '& > img': {
      maxWidth: '100%',
    },
  },
}));

export default function ContentLogFormView() {
  const classes = useStyles();
  const location = useLocation();
  const logId = parseQuery(location.search).logId;
  const [content, setContent] = useState(null);

  const seoEntry = useMemo(() => {
    return content && content.SeoEntry ? parseJson(content.SeoEntry, null) : null;
  }, [content]);

  useEffect(() => {
    Promise.all([
      contentLogApi.get(logId).then(resp => resp.data.item),
    ]).then(([item]) => {
      setContent(item);
    });
  }, [logId]);

  if (!content) {
    return null;
  }

  return (
    <Fragment>
      <TableToolbarWrapper>
        <GoBackButton />
        <Link to={`/contents/versioncompare?ContentID=${content.ContentID}`} target="_blank">
          <Button icon="branches" title="So sánh phiên bản">So sánh phiên bản</Button>
        </Link>
      </TableToolbarWrapper>
      <Descriptions bordered={true}>
        <Item label="Tiêu đề" span={3}>{content.Title}</Item>
        <Item label="Tiêu đề phụ" span={3}>{content.SubTitle}</Item>
        <Item label="Ảnh đại diện" span={3}><ContentAvatar content={content} size={96} /></Item>
        <Item label="Mô tả" span={3}>
          <div dangerouslySetInnerHTML={{ __html: content.Description }} />
        </Item>
        <Item label="Nội dung" span={3}>
          <ContentBodyDisplay className={classes.body} content={content.HtmlBody} />
        </Item>
        <Item label="Từ khóa" span={3}>{content.Keywords}</Item>
        <Item label="Chuyên mục"><ZoneDisplay id={content.ZoneID} /></Item>
        <Item label="Nguồn tin - tác giả" span={2}>{content.Author}</Item>
        <Item label="Ngày tạo"><DateTimeDisplay timestamp={content.CreatedDate} /></Item>
        <Item label="Ngày cập nhật"><DateTimeDisplay timestamp={content.ModifiedDate} /></Item>
        <Item label="Người viết"><AccountDisplay id={content.WriterID} /></Item>
        <Item label="Người sửa"><AccountDisplay id={content.EditorID} /></Item>
        <Item label="Người duyệt"><AccountDisplay id={content.ApproverID} /></Item>
        <Item label="Người đăng"><AccountDisplay id={content.DeployerID} /></Item>
        {seoEntry && (
          <Fragment>
            <Item label="Seo Title">{seoEntry.Title}</Item>
            <Item label="SEO Description">{seoEntry.Description}</Item>
            <Item label="SEO Keywords">{seoEntry.Keywords}</Item>
          </Fragment>
        )}
      </Descriptions>
    </Fragment>
  );
}
