import { parseByte } from '@bm/common';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const BROWSER_ROUTER = process.env.REACT_APP_BROWSER_ROUTER === 'true';
export const COMMON_STATIC_URL = process.env.REACT_APP_COMMON_STATIC_URL;
export const BASE_PHOTO_URL = process.env.REACT_APP_BASE_PHOTO_URL;
export const BASE_FILE_URL = process.env.REACT_APP_BASE_FILE_URL;
export const BASE_DIR = process.env.REACT_APP_BASE_DIR;
export const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL;
export const PHOTO_MAX_UPLOAD_SIZE = parseByte(process.env.REACT_APP_PHOTO_MAX_UPLOAD_SIZE);
export const FILE_MAX_UPLOAD_SIZE = parseByte(process.env.REACT_APP_FILE_MAX_UPLOAD_SIZE);
export const WATERMARK_PHOTO = process.env.PUBLIC_URL + (process.env.REACT_APP_WATERMARK_PHOTO || '/images/watermark.png');
export const WATERMARK_PHOTOS = (process.env.REACT_APP_WATERMARK_PHOTOS || '').split(',').filter(Boolean).map(p => process.env.PUBLIC_URL + p);
export const WATERMARK_ON_PICTURE_SCALE = parseFloat(process.env.REACT_APP_WATERMARK_ON_PICTURE_SCALE || 0.1);
export const WATERMARK_DEFAULT_POSITION = process.env.REACT_APP_WATERMARK_DEFAULT_POSITION || 'c';
export const BLUR_PHOTO = process.env.REACT_APP_BLUR_PHOTO || (COMMON_STATIC_URL + '/content-body/styles/img/_common/blur.png');
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const SERVICE_WEB_DOMAIN = process.env.REACT_APP_SERVICE_WEB_DOMAIN;
export const DEFAULT_CONTENT_ZONES_SIZE = parseInt(process.env.REACT_APP_DEFAULT_CONTENT_ZONES_SIZE, 10);
export const DEFAULT_CONTENT_MAX_LEVEL = parseInt(process.env.REACT_APP_DEFAULT_CONTENT_MAX_LEVEL, 10);
export const DEFAULT_CONTENT_LEVELS = (process.env.REACT_APP_DEFAULT_CONTENT_LEVEL || '').split(',').map(Number);
export const DEFAULT_CONTENT_MAX_LENGTH_TITLE = parseInt(process.env.REACT_APP_DEFAULT_CONTENT_MAX_LENGTH_TITLE, 10);
export const DEFAULT_CONTENT_MAX_LENGTH_DESCRIPTION = parseInt(process.env.REACT_APP_DEFAULT_CONTENT_MAX_LENGTH_DESCRIPTION, 10);
export const CHAPEAU_RICH_EDITOR = process.env.REACT_APP_CHAPEAU_RICH_EDITOR === 'true';
export const ALLOW_INPUT_OVERFLOW = process.env.REACT_APP_ALLOW_INPUT_OVERFLOW === 'true';
export const CONTENT_LIST_WITH_NOTE = String(process.env.REACT_APP_CONTENT_LIST_WITH_NOTE) === 'true';
export const CONTENT_LIST_CTR_STATS = String(process.env.REACT_APP_CONTENT_LIST_CTR_STATS) === 'true';
export const CONTENT_RATE_TYPE = parseInt(process.env.REACT_APP_CONTENT_RATE_TYPE, 0);
export const FILE_DOWNLOAD_FROM_URL = String(process.env.REACT_APP_FILE_DOWNLOAD_FROM_URL) !== 'false';
export const CONTENT_FORM_MAIN_INPUT_LIST = String(process.env.REACT_APP_CONTENT_FORM_MAIN_INPUT_LIST).split(',').map(i => i.trim()).filter(Boolean);
export const CONTENT_FORM_SIDEBAR_INPUT_LIST = String(process.env.REACT_APP_CONTENT_FORM_SIDEBAR_INPUT_LIST).split(',').map(i => i.trim()).filter(Boolean);
export const CONTENT_URL_TEMPLATE = process.env.REACT_APP_CONTENT_URL_TEMPLATE;
export const CONTENT_ALWAYS_SHOW_AVATAR = String(process.env.REACT_APP_CONTENT_ALWAYS_SHOW_AVATAR) === 'true';
export const CONTENT_FORM_ACTIONS_ALERT = String(process.env.REACT_APP_CONTENT_FORM_ACTIONS_ALERT || '').split(',');
export const SHORT_CONTENT_URL_TEMPLATE = process.env.REACT_APP_SHORT_CONTENT_URL_TEMPLATE;
export const CONTENT_LIST_FILTER_PARENT_AUTHOR = String(process.env.REACT_APP_CONTENT_LIST_FILTER_PARENT_AUTHOR) === 'true';
export const TOPIC_URL_TEMPLATE = process.env.REACT_APP_TOPIC_URL_TEMPLATE;
export const TAG_URL_TEMPLATE = process.env.REACT_APP_TAG_URL_TEMPLATE;
export const COMMENT_MAX_ROWS_DISPLAY = parseInt(process.env.REACT_APP_COMMENT_MAX_ROWS_DISPLAY, 0);
export const COMMENT_QUICK_APPROVE = process.env.REACT_APP_COMMENT_QUICK_APPROVE === 'true';
export const COMMENT_READER_GROUP = parseInt(process.env.REACT_APP_COMMENT_READER_GROUP, 0);
export const COMMENT_DEPLOYER_GROUP = parseInt(process.env.REACT_APP_COMMENT_DEPLOYER_GROUP, 0);
export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY;
export const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL || 'wss://lp.mykaraoke.me/ws';
export const CMS_TITLE = process.env.REACT_APP_CMS_TITLE || 'CMS Admin';
export const LOGO_TITLE = process.env.REACT_APP_LOGO_TITLE || 'CMS Admin';
export const CONTENT_INPUT_SHOW_WORD_COUNT = process.env.REACT_APP_CONTENT_INPUT_SHOW_WORD_COUNT === 'true';
export const INTERVIEW_MAX_GUESTS = process.env.REACT_APP_INTERVIEW_MAX_GUESTS;
export const TOPIC_SYSTEM_ORDERED_ITEMS = parseInt(process.env.REACT_APP_TOPIC_SYSTEM_ORDERED_ITEMS, 10) || 50;
export const TOPIC_NORMAL_DEFAULT_ORDER = parseInt(process.env.REACT_APP_TOPIC_NORMAL_DEFAULT_ORDER, 10) || 15;
export const REQUIRE_SPELL_CHECKER = process.env.REACT_APP_REQUIRE_SPELL_CHECKER === 'true';
export const VIEW_ORIGIN_IMAGE = process.env.REACT_APP_VIEW_ORIGIN_IMAGE === 'true';
export const DISABLE_HOT_LIVESTREAM_OPTION = process.env.REACT_APP_DISABLE_HOT_LIVESTREAM_OPTION === 'true';
export const VIEW_CONTENT_MY_LIST_DISCONTINUED = process.env.REACT_APP_VIEW_CONTENT_MY_LIST_DISCONTINUED === 'true';
export const LIBRE_CAPTCHA_URL = process.env.REACT_APP_LIBRE_CAPTCHA_URL || 'https://captcha-cms.epi.vn';
export const IMAGE_PROCESSING_DOMAIN = process.env.REACT_APP_IMAGE_PROCESSING_DOMAIN || 'https://image-cms.epi.vn';
export const IMAGE_PROCESSING_ORIGIN_URL = IMAGE_PROCESSING_DOMAIN + '/images/origin';
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const HIGH_BITRATE_WARNING_THRESHOLD = process.env.REACT_APP_HIGH_BITRATE_WARNING_THRESHOLD || 15000; // kbps
