import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducersRegistry from 'core/registries/reducers';
import reducers from '../reducers';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    collapsed: true,
    diff: true,
  });

  middlewares.push(logger);
}

let store;

export function configureStore() {
  if (store) {
    throw new Error('You only call configureStore once!');
  }

  const registeredReducers = reducersRegistry.getAll().reduce((previousValue, item) => {
    return Object.assign(previousValue, { [item.id]: item.reducer });
  }, {});

  store = createStore(
    combineReducers({ ...reducers, ...registeredReducers }),
    applyMiddleware(...middlewares),
  );

  return store;
}

export function getCurrentStore() {
  return store;
}
