import React, { useCallback, useEffect, useState } from 'react';
import useContentColumns from 'core/hooks/useContentColumns';
import SelectableTable from 'core/components/table/SelectableTable';
import lodash from 'core/common/lodash';

export default function SuggestRelatedContentsTab({ value, onChange, getSuggestedContent }) {
  const ContentColumns = useContentColumns();

  const columns = [
    ContentColumns.Avatar,
    ContentColumns.Title,
    ContentColumns.DistributionDate,
    ContentColumns.ViewCount,
  ];

  const [suggestedList, setSuggestedList] = useState([]);

  useEffect(() => {
    getSuggestList();
  }, []);

  const getSuggestList = useCallback(() => {
    getSuggestedContent().then((resp) => {
      if (resp.data.items) {
        setSuggestedList(resp.data.items);
      }
    });
  }, [getSuggestedContent]);

  return (
    <SelectableTable
      data={suggestedList}
      showPagination={false}
      idField="ContentID"
      columns={columns}
      selected={value}
      onSelect={onSelect}
      onUnselect={onUnselect}
    />
  );

  function onSelect(item) {
    if (lodash.findIndex(value, { ContentID: item.ContentID }) === -1) {
      onChange([...value, item]);
    }
  }

  function onUnselect(item) {
    const newValue = value.slice();
    lodash.remove(newValue, { ContentID: item.ContentID });
    onChange(newValue);
  }
}
