import { Button, Form } from 'antd';
import React from 'react';
import { asyncAction } from 'core/common/async';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import LiveForm from './LiveForm';
import BaseDialog from 'core/components/common/BaseDialog';
import liveApi from 'core/engine/live/api';

function LiveFormDialogButton({ form, live = {}, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <React.Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={isEditMode ? 'Sửa tường thuật' : 'Tạo tường thuật'}
        onClick={handleOpen}>
        {isEditMode ? '' : 'Tạo tường thuật'}
      </Button>
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa tường thuật' : 'Tạo tường thuật'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <LiveForm form={form} live={live} />
      </BaseDialog>
    </React.Fragment>
  );

  function submitFn({ formData }) {
    if (formData.StartDate) {
      formData.StartDate = formData.StartDate.toDate().getTime();
    }

    if (formData.EndDate) {
      formData.EndDate = formData.EndDate.toDate().getTime();
    }

    if (isEditMode) {
      return asyncAction('Sửa tường thuật', () => {
        return liveApi.update(formData)
          .then(handleDone);
      });
    }

    return asyncAction('Tạo tường thuật', () => {
      return liveApi.create(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'LiveFormDialogButton' })(LiveFormDialogButton);
