import * as contents from 'core/modules/contents';
import * as account from 'core/modules/managements/account';
import * as command from 'core/modules/managements/command';
import * as group from 'core/modules/managements/group';
import * as manualCache from 'core/modules/managements/manual-cache';
import * as zones from 'core/modules/managements/zones';
import * as activityLog from 'core/modules/operations/activity-log';
import * as adsoptions from 'core/modules/operations/adsoptions';
import * as adspositions from 'core/modules/operations/adspositions';
import * as authors from 'core/modules/operations/authors';
import * as comment from 'core/modules/operations/comment';
import * as contentSources from 'core/modules/operations/content-sources';
import * as interviews from 'core/modules/operations/interviews';
import * as live from 'core/modules/operations/lives';
import * as liveStream from 'core/modules/operations/livestreams';
import * as tags from 'core/modules/operations/tags';
import * as topics from 'core/modules/operations/topics';
import * as votes from 'core/modules/operations/votes';
import * as widgets from 'core/modules/operations/widgets';
import * as reportsContents from 'core/modules/reports/contents';
import * as traffics from 'core/modules/reports/traffics';
import * as reportsZones from 'core/modules/reports/zones';
import * as importContent from 'core/modules/tools/import-content';
import * as _contents from './contents';
import * as settings from './managements/settings';
import * as epapers from './operations/epaper';
import * as _widget from './operations/widgets';
import * as royalty from './royalty';
import * as site from './site';

const allModules = [
  contents,
  account,
  command,
  group,
  manualCache,
  zones,
  activityLog,
  adsoptions,
  adspositions,
  authors,
  comment,
  contentSources,
  interviews,
  live,
  liveStream,
  tags,
  topics,
  votes,
  widgets,
  reportsContents,
  traffics,
  reportsZones,
  importContent,
  _contents,
  settings,
  epapers,
  _widget,
  royalty,
  site,
];

export function register() {
  allModules.forEach(m => m.start());
}
