import {
  SET_PAGETITLE,
  SET_SIDEBAR_OPEN,
} from 'core/redux/actions/ui';
import { CMS_TITLE } from 'core/config';

const initialState = {
  pageTitle: CMS_TITLE,
  sidebarOpened: true,
};

export default function (state = initialState, action) {

  switch (action.type) {
    case SET_PAGETITLE:
      return Object.assign({}, state, {
        pageTitle: action.pageTitle || initialState.pageTitle,
      });

    case SET_SIDEBAR_OPEN:
      return { ...state, sidebarOpened: action.opened };

    default:
      return state;
  }
}
