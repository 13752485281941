import { httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_FIELDS = [
  'TagID',
  'Name',
];

const tagApi = {
  get(id) {
    return httpGet(`/tags/get/item?TagID=${id}`);
  },

  getList({ limit = 20, offset = 0, sortBy, fields = [], filters }) {
    const qs = {
      limit,
      offset,
      sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      ...filters,
    };

    return httpGet('/tags/get/items-by-range', { qs });
  },

  searchContent({ lookup, sortBy, fields = [], keyword }) {
    const qs = { lookup, sortBy, fields, keyword };
    return httpGet('/tags/get/search-content', { qs });
  },

  suggest(keyword, exclusive = undefined) {
    const qs = { keyword, exclusive };
    return httpGet('/suggests/get/suggest-tag', { qs });
  },

  addContent(contentId, keyword) {
    return httpPost('/tags/post/add-content', { ContentID: contentId, keyword });
  },

  submit(data) {
    return httpPost('/tags/post/submit', data);
  },

  removeContent(contentId, keyword) {
    return httpPost('/tags/post/remove-content', { ContentID: contentId, keyword });
  },

  delete(id) {
    return httpPost('/tags/post/delete', { TagID: id });
  },
};

export default tagApi;
