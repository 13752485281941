import { Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import React, { useMemo } from 'react';
import WidgetTypeSelector from './common/WidgetTypeSelector';
import { isNullOrUndefined } from 'core/common/is';
import { emptyArray } from 'core/common/empty';
import { widgetRegistry } from '../registry';

export default function WidgetForm({ form, onSubmit, widget = {}, availableWidgets = emptyArray }) {
  const { getFieldDecorator, getFieldValue } = form;

  getFieldDecorator('WidgetID', { initialValue: widget.WidgetID });
  getFieldDecorator('PreDefined', { initialValue: isNullOrUndefined(widget.PreDefined) ? true : widget.PreDefined });

  const initType = useMemo(() => {
    return widget.Type || (availableWidgets[0] && availableWidgets[0].type);
  }, []);

  const formItemMeta = useMemo(() => {
    const widgetOption = widgetRegistry.get(getFieldValue('Type') || initType);
    const FormItemMeta = widgetOption && widgetOption.FormItemMeta;

    return FormItemMeta ? (<FormItemMeta form={form} widget={widget} widgetOption={widgetOption} />) : (
      <Form.Item label="Meta">
        {getFieldDecorator('Meta', {
          initialValue: widget.Meta,
        })(
          <Input.TextArea autoSize />
        )}
      </Form.Item>
    );
  }, [initType, form, widget]);

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Tên">
        {getFieldDecorator('Title', {
          rules: [
            { required: true, message: 'Bắt buộc!' },
            { max: 250, message: 'Tối đa 250 kí tự!' },
          ],
          initialValue: widget.Title,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Loại">
        {getFieldDecorator('Type', {
          rules: [
            { required: true, message: 'Bắt buộc!' },
          ],
          initialValue: initType,
        })(
          <WidgetTypeSelector
            widgets={availableWidgets}
            disabled={availableWidgets.length === 1}
          />
        )}
      </Form.Item>
      {formItemMeta}
    </Form>
  );
}
