import { Input } from 'antd';
import React from 'react';
import SettingForm from 'core/modules/managements/settings/components/SettingForm';

const settings = [
  {
    key: 'StringConfig_DesktopExtendStyle',
    label: 'Desktop CSS',
    input: <Input.TextArea autosize={{ maxRows: 30 }} />,
  },
  {
    key: 'StringConfig_MobileExtendStyle',
    label: 'Mobile CSS',
    input: <Input.TextArea autosize={{ maxRows: 30 }} />,
  },
];

export default function SettingExtendStyleForm({ form, onSubmit }) {
  return (
    <SettingForm form={form} onSubmit={onSubmit} settings={settings} />
  );
}
