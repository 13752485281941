import { Checkbox } from 'antd';
import { emptyArray, emptyObject } from 'core/common/empty';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import { DEFAULT_CONTENT_LEVELS, DEFAULT_CONTENT_MAX_LEVEL, DEFAULT_CONTENT_ZONES_SIZE } from 'core/config';
import { GroupRoleUp } from 'core/engine/group/constants';
import { getListEnabledZoneByAccountCache, getMapZoneCache } from 'core/engine/zone/caching';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import ContentFormSingleZoneInput from './ContentFormSingleZoneInput';

const useStyles = makeStyles(() => ({
  container: {
    '& > *:not(:last-child)': {
      marginBottom: 5,
    },
  },
}));

export default forwardRef(function ContentFormMultipleZoneInput({
  value,
  onChange,
  size = DEFAULT_CONTENT_ZONES_SIZE,
  maxLevel = DEFAULT_CONTENT_MAX_LEVEL,
  alwaysShowLevel = true,
  alwaysShowZones = true,
  noAutoAddParent = false,
  filteredByRole = true,
}, ref) {
  const classes = useStyles();

  const valueLoadedRef = useRef(false);

  const inputs = useMemo(() => {
    return Array(size).fill('');
  }, [size]);

  const normalizedValue = useMemo(() => {
    if (Array.isArray(value)) {
      if (value.length < size) {
        const arr = value.slice();
        arr.push(...getFilledOrderArray(size - arr.length, arr.length));
        return arr;
      }

      return value;
    }

    return getFilledOrderArray(size);
  }, [value, size, maxLevel]);

  const primaryZoneId = normalizedValue[0].ZoneID;

  const [deployableZones, setDeployableZones] = useState([]);
  const [zoneMap, setZoneMap] = useState(emptyObject);

  const [autoAddParent, setAutoAddParent] = useState(false);

  const canDeployToPrimaryZone = useMemo(() => {
    return Boolean(lodash.find(deployableZones, { ZoneID: primaryZoneId }));
  }, [primaryZoneId, deployableZones]);

  useEffect(() => {
    getListEnabledZoneByAccountCache(GroupRoleUp.DEPLOYER)
      .then(zones => setDeployableZones(zones));

    getMapZoneCache()
      .then(zones => setZoneMap(zones));
  }, []);

  useEffect(() => {
    if (zoneMap === emptyObject) {
      return;
    }

    if (valueLoadedRef.current) {
      return;
    }

    valueLoadedRef.current = true;

    if (!primaryZoneId || !zoneMap[primaryZoneId]) {
      setAutoAddParent(true);
      return;
    }

    setAutoAddParent(isAutoAddParent(normalizedValue));
  }, [primaryZoneId, zoneMap, normalizedValue]);

  return (
    <div ref={ref} className={classes.container}>
      {inputs.map((_, index) => (
        (index === 0 || canDeployToPrimaryZone || alwaysShowZones) && (
          <ContentFormSingleZoneInput
            key={index}
            value={normalizedValue[index]}
            maxLevel={maxLevel}
            onChange={val => handleChange(index, val)}
            isPrimary={index === 0}
            showLevel={canDeployToPrimaryZone || alwaysShowLevel}
            roles={filteredByRole ? GroupRoleUp.WRITER : emptyArray}
          />
        )
      ))}
      {!noAutoAddParent && (
        <Checkbox checked={autoAddParent} onChange={handleAutoAddParentChange}>
          Tự động đăng vào mục cha
        </Checkbox>
      )}
    </div>
  );

  function handleChange(index, val) {
    const newValue = lodash.cloneDeep(normalizedValue);

    newValue[index] = val;

    if (noAutoAddParent) {
      onChange(newValue);
      return;
    }

    if (index > 0) {
      setAutoAddParent(isAutoAddParent(newValue));
      onChange(newValue);
      return;
    }

    const canDeployToPrimaryZone = Boolean(lodash.find(deployableZones, { ZoneID: val.ZoneID }));
    const parentId = getParentId(val.ZoneID);

    if (canDeployToPrimaryZone || alwaysShowZones) {
      cleanParentDistribution(newValue, getParentId(normalizedValue[0].ZoneID));
    } else {
      cleanNonPrimaryDistributions(newValue);
    }

    if (autoAddParent) {
      addParentDistribution(newValue, parentId);
    }

    onChange(newValue);
  }

  function handleAutoAddParentChange(event) {
    const { checked } = event.target;

    setAutoAddParent(checked);

    const parentId = getParentId(primaryZoneId);

    if (!parentId) {
      return;
    }

    const newValue = lodash.cloneDeep(normalizedValue);

    cleanParentDistribution(newValue, parentId);

    if (checked) {
      addParentDistribution(newValue, parentId);
    }

    onChange(newValue);
  }

  function getParentId(zoneId) {
    return zoneMap[zoneId] ? zoneMap[zoneId].ParentID : 0;
  }

  function cleanNonPrimaryDistributions(distributions) {
    if (!alwaysShowLevel) {
      distributions[0].Order = getDefaultOrder(0);
    }

    distributions.forEach((dist, index) => {
      if (index > 0) {
        dist.Order = getDefaultOrder(index);
        delete dist.ZoneID;
      }
    });
  }

  function cleanParentDistribution(distributions, parentId) {
    if (!parentId) {
      return;
    }

    distributions.forEach((dist, index) => {
      if (dist.ZoneID === parentId) {
        dist.Order = getDefaultOrder(index);
        delete dist.ZoneID;
      }
    });
  }

  function addParentDistribution(distributions, parentZoneId) {
    if (!parentZoneId) {
      return;
    }

    const parentIndex = lodash.findIndex(distributions, { ZoneID: parentZoneId });
    const toAddIndex = parentIndex > -1 ? parentIndex : lodash.findIndex(distributions, (dist => !dist.ZoneID));
    const newDist = { Order: getDefaultOrder(toAddIndex), ZoneID: parentZoneId };

    if (toAddIndex > 0) {
      distributions[toAddIndex] = newDist;
    } else {
      distributions.push(newDist);
    }
  }

  function isAutoAddParent(distributions) {
    const parentId = getParentId(distributions[0].ZoneID);

    return parentId && lodash.findIndex(distributions, { ZoneID: parentId }) > 0;
  }

  function getFilledOrderArray(size, offset = 0) {
    const distributions = [];

    for (let index = 0; index < size; index++) {
      distributions.push({ Order: getDefaultOrder(offset + index) });
    }

    return distributions;
  }

  function getDefaultOrder(index) {
    return DEFAULT_CONTENT_LEVELS[index] || maxLevel;
  }
});
