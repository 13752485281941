import { Col, Row } from 'antd';
import dashboardApi from 'core/engine/dashboards/api';
import { TrafficKeys } from 'core/engine/report/constants';
import useServerFeature from 'core/hooks/useServerFeature';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import ChartDailyWithFilter from '../chart/ChartDailyWithFilter';
import AuthzCheck from '../common/AuthzCheck';
import CardWidget from '../common/CardWidget';
import {
  CommentIcon,
  DraftIcon,
  ReturnApproveIcon,
  ReturnSubmitIcon,
  WaitApproveIcon,
  WaitPublicIcon,
} from '../common/SvgIcons';
import { CustomComponentContext } from '../layout';
import DashBoardWidgetLatestComments from './widgets/DashBoardWidgetLatestComments';
import DashBoardWidgetLatestContents from './widgets/DashBoardWidgetLatestContents';
import DashBoardWidgetNumber from './widgets/DashBoardWidgetNumber';
import DashBoardWidgetUserOnlines from './widgets/DashBoardWidgetUserOnlines';

function sumFn(item) {
  return (item.web_all || 0) + (item.mobile_all || 0);
}

export default function DashBoard() {
  const customComponentContext = useContext(CustomComponentContext);
  const hasAuthzDashboardTrafficFeature = useServerFeature('authz-dashboard-traffic');
  const hasComment = useServerFeature('has-comment', true);

  const [isLoading, setIsLoading] = useState(true);
  const [dataInfo, setDataInfo] = useState([]);
  const [dataStats, setDataStats] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      dashboardApi.getInfo().then(resp => resp.data),
      dashboardApi.getStats().then(resp => resp.data),
    ])
      .then(([dataInfo, dataStats]) => {
        setDataInfo(dataInfo);
        setDataStats(dataStats);

        setIsLoading(false);
      });
  }, []);

  if (customComponentContext && customComponentContext.ExtDashboard) {
    return (
      <customComponentContext.ExtDashboard />
    );
  }

  return (
    <Fragment>
      <Row gutter={24}>
        {Boolean(dataStats.isWriter) && (
          <Col md={12} lg={8}>
            <DashBoardWidgetNumber title="Tin đang biên tập" link="/contents/mylist" number={dataStats.myDraftCount} icon={DraftIcon} bgColor="rgb(236, 61, 120)" />
          </Col>
        )}
        {(Boolean(dataStats.isDeployer) || Boolean(dataStats.isApprover)) && (
          <Fragment>
            <Col md={12} lg={8}>
              <DashBoardWidgetNumber title="Tin từ chối đăng" link="/contents/approvedreturnlist" number={dataStats.approvedReturnedCount} icon={ReturnApproveIcon} bgColor="#444054" />
            </Col>
            <Col md={12} lg={8}>
              <DashBoardWidgetNumber title="Tin từ chối duyệt" link="/contents/submitedreturnlist" number={dataStats.submittedReturnedCount} icon={ReturnSubmitIcon} bgColor="#343a40" />
            </Col>
            <Col md={12} lg={8}>
              <DashBoardWidgetNumber title="Tin chờ duyệt" link="/contents/approvalwaitinglist" number={dataStats.submittedCount} icon={WaitApproveIcon} bgColor="#9C27B0" />
            </Col>
          </Fragment>
        )}
        {Boolean(dataStats.isDeployer) && (
          <Col md={12} lg={8}>
            <DashBoardWidgetNumber title="Tin chờ đăng" link="/contents/deploywaitinglist" number={dataStats.approvedCount} icon={WaitPublicIcon} bgColor="rgb(3, 169, 244)" />
          </Col>
        )}
        {hasComment && (Boolean(dataStats.isCommentDeployer) || Boolean(dataStats.isCommentApprover)) && (
          <Col md={12} lg={8}>
            <DashBoardWidgetNumber title="Bình luận chờ duyệt" link="/operations/comment" number={dataStats.waitingCommentCount} icon={CommentIcon} bgColor="rgb(0, 150, 136)" />
          </Col>
        )}
      </Row>
      <Row gutter={24}>
        <Col lg={8} md={24}>
          <DashBoardWidgetLatestContents contents={dataInfo.contents} loading={isLoading} />
        </Col>
        {hasComment && (
          <Col lg={8} md={24}>
            <DashBoardWidgetLatestComments comments={dataInfo.comments} loading={isLoading} />
          </Col>
        )}
        <Col lg={8} md={24}>
          <DashBoardWidgetUserOnlines users={dataInfo.onlines} loading={isLoading} />
        </Col>
      </Row>

      <AuthzCheck extendedRight={hasAuthzDashboardTrafficFeature ? 'view-dashboard-traffic' : null}>
        <Row gutter={24}>
          <Col lg={24} md={24}>
            <CardWidget title="Thống kê truy cập theo ngày" height={null}>
              <ChartDailyWithFilter
                keys={TrafficKeys.Content}
                showFilter={false}
                sum={sumFn}
              />
            </CardWidget>
          </Col>
        </Row>
      </AuthzCheck>
    </Fragment>
  );
}
