import { Config } from '@zdocs/core';
import lodash from 'core/common/lodash';
import FileManagerDialog from 'core/components/file-manager/FileManagerDialog';
import { FileType } from 'core/engine/file/constants';
import { buildCdnPhotoUrl } from 'core/common/cdn';
import BaseDialogPlugin from 'core/components/editor/plugins/BaseDialogPlugin';
import React from 'react';

export default class AddVideoPlugin extends BaseDialogPlugin {

  static install() {
    Config.prototype.controls.video = {
      tooltip: 'Chèn video',
      icon: 'video',
    };
  }

  get name() {
    return 'AddVideoPlugin';
  }

  get command() {
    return 'video';
  }

  get defaultOptions() {
    return {
      template: `
        <table class="video">
          <tbody>
            <tr>
              <td><video controls data-video-id="$VideoId" data-width="$Width" data-height="$Height" data-duration="$Duration" poster="$Poster"><source src="$Src" type="$Type"></source>Trình duyệt của bạn không hỗ trợ hiển thị video.</video></td>
            </tr>
            <tr>
              <td class="caption"><p></p></td>
            </tr>
          </tbody>
        </table>`,
    };
  }

  renderDialog(props) {
    return (
      <FileManagerDialog
        focusTriggerAfterClose={false}
        title="Chèn video"
        multiple
        fileType={FileType.VIDEO}
        {...props}
      />
    );
  }

  onSelect(files) {
    super.onSelect();

    const html = files.map(file => {
      let poster = lodash.get(file, 'Meta.avatarUrl');

      if (poster) {
        poster = buildCdnPhotoUrl(poster);
      }

      return this.options.template
        .replace('$VideoId', file.FileID)
        .replace('$Src', file.url)
        .replace('$Type', file.MimeType)
        .replace('$Width', lodash.get(file, 'Meta.width') || 0)
        .replace('$Height', lodash.get(file, 'Meta.height') || 0)
        .replace('$Duration', lodash.get(file, 'Meta.duration') || 0)
        .replace('$Poster', poster);
    }).join('');

    this.insertHTMLToEditor(html, true, false, '.caption');
  }
}
