import { useState, useEffect, useCallback } from 'react';

export default function useDrag(x, y, onDrag) {
  const [dragging, setDragging] = useState(false);
  const [initialDragState, setInitialDragState] = useState({
    initX: 0,
    initY: 0,
    mouseDownX: 0,
    mouseDownY: 0,
  });

  const onMouseDown = useCallback((event) => {
    event.preventDefault();
    setInitialDragState({
      initX: x,
      initY: y,
      mouseDownX: event.clientX,
      mouseDownY: event.clientY,
    });
    setDragging(true);
  }, [x, y]);

  useEffect(() => {
    const onMouseMove = (event) => {
      if (dragging) {
        const { initX, mouseDownX, initY, mouseDownY } = initialDragState;
        const dx = event.clientX - mouseDownX;
        const dy = event.clientY - mouseDownY;
        const x = initX + dx;
        const y = initY + dy;
        onDrag(x, y);
      }
    };

    window.addEventListener('mousemove', onMouseMove, { passive: true });

    return () => window.removeEventListener('mousemove', onMouseMove);
  }, [initialDragState, dragging, onDrag]);

  useEffect(() => {
    const onMouseUp = () => {
      setDragging(false);
    };

    window.addEventListener('mouseup', onMouseUp);

    return () => window.removeEventListener('mouseup', onMouseUp);
  }, []);

  return onMouseDown;
}
