import { InputNumber } from 'antd';
import TopicSelector from 'core/components/topic/TopicSelector';
import ZoneTransfer from 'core/components/zone/ZoneTransfer';
import { Sites, SubSiteZone } from 'core/engine/site/constants';
import { TopicType } from 'core/engine/topic/constants';
import SettingForm from 'core/modules/managements/settings/components/SettingForm';
import React from 'react';

function getSettings() {
  return [
    {
      key: 'StringConfig_BDSHomeHighlightTopicID',
      label: 'Nổi bật trang chủ',
      input: <TopicSelector emptyValue="" topicType={TopicType.SYSTEM} />,
    },
    {
      key: 'StringConfig_BDSHomeTimelineTopicID',
      label: 'Timeline',
      input: <TopicSelector emptyValue="" topicType={TopicType.SYSTEM} />,
    },
    {
      key: 'StringConfig_BDSHomeTimelineTop',
      label: 'Số bài timeline',
      input: <InputNumber />,
    },
    {
      key: 'StringConfig_BDSHomeFocusTopicID',
      label: 'Đang được quan tâm',
      input: <TopicSelector emptyValue="" />,
    },
    { separator: 'Desktop' },
    {
      key: 'StringConfig_BDSMainMenu',
      label: 'Menu chính',
      input: <ZoneTransfer rootZoneId={SubSiteZone[Sites.BDS]} />,
    },
    {
      key: 'StringConfig_BDSDesktopHomeCates',
      label: 'Nhóm chuyên mục 1',
      input: <ZoneTransfer rootZoneId={SubSiteZone[Sites.BDS]} />,
    },
    {
      key: 'StringConfig_BDSDesktopHomeCates1',
      label: 'Nhóm chuyên mục 2',
      input: <ZoneTransfer rootZoneId={SubSiteZone[Sites.BDS]} />,
    },
    { separator: 'Mobile' },
    {
      key: 'StringConfig_BDSMobileHomeCates',
      label: 'Nhóm chuyên mục 1',
      input: <ZoneTransfer rootZoneId={SubSiteZone[Sites.BDS]} />,
    },
    {
      key: 'StringConfig_BDSMobileHomeCates1',
      label: 'Nhóm chuyên mục 2',
      input: <ZoneTransfer rootZoneId={SubSiteZone[Sites.BDS]} />,
    },
  ];
}

export default function BDSSettingLayoutForm({ form, onSubmit }) {
  return (
    <SettingForm form={form} onSubmit={onSubmit} settings={getSettings()} />
  );
}
