import { FileType } from './constants';
import { buildCdnPhotoUrl } from 'core/common/cdn';
import { BASE_FILE_URL } from 'core/config';

const fileService = {
  buildDirectUrl(file) {
    if (file.Type === FileType.IMAGE) {
      return buildCdnPhotoUrl(file.Path);
    }

    if (file.Type === FileType.VIDEO || file.Type === FileType.AUDIO) {
      return file.url;
    }

    if (file.Type === FileType.FILE) {
      return BASE_FILE_URL + file.Path;
    }
  },
};

export default fileService;
