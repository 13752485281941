import { Button } from 'antd';
import React, { useEffect, useRef } from 'react';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import VideoPlayer from 'core/components/common/VideoPlayer';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexRow,
    width: '100%',
  },
  video: {
    width: '50%',
  },
  preview: {
    backgroundColor: '#333',
    width: '50%',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  selectButton: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
  openIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

export default function FileVideoAvatarPickerInVideo({ playableUrl, imageUrl, onPick }) {
  const classes = useStyles();
  const canvasRef = useRef();
  const videoRef = useRef();

  useEffect(() => {
    return () => {
      if (imageUrl) {
        window.URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  return (
    <div className={classes.container}>
      <VideoPlayer
        ref={videoRef}
        videoProps={{ autoPlay: true, muted: true, crossOrigin: 'anonymous' }}
        className={classes.video}
        src={playableUrl}
      />
      <div className={classes.preview}>
        <Button className={classes.selectButton} onClick={handleSelect}>Chọn</Button>
        {imageUrl && (
          <a title="Xem ảnh ở tab mới" href={imageUrl} target="_blank" rel="noopener noreferrer" className={classes.openIcon}>
            <Button icon="eye" />
          </a>
        )}
        {imageUrl && <img src={imageUrl} alt="preview" className={classes.image} />}
        <canvas style={commonStyles.hidden} ref={canvasRef} />
      </div>
    </div>
  );

  function handleSelect() {
    const canvas = canvasRef.current;
    const video = videoRef.current.getVideo();

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    canvas.toBlob((blob) => {
      onPick(window.URL.createObjectURL(blob));
    });
  }
}
