import { Form } from 'antd';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import OnBlurInput from 'core/components/common/OnBlurInput';
import fileApi from 'core/engine/file/api';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FileType } from 'core/engine/file/constants';

const uploadButtonLabels = {
  [FileType.FILE]: 'Tải tệp',
  [FileType.IMAGE]: 'Tải ảnh',
  [FileType.VIDEO]: 'Tải video',
};

const FileUploadFromUrlDialogButton = forwardRef(({ fileType, form, onUploaded }, ref) => {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  const [value, setValue] = useState();

  useEffect(() => {
    if (open) {
      setValue('');
    }
  }, [open]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  return (
    <BaseDialog
      visible={open}
      title={uploadButtonLabels[fileType]}
      onCancel={handleClose}
      onOk={handleSubmit}
      okText="Tải xuống"
      width={600}
      confirmLoading={isSubmitting}
      fullHeight
    >
      <OnBlurInput autoFocus value={value} onChange={setValue} placeholder="Đường dẫn" />
    </BaseDialog>
  );

  function submitFn() {
    return asyncAction(uploadButtonLabels[fileType], () => {
      let uploadFn;

      switch (fileType) {
        case FileType.AUDIO:
          uploadFn = fileApi.uploadAudioFromUrl;
          break;
        case FileType.VIDEO:
          uploadFn = fileApi.uploadVideoFromUrl;
          break;
        default:
          uploadFn = fileApi.uploadPhotoFromUrl;
      }

      return uploadFn(value).then(handleDone);
    });
  }

  function handleDone(resp) {
    handleClose();
    onUploaded(resp.data.item);
  }
});

export default Form.create({ name: 'FileUploadFromUrlDialogButton' })(FileUploadFromUrlDialogButton);
