import { Form } from 'antd';
import AuthzCheck from 'core/components/common/AuthzCheck';
import React from 'react';
import ContentFormItemRoyaltyInput from './ContentFormItemRoyaltyInput';

export default function ContentFormItemRoyalty({ content, form }) {
  const { getFieldDecorator } = form;

  return (
    <AuthzCheck extendedRight="content.edit-royalty">
      <Form.Item>
        {getFieldDecorator('Royalty', {
          initialValue: content.Royalty,
        })(
          <ContentFormItemRoyaltyInput />,
        )}
      </Form.Item>
    </AuthzCheck>

  );
}
