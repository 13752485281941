import { Form } from 'antd';
import AuthzCheck from 'core/components/common/AuthzCheck';
import SelectableRelatedTopicsTab from 'core/components/topic/select-button/TopicSelectableTab';
import ContentFormRelatedTopicsInput from 'core/modules/contents/components/form/related-topics/ContentFormRelatedTopicsInput';
import React from 'react';

export default function ContentFormItemRelatedTopic({ content, form }) {
  const { getFieldDecorator } = form;

  return (
    <AuthzCheck extendedRight="topic.add-content">
      <Form.Item>
        {getFieldDecorator('RelatedTopics', {
          initialValue: content.RelatedTopics,
        })(
          <ContentFormRelatedTopicsInput SelectableTab={SelectableRelatedTopicsTab} />
        )}
      </Form.Item>
    </AuthzCheck>
  );
}
