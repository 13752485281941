import { createPromiseDefer } from '@bm/common';
import { Button, message, Modal } from 'antd';
import logger from 'core/common/logger';
import { ContentAction } from 'core/engine/content/constants';
import React, { useCallback } from 'react';

export default function ContentFormToolbarResetButton({
  action,
  onAction,
  className,
  loading,
}) {
  const handleClick = useCallback(() => {
    const deferred = createPromiseDefer();

    Modal.confirm({
      title: 'Viết lại tin bài?',
      content: 'Các trường nhập liệu sẽ được đặt lại, toàn bộ nội dung đã nhập sẽ mất.',
      okText: 'Viết lại',
      onOk() {
        deferred.resolve();
      },
      onCancel() {
        deferred.reject('cancel');
      },
    });

    return deferred.promise
      .then(() => {
        onAction(ContentAction.RESET);
      })
      .catch((err) => {
        if (err !== 'cancel') {
          logger.error('Error while resetting content:', err);
          message.error('Có lỗi xảy ra!');
        }
      });
  }, [onAction]);

  return (
    <Button
      name={action.name}
      title={action.label}
      className={className}
      onClick={handleClick}
      icon={action.icon}
      loading={loading}
    >
      {action.label}
    </Button>
  );
}
