import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import makeStyles from 'core/common/makeStyles';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DeleteButton from 'core/components/common/DeleteButton';
import BaseTable from 'core/components/table/BaseTable';
import tagApi from 'core/engine/tag/api';
import useContentColumns from 'core/hooks/useContentColumns';
import useList from 'core/hooks/useList';
import React, { Fragment, useEffect } from 'react';

const useStyles = makeStyles(() => ({
  table: {
    marginTop: 10,
  },
}));

function listFn({ sorting, filters }) {
  return tagApi.searchContent({
    fields: ['ContentID', 'Title', 'AvatarUrl', 'ZoneID', 'DistributionDate', 'Status'],
    lookup: ['ContentStat.ContentID', 'ContentStat.ViewCount'],
    sortBy: sortingToSortBy(sorting),
    keyword: filters.Keywords,
  });
}

export default function SelectedContentKeywordsTab({ keyword, contentsAdded, setContentsAdded }) {
  const classes = useStyles();
  const contentColumns = useContentColumns();

  const listProps = useList({
    listFn,
    defaultFilters: {
      Keywords: keyword,
    },
    defaultSorting: {
      DistributionDate: 'DESC',
    },
    autoLoad: true,
  });

  useEffect(() => {
    setContentsAdded([...contentsAdded, ...listProps.items]);
  }, [listProps.items]);

  const columns = [
    contentColumns.Avatar,
    contentColumns.Title,
    contentColumns.DistributionDate,
    {
      ...contentColumns.ViewCount,
      width: 85,
    },
    {
      width: 55,
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <DeleteButton
            title={`Xóa từ khóa bài viết: ${original.Title}?`}
            onDelete={() => handleDelete(original)}
          />
        </ActionsCellWrapper>
      ),
    },
  ];

  return (
    <Fragment>
      <span>Tổng số bài: {listProps.total || 0}</span>
      <BaseTable
        data={contentsAdded}
        columns={columns}
        showPagination={false}
        className={classes.table}
        heightAdjust={21}
      />
    </Fragment>
  );

  function handleDelete(content) {
    return asyncAction('Xóa từ khóa bài viết', () => {
      tagApi.removeContent(content.ContentID, keyword).then(() => {
        listProps.removeItem(content, 'ContentID');
        setContentsAdded(contentsAdded.filter(c => c.ContentID !== content.ContentID));
      });
    });
  }
}
