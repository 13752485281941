import { sortingToSortBy } from 'core/common/listUtils';
import useContentColumns from 'core/hooks/useContentColumns';
import BaseTable from '../table/BaseTable';
import contentApi from 'core/engine/content/api';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { useEffect } from 'react';
import ActionsCellWrapper from '../common/ActionsCellWrapper';
import ContentEditLink from '../content/ContentEditLink';
import { Button } from 'antd';

function listFn({ limit, offset, sorting, filters }) {
  return contentApi.getDeployedList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
    fields: ['CreatedDate'],
    lookup: [
      'ContentStat.ContentID',
      'ContentStat.ViewCount',
    ],
  });
}

export default function ContentEmbedList({ objectType, objectId }) {
  const listProps = useList({
    listFn,
    defaultSorting: {
      DistributionDate: 'DESC',
    },
  });
  const ContentColumns = useContentColumns();

  const columns = [
    ContentColumns.Avatar,
    ContentColumns.Title,
    ContentColumns.Writer,
    ContentColumns.DistributionDate,
    ContentColumns.ViewCount,
    {
      maxWidth: 80,
      minWidth: 50,
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <ContentEditLink contentId={original.ContentID} target="_blank">
            <Button icon="edit" title="Sửa tin" />
          </ContentEditLink>
        </ActionsCellWrapper>
      ),
    },
  ];

  useEffect(() => {
    listProps.addFilter('ObjectType', objectType);
    listProps.addFilter('ObjectID', objectId);
  }, [objectType, objectId]);

  return (
    <BaseTable
      {...useListProps(listProps)}
      columns={columns}
    />
  );
}
