import { green } from '@ant-design/colors';
import { Badge, Button, Tabs } from 'antd';
import { emptyArray, emptyFunction } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import BaseDialog from 'core/components/common/BaseDialog';
import Separator from 'core/components/common/Separator';
import useDialog from 'core/hooks/useDialog';
import React, { Fragment, useEffect, useState } from 'react';
import TopicSelectableTab from './TopicSelectableTab';
import TopicSelectedTab from './TopicSelectedTab';

const { TabPane } = Tabs;

const useStyles = makeStyles(() => ({
  tab: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > .ant-tabs-content': {
      height: 'calc(100% - 71px)',
    },
  },
}));

export default function TopicSelectButton({
  value = emptyArray,
  onChange = emptyFunction,
  triggerComponent: TriggerComponent = DefaultTriggerComponent,
}) {
  const classes = useStyles();
  const { open, handleClose, handleOpen } = useDialog();
  const [modifyingItems, setModifingItems] = useState([]);

  useEffect(() => {
    if (open) {
      setModifingItems(value);
    } else {
      setModifingItems([]);
    }
  }, [value, open]);

  return (
    <Fragment>
      <TriggerComponent onClick={handleOpen} />
      <BaseDialog
        fullHeightFixed
        noVerticalPadding
        visible={open}
        title={null}
        onOk={onOk}
        onCancel={handleClose}
        width={900}
      >
        <Tabs className={classes.tab} tabBarStyle={{ width: 'calc(100% - 32px)' }}>
          <TabPane
            tab={(
              <Fragment>
                Chủ đề đã chọn
                <Separator />
                <Badge
                  count={modifyingItems.length}
                  style={{ backgroundColor: green[5] }}
                />
              </Fragment>
            )}
            key="list"
          >
            <TopicSelectedTab value={modifyingItems} onChange={setModifingItems} />
          </TabPane>
          <TabPane tab="Thêm chủ đề" key="add">
            <TopicSelectableTab value={modifyingItems} onChange={setModifingItems} />
          </TabPane>
        </Tabs>
      </BaseDialog>
    </Fragment>
  );

  function onOk() {
    onChange(modifyingItems);
    handleClose();
  }
}

function DefaultTriggerComponent({ onClick }) {
  return (
    <Button onClick={onClick}>Chọn chủ đề</Button>
  );
}
