import { httpDownload, httpGet, httpPost } from 'core/common/http-client';

const royaltyApi = {
  getList({ limit = 20, offset = 0, fields = [], filters }) {
    const qs = {
      limit,
      offset,
      fields,
      ...filters,
    };

    return httpGet('/royalties/get/items-by-range', { qs });
  },

  getContentList({ limit = 20, offset = 0, lookup = [], sortBy, filters }) {
    const qs = {
      limit,
      offset,
      sortBy,
      lookup,
      ...filters,
    };

    return httpGet('/royalties/get/contents', { qs });
  },

  setRoyalties(contentId, royalties) {
    return httpPost('/royalties/post/set-royalties', {
      ContentID: contentId,
      royalties: JSON.stringify(royalties),
    });
  },

  getStats(filters) {
    const qs = { ...filters };
    return httpGet('/royalties/get/stats', { qs });
  },

  submit(data) {
    return httpPost('/royalties/post/submit', { royalties: data });
  },

  export(filters, filename) {
    return httpDownload('/royalties/get/export', { qs: filters, filename });
  },

  exportContent(filters, filename) {
    return httpDownload('/royalties/get/export-content', { qs: filters, filename });
  },

  exportAuthor(filters, filename) {
    return httpDownload('/royalties/get/export-author', { qs: filters, filename });
  },
};

export default royaltyApi;
