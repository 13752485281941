import { green } from '@ant-design/colors';
import { Badge, Tabs } from 'antd';
import React, { useState, Fragment, useEffect } from 'react';
import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import Separator from 'core/components/common/Separator';
import contentApi from 'core/engine/content/api';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import AuthorSelectedContentList from './AuthorSelectedContentList';
import { ContentListType } from 'core/engine/content/constants';
import contentAuthorApi from 'core/engine/content-author/api';
import TopicSelectableContentList from 'core/modules/operations/topics/components/topic-contents/TopicSelectableContentList';

const { TabPane } = Tabs;

const useStyles = makeStyles(() => ({
  tab: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > .ant-tabs-content': {
      height: 'calc(100% - 60px)',
    },
  },
}));

function listFn({ limit, offset, sorting, filters }) {
  return contentApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    lookup: [
      'ContentAuthor.ContentAuthorID',
      'ContentAuthor.ContentID',
      'ContentAuthor.AuthorID',
      'ContentAuthor.IsHome',
    ],
    filters: filters,
  });
}

export default function AuthorContentList({ authorId }) {
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState([]);

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultPageSize: 20,
    defaultFilters: {
      AuthorID: authorId,
      listType: ContentListType.CONTENT_AUTHORS,
    },
    defaultSorting: {
      DistributionDate: 'DESC',
    },
  });

  useEffect(() => {
    contentApi.getList({
      offset: 0,
      limit: 3000,
      lookup: [
        'ContentAuthor.ContentAuthorID',
        'ContentAuthor.ContentID',
        'ContentAuthor.AuthorID',
        'ContentAuthor.IsHome',
      ],
      filters: {
        AuthorID: authorId,
        listType: ContentListType.CONTENT_AUTHORS,
      },
    }).then(resp => setSelectedItems(resp.data.items));
  }, []);

  return (
    <Tabs className={classes.tab}>
      <TabPane
        tab={
          <Fragment>
            Danh sách tin
            <Separator />
            <Badge
              count={listProps.total}
              style={{ backgroundColor: green[5] }}
              overflowCount={999}
            />
          </Fragment>
        }
        key="list"
      >
        <AuthorSelectedContentList
          {...useListProps(listProps)}
          authorId={authorId}
          selected={listProps.items}
          onUpdated={listProps.refresh}
          onUnselect={handleUnselect}
        />
      </TabPane>
      <TabPane tab="Thêm tin" key="add">
        <TopicSelectableContentList selected={selectedItems} onSelectAll={handleSelectAll} onSelect={handleSelect} onUnselect={handleUnselect} />
      </TabPane>
    </Tabs>
  );

  function handleSelectAll(items) {
    setSelectedItems([...selectedItems, ...items]);

    asyncAction('Thêm tin cho tác giả', () => {
      return contentAuthorApi.multiCreate({
        AuthorID: authorId,
        ContentIDs: items.map(item => item.ContentID).join(),
      }).then(listProps.refresh);
    });
  }

  function handleSelect(item) {
    setSelectedItems([...selectedItems, item]);

    asyncAction('Thêm tin cho tác giả', () => {
      return contentAuthorApi.create({
        AuthorID: authorId,
        ContentID: item.ContentID,
      }).then(listProps.refresh);
    });
  }

  function handleUnselect(item) {
    listProps.removeItem(item, 'ContentID');
    setSelectedItems(lodash.pull(selectedItems.slice(), item));

    asyncAction('Xóa tác giả của tin', () => {
      return contentAuthorApi.delete(item.ContentAuthorID)
        .catch((err) => {
          listProps.refresh();
          return Promise.reject(err);
        });
    });
  }
}
