import lodash from 'core/common/lodash';

export function clampDrag({ windowWidth, windowHeight, x, y, width, height }) {
  const maxX = windowWidth - width;
  const maxY = windowHeight - height;
  const clampedX = lodash.clamp(x, 0, maxX);
  const clampedY = lodash.clamp(y, 0, maxY);

  return { x: clampedX, y: clampedY };
}

export function clampResize({ windowWidth, windowHeight, x, y, width, height }) {
  const maxWidth = windowWidth - x;
  const maxHeight = windowHeight - y;
  const clampedWidth = lodash.clamp(width, 200, maxWidth);
  const clampedHeight = lodash.clamp(height, 200, maxHeight);

  return { width: clampedWidth, height: clampedHeight };
}
