import { Button, Form } from 'antd';
import React from 'react';
import { asyncAction } from 'core/common/async';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import AuthorForm from './AuthorForm';
import BaseDialog from 'core/components/common/BaseDialog';
import authorApi from 'core/engine/author/api';

function AuthorFormDialogButton({ form, author = {}, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  const title = isEditMode ? 'Sửa tác giả: ' + author.FullName : 'Tạo tác giả';

  return (
    <React.Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={title}
        onClick={handleOpen}
      >
        {isEditMode ? '' : title}
      </Button>
      <BaseDialog
        visible={open}
        title={title}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <AuthorForm form={form} author={author} />
      </BaseDialog>
    </React.Fragment>
  );

  function submitFn({ formData }) {
    formData.SeoEntry = JSON.stringify(formData.SeoEntry);

    if (formData.Parent) {
      formData.ParentID = formData.Parent.AuthorID;
    }

    if (isEditMode) {
      return asyncAction(title, () => {
        return authorApi.update(formData)
          .then(handleDone);
      });
    }

    return asyncAction(title, () => {
      return authorApi.create(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'AuthorFormDialogButton' })(AuthorFormDialogButton);
