import { Button, Form } from 'antd';
import React, { Fragment, useMemo, useRef } from 'react';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import InterviewAnswerForm from './InterviewAnswerForm';
import interviewAnswerApi from 'core/engine/interview-answer/api';

function InterviewAnswerFormDialogButton({ form, interview, answer, onDone, isEditMode = true, label }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });
  const editorRef = useRef();

  const title = useMemo(() => isEditMode ? 'Thay đổi câu trả lời' : 'Thêm câu trả lời', [isEditMode]);

  return (
    <Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'form'}
        title={title}
        onClick={handleOpen}
      >{label}</Button>
      <BaseDialog
        visible={open}
        title={title}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        fullHeightFixed={true}
        width={960}
        confirmLoading={isSubmitting}
      >
        <InterviewAnswerForm form={form} interview={interview} answer={answer} editorRef={editorRef} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    if (formData.Date) {
      formData.Date = formData.Date.toDate().getTime();
    }

    formData.HtmlAnswer = editorRef.current.getNormalizedValue();

    if (isEditMode) {
      return asyncAction(title, () => {
        return interviewAnswerApi.update(formData)
          .then(handleDone);
      });
    } else {
      return asyncAction(title, () => {
        return interviewAnswerApi.create(formData)
          .then(handleDone);
      });
    }
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'InterviewAnswerFormDialogButton' })(InterviewAnswerFormDialogButton);
