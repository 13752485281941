import { ServerErrorCode } from 'core/constants';
import { setSessionExpired } from 'core/redux/actions/auth';
import { getCurrentStore } from 'core/redux/store';
import { saveAs } from 'file-saver';
import { SERVER_URL } from '../config';
import { isNullOrUndefined } from './is';
import locationService from './locationService';

const JSON_HEADER = {
  'Content-Type': 'application/json',
};

export function httpGet(path, options = {}) {
  return httpClient(path, options);
}

export function httpPost(path, body, options = {}) {
  return httpClient(path, Object.assign({
    method: 'POST',
    body: JSON.stringify(body),
    headers: JSON_HEADER,
  }, options));
}

export function httpDelete(path, options = {}) {
  return httpClient(path, Object.assign({
    method: 'DELETE',
  }, options));
}

export function httpClient(path, options) {
  return createRequest(path, options)
    .then(responseToJson)
    .then(validateResponse);
}

export function httpDownload(path, { filename = 'export-file', ...props } = {}) {
  return createRequest(path, props)
    .then(resp => {
      const contentType = resp.headers.get('content-type');

      if (contentType && contentType.includes('application/json')) {
        return resp.json().then(json => Promise.reject(json));
      }

      return resp.blob();
    })
    .then(blob => saveAs(blob, filename));
}

function createRequest(path, options) {
  let url;
  let extraHeaders = {};

  if (/^https?:\/\//.test(path)) {
    url = new URL(path);
  } else {
    url = new URL(`${SERVER_URL}${path}`);
    extraHeaders = { 'X-CMS-CMD': locationService.getPathName() };
  }

  if (options.qs) {
    Object.keys(options.qs)
      .filter((key) => {
        return !isNullOrUndefined(options.qs[key]) && options.qs[key] !== '';
      })
      .forEach(key => url.searchParams.append(key, options.qs[key]));
  }

  options = Object.assign({
    mode: 'cors',
    credentials: 'include',
  }, options);

  options.headers = {
    ...options.headers,
    ...extraHeaders,
  };

  return fetch(url, options);
}

export function responseToJson(resp) {
  if (resp.ok) {
    return resp.json();
  }

  return Promise.reject(resp);
}

function validateResponse(resp) {
  if (resp.error_code || resp.err) {
    if ([
      ServerErrorCode.INVALID_SESSION,
      ServerErrorCode.SESSION_EMPTY,
      ServerErrorCode.SESSION_EXPIRED,
    ].includes(resp.error_code)) {
      getCurrentStore().dispatch(setSessionExpired(true));
    }

    return Promise.reject(resp);
  }

  return resp;
}
