import { httpPost, httpGet } from 'core/common/http-client';
import { LoginStatus, ServerErrorCode } from 'core/constants';

const authApi = {
  getLoginStatus() {
    return httpPost('/auths/post/verify')
      .then((resp) => {
        return {
          status: LoginStatus.LOGGED_IN,
          user: accountToUser(resp.data.account),
        };
      })
      .catch((err) => {
        if (
          [
            ServerErrorCode.INVALID_SESSION,
            ServerErrorCode.SESSION_EMPTY,
            ServerErrorCode.SESSION_EXPIRED,
          ].includes(err.error_code)
        ) {
          return {
            status: LoginStatus.NOT_LOGGED_IN,
          };
        }

        return Promise.reject(err);
      });
  },

  getSessionExpiration() {
    return httpGet('/auths/get/session-expiration');
  },

  refreshSession(data) {
    return httpPost('/auths/post/refresh-session', data)
      .then((resp) => {
        return accountToUser(resp.data.account);
      });
  },

  login(data) {
    return httpPost('/auths/post/login', data)
      .then((resp) => {
        return accountToUser(resp.data.account);
      });
  },

  logout() {
    return httpPost('/auths/post/logout');
  },
};

function accountToUser(account) {
  return {
    id: account.AccountID,
    name: account.Username,
    pseudonym: account.Pseudonym,
    isAdmin: account.IsAdministrator,
    extendedRights: account.extendedRights,
  };
}

export default authApi;
