import { parseJson } from 'core/common/jsonUtils';
import makeStyles from 'core/common/makeStyles';
import React, { useMemo } from 'react';

const useStyles = makeStyles(() => ({
  list: {
    marginBottom: 0,
    paddingLeft: 15,
  },
}));

export default function VoteOptionDisplay({ voteOptions }) {
  const classes = useStyles();
  const options = useMemo(() => parseJson(voteOptions, []), []);

  return (
    <ul className={classes.list}>
      {options.map(option => (
        <li key={option.OptionID}>{option.Option} ({option.VoteCount})</li>
      ))}
    </ul>
  );
}
