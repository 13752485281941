import { httpGet, httpPost } from 'core/common/http-client';
import { MAX_INTEGER } from 'core/constants';

const DEFAULT_FIELDS = [
  'GuestID',
  'InterviewID',
  'Name',
  'Description',
  'AvatarUrl',
  'Order',
  'Enabled',
  'Interviewers',
];

const DEFAULT_LOOKUP = [];

const interviewGuestApi = {
  getList({ limit = 20, offset = 0, sortBy, fields = [], lookup = [], filters }) {
    const qs = {
      limit,
      offset,
      sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      lookup: [...DEFAULT_LOOKUP, ...lookup],
      ...filters,
    };

    return httpGet('/interview-guests/get/items-by-range', { qs });
  },

  create(data) {
    return interviewGuestApi.submit({ insertItem: data });
  },

  update(data) {
    return interviewGuestApi.submit({ updateItems: JSON.stringify(Array.isArray(data) ? data : [data]) });
  },

  delete(data) {
    return interviewGuestApi.submit({ deleteItem: data });
  },

  submit(data) {
    return httpPost('/interview-guests/post/submit', data);
  },

  getListByInterviewId(interviewId) {
    const qs = { InterviewID: interviewId, sortBy: 'Order:ASC', limit: MAX_INTEGER };
    return httpGet('/interview-guests/get/items-by-range', { qs });
  },
};

export default interviewGuestApi;
