import { httpGet } from 'core/common/http-client';

const contentLogApi = {
  get(id, { lookup, fields = [] } = {}) {
    const qs = { LogID: id, lookup, fields: [...fields] };
    return httpGet('/content-logs/get/item', { qs });
  },

  getList({ limit = 20, offset = 0, sortBy, fields = [], filters }) {
    const qs = { limit, offset, sortBy, fields: [...fields], ...filters };
    return httpGet('/content-logs/get/items-by-range', { qs });
  },

  getVersions(contentId) {
    return httpGet(`/content-logs/get/versions?ContentID=${contentId}`);
  },

  getDiff(contentId, leftId, rightId) {
    const qs = { ContentID: contentId, LeftID: leftId, RightID: rightId };
    return httpGet('/content-logs/get/diff', { qs });
  },
};

export default contentLogApi;
