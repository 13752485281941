import { Config } from '@zdocs/core';
import BasePlugin from '../BasePlugin';

export default class TemplatePlugin extends BasePlugin {

  static install(config) {
    const defaultList = {
      left: 'Box căn trái',
      right: 'Box căn phải',
      center: 'Box căn giữa',
      quote: 'Box trích dẫn',
    };

    const list = config && config.list ? (
      typeof config.list === 'function' ? config.list(defaultList) : config.list
    ) : defaultList;

    Config.prototype.controls.template = {
      command: 'template',
      list,
      exec: function (zdocs, event, control) {
        if (control.args) {
          zdocs.execCommand(control.command, control.args[0]);
        }
      },
      icon: 'template',
      tooltip: 'Chèn các định dạng được định nghĩa trước',
    };
  }

  get name() {
    return 'TemplatePlugin';
  }

  get command() {
    return 'template';
  }

  get defaultOptions() {
    return {
      boxTemplate: '<notebox class="$class" align="$align"><p><br></p></notebox>',
      quoteTemplate: '<blockquote><p><br></p></blockquote>',
    };
  }

  constructor(zdocs) {
    super(zdocs);

    this.callback = this.callback.bind(this);

    zdocs.registerCommand(this.command, this.callback);
  }

  callback(command, control) {
    if (control === 'left' || control === 'right' || control === 'center') {
      const template = this.options.boxTemplate
        .replace('$class', 'notebox n' + control)
        .replace('$align', control);

      this.insertHTMLToEditor(template, false, false, 'p');
    }

    if (control === 'quote') {
      this.insertHTMLToEditor(this.options.quoteTemplate, false, false, 'p');
    }

    const option = this.options[control];

    if (option) {
      this.insertHTMLToEditor(option.template, option.insertInline, option.newLineAfter, option.focusSelector);
    }
  }
}
