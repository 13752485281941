import { Icon, Modal } from 'antd';
import React, { useRef, useState, useEffect, Fragment } from 'react';
import lodash from 'core/common/lodash';
import fileApi from 'core/engine/file/api';
import useAsyncAction from 'core/hooks/useAsyncAction';
import useDialog from 'core/hooks/useDialog';
import FileVideoAvatarPicker from './FileVideoAvatarPicker';

export default function FileVideoSelectInVideoAvatarButton({ file, onUpdated }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { isLoading, performAction } = useAsyncAction();
  const avatarRef = useRef();
  const [avatarPicked, setAvatarPicked] = useState(false);

  useEffect(() => {
    if (open) {
      setAvatarPicked(false);
      avatarRef.current = null;
    }
  }, [open]);

  return (
    <Fragment>
      <Icon type="camera" onClick={handleOpen} title="Chọn ảnh đại diện từ nội dung video" />
      <Modal
        visible={open}
        title="Chọn ảnh đại diện cho video"
        onCancel={handleClose}
        onOk={handleOk}
        okText="Cập nhật"
        width={600}
        confirmLoading={isLoading}
        okButtonProps={{ disabled: !avatarPicked }}
        destroyOnClose
        keyboard={false}
        maskClosable={false}
      >
        <FileVideoAvatarPicker playableUrl={file.url} onPick={handleAvatarPick} />
      </Modal>
    </Fragment>
  );

  function handleOk() {
    if (file.Meta.avatarUrl === avatarRef.current) {
      handleClose();
      return;
    }

    const avatarUrl = avatarRef.current;
    const name = `avatar-of-video-${file.FileID}.png`;
    const imageData = {
      ...lodash.pick(file.Meta, ['width', 'height']),
      videoId: file.FileID,
    };

    const updateVideoThumbnail = () => performAction('Cập nhật ảnh đại diện video', () => {
      return fileApi.uploadPhotoFromUrl(avatarUrl, name, imageData)
        .then(resp => resp.data.item)
        .then((avatarFile) => {
          const newMeta = {
            ...file.Meta,
            avatarUrl: avatarFile.Path,
            avatarWidth: imageData.width,
            avatarheight: imageData.height,
          };

          return fileApi.update({
            FileID: file.FileID,
            Meta: JSON.stringify(newMeta),
          })
            .then(() => {
              handleClose();
              onUpdated({
                ...file,
                Meta: newMeta,
              });
            });
        });
    });

    if (imageData.width && imageData.height) {
      updateVideoThumbnail && updateVideoThumbnail();
      return;
    }

    const image = new Image();

    image.onload = function() {
      imageData.width = image.width;
      imageData.height = image.height;

      updateVideoThumbnail && updateVideoThumbnail();
    };

    image.src = avatarUrl;
  }

  function handleAvatarPick(avatarUrl) {
    avatarRef.current = avatarUrl;
    setAvatarPicked(true);
  }
}
