import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './reactTableOverride';
import App from './components/layout';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

moment.locale('vi', {
  week: { dow: 1 }, // set first day of the week to Monday
});

export function startApp(props) {
  ReactDOM.render(<App {...props} />, document.getElementById('root'));
}
