import classnames from 'core/common/classnames';
import makeStyles from 'core/common/makeStyles';
import React, { memo } from 'react';
import './style.less';

const useStyles = makeStyles(() => ({
  icon: {
    display: 'inline-block',
    width: 60,
    height: 60,
    marginBottom: 0,
  },
}));

function LiveStreamIcon({ typeName }) {
  const classes = useStyles();

  return (
    <div className={classnames(classes.icon, 'LiveStreamIcon', typeName)} />
  );
}

export default memo(LiveStreamIcon);
