import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import CaptchaInput from './CaptchaInput';
import LibreCaptchaInput from './LibreCaptchaInput';
import FeatureFlip from './FeatureFlip';
import useLoginForm from 'core/hooks/useLoginForm';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const useStyles = makeStyles(() => ({
  formItem: {
    marginBottom: 8,
  },
}));

export const LoginFormRaw = forwardRef(function ({
  form,
  onSubmit,
  username = '',
  submitOnEnter = true,
}, ref) {
  const classes = useStyles();
  const { getFieldDecorator } = form;

  const captchaRef = useRef();

  useImperativeHandle(ref, () => ({
    resetCaptcha() {
      captchaRef.current && captchaRef.current.reset();
    },
  }));

  return (
    <Form {...formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Tên đăng nhập" className={classes.formItem}>
        {getFieldDecorator('username', {
          initValue: username,
          rules: [
            {
              required: true,
              message: 'Vui lòng nhập tên đăng nhập!',
            },
            {
              max: 250,
              message: 'Tối đa 250 kí tự!',
            },
          ],
        })(<Input autoFocus />)}
      </Form.Item>
      <Form.Item label="Mật khẩu" className={classes.formItem}>
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu!',
            },
            {
              max: 250,
              message: 'Tối đa 250 kí tự!',
            },
          ],
        })(<Input.Password autoComplete="off" />)}
      </Form.Item>
      <FeatureFlip name="captcha">
        <Form.Item label="Captcha" className={classes.formItem}>
          {getFieldDecorator('captcha')(<CaptchaInput ref={captchaRef} />)}
        </Form.Item>
      </FeatureFlip>
      <FeatureFlip name="libre-captcha">
        <Form.Item label="Captcha" className={classes.formItem}>
          {getFieldDecorator('libreCaptcha')(<LibreCaptchaInput ref={captchaRef} />)}
        </Form.Item>
      </FeatureFlip>
      <FeatureFlip name="otp">
        <Form.Item label="OTP" className={classes.formItem}>
          {getFieldDecorator('otpCode')(<Input autoComplete="off" />)}
        </Form.Item>
      </FeatureFlip>
      <Form.Item wrapperCol={tailFormItemLayout.wrapperCol}>
        {getFieldDecorator('remember', {
          valuePropName: 'checked',
          initialValue: true,
        })(
          <Checkbox>Ghi nhớ đăng nhập</Checkbox>
        )}
      </Form.Item>
      {submitOnEnter && <Button style={commonStyles.hidden} htmlType="submit" />}
    </Form>
  );
});

export default Form.create({ name: 'LoginForm' })(function LoginForm({ form, submitFn, ...restProps }) {
  const { handleSubmit, isSubmitting, formRef } = useLoginForm({ form, submitFn });

  return (
    <div>
      <LoginFormRaw form={form} onSubmit={handleSubmit} {...restProps} ref={formRef} />
      <Row>
        <Col {...tailFormItemLayout.wrapperCol}>
          <Button type="primary" loading={isSubmitting} onClick={handleSubmit}>Đăng nhập</Button>
        </Col>
      </Row>
    </div>
  );
});
