import React, { forwardRef, useCallback } from 'react';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import MoneyInput from 'core/components/common/MoneyInput';
import OnBlurTextArea from 'core/components/common/OnBlurTextArea';
import { emptyObject } from 'core/common/empty';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
  },
  note: {
    marginTop: 10,
  },
}));

export default forwardRef(function ContentFormRoyaltyInput({
  value: royalty = emptyObject,
  onChange,
}, ref) {
  const classes = useStyles();

  const handleMoneyChange = useCallback((value) => {
    onChange({
      ...royalty,
      Money: value || 0,
    });
  }, [royalty]);

  const handleNoteChange = useCallback((value) => {
    onChange({
      ...royalty,
      Note: value,
    });
  }, [royalty]);

  return (
    <div ref={ref} className={classes.container}>
      <MoneyInput value={royalty.Money} onChange={handleMoneyChange} placeholder="Tiền nhuận bút" min={0} />

      <OnBlurTextArea className={classes.note} value={royalty.Note} onChange={handleNoteChange} placeholder="Nhập ghi chú nhuận bút..." />
    </div>
  );
});
