import React, { forwardRef, Fragment, useImperativeHandle, useState } from 'react';
import { OnEnterFilterForList } from 'core/components/common/OnEnterFilter';
import { commonColumns, sortingToSortBy } from 'core/common/listUtils';
import { AdsPositionTypeLabel } from 'core/engine/ads-position/constants';
import { connectSelectorFilterForList } from 'core/common/hocs';
import useDialog from 'core/hooks/useDialog';
import BaseDialog from 'core/components/common/BaseDialog';
import useListProps from 'core/hooks/useListProps';
import SelectableTable from 'core/components/table/SelectableTable';
import lodash from 'core/common/lodash';
import useList from 'core/hooks/useList';
import adsPositionApi from 'core/engine/ads-position/api';
import { Button } from 'antd';

const columns = [
  commonColumns.order,
  {
    Header: 'Tên vị trí',
    accessor: 'Name',
    filterable: true,
    Filter: OnEnterFilterForList,
    minWidth: 150,
  },
  {
    id: 'Code',
    Header: 'Mã vị trí',
    accessor: 'Code',
    filterable: true,
    Filter: OnEnterFilterForList,
    maxWidth: 250,
    minWidth: 150,
  },
  {
    id: 'Type',
    Header: 'Kiểu',
    maxWidth: 150,
    minWidth: 130,
    filterable: true,
    Filter: connectSelectorFilterForList({ options: AdsPositionTypeLabel, valueIsNumber: true }),
    Cell: ({ original }) => AdsPositionTypeLabel[original.Type],
  },
  {
    id: 'Interval',
    Header: 'Thời gian',
    accessor: 'Interval',
    width: 75,
    ...commonColumns.formatNumber,
  },
];

function listFn({ limit, offset, filters, sorting }) {
  return adsPositionApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default forwardRef(function AdsPositionSelectButton({
  triggerByButton = false,
  multiple = false,
  onDone,
  ...props
}, ref) {
  const { open, handleClose, handleOpen } = useDialog();
  const [ads, setAds] = useState([]);

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: {
      PositionID: 'DESC',
    },
    defaultFilters: {
      Enabled: true,
    },
  });

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  const footerProps = multiple ? { paddingBottom: 0 } : { footer: null };

  return (
    <Fragment>
      {triggerByButton && (
        <Button
          icon="edit"
          title="Chọn vị trí quảng cáo"
          onClick={handleOpen}
        >
          Chọn vị trí quảng cáo
        </Button>
      )}
      <BaseDialog
        keyboard
        maskClosable
        fullHeightFixed
        paddingTop={0}
        title="Thêm vị trí quảng cáo"
        width={900}
        visible={open}
        onCancel={handleClose}
        onOk={() => handleOk(ads)}
        okText="Thêm"
        okButtonProps={{ disabled: !ads.length }}
        {...footerProps}
      >
        <SelectableTable
          {...useListProps(listProps)}
          idField="PositionID"
          selected={ads}
          onSelect={handleSelect}
          onUnselect={handleUnselect}
          columns={columns}
          {...props}
        />
      </BaseDialog>
    </Fragment>
  );

  function handleSelect(item) {
    setAds([...ads, item]);

    if (!multiple) {
      handleOk(item);
    }
  }

  function handleUnselect(item) {
    const newAds = ads.slice();
    lodash.remove(newAds, item);
    setAds(newAds);
  }

  function handleOk(result) {
    onDone(result);
    handleClose();
    setAds([]);
  }
});
