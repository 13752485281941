import React from 'react';
import Container from 'core/components/common/Container';
import PositionList from './AdsPositionList';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import AdsPositionFormDialogButton from './AdsPositionFormDialogButton';
import adsPositionApi from 'core/engine/ads-position/api';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

function listFn({ limit, offset, filters, sorting }) {
  return adsPositionApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default function AdsPositionListView() {
  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: {
      Code: 'ASC',
    },
  });

  return (
    <Container pageTitle="Vị trí quảng cáo">
      <TableToolbarWrapper>
        <AdsPositionFormDialogButton isEditMode={false} onDone={listProps.refresh} />
        <Link to="/operations/advertisements">
          <Button icon="unordered-list" title="Danh sách quảng cáo">Danh sách quảng cáo</Button>
        </Link>
      </TableToolbarWrapper>
      <PositionList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdate={listProps.refresh}
        onDelete={handleDelete}
      />
    </Container>
  );

  function handleDelete(item) {
    return asyncAction('Xóa vị trí quảng cáo', () => {
      return adsPositionApi.delete(item.PositionID).then(() => {
        listProps.removeItem(item, 'PositionID');
      });
    });
  }
}
