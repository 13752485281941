import { Checkbox, Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import React from 'react';

export default function ContentSourceForm({ form, onSubmit, contentSource }) {
  const { getFieldDecorator } = form;

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      {contentSource.SourceID && (
        <Form.Item label="ID">
          {getFieldDecorator('SourceID', {
            initialValue: contentSource.SourceID,
          })(
            <Input disabled type="number" />
          )}
        </Form.Item>
      )}
      <Form.Item label="Tên nguồn">
        {getFieldDecorator('Name', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
          initialValue: contentSource.Name,
        })(
          <Input autoFocus />
        )}
      </Form.Item>

      <Form.Item label="URL">
        {getFieldDecorator('Url', {
          initialValue: contentSource.Url,
          rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <Input placeholder="http://...." />
        )}
      </Form.Item>

      <Form.Item label="Sử dụng">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: isNullOrUndefined(contentSource.Enabled) ? true : contentSource.Enabled,
        })(
          <Checkbox />
        )}
      </Form.Item>
    </Form>
  );
}
