import { Checkbox } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { arrayShallowEqual } from 'core/common/arrayHelper';
import { commonColors } from 'core/common/commonStyles';
import { emptyArray, emptyFunction } from 'core/common/empty';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import BaseTable from './BaseTable';

function arrayToObject(arr = []) {
  return arr.reduce((previous, current) => {
    previous[current] = true;
    return previous;
  }, {});
}

const useStyles = makeStyles(() => ({
  selected: {
    backgroundColor: `${commonColors.selected} !important`,
  },
}));

export default function CheckableTable({
  idField,
  columns,
  data = emptyArray,
  idIsNumber = true,
  selectedIds = emptyArray,
  onSelectedChange = emptyFunction,
  selectOnLeft = false,
  ...props
}) {
  const classes = useStyles();

  const [selectedKeyMap, setSelectedKeyMap] = useState({});

  const selectedKeyList = useMemo(
    () => Object.keys(selectedKeyMap).filter(key => selectedKeyMap[key]),
    [selectedKeyMap]
  );

  const selectedAll = useMemo(() => {
    return selectedKeyList.length && selectedKeyList.length === data.length;
  }, [data.length, selectedKeyList.length]);

  const selectedAllIndeterminate = useMemo(() => {
    return selectedKeyList.length && selectedKeyList.length < data.length;
  }, [data.length, selectedKeyList.length]);

  const handleSelect = useCallback((event) => {
    const { name, checked } = event.target;

    setSelectedKeyMap(selectedKeys => ({
      ...selectedKeys,
      [name]: checked,
    }));
  }, []);

  const handleSelectAll = useCallback((event) => {
    if (event.target.checked) {
      setSelectedKeyMap(
        arrayToObject(data.map(item => item[idField]))
      );
    } else {
      setSelectedKeyMap({});
    }
  }, [data, idField]);

  const extendedColumns = useMemo(() => {
    const modifiedColumns = columns.slice();
    const selectionColumn = {
      Header: <Checkbox indeterminate={selectedAllIndeterminate} disabled={!data.length} checked={selectedAll} onChange={handleSelectAll} />,
      headerStyle: { justifyContent: 'center' },
      Cell: ({ original }) => (
        <Checkbox name={String(original[idField])} checked={Boolean(selectedKeyMap[original[idField]])} onChange={handleSelect} />
      ),
      maxWidth: 80,
      minWidth: 30,
      style: { justifyContent: 'center' },
    };

    if (selectOnLeft) {
      modifiedColumns.unshift(selectionColumn);
    } else {
      modifiedColumns.push(selectionColumn);
    }

    return modifiedColumns;
  }, [columns, selectedAllIndeterminate, data.length, selectedAll, handleSelectAll, selectOnLeft, idField, selectedKeyMap, handleSelect]);

  useEffect(() => {
    if (!arrayShallowEqual(selectedKeyList, selectedIds.map(id => String(id)))) {
      setSelectedKeyMap(arrayToObject(selectedIds));
    }
  }, [selectedIds]);

  useEffect(() => {
    onSelectedChange(idIsNumber ? selectedKeyList.map(key => parseInt(key)) : selectedKeyList);
  }, [idIsNumber, selectedKeyList]);

  useEffect(() => {
    const allKeys = data.map(item => String(item[idField]));
    const mutualKeys = lodash.intersection(selectedKeyList, allKeys);
    setSelectedKeyMap(arrayToObject(mutualKeys));
  }, [data, idField]);

  return (
    <BaseTable
      columns={extendedColumns}
      data={data}
      getTrProps={(state, rowInfo, column) => {
        const trProps = props.getTrProps ? props.getTrProps(state, rowInfo, column) : {};

        if (rowInfo) {
          return {
            ...trProps,
            className: selectedKeyMap[rowInfo.original[idField]] ? classes.selected : '',
          };
        }

        return trProps;
      }}
      {...props}
    />
  );
}
