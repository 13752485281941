import { message } from 'antd';
import { normalizeEditorValue, parseEmbededObjects } from 'core/common/editor';
import { isNullOrUndefined } from 'core/common/is';
import { parseJson } from 'core/common/jsonUtils';
import lodash from 'core/common/lodash';
import contentApi from 'core/engine/content/api';
import { ContentAttribute, ContentDisplayType } from 'core/engine/content/constants';
import contentUtils from 'core/engine/content/utils';
import actionRegistry from 'core/registries/actions';
import moment from 'moment';
import { ContentFieldLabel, ContentHookAction } from './constants';

const ContentDefaultDaysSort = 7;
const ContentMaxDaysSort = 31;

export function formDataToContent(formData) {
  const content = { ...formData };

  content.Title = (content.Title || '').replace(/\s+/g, ' ');
  content.ZoneID = lodash.get(formData.Distributions, '[0].ZoneID') || 0;
  content.Keywords = formData.Keywords.join();
  content.Distributions = stringifyDistributions(formData.Distributions);
  content.Royalty = JSON.stringify(formData.Royalty);
  content.SeoEntry = JSON.stringify(formData.SeoEntry);
  content.HtmlBody = normalizeEditorValue(formData.HtmlBody);

  if (formData.avatar) {
    content.AvatarUrl = formData.avatar.url;
    content.AvatarDescription = (formData.avatar.description || '').replace(/\s+/g, ' ');
    delete content.avatar;
  }

  if (formData.DistributionDate) {
    content.DistributionDate = formData.DistributionDate.toDate().getTime();
  }

  content.Meta = content.Meta || {};

  Object.keys(content.Meta).forEach((field) => {
    const fieldValue = formData.Meta[field];

    if (isNullOrUndefined(fieldValue)) {
      delete content.Meta[field];
      return;
    }

    if (typeof fieldValue === 'object') {
      delete content.Meta[field];
      Object.assign(content.Meta, fieldValue);
    }
  });

  if (formData.DisplayType !== ContentDisplayType.LONGFORM) {
    content.Meta = { ...content.Meta, LongformAvatarUrl: '' };

    if (formData && formData.Meta && formData.Meta.LongformAvatarUrl) {
      delete formData.Meta.LongformAvatarUrl;
    }
  } else if (formData.LongformAvatar) {
    content.Meta = { ...content.Meta, LongformAvatarUrl: formData.LongformAvatar.url };
    delete formData.LongformAvatar;
  }

  content.Meta = JSON.stringify(content.Meta);

  content.extraData = Object.assign({}, content.extraData, {
    embededObjects: parseEmbededObjects(content.HtmlBody),
  });

  if (contentUtils.hasAttribute(content.Attributes, ContentAttribute.NO_ROYALTY)) {
    content.Royalty = '[]';
  }

  if (formData.contentAuthors) {
    const filteredList = (formData.contentAuthors || []).filter(value => {
      delete value.ContentID;
      const isManaged = value.isManaged;
      delete value.isManaged;

      return (isManaged && value.AuthorID !== 0) ||
        value.AuthorID > 0 ||
        (value.AuthorName && value.AuthorName.length > 0);
    });

    content.contentAuthors = JSON.stringify(lodash.uniqWith(filteredList, (a, b) => {
      if (a.AuthorID > 0 && b.AuthorID > 0) {
        return a.AuthorID === b.AuthorID;
      } else if (a.AuthorID === 0 && b.AuthorID === 0) {
        return a.AuthorName === b.AuthorName;
      }

      return false;
    }));
  }

  actionRegistry.doAction(ContentHookAction.FORMDATA_TO_CONTENT, { content, formData });

  return content;
}

export function loadContentForForm(contentId) {
  const getFields = [
    'Attributes',
    'Description',
    'Distributions',
    'Keywords',
    'Source',
    'SourceUrl',
    'Author',
    'RelatedContents',
    'RelatedTopics',
    'DisplayType',
    'ContentType',
    'AvatarDescription',
    'Meta',
  ];

  const lookup = [
    'ContentDetail.ContentID',
    'ContentDetail.HtmlBody',
    'ContentNote.ContentID',
    'ContentNote.NoteID',
    'ContentNote.Note',
    'ContentNote.CreatorID',
    'ContentNote.CreatedDate',
    'Royalty.RoyaltyID',
    'Royalty.ContentID',
    'Royalty.Money',
    'Royalty.Note',
    'SeoEntry.EntryID',
    'SeoEntry.Title',
    'SeoEntry.Description',
    'SeoEntry.Keywords',
    'ContentAuthor.AuthorID',
    'ContentAuthor.AuthorName',
  ];

  actionRegistry.doAction(ContentHookAction.BEFORE_GET_CONTENT, { getFields, lookup });

  return contentApi.get(contentId, { lookup: lookup.join(','), fields: getFields })
    .then(resp => resp.data.item)
    .then((content) => {
      content.HtmlBody = normalizeEditorValue(content.ContentDetail ? content.ContentDetail.HtmlBody : '');
      content.Meta = parseJson(content.Meta, {});
      return content;
    });
}

export function stringifyDistributions(distributions = []) {
  return JSON.stringify(
    distributions.filter(item => item.ZoneID).map(item => ({ Order: item.Order, ZoneID: item.ZoneID }))
  );
}

export function parseDistributions(distributionsString) {
  return JSON.parse(distributionsString).filter(item => item.ZoneID);
}

export function showContentFormError(error) {
  const invalidFields = Object.keys(error).map(key => ContentFieldLabel[key] || key).join(', ');
  message.error('Vui lòng kiểm tra lại các trường sau: ' + invalidFields);
}

export function handleSortedChangeByRangeDate(sorting, listProps) {
  if (sorting.length) {
    const rangeDate = listProps.filters.rangeDate;

    if (!rangeDate.range[0] || !rangeDate.range[1]) {
      rangeDate.range = [moment().add(-ContentDefaultDaysSort, 'day').startOf('day'), moment()];
    } else if (!isValidDateRangeToSort(rangeDate.range)) {
      message.error(`Không thể sắp xếp bài trong khoảng thời gian quá ${ContentMaxDaysSort} ngày`);
      return;
    }
  }

  listProps.onSortedChange(sorting);
}

export function handleLimitSortRangeDateChange(rangeDate, listProps) {
  if (!lodash.isEmpty(listProps.sorting) && !isValidDateRangeToSort(rangeDate.range)) {
    message.error(`Không thể sắp xếp bài trong khoảng thời gian quá ${ContentMaxDaysSort} ngày`);
    return;
  }

  listProps.addFilter('rangeDate', rangeDate);
}

function isValidDateRangeToSort(range) {
  if (!range[0] || !range[1]) {
    return false;
  }

  return range[1].diff(range[0], 'day') <= ContentMaxDaysSort;
}
