import { Form } from 'antd';
import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import AdsPositionSelectButton from 'core/components/ads-option/AdsPositionSelectButton';
import { getNameByPositionCodeCache } from 'core/engine/ads-position/caching';
import { useParsedMeta } from 'core/modules/operations/widgets/hooks';

export default function AdsWidgetFormItemMeta({ widget, form }) {
  const { getFieldDecorator } = form;
  const meta = useParsedMeta(widget);

  return (
    <Form.Item label="Vị trí quảng cáo">
      {getFieldDecorator('Meta.PositionCode', {
        initialValue: meta.PositionCode,
      })(
        <WidgetFormItemAdsInput />,
      )}
    </Form.Item>
  );
}

const WidgetFormItemAdsInput = forwardRef(({ value: positionCode, onChange }, ref) => {
  const [adsName, setAdsName] = useState();

  useEffect(() => {
    getNameByPositionCodeCache(positionCode)
      .then((name) => {
        setAdsName(name);
      });
  }, [positionCode]);

  return (
    <Fragment>
      <div>{adsName}</div>
      <AdsPositionSelectButton ref={ref} onDone={(val) => onChange(val.Code)} triggerByButton />
    </Fragment>
  );
});

