import React from 'react';
import { Button } from 'antd';
import useClipboard from 'core/hooks/useClipboard';

export default function CopyToClipboardButton({
  text,
  tooltip = 'Sao chép',
  children,
}) {
  const clipboard = useClipboard({ text });

  return (
    <div className={clipboard.className}>
      {children || <Button size="small" title={tooltip} icon="link" />}
    </div>
  );
}
