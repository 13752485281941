import { Button, message } from 'antd';
import { buildCdnPhotoUrl } from 'core/common/cdn';
import { emptyObject } from 'core/common/empty';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import BaseDialog from 'core/components/common/BaseDialog';
import FlexDiv from 'core/components/common/FlexDiv';
import FileChooserButton from 'core/components/file-manager/FileChooserButton';
import contentAvatarApi from 'core/engine/content-avatar/api';
import { ContentAvatarLabel, ContentAvatarSize, ContentAvatarType } from 'core/engine/content-avatar/constants';
import { getModifiedAvatarData } from 'core/engine/content-avatar/utils';
import useDialog from 'core/hooks/useDialog';
import React, { Fragment, useState } from 'react';
import DraggableBackgroundImage from './DraggableBackgroundImage';

const useStyles = makeStyles(() => ({
  avatar: {
    marginBottom: 10,
  },
  changeAvatarBtn: {
    marginLeft: 5,
    height: 20,
    fontSize: 12,
  },
}));

export default function ContentAvatarDialogButton({
  form,
  contentId,
  value = emptyObject,
  size = emptyObject,
  contentAvatars,
  setContentAvatars,
  originContentAvatars,
  setOriginContentAvatars,
}) {
  const classes = useStyles();
  const { open, handleOpen, handleClose } = useDialog();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Fragment>
      <Button
        icon="edit"
        size="small"
        title="Sửa khung ảnh"
        onClick={handleOpen}>
        Sửa khung ảnh
      </Button>
      <BaseDialog
        visible={open}
        title="Sửa khung ảnh"
        onCancel={handleCloseAvatarFrame}
        onOk={() => handleSaveAvatarFrame(contentAvatars, value.url)}
        confirmLoading={isLoading}
        okText="Lưu"
        width={650}
        fullHeight
      >
        {Object.values(ContentAvatarType).map((type) => {
          return (
            <div className={classes.avatar} key={type}>
              <FlexDiv>
                <p><b>{ContentAvatarLabel[type]}</b> ({ContentAvatarSize[type].width}x{ContentAvatarSize[type].height})</p>
                <FileChooserButton
                  title="Chọn ảnh đại diện"
                  triggerComponent={({ onClick }) =>
                    <Button
                      size="small"
                      icon="cloud-upload"
                      onClick={onClick}
                      className={classes.changeAvatarBtn}
                    >Chọn ảnh khác</Button>
                  }
                  onSelect={(file) => handleChangeAvatar(file, type)}
                />
              </FlexDiv>
              <DraggableBackgroundImage
                type={type}
                imageSrc={buildCdnPhotoUrl(lodash.get(contentAvatars, `[${type}].Meta.originImage`, value.url))}
                size={size}
                contentAvatars={contentAvatars}
                setContentAvatars={setContentAvatars}
              />
            </div>
          );
        })}
      </BaseDialog>
    </Fragment>
  );

  function handleSaveAvatarFrame(contentAvatars, url) {
    setIsLoading(true);

    getModifiedAvatarData(contentAvatars, url).then((modifiedAvatars) => {
      const uploadAvatars = modifiedAvatars.map((avatar, index) => {
        return avatar && {
          AvatarID: avatar.AvatarID,
          Type: index,
          Url: avatar.data,
          Position: (avatar.x || 0) + (avatar.y || 0),
          Width: ContentAvatarSize[index].width,
          Height: ContentAvatarSize[index].height,
          Meta: JSON.stringify(avatar.Meta),
          Enabled: false,
        };
      }).filter(Boolean);

      contentAvatarApi.upload(contentId, uploadAvatars).then(resp => {
        const uploadedAvatarIds = resp.data.items;
        const listContentAvatar = lodash.cloneDeep(contentAvatars);

        Object.values(ContentAvatarType).forEach(type => {
          if (!listContentAvatar[type]) {
            return;
          }

          listContentAvatar[type].isModified = false;

          if (uploadedAvatarIds[type]) {
            listContentAvatar[type].AvatarID = uploadedAvatarIds[type];
          }
        });

        setOriginContentAvatars(listContentAvatar);
        setContentAvatars(listContentAvatar);

        const avatarIds = listContentAvatar.filter(Boolean).map(avatar => avatar.AvatarID);
        form.setFieldsValue({ 'contentAvatarIds': avatarIds });
      }).then(() => {
        setIsLoading(false);
        message.success('Lưu ảnh thành công');
        handleClose();
      });
    });
  }

  function handleCloseAvatarFrame() {
    setContentAvatars(originContentAvatars);
    handleClose();
  }

  function handleChangeAvatar(file, position) {
    const listContentAvatar = lodash.cloneDeep(contentAvatars);

    const width = ContentAvatarSize[position].width;
    const height = ContentAvatarSize[position].height;

    const isScrollHorizontal = file.Meta.width / file.Meta.height > width / height;
    const meta = {
      width: file.Meta.width,
      height: file.Meta.height,
      originImage: file.Path,
    };

    const backgroundSize = {
      width: isScrollHorizontal ? (meta.width * height / meta.height) : width,
      height: isScrollHorizontal ? height : (meta.height * width / meta.width),
    };

    listContentAvatar[position] = {
      ...listContentAvatar[position],
      x: Math.round(isScrollHorizontal ? (-1 * (backgroundSize.width - width) / 2) : 0),
      y: 0,
      backgroundSize: backgroundSize,
      isScrollHorizontal: isScrollHorizontal,
      Meta: meta,
      isModified: true,
    };

    setContentAvatars(listContentAvatar);
  }
}
