import React, { useMemo } from 'react';
import { Modal } from 'antd';
import { isNullOrUndefined } from 'core/common/is';

export default function BaseDialog({
  children,
  title,
  footer,
  fullHeight = false,
  fullHeightFixed = false,
  noPadding = false,
  noHorizontalPadding = false,
  noVerticalPadding = false,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  destroyOnClose = true,
  keyboard = false,
  maskClosable = false,
  ...props
}) {
  const hasTitle = useMemo(() => Boolean(title), [title]);
  const hasFooter = useMemo(() => footer !== null, [footer]);

  const modalStyle = useMemo(() => {
    const style = {};

    if (fullHeightFixed) {
      Object.assign(style, {
        paddingBottom: 0,
      });
    }

    return style;
  }, [fullHeightFixed]);

  const bodyStyle = useMemo(() => {
    const style = {};
    let heightAdjust = 0;

    if (hasTitle) {
      heightAdjust += 55;
    }

    if (hasFooter) {
      heightAdjust += 53;
    }

    if (fullHeight || fullHeightFixed) {
      heightAdjust += 20;
    }

    const heightCalc = `calc(100vh - ${heightAdjust}px)`;

    if (fullHeight) {
      Object.assign(style, {
        maxHeight: 'calc(100vh - 250px)',
        overflow: 'auto',
      });
    }

    if (fullHeightFixed) {
      Object.assign(style, {
        height: heightCalc,
        overflow: 'auto',
      });
    }

    if (noPadding) {
      Object.assign(style, {
        padding: 0,
      });
    }

    if (noHorizontalPadding) {
      Object.assign(style, {
        paddingLeft: 0,
        paddingRight: 0,
      });
    }

    if (noVerticalPadding) {
      Object.assign(style, {
        paddingTop: 0,
        paddingBottom: 0,
      });
    }

    if (!isNullOrUndefined(paddingTop)) {
      Object.assign(style, {
        paddingTop,
      });
    }

    if (!isNullOrUndefined(paddingBottom)) {
      Object.assign(style, {
        paddingBottom,
      });
    }

    if (!isNullOrUndefined(paddingLeft)) {
      Object.assign(style, {
        paddingLeft,
      });
    }

    if (!isNullOrUndefined(paddingRight)) {
      Object.assign(style, {
        paddingRight,
      });
    }

    return style;
  }, [fullHeight, fullHeightFixed, noPadding, noHorizontalPadding, noVerticalPadding, paddingTop, paddingBottom, paddingLeft, paddingRight, hasTitle, hasFooter]);

  return (
    <Modal
      title={title}
      footer={footer}
      style={modalStyle}
      bodyStyle={bodyStyle}
      destroyOnClose={destroyOnClose}
      keyboard={keyboard}
      maskClosable={maskClosable}
      centered
      {...props}
    >{children}</Modal>
  );
}
