import BaseDialog from 'core/components/common/BaseDialog';
import { Form, Input } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import useDialog from 'core/hooks/useDialog';
import makeStyles from 'core/common/makeStyles';
import { durationStringToSeconds, secondsToDurationString } from 'core/common/utils';
import useForm from 'core/hooks/useForm';
import commonStyles from 'core/common/commonStyles';
import { getYoutubeId } from 'core/common/buildEmbedCode';

const useStyles = makeStyles(() => ({
  duration: {
    width: 100,
  },
  preview: {
    textAlign: 'center',
    marginTop: 20,
    border: '1px solid #e5e5e5',
    padding: 10,
    borderRadius: 3,
    display: 'block',
    margin: 'auto',
  },
  message: {
    color: '#c7254e',
    marginLeft: 10,
  },
}));

export default forwardRef(function ContentFormItemVideoUrlDialog({ value, onDone }, ref) {
  const classes = useStyles();
  const { open, handleClose, handleOpen } = useDialog();
  const { formData, setFormData, handleChange } = useForm();

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  const validDuration = useMemo(() => {
    return durationStringToSeconds(formData.duration) > 0;
  }, [formData.duration]);

  const validUrl = useMemo(() => {
    return /^https?:\/\//.test(formData.url);
  }, [formData.url]);

  useEffect(() => {
    setFormData({ url: value.url || '', duration: value.duration ? secondsToDurationString(value.duration) : '' });
  }, [value]);

  return (
    <BaseDialog
      keyboard
      maskClosable
      visible={open}
      title="Nhập đường dẫn cho Video đại diện"
      width={900}
      okButtonProps={{ disabled: !(validUrl && validDuration) }}
      onCancel={handleClose}
      onOk={handleOk}
      fullHeight
    >
      <Form {...commonStyles.formItemLayout}>
        <Form.Item label="Đường dẫn video">
          <Input.TextArea
            placeholder="Nhập đường dẫn video..."
            name="url"
            value={formData.url}
            onChange={handleChange}
            autoFocus
            autoSize
            maxLength={250}
          />
        </Form.Item>
        <Form.Item label="Thời lượng">
          <Input
            name="duration"
            value={formData.duration}
            className={classes.duration}
            placeholder="hh:mm:ss"
            onChange={handleChange}
          />
          {validDuration ? '' :
            <span className={classes.message}>Thời lượng phải theo định dạng hh:mm:ss</span>}
        </Form.Item>
      </Form>
      {validUrl && (
        <iframe className={classes.preview} src={parseYoutubeUrl(formData.url)} title="videoAvatar" width={600} height={300} />
      )}
    </BaseDialog>
  );

  function handleOk() {
    handleClose();
    onDone(parseYoutubeUrl(formData.url), durationStringToSeconds(formData.duration));
  }
});

function parseYoutubeUrl(url) {
  const videoId = getYoutubeId(url);

  return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
}
