import { Form } from 'antd';
import ContentFormAvatarInput from 'core/modules/contents/components/form/input/ContentFormAvatarInput';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React, { useMemo } from 'react';

export default function ContentFormItemAvatar({ content, form }) {
  const { getFieldDecorator } = form;

  const normalizedAvatar = useMemo(() => {
    return {
      url: content.AvatarUrl,
      description: content.AvatarDescription,
    };
  }, [content]);

  return (
    <Form.Item label={ContentFieldLabel.avatar}>
      {getFieldDecorator('avatar', {
        initialValue: normalizedAvatar,
        rules: [
          { validator: avatarValidator },
        ],
      })(
        <ContentFormAvatarInput />
      )}
    </Form.Item>
  );
}

function avatarValidator(rule, value, callback) {
  if (!value || !value.url) {
    return callback(new Error('Bắt buộc!'));
  }

  if (value.url.length > 250) {
    return callback(new Error('Tối đa 250 kí tự!'));
  }

  if (value && value.error) {
    return callback(new Error('Đường dẫn ảnh không chính xác!'));
  }

  callback();
}
