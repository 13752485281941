import React from 'react';
import Container from 'core/components/common/Container';
import GroupList from './GroupList';
import GroupFormDialogButton from './GroupFormDialogButton';
import groupApi from 'core/engine/group/api';
import { sortingToSortBy } from 'core/common/listUtils';
import useList from 'core/hooks/useList';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import { asyncAction } from 'core/common/async';
import useListProps from 'core/hooks/useListProps';

function listFn({ limit, offset, filters, sorting }) {
  return groupApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default function GroupListView() {

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: {
      GroupID: 'ASC',
    },
  });

  return (
    <Container pageTitle="Quản lý nhóm người dùng">
      <TableToolbarWrapper>
        <GroupFormDialogButton isEditMode={false} onDone={listProps.refresh} />
      </TableToolbarWrapper>
      <GroupList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdate={listProps.refresh}
        onDelete={handleDelete}
      />
    </Container>
  );

  function handleDelete(group) {
    return asyncAction('Xóa nhóm người dùng', () => {
      return groupApi.delete(group.GroupID).then(() => {
        listProps.removeItem(group, 'GroupID');
      });
    });
  }
}
