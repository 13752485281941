import { Button, Form } from 'antd';
import React, { Fragment, useMemo, useRef } from 'react';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import InterviewForm from './InterviewForm';
import interviewApi from 'core/engine/interview/api';
import { emptyObject } from 'core/common/empty';

function InterviewFormDialogButton({ form, interview = emptyObject, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });
  const editorRef = useRef();

  const title = useMemo(() => isEditMode ? 'Sửa giao lưu trực tuyến' : 'Tạo giao lưu trực tuyến', [isEditMode]);

  return (
    <Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={title}
        onClick={handleOpen}
      >
        {isEditMode ? '' : title}
      </Button>
      <BaseDialog
        visible={open}
        title={title}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        fullHeightFixed
        width={900}
        confirmLoading={isSubmitting}
      >
        <InterviewForm form={form} interview={interview} editorRef={editorRef} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    if (formData.StartDate) {
      formData.StartDate = formData.StartDate.toDate().getTime();
    }

    if (formData.EndDate) {
      formData.EndDate = formData.EndDate.toDate().getTime();
    }

    formData.Description = editorRef.current.getNormalizedValue();

    if (isEditMode) {
      return asyncAction(title, () => {
        return interviewApi.update(formData)
          .then(handleDone);
      });
    }

    return asyncAction(title, () => {
      return interviewApi.create(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'InterviewFormDialogButton' })(InterviewFormDialogButton);
