import { green } from '@ant-design/colors';
import { Input, Typography } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { emptyObject } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import FlexDiv from 'core/components/common/FlexDiv';
import settingApi from 'core/engine/setting/api';
import { SettingSystemKeys } from 'core/engine/setting/constants';
import useDataLoader from 'core/hooks/useDataLoader';
import useForm from 'core/hooks/useForm';
import React, { forwardRef, Fragment, useEffect } from 'react';

const defaultAutoSize = { minRows: 1, maxRows: 4 };

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
    marginBottom: 10,
    padding: '0 10px 10px 10px',
    border: '1px solid #0088cc',
  },
  title: {
    backgroundColor: '#0088cc',
    textAlign: 'center',
    color: '#fff',
    lineHeight: 'initial',
    padding: 8,
    margin: '0 -10px 10px -10px',
  },
  helper: {
    ...commonStyles.flexRow,
    justifyContent: 'space-between',
    lineHeight: 'initial',
    fontSize: 'small',
    color: '#d14',
    marginBottom: 10,
  },
  length: {
    color: green[7],
  },
}));

function loadFn() {
  return settingApi.multiGet([
    SettingSystemKeys.LimitedTitleCharacter,
    SettingSystemKeys.LimitedDescCharacter,
    SettingSystemKeys.LimitedSEOTitleCharacter,
    SettingSystemKeys.LimitedSEODescCharacter,
    SettingSystemKeys.DescriptionPrefix,
  ])
    .then((resp) => {
      return resp.data.reduce((previous, current) => {
        previous[current.Key] = current.Value;
        return previous;
      }, {});
    });
}

export default forwardRef(function ContentFormItemSeoEntryInput({
  value: seoEntry = emptyObject,
  onChange,
  showTitle = true,
  showDescription = true,
  showKeywords = true,
}, ref) {
  const classes = useStyles();

  const { data: config } = useDataLoader({ loadFn, defaultData: {} });

  const { formData, setFormData, handleChange } = useForm();

  useEffect(() => {
    setFormData(seoEntry);
  }, [seoEntry]);

  return (
    <div ref={ref} className={classes.container}>
      <Typography.Text className={classes.title}>Vùng dành cho SEO</Typography.Text>
      {showTitle && (
        <Fragment>
          <Input.TextArea
            name="Title"
            value={formData.Title}
            onChange={handleChange}
            placeholder="SEO Title"
            autoSize={defaultAutoSize}
            maxLength={config[SettingSystemKeys.LimitedSEOTitleCharacter] || 75}
            onBlur={handleBlur}
          />
          <FlexDiv className={classes.helper}>
            <span>Giật tít hấp dẫn, khác với title hiển thị ở trên.</span>
            <span className={classes.length}>{(formData.Title || '').length} / {config[SettingSystemKeys.LimitedSEOTitleCharacter] || 75} ký tự</span>
          </FlexDiv>
        </Fragment>
      )}
      {showDescription && (
        <Fragment>
          <Input.TextArea
            name="Description"
            value={formData.Description}
            onChange={handleChange}
            placeholder="SEO Description"
            autoSize={defaultAutoSize}
            maxLength={config[SettingSystemKeys.LimitedSEODescCharacter] || 160}
            onBlur={handleBlur}
          />
          <FlexDiv className={classes.helper}>
            <span>Giới thiệu nội dung hấp dẫn nhất trong tin bài.</span>
            <span className={classes.length}>{(formData.Description || '').length} / {config[SettingSystemKeys.LimitedSEODescCharacter] || 160} ký tự</span>
          </FlexDiv>
        </Fragment>
      )}
      {showKeywords && (
        <Fragment>
          <Input.TextArea
            name="Keywords"
            value={formData.Keywords}
            onChange={handleChange}
            placeholder="SEO Keywords"
            autoSize={defaultAutoSize}
            onBlur={handleBlur}
            maxLength={1000}
          />
          <FlexDiv className={classes.helper}>
            <span>Nhập các cụm từ khóa liên quan đến sự kiện, tin bài.</span>
          </FlexDiv>
        </Fragment>
      )}
    </div>
  );

  function handleBlur() {
    onChange(formData);
  }
});
