import { httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_FIELDS = [
  'InterviewID',
  'Title',
  'Description',
  'StartDate',
  'EndDate',
  'Enabled',
  'Admins',
  'Editors',
];

const DEFAULT_LOOKUP = [];

const interviewApi = {
  get(id) {
    return httpGet(`/interviews/get/item?InterviewID=${id}`);
  },

  getList({ limit = 20, offset = 0, sortBy, fields = [], lookup = [], filters }) {
    const qs = {
      limit,
      offset,
      sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      lookup: [...DEFAULT_LOOKUP, ...lookup],
      ...filters,
    };

    return httpGet('/interviews/get/items-by-range', { qs });
  },

  create(data) {
    return httpPost('/interviews/post/insert', data);
  },

  update(data) {
    return httpPost('/interviews/post/update', data);
  },
};

export default interviewApi;
