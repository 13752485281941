export const EditorHookAction = {
  BEFORE_RENDER_EDITOR: 'EDITOR:BEFORE_RENDER_EDITOR',
};

export const EditorButtonPreset = {
  SIMPLE: [
    'cut', 'copy', 'paste', '|',
    'undo', 'redo', '|',
    'link', '|',
    'subscript', 'superscript', '|',
    'bold', 'italic', 'underline', 'strikethrough',
  ],
  FULL: [
    'source', '|',
    'cut', 'copy', 'paste', '|',
    'undo', 'redo', '|',
    'image', 'video', 'file', '|',
    'linkContent', 'template', 'BeforeAfterImage', 'gallery', '|',
    'vote',
    'table', 'link', 'unlink', 'embed', '|',
    'subscript', 'superscript', 'paragraph', '|',
    'bold', 'italic', 'underline', 'strikethrough', '|',
    'left', 'right', 'center', 'justify', '|',
    'outdent', 'indent', '|',
    'ul', 'ol', '|',
    'SpellChecker', 'eraser', 'reformat', 'fullsize',
  ],
};
