import { Button, Form } from 'antd';
import { asyncAction } from 'core/common/async';
import { emptyObject, emptyFunction } from 'core/common/empty';
import BaseDialog from 'core/components/common/BaseDialog';
import albumApi from 'core/engine/album/api';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import React, { Fragment } from 'react';
import { FileManagerAlbumForm } from './FileManagerAlbumForm';

function FileManagerAlbumDialogButton({ form, anchorElement: AnchorElement, album = emptyObject, onDone = emptyFunction, isEditMode = true }) {

  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  const button = AnchorElement ? (
    <AnchorElement onClick={handleOpen} loading={isSubmitting} />
  ) : (
    <Button
      title={isEditMode ? 'Sửa album' : 'Tạo album'}
      icon={isEditMode ? 'edit' : 'plus'}
      onClick={handleOpen}
      type="primary"
    >
      {isEditMode ? '' : 'Tạo album'}
    </Button>
  );

  return (
    <Fragment>
      {button}
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa album' : 'Tạo album'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText={isEditMode ? 'Sửa' : 'Tạo'}
        confirmLoading={isSubmitting}
        keyboard={!form.isFieldsTouched()}
        maskClosable={!form.isFieldsTouched()}
        width={600}
        fullHeight
      >
        <FileManagerAlbumForm form={form} onSubmit={handleSubmit} album={album} isEditMode={isEditMode} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    if (isEditMode) {
      return asyncAction('Sửa album', () => {
        return albumApi.update(formData)
          .then(() => albumApi.get(album.AlbumID))
          .then(resp => resp.data.item)
          .then(handleDone);
      });
    }

    return asyncAction('Tạo album', () => {
      return albumApi.create(formData)
        .then(resp => albumApi.get(resp.data.generatedId))
        .then(resp => resp.data.item)
        .then(handleDone);
    });
  }

  function handleDone(data) {
    handleClose();
    onDone(data);
  }

}

export default Form.create({ name: 'FileManagerAlbumDialogButton' })(FileManagerAlbumDialogButton);
