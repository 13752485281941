import { httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_FIELDS = [
  'QuestionID',
  'InterviewID',
  'Guests',
  'Question',
  'UserDisplayName',
  'UserEmail',
  'Date',
  'Status',
];

const DEFAULT_LOOKUP = [];

const interviewQuestionApi = {
  getList({ limit = 20, offset = 0, sortBy, fields = [], lookup = [], filters }) {
    const qs = {
      limit,
      offset,
      sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      lookup: [...DEFAULT_LOOKUP, ...lookup],
      ...filters,
    };

    return httpGet('/interview-questions/get/items-by-range', { qs });
  },

  create(data) {
    return httpPost('/interview-questions/post/insert', data);
  },

  update(data) {
    return httpPost('/interview-questions/post/update', data);
  },

  delete(id) {
    return httpPost('/interview-questions/post/delete', { QuestionID: id });
  },
};

export default interviewQuestionApi;
