import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'core/common/classnames';
import { Button } from 'antd';
import makeStyles from 'core/common/makeStyles';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    flex: '0.5 1 !important',
  },
  center: {
    overflowX: 'auto',
    flexWrap: 'nowrap !important',
    justifyContent: 'unset !important',
    '& > :first-child': {
      paddingLeft: 10,
    },
    '& > *': {
      margin: '0px auto !important',
      paddingRight: 10,
    },
  },
  totalText: {
    whiteSpace: 'nowrap',
  },
  input: {
    padding: '0 7px !important',
  },
  pagination: {
    padding: '0 !important',
    boxShadow: 'none !important',
  },
}));

function defaultButton(props) {
  return (
    <button type="button" {...props} className="-btn">
      {props.children}
    </button>
  );
}

export default function TablePagination({
  pages,
  style,
  page,
  showPageSizeOptions,
  pageSizeOptions,
  pageSize,
  showPageJump,
  canPrevious,
  canNext,
  onPageSizeChange,
  onPageChange,
  className,
  PreviousComponent = defaultButton,
  NextComponent = defaultButton,
  previousText,
  nextText,
  pageText,
  ofText,
  rowsText,
  showTotal,
  totalText,
  total,
  refreshText,
  showRefreshButton,
  onRefresh,
}) {
  const classes = useStyles();
  const [pageState, setPageState] = useState(page);
  const rowCount = useMemo(() => typeof total === 'undefined' ? 0 : total, [total]);

  useEffect(() => setPageState(page), [page]);

  return (
    <div className={classnames(className, '-pagination', classes.pagination)} style={style}>
      <div className={classnames(classes.buttonContainer, '-previous')}>
        <PreviousComponent
          onClick={() => {
            if (!canPrevious) return;
            changePage(page - 1);
          }}
          disabled={!canPrevious}
          className={classes.button}
        >
          {previousText}
        </PreviousComponent>
      </div>
      <div className={classnames('-center', classes.center)}>
        {showTotal && !!rowCount && (
          <span className={classes.totalText}>
            {totalText + ': ' + rowCount}
          </span>
        )}

        <span className="-pageInfo">
          {pageText}&nbsp;
          {showPageJump ? (
            <div className="-pageJump">
              <input
                className={classes.input}
                type={pageState === '' ? 'text' : 'number'}
                onChange={e => {
                  const val = e.target.value;
                  const newPage = val - 1;

                  if (val === '') {
                    return setPageState(val);
                  }

                  setPageState(getSafePage(newPage));
                }}
                value={pageState === '' ? '' : pageState + 1}
                onBlur={applyPage}
                onKeyPress={e => {
                  if (e.which === 13 || e.keyCode === 13) {
                    applyPage();
                  }
                }}
              />
            </div>
          ) : (
            <span className="-currentPage">
              {page + 1}
            </span>
          )}
          &nbsp;{ofText}&nbsp;
          <span className="-totalPages">{pages || 1}</span>
        </span>

        {showPageSizeOptions && (
          <span className="-pageSizeOptions">
            <select
              onChange={e => onPageSizeChange(Number(e.target.value))}
              value={pageSize}
              className={classes.input}
            >
              {pageSizeOptions.map((option, i) => (
                <option key={i} value={option}>
                  {option} {rowsText}
                </option>
              ))}
            </select>
          </span>
        )}

        {showRefreshButton && (
          <span>
            <Button onClick={onRefresh} icon="reload" title={refreshText} size="small" />
          </span>
        )}
      </div>
      <div className={classnames(classes.buttonContainer, '-next')}>
        <NextComponent
          onClick={() => {
            if (!canNext) return;
            changePage(page + 1);
          }}
          disabled={!canNext}
        >
          {nextText}
        </NextComponent>
      </div>
    </div>
  );

  function getSafePage(pageValue) {
    return Math.min(Math.max(isNaN(pageValue) ? page : pageValue, 0), pages - 1);
  }

  function changePage(value) {
    const newPage = getSafePage(value);
    setPageState(newPage);

    if (page !== newPage) {
      onPageChange(newPage);
    }
  }

  function applyPage(event) {
    if (event) {
      event.preventDefault();
    }

    changePage(pageState === '' ? page : pageState);
  }
}
