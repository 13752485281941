import { IS_MOBILE } from '@bm/common';
import AccountDisplay from 'core/components/account/AccountDisplay';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import DeleteButton from 'core/components/common/DeleteButton';
import CheckableTable from 'core/components/table/CheckableTable';
import React, { Fragment } from 'react';
import LiveStreamAuthzCheck from './LiveStreamAuthzCheck';
import LiveStreamDisplay from './LiveStreamDisplay';
import LiveStreamFormDialogButton from './LiveStreamFormDialogButton';
import LiveStreamIcon from './LiveStreamIcon/LiveStreamIcon';
import LiveStreamLogDialogButton from './log/LiveStreamLogDialogButton';

export default function LiveStreamList({
  onUpdate,
  onDelete,
  ...props
}) {
  const columns = [
    !IS_MOBILE && {
      width: 80,
      Cell: ({ original }) => <LiveStreamIcon typeName={original.typeName} />,
    },
    {
      Header: 'Nội dung',
      id: 'HtmlDetail',
      Cell: ({ original }) => <LiveStreamDisplay liveStream={original} />,
    },
    {
      Header: 'Người tường thuật',
      headerStyle: { justifyContent: 'center' },
      style: { flexDirection: 'column' },
      Cell: ({ original }) => (
        <Fragment>
          <AccountDisplay id={original.EditorID} />
          <DateTimeDisplay timestamp={original.ModifiedDate} />
        </Fragment>
      ),
      width: 130,
    },
    {
      id: 'Date',
      Header: 'Thời gian',
      sortable: true,
      show: !IS_MOBILE,
      Cell: ({ original }) => <DateTimeDisplay timestamp={original.Date} />,
      maxWidth: 150,
    },
    {
      Cell: ({ original }) => (
        <LiveStreamAuthzCheck liveStream={original}>
          {({ canEdit, canDelete, canViewLog }) => (
            <ActionsCellWrapper>
              <LiveStreamFormDialogButton
                disabled={!canEdit}
                liveStream={original}
                onDone={onUpdate}
              />
              <DeleteButton
                disabled={!canDelete}
                title="Xóa nội dung tường thuật?"
                onDelete={() => onDelete(original)}
              />
              {canViewLog && (
                <LiveStreamLogDialogButton liveStreamId={original.StreamID} />
              )}
            </ActionsCellWrapper>
          )}
        </LiveStreamAuthzCheck>
      ),
      width: 140,
    },
  ].filter(Boolean);

  return (
    <CheckableTable
      idField="StreamID"
      columns={columns}
      showScroll={false}
      highlight={false}
      {...props}
    />
  );
}
