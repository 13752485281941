import striptags from 'striptags';
import { trimHtml } from '@bm/common';

export function formatNumber(num, separator = ',') {
  return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + separator);
}

export function countChar(str) {
  return (str || '').length;
}

export function countCharAfterStripHtml(html) {
  return countChar(trimHtml(html || ''));
}

export function countWord(str) {
  return (str || '').split(/[\s\n\r\t]+/).filter(text => text && ' .,:;-_\'"+-=!@#$%^&`'.indexOf(text) < 0).length;
}

export function countWordAfterStripHtml(html) {
  return countWord(striptags(html || '', [], ' '));
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function stripXss(html) {
  const domparser = new DOMParser();
  const doc = domparser.parseFromString(html || '', 'text/html');

  if (doc.getElementsByTagName('parsererror').length) {
    return '';
  }

  doc.body.querySelectorAll('*').forEach((node) => {
    if (node.tagName === 'SCRIPT' || node.tagName === 'BASE') {
      node.remove();
    } else {
      Array.prototype.forEach.call(node.attributes, (attr) => {
        if (attr.name.toLowerCase().startsWith('on')
                  || attr.value.toLowerCase().replace(/\\0|\s+/g, '').startsWith('javascript')) {
          node.removeAttribute(attr.name);
        }
      });
    }
  });

  return doc.body.innerHTML;
}
