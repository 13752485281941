import { IS_MOBILE } from '@bm/common';
import { commonColumns } from 'core/common/listUtils';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import AvatarDisplay from 'core/components/common/AvatarDisplay';
import BaseTable from 'core/components/table/BaseTable';
import React from 'react';
import AuthorFormDialogButton from './AuthorFormDialogButton';
import AuthorContentListButtonForList from './content-authors/AuthorContentListButtonForList';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

export default function AuthorList({ onUpdated, ...props }) {
  const columns = [
    {
      show: !IS_MOBILE,
      Header: 'Ảnh',
      accessor: 'AvatarUrl',
      width: 64,
      Cell: ({ value }) => (<AvatarDisplay url={value} />),
      ...commonColumns.allCentered,
    },
    {
      Header: 'Tên',
      accessor: 'FullName',
      sortable: true,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      Header: 'Mô tả',
      accessor: 'Description',
      minWidth: 300,
    },
    {
      Header: 'Trạng thái',
      accessor: 'Enabled',
      Cell: ({ original }) => original.Enabled ? 'Hoạt động' : 'Không hoạt động',
      maxWidth: 250,
      minWidth: 150,
      ...commonColumns.allCentered,
    },
    {
      Header: 'Tác giả gốc',
      accessor: 'Parent.FullName',
      Cell: ({ original }) => original.Parent ? original.Parent.FullName : '#',
      minWidth: 200,
      ...commonColumns.allCentered,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          {original.ParentID === 0 ? (
            <Link to={'/operations/authors?authorId=' + original.AuthorID}>
              <Button
                title="Danh sách tác giả con"
                icon="ordered-list"
              />
            </Link>
          )
            : (
              <Button
                disabled={true}
                title="Danh sách tác giả con"
                icon="ordered-list"
              />
            )
          }
          <AuthorContentListButtonForList author={original} />
          <AuthorFormDialogButton author={original} onDone={onUpdated} />
        </ActionsCellWrapper>
      ),
      width: 150,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
