import React, { forwardRef, useMemo } from 'react';
import makeStyles from 'core/common/makeStyles';
import FlexDiv from 'core/components/common/FlexDiv';
import { Button, Dropdown, Menu, Typography } from 'antd';
import commonStyles from 'core/common/commonStyles';
import ContentFormItemAuthorInput from './ContentFormItemAuthorInput';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  list: {
    '& > div:not(:last-child)': {
      marginBottom: 5,
    },
    '&:not(:last-child) > div:last-child': {
      marginBottom: 20,
    },
  },
}));

const AuthorType = {
  MANAGED: 'managed_author',
  FREE: 'free_author',
};

export default forwardRef(function ContentFormItemAuthorMultipleInput({
  value = [],
  onChange,
  defaultAuthors,
}, ref) {
  const classes = useStyles();

  const { managedAuthors, freeAuthors } = useMemo(() => {
    const freeAuthors = [];
    const managedAuthors = [];

    value.forEach((contentAuthor) => {
      if (contentAuthor.isManaged || contentAuthor.AuthorID > 0) {
        managedAuthors.push(contentAuthor);
      } else {
        freeAuthors.push(contentAuthor);
      }
    });

    return { freeAuthors, managedAuthors };
  }, [value]);

  return (
    <div ref={ref} className={classes.container}>
      <FlexDiv className={classes.header}>
        <span>Tác giả :</span>
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu onClick={handleClick}>
              <Menu.Item key={AuthorType.MANAGED}>Tác giả được quản lý</Menu.Item>
              <Menu.Item key={AuthorType.FREE}>Tác giả vãng lai</Menu.Item>
            </Menu>
          )}
        >
          <Button
            title="Thêm tác giả"
            type="primary"
            shape="circle"
            size="small"
            icon="plus"
          />
        </Dropdown>
      </FlexDiv>
      {managedAuthors.length > 0 ? (
        <div className={classes.list}>
          {managedAuthors.map((author, index) => (
            <ContentFormItemAuthorInput
              key={index}
              value={author}
              onChange={val => handleChange(AuthorType.MANAGED, val, index)}
              onDelete={() => handleDelete(AuthorType.MANAGED, index)}
              defaultAuthors={defaultAuthors}
            />
          ))}
        </div>
      ) : null}
      {freeAuthors.length > 0 ? (
        <div className={classes.list}>
          {freeAuthors.map((author, index) => (
            <ContentFormItemAuthorInput
              free
              key={index}
              value={author}
              onChange={val => handleChange(AuthorType.FREE, val, index)}
              onDelete={() => handleDelete(AuthorType.FREE, index)}
            />
          ))}
        </div>
      ) : null}
      {freeAuthors.length === 0 && managedAuthors.length === 0 ? (
        <Typography.Text type="secondary"><i>Chưa có tác giả</i></Typography.Text>
      ) : null}
    </div>
  );

  function handleClick(event) {
    if (event.key === AuthorType.MANAGED) {
      onChangeManaged([...managedAuthors, { AuthorID: 0, isManaged: true }]);
    } else if (event.key === AuthorType.FREE) {
      onChangeFree([...freeAuthors, { AuthorID: 0, AuthorName: '' }]);
    }
  }

  function onChangeManaged(newManaged) {
    onChange([...newManaged, ...freeAuthors]);
  }

  function onChangeFree(newFree) {
    onChange([...managedAuthors, ...newFree]);
  }

  function handleChange(type, val, index) {
    const newList = (type === AuthorType.MANAGED ? managedAuthors : freeAuthors).slice();
    newList.splice(index, 1, val);
    const changeFn = type === AuthorType.MANAGED ? onChangeManaged : onChangeFree;
    changeFn(newList);
  }

  function handleDelete(type, index) {
    const newList = (type === AuthorType.MANAGED ? managedAuthors : freeAuthors).slice();
    newList.splice(index, 1);
    const changeFn = type === AuthorType.MANAGED ? onChangeManaged : onChangeFree;
    changeFn(newList);
  }
});
