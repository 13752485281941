import { httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_FIELDS = [
  'AnswerID',
  'InterviewID',
  'GuestID',
  'QuestionID',
  'HtmlAnswer',
  'Date',
  'Status',
];

const DEFAULT_LOOKUP = [
  'InterviewGuest.GuestID',
  'InterviewGuest.Name',
  'InterviewQuestion.QuestionID',
  'InterviewQuestion.UserName',
  'InterviewQuestion.Question',
];

const interviewAnswerApi = {
  getList({ limit = 20, offset = 0, sortBy, fields = [], lookup = [], filters }) {
    const qs = {
      limit,
      offset,
      sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      lookup: [...DEFAULT_LOOKUP, ...lookup],
      ...filters,
    };

    return httpGet('/interview-answers/get/items-by-range', { qs });
  },

  create(data) {
    return httpPost('/interview-answers/post/insert', data);
  },

  update(data) {
    return httpPost('/interview-answers/post/update', data);
  },

  delete(id) {
    return httpPost('/interview-answers/post/delete', { AnswerID: id });
  },

  multiApprove(ids) {
    return httpPost('/interview-answers/post/multi-approve', { answerIds: ids });
  },
};

export default interviewAnswerApi;
