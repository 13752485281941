import { Input } from 'antd';
import React from 'react';
import SettingForm from './SettingForm';

const settings = [
  { separator: 'Desktop' },
  {
    key: 'StringConfig_WebDesktopScript',
    label: 'Main Script',
    input: <Input />,
  },
  {
    key: 'StringConfig_WebDesktopStyle',
    label: 'Main CSS',
    input: <Input />,
  },
  {
    key: 'StringConfig_WebDesktopBannerScript',
    label: 'Banner Script',
    input: <Input />,
  },
  { separator: 'Mobile' },
  {
    key: 'StringConfig_WebMobileScript',
    label: 'Main Script',
    input: <Input />,
  },
  {
    key: 'StringConfig_WebMobileStyle',
    label: 'Main CSS',
    input: <Input />,
  },
  {
    key: 'StringConfig_WebMobileBannerScript',
    label: 'Banner Script',
    input: <Input />,
  },
];

export default function SettingWebForm({ form, onSubmit }) {
  return (
    <SettingForm form={form} onSubmit={onSubmit} settings={settings} />
  );
}
