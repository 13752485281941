import { Checkbox } from 'antd';
import React from 'react';
import { isNullOrUndefined } from 'core/common/is';

export default function CheckboxFilterForList({ filter, onChange }) {
  return (
    <Checkbox
      indeterminate={!filter}
      checked={filter ? Boolean(filter.value) : false}
      onChange={handleChange}
    />
  );

  function handleChange() {
    if (!filter || isNullOrUndefined(filter.value)) {
      onChange(true);
    } else if (filter.value) {
      onChange(false);
    } else {
      onChange();
    }
  }
}
