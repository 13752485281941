export const ExtendedRight = {
  DELETE: 'delete',
  UNPUBLISH: 'unpublish',
  FORCE_CHECKOUT: 'force-checkout',
  READ_VIEW_COUNT: 'read-view-count',
  READ_CONTENT_RATE: 'read-content-rate',
  READ_GA_VIEW_COUNT: 'read-ga-view-count',
  VIEW_DASHBOARD: 'view-dashboard',
  CONTENT_MORAT: 'content.morat',
};
