import React, { Fragment, useEffect, useMemo } from 'react';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import AccountSelector from 'core/components/account/AccountSelector';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateWithTypeSelector from 'core/components/common/RangeDateWithTypeSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import useContentColumns from 'core/hooks/useContentColumns';
import SelectableTable from 'core/components/table/SelectableTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import contentApi from 'core/engine/content/api';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import useContentFilterHandlers from 'core/modules/contents/hooks/useContentFilterHandlers';
import ContentDisplayTypeSelector from 'core/components/content/ContentDisplayTypeSelector';
import { Button } from 'antd';

const defaultRangeDate = [null, null];

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;
  const listSorting = { [rangeDate.type]: rangeDate.desc ? 'DESC' : 'ASC', ...sorting };

  if (rangeDate.range[0] && rangeDate.range[1]) {
    listFilters[rangeDate.type] = dateRangeToFilter(rangeDate.range);
  }

  delete listFilters.rangeDate;

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return contentApi.getDeployedList({
    limit,
    offset,
    sortBy: sortingToSortBy(listSorting),
    fields: ['ViewCount'],
    filters: listFilters,
  });
}

const SEARCH_TYPE_OPTIONS = [
  { label: 'Tiêu đề', value: 'Title' },
  { label: 'Tác giả', value: 'Author' },
];

export default function TopicSelectableContentList({ selected, onSelect, onSelectAll, onUnselect }) {
  const listProps = useList({
    listFn,
    defaultPageSize: 20,
    defaultFilters: {
      search: { type: SEARCH_TYPE_OPTIONS[0].value },
      rangeDate: { type: 'DistributionDate', range: defaultRangeDate, desc: true },
    },
  });
  const ContentColumns = useContentColumns();

  const columns = [
    ContentColumns.Avatar,
    ContentColumns.Title,
    ContentColumns.DistributionDate,
    ContentColumns.ViewCount,
  ];

  const filterHandlers = useContentFilterHandlers(listProps);

  useEffect(() => {
    setTimeout(listProps.refresh, 300); // after tab transition
  }, []);

  const selectedIds = useMemo(() => {
    return selected.map(item => item.ContentID);
  }, [selected]);

  return (
    <Fragment>
      <TableToolbarWrapper>
        <ZoneSelector
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
          allowClear
        />
        <AccountSelector
          emptyLabel="-- Người tạo --"
          value={listProps.filters.WriterID}
          onChange={filterHandlers.onWriterFilter}
          allowClear
        />
        <FlexPushRight />
        <RangeDateWithTypeSelector
          value={listProps.filters.rangeDate}
          options={{ DistributionDate: 'Ngày đăng', CreatedDate: 'Ngày tạo', ModifiedDate: 'Ngày sửa' }}
          onChange={filterHandlers.onRangeDateFilter}
        />
      </TableToolbarWrapper>
      <TableToolbarWrapper>
        <SearchFieldWithType
          typeOptions={SEARCH_TYPE_OPTIONS}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
        <ContentDisplayTypeSelector
          emptyLabel="-- Kiểu hiển thị --"
          value={listProps.filters.DisplayType}
          onChange={filterHandlers.onDisplayTypeFilter}
          width={180}
          allowClear
        />
        <FlexPushRight />
        <Button
          type="primary"
          onClick={() => onSelectAll(listProps.items.filter(item => !selectedIds.includes(item.ContentID)))}
        >
          Thêm tất cả tin
        </Button>
      </TableToolbarWrapper>
      <SelectableTable
        {...useListProps(listProps)}
        idField="ContentID"
        columns={columns}
        selected={selected}
        onSelect={onSelect}
        onUnselect={onUnselect}
        heightAdjust={84}
      />
    </Fragment>
  );
}
