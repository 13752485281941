import React, { useState, useRef, useEffect } from 'react';
import makeStyles from 'core/common/makeStyles';
import { Input, Button } from 'antd';

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
  },
  button: {
    border: 0,
    background: 'transparent',
    padding: 0,
    display: 'inline-block',
    '&:active, &:hover': {
      background: 'transparent',
    },
  },
}));

export default function EditableDiv({
  text,
  onSubmit,
  children,
  inputProps,
  ...props
}) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    setValue(text);
  }, [text, setValue]);

  return (
    <div {...props}>
      {isEditing ? (
        <Input.TextArea
          {...inputProps}
          autoSize
          autoFocus
          className={classes.input}
          ref={inputRef}
          value={value}
          onChange={event => setValue(event.target.value)}
          onKeyDown={handleInputKeyDown}
          title="Bấm Enter để lưu hoặc ESC để hủy thay đổi"
        />
      ) : children}
      {!isEditing && <Button title="Sửa" onClick={handleClick} className={classes.button} icon="edit"></Button>}
    </div>
  );

  function handleClick() {
    if (!isEditing) {
      setIsEditing(true);
    }
  }

  function handleInputKeyDown(event) {
    if (isEditing) {
      if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
        return;
      }

      if (event.keyCode === 13) { // Enter
        event.preventDefault();
        setIsEditing(false);
        onSubmit(value);
      } else if (event.keyCode === 27) { // ESC
        setIsEditing(false);
        setValue(text);
      }
    }
  }
}
