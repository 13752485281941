import { Checkbox } from 'antd';
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';

export default forwardRef(function AccountGroupsCheckbox({ groups, accountGroups }, ref) {
  const groupOptions = useMemo(() =>
    groups.map(group => ({ value: group.GroupID, label: group.Alias || group.Name })),
  [groups]);
  const defaultValue = useMemo(() => accountGroups.map(item => item.GroupID), [accountGroups]);
  const dataRef = useRef();

  const onChange = useCallback((data) => {
    dataRef.current = data;
  }, []);

  useImperativeHandle(ref, () => ({
    getData() {
      return dataRef.current && dataRef.current.map(groupId => ({ GroupID: groupId, ZoneID: 0 }));
    },
  }), []);

  return (
    <Checkbox.Group
      options={groupOptions}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
});
