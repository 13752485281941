import { httpGet, httpPost } from 'core/common/http-client';

const authorApi = {
  get(id) {
    return httpGet(`/authors/get/item?AuthorID=${id}`);
  },

  getList({ limit = 20, offset = 0, fields = [], sortBy, filters, lookup }) {
    const qs = { limit, offset, sortBy, fields: [...fields], lookup, ...filters };
    return httpGet('/authors/get/items-by-range', { qs });
  },

  create(data) {
    return httpPost('/authors/post/insert', data);
  },

  update(data) {
    return httpPost('/authors/post/update', data);
  },

  delete(id) {
    return httpPost('/authors/post/delete', { AuthorID: id });
  },
};

export default authorApi;
