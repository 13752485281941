import moment from 'moment';
import { Button, Checkbox } from 'antd';
import { emptyFunction } from 'core/common/empty';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import AccountSelector from 'core/components/account/AccountSelector';
import BaseDialog from 'core/components/common/BaseDialog';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateWithTypeSelector from 'core/components/common/RangeDateWithTypeSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import useContentColumns from 'core/hooks/useContentColumns';
import ContentDisplayTypeSelector from 'core/components/content/ContentDisplayTypeSelector';
import SelectableTable from 'core/components/table/SelectableTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import contentApi from 'core/engine/content/api';
import { ContentSearchOptions } from 'core/engine/content/constants';
import { GroupRoleUp } from 'core/engine/group/constants';
import useDialog from 'core/hooks/useDialog';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import useSyncRef from 'core/hooks/useSyncRef';
import useContentFilterHandlers from 'core/modules/contents/hooks/useContentFilterHandlers';
import React, { Fragment, useEffect } from 'react';
import makeStyles from 'core/common/makeStyles';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import { Sites, SubSiteZone } from 'core/engine/site/constants';

const useStyles = makeStyles(() => ({
  checkbox: {
    minWidth: 120,
    marginTop: 5,
  },
}));

const defaultRangeDate = [null, null];

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;
  const listSorting = { [rangeDate.type]: rangeDate.desc ? 'DESC' : 'ASC', ...sorting };

  if (rangeDate.range[0] && rangeDate.range[1]) {
    listFilters[rangeDate.type] = dateRangeToFilter(rangeDate.range);
  }

  delete listFilters.rangeDate;

  if (listFilters.isHideScheduleContent) {
    const now = moment().format('x');

    if (rangeDate.type === 'DistributionDate' && rangeDate.range[0] && rangeDate.range[1]) {
      const from = rangeDate.range[0].startOf('day').format('x');
      const to = rangeDate.range[1].endOf('day').format('x') - 1;

      listFilters.DistributionDate = 'bw:' + from + ',' + (now < to ? now : to);
    } else {
      listFilters.DistributionDate = 'lte:' + now;
    }
  }

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return contentApi.getDeployedList({
    limit,
    offset,
    fields: ['Url', 'WriterID', 'DeployerID', 'ViewCount'],
    sortBy: sortingToSortBy(listSorting),
    filters: listFilters,
  });
}

export default function TopicAddContentButton({ list, onInsert, onDelete, defaultZoneID, siteId = Sites.ALL, onDone = emptyFunction }) {
  const classes = useStyles();
  const { open, handleClose, handleOpen } = useDialog();
  const onDoneRef = useSyncRef(onDone);

  const listProps = useList({
    listFn,
    defaultPageSize: 20,
    defaultFilters: {
      search: { type: ContentSearchOptions[0].value },
      rangeDate: { type: 'DistributionDate', range: defaultRangeDate, desc: true },
      ZoneID: defaultZoneID || (siteId ? SubSiteZone[siteId]: null),
      SiteID: siteId,
      isHideScheduleContent: true,
    },
  });
  const ContentColumns = useContentColumns();

  const columns = [
    ContentColumns.Avatar,
    ContentColumns.Title,
    ContentColumns.DistributionDate,
    ContentColumns.ViewCount,
  ];

  const filterHandlers = useContentFilterHandlers(listProps);
  const handleFilterIsHideScheduleContent = useCreateOnFilterChange(listProps, 'isHideScheduleContent');

  useEffect(() => {
    if (open) {
      listProps.refresh();
    } else {
      onDoneRef.current();
    }
  }, [open, onDoneRef]);

  return (
    <Fragment>
      <Button title="Thêm tin" icon="plus" onClick={handleOpen}>Thêm tin</Button>
      <BaseDialog
        fullHeightFixed
        visible={open}
        title="Thêm tin vào chủ đề"
        width={900}
        onCancel={handleClose}
        footer={null}
      >
        <TableToolbarWrapper>
          <ZoneSelector
            allowClear
            roles={GroupRoleUp.DEPLOYER}
            emptyLabel="-- Chuyên mục --"
            emptyValue={siteId ? SubSiteZone[siteId] : null}
            value={listProps.filters.ZoneID}
            onChange={filterHandlers.onZoneFilter}
            siteId={siteId}
          />
          <AccountSelector
            emptyLabel="-- Người tạo --"
            value={listProps.filters.WriterID}
            onChange={filterHandlers.onWriterFilter}
            allowClear
          />
          <FlexPushRight />
          <RangeDateWithTypeSelector
            value={listProps.filters.rangeDate}
            options={{ CreatedDate: 'Ngày tạo', ModifiedDate: 'Ngày sửa', DistributionDate: 'Ngày đăng' }}
            onChange={filterHandlers.onRangeDateFilter}
          />
          <Checkbox
            checked={listProps.filters.isHideScheduleContent}
            onChange={(event) => handleFilterIsHideScheduleContent(event.target.checked)}
            className={classes.checkbox}
          >
            Ẩn tin hẹn giờ
          </Checkbox>
        </TableToolbarWrapper>
        <TableToolbarWrapper>
          <SearchFieldWithType
            typeOptions={ContentSearchOptions}
            search={listProps.filters.search}
            onSearch={filterHandlers.onSearchFilter}
          />
          <ContentDisplayTypeSelector
            emptyLabel="-- Kiểu hiển thị --"
            value={listProps.filters.DisplayType}
            onChange={filterHandlers.onDisplayTypeFilter}
            width={180}
            allowClear
          />
        </TableToolbarWrapper>
        <SelectableTable
          {...useListProps(listProps)}
          idField="ContentID"
          columns={columns}
          selected={list}
          onSelect={onInsert}
          onUnselect={onDelete}
          heightAdjust={84}
        />
      </BaseDialog>
    </Fragment>
  );
}
