import { Checkbox, Spin } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { emptyFunction } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import topicApi from 'core/engine/topic/api';
import useMountedState from 'core/hooks/useMountedState';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
  },
}));

export default forwardRef(function ContentFormItemDistributionTopicsInput({
  value,
  onChange = emptyFunction,
}, ref) {
  const classes = useStyles();
  const isMounted = useMountedState();

  const normalizedvalue = useMemo(() => {
    return (value || '').split(',').map(chunk => parseInt(chunk));
  }, [value]);

  const [options, setOptions] = useState();

  useEffect(() => {
    topicApi.getListDistributable()
      .then((resp) => {
        if (!isMounted()) {
          return;
        }

        setOptions(resp.data.items.map(topic => ({
          value: topic.TopicID,
          label: topic.Name,
        })));
      });
  }, []);

  if (!options) {
    return <Spin />;
  }

  return (
    <Checkbox.Group
      ref={ref}
      className={classes.container}
      options={options}
      value={normalizedvalue}
      onChange={handleChange}
    />
  );

  function handleChange(checkedValues) {
    onChange(checkedValues.join());
  }
});
