import { IS_MOBILE } from '@bm/common';
import { Button } from 'antd';
import { asyncAction } from 'core/common/async';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import makeStyles from 'core/common/makeStyles';
import RangeDateWithTypeSelector from 'core/components/common/RangeDateWithTypeSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import Selector from 'core/components/common/Selector';
import SelectableTable from 'core/components/table/SelectableTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import contentApi from 'core/engine/content/api';
import { ContentDisplayTypeLabel, ContentSearchOptions } from 'core/engine/content/constants';
import { GroupRoleUp } from 'core/engine/group/constants';
import tagApi from 'core/engine/tag/api';
import useContentColumns from 'core/hooks/useContentColumns';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import useContentFilterHandlers from 'core/modules/contents/hooks/useContentFilterHandlers';
import React, { Fragment, useState } from 'react';

const useStyles = makeStyles(() => ({
  button: {
    position: 'absolute',
    top: '5px',
    right: '60px',
    lineHeight: 1.5,
  },
}));

const defaultRangeDate = [null, null];

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;
  const listSorting = { [rangeDate.type]: rangeDate.desc ? 'DESC' : 'ASC', ...sorting };

  if (rangeDate.range[0] && rangeDate.range[1]) {
    listFilters[rangeDate.type] = dateRangeToFilter(rangeDate.range);
  }

  delete listFilters.rangeDate;

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return contentApi.getDeployedList({
    limit,
    offset,
    fields: ['Url', 'WriterID', 'DeployerID', 'ViewCount', 'Keywords'],
    sortBy: sortingToSortBy(listSorting),
    filters: listFilters,
  });
}

export default function SelectableContentKeywordsTab({ keyword, contentsAdded, setContentsAdded }) {
  const classes = useStyles();
  const contentColumns = useContentColumns();
  const [collapsed, setCollapsed] = useState(false);

  const listProps = useList({
    listFn,
    defaultPageSize: 20,
    defaultFilters: {
      search: { type: ContentSearchOptions[0].value },
      rangeDate: { type: 'DistributionDate', range: defaultRangeDate, desc: true },
    },
    defaultSorting: {
      DistributionDate: 'DESC',
    },
    autoLoad: true,
  });

  const filterHandlers = useContentFilterHandlers(listProps);

  const columns = [
    contentColumns.Avatar,
    contentColumns.Title,
    contentColumns.DistributionDate,
    {
      ...contentColumns.ViewCount,
      width: 85,
    },
  ];

  return (
    <Fragment>
      {!IS_MOBILE && (
        <Button
          title={collapsed ? 'Hiện tìm kiếm' : 'Ẩn tìm kiếm'}
          className={classes.button}
          onClick={toggleCollapsed}
        >
          {collapsed ? 'Hiện tìm kiếm' : 'Ẩn tìm kiếm'}
        </Button>
      )}
      <TableToolbarWrapper hidden={collapsed}>
        <ZoneSelector
          allowClear
          roles={GroupRoleUp.WRITER}
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
          width={200}
        />
        <Selector
          emptyLabel="-- Hiển thị --"
          options={ContentDisplayTypeLabel}
          value={listProps.filters.DisplayType}
          onChange={filterHandlers.onDisplayTypeFilter}
          width={200}
          allowClear
        />
        <RangeDateWithTypeSelector
          value={listProps.filters.rangeDate}
          options={{ CreatedDate: 'Ngày tạo', ModifiedDate: 'Ngày sửa', DistributionDate: 'Ngày đăng' }}
          onChange={filterHandlers.onRangeDateFilter}
        />
      </TableToolbarWrapper>
      <TableToolbarWrapper hidden={collapsed}>
        <SearchFieldWithType
          typeOptions={ContentSearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
      </TableToolbarWrapper>
      <SelectableTable
        {...useListProps(listProps)}
        idField="ContentID"
        columns={columns}
        selected={contentsAdded}
        onSelect={handleSelect}
        onUnselect={handleUnSelect}
        heightAdjust={collapsed ? 0 : 84}
      />
    </Fragment>
  );

  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }

  function handleSelect(item) {
    return asyncAction('Thêm từ khóa vào bài viết', () => {
      tagApi.addContent(item.ContentID, keyword).then(() => {
        setContentsAdded([item, ...contentsAdded]);
      });
    });
  }

  function handleUnSelect(item) {
    return asyncAction('Xóa từ khóa bài viết', () => {
      tagApi.removeContent(item.ContentID, keyword).then(() => {
        setContentsAdded(contentsAdded.filter(c => c.ContentID !== item.ContentID));
      });
    });
  }
}
