import { load } from './menu';

export const SET_USER = 'SET_USER';
export const SET_SESSION_EXPIRED = 'SET_SESSION_EXPIRED';

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function setSessionExpired(value) {
  return {
    type: SET_SESSION_EXPIRED,
    value,
  };
}

export function login(user) {
  return (dispatch) => {
    dispatch(setSessionExpired(false));
    dispatch(setUser(user));
    dispatch(load());
  };
}
