import React, { forwardRef } from 'react';
import Editor from './Editor';
import { EditorButtonPreset } from './constants';
import { emptyObject } from 'core/common/empty';

export default forwardRef(function FullEditor({
  placeholder,
  height,
  minHeight,
  toolbarButtonSize = 'middle',
  buttons = EditorButtonPreset.FULL,
  options = emptyObject,
  ...props
}, ref) {
  const editorOptions = {
    zIndex: 0,
    readonly: false,
    placeholder: placeholder,
    editorCssClass: 'cms-editor',
    width: 'auto',
    height: height,
    minHeight: minHeight,
    disablePlugins: [],
    buttons: buttons,
    buttonsMD: buttons,
    buttonsXS: buttons,
    buttonsSM: buttons,
    toolbarSticky: false,
    toolbarButtonSize: toolbarButtonSize,
    ...options,
  };

  return (
    <Editor
      options={editorOptions}
      workplaceClassName="cms-workplace"
      ref={ref}
      {...props}
    />
  );
});
