import { emptyArray } from 'core/common/empty';
import { getTreeListEnabledZoneCache, getTreeListZoneByAccountCache } from 'core/engine/zone/caching';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import Selector from 'core/components/common/Selector';
import siteUtils from 'core/engine/site/utils';
import { Sites, SubSiteZone } from 'core/engine/site/constants';
import lodash from 'core/common/lodash';
import { getAncestorZoneIds, getDescendantZoneIds } from 'core/common/listUtils';

export default forwardRef(function ZoneSelector({
  value,
  onChange,
  extendItems = emptyArray,
  roles = emptyArray,
  onlyRoot = false,
  siteId = Sites.ALL,
  disableRootSubSite = false,
  ...props
}, ref) {

  const [zones, setZones] = useState([]);
  const subSiteZoneIds = useMemo(() => Object.values(SubSiteZone), []);
  let keyword = '';
  let selectedIds = [];

  const options = useMemo(() => {
    let filteredZones = zones;

    if (onlyRoot) {
      filteredZones = filteredZones.filter(zone => zone.ParentID === 0);
    }

    if (siteId !== Sites.ALL) {
      filteredZones = siteUtils.getSiteZonesMap(filteredZones)[siteId] || filteredZones;
    }

    return [
      ...extendItems,
      ...filteredZones.map(zone => {
        const isRootZoneSubSite = subSiteZoneIds.includes(zone.ZoneID);
        const label = ' ---- '.repeat(zone.Level) + zone.Name + (isRootZoneSubSite ? ' [Trang con]' : '');

        return {
          value: zone.ZoneID,
          disabled: (disableRootSubSite && isRootZoneSubSite) || zone.disabled,
          label: zone.ParentID ? label : <b>{label}</b>,
        };
      }),
    ];
  }, [zones, onlyRoot, siteId]);

  useEffect(() => {
    let active = true;

    (roles.length ? getTreeListZoneByAccountCache(roles) : getTreeListEnabledZoneCache())
      .then((zones) => {
        if (active) {
          setZones(zones);
        }
      });

    return () => active = false;
  }, [roles]);

  return (
    <Selector
      ref={ref}
      value={value && typeof value === 'string' && !isNaN(value) ? parseInt(value) : value}
      options={options}
      onChange={onChange}
      valueIsNumber
      showDisabledOptions
      selectProps={{ filterOption }}
      {...props}
    />
  );

  function filterOption(input, option) {
    if (keyword !== input) {
      keyword = input;
      selectedIds = updateFilter(keyword);
    }

    return !input || selectedIds.indexOf(parseInt(option.props.value)) >= 0;
  }

  function updateFilter(input) {
    if (!input) {
      return [];
    }

    const foundZoneIds = [];
    options.forEach(option => {
      const label = typeof option.label === 'object'
        ? lodash.get(option, 'label.props.children', '')
        : option.label;

      if (label.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
        const zoneId = parseInt(option.value);
        const subZoneIds = getAncestorZoneIds(zones, zoneId);
        foundZoneIds.push(...subZoneIds);
        const descendantZoneIds = getDescendantZoneIds(zones, zoneId);
        foundZoneIds.push(...descendantZoneIds);
      }
    });

    return lodash.uniq(foundZoneIds);
  }
});
