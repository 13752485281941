import { asyncAction } from 'core/common/async';
import { useCallback, useState } from 'react';
import useMountedState from './useMountedState';

export default function useAsyncAction({ defaultLoading = false } = {}) {
  const [isLoading, setIsLoading] = useState(defaultLoading);
  const isMounted = useMountedState();

  const performAction = useCallback((...args) => {
    setIsLoading(true);

    return asyncAction(...args).then(() => {
      isMounted() && setIsLoading(false);
    });
  }, []);

  return {
    isLoading,
    performAction,
  };
}
