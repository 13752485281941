import { asyncAction } from 'core/common/async';
import { trimCdnPhotoUrl } from 'core/common/cdn';
import logger from 'core/common/logger';
import contentApi from 'core/engine/content/api';
import { ContentAction, ContentDisplayType, ContentStatus, ContentType } from 'core/engine/content/constants';
import useGoBack from 'core/hooks/useGoBack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { formDataToContent, loadContentForForm, showContentFormError } from '../services';
import useContentVisibleActions from './useContentVisibleActions';
import actionRegistry from 'core/registries/actions';
import { ContentHookAction } from 'core/modules/contents/constants';
import { Modal } from 'antd';
import { CONTENT_FORM_ACTIONS_ALERT } from 'core/config';
import { createPromiseDefer } from '@bm/common';

const actionHandlers = {
  [ContentAction.CHECKOUT]: handleCheckout,
  [ContentAction.SAVE]: handleSave,
  [ContentAction.SUBMIT]: handleSubmit,
  [ContentAction.APPROVE]: handleApprove,
  [ContentAction.PUBLISH]: handlePublish,
  [ContentAction.UNPUBLISH]: handleUnpublish,
  [ContentAction.SUBMIT_RETURN]: handleSubmitReturn,
  [ContentAction.APPROVE_RETURN]: handleApproveReturn,
  [ContentAction.DELETE]: handleDelete,
  [ContentAction.FOWARD]: handleForward,
  [ContentAction.MOVE]: handleMove,
};

const CONTENT = {
  Status: ContentStatus.DRAFT,
  DisplayType: ContentDisplayType.NORMAL,
  ContentType: ContentType.OTHERS,
  Description: '',
  HtmlBody: '<p><br></p>',
};

export default function useContentForm({ form }) {
  const { getFieldsValue, setFieldsValue, validateFieldsAndScroll } = form;
  const goBack = useGoBack();
  const bodyEditorRef = useRef();
  const descriptionEditorRef = useRef();
  const [content, setContent] = useState(CONTENT);
  const [isBlocking, setIsBlocking] = useState(true);

  const visibleActions = useContentVisibleActions({ form, content });

  const getFormData = useCallback((validationFields = true, action) => {
    if (bodyEditorRef.current) {
      setFieldsValue({ HtmlBody: bodyEditorRef.current.getNormalizedValue() });
    }

    if (descriptionEditorRef.current) {
      setFieldsValue({ Description: descriptionEditorRef.current.getNormalizedValue() });
    }

    const values = getFieldsValue();

    if (validationFields && !Array.isArray(validationFields)) {
      validationFields = Object.keys(values);
      actionRegistry.doAction(ContentHookAction.VALIDATE_BY_ACTION, { validationFields, values, action });
    }

    return new Promise((resolve, reject) => {
      if (Array.isArray(validationFields)) {
        validateFieldsAndScroll(validationFields, (errors) => {
          errors ? reject(errors) : resolve(values);
        });
      } else {
        resolve(values);
      }
    });
  }, [getFieldsValue, setFieldsValue, validateFieldsAndScroll]);

  const resetContent = useCallback(() => {
    setContent({ ...CONTENT });

    if (bodyEditorRef.current) {
      bodyEditorRef.current.setValue(CONTENT.HtmlBody);
    }

    if (descriptionEditorRef.current) {
      descriptionEditorRef.current.setValue(CONTENT.Description);
    }
  }, []);

  const handleAction = useCallback((action, extraData) => {
    if (action === ContentAction.RESET) {
      resetContent();
      return;
    }

    let validationFields = true;

    if ([ContentAction.CHECKOUT].includes(action)) {
      validationFields = false;
    } else if (action === ContentAction.SAVE && (!content.ContentID || content.Status === ContentStatus.DRAFT)) {
      validationFields = ['Title', 'Distributions'];
    }

    return getFormData(validationFields, action)
      .then((formData) => {
        return actionHandlers[action]({ formData, extraData })
          .then((resp) => {
            setIsBlocking(false);

            if (resp && resp.ContentID) {
              setContent(resp);
            } else {
              goBack();
            }
          });
      }, (error) => {
        showContentFormError(error);
        return Promise.reject(new Error('Form invalid'));
      })
      .catch((err) => {
        if (err.message !== 'Form invalid') {
          logger.error('Error while handing content action:', err);
          return Promise.reject(new Error('Unexpected error'));
        }
      });
  }, [getFormData, resetContent, goBack, content]);

  useEffect(() => {
    const changeAvatar = imgElement => {
      setFieldsValue({
        avatar: {
          url: trimCdnPhotoUrl(imgElement.src),
          width: imgElement.naturalWidth || 0,
          height: imgElement.naturalHeight || 0,
        },
      });
    };

    const timeoutId = setTimeout(() => {
      if (bodyEditorRef.current) {
        bodyEditorRef.current.registerEvent('change-avatar', changeAvatar);
      } else {
        logger.warn('editorRef is null. Choosing avatar from editor function will not working. Reload may fix the problem.');
      }
    }, 1000); // after editor initialization to wait for editorRef.current not null

    return () => {
      clearTimeout(timeoutId);

      bodyEditorRef.current && bodyEditorRef.current.unregisterEvent('change-avatar', changeAvatar);
    };
  }, [setFieldsValue]);

  return {
    content,
    setContent,
    resetContent,
    bodyEditorRef,
    descriptionEditorRef,
    handleAction,
    visibleActions,
    isBlocking,
    getFormData,
  };
}

function handleCheckout({ formData }) {
  const contentId = formData.ContentID;
  const deferred = modalAction({
    title: 'Đóng tin bài',
    content: 'Bạn có muốn đóng tin bài?',
    okText: 'Đóng',
  }, 'checkout');

  if (!contentId) {
    return deferred.promise;
  }

  return deferred.promise.then(() =>
    contentAction('Đóng tin', () => {
      return contentApi.checkout(contentId);
    })
  );
}

function handleSave({ formData }) {
  const content = formDataToContent(formData);

  return contentAction('Lưu tin', () => {
    return contentApi.save(content)
      .then(resp => loadContentForForm(resp.data.ContentID));
  });
}

function handleSubmit({ formData }) {
  const content = formDataToContent(formData);
  const deferred = modalAction({
    title: 'Gửi tin bài',
    content: 'Bạn có muốn gửi tin bài?',
    okText: 'Gửi',
  }, 'submit');

  return deferred.promise.then(() =>
    contentAction('Gửi tin', () => {
      return contentApi.submit(content);
    })
  );
}

function handleApprove({ formData }) {
  const content = formDataToContent(formData);
  const deferred = modalAction({
    title: 'Duyệt tin bài',
    content: 'Bạn có muốn duyệt tin bài?',
    okText: 'Duyệt',
  }, 'approve');

  return deferred.promise.then(() =>
    contentAction('Duyệt tin', () => {
      return contentApi.approve(content);
    })
  );
}

function handlePublish({ formData }) {
  const content = formDataToContent(formData);

  return contentAction('Đăng tin', () => {
    return contentApi.publish(content);
  });
}

function handleUnpublish({ formData, extraData }) {
  const content = formDataToContent(formData);

  content.contentNote = extraData.note;
  content.returnAfterUnpublish = extraData.returnAfterUnpublish;

  return contentAction('Gỡ tin', () => {
    return contentApi.unpublish(content);
  });
}

function handleSubmitReturn({ formData, extraData }) {
  const content = formDataToContent(formData);

  content.contentNote = extraData.note;

  return contentAction('Trả tin để biên tập lại', () => {
    return contentApi.submitReturn(content);
  });
}

function handleApproveReturn({ formData, extraData }) {
  const content = formDataToContent(formData);

  content.contentNote = extraData.note;

  return contentAction('Trả tin để duyệt lại', () => {
    return contentApi.approveReturn(content);
  });
}

function handleDelete({ formData, extraData }) {
  const content = formDataToContent(formData);

  content.contentNote = extraData.note;

  return contentAction('Xóa tin', () => {
    return contentApi.delete(content);
  });
}

function handleForward({ formData, extraData }) {
  const content = formDataToContent(formData);

  content.contentNote = extraData.note;
  content.forwardAccountId = extraData.account;

  const deferred = modalAction(content.ContentID, {
    title: 'Chuyển tiếp tin',
    content: 'Bạn có muốn chuyển tiếp tin?',
    okText: 'Chuyển tiếp',
  }, 'forward');

  return deferred.promise.then(() =>
    contentAction('Chuyển tiếp tin', () => {
      return contentApi.forward(content);
    })
  );
}

function handleMove({ formData, extraData }) {
  const content = formDataToContent(formData);

  content.contentNote = extraData.note;
  content.forwardAccountId = extraData.account;

  const deferred = modalAction(content.ContentID, {
    title: 'Cho tin',
    content: 'Bạn có muốn cho tin?',
    okText: 'Cho tin',
  }, 'move');

  return deferred.promise.then(() =>
    contentAction('Cho tin', () => {
      return contentApi.move(content);
    })
  );
}

function contentAction(actionTitle, action) {
  return asyncAction(actionTitle, action, { rejectOnError: true });
}

function modalAction(modalOption, action) {
  const deferred = createPromiseDefer();

  if (CONTENT_FORM_ACTIONS_ALERT.includes(action)) {
    Modal.confirm({
      ...modalOption,
      onOk() {
        deferred.resolve();
      },
      onCancel() {
        deferred.reject();
      },
    });
  } else {
    deferred.resolve();
  }

  return deferred;
}
