import FeatureFlip from 'core/components/common/FeatureFlip';
import { Sites } from 'core/engine/site/constants';
import React, { Fragment } from 'react';
import ContentAutoDraft from '../../ContentAutoDraft';
import { Form } from 'antd';
import ContentFormContentInput from './ContentFormContentInput';
import makeStyles from 'core/common/makeStyles';

const useStyles = makeStyles(() => ({
  formItemBody: {
    marginBottom: '0 !important',
  },
}));

export default function ContentFormContentInputGroup({
  form,
  content,
  bodyEditorRef,
  descriptionEditorRef,
  avatarDescriptionEditorRef,
  changeAvatar,
  siteId = Sites.ALL,
  ...props
}) {
  const { getFieldDecorator } = form;
  const classes = useStyles();

  return (
    <Fragment>
      <FeatureFlip name="content-auto-draft">
        <ContentAutoDraft
          contentId={content.ContentID}
          form={form}
          editorRef={bodyEditorRef}
          descriptionRef={descriptionEditorRef}
        />
      </FeatureFlip>
      <Form.Item className={classes.formItemBody}>
        {getFieldDecorator('HtmlBody', {
          initialValue: content.HtmlBody || '',
          rules: [
            { required: !form.getFieldValue('FrameLink'), message: 'Bắt buộc!' },
          ],
        })(
          <ContentFormContentInput
            editorRef={bodyEditorRef}
            descriptionEditorRef={descriptionEditorRef}
            avatarDescriptionEditorRef={avatarDescriptionEditorRef}
            form={form}
            changeAvatar={changeAvatar}
            siteId={siteId}
            {...props}
          />,
        )}
      </Form.Item>
    </Fragment>
  );
}
