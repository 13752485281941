import React from 'react';
import { connectSelectorFilterForList } from 'core/common/hocs';
import { commonColumns } from 'core/common/listUtils';
import AccountDisplay from 'core/components/account/AccountDisplay';
import { AccountFilterForList } from 'core/components/account/AccountSelector';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import DeleteButton from 'core/components/common/DeleteButton';
import { OnEnterFilterForList } from 'core/components/common/OnEnterFilter';
import BaseTable from 'core/components/table/BaseTable';
import ZoneDisplay from 'core/components/zone/ZoneDisplay';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import { TopicType } from 'core/engine/topic/constants';
import TopicFormDialogButton from './TopicFormDialogButton';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { TopicDetailBaseUrl } from '../constants';
import { buildTopicUrl } from 'core/common/urlHelper';
import TopicLogDialogButton from './log/TopicLogDialogButton';

export default function TopicList({ onUpdate, onDelete, type, ...props }) {
  const columns = [
    commonColumns.order,
    {
      Header: 'ID',
      accessor: 'TopicID',
      maxWidth: 100,
      minWidth: 50,
      filterable: true,
      Filter: OnEnterFilterForList,
    },
    {
      Header: 'Tên chủ đề',
      accessor: 'Name',
      filterable: true,
      Filter: OnEnterFilterForList,
      Cell: ({ original, value }) => original.Type === TopicType.SYSTEM
        ? <span>{value}</span>
        : <a href={buildTopicUrl(original.TopicID, value)} rel="noopener noreferrer" target="_blank">{value}</a>,
      minWidth: 250,
    },
    {
      id: 'ZoneID',
      Header: 'Chuyên mục',
      filterable: true,
      Filter: connectSelectorFilterForList(ZoneSelector, { extendItems: [{ value: 0, label: 'Trang chủ' }] }),
      Cell: ({ original }) => original.ZoneID === 0 ? 'Trang chủ' : <ZoneDisplay id={original.ZoneID} />,
      maxWidth: 180,
      minWidth: 150,
    },
    {
      id: 'CreatedDate',
      Header: 'Ngày tạo',
      sortable: true,
      Cell: (row) => (<DateTimeDisplay timestamp={parseInt(row.original.CreatedDate)} />),
      maxWidth: 150,
      minWidth: 130,
    },
    {
      Header: 'Người tạo',
      accessor: 'CreatorID',
      filterable: true,
      Cell: (row) => (<AccountDisplay id={row.original.CreatorID} />),
      Filter: AccountFilterForList,
      maxWidth: 150,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <Link to={(original.Type === TopicType.SYSTEM ? TopicDetailBaseUrl.SYSTEM : TopicDetailBaseUrl.NORMAL) + original.TopicID}>
            <Button title="Danh sách tin" icon="ordered-list" />
          </Link>

          <TopicFormDialogButton topic={original} onDone={onUpdate} />
          {original.Type === TopicType.NORMAL && (
            <DeleteButton title={`Xóa chủ đề tin: ${original.Name}?`} onDelete={() => onDelete(original)} />
          )}
          {type === TopicType.SYSTEM && (
            <TopicLogDialogButton topic={original} />
          )}
        </ActionsCellWrapper>
      ),
      width: 150,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
