import { httpGet } from 'core/common/http-client';

const DEFAULT_FIELDS = [
  'OptionID',
  'VoteID',
  'Option',
  'Order',
  'VoteCount',
];

const voteOptionApi = {
  getList({ limit = 20, offset = 0, sortBy, fields = [], filters }) {
    const qs = { limit, offset, sortBy, fields: [...DEFAULT_FIELDS, ...fields], ...filters };
    return httpGet('/vote-options/get/items-by-range', { qs });
  },
};

export default voteOptionApi;
