import { httpPost } from 'core/common/http-client';

const contentAuthorApi = {
  create(data) {
    return httpPost('/content-authors/post/insert', data);
  },

  multiCreate(data) {
    return httpPost('/content-authors/post/multi-insert', data);
  },

  update(data) {
    return httpPost('/content-authors/post/update', data);
  },

  delete(contentAuthorId) {
    return httpPost('/content-authors/post/delete', { ContentAuthorID: contentAuthorId });
  },
};

export default contentAuthorApi;
