import { Config } from '@zdocs/core';
import VoteDialog from './VoteDialog';
import BaseDialogPlugin from '../BaseDialogPlugin';
import React from 'react';

export default class VotePlugin extends BaseDialogPlugin {

  static install() {
    Config.prototype.controls.vote = {
      tooltip: 'Chèn bình chọn',
      icon: 'vote',
    };
  }

  get name() {
    return 'vote';
  }

  get command() {
    return 'vote';
  }

  get defaultOptions() {
    return {
      template: '<vote voteId="$Id"><strong>Bình chọn</strong><hr>$Title</vote>',
    };
  }

  renderDialog(props) {
    return (
      <VoteDialog
        title="Nhúng bình chọn"
        {...props}
      />
    );
  }

  onSelect(select) {
    super.onSelect();

    if (select.VoteID) {
      this.insertHTMLToEditor(
        this.options.template
          .replace('$Id', select.VoteID)
          .replace('$Title', select.Title),
        false, true);
    }
  }
}
