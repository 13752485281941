import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import Selector from 'core/components/common/Selector';
import authorApi from 'core/engine/author/api';
import useDebounce from 'core/hooks/useDebounce';
import logger from 'core/common/logger';
import { emptyArray, emptyObject } from 'core/common/empty';
import AuthorDisplay from 'core/components/author/AuthorDisplay';

export default forwardRef(function AuthorSelector({
  value = emptyObject,
  onChange,
  extItems = emptyArray,
  exclusiveItems = emptyArray,
  parentOnly = false,
  ...props
}, ref) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    setOptions([]);
    setLoading(true);
    authorApi.getList({
      fields: ['AuthorID', 'FullName'],
      filters: { Enabled: true, FullName: debouncedSearch, ParentID: parentOnly ? 0 : undefined },
    })
      .then((resp) => {
        const extOptions = search ? [] : extItems;
        const extOptionIds = extOptions.map(option => option.AuthorID);
        let items = resp.data.items.filter(item => !extOptionIds.includes(item.AuthorID));

        if (exclusiveItems.length > 0) {
          items = items.filter(item => !exclusiveItems.map(ei => ei.AuthorID).includes(item.AuthorID));
        }

        setOptions([...extOptions, ...items]);
        setLoading(false);
      })
      .catch(() => {
        logger.debug('Failed to load Author list by keyword:', debouncedSearch);
        setLoading(false);
      });
  }, [extItems, debouncedSearch]);

  const normalizeValue = useMemo(() => {
    return { key: value.AuthorID || 0, label: value.FullName };
  }, [value]);

  useEffect(() => {
    if (value.AuthorID > 0 && !options.find((option) => option.AuthorID === value.AuthorID)) {
      setOptions([{ AuthorID: value.AuthorID, FullName: <AuthorDisplay id={value.AuthorID} /> }, ...options]);
    }
  }, [value, options]);

  return (
    <Selector
      ref={ref}
      value={normalizeValue}
      onChange={handleChange}
      options={options}
      onSearch={handleSearch}
      onBlur={handleBlur}
      loading={loading}
      valueField="AuthorID"
      labelField="FullName"
      emptyValue={0}
      labelInValue
      emptyLabel="--- Chọn tác giả ---"
      {...props}
    />
  );

  function handleSearch(value) {
    setSearch(value);
  }

  function handleBlur() {
    setSearch('');
  }

  function handleChange(val) {
    return onChange({ AuthorID: val.key, FullName: val.label });
  }
});
