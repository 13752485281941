import { Button } from 'antd';
import React from 'react';
import useContentColumns from 'core/hooks/useContentColumns';
import DraggableTable from 'core/components/table/DraggableTable';
import lodash from 'core/common/lodash';

export default function SelectedRelatedContentsTab({ value, onChange }) {
  const ContentColumns = useContentColumns();

  const columns = [
    ContentColumns.Avatar,
    ContentColumns.Title,
    ContentColumns.DistributionDate,
    {
      Cell: ({ original }) => (
        <Button
          size="small"
          title="Bỏ chọn"
          icon="delete"
          style={{ color: 'red' }}
          onClick={() => onUnselect(original)}
        />
      ),
      maxWidth: 80,
      minWidth: 50,
      style: { justifyContent: 'center' },
    },
  ];

  return (
    <DraggableTable
      idField="ContentID"
      columns={columns}
      data={value}
      pageSize={Math.max(value.length, 23)}
      showPagination={false}
      onMove={onChange}
      showDragIcon="left"
    />
  );

  function onUnselect(item) {
    const newValue = value.slice();
    lodash.remove(newValue, { ContentID: item.ContentID });
    onChange(newValue);
  }
}
