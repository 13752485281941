import { useState } from 'react';

export default function useForm(defaultValues = {}) {
  const [formData, setFormData] = useState(defaultValues);

  const handleChange = (nameOrEvent, value) => {
    if (typeof nameOrEvent === 'string') {
      setFormData(formData => ({ ...formData, [nameOrEvent]: value }));
    } else {
      // React reuses event object hence we need to persist it to safely access the event data
      nameOrEvent.persist && nameOrEvent.persist();

      const target = nameOrEvent.target;

      setFormData(formData => ({
        ...formData,
        [target.name]: target.type === 'checkbox' ? target.checked : target.value,
      }));
    }
  };

  function setFieldsData(fieldsData) {
    setFormData(formData => ({
      ...formData,
      ...fieldsData,
    }));
  }

  return {
    formData,
    setFormData,
    setFieldsData,
    handleChange,
  };
}
