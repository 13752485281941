import { httpGet, httpPost } from 'core/common/http-client';
import { LiveStreamAction } from 'core/engine/live-stream/constants';

const liveStreamApi = {
  get(id) {
    return httpGet(`/live-streams/get/item?StreamID=${id}`);
  },

  getList({ limit = 20, offset = 0, sortBy, filters }) {
    const qs = {
      limit,
      offset,
      sortBy,
      ...filters,
    };

    return httpGet('/live-streams/get/items-by-range', { qs });
  },

  getTypes() {
    return httpGet('/live-streams/get/types');
  },

  create(data) {
    return httpPost('/live-streams/post/insert', data);
  },

  update(data, action = '') {
    const qs = { action };

    return httpPost('/live-streams/post/update', data, { qs });
  },

  delete(id) {
    return httpPost('/live-streams/post/delete', { StreamID: id });
  },

  checkin(id) {
    return this.update({ StreamID: id }, LiveStreamAction.CHECKIN);
  },

  checkout(id) {
    return this.update({ StreamID: id }, LiveStreamAction.CHECKOUT);
  },
};

export default liveStreamApi;
