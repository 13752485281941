import { httpDownload, httpGet, httpPost } from 'core/common/http-client';
import { ContentAction } from './constants';

const DEFAULT_FIELDS = [
  'ContentID',
  'Status',
  'ZoneID',
  'Title',
  'AvatarUrl',
  'DistributionDate',
  'Distributions',
  'WriterID',
  'CheckinID',
  'Attributes',
  'WordCount',
  'IsLockEdit',
  'Meta',
];

const DEFAULT_LOOKUP = [
  'ContentRegion.ContentID',
  'ContentRegion.RegionID',
];

const contentApi = {
  get(id, { lookup, fields = [] } = {}) {
    const qs = { ContentID: id, lookup: [...DEFAULT_LOOKUP, lookup], fields: [...DEFAULT_FIELDS, ...fields] };
    return httpGet('/contents/get/item', { qs });
  },

  getList({ limit = 20, offset = 0, sortBy, lookup, fields = [], filters }) {
    const qs = { limit, offset, sortBy, lookup: [...DEFAULT_LOOKUP, lookup], fields: [...DEFAULT_FIELDS, ...fields], ...filters };
    return httpGet('/contents/get/items-by-range', { qs });
  },

  getDeployedList({ limit = 20, offset = 0, sortBy, lookup, fields = [], filters }) {
    const qs = { limit, offset, sortBy, lookup: [...DEFAULT_LOOKUP, lookup], fields: [...DEFAULT_FIELDS, ...fields], ...filters };
    return httpGet('/contents/get/deployed-items', { qs });
  },

  exportContentList({ limit, offset, sortBy, lookup, fields = [], filters, filename }) {
    const qs = { limit, offset, sortBy, lookup: [...DEFAULT_LOOKUP, lookup], fields: [...DEFAULT_FIELDS, ...fields], ...filters };
    return httpDownload('/contents/get/export-content-list', { qs, filename });
  },

  getActions(data) {
    return httpPost('/contents/post/get-actions', data);
  },

  getRelated(siteId, size, keywords, contentBodyText) {
    return httpPost('/contents/post/get-related', { SiteID: siteId, size, keywords, contentBodyText });
  },

  getKeywords(size, text) {
    return httpPost('/contents/post/get-keywords', { size, text });
  },

  getStats(filters) {
    const qs = { ...filters };
    return httpGet('/contents/get/stats', { qs });
  },

  exportRoyalties(filters, filename) {
    return httpDownload('/contents/get/export-royalties', { qs: filters, filename });
  },

  getBack(contentId) {
    const data = { ContentID: contentId };
    return httpPost('/contents/post/get-back', data);
  },

  submitData(data, action = '') {
    const qs = { action };
    return httpPost('/contents/post/submit', data, { qs });
  },

  updateLevel(contentId, zoneId, level) {
    const data = {
      ContentID: contentId,
      ZoneID: zoneId,
      Order: level,
    };

    return httpPost('/contents/post/update-level', data);
  },

  multiCheckout(ids) {
    return httpPost('/contents/post/multi-checkout', { contentIds: ids });
  },

  multiDelete(ids, contentNote) {
    return httpPost('/contents/post/multi-delete', { contentIds: ids, contentNote: contentNote });
  },

  multiUnpublish(ids, contentNote) {
    return httpPost('/contents/post/multi-unpublish', { contentIds: ids, contentNote: contentNote });
  },

  multiUnlockEdit(ids) {
    return httpPost('/contents/post/multi-unlock-edit', { contentIds: ids });
  },

  pushZOA(contentId) {
    const qs = { ContentID: contentId };
    return httpPost('/contents/post/push-zoa', qs);
  },

  checkout(id) {
    return contentApi.submitData({ ContentID: id }, ContentAction.CHECKOUT);
  },

  checkin(id) {
    return contentApi.submitData({ ContentID: id }, ContentAction.CHECKIN);
  },

  save(data) {
    return contentApi.submitData(data, ContentAction.SAVE);
  },

  submit(data) {
    return contentApi.submitData(data, ContentAction.SUBMIT);
  },

  submitReturn(data) {
    return contentApi.submitData(data, ContentAction.SUBMIT_RETURN);
  },

  approve(data) {
    return contentApi.submitData(data, ContentAction.APPROVE);
  },

  approveReturn(data) {
    return contentApi.submitData(data, ContentAction.APPROVE_RETURN);
  },

  publish(data) {
    return contentApi.submitData(data, ContentAction.PUBLISH);
  },

  unpublish(data) {
    return contentApi.submitData(data, ContentAction.UNPUBLISH);
  },

  delete(data) {
    return contentApi.submitData(data, ContentAction.DELETE);
  },

  preview(data) {
    return contentApi.submitData(data, ContentAction.PREVIEW);
  },

  forward(data) {
    return contentApi.submitData(data, ContentAction.FOWARD);
  },

  move(data) {
    return contentApi.submitData(data, ContentAction.MOVE);
  },
};

export default contentApi;
