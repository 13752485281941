import { Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import ImagePickerInput from 'core/components/common/ImagePickerInput';
import SimpleEditor from 'core/components/editor/SimpleEditor';
import { CustomComponentContext } from 'core/components/layout';
import SeoEntryForm from 'core/components/seo-entry/SeoEntryForm';
import TopicSelector from 'core/components/topic/TopicSelector';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import { SeoEntryType } from 'core/engine/seo-entry/constants';
import { TopicType } from 'core/engine/topic/constants';
import React, { useContext } from 'react';

export default function TopicForm({ form, onSubmit, topic, editorRef }) {
  const customComponentContext = useContext(CustomComponentContext);

  if (customComponentContext && customComponentContext.ExtTopicForm) {
    return (
      <customComponentContext.ExtTopicForm
        form={form}
        onSubmit={onSubmit}
        topic={topic}
        editorRef={editorRef}
      />
    );
  }

  const { getFieldDecorator } = form;

  getFieldDecorator('Type', { initialValue: topic.Type || TopicType.NORMAL });
  getFieldDecorator('Enabled', { initialValue: isNullOrUndefined(topic.Enabled) ? true : topic.Enabled });

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      {topic.TopicID && (
        <Form.Item label="ID">
          {getFieldDecorator('TopicID', {
            initialValue: topic.TopicID,
          })(
            <Input disabled type="number" />
          )}
        </Form.Item>
      )}
      <Form.Item label="Tên chủ đề">
        {getFieldDecorator('Name', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
          initialValue: topic.Name,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Chủ đề gốc">
        {getFieldDecorator('ParentIDs', {
          initialValue: parseInt(topic.ParentIDs || '') || 0,
        })(
          <TopicSelector emptyLabel="-- Chủ đề --" emptyValue={0} />
        )}
      </Form.Item>
      <Form.Item label="Chuyên mục">
        {getFieldDecorator('ZoneID', {
          initialValue: topic.ZoneID || 0,
        })(
          <ZoneSelector emptyLabel="Trang chủ" emptyValue={0} />
        )}
      </Form.Item>
      <Form.Item label="Ảnh đại diện">
        {getFieldDecorator('AvatarUrl', {
          initialValue: topic.AvatarUrl,
        })(
          <ImagePickerInput showImage />
        )}
      </Form.Item>
      <Form.Item label="Mô tả">
        {getFieldDecorator('Description', {
          initialValue: topic.Description || '',
        })(
          <SimpleEditor placeholder="Mô tả" editorRef={editorRef} />
        )}
      </Form.Item>
      <SeoEntryForm form={form} type={SeoEntryType.TOPIC} id={topic.TopicID} />

    </Form>
  );
}
