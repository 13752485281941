import { Form } from 'antd';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React from 'react';
import ContentFormSourceInput from '../input/ContentFormSourceInput';

export default function ContentFormItemSource({ content, form }) {
  const { getFieldDecorator } = form;

  form.getFieldDecorator('SourceUrl', { initialValue: content.SourceUrl });

  return (
    <Form.Item label={ContentFieldLabel.Source}>
      {getFieldDecorator('Source', {
        initialValue: content.Source,
        rules: [
          { max: 250, message: 'Tối đa 250 kí tự!' },
        ],
      })(
        <ContentFormSourceInput form={form} />
      )}
    </Form.Item>
  );
}
