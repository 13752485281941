import routesRegistry from 'core/registries/routes';
import routes from './routes';

export {
  start,
};

function start() {
  routesRegistry.registerMultiple(routes);
}
