import { Button, Typography } from 'antd';
import { arrayShallowEqual } from 'core/common/arrayHelper';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import BaseDialog from 'core/components/common/BaseDialog';
import FlexDiv from 'core/components/common/FlexDiv';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import { PlatformLabel } from 'core/engine/common/constants';
import embeddedStyleApi from 'core/engine/embedded-styles/api';
import { EmbeddedStyleTypeLabel } from 'core/engine/embedded-styles/constants';
import useDialog from 'core/hooks/useDialog';
import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import EmbeddedStyleSelector from './EmbeddedStyleSelector';

const ID_FIELD = 'StyleID';

const useStyles = makeStyles(() => ({
  list: {
    paddingLeft: 20,

    '& > li': {
      lineHeight: 'initial',
    },

    '& > li:not(:last-child)': {
      marginBottom: 5,
    },
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default forwardRef(function ContentFormEmbeddedStyleInput({ type, value, onChange }, ref) {
  const classes = useStyles();
  const { open, handleClose, handleOpen } = useDialog();
  const [displayItems, setDisplayItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [modifyingItems, setModifingItems] = useState([]);

  useEffect(() => {
    const ids = value && value.split(',').map(item => parseInt(item));

    if (!ids || !ids.length) {
      setDisplayItems([]);
      return;
    }

    const sortedIds = ids.slice().sort();

    if (arrayShallowEqual(sortedIds, modifyingItems.map(c => c[ID_FIELD]).sort())) {
      setDisplayItems(modifyingItems);
      return;
    }

    setIsLoading(true);

    embeddedStyleApi.getList({ limit: 1000, filters: { Type: type, StyleID: 'in:' + ids } })
      .then(resp => {
        setDisplayItems(lodash.sortBy(resp.data.items, item => ids.indexOf(item[ID_FIELD])));
        setIsLoading(false);
      });
  }, [value]);

  useEffect(() => {
    if (open) {
      setModifingItems(displayItems);
    }
  }, [displayItems, open]);

  return (
    <Fragment>
      <FlexDiv className={classes.header}>
        <span ref={ref}>{`Nhúng ${EmbeddedStyleTypeLabel[type]}:`}</span>
        <Button title={`Sửa nhúng ${EmbeddedStyleTypeLabel[type]}`} type="primary" shape="circle" size="small" icon="edit"
          onClick={handleOpen} />
      </FlexDiv>
      <LoadingWrapper loading={isLoading} center>
        {displayItems.length > 0 ?
          <ul className={classes.list}>
            {displayItems.map(item => (
              <li key={item[ID_FIELD]}>
                {item.Title} - {PlatformLabel[item.Platform]}
              </li>
            ))}
          </ul> :
          <Typography.Text><i>{`Chưa nhúng ${EmbeddedStyleTypeLabel[type]}`}</i></Typography.Text>
        }
      </LoadingWrapper>
      <BaseDialog
        fullHeightFixed
        noVerticalPadding
        visible={open}
        title={null}
        onOk={onOk}
        onCancel={handleClose}
        width={900}
      >
        <EmbeddedStyleSelector type={type} value={modifyingItems} onChange={setModifingItems} />
      </BaseDialog>
    </Fragment>
  );

  function onOk() {
    onChange(modifyingItems.map(c => c[ID_FIELD]).join());
    handleClose();
  }
});
