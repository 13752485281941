import { Icon } from 'antd';
import React, { useEffect } from 'react';
import makeStyles from 'core/common/makeStyles';
import useSidebar from 'core/hooks/useSidebar';
import FlexPushRight from '../common/FlexPushRight';
import HeaderUserMenu from './HeaderUserMenu';
import HeaderNotification from './HeaderNotification';
import Separator from '../common/Separator';

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  logo: {
    background: 'rgba(255, 255, 255, 0.2)',
    color: '#FFF',
    fontWeight: 700,
    padding: '5px 20px',
  },
  toggle: {
    color: '#fff',
    marginLeft: -26,
    fontSize: 'x-large',
  },
  user: {
    marginRight: -26,
  },
}));

export const HEADER_COMPONENTS = [];

export default function Header() {
  const classes = useStyles();
  const sidebar = useSidebar();

  useEffect(() => {
    HEADER_COMPONENTS.push(
      () => <FlexPushRight />,
      () => <HeaderNotification />,
      () => <Separator size={40} />,
      () => <HeaderUserMenu className={classes.user} />,
    );
  }, []);

  return (
    <div className={classes.container}>
      {!sidebar.disabled && (
        <Icon
          title={sidebar.opened ? 'Ẩn thanh bên' : 'Hiện thanh bên'}
          className={classes.toggle}
          type={sidebar.opened ? 'menu-fold' : 'menu-unfold'}
          onClick={sidebar.toggle}
        />
      )}
      {HEADER_COMPONENTS.map((Component, index) => <Component key={index} />)}
    </div>
  );
}
