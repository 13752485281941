import { useCallback, useRef } from 'react';

export default function useIdGenerate(start = 1, desc = false, step = 1) {
  const idRef = useRef(start);

  return useCallback(() => {
    if (desc) {
      idRef.current = idRef.current - step;
    } else {
      idRef.current = idRef.current + step;
    }

    return idRef.current;
  }, [idRef, step, desc]);
}
