import { Checkbox } from 'antd';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import makeStyles from 'core/common/makeStyles';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateWithTypeSelector from 'core/components/common/RangeDateWithTypeSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import Selector from 'core/components/common/Selector';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import contentApi from 'core/engine/content/api';
import {
  ContentListType,
  ContentSearchOptions,
  ContentStatus,
  ContentStatusLabel,
} from 'core/engine/content/constants';
import { GroupRoleUp } from 'core/engine/group/constants';
import { getListEnabledZoneByAccountCache } from 'core/engine/zone/caching';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import useServerFeature from 'core/hooks/useServerFeature';
import ContentMyList from 'core/modules/contents/components/my-list/ContentMyList';
import React, { useEffect, useMemo, useState } from 'react';
import useContentConfig from '../../hooks/useContentConfig';
import useContentFilterHandlers from '../../hooks/useContentFilterHandlers';
import { handleLimitSortRangeDateChange, handleSortedChangeByRangeDate } from '../../services';
import moment from 'moment';
import { CONTENT_LIST_CTR_STATS, VIEW_CONTENT_MY_LIST_DISCONTINUED } from 'core/config';
import { isNullOrUndefined } from 'core/common/is';

const defaultRangeDate = [null, null];

const useStyles = makeStyles(() => ({
  checkbox: {
    minWidth: 120,
    marginTop: 5,
  },
}));

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;
  const listSorting = { ...sorting, [rangeDate.type]: rangeDate.desc ? 'DESC' : 'ASC' };

  if (rangeDate.range[0] && rangeDate.range[1]) {
    listFilters[rangeDate.type] = dateRangeToFilter(rangeDate.range);
  }

  delete listFilters.rangeDate;

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  if (listFilters.Status === 'transfered') {
    listFilters.listType = ContentListType.MY_TRANSFERED_LIST;
    delete listFilters.Status;
  }

  if ((listFilters.Status === String(CONTENT_STATUS_ALL) || listFilters.Status === String(ContentStatus.DEPLOYED))
    && listFilters.isHideScheduleContent) {
    const now = moment().format('x');

    if (rangeDate.type === 'DistributionDate' && rangeDate.range[0] && rangeDate.range[1]) {
      const from = rangeDate.range[0].startOf('day').format('x');
      const to = rangeDate.range[1].endOf('day').format('x') - 1;

      listFilters.DistributionDate = 'bw:' + from + ',' + (now < to ? now : to);
    } else {
      listFilters.DistributionDate = 'lte:' + now;
    }
  }

  let lookup = [];
  const fields = ['WriterID', 'CreatedDate', 'DeployerID', 'EditorID', 'ModifiedDate', 'OwnerID', 'ViewCount'];

  if (listFilters.Status === String(ContentStatus.DEPLOYED)) {
    lookup = ['ContentStat.ContentID', 'ContentStat.ViewCount', 'Royalty.ContentID', 'Royalty.Money'];

    if (CONTENT_LIST_CTR_STATS) {
      lookup.push('CTRStats.ContentID', 'CTRStats.Click', 'CTRStats.Imps');
    }
  }

  return contentApi.getList({
    limit,
    offset,
    fields,
    lookup,
    sortBy: sortingToSortBy(listSorting),
    filters: listFilters,
  });
}

const CONTENT_STATUS_ALL = `in:${[
  ContentStatus.DRAFT,
  ContentStatus.SUBMITTED,
  ContentStatus.APPROVED,
  ContentStatus.DEPLOYED,
  ContentStatus.APPROVED_ITEM_RETURNED,
  ContentStatus.SUBMITTED_ITEM_RETURNED,
  VIEW_CONTENT_MY_LIST_DISCONTINUED ? ContentStatus.DISCONTINUED : null,
].filter(status => !isNullOrUndefined(status)).join()}`;

const SERVER_FEATURE_VIEW_ROYALTY = 'contents.mylist.view-royalty';

const StatusFilterOption = [
  { value: CONTENT_STATUS_ALL, label: 'Tất cả' },
  { value: String(ContentStatus.DRAFT), label: ContentStatusLabel[ContentStatus.DRAFT] },
  {
    value: `in:${[ContentStatus.SUBMITTED, ContentStatus.APPROVED].join()}`,
    label: 'Tin chờ duyệt/đăng',
  },
  { value: String(ContentStatus.DEPLOYED), label: ContentStatusLabel[ContentStatus.DEPLOYED] },
  { value: 'transfered', label: 'Tin đã chuyển' },
  { value: `in:${[ContentStatus.APPROVED_ITEM_RETURNED, ContentStatus.SUBMITTED_ITEM_RETURNED].join()}`, label: 'Tin trả lại biên tập' },
  VIEW_CONTENT_MY_LIST_DISCONTINUED ? { value: ContentStatus.DISCONTINUED, label: 'Tin đã gỡ' } : null,
].filter(Boolean);

export default function ContentMyListView() {
  const classes = useStyles();
  const { myListDefaultStatusFilter } = useContentConfig();

  const listProps = useList({
    listFn,
    listId: 'ContentMyList',
    autoLoad: true,
    defaultFilters: {
      search: { type: ContentSearchOptions[0].value },
      rangeDate: { type: 'ModifiedDate', range: defaultRangeDate, desc: true },
      listType: ContentListType.MY_LIST,
      Status: (myListDefaultStatusFilter === 'ALL' ? CONTENT_STATUS_ALL : myListDefaultStatusFilter) || String(ContentStatus.DRAFT),
    },
  });

  const [approveZoneIds, setApproveZoneIds] = useState([]);
  const [deployZoneIds, setDeployZoneIds] = useState([]);
  const handleFilterIsHideScheduleContent = useCreateOnFilterChange(listProps, 'isHideScheduleContent');

  useEffect(() => {
    getListEnabledZoneByAccountCache(GroupRoleUp.APPROVER).then(zone => setApproveZoneIds(zone.map(zone => zone.ZoneID)));
    getListEnabledZoneByAccountCache(GroupRoleUp.DEPLOYER).then(zone => setDeployZoneIds(zone.map(zone => zone.ZoneID)));
  }, []);

  const dateTypeOptions = useMemo(() => {
    if (listProps.filters.Status === String(ContentStatus.DEPLOYED)) {
      return { CreatedDate: 'Ngày tạo', DistributionDate: 'Ngày đăng' };
    }

    return { CreatedDate: 'Ngày tạo', ModifiedDate: 'Ngày sửa' };
  }, [listProps.filters.Status]);

  const filterHandlers = useContentFilterHandlers(listProps);

  return (
    <Container pageTitle="Tin của tôi">
      <TableToolbarWrapper>
        <ZoneSelector
          allowClear
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
        />
        <Selector
          options={StatusFilterOption}
          value={listProps.filters.Status}
          onChange={handleStatusChange}
        />
        <RangeDateWithTypeSelector
          options={dateTypeOptions}
          value={listProps.filters.rangeDate}
          onChange={(rangeDate) => handleLimitSortRangeDateChange(rangeDate, listProps)}
        />
        {(listProps.filters.Status === String(CONTENT_STATUS_ALL) || listProps.filters.Status === String(ContentStatus.DEPLOYED)) &&
          <Checkbox
            checked={listProps.filters.isHideScheduleContent}
            onChange={(event) => handleFilterIsHideScheduleContent(event.target.checked)}
            className={classes.checkbox}
          >
            Ẩn tin hẹn giờ
          </Checkbox>
        }
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={ContentSearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
      </TableToolbarWrapper>
      <ContentMyList
        {...useListProps(listProps)}
        heightAdjust={42}
        showEditButton={listProps.filters.Status !== 'transfered'}
        showEditorColumn={listProps.filters.Status === StatusFilterOption[1].value || listProps.filters.Status === String(ContentStatus.DRAFT)}
        showDeployerColumn={listProps.filters.Status === String(ContentStatus.DEPLOYED)}
        showRoyalty={useServerFeature(SERVER_FEATURE_VIEW_ROYALTY, false) && listProps.filters.Status === String(ContentStatus.DEPLOYED)}
        showViewCountColumn={true}
        showOwnerColumn={listProps.filters.Status === 'transfered'}
        showCTRColumn={CONTENT_LIST_CTR_STATS && listProps.filters.Status === String(ContentStatus.DEPLOYED)}
        onSortedChange={(sorting) => handleSortedChangeByRangeDate(sorting, listProps)}
        approveZoneIds={approveZoneIds}
        deployZoneIds={deployZoneIds}
      />
    </Container>
  );

  function handleStatusChange(value) {
    const newDateType = value === String(ContentStatus.DEPLOYED) ? 'DistributionDate' : 'ModifiedDate';

    listProps.addFilter('rangeDate', {
      ...listProps.filters.rangeDate,
      type: newDateType,
    });
    listProps.addFilter('Status', value);
  }
}
