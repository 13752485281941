import Selector from 'core/components/common/Selector';
import React, { forwardRef, useEffect, useState } from 'react';

export default forwardRef(function WidgetTypeSelector({ value, onChange, widgets, ...props }, ref) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const options = widgets.map((widget) => ({
      value: widget.type,
      label: widget.name,
    }));

    setOptions(options);
  }, [widgets]);

  return (
    <Selector
      ref={ref}
      options={options}
      value={value}
      onChange={onChange}
      valueIsNumber={true}
      {...props}
    />
  );
});
