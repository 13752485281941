import React from 'react';
import { emptyFunction } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import AccountDisplay from '../account/AccountDisplay';
import { commonColors } from 'core/common/commonStyles';

const useStyles = makeStyles(() => ({
  container: {
    color: commonColors.link,
    cursor: 'pointer',
  },
}));

export default function ContentFilterableAccount({ id, title = 'Lọc theo tài khoản này', onClick = emptyFunction }) {
  const classes = useStyles();

  return (
    <AccountDisplay id={id} title={title} className={classes.container} onClick={onClick} />
  );
}
