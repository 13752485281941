import { httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_FIELDS = [
  'TopicID',
  'ContentID',
  'Order',
  'StartDate',
  'EndDate',
];

const DEFAULT_LOOKUP = [];

const topicContentApi = {
  getList({ limit = 20, offset = 0, sortBy, fields = [], lookup = [], filters }) {
    const qs = {
      limit,
      offset,
      sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      lookup: [...DEFAULT_LOOKUP, ...lookup],
      ...filters,
    };

    return httpGet('/topic-contents/get/items-by-range', { qs });
  },

  create(data) {
    return httpPost('/topic-contents/post/insert', data);
  },

  delete(contentId, topicId) {
    return httpPost('/topic-contents/post/delete', { TopicID: topicId, ContentID: contentId });
  },

  updateOrder(topicId, topicContents) {
    return httpPost('/topic-contents/post/update-order', { TopicID: topicId, topicContents });
  },

  submit(topicId, { insertItems, updateItems, deleteItems }) {
    return httpPost('/topic-contents/post/submit',
      {
        TopicID: topicId,
        insertItems: JSON.stringify(insertItems),
        updateItems: JSON.stringify(updateItems),
        deleteItems: JSON.stringify(deleteItems),
      }
    );
  },
};

export default topicContentApi;
