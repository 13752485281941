import SettingAdminForm from 'core/modules/managements/settings/components/SettingAdminForm';
import SettingAdsForm from 'core/modules/managements/settings/components/SettingAdsForm';
import SettingGeneralForm from 'core/modules/managements/settings/components/SettingGeneralForm';
import routesRegistry from 'core/registries/routes';
import settingsRegistry from 'core/registries/settings';
import SettingLayoutForm from './components/main/SettingLayoutForm';
import SettingWebForm from './components/main/SettingWebForm';
import SettingExtendStyleForm from './components/main/SettingExtendStyleForm';
import routes from './routes';

export function start() {
  registerMainSetting();
  routesRegistry.registerMultiple(routes);
}

function registerMainSetting() {
  settingsRegistry.register({
    name: 'Cài đặt chung',
    order: 1,
    Form: SettingGeneralForm,
  });

  settingsRegistry.register({
    name: 'Giao diện',
    order: 2,
    Form: SettingLayoutForm,
  });

  settingsRegistry.register({
    name: 'Web',
    order: 3,
    Form: SettingWebForm,
  });

  settingsRegistry.register({
    name: 'Ads',
    order: 4,
    Form: SettingAdsForm,
  });

  settingsRegistry.register({
    name: 'Admin',
    order: 4,
    Form: SettingAdminForm,
  });

  settingsRegistry.register({
    name: 'Styles',
    order: 5,
    Form: SettingExtendStyleForm,
  });
}
