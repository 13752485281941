import { httpGet, httpPost } from 'core/common/http-client';

const groupApi = {
  get(id) {
    return httpGet(`/groups/get/item?GroupID=${id}`);
  },

  getList({ limit = 50, offset = 0, filters, sortBy }) {
    const qs = { limit, offset, sortBy, ...filters };
    return httpGet('/groups/get/items-by-range', { qs });
  },

  getListAll() {
    return httpGet('/groups/get/items');
  },

  create(data) {
    return httpPost('/groups/post/insert', data);
  },

  update(data) {
    return httpPost('/groups/post/update', data);
  },

  delete(id) {
    return httpPost('/groups/post/delete', { GroupID: id });
  },

  getCommandRoles(id) {
    return httpGet(`/groups/get/command-roles?GroupID=${id}`);
  },

  setCommandRoles(id, roles) {
    return httpPost('/groups/post/set-command-roles', {
      GroupID: id,
      roles,
    });
  },
};

export default groupApi;
