import React, { memo, useContext } from 'react';
import { Button } from 'antd';
import { buildPreviewUrlById } from 'core/common/urlHelper';
import { CustomComponentContext } from 'core/components/layout';

function ContentPreviewButtonForList({ noiseId }) {
  const customComponentContext = useContext(CustomComponentContext);

  if (customComponentContext && customComponentContext.ContentPreviewButtonForList) {
    return <customComponentContext.ContentPreviewButtonForList noiseId={noiseId} />;
  }

  return (
    <a href={buildPreviewUrlById('content_id', noiseId)} target="_blank" rel="noopener noreferrer">
      <Button title="Xem trước" icon="eye"></Button>
    </a>
  );
}

export default memo(ContentPreviewButtonForList);
