import { httpGet, httpPost } from 'core/common/http-client';

const settingApi = {
  get(key) {
    return httpGet(`/settings/get/item?Key=${key}`);
  },

  multiGet(keys) {
    return httpGet(`/settings/get/multi-keys?keys=${keys.join()}`);
  },

  multiPut(data) {
    return httpPost('/settings/post/multi-keys', { data });
  },
};

export default settingApi;
