const commonStyles = {};

commonStyles.displayInline = {
  display: 'inline',
};

commonStyles.flex = {
  display: 'flex',
};

commonStyles.flexRow = {
  ...commonStyles.flex,
  flexDirection: 'row',
};

commonStyles.flexColumn = {
  ...commonStyles.flex,
  flexDirection: 'column',
};

commonStyles.flexAllCentered = {
  ...commonStyles.flex,
  justifyContent: 'center',
  alignItems: 'center',
};

commonStyles.hidden = {
  display: 'none',
};

commonStyles.code = {
  padding: '2px 4px',
  fontSize: '90%',
  color: '#c7254e',
  backgroundColor: '#f9f2f4',
  borderRadius: 4,
};

commonStyles.ellipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

commonStyles.formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

commonStyles.tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 4,
    },
  },
};

commonStyles.fullFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};

commonStyles.borderRadius = {
  border: '1px solid #d4d4d4',
  borderRadius: 4,
};

export default commonStyles;

export const commonMixins = {
  buttonColor(bgColor, fgColor = '#FFF', borderColor = bgColor) {
    return {
      color: fgColor,
      backgroundColor: bgColor,
      '&:hover, &:active, &:focus': {
        borderColor,
        backgroundColor: bgColor,
        color: fgColor,
      },
    };
  },
};

export const commonColors = {
  text: 'rgba(0, 0, 0, 0.8)',
  selected: '#ebf8ff',
  link: '#096dd9',
};
