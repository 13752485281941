import { Tag, Tooltip } from 'antd';
import React from 'react';

const MAX_NAME_LENGTH = 20;

export default function InterviewGuestDisplay({ guests }) {
  return guests ? guests.map(guest => {
    const guestName = guest.Name;

    if (guestName) {
      const isLongName = guestName.length > MAX_NAME_LENGTH;
      const tagElem = (
        <Tag key={guest.GuestID} closable={false} style={{ marginTop: 8 }}>
          {isLongName ? `${guestName.slice(0, MAX_NAME_LENGTH)}...` : guestName}
        </Tag>
      );

      return isLongName ? (
        <Tooltip title={guestName} key={guest.GuestID}>
          {tagElem}
        </Tooltip>
      ) : tagElem;
    }

    return null;
  }) : null;
}
