import { Form, Input, DatePicker } from 'antd';
import React, { useMemo } from 'react';
import commonStyles from 'core/common/commonStyles';
import moment from 'moment';
import Selector from 'core/components/common/Selector';
import { InterviewQuestionStatus, InterviewQuestionStatusLabel } from 'core/engine/interview-question/constants';
import lodash from 'core/common/lodash';
import useInterviewGuestLoader from '../../hooks/useInterviewGuestLoader';
import SimpleEditor from 'core/components/editor/SimpleEditor';

export default function InterviewQuestionForm({
  form,
  onSubmit,
  interview,
  question,
  editorRef,
}) {
  const { getFieldDecorator } = form;
  const date = useMemo(() => question.Date ? moment(question.Date) : moment(), [question.Date]);
  const { guests } = useInterviewGuestLoader(interview);

  const defaultGuests = useMemo(() => lodash.map(question.Guests, 'GuestID'), [question]);

  getFieldDecorator('InterviewID', { initialValue: interview.InterviewID });
  getFieldDecorator('QuestionID', { initialValue: question.QuestionID });

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Người gửi">
        {getFieldDecorator('UserDisplayName', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
          initialValue: question.UserDisplayName,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="E-mail">
        {getFieldDecorator('UserEmail', {
          rules: [
            { max: 250, message: 'Tối đa 250 kí tự!' },
          ],
          initialValue: question.UserEmail,
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Thời gian">
        {getFieldDecorator('Date', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: date,
        })(
          <DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
        )}
      </Form.Item>
      <Form.Item label="Câu hỏi">
        {getFieldDecorator('Question', {
          rules: [{
            required: true,
            message: 'Bắt buộc!',
            transform: () => editorRef.current.getValue(),
          }],
          initialValue: question.Question,
        })(
          <SimpleEditor placeholder="Nhập câu hỏi" editorRef={editorRef} />
        )}
      </Form.Item>
      <Form.Item label="Trạng thái">
        {getFieldDecorator('Status', {
          initialValue: question.Status ? question.Status : InterviewQuestionStatus.NEW,
        })(
          <Selector
            disabled={!interview.isAdmin}
            options={InterviewQuestionStatusLabel}
            valueIsNumber
          />
        )}
      </Form.Item>
      <Form.Item label="Khách mời">
        {getFieldDecorator('Guests', {
          initialValue: defaultGuests,
        })(
          <Selector
            placeholder="Chọn khách mời"
            valueField="GuestID"
            labelField="Name"
            valueIsNumber
            options={guests}
            mode="multiple"
          />
        )}
      </Form.Item>
    </Form>
  );
}
