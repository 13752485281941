import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/vi_VN';
import React, { Component } from 'react';
import { JssProvider } from 'react-jss';
import { Provider } from 'react-redux';
import { configureStore } from 'core/redux/store';
import App from './App';

let counter = 0;

const generateId = (rule) => {
  return `makeStyles--${rule.key}-${counter++}`;
};

const jssProps = process.env.NODE_ENV === 'production' ? { id: { minify: true } } : { generateId };

export const CustomComponentContext = React.createContext({});

export default class RootApp extends Component {
  constructor(props) {
    super(props);
    this.store = configureStore();
  }

  render() {
    return (
      <CustomComponentContext.Provider value={this.props.ComponentList}>
        <Provider store={this.store}>
          <JssProvider {...jssProps}>
            <ConfigProvider locale={locale}>
              <App />
            </ConfigProvider>
          </JssProvider>
        </Provider>
      </CustomComponentContext.Provider>
    );
  }
}
