import { loadServerConfig } from 'core/redux/actions/config';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import useServerConfig from './useServerConfig';

export default function useLoadServerConfig() {
  const dispatch = useDispatch();
  const serverConfig = useServerConfig();

  useLayoutEffect(() => {
    if (!serverConfig) {
      dispatch(loadServerConfig());
    }
  }, [dispatch, serverConfig]);

  return Boolean(serverConfig);
}
