import React, { useCallback } from 'react';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DeleteButton from 'core/components/common/DeleteButton';
import { Checkbox } from 'antd';
import DraggableTable from 'core/components/table/DraggableTable';
import { commonColumns } from 'core/common/listUtils';
import { IS_MOBILE } from '@bm/common';
import InterviewGuestFormDialogButton from './InterviewGuestFormDialogButton';
import AvatarDisplay from 'core/components/common/AvatarDisplay';
import InterviewQuestionButtonForList from '../interview-questions/InterviewQuestionButtonForList';
import useAuth from 'core/hooks/useAuth';
import interviewServices from '../../services';
import HtmlDisplay from 'core/components/common/HtmlDisplay';
import lodash from 'core/common/lodash';

export default function InterviewGuestList({ interview, data, onUpdate, onMultiUpdate, onDelete }) {
  const { loggedInUser } = useAuth();
  const questionListButtonFn = useCallback(guest => {
    if (interview.isAdmin || (interview.isEditor && guest && interviewServices.isInterviewer(guest, loggedInUser))) {
      return (
        <InterviewQuestionButtonForList
          interviewId={interview.InterviewID}
          guestId={guest ? guest.GuestID : 0}
        />
      );
    }
  }, [interview]);

  const actionColumn = interview.isAdmin ? {
    Cell: ({ original }) => (
      <ActionsCellWrapper>
        {questionListButtonFn(original)}
        <InterviewGuestFormDialogButton
          guest={original}
          isEditMode={true}
          onDone={onUpdate}
        />
        <DeleteButton
          title={`Xóa khách mời ${original.Name} khỏi giao lưu trực tuyến?`}
          onDelete={() => onDelete(original)}
        />
      </ActionsCellWrapper>
    ),
    width: 130,
  } : {
    Cell: ({ original }) => (
      <ActionsCellWrapper>
        {questionListButtonFn(original)}
      </ActionsCellWrapper>
    ),
    width: 50,
  };

  const columns = [
    commonColumns.order,
    {
      show: !IS_MOBILE,
      Header: 'Ảnh',
      accessor: 'AvatarUrl',
      width: 64,
      Cell: ({ value }) => (<AvatarDisplay url={value} />),
      ...commonColumns.allCentered,
    },
    {
      Header: 'Tên khách mời',
      accessor: 'Name',
      sortable: false,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      Header: 'Thông tin',
      accessor: 'Description',
      Cell: ({ value }) => (<HtmlDisplay html={value} />),
      minWidth: 300,
    },
    {
      Header: 'Câu hỏi/Trả lời',
      width: 120,
      Cell: ({ original }) => (
        <span>{original.QuestionCount}/{original.AnswerCount}</span>
      ),
    },
    {
      Header: 'Hiển thị',
      Cell: ({ original }) => <Checkbox checked={original.Enabled} />,
      maxWidth: 80,
      minWidth: 60,
      ...commonColumns.allCentered,
    },
    DraggableTable.MOVE_COLUMN,
    actionColumn,
  ];

  return (
    <DraggableTable
      columns={columns}
      data={data}
      onMove={handleMove}
      fullHeight={false}
      maxHeightAdjust={40}
      isDragDisabled={!interview.isAdmin}
    />
  );

  function handleMove(newList) {
    const dataMap = lodash.keyBy(data, 'GuestID');
    const updatedItems = [];

    newList.forEach((item, i) => {
      if (dataMap[item.GuestID].Order !== i + 1) {
        item.Order = i + 1;
        updatedItems.push(item);
      }
    });

    updatedItems.length > 0 && onMultiUpdate(updatedItems);
  }
}
