import { Button } from 'antd';
import { commonColumns } from 'core/common/listUtils';
import { buildTagUrl } from 'core/common/urlHelper';
import AccountDisplay from 'core/components/account/AccountDisplay';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DeleteButton from 'core/components/common/DeleteButton';
import BaseTable from 'core/components/table/BaseTable';
import TagDisplay from 'core/components/tag/TagDisplay';
import React from 'react';
import { Link } from 'react-router-dom';
import ContentAddKeywordDialogButton from './content/ContentAddKeywordDialogButton';
import TagFormDialogButton from './TagFormDialogButton';

export default function TagList({ onUpdate, onDelete, ...props }) {
  const columns = [
    commonColumns.order,
    {
      style: { justifyContent: 'center' },
      Header: 'ID',
      accessor: 'TagID',
      maxWidth: 80,
      minWidth: 60,
    },
    {
      Header: 'Tên',
      accessor: 'Name',
      sortable: true,
      Cell: (row) => (
        <a
          href={buildTagUrl(row.original.TagID, row.original.Name, row.original.Url)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {row.original.Name}
        </a>
      ),
      minWidth: 200,
    },
    {
      Header: 'URL',
      accessor: 'Url',
      minWidth: 200,
    },
    {
      Header: 'Người tạo',
      accessor: 'CreatorID',
      Cell: ({ value }) => <AccountDisplay id={value} />,
      maxWidth: 150,
    },
    {
      Header: 'Mục cha',
      accessor: 'ParentID',
      Cell: ({ value }) => <TagDisplay id={value} />,
      maxWidth: 150,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          {original.ParentID === 0 ? (
            <Link to={'/operations/tags?tagId=' + original.TagID}>
              <Button title="Danh sách từ khóa phụ thuộc" icon="ordered-list" />
            </Link>
          ) : (
            <Button title="Danh sách từ khóa phụ thuộc" icon="ordered-list" disabled={true} />
          )}
          <ContentAddKeywordDialogButton keyword={original.Name} />
          <TagFormDialogButton tag={original} onDone={onUpdate} />
          <DeleteButton title={`Xóa từ khóa: ${original.Name}?`} onDelete={() => onDelete(original)} />
        </ActionsCellWrapper>
      ),
      width: 180,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
