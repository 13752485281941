import { httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_FIELDS = [
  'Name',
];

const contentSourceApi = {

  get(id) {
    return httpGet(`/content-sources/get/item?SourceID=${id}`);
  },

  getList({ limit = 20, offset = 0, sortBy, fields = [], filters }) {
    const qs = {
      limit,
      offset,
      sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      ...filters,
    };

    return httpGet('/content-sources/get/items-by-range', { qs });
  },

  suggest(keyword) {
    return httpGet(`/suggests/get/suggest-source?keyword=${keyword}`);
  },

  create(data) {
    return httpPost('/content-sources/post/insert', data);
  },

  update(data) {
    return httpPost('/content-sources/post/update', data);
  },

  delete(id) {
    return httpPost('/content-sources/post/delete', { SourceID: id });
  },

  multiDelete(ids) {
    return httpPost('/content-sources/post/multi-delete', { ids: ids.join() });
  },
};

export default contentSourceApi;
