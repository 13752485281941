import Container from 'core/components/common/Container';
import React from 'react';
import TopicDisplayPanel from './TopicDisplayPanel';

export default function TopicBoxHotView() {
  return (
    <Container pageTitle="Box Tin hot">
      <TopicDisplayPanel settingKey="StringConfig_HomeHotTopicID" />
    </Container>
  );
}
