import { DatePicker, Form } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { disabledEndTime, disabledStartTime } from 'core/common/datetimeHelper';
import moment from 'moment';
import React, { useMemo, useState } from 'react';

export default function TopicSystemContentListItemScheduleForm({ form, onSubmit, topicContent }) {
  const { getFieldDecorator } = form;

  const initialStartDate = useMemo(() => {
    return topicContent.StartDate ? moment(topicContent.StartDate) : null;
  }, [topicContent.StartDate]);

  const initialEndDate = useMemo(() => {
    return topicContent.EndDate ? moment(topicContent.EndDate) : null;
  }, [topicContent.EndDate]);

  const [startValue, setStartValue] = useState(initialStartDate);
  const [endValue, setEndValue] = useState(initialEndDate);

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Bắt đầu">
        {getFieldDecorator('StartDate', {
          initialValue: initialStartDate,
        })(
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            format="DD/MM/YYYY HH:mm"
            disabledDate={disabledStartDate}
            disabledTime={(value) => disabledStartTime(value, endValue)}
            onChange={setStartValue}
          />
        )}
      </Form.Item>
      <Form.Item label="Kết thúc">
        {getFieldDecorator('EndDate', {
          initialValue: initialEndDate,
        })(
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            format="DD/MM/YYYY HH:mm"
            disabledDate={disabledEndDate}
            disabledTime={(value) => disabledEndTime(value, startValue)}
            onChange={setEndValue}
          />
        )}
      </Form.Item>
    </Form>
  );

  function disabledStartDate(startValue) {
    if (!startValue || !endValue) {
      return false;
    }

    return startValue.valueOf() > endValue.valueOf();
  }

  function disabledEndDate(endValue) {
    if (!endValue || !startValue) {
      return false;
    }

    return endValue.valueOf() <= startValue.valueOf();
  }
}
