import { asyncAction } from 'core/common/async';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import AccountSelector from 'core/components/account/AccountSelector';
import AsyncButton from 'core/components/common/AsyncButton';
import Container from 'core/components/common/Container';
import FeatureFlip from 'core/components/common/FeatureFlip';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateWithTypeSelector from 'core/components/common/RangeDateWithTypeSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import { CONTENT_LIST_FILTER_PARENT_AUTHOR, CONTENT_LIST_WITH_NOTE } from 'core/config';
import contentApi from 'core/engine/content/api';
import { ContentListType, ContentSearchOptions } from 'core/engine/content/constants';
import { GroupRoleUp } from 'core/engine/group/constants';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import ContentSelectionToolbar from 'core/modules/contents/components/ContentSelectionToolbar';
import useContentFilterHandlers from 'core/modules/contents/hooks/useContentFilterHandlers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ContentDeletedList from './ContentDeletedList';
import AuthorIdSelector from 'core/components/author/AuthorIdSelector';

const defaultRangeDate = [moment().add(-3, 'months'), moment().add(1, 'day')];

function listFn({ limit, offset, filters, sorting }) {
  return contentApi.getList({
    limit,
    offset,
    fields: ['WriterID', 'CreatedDate', 'ModifiedDate', 'EditorID'],
    lookup: CONTENT_LIST_WITH_NOTE ? ['ContentNote.ContentID', 'ContentNote.CreatorID', 'ContentNote.CreatedDate', 'ContentNote.Note'] : null,
    sortBy: buildSorting(sorting, filters),
    filters: buildFilter(filters),
  });
}

function buildFilter(filters) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;

  if (rangeDate.range[0] && rangeDate.range[1]) {
    listFilters[rangeDate.type] = dateRangeToFilter(rangeDate.range);
  }

  delete listFilters.rangeDate;

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return listFilters;
}

function buildSorting(sorting, filters) {
  const rangeDate = filters.rangeDate;
  let listSorting = { ...sorting };

  if (rangeDate.range[0] && rangeDate.range[1]) {
    listSorting = { [rangeDate.type]: rangeDate.desc ? 'DESC' : 'ASC', ...listSorting };
  }

  return sortingToSortBy(listSorting);
}

export default function ContentDeletedListView() {
  const listProps = useList({
    listFn,
    listId: 'ContentDeletedList',
    defaultFilters: {
      search: { type: ContentSearchOptions[0].value },
      rangeDate: { type: 'ModifiedDate', range: defaultRangeDate, desc: true },
      listType: ContentListType.DELETED,
    },
  });
  const filterHandlers = useContentFilterHandlers(listProps);

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(listProps.refresh, []);

  let toolbar;

  if (selectedIds.length) {
    toolbar = (
      <ContentSelectionToolbar
        selectedIds={selectedIds}
        onClear={handleClearSelectedContents}
        onCheckout={handleCheckoutSelectedContents}
      />
    );
  } else {
    toolbar = (
      <TableToolbarWrapper>
        <ZoneSelector
          allowClear
          roles={GroupRoleUp.WRITER}
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
        />
        <AccountSelector
          width={200}
          emptyLabel="-- Người tạo --"
          value={listProps.filters.WriterID}
          onChange={filterHandlers.onWriterFilter}
          valueIsNumber
          allowClear
        />
        {CONTENT_LIST_FILTER_PARENT_AUTHOR &&
          <AuthorIdSelector
            width={200}
            emptyValue={null}
            value={listProps.filters.AuthorID}
            onChange={value => listProps.addFilter('AuthorID', value)}
            parentOnly
          />
        }
        <RangeDateWithTypeSelector
          value={listProps.filters.rangeDate}
          onChange={filterHandlers.onRangeDateFilter}
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={ContentSearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
        <FeatureFlip name="content-discontinuedlist-export">
          <AsyncButton
            icon="export"
            type="primary"
            onClick={handleExport}
            title="Xuất Excel"
          >
            Xuất Excel
          </AsyncButton>
        </FeatureFlip>
      </TableToolbarWrapper>
    );
  }

  return (
    <Container pageTitle="Tin đã xóa">
      {toolbar}
      <ContentDeletedList
        {...useListProps(listProps)}
        heightAdjust={42}
        selectedIds={selectedIds}
        onSelectedChange={setSelectedIds}
        onWriterSelected={onWriterSelected}
      />
    </Container>
  );

  function handleClearSelectedContents() {
    setSelectedIds([]);
  }

  function handleCheckoutSelectedContents() {
    return asyncAction('Đóng các tin đã chọn', () => {
      return contentApi.multiCheckout(selectedIds)
        .then(() => {
          setSelectedIds([]);
          listProps.refresh();
        });
    });
  }

  function onWriterSelected(writerId) {
    return listProps.addFilter('WriterID', writerId);
  }

  function handleExport() {
    const rangeDate = listProps.filters.rangeDate.range;
    const filename = rangeDate[0] && rangeDate[1]
      ? `Danh sách tin đã xóa từ ${rangeDate[0].format('DD-MM-YYYY')} đến ${rangeDate[1].format('DD-MM-YYYY')}.xlsx`
      : 'Danh sách tin đã xóa.xlsx';

    return asyncAction('Xuất danh sách tin đã xóa', () => {
      return contentApi.exportContentList({
        filters: buildFilter(listProps.filters),
        sortBy: buildSorting(listProps.sorting, listProps.filters),
        fields: ['ContentID', 'Title', 'ZoneID', 'WriterID', 'EditorID', 'ModifiedDate'],
        lookup: ['ContentAuthor.ContentID', 'ContentAuthor.AuthorName', 'ContentNote.ContentID', 'ContentNote.CreatorID', 'ContentNote.Note'],
        filename,
      });
    });
  }
}
