import { green, yellow } from '@ant-design/colors';
import { createPromiseDefer } from '@bm/common';
import { Button, message, Modal } from 'antd';
import { asyncAction } from 'core/common/async';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import ConfirmButton from 'core/components/common/ConfirmButton';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateSelector from 'core/components/common/RangeDateSelector';
import Selector from 'core/components/common/Selector';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import { COMMENT_QUICK_APPROVE } from 'core/config';
import commentApi from 'core/engine/comment/api';
import { CommentStatus, CommentStatusLabel } from 'core/engine/comment/constants';
import { ObjectType } from 'core/engine/common/constants';
import { GroupRoleUp } from 'core/engine/group/constants';
import { getListEnabledZoneByAccountCache } from 'core/engine/zone/caching';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import CommentList from './CommentList';
import CommentSearcField from './CommentSearchField';
import { commonMixins } from 'core/common/commonStyles';

const useStyles = makeStyles(() => ({
  selectedText: {
    whiteSpace: 'nowrap',
  },
  approveAllButton: {
    background: green[5],
    borderColor: green[5],
    '&:hover,&:focus': {
      background: green[4],
      borderColor: green[4],
    },
  },
  rejectAllButton: {
    ...commonMixins.buttonColor(yellow[6]),
  },
}));

function listFn({ limit, offset, filters, sorting }) {
  let listFilters = { ...filters };

  if (filters.rangeDate[0] && filters.rangeDate[1]) {
    listFilters.CreatedDate = dateRangeToFilter(filters.rangeDate);
  }

  delete listFilters.rangeDate;

  if (listFilters.search.value) {
    // just ignore any other filters
    listFilters = {
      [filters.search.type]: filters.search.value,
    };
  }

  delete listFilters.search;

  return commentApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

const defaultRangeDate = [moment().add(-3, 'months'), moment().add(1, 'day')];

export default function CommentListView() {
  const classes = useStyles();

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: {
      CreatedDate: 'DESC',
    },
    defaultFilters: {
      search: { type: 'Title' },
      rangeDate: defaultRangeDate,
      Status: CommentStatus.WAITING,
      ObjectType: ObjectType.CONTENT,
      roles: GroupRoleUp.COMMENT_READER.join(),
    },
  });

  const handleStatusFilter = useCreateOnFilterChange(listProps, 'Status');
  const handleZoneIdFilter = useCreateOnFilterChange(listProps, 'ZoneID');
  const handleRangeDateFilter = useCreateOnFilterChange(listProps, 'rangeDate');
  const handleSearchFilter = useCreateOnFilterChange(listProps, 'search');

  const [approveZoneIds, setApproveZoneIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    GroupRoleUp.COMMENT_DEPLOYER.length && getListEnabledZoneByAccountCache(GroupRoleUp.COMMENT_DEPLOYER)
      .then(zones => setApproveZoneIds(zones.map(zone => zone.ZoneID)));
  }, []);

  const mapZoneItem = useMemo(() => lodash.keyBy(listProps.items, 'CommentID'), [listProps.items]);

  return (
    <Container pageTitle="Bình luận">
      <TableToolbarWrapper>
        {selectedIds.length ? (
          <Fragment>
            <span className={classes.selectedText}><strong>{selectedIds.length}</strong> bình luận đã được chọn</span>
            <FlexPushRight />
            <Button onClick={() => setSelectedIds([])}>Hủy</Button>
            <ConfirmButton
              anchorElement={({ onClick }) => (
                <Button
                  className={classes.approveAllButton}
                  icon="arrow-up"
                  onClick={onClick}
                  type="primary"
                  title="Duyệt tất cả"
                  disabled={listProps.filters.Status === CommentStatus.APPROVED}
                >Duyệt tất cả</Button>
              )}
              dialogActionText="Duyệt"
              dialogTitleText="Duyệt bình luận"
              dialogBodyText={`Bạn có chắc chắn muốn duyệt tất cả ${selectedIds.length} bình luận?`}
              handleSubmit={() => handleApproveSelectedComments(true)}
            />
            <ConfirmButton
              anchorElement={({ onClick }) => (
                <Button
                  icon="arrow-down"
                  onClick={onClick}
                  title="Ẩn tất cả"
                  disabled={listProps.filters.Status === CommentStatus.DROPPED}
                  className={classes.rejectAllButton}
                >Ẩn tất cả</Button>
              )}
              danger
              dialogActionText="Ẩn"
              dialogTitleText="Ẩn bình luận"
              dialogBodyText={`Bạn có chắc chắn muốn ẩn tất cả ${selectedIds.length} bình luận?`}
              handleSubmit={() => handleApproveSelectedComments(false)}
            />
            <ConfirmButton
              anchorElement={({ onClick }) => (
                <Button
                  icon="stop"
                  onClick={onClick}
                  type="danger"
                  title="Xóa tất cả"
                >Xóa tất cả</Button>
              )}
              danger
              dialogActionText="Xóa"
              dialogTitleText="Xóa bình luận"
              dialogBodyText={`Bạn có chắc chắn muốn xóa tất cả ${selectedIds.length} bình luận?`}
              handleSubmit={handleDeleteSelectedComments}
            />
          </Fragment>
        ) : (
          <Fragment>
            <Selector
              valueIsNumber
              options={CommentStatusLabel}
              value={listProps.filters.Status}
              onChange={handleStatusFilter}
            />
            <ZoneSelector
              valueIsNumber
              allowClear
              emptyLabel="-- Chuyên mục --"
              value={listProps.filters.ZoneID}
              onChange={handleZoneIdFilter}
              roles={GroupRoleUp.COMMENT_READER}
            />
            <RangeDateSelector
              value={listProps.filters.rangeDate}
              onChange={handleRangeDateFilter}
            />
            <FlexPushRight />
            <CommentSearcField
              type={listProps.filters.search.type}
              value={listProps.filters.search.value}
              onSearch={handleSearchFilter}
            />
          </Fragment>
        )}
      </TableToolbarWrapper>
      <CommentList
        {...useListProps(listProps)}
        heightAdjust={42}
        showApprover={listProps.filters.Status !== CommentStatus.WAITING}
        selectedIds={selectedIds}
        approveZoneIds={approveZoneIds}
        onSelectedChange={handleSelectedChange}
        onUpdate={listProps.refresh}
        onDelete={handleDelete}
        onFilterObjectId={onFilterObjectId}
        onFilterZoneId={useCreateOnFilterChange(listProps, 'ZoneID')}
        onFilterUserName={onFilterUserName}
        onFilterUserEmail={onFilterUserEmail}
        onContentChange={onContentChange}
        onChange={listProps.refresh}
        onApprove={onApprove}
        onReject={onReject}
      />
    </Container>
  );

  function handleSelectedChange(ids) {
    if (GroupRoleUp.COMMENT_DEPLOYER.length) {
      setSelectedIds(ids.filter(id => approveZoneIds.includes(lodash.get(mapZoneItem, `${id}.Object.ZoneID`))));
    } else {
      setSelectedIds(ids);
    }
  }

  function handleDelete(comment) {
    return asyncAction('Xóa bình luận', () => {
      return commentApi.delete(comment.CommentID).then(() => {
        listProps.removeItem(comment, 'CommentID');
      });
    });
  }

  function onFilterObjectId(value) {
    listProps.addFilter('search', { type: 'ObjectID', value: value });
  }

  function onFilterUserName(value) {
    listProps.addFilter('search', { type: 'UserDisplayName', value: value });
  }

  function onFilterUserEmail(value) {
    listProps.addFilter('search', { type: 'UserEmail', value: value });
  }

  function onContentChange(comment) {
    return asyncAction('Thay đổi nội dung bình luận', () => {
      return commentApi.changeContent({
        CommentID: comment.CommentID,
        Comment: comment.Comment,
      }).then(() => listProps.replaceItem(comment, 'CommentID'));
    });
  }

  function onApprove(comment) {
    const deferred = createPromiseDefer();

    if (comment.Status === CommentStatus.WAITING) {
      if (COMMENT_QUICK_APPROVE) {
        deferred.resolve();
      } else {
        Modal.confirm({
          title: 'Duyệt bình luận?',
          content: 'Bình luận đã duyệt sẽ được đăng lên trang.',
          okText: 'Duyệt',
          onOk() {
            deferred.resolve();
          },
          onCancel() {
            deferred.reject('cancel');
          },
        });
      }
    } else {
      Modal.confirm({
        title: 'Duyệt bình luận đã ẩn?',
        content: 'Bình luận này đã bị ẩn trước đó, bấm "Duyệt" nếu bạn thật sự muốn duyệt lại.',
        okText: 'Duyệt',
        onOk() {
          deferred.resolve();
        },
        onCancel() {
          deferred.reject('cancel');
        },
      });
    }

    return deferred.promise
      .then(() => commentApi.approve({
        CommentID: comment.CommentID,
      }))
      .then(() => {
        listProps.removeItem(comment, 'CommentID');
      })
      .catch((err) => {
        if (err !== 'cancel') {
          message.error('Có lỗi xảy ra khi duyệt bình luận!');
        }
      });
  }

  function onReject(comment) {
    const deferred = createPromiseDefer();

    if (comment.Status === CommentStatus.WAITING) {
      deferred.resolve();
    } else {
      Modal.confirm({
        title: 'Ẩn bình luận đã duyệt?',
        content: 'Bình luận này đã được duyệt trước đó, bấm "Ẩn" nếu bạn thật sự muốn ẩn đi.',
        okText: 'Ẩn',
        onOk() {
          deferred.resolve();
        },
        onCancel() {
          deferred.reject('cancel');
        },
      });
    }

    return deferred.promise
      .then(() => commentApi.reject({
        CommentID: comment.CommentID,
      }))
      .then(() => {
        listProps.removeItem(comment, 'CommentID');
      })
      .catch((err) => {
        if (err !== 'cancel') {
          message.error('Có lỗi xảy ra khi ẩn bình luận!');
        }
      });
  }

  function handleApproveSelectedComments(approve) {
    return asyncAction((approve ? 'Duyệt' : 'Ẩn') + ' các bình luận đã chọn', () => {
      return commentApi.multiApprove(selectedIds, approve)
        .then(() => {
          setSelectedIds([]);
          listProps.refresh();
        });
    });
  }

  function handleDeleteSelectedComments() {
    return asyncAction('Xóa các bình luận đã chọn', () => {
      return commentApi.multiDelete(selectedIds)
        .then(() => {
          setSelectedIds([]);
          listProps.refresh();
        });
    });
  }
}
