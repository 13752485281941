import { useMemo } from 'react';

export default function useListProps({
  items,
  isFetching,
  pageIndex,
  totalPage,
  total,
  filtered,
  sorted,
  pageSize,
  onPageChange,
  onPageSizeChange,
  onFilteredChange,
  onSortedChange,
  refresh,
}) {
  return useMemo(() => ({
    data: items,
    loading: isFetching,
    page: pageIndex,
    pages: totalPage,
    total,
    filtered,
    sorted,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onFilteredChange,
    onSortedChange,
    onRefresh: refresh,
  }), [filtered, isFetching, items, onFilteredChange, onPageChange, onPageSizeChange, onSortedChange, pageIndex, pageSize, sorted, totalPage, total, refresh]);
}
