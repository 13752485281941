import { Form } from 'antd';
import { isNullOrUndefined } from 'core/common/is';
import Selector from 'core/components/common/Selector';
import { ContentType, ContentTypeOptions } from 'core/engine/content/constants';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React from 'react';

export default function ContentFormItemContentType({ content, form }) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={ContentFieldLabel.ContentType}>
      {getFieldDecorator('ContentType', {
        initialValue: isNullOrUndefined(content.ContentType) ? ContentType.OTHERS : content.ContentType,
      })(
        <Selector options={ContentTypeOptions} valueIsNumber />
      )}
    </Form.Item>
  );
}
