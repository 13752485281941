import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import contentApi from 'core/engine/content/api';
import AsyncButton from '../common/AsyncButton';

export default function ContentEditButtonForList({ content }) {
  const history = useHistory();
  const contentId = content.ContentID;

  const handleClick = useCallback(() => {
    return contentApi.checkin(contentId)
      .then(() => {
        history.push({
          pathname: '/contents/compose',
          search: `?contentId=${contentId}`,
          state: { ignoreCheckin: true },
        });
      })
      .catch((err) => {
        message.error(err.error_message, 5);
      });
  }, [contentId]);

  return (
    <AsyncButton title={content.IsLockEdit ? 'Tin bài đã khóa' : 'Sửa'} icon="edit" onClick={handleClick} disabled={Boolean(content.IsLockEdit)} />
  );
}
