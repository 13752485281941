import fileApi from 'core/engine/file/api';
import { Modal } from 'antd';
import { HIGH_BITRATE_WARNING_THRESHOLD } from 'core/config';
import { formatNumber } from 'core/common/string';

export function getVideoBitrateFromFile(file) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve({
        duration: video.duration, // seconds
        fileSize: file.size / 1000, // kilobytes
        bitrate: Math.round((8 * file.size / 1000) / video.duration), // kbit/s
      });
    };

    video.onerror = () => reject(new Error('Video load failed'));
  });
}

export function confirmationUpload(file, uploadData, bitrate) {
  return new Promise((resolve, reject) => {
    const okFn = () => resolve(fileApi.uploadVideo(file, file.name, uploadData));
    Modal.confirm({
      title: 'Cảnh báo',
      content: `Bitrate hiện tại (${formatNumber(bitrate, '.')}Kbps) quá cao (>${formatNumber(HIGH_BITRATE_WARNING_THRESHOLD, '.')}Kbps) có thể gây lỗi hiển thị ở một số thiết bị!`,
      okText: 'Tiếp tục',
      cancelText: 'Hủy',
      onOk: okFn,
      onCancel: () => reject(new Error('Video upload cancel')),
    });
  });
}
