
import { httpPost } from 'core/common/http-client';

const removeCacheApi = {
  clear(data) {
    return httpPost('/manual-cache/post/clear', data);
  },
};

export default removeCacheApi;
