import { Select } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import tagApi from 'core/engine/tag/api';
import useDebounce from 'core/hooks/useDebounce';
import { emptyArray } from 'core/common/empty';

const { Option } = Select;

export default forwardRef(function TagSelector({
  value,
  onChange,
  exclusiveItems = emptyArray,
}, ref) {
  const [options, setOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    getApi().then((resp) => {
      if (resp.data && resp.data.items) {
        setDefaultOptions(resp.data.items);
      } else {
        setDefaultOptions([]);
      }
    });
  }, []);

  useEffect(() => {
    if (debouncedSearch) {
      getApi(debouncedSearch)
        .then((resp) => {
          if (resp.data && resp.data.items) {
            let items = resp.data.items;

            if (exclusiveItems.length) {
              items = items.filter(item => !exclusiveItems.map(ei => ei.TagID).includes(item.TagID));
            }

            setOptions(items);
          } else {
            setOptions([]);
          }
        });
    } else {
      setOptions([]);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (value > 0 && defaultOptions.length && !defaultOptions.find((option) => option.TagID === parseInt(value))) {
      tagApi.get(value).then(resp => {
        setDefaultOptions([resp.data.item, ...defaultOptions]);
      });
    }
  }, [value, defaultOptions]);

  return (
    <Select
      ref={ref}
      showSearch
      autoClearSearchValue
      value={value ? parseInt(value) : value}
      onSearch={handleSearch}
      onChange={onChange}
      onBlur={onBlur}
      filterOption={false}
    >
      <Option value={0}>--- Mặc định ---</Option>
      {(search ? options : defaultOptions).map(option => (
        <Option
          key={option.TagID}
          value={option.TagID}>
          {option.Name}
        </Option>
      ))}
    </Select>
  );

  function getApi(keyword) {
    return tagApi.getList({ limit: 50, offset: 0, filters: { Name: keyword } });
  }

  function handleSearch(value) {
    setSearch(value);
  }

  function onBlur() {
    setSearch('');
  }
});
