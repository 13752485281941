import { BASE_PHOTO_URL } from 'core/config';
import { trimUrlProtocol } from './urlHelper';

const RESIZE_WIDTH_PREFIX = 'w';
const RESIZE_HEIGHT_PREFIX = 'h';

export function buildCdnPhotoUrl(imagePathOrUrl = '', width = 0, height = 0) {
  if (!imagePathOrUrl) {
    return imagePathOrUrl;
  }

  if (/^https?:\/\/|^\/\//.test(imagePathOrUrl)) {
    imagePathOrUrl = trimCdnPhotoUrl(imagePathOrUrl);

    if (/^https?:\/\/|^\/\//.test(imagePathOrUrl)) {
      return imagePathOrUrl;
    }
  }

  const baseUrl = BASE_PHOTO_URL;

  if (!imagePathOrUrl.endsWith('.gif')) {
    if (width > 0) {
      if (height > 0) {
        return baseUrl + '/' + width + 'x' + height + imagePathOrUrl;
      } else {
        return baseUrl + '/' + RESIZE_WIDTH_PREFIX + width + imagePathOrUrl;
      }
    } else if (height > 0) {
      return baseUrl + '/' + RESIZE_HEIGHT_PREFIX + height + imagePathOrUrl;
    }
  }

  return baseUrl + imagePathOrUrl;
}

export function trimCdnPhotoUrl(imageUrl = '') {
  const baseUrlWithoutProtocol = trimUrlProtocol(BASE_PHOTO_URL);
  const imageUrlWithoutProtocol = trimUrlProtocol(imageUrl);

  if (imageUrlWithoutProtocol.indexOf(baseUrlWithoutProtocol) === 0) {
    imageUrl = imageUrlWithoutProtocol.substring(baseUrlWithoutProtocol.length);

    const resizePrefix = /\/(w|h)\d+\//.exec(imageUrl);
    const resizeBothPrefix = /\/\d+x\d+\//.exec(imageUrl);

    if (resizePrefix) {
      imageUrl = imageUrl.substring(resizePrefix.index + resizePrefix[0].length - 1);
    } else if (resizeBothPrefix) {
      imageUrl = imageUrl.substring(resizeBothPrefix.index + resizeBothPrefix[0].length - 1);
    }
  }

  return imageUrl;
}
