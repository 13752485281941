import { Button } from 'antd';
import lodash from 'core/common/lodash';
import AccountDisplay from 'core/components/account/AccountDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import BaseTable from 'core/components/table/BaseTable';
import { PlatformLabel } from 'core/engine/common/constants';
import React from 'react';

export default function SelectedEmbeddedStyleTab({ value, onChange }) {
  const columns = [
    {
      Header: 'ID',
      accessor: 'StyleID',
      width: 50,
      style: { justifyContent: 'center' },
    },
    {
      Header: 'Tên',
      accessor: 'Title',
      minWidth: 250,
    },
    {
      Header: 'Nền tảng',
      accessor: 'Platform',
      Cell: ({ value }) => PlatformLabel[value],
      maxWidth: 100,
    },
    {
      Header: 'Ngày sửa',
      width: 120,
      Cell: ({ original }) => <DateTimeDisplay timestamp={original.ModifiedDate} />,
    },
    {
      Header: 'Người sửa',
      width: 120,
      Cell: ({ original }) => <AccountDisplay id={original.EditorID} />,
    },
    {
      Cell: ({ original }) => (
        <Button
          size="small"
          title="Bỏ chọn"
          icon="delete"
          style={{ color: 'red' }}
          onClick={() => onUnselect(original)}
        />
      ),
      width: 80,
      style: { justifyContent: 'center' },
    },
  ];

  return (
    <BaseTable
      columns={columns}
      data={value}
    />
  );

  function onUnselect(item) {
    onChange(lodash.pull(value.slice(), item));
  }
}
