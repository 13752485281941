import { green, orange } from '@ant-design/colors';
import React, { memo } from 'react';
import classnames from 'core/common/classnames';
import makeStyles from 'core/common/makeStyles';
import AccountDisplay from 'core/components/account/AccountDisplay';

const useStyles = makeStyles(() => ({
  box: {
    borderRadius: 3,
    padding: '1px 4px',
    color: '#fff',
    fontSize: 'small',
  },
  checkin: {
    backgroundColor: orange[4],
  },
  checkout: {
    backgroundColor: green[6],
  },
}));

function ContentCheckinDisplay({ checkinId }) {
  const classes = useStyles();

  return checkinId ? (
    <span className={classnames(classes.box, classes.checkin)}>
      <AccountDisplay id={checkinId} />
    </span>
  ) :
    (
      <span className={classnames(classes.box, classes.checkout)}>Đóng</span>
    );
}

export default memo(ContentCheckinDisplay);
