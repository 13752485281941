import { Tabs } from 'antd';
import makeStyles from 'core/common/makeStyles';
import React, { Fragment, useState } from 'react';
import SelectableContentKeywordsTab from './SelectableContentKeywordsTab';
import SelectedContentKeywordsTab from './SelectedContentKeywordsTab';

const useStyles = makeStyles(() => ({
  tab: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > .ant-tabs-content': {
      height: 'calc(100% - 71px)',
    },
  },
}));

export default function ContentKeywordSelector({ keyword }) {
  const classes = useStyles();
  const [contentsAdded, setContentsAdded] = useState([]);

  return (
    <Fragment>
      <Tabs defaultActiveKey="list" className={classes.tab} tabBarStyle={{ width: 'calc(100% - 32px)' }}>
        <Tabs.TabPane tab="Danh sách bài chứa từ khóa" key="list">
          <SelectedContentKeywordsTab
            keyword={keyword}
            contentsAdded={contentsAdded}
            setContentsAdded={setContentsAdded}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Thêm từ khóa vào bài" key="add">
          <SelectableContentKeywordsTab
            keyword={keyword}
            contentsAdded={contentsAdded}
            setContentsAdded={setContentsAdded}
          />
        </Tabs.TabPane>
      </Tabs>
    </Fragment>
  );
}
