import { useEffect, useState } from 'react';
import interviewGuestApi from 'core/engine/interview-guest/api';
import interviewServices from 'core/modules/operations/interviews/services';
import useAuth from 'core/hooks/useAuth';
import { message } from 'antd';

export default function useInterviewGuestLoader(interview) {
  const { loggedInUser } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [guests, setGuests] = useState([]);
  const [allGuests, setAllGuests] = useState([]);

  useEffect(() => {
    if (!interview.InterviewID) {
      setGuests([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    interviewGuestApi.getListByInterviewId(interview.InterviewID)
      .then(resp => {
        const newGuests = resp.data.items;
        setAllGuests(newGuests);

        if (interview.isAdmin) {
          setGuests(newGuests);
        } else if (interview.isEditor) {
          setGuests(newGuests.filter((guest) => interviewServices.isInterviewer(guest, loggedInUser)));
        }
      })
      .catch(() => {
        message.error('Lấy dữ liệu khách mời thất bại!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [interview, loggedInUser]);

  return { guests, allGuests, isLoading };
}
