import React, { useCallback, memo } from 'react';
import makeStyles from 'core/common/makeStyles';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    textAlign: 'center',
  },
  name: {
    fontWeight: 700,
    cursor: 'pointer',
    wordBreak: 'break-word',
  },
  email: {
    color: '#333',
    display: 'block',
    wordBreak: 'break-all',
  },
  date: {
    fontSize: 'small',
    fontStyle: 'italic',
  },
}));

function CommentSenderDisplay({
  name,
  email,
  createdDate,
  onFilterUserName,
  onFilterUserEmail,
}) {
  const classes = useStyles();

  const handleNameClick = useCallback(() => {
    onFilterUserName(name);
  }, [name]);

  const handleEmailClick = useCallback((event) => {
    event.preventDefault();
    onFilterUserEmail(email);
  }, [email]);

  return (
    <div className={classes.container}>
      <div className={classes.name} title="Lọc theo tác giả này" onClick={handleNameClick}>{name}</div>
      <a href={`mailto:${email}`} title="Lọc theo email này" className={classes.email} onClick={handleEmailClick}>{email}</a>
      <span className={classes.date}>
        <DateTimeDisplay timestamp={createdDate} />
      </span>

    </div>
  );
}

export default memo(CommentSenderDisplay);
