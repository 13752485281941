import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { buildTreeList } from 'core/common/utils';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import PermissionTable from 'core/components/common/PermissionTable';
import zoneApi from 'core/engine/zone/api';
import { GroupRole } from 'core/engine/group/constants';

export default forwardRef(function AccountGroupsTable({ groups, accountGroups }, ref) {
  const [zoneTreeList, setZoneTreeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const rights = useMemo(() =>
    groups.map(group => ({ id: group.GroupID, label: group.Alias || group.Name })),
  [groups]);
  const [permissionMap, setPermissionMap] = useState();
  const dataRef = useRef();

  const onChange = useCallback((data) => {
    dataRef.current = data;
  }, []);

  useImperativeHandle(ref, () => ({
    getData() {
      return dataRef.current && permissionMapToAccountGroups(dataRef.current);
    },
  }), []);

  useEffect(() => {
    setIsLoading(true);

    zoneApi.getListEnabled()
      .then(resp => resp.data.items)
      .then((zones) => {
        setPermissionMap(buildPermissionMap(accountGroups, zones));
        setZoneTreeList([
          { ZoneID: 0, Name: '' },
          ...buildTreeList(zones, { idField: 'ZoneID' }),
        ]);
        setIsLoading(false);
      });
  }, []);

  return (
    <LoadingWrapper center loading={isLoading}>
      <PermissionTable
        commandIdField="ZoneID"
        commandLabel="Chuyên mục"
        checkAllCommandsId="0"
        commands={zoneTreeList}
        rights={rights}
        defaultCheckedMap={permissionMap}
        onChange={onChange}
      />
    </LoadingWrapper>
  );
});

function buildPermissionMap(accountGroups, zones) {
  const map = {};

  accountGroups.forEach((ac) => {
    map[`${ac.ZoneID}.${ac.GroupID}`] = true;
  });

  accountGroups.forEach((ac) => {
    if (ac.ZoneID === 0) {
      zones.forEach((zone) => {
        map[`${zone.ZoneID}.${ac.GroupID}`] = true;
      });
    }
  });

  return map;
}

function permissionMapToAccountGroups(permissionMap) {
  // pre-process check all
  Object.keys(permissionMap).forEach((key) => {
    const [zoneId, groupId] = key.split('.');

    if (!permissionMap[key] || !groupId) {
      return;
    }

    if (zoneId !== '0' && permissionMap[`0:${groupId}`]) {
      delete permissionMap[key];
    }
  });

  // group inheritance
  Object.keys(permissionMap).forEach((key) => {
    const [zoneId, groupId] = key.split('.');

    if (!permissionMap[key] || !groupId) {
      return;
    }

    if (groupId === String(GroupRole.DEPLOYER)) {
      permissionMap[`${zoneId}.${GroupRole.APPROVER}`] = true;
      permissionMap[`${zoneId}.${GroupRole.WRITER}`] = true;
    } else if (groupId === String(GroupRole.APPROVER)) {
      permissionMap[`${zoneId}.${GroupRole.WRITER}`] = true;
    }
  });

  const accountGroups = [];

  Object.keys(permissionMap).forEach((key) => {
    const [zoneId, groupId] = key.split('.');

    if (!permissionMap[key] || !groupId) {
      return;
    }

    accountGroups.push({ ZoneID: parseInt(zoneId), GroupID: parseInt(groupId) });
  });

  return accountGroups;
}
