import { gold, green } from '@ant-design/colors';
import { Icon, Popover } from 'antd';
import makeStyles from 'core/common/makeStyles';
import AccountDisplay from 'core/components/account/AccountDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import React, { memo } from 'react';
import HtmlDisplay from '../common/HtmlDisplay';

const useStyles = makeStyles(() => ({
  noteContainer: {
    maxWidth: 300,
    maxHeight: 400,
    overflowY: 'auto',

    '& > div:not(:first-child)': {
      marginTop: 20,
    },
  },
  noteInfo: {
    color: gold[9],
  },
  icon: {
    fontSize: 18,
    color: green[7],
    marginLeft: 5,
  },
}));

export default memo(function ContentNoteDisplayAsIcon({ content }) {
  const classes = useStyles();

  if (!content.ContentNote || content.ContentNote.length === 0) {
    return null;
  }

  const sortedNotes = content.ContentNote.slice();

  sortedNotes.sort((a, b) => b.CreatedDate - a.CreatedDate);

  const notes = (
    <div className={classes.noteContainer}>
      {sortedNotes.map(note => (
        <div key={note.NoteID}>
          <span className={classes.noteInfo}>
            <i><DateTimeDisplay timestamp={note.CreatedDate} /></i> bởi <b><AccountDisplay id={note.CreatorID} /></b>:
          </span>
          <HtmlDisplay html={note.Note} />
        </div>
      ))}
    </div>
  );

  return (
    <Popover title="Ghi chú bài viết" content={notes}>
      <Icon type="message" className={classes.icon} />
    </Popover>
  );
});
