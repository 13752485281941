import React, { useState, useEffect } from 'react';
import { getMapAdsPositionCache } from 'core/engine/ads-position/caching';

export default function AdsPositionDisplay({ id, ...props }) {
  const [name, setName] = useState('Loading...');

  useEffect(() => {
    getMapAdsPositionCache().then((map) => setName(map[id] ? map[id].Name : '#'));
  }, [id]);

  return (
    <span {...props}>{name}</span>
  );
}
