import { Checkbox, Form, Input, InputNumber } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import Selector from 'core/components/common/Selector';
import { AdsPositionType, AdsPositionTypeLabel } from 'core/engine/ads-position/constants';
import React from 'react';

export default function AdsPositionForm({ form, onSubmit, adsPosition }) {
  const { getFieldDecorator } = form;
  getFieldDecorator('PositionID', { initialValue: adsPosition.PositionID });

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Tên vị trí">
        {getFieldDecorator('Name', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
          initialValue: adsPosition.Name,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Mã vị trí">
        {getFieldDecorator('Code', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 50, message: 'Tối đa 50 kí tự!' }],
          initialValue: adsPosition.Code,
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Rộng">
        {getFieldDecorator('Width', {
          initialValue: adsPosition.Width || 0,
          rules: [{ required: true, message: 'Bắt buộc!' }],
        })(
          <InputNumber />
        )}
      </Form.Item>
      <Form.Item label="Cao">
        {getFieldDecorator('Height', {
          initialValue: adsPosition.Height || 0,
          rules: [{ required: true, message: 'Bắt buộc!' }],
        })(
          <InputNumber />
        )}
      </Form.Item>
      <Form.Item label="Kiểu">
        {getFieldDecorator('Type', {
          initialValue: adsPosition.Type || AdsPositionType.Default,
        })(
          <Selector options={AdsPositionTypeLabel} valueIsNumber />
        )}
      </Form.Item>
      <Form.Item label="Thời gian">
        {getFieldDecorator('Interval', {
          initialValue: adsPosition.Interval || 0,
          rules: [{ required: true, message: 'Bắt buộc!' }],
        })(
          <InputNumber />
        )}
      </Form.Item>
      <Form.Item label="Sử dụng">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: isNullOrUndefined(adsPosition.Enabled) ? true : adsPosition.Enabled,
        })(
          <Checkbox />
        )}
      </Form.Item>
    </Form>
  );
}
