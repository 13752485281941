import { SET_SERVER_CONFIG } from '../actions/config';

const initialState = {
  serverConfig: null,
};

export default function (state = initialState, action) {

  switch (action.type) {
    case SET_SERVER_CONFIG:
      return { serverConfig: action.serverConfig };

    default:
      return state;
  }
}
