export const SeoEntryType = {
  UNKNOWN: 0,
  ZONE: 1,
  TOPIC: 2,
  CONTENT: 3,
  TAG: 4,
  REPORT: 5,
  REGION: 6,
  AUTHOR: 7,
};
