import { Descriptions } from 'antd';
import { formatDateTime } from 'core/common/datetimeHelper';
import { emptyArray } from 'core/common/empty';
import { commonColumns } from 'core/common/listUtils';
import lodash from 'core/common/lodash';
import ChartViewer from 'core/components/chart/ChartViewer';
import Container from 'core/components/common/Container';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import NumberDisplay from 'core/components/common/NumberDisplay';
import RangeDateSelector from 'core/components/common/RangeDateSelector';
import Selector from 'core/components/common/Selector';
import BaseTable from 'core/components/table/BaseTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import reportApi from 'core/engine/report/api';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import FlexPushRight from 'core/components/common/FlexPushRight';
import FeatureFlip from 'core/components/common/FeatureFlip';
import AsyncButton from 'core/components/common/AsyncButton';
import { asyncAction } from 'core/common/async';
import useServerFeature from 'core/hooks/useServerFeature';

function listFn({ filters }) {
  return reportApi.contentByAccounts({
    accountsIds: '',
    group: filters.groupBy,
    fromDate: filters.rangeDate[0].startOf('day').toDate().getTime(),
    toDate: filters.rangeDate[1].endOf('day').toDate().getTime(),
  }).then((resp) => {
    resp.data.items.forEach((item) => {
      item.AvgViewCount = item.ContentCount > 0 ? Math.round(item.ViewCount / item.ContentCount) : 0;
    });

    return resp;
  });
}

const { Item } = Descriptions;

export default function ReportContentByAccountsView({
  showPivotDate = true,
  showCreator = true,
}) {
  const defaultRangeDate = useMemo(() => [moment().add(-1, 'month'), moment()], []);

  const [sorted, setSorted] = useState([{ id: 'ContentCount', desc: true }]);
  const [chartKey, setChartKey] = useState('ContentCount');
  const [generalReport, setGeneralReport] = useState({});
  const [chartKeyOptions, setChartKeyOptions] = useState({});

  const hasComment = useServerFeature('has-comment', true);

  const listProps = useList({
    autoLoad: true,
    listFn,
    defaultFilters: {
      groupBy: 'day',
      rangeDate: defaultRangeDate,
    },
  });

  const isGrouping = useMemo(() => Boolean(listProps.filters.groupBy), [listProps.filters.groupBy]);
  const dateFormat = useMemo(() => {
    if (listProps.filters.groupBy === 'month') {
      return 'MM/YYYY';
    }

    return 'DD/MM/YYYY';
  }, [listProps.filters.groupBy]);

  const sortedItems = useMemo(() => {
    const items = listProps.items.slice();
    const sorting = sorted[0] || { id: 'ContentCount', desc: true };

    items.sort((item1, item2) => {
      if (item1.StatsDate !== item2.StatsDate) {
        return item2.StatsDate - item1.StatsDate;
      }

      return (item2[sorting.id] - item1[sorting.id]) * (sorting.desc ? 1 : -1);
    });

    return items;
  }, [sorted, listProps.items]);

  const chartData = useMemo(() => {
    if (!listProps.filters.groupBy) {
      return [];
    }

    const defaultMapItem = listProps.items.reduce((previous, current) => {
      previous[current.UserName] = 0;
      return previous;
    }, {});
    const mapByDate = {};
    const sortedKeys = [];

    listProps.items.forEach((item) => {
      if (!mapByDate[item.StatsDate]) {
        mapByDate[item.StatsDate] = { ...defaultMapItem };
        sortedKeys.push(item.StatsDate);
      }

      mapByDate[item.StatsDate][item.UserName] = item[chartKey] || 0;
    });

    return sortedKeys.reverse().map(key => {
      mapByDate[key].StatsDate = formatDateTime(key, 'DD/MM/YYYY');
      return mapByDate[key];
    });
  }, [listProps.items, chartKey, listProps.filters.groupBy]);

  useEffect(() => {
    const chartKeyOptions = {
      ContentCount: 'Số bài',
      ViewCount: 'Lượt xem',
      AvgViewCount: 'Lượt xem TB',
      CommentCount: 'Bình luận',
      Royalty: 'Nhuận bút',
    };

    if (!hasComment) {
      delete chartKeyOptions.CommentCount;
    }

    setChartKeyOptions(chartKeyOptions);
  }, [hasComment]);

  useEffect(() => {
    const reports = {};
    const items = listProps.items;

    const contentCount = lodash.sum(items.map(item => item.ContentCount));
    const viewCount = lodash.sum(items.map(item => item.ViewCount));

    reports.ContentCount = contentCount;
    reports.ViewCount = viewCount;
    reports.AvgViewCount = lodash.round(viewCount / contentCount);
    reports.CommentCount = lodash.sum(items.map(item => item.CommentCount));
    reports.Royalty = lodash.sum(items.map(item => item.Royalty));

    setGeneralReport(reports);
  }, [listProps.items]);

  const columns = useMemo(() => {
    return [
      showPivotDate ? {
        Header: 'Thời gian',
        accessor: 'StatsDate',
        PivotValue: ({ value }) => <b><DateTimeDisplay timestamp={value} format={dateFormat} /></b>,
        width: 150,
        show: isGrouping,
      } : {
        Header: 'Thời gian',
        accessor: 'StatsDate',
        Cell: ({ value }) => <b><DateTimeDisplay timestamp={value} format={dateFormat} /></b>,
        width: 150,
        show: isGrouping,
      },
      showCreator && {
        Header: 'Người tạo',
        accessor: 'UserName',
        width: 120,
        aggregate: vals => `${vals.length} tài khoản`,
        style: { fontWeight: isGrouping ? 'normal' : 'bold' },
      },
      {
        ...commonColumns.formatNumber,
        Header: 'Số bài',
        accessor: 'ContentCount',
        aggregate: vals => lodash.sum(vals),
        sortable: true,
      },
      {
        ...commonColumns.formatNumber,
        Header: 'Lượt xem',
        accessor: 'ViewCount',
        aggregate: vals => lodash.sum(vals),
        sortable: true,
      },
      {
        ...commonColumns.formatNumber,
        Header: 'Lượt xem TB',
        accessor: 'AvgViewCount',
        aggregate: (vals, row) => {
          const totalView = lodash.sum(row.map(item => item._original.ViewCount));
          const totalContent = lodash.sum(row.map(item => item._original.ContentCount));

          return lodash.round(totalView / totalContent);
        },
        sortable: true,
      },
      hasComment && {
        ...commonColumns.formatNumber,
        Header: 'Bình luận',
        accessor: 'CommentCount',
        aggregate: vals => lodash.sum(vals),
        sortable: true,
      },
      {
        ...commonColumns.formatNumber,
        Header: 'Nhuận bút',
        accessor: 'Royalty',
        aggregate: vals => lodash.sum(vals),
        sortable: true,
      },
    ].filter(Boolean);
  }, [isGrouping, dateFormat]);

  const onGroupByFilter = useCreateOnFilterChange(listProps, 'groupBy');

  return (
    <Container pageTitle="Thống kê theo tài khoản">
      <TableToolbarWrapper>
        <Selector
          value={listProps.filters.groupBy}
          onChange={onGroupByFilter}
          options={{ day: 'Xem theo ngày', week: 'Xem theo tuần', month: 'Xem theo tháng' }}
          width={150}
          emptyLabel="Tổng hợp"
          emptyValue=""
        />
        <Selector
          value={chartKey}
          onChange={setChartKey}
          options={chartKeyOptions}
          width={150}
          disabled={!listProps.filters.groupBy}
        />
        <RangeDateSelector
          value={listProps.filters.rangeDate}
          onChange={onGroupByFilterChange}
        />
        <FlexPushRight />
        <FeatureFlip name="export-report">
          <AsyncButton
            icon="export"
            type="primary"
            onClick={handleExport}
            title="Xuất Excel"
          >
            Xuất Excel
          </AsyncButton>
        </FeatureFlip>
      </TableToolbarWrapper>
      {listProps.filters.groupBy && (
        <ChartViewer
          title={`So sánh theo: ${chartKeyOptions[chartKey]}`}
          xAxisKey="StatsDate"
          data={chartData}
          sum={false}
        />
      )}
      <Descriptions bordered={true} column={5}>
        <Item label="Tổng bài"><NumberDisplay value={generalReport.ContentCount} /></Item>
        <Item label="Tổng xem"><NumberDisplay value={generalReport.ViewCount} /></Item>
        <Item label="Lượt xem trung bình"><NumberDisplay value={generalReport.AvgViewCount} /></Item>
        {hasComment && (
          <Item label="Tổng bình luận"><NumberDisplay value={generalReport.CommentCount} /></Item>
        )}
        <Item label="Tổng nhuận bút"><NumberDisplay value={generalReport.Royalty} /></Item>
      </Descriptions>
      <br />
      <BaseTable
        columns={columns}
        {...useListProps(listProps)}
        data={sortedItems}
        sorted={sorted}
        onSortedChange={setSorted}
        fullHeight={false}
        showPageSizeOptions={false}
        showPageJump={false}
        pages={1}
        pivotBy={isGrouping && showPivotDate ? ['StatsDate'] : emptyArray}
      />
    </ Container>
  );

  function onGroupByFilterChange(value) {
    if (value && value.length && listProps.filters.groupBy) {
      const groupBy = listProps.filters.groupBy;
      value = [value[0].startOf(groupBy), value[1].endOf(groupBy)];
    }

    listProps.addFilter('rangeDate', value);
  }

  function handleExport() {
    const listFilters = {
      fromDate: listProps.filters.rangeDate[0].startOf('day').toDate().getTime(),
      toDate: listProps.filters.rangeDate[1].endOf('day').toDate().getTime(),
    };

    return asyncAction('Xuất thống kê theo tài khoản', () => {
      return reportApi.exportContentByAccounts({
        filters: listFilters,
        filename: `Thống kê theo tài khoản từ ${listProps.filters.rangeDate[0].format('DD-MM-YYYY')} đến ${listProps.filters.rangeDate[1].format('DD-MM-YYYY')}.xlsx`,
      });
    });
  }
}
