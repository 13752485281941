import { useEffect } from 'react';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';

const SCROLL_DEVIATION = 5;

export default function useStickyComponent({
  container,
  target,
  offsetTop = 0,
  offsetBottom = 0,
}) {

  useEffect(() => {
    let prevScrollY = 1;
    let stickPosition = 0;
    const media = window.matchMedia('(min-width: 992px)');

    const handleScroll = () => {
      const currentScrollY = container.scrollTop;

      const absoluteChange = currentScrollY > prevScrollY ? currentScrollY - prevScrollY : prevScrollY - currentScrollY;

      if (absoluteChange > SCROLL_DEVIATION) {
        if (currentScrollY < prevScrollY) {
          stickPosition = scrollUpwards(stickPosition, target, absoluteChange);
        } else {
          stickPosition = scrollDownwards(stickPosition, target, container, absoluteChange);
        }

        prevScrollY = currentScrollY;
      }
    };

    const mediaChange = (event) => {
      if (event.matches) {
        container.addEventListener('scroll', handleScroll, { passive: true });
        stickPosition = stickyTopPositioning(target);
      } else {
        container.removeEventListener('scroll', handleScroll);
        removePositioning(target);
      }
    };

    if (media.matches) {
      container.addEventListener('scroll', handleScroll, { passive: true });
    }

    media.addListener(mediaChange);

    return () => {
      container.removeEventListener('scroll', handleScroll);
      media.removeListener(mediaChange);
    };
  }, [container, target]);

  function scrollUpwards(stickPos, element, absoluteChange) {
    if (stickPos === 1 || !(target && target.parentNode)) return stickPos;

    const aboveAside = element.getBoundingClientRect().top - element.parentNode.offsetTop > 0;

    if (aboveAside || absoluteChange > element.offsetHeight) {
      return stickyTopPositioning(element);
    }

    return relativePositioning(stickPos, element);
  }

  function scrollDownwards(stickPos, element, container, absoluteChange) {
    if (stickPos === -1 || !(target && target.parentNode) || !container) return stickPos;

    const topBound = element.offsetTop + element.offsetHeight - container.offsetHeight - element.parentNode.offsetTop;
    const belowTopBound = container.scrollTop > topBound;

    if (belowTopBound || absoluteChange > element.offsetHeight) {
      return stickyBottomPositioning(element);
    }

    return relativePositioning(stickPos, element);
  }

  function stickyTopPositioning(element) {
    element.style.position = 'sticky';
    element.style.position = '-webkit-sticky'; // css for Safari (will not overwrite in other browsers)
    element.style.top = offsetTop;
    element.style.bottom = '';
    element.style.alignSelf = 'flex-start';
    return 1;
  }

  function stickyBottomPositioning(element) {
    element.style.position = 'sticky';
    element.style.position = '-webkit-sticky'; // css for Safari (will not overwrite in other browsers)
    element.style.top = '';
    element.style.bottom = offsetBottom + 'px';
    element.style.alignSelf = 'flex-end';
    return -1;
  }

  function relativePositioning(stickPos, element) {
    if (stickPos === 0) return stickPos;

    element.style.top = element.offsetTop - element.parentNode.offsetTop + 'px';
    element.style.position = 'relative';
    element.style.bottom = '';
    element.style.alignSelf = '';

    return 0;
  }

  function removePositioning(element) {
    element.style.position = '';
    element.style.top = '';
    element.style.bottom = '';
    element.style.alignSelf = '';
  }
}
