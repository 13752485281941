import { formatByte } from '@bm/common';
import { Empty, Icon, Typography } from 'antd';
import { asyncAction } from 'core/common/async';
import classnames from 'core/common/classnames';
import commonStyles, { commonColors } from 'core/common/commonStyles';
import { emptyFunction } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import { secondsToDurationString } from 'core/common/utils';
import AccountDisplay from 'core/components/account/AccountDisplay';
import AudioPlayer from 'core/components/common/AudioPlayer';
import CopyToClipboardButton from 'core/components/common/CopyToClipboardButton';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import FlexPushRight from 'core/components/common/FlexPushRight';
import Separator from 'core/components/common/Separator';
import TagGroupInput from 'core/components/common/TagGroupInput';
import VideoPlayer from 'core/components/common/VideoPlayer';
import FileVideoSelectInVideoAvatarButton from 'core/components/file-manager/video/FileVideoSelectInVideoAvatarButton';
import FileVideoSelectLibraryAvatarButton from 'core/components/file-manager/video/FileVideoSelectLibraryAvatarButton';
import fileApi from 'core/engine/file/api';
import { FileType } from 'core/engine/file/constants';
import fileService from 'core/engine/file/service';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: 300,
    overflow: 'hidden',
    marginLeft: 10,
    border: '1px solid #d4d4d4',
    borderRadius: 4,
  },
  coverWrapper: {
    ...commonStyles.flexAllCentered,
    width: '100%',
    height: 200,
    borderBottom: '1px solid #d4d4d4',
    backgroundColor: '#000',

    '& video, & img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  videoPlayer: {
    width: '100%',
    height: '100%',
    '& img, & video': {
      width: '100%',
      height: '100%',
    },
  },
  body: {
    padding: '20px 10px',
    overflow: 'auto',
    height: 'calc(100% - 50px)',
  },
  hasCover: {
    height: 'calc(100% - 250px)',
  },
  name: {
    fontSize: 'larger',
    wordBreak: 'break-all',
  },
  date: {
    fontStyle: 'italic',
  },
  account: {
    color: commonColors.link,
    cursor: 'pointer',
  },
  titleInput: {
    ...commonStyles.flexRow,
  },
  inputGroup: {
    ...commonStyles.flexRow,
    height: 25,
  },
  infoRow: {
    ...commonStyles.flexRow,
    justifyContent: 'space-between',
    height: 25,
  },
  actions: {
    ...commonStyles.flexRow,
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #e8e8e8',
    height: 40,

    '& .anticon': {
      color: 'rgba(0, 0, 0, 0.45)',
      fontSize: 15,
    },

    '& > *': {
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      lineHeight: '40px',
      textAlign: 'center',

      '&:not(:last-child)': {
        borderRight: '1px solid #e8e8e8',
      },
    },
  },
}));

function fileAction(title, action) {
  return asyncAction(title, action, { silentSuccess: true, silentProgress: true });
}

const openNewTabIconStyle = { transform: 'rotate(90deg)' };

export default function VideoAudioManagerSidebar({
  currentFile,
  onFilterOwnerId = emptyFunction,
  onFileUpdated = emptyFunction,
}) {
  const classes = useStyles();
  const [file, setFile] = useState(currentFile);

  const directUrl = useMemo(() => {
    if (file) {
      return fileService.buildDirectUrl(file);
    }
  }, [file]);

  useEffect(() => {
    setFile(currentFile);
  }, [currentFile]);

  if (!file) {
    return (
      <div className={classes.container}>
        <Empty description="Chọn một tệp" />
      </div>
    );
  }

  let actions;
  let cover;

  if (currentFile.Type === FileType.VIDEO) {
    actions = (
      <Fragment>
        <FileVideoSelectLibraryAvatarButton file={file} onUpdated={handleFileUpdated} key="library-picker" />
        <FileVideoSelectInVideoAvatarButton file={file} onUpdated={handleFileUpdated} key="in-video-picker" />
        <CopyToClipboardButton text={directUrl}>
          <Icon type="link" key="link" title="Sao chép link video" />
        </CopyToClipboardButton>
        <a href={directUrl} target="_blank" rel="noopener noreferrer">
          <Icon style={openNewTabIconStyle} type="select" key="select" title="Mở video trong tab mới" />
        </a>
      </Fragment>
    );

    cover = (
      <VideoPlayer className={classes.videoPlayer} src={file.url} poster={file.Meta.avatarUrl} />
    );
  } else if (currentFile.Type === FileType.AUDIO) {
    actions = (
      <Fragment>
        <CopyToClipboardButton text={directUrl}>
          <Icon type="link" key="link" title="Sao chép link Audio" />
        </CopyToClipboardButton>
        <a href={directUrl} target="_blank" rel="noopener noreferrer">
          <Icon style={openNewTabIconStyle} type="select" key="select" title="Mở audio trong tab mới" />
        </a>
      </Fragment>
    );

    cover = (
      <AudioPlayer className={classes.audioPlayer} src={file.url} />
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.actions}>{actions}</div>
      {cover && <div className={classes.coverWrapper}>{cover}</div>}
      <div className={classnames(classes.body, cover && classes.hasCover)}>
        <div className={classes.titleInput}>
          <FlexPushRight />
          <Typography.Paragraph className={classes.name} editable={{ onChange: handleNameChange }} >{file.Name}</Typography.Paragraph>
        </div>
        <div className={classes.infoRow}>
          <span>Dung lượng</span>
          <span>{formatByte(file.Size)}</span>
        </div>
        <div className={classes.infoRow}>
          <span>Thời lượng</span>
          <span>{secondsToDurationString(file.Meta.duration)}</span>
        </div>
        <div className={classes.infoRow}>
          <span>Bitrate</span>
          <span>{file.Meta.bitrate || 0}</span>
        </div>
        <div className={classes.infoRow}>
          <span>Tạo bởi</span>
          <span title="Lọc theo tài khoản này" onClick={() => onFilterOwnerId(file.OwnerID)}><AccountDisplay className={classes.account} id={file.OwnerID} /></span>
        </div>
        <div className={classes.infoRow}>
          <span>Ngày tạo</span>
          <span className={classes.date}><DateTimeDisplay timestamp={file.CreatedDate} /></span>
        </div>
        <div className={classes.inputGroup}>
          <span>Nguồn</span>
          <Separator />
          <FlexPushRight />
          <Typography.Paragraph editable={{ onChange: handleSourceChange }} >{file.Source || ''}</Typography.Paragraph>
        </div>
        <div className={classes.inputGroup}>
          <span>Thẻ</span>
          <FlexPushRight />
          <TagGroupInput value={file.Tags} onChange={handleTagsChange} />
        </div>
      </div>
    </div>
  );

  function handleNameChange(value) {
    const newFile = {
      ...file,
      Name: value,
    };

    setFile(newFile);

    return fileAction('Cập nhật tên', () => {
      return fileApi.update({
        FileID: file.FileID,
        Name: value,
      })
        .then(() => {
          onFileUpdated(newFile);
        })
        .catch((err) => {
          setFile(file);
          return Promise.reject(err);
        });
    });
  }

  function handleSourceChange(value) {
    if (file.Source === value) {
      return;
    }

    const newFile = {
      ...file,
      Source: value,
    };

    setFile(newFile);

    return fileAction('Cập nhật nguồn', () => {
      return fileApi.update({
        FileID: file.FileID,
        Source: value,
      })
        .then(() => {
          onFileUpdated(newFile);
        })
        .catch((err) => {
          setFile(file);
          return Promise.reject(err);
        });
    });

  }

  function handleTagsChange(value) {
    const newFile = {
      ...file,
      Tags: value,
    };

    setFile(newFile);

    return fileAction('Cập nhật thẻ', () => {
      return fileApi.update({
        FileID: file.FileID,
        Tags: value,
      })
        .then(() => {
          onFileUpdated(newFile);
        })
        .catch((err) => {
          setFile(file);
          return Promise.reject(err);
        });
    });
  }

  function handleFileUpdated(file) {
    setFile(file);
    onFileUpdated(file);
  }
}
