import React from 'react';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';

const useStyles = makeStyles(() => ({
  code: {
    ...commonStyles.code,
  },
}));

export default function CodeTag({ tagName = 'span', children, ...props }) {
  const TagName = tagName;

  const classes = useStyles();

  return (
    <TagName className={classes.code} {...props}>{children}</TagName>
  );
}
