import { Tooltip } from 'antd';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { DEFAULT_CONTENT_MAX_LEVEL } from 'core/config';
import { getMapZoneCache } from 'core/engine/zone/caching';
import LoadingWrapper from '../common/LoadingWrapper';
import Selector from '../common/Selector';

export default forwardRef(function ContentLevelSelector({
  value: currentLevel,
  onChange,
  zoneId,
  maxLevel = DEFAULT_CONTENT_MAX_LEVEL,
  disabled = false,
}, ref) {
  const [isLoading, setIsLoading] = useState(false);
  const [tooltip, setTooltip] = useState('');

  const levelOptions = useMemo(() => {
    return Array(maxLevel).fill('').map((_, index) => ({
      label: index + 1,
      value: index + 1,
    }));
  }, [maxLevel]);

  useEffect(() => {
    getMapZoneCache()
      .then(map => setTooltip(`Thứ tự tin trong chuyên mục ${map[zoneId] ? map[zoneId].Name : '#'}`));
  }, [zoneId]);

  return (
    <LoadingWrapper loading={isLoading}>
      <Tooltip title={tooltip}>
        <Selector
          disabled={disabled || !zoneId}
          ref={ref}
          options={levelOptions}
          value={currentLevel}
          onChange={handleChange}
        />
      </Tooltip>
    </LoadingWrapper>
  );

  function handleChange(level) {
    setIsLoading(true);

    Promise.resolve()
      .then(() => onChange(zoneId, level))
      .then(() => setIsLoading(false));
  }
});
