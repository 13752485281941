import { Button } from 'antd';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import React, { Fragment } from 'react';
import VoteLog from './VoteLog';

export default function VoteLogDialogButton({ voteId }) {
  const { open, handleOpen, handleClose } = useDialog();

  return (
    <Fragment>
      <Button icon="history" title="Truy vết bình chọn" onClick={handleOpen} />
      <BaseDialog
        visible={open}
        title="Truy vết bình chọn"
        onCancel={handleClose}
        width={1000}
        footer={[
          <Button key="ok" type="primary" onClick={handleClose}>OK</Button>,
        ]}
        fullHeightFixed={true}
      >
        <VoteLog voteId={voteId} />
      </BaseDialog>
    </Fragment>
  );
}
