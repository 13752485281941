import React from 'react';
import BaseTable from 'core/components/table/BaseTable';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DeleteButton from 'core/components/common/DeleteButton';
import { OnEnterFilterForList } from 'core/components/common/OnEnterFilter';
import { commonColumns } from 'core/common/listUtils';
import { AdsPositionTypeLabel } from 'core/engine/ads-position/constants';
import { connectSelectorFilterForList } from 'core/common/hocs';
import AdsPositionFormDialogButton from './AdsPositionFormDialogButton';
import { Checkbox } from 'antd';

export default function AdsPositionList({ onUpdate, onDelete, ...props }) {
  const columns = [
    commonColumns.order,
    {
      Header: 'Tên vị trí',
      accessor: 'Name',
      filterable: true,
      Filter: OnEnterFilterForList,
      minWidth: 150,
    },
    {
      id: 'Code',
      Header: 'Mã vị trí',
      accessor: 'Code',
      filterable: true,
      Filter: OnEnterFilterForList,
      maxWidth: 250,
      minWidth: 150,
    },
    {
      id: 'Type',
      Header: 'Kiểu',
      maxWidth: 150,
      minWidth: 130,
      filterable: true,
      Filter: connectSelectorFilterForList({ options: AdsPositionTypeLabel, valueIsNumber: true }),
      Cell: ({ original }) => AdsPositionTypeLabel[original.Type],
    },
    {
      id: 'Width',
      Header: 'Rộng',
      accessor: 'Width',
      maxWidth: 80,
      minWidth: 50,
    },
    {
      id: 'Height',
      Header: 'Cao',
      accessor: 'Height',
      maxWidth: 80,
      minWidth: 50,
    },
    {
      id: 'Interval',
      Header: 'Thời gian',
      accessor: 'Interval',
      width: 75,
    },
    {
      Header: 'Sử dụng',
      Cell: ({ original }) => <Checkbox checked={original.Enabled} />,
      width: 75,
      ...commonColumns.allCentered,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <AdsPositionFormDialogButton adsPosition={original} onDone={onUpdate} />
          <DeleteButton title={`Xóa vị trí: ${original.Name}?`} onDelete={() => onDelete(original)} />
        </ActionsCellWrapper>
      ),
      maxWidth: 120,
      minWidth: 100,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
