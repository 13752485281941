import lodash from 'core/common/lodash';
import regionApi from './api';
import { buildTreeList } from 'core/common/utils';

export const getAllRegionsCache = lodash.memoize(() =>
  regionApi.getListAll().then(resp => resp.data.items)
);

export const getTreeListRegionCache = lodash.memoize(() =>
  getAllRegionsCache().then(items => buildTreeList(items, { idField: 'RegionID' }))
);

export const getMapRegionCache = lodash.memoize(() =>
  getAllRegionsCache().then(regions => lodash.keyBy(regions, 'RegionID'))
);

export const getRegionNameCache = lodash.memoize((id) =>
  getMapRegionCache().then(map => map[id] ? map[id].Name : '#')
);
