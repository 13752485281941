import { Checkbox } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { emptyFunction, emptyObject } from 'core/common/empty';
import BaseTable from 'core/components/table/BaseTable';
import makeStyles from 'core/common/makeStyles';

function checkOrRemove(obj, key, checked) {
  if (checked) {
    obj[key] = true;
  } else {
    delete obj[key];
  }
}

const useStyles = makeStyles(() => ({
  stickyTable: {
    '& .rt-table .rt-thead': {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      backgroundColor: '#fff',
    },
    '& .rt-tbody': {
      overflow: 'unset !important',
    },
  },
}));

export default function PermissionTable({
  commandIdField,
  commands,
  rights,
  commandLabel = 'Chức năng',
  checkAllRightsLabel,
  checkAllCommandsId,
  defaultCheckedMap = emptyObject,
  onChange = emptyFunction,
}) {
  const classes = useStyles();
  const [checkedMap, setCheckedMap] = useState(defaultCheckedMap);

  const handleChange = useCallback((event) => {
    const { checked, name } = event.target;
    const [commandId, rightId] = name.split('.');

    setCheckedMap((checkedMap) => {
      const updatedCheckedMap = { ...checkedMap };

      if (rightId && commandId) {
        updatedCheckedMap[commandId] = true;
        checkOrRemove(updatedCheckedMap, `${commandId}.${rightId}`, checked);

        if (checkAllCommandsId) {
          if (commandId === checkAllCommandsId) {
            checkOrRemove(updatedCheckedMap, `${checkAllCommandsId}.${rightId}`, checked);
            commands.forEach((command) => {
              checkOrRemove(updatedCheckedMap, `${command[commandIdField]}.${rightId}`, checked);
            });
          }

          if (updatedCheckedMap[`${checkAllCommandsId}.${rightId}`] && !checked) {
            updatedCheckedMap[`${checkAllCommandsId}.${rightId}`] = false;
          } else {
            let checkedCounter = 0;

            Object.keys(updatedCheckedMap).forEach((key) => {
              if (updatedCheckedMap[key] && key.split('.')[1] === rightId) {
                checkedCounter += 1;
              }
            });

            if (checkedCounter === commands.length - 1) {
              updatedCheckedMap[`${checkAllCommandsId}.${rightId}`] = true;
            }
          }
        }
      } else if (commandId) {
        checkOrRemove(updatedCheckedMap, commandId, checked);
        rights.forEach((right) => {
          checkOrRemove(updatedCheckedMap, `${commandId}.${right.id}`, checked);
        });
      }

      onChange(updatedCheckedMap);

      return updatedCheckedMap;
    });
  }, [onChange, rights, commands]);

  const columns = useMemo(() => {
    return [
      {
        Header: commandLabel,
        headerStyle: {
          position: 'sticky',
          left: 0,
          backgroundColor: '#fff',
          zIndex: 2,
        },
        style: {
          position: 'sticky',
          left: 0,
          backgroundColor: '#fff',
          zIndex: 1,
        },
        Cell: ({ original }) => (' ---- '.repeat(original.Level) + original.Name),
        minWidth: 150,
      },
      {
        Header: checkAllRightsLabel,
        show: !!checkAllRightsLabel,
        minWidth: 100,
        maxWidth: 150,
        Cell: ({ original }) => (
          <Checkbox
            name={`${original[commandIdField]}`}
            checked={!!checkedMap[original[commandIdField]]}
            onChange={handleChange}
          />
        ),
      },
      ...rights.map(right => ({
        Header: right.label,
        minWidth: 100,
        maxWidth: 150,
        Cell: ({ original }) => (
          <Checkbox
            name={`${original[commandIdField]}.${right.id}`}
            checked={!!checkedMap[`${original[commandIdField]}.${right.id}`]}
            onChange={handleChange}
          />
        ),
      })),
    ];
  }, [checkAllRightsLabel, checkedMap, commandLabel, handleChange, commandIdField, rights]);

  useEffect(() => {
    setCheckedMap(defaultCheckedMap);
  }, [defaultCheckedMap]);

  return (
    <BaseTable
      className={classes.stickyTable}
      columns={columns}
      data={commands}
      showPagination={false}
    />
  );
}
