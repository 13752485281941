import { Badge, Icon } from 'antd';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import dashboardApi from 'core/engine/dashboards/api';
import {
  CommentIcon,
  DraftIcon,
  ReturnApproveIcon,
  ReturnSubmitIcon,
  WaitApproveIcon,
  WaitPublicIcon,
} from '../common/SvgIcons';
import useAuth from 'core/hooks/useAuth';
import useServerFeature from 'core/hooks/useServerFeature';
import useWebSocket from 'core/hooks/useWebSocket';
import { EventType } from 'core/engine/tool/constants';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexRow,
    height: '100%',

    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  icon: {
    color: '#fff',
    '& > svg': {
      width: 24,
      height: 24,
    },
  },
  item: {
    ...commonStyles.flexAllCentered,
    flexDirection: 'column',
    cursor: 'pointer',
    height: '100%',
    width: 105,
    color: '#ccc',
    lineHeight: '22px',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#132a40',
    },

    '@media (max-width: 1200px)': {
      width: 65,
    },
  },
  title: {
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
}));

export default function HeaderNotification() {
  const classes = useStyles();

  const { loggedInUser } = useAuth();
  const [dataStats, setDataStats] = useState({});
  const hasComment = useServerFeature('has-comment', true);

  const loadData = useCallback(() => {
    dashboardApi.getStats().then((resp) => {
      setDataStats(resp.data);
    });
  }, []);

  useEffect(loadData, [loggedInUser]);

  useWebSocket({
    topic: EventType.HEADER_NOTI_UPDATE,
    isPersonal: true,
    onMessage: loadData,
  });

  if (!loggedInUser) {
    return null;
  }

  return (
    <div className={classes.container}>
      {Boolean(dataStats.isWriter) && (
        <Fragment>
          <BadgeDisplay title="Biên tập" link="/contents/mylist" number={dataStats.myDraftCount} icon={DraftIcon} />
          <BadgeDisplay
            title="Từ chối duyệt"
            link="/contents/submitedreturnlist"
            number={dataStats.submittedReturnedCount}
            icon={ReturnSubmitIcon}
          />
        </Fragment>
      )}
      {Boolean(dataStats.isApprover) && (
        <Fragment>
          <BadgeDisplay
            title="Từ chối đăng"
            link="/contents/approvedreturnlist"
            number={dataStats.approvedReturnedCount}
            icon={ReturnApproveIcon}
          />
          <BadgeDisplay
            title="Chờ duyệt"
            link="/contents/approvalwaitinglist"
            number={dataStats.submittedCount}
            icon={WaitApproveIcon}
          />
        </Fragment>
      )}
      {Boolean(dataStats.isDeployer) && (
        <BadgeDisplay
          title="Chờ đăng"
          link="/contents/deploywaitinglist"
          number={dataStats.approvedCount}
          icon={WaitPublicIcon}
        />
      )}
      {hasComment && (Boolean(dataStats.isCommentDeployer) || Boolean(dataStats.isCommentApprover)) && (
        <BadgeDisplay
          title="Bình luận"
          link="/operations/comment"
          number={dataStats.waitingCommentCount}
          icon={CommentIcon}
        />
      )}
    </div>
  );
}

function BadgeDisplay({ number, icon, title, link }) {
  const classes = useStyles();

  return (
    <Link className={classes.item} to={link} title={title}>
      <Badge
        offset={[10, 10]}
        style={{ boxShadow: 'none' }}
        count={number <= 0 ? 0 : number}
      >
        <Icon component={icon} className={classes.icon} />
      </Badge>
      <span className={classes.title}>{title}</span>
    </Link>
  );
}
