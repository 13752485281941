import React, { Fragment, useState } from 'react';
import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import Container from 'core/components/common/Container';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import contentSourceApi from 'core/engine/content-source/api';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import ContentSourceFormDialogButton from './ContentSourceFormDialogButton';
import ContentSourceList from './ContentSourceList';
import FlexPushRight from 'core/components/common/FlexPushRight';
import DeleteButton from 'core/components/common/DeleteButton';
import { Button } from 'antd';
import makeStyles from 'core/common/makeStyles';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';

const useStyles = makeStyles(() => ({
  selectedText: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return contentSourceApi.getList({
    limit,
    offset,
    fields: ['SourceID', 'Url', 'Enabled'],
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

const SourceSearchOptions = [
  { value: 'Name', label: 'Tên' },
  { value: 'Url', label: 'Url' },
  { value: 'SourceID', label: 'ID' },
];

export default function ContentSourceListView() {
  const classes = useStyles();

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: {
      SourceID: 'DESC',
    },
    defaultFilters: {
      search: { type: SourceSearchOptions[0].value },
    },
  });

  const [selectedIds, setSelectedIds] = useState([]);

  const handleSearchFilter = useCreateOnFilterChange(listProps, 'search');

  return (
    <Container pageTitle="Quản lý nguồn tin">
      <TableToolbarWrapper>
        {selectedIds.length ? (
          <Fragment>
            <span className={classes.selectedText}><strong>{selectedIds.length}</strong> nguồn tin đã được chọn</span>
            <FlexPushRight />
            <DeleteButton
              title="Xóa nguồn tin"
              onDelete={handleMultiDelete}
              anchorElement={({ onClick }) => (
                <Button
                  icon="delete"
                  onClick={onClick}
                  type="danger"
                  title="Xóa nguồn tin"
                >Xóa nguồn tin</Button>
              )}
            />
          </Fragment>
        ) : (
          <Fragment>
            <ContentSourceFormDialogButton isEditMode={false} onDone={listProps.refresh} />
            <FlexPushRight />
            <SearchFieldWithType
              typeOptions={SourceSearchOptions}
              search={listProps.filters.search}
              onSearch={handleSearchFilter}
              autoFocus
            />
          </Fragment>
        )}
      </TableToolbarWrapper>
      <ContentSourceList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdate={listProps.refresh}
        onDelete={handleDelete}
        selectedIds={selectedIds}
        onSelectedChange={setSelectedIds}
      />
    </Container>
  );

  function handleDelete(contentSource) {
    return asyncAction('Xóa nguồn tin', () => {
      return contentSourceApi.delete(contentSource.SourceID).then(() => {
        listProps.removeItem(contentSource, 'SourceID');
      });
    });
  }

  function handleMultiDelete() {
    return asyncAction('Xoá nguồn tin', () => {
      return contentSourceApi.multiDelete(selectedIds).then(() => {
        setSelectedIds([]);
        listProps.refresh();
      });
    });
  }
}
