import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import AccountSelector from 'core/components/account/AccountSelector';
import ActivitySelector from 'core/components/activity-log/ActivitySelector';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateSelector from 'core/components/common/RangeDateSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import activityLogApi from 'core/engine/activity-log/api';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import useContentFilterHandlers from 'core/modules/contents/hooks/useContentFilterHandlers';
import React from 'react';
import ActivityAddLabelButton from './ActivityAddLabelButton';
import ActivityLogList from './ActivityLogList';

const defaultRangeDate = [null, null];

function listFn({ limit, offset, filters, sorting }) {
  const [listFilters, sortBy] = buildFilterAndSort(filters, sorting);

  return activityLogApi.getList({
    limit,
    offset,
    sortBy,
    filters: listFilters,
  });
}

function buildFilterAndSort(filters, sorting) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;

  if (rangeDate[0] && rangeDate[1]) {
    listFilters.Date = dateRangeToFilter(listFilters.rangeDate);
  }

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  delete listFilters.rangeDate;

  return [listFilters, sortingToSortBy(sorting)];
}

const DEFAULT_REQUESET_PATH_FILTER = 'not:/remote-logs/post/record';

const ActivitySearchOptions = [
  { value: 'ObjectID', label: 'Đối tượng' },
  { value: 'RequestBody', label: 'Nội dung' },
  { value: 'IPAddress', label: 'IP' },
];

export default function NotificationListView() {
  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultFilters: {
      search: { type: ActivitySearchOptions[0].value },
      rangeDate: defaultRangeDate,
      RequestPath: DEFAULT_REQUESET_PATH_FILTER,
    },
    defaultSorting: {
      Date: 'DESC',
    },
  });

  const filterHandlers = useContentFilterHandlers(listProps);

  const handleAccountFilter = useCreateOnFilterChange(listProps, 'AccountID');
  const handleRequestPathFilter = useCreateOnFilterChange(listProps, 'RequestPath');
  const handleRangeDateFilter = useCreateOnFilterChange(listProps, 'rangeDate');

  return (
    <Container pageTitle="Truy vết">
      <TableToolbarWrapper>
        <ActivityAddLabelButton />
        <AccountSelector
          emptyLabel="-- Người dùng --"
          value={listProps.filters.AccountID}
          onChange={handleAccountFilter}
          allowClear
          width={150}
        />
        <ActivitySelector
          emptyValue={DEFAULT_REQUESET_PATH_FILTER}
          emptyLabel="-- Hành động --"
          value={listProps.filters.RequestPath}
          onChange={handleRequestPathFilter}
          width={220}
        />
        <RangeDateSelector
          value={listProps.filters.rangeDate}
          onChange={handleRangeDateFilter}
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={ActivitySearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
      </TableToolbarWrapper>
      <ActivityLogList
        {...useListProps(listProps)}
        heightAdjust={42}
      />
    </Container>
  );
}
