export function parseJson(jsonString = '', defaultValue) {
  try {
    const str = jsonString
      .replace(/(\r\n|\n|\r)/gm, '\\n')
      .replace(/\t/gm, '\\t');

    return JSON.parse(str) || defaultValue;
  } catch (e) {
    if (defaultValue === undefined) {
      throw e;
    }

    return defaultValue;
  }
}
