import { Button, Input, Modal, Checkbox } from 'antd';
import React, { useCallback, Fragment } from 'react';
import makeStyles from 'core/common/makeStyles';
import { ContentAction } from 'core/engine/content/constants';
import useDialog from 'core/hooks/useDialog';
import useForm from 'core/hooks/useForm';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 700,
    marginBottom: 8,
    display: 'block',
  },
  info: {
    marginTop: 10,
  },
}));

export default function ContentFormToolbarUnpublishButton({
  getFormData,
  action,
  onAction,
  className,
  loading,
}) {
  const classes = useStyles();

  const { open, handleClose, handleOpen } = useDialog();
  const { formData, setFieldsData, handleChange } = useForm({});

  const handleClick = useCallback(() => {
    getFormData(false).then((data) => {
      if (data.contentNote) {
        setFieldsData({ note: data.contentNote });
      }

      handleOpen();
    });
  }, [getFormData, handleOpen, setFieldsData]);

  const handleOk = useCallback(() => {
    handleClose();
    onAction(ContentAction.UNPUBLISH, formData);
  }, [formData, handleClose, onAction]);

  return (
    <Fragment>
      <Button
        name={action.name}
        title={action.label}
        className={className}
        onClick={handleClick}
        icon={action.icon}
        loading={loading}
      >
        {action.label}
      </Button>
      <Modal
        visible={open}
        title="Gỡ tin này?"
        onCancel={handleClose}
        onOk={handleOk}
        okText={formData.returnAfterUnpublish ? 'Gỡ tin & trả lại' : 'Gỡ tin'}
        okButtonProps={{ disabled: !formData.note }}
        okType="danger"
        destroyOnClose
        keyboard={false}
        maskClosable={false}
      >
        <span className={classes.label}>Ghi chú:</span>
        <Input.TextArea
          placeholder="Nhập lý do gỡ tin"
          name="note"
          value={formData.note}
          onChange={handleChange}
          autoFocus
        />
        <p className={classes.info}>Tin bị gỡ sẽ được chuyển vào trang "Quản lý tin {'>'} {formData.returnAfterUnpublish ? 'Từ chối đăng' : 'Đã gỡ xuống'}".</p>

        <Checkbox
          name="returnAfterUnpublish"
          checked={!!formData.returnAfterUnpublish}
          onChange={handleChange}
        >
          Trả lại "Từ chối đăng"
        </Checkbox>
      </Modal>
    </Fragment>
  );
}
