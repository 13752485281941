import makeStyles from 'core/common/makeStyles';
import React from 'react';
import ContentFormItemDistributionTopics from '../distribution-topics/ContentFormItemDistributionTopics';
import ContentFormItemDistributions from '../distributions/ContentFormItemDistributions';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 10,
    backgroundColor: '#f9f9f9',
    padding: 10,
    paddingTop: 0,
    border: '1px solid #d4d4d4',
    borderRadius: 4,

    '& > :nth-child(2)': {
      marginTop: '-10px !important',
    },
  },
}));

export default function ContentFormItemDistributionGroup({ content, form, defaultTopicIds, distributionProps }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ContentFormItemDistributions content={content} form={form} {...distributionProps} />
      <ContentFormItemDistributionTopics content={content} form={form} defaultTopicIds={defaultTopicIds} />
    </div>
  );
}
