import React, { useEffect } from 'react';
import Container from 'core/components/common/Container';
import contentLogApi from 'core/engine/content-log/api';
import useList from 'core/hooks/useList';
import AccountDisplay from 'core/components/account/AccountDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import BaseTable from 'core/components/table/BaseTable';
import useListProps from 'core/hooks/useListProps';
import { useLocation, Link } from 'react-router-dom';
import { parseQuery } from 'core/common/urlHelper';
import { sortingToSortBy } from 'core/common/listUtils';
import ZoneDisplay from 'core/components/zone/ZoneDisplay';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import { Button } from 'antd';
import GoBackButton from 'core/components/common/GoBackButton';
import useContentColumns from 'core/hooks/useContentColumns';

function listFn({ offset, limit, filters, sorting }) {
  const listFilters = { ...filters };

  const getFields = [
    'LogID',
    'ContentID',
    'ZoneID',
    'Title',
    'Status',
    'EditorID',
    'ModifiedDate',
    'AvatarUrl',
    'Attributes',
  ];

  return contentLogApi.getList({
    offset,
    limit,
    fields: getFields,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

export default function ContentLogListView() {
  const location = useLocation();
  const contentId = parseQuery(location.search).contentId;

  const listProps = useList({
    listFn,
    defaultSorting: { ModifiedDate: 'DESC' },
    defaultFilters: {
      ContentID: contentId,
    },
  });

  useEffect(listProps.refresh, []);
  const ContentColumns = useContentColumns();

  const columns = [
    {
      Header: 'Thời gian',
      width: 130,
      style: { justifyContent: 'center' },
      Cell: ({ original }) => (<DateTimeDisplay timestamp={original.ModifiedDate} />),
    },
    {
      Header: 'Cập nhật',
      maxWidth: 100,
      minWidth: 80,
      Cell: ({ original }) => (<AccountDisplay id={original.EditorID} />),
    },
    ContentColumns.Avatar,
    {
      Header: 'Tiêu đề',
      Cell: ({ original }) => (
        <Link title={original.Title} to={{ pathname: '/contents/log', search: `?logId=${original.LogID}` }}>
          {original.Title}
        </Link>
      ),
      minWidth: 300,
    },
    {
      Header: 'Chuyên mục',
      maxWidth: 160,
      minWidth: 120,
      Cell: ({ original }) => (
        <ZoneDisplay id={original.ZoneID} />
      ),
    },
    ContentColumns.Status,
  ];

  return (
    <Container pageTitle="Truy vết nội dung">
      <TableToolbarWrapper>
        <GoBackButton />
        {listProps.total > 1 && (
          <Link to={`/contents/versioncompare?ContentID=${contentId}`} target="_blank">
            <Button icon="branches" title="So sánh phiên bản">So sánh phiên bản</Button>
          </Link>
        )}
      </TableToolbarWrapper>
      <BaseTable
        columns={columns}
        {...useListProps(listProps)}
        heightAdjust={42}
      />
    </Container>
  );
}
