import { Form } from 'antd';
import Selector from 'core/components/common/Selector';
import React from 'react';
import { useParsedMeta } from 'core/modules/operations/widgets/hooks';
import { emptyObject } from 'core/common/empty';

export default function WidgetMetaTemplateFormItem({ widget, widgetOption = emptyObject, form }) {
  const meta = useParsedMeta(widget);

  return widgetOption.WidgetTemplate ? (
    <Form.Item label="Kiểu hiển thị">
      {form.getFieldDecorator('Meta.Template', {
        initialValue: meta.Template,
      })(
        <Selector
          emptyLabel="-- Kiểu hiển thị --"
          options={widgetOption.WidgetTemplate}
        />
      )}
    </Form.Item>
  ) : null;
}
