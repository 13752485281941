import Container from 'core/components/common/Container';
import React from 'react';
import TopicDisplayPanel from './TopicDisplayPanel';

export default function TopicBoxHighlightView() {
  return (
    <Container pageTitle="Box Tin nổi bật">
      <TopicDisplayPanel settingKey="StringConfig_HomeHighlightTopicID" />
    </Container>
  );
}
