import commandApi from 'core/engine/command/api';

export const SET_ITEMS = 'MENU_SET_ITEMS';
export const SELECT_ITEM = 'MENU_SELECT_ITEM';
export const SET_FILTER = 'MENU_SET_FILTER';

function setItems(items) {
  return {
    type: SET_ITEMS,
    items,
  };
}

export function selectItemId(id) {
  return {
    type: SELECT_ITEM,
    id,
  };
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    filter,
  };
}

export function load() {
  return (dispatch) => {
    return commandApi.getListAuthorized()
      .then((resp) => {
        dispatch(setItems(resp.data.items));
      });
  };
}
