import { Button, Modal } from 'antd';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import makeStyles from 'core/common/makeStyles';
import ZoneDisplay from 'core/components/zone/ZoneDisplay';
import useAsyncAction from 'core/hooks/useAsyncAction';
import useDialog from 'core/hooks/useDialog';
import { showContentFormError } from 'core/modules/contents/services';
import { CustomComponentContext } from 'core/components/layout';

const useStyles = makeStyles(() => ({
  order: {
    fontWeight: 700,
  },
}));

export default function ContentFormToolbarPublishButton({
  getFormData,
  action,
  onAction,
  className,
}) {
  const classes = useStyles();

  const { open, handleClose, handleOpen } = useDialog();
  const [distributions, setDistributions] = useState([]);
  const { isLoading, performAction } = useAsyncAction();

  const handleClick = useCallback(() => {
    getFormData(true, action.name).then((formData) => {
      setDistributions(formData.Distributions.filter(dist => dist.ZoneID > 0));
      handleOpen();
    }, showContentFormError);
  }, [getFormData, handleOpen]);

  const handleOk = useCallback(() => {
    performAction('', () => {
      return onAction(action.name).then(() => handleClose());
    }, { silent: true });
  }, [handleClose, onAction]);

  const customComponentContext = useContext(CustomComponentContext);

  if (customComponentContext && customComponentContext.ExtContentFormToolbarPublishButton) {
    return (
      <customComponentContext.ExtContentFormToolbarPublishButton
        getFormData={getFormData}
        action={action}
        onAction={onAction}
        className={className}
      />
    );
  }

  return (
    <Fragment>
      <Button
        title={action.label}
        onClick={handleClick}
        icon={action.icon}
        className={className}
      >
        {action.label}
      </Button>
      <Modal
        visible={open}
        title={'Xác nhận ' + action.label}
        onCancel={handleClose}
        onOk={handleOk}
        okText={action.label}
        confirmLoading={isLoading}
        destroyOnClose
        keyboard={false}
        maskClosable={false}
      >
        <span>Tin bài sẽ được đăng vào các chuyên mục sau:</span>
        <ul>
          {distributions.map(distribution => (
            <li key={distribution.ZoneID}><ZoneDisplay id={distribution.ZoneID} /> (<span className={classes.order}>{distribution.Order}</span>)</li>
          ))}
        </ul>
      </Modal>
    </Fragment>
  );
}
