import authApi from 'core/engine/auth/api';
import { login as loginAction } from 'core/redux/actions/auth';
import { sessionExpiredSelector, userSelector } from 'core/redux/selectors/auth';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useAuth() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(userSelector);
  const sessionExpired = useSelector(sessionExpiredSelector);

  const login = useCallback((user) => {
    dispatch(loginAction(user));
  }, [dispatch]);

  const logout = useCallback(() => {
    authApi.logout().then(() => {
      window.location.reload();
    });
  }, []);

  const hasExtendedRight = useCallback((right) => {
    return (loggedInUser.extendedRights || []).includes(right);
  }, [loggedInUser]);

  return {
    loggedInUser,
    sessionExpired,
    login,
    logout,
    hasExtendedRight,
  };
}
