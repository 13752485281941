import { Card, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import Container from '../common/Container';

const useStyles = makeStyles(() => ({
  content: {
    ...commonStyles.flexAllCentered,
    minHeight: '50vh',
  },
  back: {
    display: 'block',
    marginTop: 20,
  },
}));

export default function ForbiddenView() {
  const classes = useStyles();

  return (
    <Container>
      <Card className={classes.content}>
        <Typography.Title level={4}>Không có quyền truy cập!</Typography.Title>
        <br />
        <Typography.Text strong>
          Bạn không có quyền truy cập trang này. Vui lòng liên hệ quản trị viên để được hỗ trợ.
        </Typography.Text>
        <Link className={classes.back} to="/">Trở về trang chủ.</Link>
      </Card>
    </Container>
  );
}
