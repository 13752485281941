import { sortingToSortBy } from 'core/common/listUtils';
import AccountDisplay from 'core/components/account/AccountDisplay';
import BaseDialog from 'core/components/common/BaseDialog';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import FlexPushRight from 'core/components/common/FlexPushRight';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import SelectableTable from 'core/components/table/SelectableTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneDisplay from 'core/components/zone/ZoneDisplay';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import { TopicSearchOptions } from 'core/engine/content/constants';
import topicApi from 'core/engine/topic/api';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import useDialog from 'core/hooks/useDialog';
import lodash from 'core/common/lodash';

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return topicApi.getList({ limit, offset, filters: listFilters, sortBy: sortingToSortBy(sorting) });
}

const columns = [
  {
    Header: 'ID',
    accessor: 'TopicID',
    width: 50,
    style: { justifyContent: 'center' },
  },
  {
    Header: 'Tên',
    accessor: 'Name',
    minWidth: 250,
  },
  {
    Header: 'Chuyên mục',
    width: 120,
    Cell: ({ original }) => <ZoneDisplay id={original.ZoneID} />,
  },
  {
    Header: 'Ngày tạo',
    width: 120,
    Cell: ({ original }) => <DateTimeDisplay timestamp={original.CreatedDate} />,
  },
  {
    Header: 'Người tạo',
    maxWidth: 120,
    minWidth: 80,
    Cell: ({ original }) => <AccountDisplay id={original.CreatorID} />,
  },
];

export default forwardRef(function TopicWidgetQuickInsertDialog({ onInsert, widget }, ref) {
  const { open, handleClose, handleOpen } = useDialog();
  const [topics, setTopics] = useState([]);

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultPageSize: 20,
    defaultFilters: {
      Enabled: true,
      search: { type: TopicSearchOptions[0].value },
    },
    defaultSorting: {
      Type: 'DESC',
      CreatedDate: 'DESC',
    },
  });

  const onZoneFilter = useCreateOnFilterChange(listProps, 'ZoneID');
  const onSearchFilter = useCreateOnFilterChange(listProps, 'search');

  useImperativeHandle(ref, () => ({
    close: handleCleanClose,
    open: handleOpen,
  }));

  return (
    <BaseDialog
      keyboard
      maskClosable
      fullHeightFixed
      paddingTop={10}
      paddingBottom={10}
      title="Thêm chủ đề"
      width={900}
      visible={open}
      onCancel={handleCleanClose}
      onOk={handleOk}
      okText="Thêm"
    >
      <TableToolbarWrapper>
        <ZoneSelector
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={onZoneFilter}
          allowClear
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={TopicSearchOptions}
          search={listProps.filters.search}
          onSearch={onSearchFilter}
        />
      </TableToolbarWrapper>
      <SelectableTable
        {...useListProps(listProps)}
        idField="TopicID"
        selected={topics}
        onSelect={handleSelect}
        onUnselect={onUnselect}
        columns={columns}
        heightAdjust={42}
      />
    </BaseDialog>
  );

  function handleSelect(item) {
    setTopics([...topics, item]);
  }

  function onUnselect(item) {
    const newTopics = topics.slice();
    lodash.remove(newTopics, item);
    setTopics(newTopics);
  }

  function handleOk() {
    onInsert(topics.map((topic) => ({
      Title: '[Chủ đề] ' + topic.Name,
      Type: widget.type,
      PreDefined: false,
      Meta: JSON.stringify({ TopicID: topic.TopicID }),
    })));
    handleCleanClose();
  }

  function handleCleanClose() {
    handleClose();
    setTopics([]);
  }
});
