import AccountDisplay from 'core/components/account/AccountDisplay';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import BaseTable from 'core/components/table/BaseTable';
import React, { useEffect, useState } from 'react';
import ActivityLogDetailDialogButton from './ActivityLogDetailDialogButton';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import settingApi from 'core/engine/setting/api';
import { parseJson } from 'core/common/jsonUtils';
import ContentTitleDisplayForList from 'core/components/content/ContentTitleDisplayForList';
import contentApi from 'core/engine/content/api';
import lodash from 'core/common/lodash';
import { ContentListType } from 'core/engine/content/constants';

export default function ActivityLogList({ ...props }) {
  const [activityPathLabel, setActivityPathLabel] = useState({});
  const [contents, setContents] = useState({});

  useEffect(() => {
    settingApi.get('ActivityPathLabel').then(resp => setActivityPathLabel(parseJson(resp.data.item.Value, {})));
  }, []);

  useEffect(() => {
    if (!props.data.length) {
      return;
    }

    contentApi.getList({
      limit: 20,
      offset: 0,
      filters: {
        ContentID: `in:${props.data.map(log => log.ObjectID).join()}`,
        listType: `${ContentListType.ALL}`,
      },
    }).then(resp => setContents(lodash.keyBy(resp.data.items, 'ContentID')));
  }, [props.data]);

  const columns = [
    {
      Header: 'Người dùng',
      accessor: 'AccountID',
      Cell: ({ value }) => <AccountDisplay id={value} />,
      width: 120,
    },
    {
      Header: 'Hành động',
      accessor: 'RequestPath',
      Cell: ({ value }) => <span>{getActivityLabel(value)}</span>,
      width: 200,
    },
    {
      Header: 'Đối tượng',
      accessor: 'ObjectID',
      width: 75,
    },
    {
      Header: 'IP Address',
      accessor: 'IPAddress',
      width: 150,
    },
    {
      Header: 'Bài viết',
      Cell: ({ original }) => original.RequestPath.includes('/contents/') && (
        <ContentTitleDisplayForList content={{ ...contents[original.ObjectID], Distributions: '' }} withZone={false} />
      ),
      minWidth: 350,
    },
    {
      Header: 'Ngày',
      accessor: 'Date',
      Cell: ({ value }) => <DateTimeDisplay timestamp={value} />,
      width: 120,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <ActivityLogDetailDialogButton body={original.RequestBody} browser={original.Browser} />
        </ActionsCellWrapper>
      ),
      width: 70,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );

  function getActivityLabel(value) {
    if (activityPathLabel[value]) {
      return activityPathLabel[value];
    }

    const paths = Object.keys(activityPathLabel);

    for (const index in paths) {
      if (value.includes(paths[index])) {
        return activityPathLabel[paths[index]];
      }
    }

    return value;
  }
}
