import React, { useEffect, useState } from 'react';
import moment from 'moment';
import reportApi from 'core/engine/report/api';
import ChartViewer from './ChartViewer';
import { Row, Col } from 'antd';

const timeFormat = 'YYMMDDHHmm';

export default function ChartHourly({
  title = 'Biểu đồ truy cập gần đây',
  keys = [],
  value,
  startTime = moment().add(-7, 'days').startOf('day').format(timeFormat),
  endTime = moment().endOf('day').format(timeFormat),
  sum = true,
  chartOptions,
  siteId,
}) {

  const [data, setData] = useState([]);

  function buildComparisonData() {
    if (!data || data.length === 0) return null;

    const timeField = 'Time';
    const sumArr = [];
    data.forEach((row) => {

      let total = 0;

      Object.keys(row).forEach((field) => {
        if (field !== timeField) {
          total += row[field];
        }
      });

      sumArr.push({
        Time: moment(row[timeField], timeFormat),
        Value: total,
      });
    });

    const comparisons = new Map([[0, 'today'], [-1, 'days_1'], [-2, 'days_2'], [-3, 'days_3'], [-4, 'days_4'], [-5, 'days_5'], [-6, 'days_6'], [-7, 'days_7']]);

    const mapData = {};

    for (let i = 0; i < 24; i++) {
      mapData[i] = {
        today: 0,
        days_1: 0,
        days_2: 0,
        days_3: 0,
        days_4: 0,
        days_5: 0,
        days_6: 0,
        days_7: 0,
      };
    }

    const today = moment().startOf('day');
    sumArr.forEach((row) => {

      const time = row[timeField];
      const hour = time.hour();
      const diff = time.startOf('day').diff(today, 'days');

      const field = comparisons.get(diff);

      if (field) {
        mapData[hour][field] += row.Value;
      }
    });

    const result = [];
    Object.keys(mapData).forEach((k) => {
      const obj = mapData[k];

      Object.keys(obj).forEach((k2) => obj[k2] = (obj[k2] && obj[k2] !== 0 ? obj[k2] : null));
      obj.Hour = k + 'h';

      return result.push(obj);
    });

    return result;
  }

  useEffect(() => {
    if (keys.length === 0 || value === 0) {
      setData();
      return;
    }

    reportApi.getHourly(
      {
        keys: keys.join(),
        value: value,
        startTime: startTime,
        endTime: endTime,
        siteId: siteId,
      }
    ).then((resp) =>
      setData(resp.data.items),
    );
  }, [keys, value, siteId]);

  return (
    <Row type="flex" justify="center" align="top">
      <Col span={sum ? 12 : 24}>
        <ChartViewer
          title={title}
          xAxisKey={'Time'}
          data={data}
          sum={sum}
          chartOptions={chartOptions}
        />
      </Col>
      {sum && (
        <Col span={12}>
          <ChartViewer
            title={'Biểu đồ so sánh truy cập gần đây'}
            xAxisKey={'Hour'}
            sum={false}
            chartOptions={chartOptions}
            data={buildComparisonData()}
          />
        </Col>
      )}
    </Row>
  );
}
