import { commonColumns, sortingToSortBy } from 'core/common/listUtils';
import lodash from 'core/common/lodash';
import ContentTitleDisplayForList from 'core/components/content/ContentTitleDisplayForList';
import BaseTable from 'core/components/table/BaseTable';
import { TOPIC_SYSTEM_ORDERED_ITEMS } from 'core/config';
import contentApi from 'core/engine/content/api';
import useContentColumns from 'core/hooks/useContentColumns';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { Fragment, useEffect } from 'react';

function listFn({ filters, sorting }) {
  const listFilters = { ...filters };

  return contentApi.getDeployedList({
    offset: 0,
    limit: TOPIC_SYSTEM_ORDERED_ITEMS,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  }).then(resp => {
    const contentIds = listFilters.ContentID ? listFilters.ContentID.substring(3).split(',').map(Number) : [];
    const contentMap = lodash.keyBy(resp.data.items, 'ContentID');

    resp.data.items = contentIds.map(contentId => contentMap[contentId]).filter(Boolean);

    return resp;
  });
}

export default function TopicContentLog({ contentIds, modifiedDate }) {
  const contentColumns = useContentColumns();

  const listProps = useList({
    listFn,
    listId: 'ListTopicContentLog',
  });

  useEffect(() => {
    const contents = (contentIds || '').split(',').slice(0, 20);

    listProps.addFilter('ContentID', contentIds ? `in:${contents.join()}` : null);
  }, [contentIds]);

  const columns = [
    {
      ...commonColumns.order,
      ...commonColumns.allCentered,
      Header: 'STT',
    },
    contentColumns.Avatar,
    {
      Header: 'Tiêu đề',
      Cell: ({ original }) => (
        <ContentTitleDisplayForList content={original} now={modifiedDate} />
      ),
      minWidth: 225,
    },
  ];

  return (
    <Fragment>
      <BaseTable
        {...useListProps(listProps)}
        columns={columns}
        fullHeight={false}
        showPagination={false}
      />
    </Fragment>
  );
}
