import React from 'react';
import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import Container from 'core/components/common/Container';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import LiveFormDialogButton from './LiveFormDialogButton';
import LiveList from './LiveList';
import liveApi from 'core/engine/live/api';
import FlexPushRight from 'core/components/common/FlexPushRight';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import Selector from 'core/components/common/Selector';

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return liveApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

const LiveSearchOptions = [
  { value: 'Title', label: 'Tiêu đề' },
  { value: 'LiveID', label: 'ID' },
];

export default function LiveListView() {
  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultFilters: {
      search: { type: LiveSearchOptions[0].value },
    },
    defaultSorting: {
      LiveID: 'DESC',
    },
  });

  const handleSearchFilter = useCreateOnFilterChange(listProps, 'search');
  const handleStatusFilter = useCreateOnFilterChange(listProps, 'Enabled');

  return (
    <Container pageTitle="Quản lý tường thuật">
      <TableToolbarWrapper>
        <LiveFormDialogButton isEditMode={false} onDone={listProps.refresh} />
        <Selector
          width={200}
          emptyLabel="-- Trạng thái --"
          value={listProps.filters.Enabled}
          options={[{ value: true, label: 'Đã kích hoạt'}, { value: false, label: 'Chưa kích hoạt' }]}
          onChange={handleStatusFilter}
          showDisabledOptions
          allowClear
          showSearch={false}
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={LiveSearchOptions}
          search={listProps.filters.search}
          onSearch={handleSearchFilter}
          autoFocus
        />
      </TableToolbarWrapper>
      <LiveList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdate={listProps.refresh}
        onDelete={handleDelete}
      />
    </Container>
  );

  function handleDelete(live) {
    return asyncAction('Xóa tường thuật', () => {
      return liveApi.delete(live.LiveID).then(() => {
        listProps.removeItem(live, 'LiveID');
      });
    });
  }
}
