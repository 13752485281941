import { yellow } from '@ant-design/colors';
import { Alert, Button, message, Typography } from 'antd';
import makeStyles from 'core/common/makeStyles';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import toolApi from 'core/engine/tool/api';
import useDialog from 'core/hooks/useDialog';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import striptags from 'striptags';
import DraggableDialog from 'core/components/dialog/DraggableDialog';
import { ContentFieldLabel } from 'core/modules/contents/constants';

const useStyles = makeStyles(() => ({
  invalid: {
    backgroundColor: yellow.primary,

    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  title: {
    fontWeight: 'bold',
    marginTop: 10,
  },
  content: {
    marginTop: 20,
    fontSize: 'larger',
  },
}));

const CONTENT_SEPARATOR = '<--ContentSeparator-->';
const PARAGRAPH_SEPARATOR = '<--NewLine-->';
const CONTENT_FIELDS_TITLE = ['Tiêu đề', 'Mô tả', 'Nội dung', 'Chú thích ảnh đại diện'];

export default forwardRef(function Dialog({ content }, ref) {
  const classes = useStyles();
  const { open, handleClose, handleOpen } = useDialog();

  const [isChecking, setIsChecking] = useState(false);
  const [result, setResult] = useState();
  const typoCount = useMemo(() => {
    return (result || []).filter(chunk => typeof chunk !== 'string' && !chunk.separator).length;
  }, [result]);

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  useEffect(() => {
    if (!open) {
      setIsChecking(false);
      setResult(null);
      return;
    }

    const { contentId, form } = content;
    delete content.ContentID;
    delete content.form;

    if (form) {
      form.setFieldsValue({ 'spellChecker': true });
    }

    const text = Object.values(content)
      .map(value => normalizeHtml(value)
        .replace(/\n/g, ` ${PARAGRAPH_SEPARATOR}\n`))
      .join(` ${CONTENT_SEPARATOR}\n`);

    let active = true;

    setIsChecking(true);
    setResult(null);

    toolApi.check({ contentId, text })
      .then((resp) => {
        if (active) {
          setResult(normalizeChunks(resp.data.result || resp.data || []));
          setIsChecking(false);
        }
      })
      .catch(() => {
        setIsChecking(false);
        message.error('Có lỗi xảy ra!');
      });

    return () => active = false;
  }, [open, content]);

  return (
    <DraggableDialog
      keyboard
      visible={open}
      onCancel={handleClose}
      width={600}
      title="Kiểm tra chính tả"
      footer={[<Button key="ok" type="primary" onClick={handleClose}>Đóng</Button>]}
    >
      <LoadingWrapper loading={isChecking} center>
        {displayResults()}
      </LoadingWrapper>
    </DraggableDialog>
  );

  function displayResults() {
    if (!result) {
      return (
        <Typography.Text type="danger">Có lỗi xảy ra!</Typography.Text>
      );
    }

    if (typoCount === 0) {
      return (
        <div>
          <Alert message="Bài viết không có lỗi chính tả nào!" type="success" showIcon />
          <div className={classes.content}>
            {Object.keys(content).map(key => content[key] ? (
              <>
                <div className={classes.title}>{ContentFieldLabel[key]}</div>
                {normalizeHtml(content[key])}
              </>
            ) : null)}
          </div>
        </div>
      );
    }

    let contentTitleIndex = 1;

    return (
      <div>
        <Alert message={
          <>Phát hiện <strong>{typoCount}</strong> cụm từ có thể là lỗi chính tả!</>
        } type="warning" showIcon />
        <div className={classes.content}>
          <div className={classes.title}>{CONTENT_FIELDS_TITLE[0]}</div>
          {result.map((chunk, index) =>
            typeof chunk === 'string' ? (
              <Fragment key={String(index)}>{chunk}</Fragment>
            ) : (
              chunk.separator === CONTENT_SEPARATOR ? (
                <div key={String(index)} className={classes.title}>{CONTENT_FIELDS_TITLE[contentTitleIndex++]}</div>
              ) : chunk.separator === PARAGRAPH_SEPARATOR ? (
                <br key={String(index)} />
              ) : (
                <Fragment key={String(index)}>
                  {' '}
                  <span title={chunk.origin} className={classes.invalid}>{chunk.origin}</span>
                  {' '}
                </Fragment>
              )
            ),
          )}
        </div>
      </div>
    );
  }
});

function normalizeChunks(chunks = []) {
  const output = [];
  let currentChunks = [];

  chunks.forEach((chunk) => {
    if (typeof chunk === 'string' && ![CONTENT_SEPARATOR, PARAGRAPH_SEPARATOR].includes(chunk)) {
      currentChunks.push(chunk);
    } else {
      if (currentChunks.length) {
        output.push(correctPuntation(currentChunks.join(' ')));
        currentChunks = [];
      }

      if (typeof chunk === 'string') {
        output.push({ separator: chunk });
      } else {
        output.push({ origin: Object.keys(chunk).join(' ') });
      }
    }
  });

  if (currentChunks.length) {
    output.push(correctPuntation(currentChunks.join(' ')));
  }

  return output;
}

function correctPuntation(text) {
  return text
    .replace(/ ([.,)?!%:])/g, '$1')
    .replace(/\( /g, '(');
}

function normalizeHtml(html) {
  html = (html || '').normalize();
  html = striptags(html, ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'table', 'tbody', 'tr', 'td'], ' ');
  html = html.replace(/\s+/g, ' ');

  return htmlDecode(striptags(html, [], '\n'))
    .split('\n')
    .map(chunk => chunk.trim())
    .filter(Boolean)
    .map(chunk => chunk.replace(/([^.?!\s])$/g, '$1.'))
    .join('\n');
}

function htmlDecode(input) {
  const element = document.createElement('div');
  element.innerHTML = input;
  return element.childNodes.length === 0 ? '' : element.childNodes[0].nodeValue;
}
