import React from 'react';
import { emptyArray } from 'core/common/empty';
import BaseTable from 'core/components/table/BaseTable';
import { ContentLink } from 'core/components/content/ContentLink';
import ZoneDisplay from 'core/components/zone/ZoneDisplay';
import CardWidget from 'core/components/common/CardWidget';

export default function DashBoardWidgetLatestContents({ contents = emptyArray, loading }) {

  const columns = [
    {
      Header: 'Tiêu đề',
      Cell: ({ original }) => (
        <ContentLink id={original.ContentID}>{original.Title}</ContentLink>
      ),
      minWidth: 300,
    },
    {
      Header: 'Chuyên mục',
      width: 160,
      Cell: ({ original }) => (
        <ZoneDisplay id={original.ZoneID} />
      ),
    },
  ];

  return (
    <CardWidget title="Bài đăng mới nhất" titleLink="/contents/deployedlist">
      <BaseTable
        data={contents}
        loading={loading}
        columns={columns}
        showPagination={false}
        pageSize={contents.length}
      />
    </CardWidget>
  );
}
