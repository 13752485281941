import { Config, ToolbarIcon } from '@zdocs/core';
import LiveDialog from './LiveDialog';
import BaseDialogPlugin from 'core/components/editor/plugins/BaseDialogPlugin';
import React from 'react';
import icon from './icon.svg';

export default class LivePlugin extends BaseDialogPlugin {
  static install() {
    ToolbarIcon.setIcon('Live', icon);

    Config.prototype.controls.live = {
      tooltip: 'Chèn tường thuật',
      icon: 'Live',
    };
  }

  get name() {
    return 'live';
  }

  get command() {
    return 'live';
  }

  get defaultOptions() {
    return {
      template: '<live liveId="$Id"><strong>Tường thuật trực tiếp</strong><hr>$Title</live>',
    };
  }

  renderDialog(props) {
    return (
      <LiveDialog
        title="Chèn tường thuật trực tiếp"
        {...props}
      />
    );
  }

  onSelect(select) {
    super.onSelect();

    if (select.LiveID) {
      this.insertHTMLToEditor(
        this.options.template
          .replace('$Id', select.LiveID)
          .replace('$Title', select.Title),
        false, true);
    }
  }
}
