import domUtils from 'core/common/domUtils';
import striptags from 'striptags';

function normalize(html) {
  html = striptags(html, [
    'div',
    'p',
    'b',
    'strong',
    'i',
    'em',
    'u',
    'sub',
    'sup',
    'table',
    'th',
    'tr',
    'td',
    'thead',
    'tbody',
    'tfoot',
    'img',
    'picture',
    'source',
  ], ' ');
  html = html.replace(/\s+/g, ' ');
  const domparser = new DOMParser();
  const doc = domparser.parseFromString(html || '', 'text/html');

  if (doc.getElementsByTagName('parsererror').length > 0) {
    return '';
  }

  const body = doc.body;

  normalizePictureTag(body);
  normalizeImageDataSrc(body);

  cleanAttributes(body);

  replaceDivByP(body);

  unwrapNestedP(body);

  addTablePictureClass(body);
  wrapperAloneImageByTable(body);

  rempveEmptyDivAndP(body);

  return body.innerHTML;
}

function replaceDivByP(body) {
  body.querySelectorAll('div').forEach((element) => {
    const p = document.createElement('p');

    while (element.firstChild) {
      const child = element.removeChild(element.firstChild);
      p.appendChild(child);
    }

    element.parentNode.insertBefore(p, element);
    element.parentNode.removeChild(element);
  });
}

// usually found on VNExpress.net
function normalizePictureTag(body) {
  body.querySelectorAll('picture').forEach((element) => {
    const source = element.querySelector('[data-src]');

    if (source) {
      const img = document.createElement('img');

      img.setAttribute('src', source.getAttribute('data-src'));
      element.parentNode.insertBefore(img, element);
    }

    element.parentNode.removeChild(element);
  });
}

function normalizeImageDataSrc(body) {
  body.querySelectorAll('img[data-src]').forEach((element) => {
    element.setAttribute('src', element.getAttribute('data-src'));
  });
}

function wrapperAloneImageByTable(body) {
  body.querySelectorAll(':scope > img').forEach((element) => {
    const table = document.createElement('table');
    const tbody = document.createElement('tbody');
    const tr = document.createElement('tr');
    const td = document.createElement('td');

    element.parentNode.insertBefore(table, element);
    element.parentNode.removeChild(element);

    table.setAttribute('class', 'picture');
    table.setAttribute('align', 'center');
    table.appendChild(tbody);
    tbody.appendChild(tr);
    tr.appendChild(td);
    td.appendChild(element);
  });
}

function addTablePictureClass(body) {
  body.querySelectorAll('table').forEach((element) => {
    if (element.querySelector('img')) {
      element.setAttribute('class', 'picture');
      element.setAttribute('align', 'center');
    }
  });
}

function cleanAttributes(body) {
  body.querySelectorAll('*').forEach((element) => {
    const keepAttributes = element.tagName === 'TD' ? ['src', 'colspan', 'rowspan', 'class'] : ['src', 'colspan', 'rowspan'];
    domUtils.cleanAttributes(element, keepAttributes);
  });
}

function unwrapNestedP(body) {
  let count = 0; // to avoid infinite loop

  while (body.querySelector('p > table, p > img, p > p') && count < 1000) {
    process();
    count++;
  }

  function process() {
    body.querySelectorAll('p').forEach((element) => {
      if (!element.querySelector(':scope > img, :scope > table, :scope > p')) {
        return;
      }

      let currentP = document.createElement('p');

      while (element.firstChild) {
        const child = element.removeChild(element.firstChild);

        if (child.tagName === 'TABLE' || child.tagName === 'IMG' || child.tagName === 'P') {
          if (currentP.firstChild) {
            element.parentNode.insertBefore(currentP, element);
            currentP = document.createElement('p');
          }

          element.parentNode.insertBefore(child, element);
        } else {
          currentP.appendChild(child);
        }
      }

      if (currentP.firstChild) {
        element.parentNode.insertBefore(currentP, element);
      }

      element.parentNode.removeChild(element);
    });
  }
}

function rempveEmptyDivAndP(body) {
  body.querySelectorAll('p, div').forEach((element) => {
    if (domUtils.isEmptyNode(element)) {
      element.parentNode.removeChild(element);
    }
  });
}

const importContentService = {
  normalize,
};

export default importContentService;
