import React, { useMemo, Fragment } from 'react';
import { formatDateTime } from 'core/common/datetimeHelper';

export default function DateTimeDisplay({ timestamp, format = 'DD/MM/YYYY HH:mm' }) {

  const dateString = useMemo(() => {
    return formatDateTime(timestamp, format);
  }, [timestamp, format]);

  return (
    <Fragment>
      {dateString}
    </Fragment>
  );
}
