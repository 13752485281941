import { Affix, Button, Form, Tabs } from 'antd';
import { asyncAction } from 'core/common/async';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import Container from 'core/components/common/Container';
import settingApi from 'core/engine/setting/api';
import useFormHandler from 'core/hooks/useFormHandler';
import settingsRegistry from 'core/registries/settings';
import React, { useMemo } from 'react';
import SettingLayoutForm from './SettingLayoutForm';
import SettingGeneralForm from './SettingGeneralForm';
import SettingWebForm from './SettingWebForm';
import { emptyArray } from 'core/common/empty';

const { TabPane } = Tabs;

const useStyles = makeStyles(() => ({
  footer: {
    backgroundColor: '#FFF',
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

function SettingView({ form, settingTabs = emptyArray }) {
  const classes = useStyles();

  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  const tabs = useMemo(() => {
    return settingTabs.length ? settingTabs : lodash.sortBy(settingsRegistry.getAll(), ['order']);
  }, [settingTabs]);

  return (
    <Container pageTitle="Cấu hình hệ thống">
      {tabs.length ? (
        <Tabs>
          {tabs.map(tab => (
            <TabPane tab={tab.label || tab.name} key={tab.name}>
              {tab.Form ? <tab.Form form={form} onSubmit={handleSubmit} /> : null}
            </TabPane>
          ))}
        </Tabs>
      ) : (
        <Tabs>
          <TabPane tab="Cài đặt chung" key="General">
            <SettingGeneralForm form={form} onSubmit={handleSubmit} />
          </TabPane>
          <TabPane tab="Giao diện" key="Layout">
            <SettingLayoutForm form={form} onSubmit={handleSubmit} />
          </TabPane>
          <TabPane tab="Web" key="Web">
            <SettingWebForm form={form} onSubmit={handleSubmit} />
          </TabPane>
        </Tabs>
      )}

      <Affix offsetBottom={24}>
        <Form.Item {...commonStyles.tailFormItemLayout} className={classes.footer}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            onClick={handleSubmit}
            title="Lưu"
          >Lưu</Button>
        </Form.Item>
      </Affix>
    </Container>
  );

  function submitFn({ formData }) {
    return asyncAction('Cấu hình hệ thống', () => {
      const settings = Object.keys(formData).map(key => {
        const value = isNullOrUndefined(formData[key]) ? '' : formData[key];
        return {
          Key: key,
          Value: Array.isArray(value)
            ? value.join()
            : typeof value === 'string' ? value : JSON.stringify(value),
        };
      });

      if (settings.length > 0) {
        return settingApi.multiPut(JSON.stringify(settings));
      }
    });
  }
}

export default Form.create()(SettingView);
