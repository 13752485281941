import { emptyArray, emptyFunction } from 'core/common/empty';
import lodash from 'core/common/lodash';
import React, { useCallback, useMemo } from 'react';
import AsyncButton from '../common/AsyncButton';
import BaseTable from './BaseTable';

export default function SelectableTable({
  idField,
  columns = emptyArray,
  data = emptyArray,
  selected = emptyArray,
  onSelect = emptyFunction,
  onUnselect = emptyFunction,
  selectLabel = 'Chọn',
  unselectLabel = 'Bỏ chọn',
  ...props
}) {
  const selectedIdMap = useMemo(() => {
    return lodash.keyBy(selected, idField);
  }, [idField, selected]);

  const dataMap = useMemo(() => {
    return lodash.keyBy(data, idField);
  }, [data, idField]);

  const handleSelect = useCallback((event) => {
    const id = event.target.name;

    if (selectedIdMap[id]) {
      return onUnselect(dataMap[id]);
    } else {
      return onSelect(dataMap[id]);
    }
  }, [dataMap, onSelect, onUnselect, selectedIdMap]);

  const extendedColumns = useMemo(() => {
    return [
      ...columns,
      {
        Cell: ({ original }) => (
          <AsyncButton
            size="small"
            name={String(original[idField])}
            title={selectedIdMap[original[idField]] ? unselectLabel : selectLabel }
            icon={selectedIdMap[original[idField]] ? 'minus-circle' : 'plus'}
            style={{ color: selectedIdMap[original[idField]] ? 'red' : 'green' }}
            onClick={handleSelect}
          />
        ),
        maxWidth: 80,
        minWidth: 50,
        style: { justifyContent: 'center' },
      },
    ];
  }, [columns, handleSelect, idField, selectLabel, selectedIdMap, unselectLabel]);

  return (
    <BaseTable
      columns={extendedColumns}
      data={data}
      {...props}
    />
  );
}
