import { Form, Input } from 'antd';
import React, { useEffect, useState, Fragment } from 'react';
import commonStyles from 'core/common/commonStyles';
import seoEntryApi from 'core/engine/seo-entry/api';
import LoadingWrapper from '../common/LoadingWrapper';

export default function SeoEntryForm({ form, type, id, defaultValue }) {
  const { getFieldDecorator } = form;
  const [seoEntry, setSeoEntry] = useState();

  useEffect(() => {
    if (defaultValue) {
      setSeoEntry(JSON.parse(defaultValue));
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!id) {
      setSeoEntry({});
    }

    seoEntryApi.get(type, id)
      .then(resp => setSeoEntry(resp.data.item))
      .catch(() => setSeoEntry({}));
  }, [type, id]);

  if (!seoEntry) {
    return <LoadingWrapper loading center />;
  }

  return (
    <Fragment>
      <Form.Item label="SEO Title" {...commonStyles.formItemLayout}>
        {getFieldDecorator('SeoEntry.Title', {
          initialValue: seoEntry.Title,
        })(
          <Input.TextArea maxLength={250} />
        )}
      </Form.Item>
      <Form.Item label="SEO Description" {...commonStyles.formItemLayout}>
        {getFieldDecorator('SeoEntry.Description', {
          initialValue: seoEntry.Description,
        })(
          <Input.TextArea maxLength={1000} />
        )}
      </Form.Item>
      <Form.Item label="SEO Keywords" {...commonStyles.formItemLayout}>
        {getFieldDecorator('SeoEntry.Keywords', {
          initialValue: seoEntry.Keywords,
        })(
          <Input.TextArea maxLength={1000} />
        )}
      </Form.Item>
    </Fragment>
  );
}
