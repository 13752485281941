import React, { useEffect, useMemo, useState } from 'react';
import { sortingToSortBy } from 'core/common/listUtils';
import Container from 'core/components/common/Container';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import AuthorFormDialogButton from './AuthorFormDialogButton';
import AuthorList from './AuthorList';
import authorApi from 'core/engine/author/api';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import FlexPushRight from 'core/components/common/FlexPushRight';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import Selector from 'core/components/common/Selector';
import { Link, useLocation } from 'react-router-dom';
import { parseQuery } from 'core/common/urlHelper';
import { Button, Checkbox } from 'antd';
import makeStyles from 'core/common/makeStyles';

const useStyles = makeStyles(() => ({
  checkbox: {
    minWidth: 120,
    marginTop: 5,
  },
}));

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return authorApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

const AuthorSearchOptions = [
  { value: 'FullName', label: 'Tên' },
];

export default function AuthorListView() {
  const classes = useStyles();

  const [parentId, setParentId] = useState(0);
  const location = useLocation();
  const { search } = location;

  const authorId = useMemo(() => {
    return parseQuery(search).authorId;
  }, [search]);

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: {
      AuthorID: 'DESC',
    },
    defaultFilters: {
      search: { type: AuthorSearchOptions[0].value },
    },
  });

  useEffect(() => {
    removeFilter();

    setParentId(authorId);
    listProps.addFilter('ParentID', authorId);
  }, [authorId]);

  const handleSearchFilter = useCreateOnFilterChange(listProps, 'search');
  const handleStatusFilter = useCreateOnFilterChange(listProps, 'Enabled');

  return (
    <Container pageTitle="Tác giả">
      <TableToolbarWrapper>
        <AuthorFormDialogButton isEditMode={false} onDone={listProps.refresh} />
        {parentId > 0 && (
          <Link to="/operations/authors"><Button icon="menu-fold">Quay lại</Button></Link>
        )}
        <Selector
          width={200}
          emptyLabel="-- Trạng thái --"
          options={[{ value: true, label: 'Hoạt động'}, { value: false, label: 'Không hoạt động' }]}
          value={listProps.filters.Enabled}
          onChange={handleStatusFilter}
          allowClear
        />
        {!authorId &&
          <Checkbox
            checked={listProps.filters.ParentID === 0}
            onChange={(event) => listProps.addFilter('ParentID', event.target.checked ? 0 : undefined)}
            className={classes.checkbox}
          >
            Ẩn tác giả con
          </Checkbox>
        }
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={AuthorSearchOptions}
          search={listProps.filters.search}
          onSearch={handleSearchFilter}
          autoFocus
        />
      </TableToolbarWrapper>
      <AuthorList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdated={listProps.refresh}
      />
    </Container>
  );

  function removeFilter() {
    listProps.addFilter('search', { type: AuthorSearchOptions[0].value });
    listProps.addFilter('Enabled', null);
  }
}
