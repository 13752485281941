import { httpGet } from 'core/common/http-client';

const voteLogApi = {
  getList({ limit = 20, offset = 0, fields = [], sortBy, filters }) {
    const qs = { limit, offset, fields, sortBy, ...filters };
    return httpGet('/vote-logs/get/items-by-range', { qs });
  },
};

export default voteLogApi;
