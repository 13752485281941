import TopicSelector from 'core/components/topic/TopicSelector';
import ZoneTransfer from 'core/components/zone/ZoneTransfer';
import { TopicType } from 'core/engine/topic/constants';
import React from 'react';
import SettingForm from './SettingForm';

const settings = [
  {
    key: 'StringConfig_HomeHighlightTopicID',
    label: 'Chủ đề tin nổi bật',
    input: <TopicSelector emptyValue="" topicType={TopicType.SYSTEM} />,
  },
  {
    key: 'StringConfig_HomeHotTopicID',
    label: 'Chủ đề tin hot',
    input: <TopicSelector emptyValue="" topicType={TopicType.SYSTEM} />,
  },
  {
    key: 'StringConfig_MainMenu',
    label: 'Menu chính',
    input: <ZoneTransfer />,
  },
];

export default function SettingLayoutForm({ form, onSubmit }) {
  return (
    <SettingForm form={form} onSubmit={onSubmit} settings={settings} />
  );
}
