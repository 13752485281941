import { Button, Form } from 'antd';
import React, { Fragment } from 'react';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import voteApi from 'core/engine/vote/api';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import VoteForm from './VoteForm';

function VoteFormDialogButton({ form, vote = {}, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={isEditMode ? 'Sửa bình chọn' : 'Tạo bình chọn'}
        onClick={handleOpen}>
        {isEditMode ? '' : 'Tạo bình chọn'}
      </Button>
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa bình chọn' : 'Tạo bình chọn'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <VoteForm form={form} vote={vote} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    formData.zoneIds = formData.zoneIds ? formData.zoneIds.join() : '';

    if (isEditMode) {
      return asyncAction('Sửa bình chọn', () => {
        return voteApi.update(formData)
          .then(handleDone);
      });
    }

    return asyncAction('Tạo bình chọn', () => {
      return voteApi.create(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'VoteFormDialogButton' })(VoteFormDialogButton);
