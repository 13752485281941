import { httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_FIELDS = [
  'VoteID',
  'Title',
  'Description',
  'Attributes',
  'Enabled',
  'ZoneVote',
];

const DEFAULT_LOOKUP = [
  'ZoneVote.VoteID',
  'ZoneVote.ZoneID',
];

const voteApi = {
  get(id) {
    return httpGet(`/votes/get/item?VoteID=${id}`);
  },

  getList({ limit = 20, offset = 0, sortBy, fields = [], lookup = [], filters }) {
    const qs = {
      limit, offset, sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      lookup: [...DEFAULT_LOOKUP, ...lookup],
      ...filters };

    return httpGet('/votes/get/items-by-range', { qs });
  },

  create(data) {
    return httpPost('/votes/post/insert', data);
  },

  update(data) {
    return httpPost('/votes/post/update', data);
  },

  delete(id) {
    return httpPost('/votes/post/delete', { VoteID: id });
  },

  setOptions(id, options) {
    return httpPost('/votes/post/set-options', { VoteID: id, options: JSON.stringify(options) });
  },
};

export default voteApi;
