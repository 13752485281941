import { useEffect } from 'react';

export default function useWindowUnloadAlert(when = true) {
  useEffect(() => {
    if (!when) {
      return;
    }

    const handler = (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handler);

    return () => window.removeEventListener('beforeunload', handler);
  }, [when]);
}
