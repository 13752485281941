import { Button, Form } from 'antd';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import tagApi from 'core/engine/tag/api';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import React, { Fragment } from 'react';
import TagForm from './TagForm';

function TagFormDialogButton({ form, tag = {}, parentId, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={isEditMode ? 'Sửa từ khóa' : 'Tạo từ khóa'}
        onClick={handleOpen}>
        {isEditMode ? '' : 'Tạo từ khóa'}
      </Button>
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa từ khóa' : 'Tạo từ khóa'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <TagForm form={form} tag={tag} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    formData.SeoEntry = JSON.stringify(formData.SeoEntry);
    formData.ParentID = formData.ParentID ? formData.ParentID : parentId ? parentId : 0;

    return asyncAction(isEditMode ? 'Sửa từ khóa' : 'Tạo từ khóa', () => {
      return tagApi.submit(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'TagFormDialogButton' })(TagFormDialogButton);
