import TopicBoxHighlightView from './components/TopicBoxHighlightView';
import TopicBoxHotView from './components/TopicBoxHotView';
import TopicListView from './components/TopicListView';
import SystemTopicListView from './components/topic-system/SystemTopicListView';

export default [
  { path: '/operations/topics', component: TopicListView },
  { path: '/operations/system-topics', component: SystemTopicListView },
  { path: '/operations/topics/highlight', component: TopicBoxHighlightView },
  { path: '/operations/topics/hot', component: TopicBoxHotView },
];
