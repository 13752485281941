import { Form } from 'antd';
import { isNullOrUndefined } from 'core/common/is';
import Selector from 'core/components/common/Selector';
import { ContentDisplayType, ContentDisplayTypeOptions } from 'core/engine/content/constants';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React from 'react';

export default function ContentFormItemDisplayType({ content, form }) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={ContentFieldLabel.DisplayType}>
      {getFieldDecorator('DisplayType', {
        initialValue: isNullOrUndefined(content.DisplayType) ? ContentDisplayType.NORMAL : content.DisplayType,
      })(
        <Selector options={ContentDisplayTypeOptions} valueIsNumber />
      )}
    </Form.Item>
  );
}
