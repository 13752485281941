import { BROWSER_ROUTER } from 'core/config';

let lastLocation;

const locationService = {
  getPathName() {
    if (BROWSER_ROUTER) {
      return window.location.pathname;
    }

    return new URL('http://abc.com' + window.location.hash.substring(1)).pathname;
  },
  reload() {
    return window.location.reload();
  },
  saveLastLocation(location) {
    lastLocation = location;
  },
  getLastLocation() {
    return lastLocation;
  },
};

export default locationService;
