import { message } from 'antd';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import logger from 'core/common/logger';

export default function useFormHandler({
  form,
  submitFn,
  scrollOnSubmit = true,
  alertOnError = true,
}) {
  const { validateFieldsAndScroll, validateFields, resetFields, isFieldTouched } = form;
  const [isSubmitting, setIsSubbmiting] = useState(false);

  const handleReset = useCallback(() => resetFields(), [resetFields]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    setIsSubbmiting(true);

    (scrollOnSubmit ? validateFieldsAndScroll : validateFields)((err, formData) => {
      if (err) {
        setIsSubbmiting(false);

        if (alertOnError) {
          message.error('Form nhập chưa chính xác!');
        }
      } else {
        const modifiedFields = Object.keys(formData).filter(fieldName => isFieldTouched(fieldName));
        const modifiedFormData = _.pick(formData, modifiedFields);

        // submitFn should not throw any exceptions and always resolves
        // but sometimes...
        try {
          submitFn({ formData, modifiedFormData, form }).then(() => setIsSubbmiting(false));
        } catch (err) {
          message.error('Unexpected error occured, check JS Console for detail');
          logger.error('Error while calling submitFn:', err);
        }
      }
    });
  }, [alertOnError, form, isFieldTouched, scrollOnSubmit, submitFn, validateFields, validateFieldsAndScroll]);

  return {
    handleSubmit,
    handleReset,
    isSubmitting,
  };
}
