import { Config } from '@zdocs/core';
import React from 'react';
import { buildCdnPhotoUrl } from 'core/common/cdn';
import { buildShortContentUrl, buildTopicUrl } from 'core/common/urlHelper';
import BaseDialogPlugin from '../BaseDialogPlugin';
import LinkContentDialog from './LinkContentDialog';
import { formatDateTime } from 'core/common/datetimeHelper';

export default class LinkContentPlugin extends BaseDialogPlugin {

  static install() {
    Config.prototype.controls.linkContent = {
      tooltip: 'Liên kết nội dung',
      icon: 'article',
    };
  }

  constructor(zdocs) {
    super(zdocs);

    this.insertLink = this.insertLink.bind(this);
    this.buildContentHTML = this.buildContentHTML.bind(this);
    this.buildTopicHTML = this.buildTopicHTML.bind(this);
  }

  get name() {
    return 'LinkContentPlugin';
  }

  get command() {
    return 'linkContent';
  }

  get defaultOptions() {
    return {
      isMultiple: true,
      dateTimeFormat: 'DD/MM/YYYY',
      contentLinkTemplate: '<related-content class="notebox nleft" data-cid="$Id"><img src="$AvatarUrl"/><br/><a href="$Url">$Text</a></related-content>',
      topicLinkTemplate: '<related-topic data-tid="$Id"><a href="$Url">$Text</a></related-topic>',
    };
  }

  callback() {
    this.selectionInfo = this.zdocs.selection.save();
    this.isMultiple = this.options.isMultiple && this.zdocs.selection.isCollapsed();
    this.openDialog({ multiple: this.isMultiple });

    return false;
  }

  renderDialog(props) {
    return (
      <LinkContentDialog {...props} />
    );
  }

  insertLink(url, title) {
    const selection = this.zdocs.selection;

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.innerText = selection.sel;
    a.title = title;

    this.insertHTMLToEditor(a, false, false);
  }

  onSelect(items) {
    super.onSelect();

    if (this.isMultiple) {
      const html = items.map(item => {
        return item.ContentID ? this.buildContentHTML(item) : (item.TopicID ? this.buildTopicHTML(item) : '');
      }).join('');

      this.insertHTMLToEditor(html, false, false);
    } else {
      const item = items[0];

      if (item.ContentID) {
        this.insertLink(buildShortContentUrl(item.ContentID), item.Title);
      } else if (item.TopicID) {
        this.insertLink(buildTopicUrl(item.TopicID, item.Name), item.Name);
      }
    }
  }

  buildContentHTML(content) {
    return this.options.contentLinkTemplate
      .replace('$Id', content.ContentID)
      .replace('$Url', buildShortContentUrl(content.ContentID))
      .replace('$Text', content.Title)
      .replace('$AvatarUrl', buildCdnPhotoUrl(content.AvatarUrl))
      .replace('$Date', formatDateTime(content.DistributionDate, this.options.dateTimeFormat))
      .replace('$Description', content.Description);
  }

  buildTopicHTML(topic) {
    return this.options.topicLinkTemplate
      .replace('$Id', topic.TopicID)
      .replace('$Url', buildTopicUrl(topic.TopicID, topic.Name))
      .replace('$Text', topic.Name);
  }
}
