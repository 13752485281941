import React, { useMemo } from 'react';
import { ContentAttribute } from 'core/engine/content/constants';
import { Icon } from 'antd';
import { isNullOrUndefined } from 'core/common/is';
import makeStyles from 'core/common/makeStyles';
import { orange } from '@ant-design/colors';

const useStyles = makeStyles(() => ({
  icon: {
    marginLeft: 5,
    fontSize: 18,
    color: orange[6],
  },
}));

export default function ContentAttributeDisplay({ content }) {
  const classes = useStyles();

  const iconTypes = useMemo(() => {
    if (isNullOrUndefined(content) || isNullOrUndefined(content.Attributes)) return [];

    const retVal = [];

    const attribute= content.Attributes;

    if ((attribute & ContentAttribute.HAS_PHOTO) > 0) {
      retVal.push(['picture', 'Có ảnh']);
    }

    if ((attribute & ContentAttribute.HAS_VIDEO) > 0) {
      retVal.push(['video-camera', 'Có video']);
    }

    if ((attribute & ContentAttribute.HAS_AUDIO) > 0) {
      retVal.push(['audio', 'Có audio']);
    }

    if ((attribute & ContentAttribute.LIVE) > 0) {
      retVal.push(['fire', 'Tường thuật trực tiếp']);
    }

    if ((attribute & ContentAttribute.UPDATING) > 0) {
      retVal.push(['redo', 'Liên tục cập nhật']);
    }

    if ((attribute & ContentAttribute.INTERVIEW) > 0) {
      retVal.push(['solution', 'Giao lưu trực tuyến']);
    }

    return retVal;
  }, [content.Attributes, content.DistributionDate]);

  return iconTypes.map((type) => {
    return <Icon key={type[0]} type={type[0]} title={type[1]} className={classes.icon} />;
  });

}
