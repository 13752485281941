import { blue, green, grey, red } from '@ant-design/colors';
import { Icon, Input, Tooltip } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { emptyFunction, emptyObject } from 'core/common/empty';
import { isNullOrUndefined } from 'core/common/is';
import makeStyles from 'core/common/makeStyles';
import ImageDisplay from 'core/components/common/ImageDisplay';
import OnBlurTextArea from 'core/components/common/OnBlurTextArea';
import FileChooserButton from 'core/components/file-manager/FileChooserButton';
import useDebounce from 'core/hooks/useDebounce';
import useImageSize from 'core/hooks/useImageSize';
import useSyncRef from 'core/hooks/useSyncRef';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
    alignItems: 'center',
    paddingBottom: 5,
  },
  avatar: {
    marginBottom: 10,
  },
  captionInput: {
    marginTop: 5,
  },
  size: {
    position: 'absolute',
    left: 0,
    padding: '1px 3px',
    backgroundColor: '#333',
    lineHeight: 'initial',
    color: '#fff',
    whiteSpace: 'nowrap',
    borderBottomRightRadius: 4,
  },
}));

const Status = {
  EMPTY: 1,
  LOADING: 2,
  LOADED: 3,
  ERROR: 4,
};

const StatusIcon = {
  [Status.EMPTY]: '',
  [Status.LOADING]: 'loading',
  [Status.LOADED]: 'check-circle',
  [Status.ERROR]: 'close-circle',
};

const StatusTooltip = {
  [Status.EMPTY]: '',
  [Status.LOADING]: 'Đang tải ảnh...',
  [Status.LOADED]: '',
  [Status.ERROR]: 'Không thể tải ảnh từ đường dẫn này!',
};

const StatusStyle = {
  [Status.EMPTY]: { color: grey[5] },
  [Status.LOADING]: { color: blue[4] },
  [Status.LOADED]: { color: green[7] },
  [Status.ERROR]: { color: red.primary },
};

export default forwardRef(function ContentFormAvatarInput({
  value = emptyObject,
  onChange = emptyFunction,
  showDescription = true,
  editorRef,
}, ref) {
  const classes = useStyles();

  const [internalUrl, setInternalUrl] = useState();
  const internalUrlDebounced = useDebounce(internalUrl, 500);
  const valueRef = useSyncRef(value);
  const { size, isLoading, error } = useImageSize(internalUrlDebounced);
  const status = useMemo(() => {
    if (error) {
      return Status.ERROR;
    }

    if (isLoading) {
      return Status.LOADING;
    }

    if (!size.width || !size.height) {
      return Status.EMPTY;
    }

    return Status.LOADED;
  }, [size, isLoading, error]);

  useEffect(() => {
    if (value && value.url) {
      setInternalUrl(value.url);
    } else {
      setInternalUrl(null);
    }
  }, [value]);

  useEffect(() => {
    if (isNullOrUndefined(internalUrlDebounced)) { // ignore first run or null value
      return;
    }

    if (!internalUrlDebounced) { // empty string, caused by user
      onChange({
        ...valueRef.current,
        url: '',
        error: null,
      });

      return;
    }

    if (valueRef.current && valueRef.current.url === internalUrlDebounced) {
      return;
    }

    onChange({
      ...valueRef.current,
      url: internalUrlDebounced,
      error: null,
    });
  }, [internalUrlDebounced, valueRef]);

  const handleSelect = useCallback((file) => {
    onChange({
      ...valueRef.current,
      url: file.Path,
      error: null,
    });
  }, [onChange, valueRef]);

  const handleDescriptionChange = useCallback((description) => {
    onChange({
      ...valueRef.current,
      description,
    });
  }, [onChange, valueRef]);

  const handleUrlChange = useCallback((event) => {
    setInternalUrl(event.target.value.trim());
  }, []);

  return (
    <div className={classes.container}>
      {size.width && size.height && status === Status.LOADED ? (
        <span className={classes.size}>{size.width} x {size.height}</span>
      ) : null}
      {status === Status.LOADED && (
        <ImageDisplay src={value.url} alt="avatar" className={classes.avatar} />
      )}
      <Input
        ref={ref}
        placeholder="Ảnh đại diện"
        value={internalUrl}
        onChange={handleUrlChange}
        addonAfter={
          <FileChooserButton
            title="Chọn ảnh đại diện"
            triggerComponent={TriggerComponent}
            onSelect={handleSelect}
            currentFileUrl={internalUrl}
          />
        }
        suffix={
          <Tooltip title={StatusTooltip[status]}>
            {StatusIcon[status] ? <Icon style={StatusStyle[status]} type={StatusIcon[status]} /> : null}
          </Tooltip>
        }
      />
      {showDescription && value.url ? (
        <OnBlurTextArea
          ref={editorRef}
          className={classes.captionInput}
          placeholder="Mô tả cho ảnh đại diện"
          value={value.description}
          onChange={handleDescriptionChange}
        />
      ) : null}
    </div>
  );
});

function TriggerComponent({ onClick }) {
  return (
    <Icon type="cloud-upload" onClick={onClick} />
  );
}
