import React from 'react';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import BaseTable from 'core/components/table/BaseTable';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import { commonColumns } from 'core/common/listUtils';
import InterviewQuestionFormDialogButton from './InterviewQuestionFormDialogButton';
import { InterviewQuestionStatusLabel } from 'core/engine/interview-question/constants';
import DeleteButton from 'core/components/common/DeleteButton';
import InterviewAnswerFormDialogButton from '../interview-answers/InterviewAnswerFormDialogButton';
import InterviewGuestDisplay from '../interview-guests/InterviewGuestDisplay';
import lodash from 'core/common/lodash';
import HtmlDisplay from 'core/components/common/HtmlDisplay';
import { emptyArray } from 'core/common/empty';

export default function InterviewQuestionList({
  onUpdate,
  onDelete,
  interview,
  guests = emptyArray,
  ...props
}) {

  const columns = [
    {
      Header: 'ID',
      accessor: 'QuestionID',
      width: 50,
      ...commonColumns.allCentered,
    },
    {
      Header: 'Người gửi',
      accessor: 'UserDisplayName',
      minWidth: 100,
      maxWidth: 200,
    },
    {
      Header: 'Câu hỏi',
      accessor: 'Question',
      Cell: ({ value }) => <HtmlDisplay html={value} />,
      minWidth: 200,
    },
    {
      Header: 'Trạng thái',
      accessor: 'Status',
      Cell: ({ value }) => (InterviewQuestionStatusLabel[value]),
      width: 120,
      ...commonColumns.allCentered,
    },
    {
      Header: 'Thời gian',
      accessor: 'Date',
      Cell: ({ value }) => (<DateTimeDisplay timestamp={value} />),
      maxWidth: 150,
      minWidth: 120,
    },
    {
      Header: 'Khách mời',
      minWidth: 160,
      maxWidth: 200,
      style: { flexWrap: 'wrap', marginTop: -8, alignSelf: 'flex-start' },
      Cell: ({ original }) => (
        <InterviewGuestDisplay guests={original.Guests} />
      ),
      accessor: 'GuestID', // for filter purpose only
    },
    {
      ...commonColumns.formatNumber,
      Header: 'Trả lời',
      width: 60,
      accessor: 'AnswerCount',
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <InterviewAnswerFormDialogButton
            onDone={onUpdate}
            isEditMode={false}
            interview={interview}
            answer={{
              InterviewID: interview.InterviewID,
              QuestionID: original.QuestionID,
              InterviewQuestion: original,
              GuestID: findGuest(lodash.get(original, 'Guests[0].GuestID')),
            }}
          />
          <InterviewQuestionFormDialogButton onDone={onUpdate} interview={interview} question={original} />
          {interview.isAdmin ? (
            <DeleteButton title={`Xóa câu hỏi: ${original.Question}?`} onDelete={() => onDelete(original.QuestionID)} />
          ) : null}
        </ActionsCellWrapper>
      ),
      width: interview.isAdmin ? 150 : 100,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );

  function findGuest(guestId) {
    return lodash.find(guests, { GuestID: guestId }) ? guestId : lodash.get(guests, '[0].GuestID');
  }
}
