import { Input } from 'antd';
import NumberAddonInput from 'core/components/common/NumberAddonInput';
import Selector from 'core/components/common/Selector';
import { SettingSystemKeys } from 'core/engine/setting/constants';
import useServerConfig from 'core/hooks/useServerConfig';
import { AwareInputTypeLabels, AwareInputTypes } from 'core/modules/contents/components/form/input/ContentFormLengthAwareInput';
import React, { useMemo } from 'react';
import SettingForm from './SettingForm';

const { TextArea } = Input;

export default function SettingGeneralForm({ form, onSubmit }) {
  const settings = useMemo(() => {
    return [
      {
        label: 'Tiếp đầu ngữ',
        key: SettingSystemKeys.DescriptionPrefix,
        input: <Input />,
      },
      {
        label: 'Thời hạn đổi mật khẩu',
        key: 'AccountExpirationDate',
        input: <NumberAddonInput addon="Ngày" />,
      },
      {
        label: 'Tin đọc nhiều',
        key: 'DayLeftOfMostRead',
        input: <NumberAddonInput addon="Ngày" />,
      },
      {
        label: 'Phản hồi nhiều',
        key: 'DayLeftOfFeedback',
        input: <NumberAddonInput addon="Ngày" />,
      },
      {
        label: 'Tiêu đề tối đa',
        key: SettingSystemKeys.LimitedTitleCharacter,
        subKeys: [SettingSystemKeys.LimitedInputType(SettingSystemKeys.LimitedTitleCharacter)],
        input: <Input type="number" addonAfter={
          <AwareInputTypeSelector form={form} fieldName={SettingSystemKeys.LimitedTitleCharacter} />} />,
      },
      {
        label: 'Mô tả tối đa',
        key: SettingSystemKeys.LimitedDescCharacter,
        subKeys: [SettingSystemKeys.LimitedInputType(SettingSystemKeys.LimitedDescCharacter)],
        input: <Input type="number" addonAfter={
          <AwareInputTypeSelector form={form} fieldName={SettingSystemKeys.LimitedDescCharacter} />} />,
      },
      {
        label: 'Tiêu đề SEO tối đa',
        key: SettingSystemKeys.LimitedSEOTitleCharacter,
        input: <NumberAddonInput addon="Ký tự" />,
      },
      {
        label: 'Mô tả SEO tối đa',
        key: SettingSystemKeys.LimitedSEODescCharacter,
        input: <NumberAddonInput addon="Ký tự" />,
      },
      {
        label: 'Keywords',
        key: 'StringConfig_SeoDefaultKeywords',
        input: <TextArea rows={4} />,
      },
      {
        label: 'Description',
        key: 'StringConfig_SeoDefaultDescription',
        input: <TextArea rows={4} />,
      },
      {
        label: 'Google Analytics IDs',
        key: 'StringConfig_GoogleAnalyticsIDs',
        input: <Input />,
      },
    ];
  });

  return (
    <SettingForm form={form} onSubmit={onSubmit} settings={settings} />
  );
}

export function AwareInputTypeSelector({ form, fieldName }) {
  const config = useServerConfig();
  const formField = SettingSystemKeys.LimitedInputType(fieldName);

  form.getFieldDecorator(formField, { initialValue: config.LimitedInputType || AwareInputTypes[0] });

  return (
    <Selector
      options={AwareInputTypeLabels}
      value={form.getFieldValue(formField)}
      onChange={value => form.setFieldsValue({ [formField]: value })}
      style={{ width: 80 }}
    />
  );
}
