import Selector from 'core/components/common/Selector';
import React, { forwardRef, useEffect, useState } from 'react';
import { layoutRegistry } from 'core/modules/operations/widgets/registry';

export default forwardRef(function WidgetLayoutSelector(props, ref) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const layouts = layoutRegistry.getAll();
    const options = layouts.map((layout) => ({
      value: layout.id,
      label: layout.name,
    }));

    setOptions(options);
  }, []);

  return (
    <Selector
      ref={ref}
      options={options}
      valueIsNumber
      {...props}
    />
  );
});
