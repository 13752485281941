import { red } from '@ant-design/colors';
import { Button, Input } from 'antd';
import React, { useCallback, useMemo, Fragment } from 'react';
import { commonMixins } from 'core/common/commonStyles';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import useForm from 'core/hooks/useForm';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 700,
    marginBottom: 8,
    display: 'block',

    '&:not(:first-child)': {
      marginTop: 10,
    },
  },
  delete: {
    ...commonMixins.buttonColor(red[6]),
  },
  contentList: {
    maxHeight: 300,
    overflow: 'auto',
  },
  note: {
    marginTop: 15,
  },
}));

export default function ContentSelectionToolbarDeleteButton({
  selectedIds,
  contents,
  onDelete,
}) {
  const classes = useStyles();

  const { open, handleClose, handleOpen } = useDialog();
  const { formData, handleChange } = useForm({});

  const handleOk = useCallback(() => {
    handleClose();
    onDelete(formData.note);
  }, [formData, handleClose, onDelete]);

  const contentsMap = useMemo(() => lodash.keyBy(contents, 'ContentID'), [contents]);

  return (
    <Fragment>
      <Button
        name="delete"
        title="Xóa các tin đã chọn"
        onClick={handleOpen}
        icon="delete"
        className={classes.delete}
      >
        Xóa tin
      </Button>
      <BaseDialog
        visible={open}
        title={`Xóa ${selectedIds.length} tin đã chọn?`}
        onCancel={handleClose}
        onOk={handleOk}
        okText="Xóa tin"
        okButtonProps={{ disabled: !formData.note }}
        keyboard
        maskClosable
      >
        <span className={classes.label}>Danh sách tin sẽ xóa:</span>
        <ul className={classes.contentList}>
          {selectedIds.map(contentId => (<li key={contentId}>{contentsMap[contentId].Title}</li>))}
        </ul>
        <span className={classes.label}>Ghi chú:</span>
        <Input.TextArea
          placeholder="Nhập lý do xóa tin"
          name="note"
          value={formData.note}
          onChange={handleChange}
          autoFocus
        />
        <p className={classes.note}>Các tin bị xóa sẽ được chuyển vào trang "Quản lý tin &gt; Tin đã xóa".</p>
      </BaseDialog>
    </Fragment>);
}
