import { commonColumns } from 'core/common/listUtils';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import BaseTable from 'core/components/table/BaseTable';
import useAuth from 'core/hooks/useAuth';
import React from 'react';
import interviewServices from '../services';
import InterviewAnswerButtonForList from './interview-answers/InterviewAnswerButtonForList';
import InterviewGuestButtonForList from './interview-guests/InterviewGuestButtonForList';
import InterviewQuestionButtonForList from './interview-questions/InterviewQuestionButtonForList';
import InterviewEmbeddedContentListButtonForList from './InterviewEmbeddedContentListButtonForList';
import InterviewFormDialogButton from './InterviewFormDialogButton';

export default function InterviewList({ onUpdate, ...props }) {
  const { loggedInUser } = useAuth();

  const columns = [
    {
      Header: 'ID',
      accessor: 'InterviewID',
      maxWidth: 100,
      minWidth: 50,
      ...commonColumns.allCentered,
    },
    {
      Header: 'Tiêu đề',
      accessor: 'Title',
      minWidth: 200,
    },
    {
      Header: 'Trạng thái',
      id: 'Enabled',
      Cell: ({ original }) => original.Enabled ? 'Hiển thị' : 'Không hiển thị',
      maxWidth: 120,
      minWidth: 80,
      ...commonColumns.allCentered,
    },
    {
      ...commonColumns.formatNumber,
      Header: 'Câu hỏi',
      width: 80,
      accessor: 'QuestionCount',
    },
    {
      ...commonColumns.formatNumber,
      Header: 'Trả lời',
      width: 80,
      accessor: 'AnswerCount',
    },
    {
      Cell: ({ original }) => {
        const isAdmin = interviewServices.isAdmin(original, loggedInUser);
        const isEditor = interviewServices.isEditor(original, loggedInUser);

        return (
          <ActionsCellWrapper>
            <InterviewGuestButtonForList interviewId={original.InterviewID} disabled={!isAdmin && !isEditor} />
            <InterviewQuestionButtonForList interviewId={original.InterviewID} disabled={!isAdmin && !isEditor} />
            <InterviewAnswerButtonForList interviewId={original.InterviewID} disabled={!isAdmin} />
          </ActionsCellWrapper>
        );
      },
      width: 150,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <InterviewEmbeddedContentListButtonForList interviewId={original.InterviewID} />
          <InterviewFormDialogButton interview={original} onDone={onUpdate} />
        </ActionsCellWrapper>
      ),
      width: 100,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
