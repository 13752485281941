import { Form } from 'antd';
import ContentFormRelatedContentsInput from './ContentFormRelatedContentsInput';
import React from 'react';
import contentApi from 'core/engine/content/api';
import lodash from 'core/common/lodash';
import { Sites } from 'core/engine/site/constants';

export default function ContentFormItemRelatedContents({ form, content, bodyEditorRef, siteId = Sites.ALL }) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item>
      {getFieldDecorator('RelatedContents', {
        initialValue: content.RelatedContents,
      })(
        <ContentFormRelatedContentsInput getSuggestedContent={getSuggestedContent} siteId={siteId} />
      )}
    </Form.Item>
  );

  function getSuggestedContent() {
    const contentBodyText = bodyEditorRef.current ? bodyEditorRef.current.getValueAsText() : '';
    const keywords = form.getFieldValue('Keywords');
    return contentApi.getRelated(siteId, 21, keywords, contentBodyText).then((resp) => { // get an extra one to remove later in case the suggested list also suggest the current content
      lodash.remove(resp.data.items, { ContentID: content.ContentID });
      return resp;
    });
  }
}
