import { range } from 'lodash';
import moment from 'moment';

export function formatDateTime(timestamp, format = 'DD/MM/YYYY HH:mm') {
  return moment(new Date(parseInt(timestamp))).format(format);
}

export function formatDateFromInt(value) {
  const str = String(value);
  return str.substring(0, 4) + '/' + str.substring(4, 6) + '/' + str.substring(6, 8);
}

export function disabledStartTime(startValue, threshold) {
  if (!startValue || !threshold) {
    return false;
  }

  if (!threshold.isBefore(startValue, 'days')) {
    return {
      disabledHours: () => range(threshold.hours() + 1, 24),
      disabledMinutes: () => threshold.isAfter(startValue, 'hours') ? [] : range(threshold.minutes() + 1, 60),
    };
  }

  return false;
}

export function disabledEndTime(endValue, threshold) {
  if (!endValue || !threshold) {
    return false;
  }

  if (!threshold.isBefore(endValue, 'days')) {
    return {
      disabledHours: () => range(0, threshold.hours()),
      disabledMinutes: () => threshold.isBefore(endValue, 'hours') ? [] : range(0, threshold.minutes()),
    };
  }

  return false;
}
