import React from 'react';
import { emptyArray } from 'core/common/empty';
import { Icon } from 'antd';
import makeStyles from 'core/common/makeStyles';
import CardWidget from 'core/components/common/CardWidget';
import { blue } from '@ant-design/colors';

const useStyles = makeStyles(() => ({
  accountItem: {
    padding: '6px 0',
  },
  icon: {
    fontSize: 16,
    color: blue[6],
    paddingRight: 5,
  },
}));

export default function DashBoardWidgetUserOnlines({ users = emptyArray }) {
  const classes = useStyles();

  return (
    <CardWidget title="Ai đang online">
      {users.map(user => (
        <div key={user.AccountID} className={classes.accountItem}>
          <Icon type="user" className={classes.icon} />
          <span>{user.Username}{user.Pseudonym && ` - ${user.Pseudonym}`}</span>
        </div>
      ))}
    </CardWidget>
  );
}
