import { IS_MOBILE } from '@bm/common';
import { Col, Row, Spin, Tree } from 'antd';
import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { asyncAction } from 'core/common/async';
import makeStyles from 'core/common/makeStyles';
import { buildTree } from 'core/common/utils';
import Container from 'core/components/common/Container';
import commandApi from 'core/engine/command/api';
import CommandForm from './CommandForm';

const { TreeNode } = Tree;
const EMPTY_COMMAND = { Enabled: true, Visible: true };

const useStyles = makeStyles(() => ({
  tree: {
    borderRight: '1px solid #d4d4d4',
    minHeight: '70vh',
  },
  mobileTree: {
    minHeight: 'auto',
  },
  mobileForm: {
    borderTop: '1px solid #d4d4d4',
    paddingTop: 10,
  },
  allCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function CommandsView() {
  const classes = useStyles();
  const [commandMap, setCommandMap] = useState({});
  const [commandTrees, setCommandTrees] = useState([]);
  const [selectedCommand, setSelectedCommand] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    commandApi.getListAll()
      .then((resp) => {
        const commandMap = {};

        resp.data.items.forEach((item) => {
          commandMap[item.CommandID] = item;
        });

        setCommandMap(commandMap);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setCommandTrees(buildTree(Object.values(commandMap), { idField: 'CommandID' }));
  }, [commandMap]);

  useEffect(() => {
    setSelectedCommand((command) => {
      return command && commandMap[command.CommandID];
    });
  }, [commandMap, setSelectedCommand]);

  const onSelect = useCallback((selectedKeys) => {
    setSelectedCommand(commandMap[selectedKeys[0]]);
  }, [setSelectedCommand, commandMap]);

  const handleSubmit = useCallback((formData) => {
    if (formData.CommandID !== undefined) {
      return asyncAction('Sửa chức năng', () => {
        return commandApi.update(formData).then(() => {
          const newCommand = { ...commandMap[formData.CommandID], ...formData };

          setCommandMap({
            ...commandMap,
            [newCommand.CommandID]: newCommand,
          });
        });
      });
    }

    return asyncAction('Tạo chức năng', () => {
      return commandApi.create(formData).then((resp) => {
        const commandId = resp.data.generatedId;
        const newCommand = { ...formData, CommandID: commandId };

        setSelectedCommand(newCommand);
        setCommandMap({
          ...commandMap,
          [commandId]: newCommand,
        });
      });
    });
  }, [setCommandMap, commandMap, setSelectedCommand]);

  const handleCancel = useCallback(() => {
    setSelectedCommand();
  }, [setSelectedCommand]);

  const handleDelete = useCallback((formData) => {
    return asyncAction('Xóa chức năng', () => {
      return commandApi.delete(formData.CommandID).then(() => {
        setCommandMap(commandMap => {
          const newCommandMap = { ...commandMap };
          delete newCommandMap[formData.CommandID];
          return newCommandMap;
        });
      });
    });
  }, [setCommandMap]);

  return (
    <Container pageTitle="Chức năng">
      <Row>
        <Col xs={24} sm={8} className={classnames(IS_MOBILE ? classes.mobileTree : classes.tree, isLoading && classes.allCentered)}>
          {isLoading ? (
            <Spin />
          ) : (
            <Tree onSelect={onSelect}>
              {commandTrees.map(item => buildNodes(item))}
            </Tree>
          )}
        </Col>
        <Col xs={24} sm={16} className={classnames(IS_MOBILE ? classes.mobileForm : null)}>
          <CommandForm
            command={selectedCommand || EMPTY_COMMAND}
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
            handleCancel={handleCancel}
          />
        </Col>
      </Row>
    </Container>
  );
}

function buildNodes(item) {
  if (item.Subs.length) {
    return (
      <TreeNode key={item.CommandID} title={item.Name}>
        {item.Subs.map(subItem => buildNodes(subItem))}
      </TreeNode>
    );
  }

  return (
    <Tree.TreeNode key={item.CommandID} title={item.Name} isLeaf />
  );
}
