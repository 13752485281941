import classnames from 'classnames';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import makeStyles from 'core/common/makeStyles';
import { emptyObject } from 'core/common/empty';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    lineHeight: 0, // to avoid unexpected padding
  },
  poster: {
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'pointer',
    height: '100%',
  },
  play: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    cursor: 'pointer',
    fontSize: 50,
    color: '#fff',
    transform: 'translateX(-50%) translateY(-50%)',
    pointerEvents: 'none',
  },
  hide: {
    display: 'none',
  },
}));

export default forwardRef(function AudioPlayer({ src, className, audioProps = emptyObject }, ref) {
  const classes = useStyles();
  const audioRef = useRef();

  useImperativeHandle(ref, () => ({
    getAudio() {
      return audioRef.current;
    },
  }));

  return (
    <div className={classnames(classes.container, className)}>
      <audio
        ref={audioRef}
        src={src}
        controls
        width="100%"
        height="80px"
        preload="metadata"
        {...audioProps}
      />
    </div>
  );

});
