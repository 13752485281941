import { httpGet } from 'core/common/http-client';

const DEFAULT_FIELDS = [];
const DEFAULT_LOOKUP = [];

const widgetDistributionApi = {
  get(id) {
    return httpGet(`/widget-distributions/get/item?DistributionID=${id}`);
  },

  getList({ limit = 20, offset = 0, sortBy, fields = [], lookup = [], filters }) {
    const qs = {
      limit, offset, sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      lookup: [...DEFAULT_LOOKUP, ...lookup],
      ...filters,
    };

    return httpGet('/widget-distributions/get/items-by-range', { qs });
  },
};

export default widgetDistributionApi;
