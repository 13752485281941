import React from 'react';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import moment from 'moment';
import { DatePicker, Form } from 'antd';

export default function ContentFormItemDistributionDate({ content, form }) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={ContentFieldLabel.DistributionDate}>
      {getFieldDecorator('DistributionDate', {
        initialValue: content.DistributionDate ? moment(parseInt(content.DistributionDate)) : null,
      })(<DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />)}
    </Form.Item>
  );
}
