import { Icon } from 'antd';
import React from 'react';
import lodash from 'core/common/lodash';
import fileApi from 'core/engine/file/api';
import useAsyncAction from 'core/hooks/useAsyncAction';
import FileChooserButton from '../FileChooserButton';

export default function FileVideoSelectLibraryAvatarButton({ file, onUpdated }) {
  const { isLoading, performAction } = useAsyncAction();

  return (
    <FileChooserButton
      title="Chọn ảnh đại diện cho video"
      onSelect={handleAvatarSelect}
      triggerComponent={({ onClick }) => (
        <Icon title="Chọn ảnh đại diện từ thư viện ảnh" type={isLoading ? 'loading' : 'picture'} onClick={onClick} />
      )}
    />
  );

  function handleAvatarSelect(value) {
    const newMeta = {
      ...file.Meta,
      avatarUrl: value.Path,
      avatarWidth: lodash.get(file, 'Meta.width') || 0,
      avatarheight: lodash.get(file, 'Meta.height') || 0,
    };

    performAction('Cập nhật ảnh đại diện video', () => {
      return fileApi.update({
        FileID: file.FileID,
        Meta: JSON.stringify(newMeta),
      })
        .then(() => {
          onUpdated({
            ...file,
            Meta: newMeta,
          });
        });
    });
  }
}
