import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

export default function InterviewGuestButtonForList({ interviewId, disabled }) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push({
      pathname: '/operations/interviews/guests',
      search: `?interviewId=${interviewId}`,
    });
  }, [interviewId]);

  return (
    <Button title="Khách mời" icon="team" onClick={handleClick} disabled={disabled} />
  );
}
