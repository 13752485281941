import { httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_FIELDS = [];

const albumApi = {
  get(id) {
    return httpGet(`/albums/get/item?AlbumID=${id}`);
  },

  getList({ limit = 20, offset = 0, sortBy, fields = [], filters }) {
    const qs = {
      limit, offset, sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      ...filters,
    };

    return httpGet('/albums/get/items-by-range', { qs });
  },

  create(data) {
    return httpPost('/albums/post/insert', data);
  },

  update(data) {
    return httpPost('/albums/post/update', data);
  },

  delete(id) {
    return httpPost('/albums/post/delete', { AlbumID: id });
  },

  addFiles(albumId, fileIds = []) {
    return httpPost('/albums/post/add-files', { AlbumID: albumId, fileIds });
  },
};

export default albumApi;
