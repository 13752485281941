import { Result, Typography } from 'antd';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Container from '../common/Container';
import FlexDiv from '../common/FlexDiv';
import GoBackButton from '../common/GoBackButton';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexAllCentered,
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
}));

export default function NotFoundView() {
  const classes = useStyles();
  const location = useLocation();
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const intervalId = setInterval(() => setCounter(old => old - 1), 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (counter < 0) {
    return <Redirect path="/" />;
  }

  return (
    <Container>
      <FlexDiv className={classes.container}>
        <Result
          status="404"
          title="Không tìm thấy đường dẫn này"
          subTitle={location.pathname}
          extra={<GoBackButton icon="home" type="primary" />}
        />
        <Typography.Text type="secondary">Bạn sẽ được chuyển về trang chủ sau <b>{counter}</b> giây</Typography.Text>
      </FlexDiv>
    </Container>
  );
}
