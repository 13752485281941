import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import ContentEditButtonForList from 'core/components/content/ContentEditButtonForList';
import ContentFilterableAccount from 'core/components/content/ContentFilterableAccount';
import ContentLevelSelector from 'core/components/content/ContentLevelSelector';
import ContentLogButtonForList from 'core/components/content/ContentLogButtonForList';
import { ContentPushZOAButton } from 'core/components/content/ContentPushZOAButton';
import CheckableTable from 'core/components/table/CheckableTable';
import { CONTENT_LIST_CTR_STATS, CONTENT_RATE_TYPE } from 'core/config';
import useContentColumns from 'core/hooks/useContentColumns';
import React from 'react';

export default function DeployedList({
  selectedIds,
  onSelectedChange,
  onEditorSelected,
  onWriterSelected,
  onDeployerSelected,
  onContentLevelChange,
  onPushZOA,
  filteredZoneId,
  deployZoneIds,
  ...props
}) {
  const ContentColumns = useContentColumns();

  const columns = [
    ContentColumns.ContentID,
    ContentColumns.Avatar,
    ContentColumns.Title,
    {
      Header: 'Thứ tự',
      width: 70,
      style: { justifyContent: 'center' },
      Cell: ({ original }) => (
        <ContentLevelSelector
          value={original.Order}
          zoneId={filteredZoneId ? filteredZoneId : original.ZoneID}
          onChange={(zoneId, level) => onContentLevelChange(original.ContentID, zoneId, level)}
          disabled={Boolean(original.IsLockEdit) || !deployZoneIds.includes(original.ZoneID)}
        />
      ),
    },
    {
      Header: 'Người tạo',
      accessor: 'WriterID',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <ContentFilterableAccount
            id={original.WriterID}
            onClick={() => onWriterSelected(original.WriterID)}
            title="Lọc tin tạo bởi tài khoản này"
          />
          <br />
          <i><DateTimeDisplay timestamp={original.CreatedDate} /></i>
        </div>
      ),
    },
    {
      Header: 'Người đăng',
      accessor: 'DeployerID',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <ContentFilterableAccount
            id={original.DeployerID}
            onClick={() => onDeployerSelected(original.DeployerID)}
            title="Lọc tin đăng bởi tài khoản này"
          />
          <br />
          <i><DateTimeDisplay timestamp={original.DistributionDate} /></i>
        </div>
      ),
    },
    {
      Header: 'Người sửa',
      accessor: 'EditorID',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <ContentFilterableAccount
            id={original.EditorID}
            onClick={() => onEditorSelected(original.EditorID)}
            title="Lọc tin sửa bởi tài khoản này"
          />
          <br />
          <i><DateTimeDisplay timestamp={original.ModifiedDate} /></i>
        </div>
      ),
    },
    {
      ...ContentColumns.ViewCount,
      sortable: true,
    },
    CONTENT_LIST_CTR_STATS && ContentColumns.CTR,
    CONTENT_RATE_TYPE && ContentColumns.Rate,
    ContentColumns.CheckIn,
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <ContentEditButtonForList content={original} />
          <ContentLogButtonForList contentId={original.ContentID} />
          <ContentPushZOAButton
            contentId={original.ContentID}
            disabled={original.ZOAContents}
            onClick={onPushZOA}
          />
        </ActionsCellWrapper>
      ),
      width: 145,
    },
  ].filter(Boolean);

  return (
    <CheckableTable
      selectOnLeft
      idField="ContentID"
      columns={columns}
      selectedIds={selectedIds}
      onSelectedChange={onSelectedChange}
      {...props}
    />
  );
}
