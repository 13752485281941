import React, { useEffect, useRef, useState } from 'react';
import classnames from 'core/common/classnames';
import { emptyFunction } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import useSyncRef from 'core/hooks/useSyncRef';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  dropZone: {
    border: 'dashed grey 4px',
    backgroundColor: 'rgba(255,255,255,.8)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  dropText: {
    position: 'absolute',
    top: '50%',
    right: 0,
    left: 0,
    textAlign: 'center',
    color: 'grey',
    fontSize: 36,
  },
}));

export default function FileManagerDragAndDrop({ onDrop = emptyFunction, children, className }) {
  const classes = useStyles();
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef();
  const dragCounterRef = useRef(0);
  const onDropRef = useSyncRef(onDrop);

  useEffect(() => {
    const handleDrag = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleDragIn = (evemt) => {
      evemt.preventDefault();
      evemt.stopPropagation();
      dragCounterRef.current += 1;

      if (evemt.dataTransfer.items && evemt.dataTransfer.items.length > 0) {
        setIsDragging(true);
      }
    };

    const handleDragOut = (event) => {
      event.preventDefault();
      event.stopPropagation();
      dragCounterRef.current -= 1;

      if (dragCounterRef.current === 0) {
        setIsDragging(false);
      }
    };

    const handleDrop = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(false);

      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        onDropRef.current(event.dataTransfer.files);
        event.dataTransfer.clearData();
        dragCounterRef.current = 0;
      }
    };

    const containerElement = containerRef.current;
    containerElement.addEventListener('dragenter', handleDragIn);
    containerElement.addEventListener('dragleave', handleDragOut);
    containerElement.addEventListener('dragover', handleDrag);
    containerElement.addEventListener('drop', handleDrop);

    return () => {
      containerElement.removeEventListener('dragenter', handleDragIn);
      containerElement.removeEventListener('dragleave', handleDragOut);
      containerElement.removeEventListener('dragover', handleDrag);
      containerElement.removeEventListener('drop', handleDrop);
    };
  }, [onDropRef]);

  return (
    <div className={classnames(classes.container, className)} ref={containerRef}>
      {children}
      {isDragging && (
        <div className={classes.dropZone}>
          <div className={classes.dropText}>
            <div>Thả tệp vào đây</div>
          </div>
        </div>
      )}
    </div>
  );
}
