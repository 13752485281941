import { message } from 'antd';
import { buildCdnPhotoUrl } from 'core/common/cdn';
import { emptyFunction, emptyObject } from 'core/common/empty';
import { getImageDimensions } from 'core/common/imageUtils';
import { parseJson } from 'core/common/jsonUtils';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import contentAvatarApi from 'core/engine/content-avatar/api';
import { ContentAvatarSize, ContentAvatarType } from 'core/engine/content-avatar/constants';
import useImageSize from 'core/hooks/useImageSize';
import ContentFormAvatarInput from 'core/modules/contents/components/form/input/ContentFormAvatarInput';
import React, { forwardRef, useEffect, useState } from 'react';
import ContentAvatarDialogButton from './ContentAvatarDialogButton';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    '&:hover $overlayButton': {
      display: 'block',
    },
  },
  overlayButton: {
    display: 'none',
    position: 'absolute',
    top: -8,
    right: 0,
  },
}));

export default forwardRef(function ContentFormAvatars({
  form,
  content,
  value = emptyObject,
  onChange = emptyFunction,
}, ref) {
  const classes = useStyles();
  const { size, error } = useImageSize(value.url);

  const [originContentAvatars, setOriginContentAvatars] = useState([]);
  const [contentAvatars, setContentAvatars] = useState([]);
  const [loadOldContentAvatars, setLoadOldContentAvatars] = useState(true);

  useEffect(() => {
    if (loadOldContentAvatars && content.ContentID && size.width && size.height) {
      contentAvatarApi.getItems(content.ContentID).then(resp => {
        const contentAvatars = itemsToContentAvatars(resp.data.items);
        setOriginContentAvatars(contentAvatars);
        setContentAvatars(contentAvatars);
        setLoadOldContentAvatars(false);
        form.setFieldsValue({ 'contentAvatarIds': contentAvatars.map(avatar => avatar.AvatarID) });
      });
    }
  }, [loadOldContentAvatars, content, size]);

  useEffect(() => {
    handleChange(value);
  }, [value.url]);

  return (
    <div className={classes.container}>
      <ContentFormAvatarInput
        ref={ref}
        contentId={content.ContentID}
        value={value}
        onChange={onChange}
        showDescription={true}
      />
      {value.url && !error && !lodash.isEmpty(ContentAvatarType) && (
        <div className={classes.overlayButton}>
          <ContentAvatarDialogButton
            form={form}
            contentId={content.ContentID}
            value={value}
            size={size}
            contentAvatars={contentAvatars}
            originContentAvatars={originContentAvatars}
            setContentAvatars={setContentAvatars}
            setOriginContentAvatars={setOriginContentAvatars}
          />
        </div>
      )}
    </div>
  );

  function handleChange(newValue) {
    if (!newValue || !newValue.url) {
      onChange(newValue);
      setOriginContentAvatars([]);
      setContentAvatars([]);
      form.setFieldsValue({ 'contentAvatarIds': [] });
      return;
    }

    const url = buildCdnPhotoUrl(newValue.url);
    const loading = message.loading('Đang tải thông tin ảnh');

    getImageDimensions(url)
      .then(resp => {
        if (resp.width && resp.height) {
          onChange(newValue);
          handleLoadImage(loading, true);

          setOriginContentAvatars([]);
          setContentAvatars([]);
          form.setFieldsValue({ 'contentAvatarIds': [] });
        } else {
          handleLoadImage(loading, false);
        }
      }).catch(() => handleLoadImage(loading, false));
  }

  function handleLoadImage(loading, success) {
    loading && loading();
    success
      ? message.success('Tải thông tin ảnh thành công')
      : message.error('Tải thông tin ảnh thất bại');
  }

  function itemsToContentAvatars(items) {
    const contentAvatars = [];

    items.forEach(item => {
      if (!ContentAvatarSize[item.Type]) {
        return;
      }

      const width = ContentAvatarSize[item.Type].width;
      const height = ContentAvatarSize[item.Type].height;
      const meta = parseJson(item.Meta);

      const originWidth = meta.width || size.width;
      const originHeight = meta.height || size.height;

      const isScrollHorizontal = originWidth / originHeight > width / height;

      const backgroundSize = {
        width: isScrollHorizontal ? (meta.width * height / meta.height) : width,
        height: isScrollHorizontal ? height : (meta.height * width / meta.width),
      };

      contentAvatars[item.Type] = {
        AvatarID: item.AvatarID,
        x: isScrollHorizontal ? item.Position : 0,
        y: isScrollHorizontal ? 0 : item.Position,
        isScrollHorizontal: isScrollHorizontal,
        backgroundSize: backgroundSize,
        Meta: meta,
        isModified: false,
      };
    });

    return contentAvatars;
  }
});
