import { COMMENT_DEPLOYER_GROUP, COMMENT_READER_GROUP } from 'core/config';

export const GroupRole = {
  DEPLOYER: 1,
  APPROVER: 2,
  WRITER: 3,

  COMMENT_DEPLOYER: COMMENT_DEPLOYER_GROUP,
  COMMENT_READER: COMMENT_READER_GROUP,
};

export const GroupRoleUp = {
  DEPLOYER: [GroupRole.DEPLOYER],
  APPROVER: [GroupRole.DEPLOYER, GroupRole.APPROVER],
  WRITER: [GroupRole.DEPLOYER, GroupRole.APPROVER, GroupRole.WRITER],

  COMMENT_DEPLOYER: [GroupRole.COMMENT_DEPLOYER].filter(Boolean),
  COMMENT_READER: [GroupRole.COMMENT_READER, GroupRole.COMMENT_DEPLOYER].filter(Boolean),
};
