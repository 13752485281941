import { Form } from 'antd';
import AuthzCheck from 'core/components/common/AuthzCheck';
import LinkInput from 'core/components/common/LinkInput';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React from 'react';

export default function ContentFormItemRedirectLink({ content, form }) {
  const { getFieldDecorator } = form;

  return (
    <AuthzCheck extendedRight="content.edit-redirect-link">
      <Form.Item label={ContentFieldLabel.RedirectLink}>
        {getFieldDecorator('RedirectLink', {
          initialValue: content.RedirectLink,
        })(
          <LinkInput placeholder="http://..." maxLength={255} />
        )}
      </Form.Item>
    </AuthzCheck>
  );
}
