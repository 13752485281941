export const AdsPositionType = {
  Default: 0,
  Random: 1,
  Rotate: 2,
};
export const AdsPositionTypeLabel = {
  [AdsPositionType.Default]: 'Lần lượt',
  [AdsPositionType.Random]: 'Ngẫu nhiên',
  [AdsPositionType.Rotate]: 'Xoay vòng',
};
