export const TopicType = {
  NORMAL: 0,
  SYSTEM: 1,
  PODCAST: 2,
  KNOWLEDGE: 3,
};

export const TopicTypeTitle = {
  [TopicType.NORMAL]: 'Tin',
  [TopicType.SYSTEM]: 'Hệ thống',
  [TopicType.PODCAST]: 'Podcast',
  [TopicType.KNOWLEDGE]: 'Knowledge',
};
