import { Avatar } from 'antd';
import React, { useMemo } from 'react';
import { buildCdnPhotoUrl } from 'core/common/cdn';

export default function ContentAvatar({ content, size = 50, ...props }) {
  const url = useMemo(() => {
    return content.AvatarUrl ? buildCdnPhotoUrl(content.AvatarUrl, 150) : undefined;
  }, [content.AvatarUrl]);

  return (
    <Avatar src={url} shape="square" size={size} {...props} />
  );
}
