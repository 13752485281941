import React, { useMemo } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import useAuth from 'core/hooks/useAuth';
import { menuItemMapSelector, menuItemsLoadedSelector } from 'core/redux/selectors/menu';
import { useSelector } from 'react-redux';
import actionRegistry from 'core/registries/actions';
import { HookAction } from 'core/constants';

export default function PrivateRoute({ component: ViewComponent, authzCheck = true, ...props }) {
  const { loggedInUser } = useAuth();
  const menuItemMap = useSelector(menuItemMapSelector);
  const menuItemsLoaded = useSelector(menuItemsLoadedSelector);
  const location = useLocation();

  const isForbidden = useMemo(() => {
    return menuItemsLoaded && !menuItemMap[location.pathname];
  }, [location.pathname, menuItemMap, menuItemsLoaded]);

  const [AlternativeComponent] = actionRegistry.doAction(HookAction.CHANGE_VIEW_COMPONENT, { props });

  if (AlternativeComponent) {
    ViewComponent = AlternativeComponent;
  }

  const render = (props) => {
    if (!loggedInUser) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }

    if (authzCheck && isForbidden) {
      return (
        <Redirect
          to={{
            pathname: '/forbidden',
            state: { from: props.location },
          }}
        />
      );
    }

    return (<ViewComponent {...props} />);
  };

  return (
    <Route
      {...props}
      render={render}
    />
  );
}
