import React from 'react';
import makeStyles from 'core/common/makeStyles';
import commonStyles from 'core/common/commonStyles';
import classnames from 'core/common/classnames';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexAllCentered,
    width: '100%',

    '& > .ant-btn': {
      transition: 'none', // avoid unwanted animation
    },

    '& > :not(:first-child)': {
      marginLeft: 5,
    },

    '& > :not(:last-child)': {
      marginRight: 5,
    },
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  alignLeft: {
    justifyContent: 'flex-start',
  },
}));

export default function ActionsCellWrapper({ align, children }) {
  const classes = useStyles();

  return (
    <div className={classnames(classes.container, align === 'right' && classes.alignRight, align === 'left' && classes.alignLeft)}>{children}</div>
  );
}
