import { Button, Checkbox, Form, Input, InputNumber } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { parseJson } from 'core/common/jsonUtils';
import ImagePickerInput from 'core/components/common/ImagePickerInput';
import Selector from 'core/components/common/Selector';
import SeoEntryForm from 'core/components/seo-entry/SeoEntryForm';
import seoEntryApi from 'core/engine/seo-entry/api';
import { SeoEntryType } from 'core/engine/seo-entry/constants';
import ZoneFormParentZoneInput from 'core/modules/managements/zones/components/ZoneFormParentZoneInput';
import React, { useEffect, useMemo, useState } from 'react';
import PodcastCategoriesInput from 'src/components/podcast/PodcastCategoriesInput';
import { ThemeLabel } from 'src/engine/theme/constants';

export default function ZoneFormRaw({ form, zone, onSubmit, submitOnEnter = true }) {
  const { getFieldDecorator, resetFields } = form;
  const [seoEntry, setSeoEntry] = useState({});

  useEffect(() => resetFields(), [zone]);

  const meta = useMemo(() => parseJson(zone.Meta, {}), [zone]);

  useEffect(() => {
    if (!zone.ZoneID) {
      setSeoEntry({});
    }

    seoEntryApi.get(SeoEntryType.ZONE, zone.ZoneID)
      .then(resp => setSeoEntry(resp.data.item))
      .catch(() => setSeoEntry({}));
  }, [zone.ZoneID]);

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      {zone.ZoneID && (
        <Form.Item label="ID">
          {getFieldDecorator('ZoneID', {
            initialValue: zone.ZoneID,
          })(
            <Input disabled />
          )}
        </Form.Item>
      )}
      <Form.Item label="Tên">
        {getFieldDecorator('Name', {
          initialValue: zone.Name,
          rules: [{ required: true, message: 'Bắt buộc' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item label="Slug">
        {getFieldDecorator('Slug', {
          initialValue: zone.Slug || '',
          rules: [
            { required: true, message: 'Bắt buộc' },
            { pattern: /^[a-zA-Z0-9-/]+$/, message: 'Chỉ được phép dùng các kí tự không dấu, số và dấu gạch ngang, gạch chéo xuôi' },
          ],
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Mục cha">
        {getFieldDecorator('ParentID', { initialValue: zone.ParentID || 0 })(
          <ZoneFormParentZoneInput allowEmpty excludedIds={zone.ZoneID ? [zone.ZoneID] : []} />
        )}
      </Form.Item>
      <Form.Item label="URL">
        {getFieldDecorator('Url', {
          initialValue: zone.Url || '',
          rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <Input placeholder="URL" />
        )}
      </Form.Item>
      <Form.Item label="Ảnh đại diện">
        {getFieldDecorator('SeoEntry.ImageUrl', {
          initialValue: seoEntry.ImageUrl || '',
          rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <ImagePickerInput onChange={value => setSeoEntry({ ...seoEntry, ImageUrl: value })} />
        )}
      </Form.Item>

      <Form.Item label="Ảnh tiêu đề">
        {getFieldDecorator('Meta.titleImage', {
          initialValue: meta.titleImage || '',
        })(
          <ImagePickerInput />
        )}
      </Form.Item>
      <Form.Item label="Background">
        {getFieldDecorator('Meta.backgroundImage', {
          initialValue: meta.backgroundImage || '',
        })(
          <ImagePickerInput />
        )}
      </Form.Item>
      <Form.Item label="Background mobile">
        {getFieldDecorator('Meta.mobileBackgroundImage', {
          initialValue: meta.mobileBackgroundImage || '',
        })(
          <ImagePickerInput />
        )}
      </Form.Item>
      <Form.Item label="Theme">
        {getFieldDecorator('Meta.theme', {
          initialValue: meta.theme || '',
        })(
          <Selector
            emptyValue=""
            emptyLabel="-- Theme --"
            options={ThemeLabel}
          />
        )}
      </Form.Item>
      <Form.Item label="Podcast cover">
        {getFieldDecorator('Meta.podcastCover', {
          initialValue: meta.podcastCover || '',
        })(
          <ImagePickerInput />
        )}
      </Form.Item>
      <Form.Item label="Podcast categories">
        {getFieldDecorator('Meta.podcastCategories', {
          initialValue: meta.podcastCategories,
        })(
          <PodcastCategoriesInput />
        )}
      </Form.Item>

      <SeoEntryForm form={form} type={SeoEntryType.ZONE} id={zone.ZoneID} defaultValue={zone.SeoEntry} />

      <Form.Item label="Thứ tự">
        {getFieldDecorator('Order', {
          initialValue: zone.Order || 0,
        })(
          <InputNumber />
        )}
      </Form.Item>
      <Form.Item label="Sử dụng">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: zone.Enabled,
        })(
          <Checkbox />
        )}
      </Form.Item>
      <Form.Item label="Hiển thị">
        {getFieldDecorator('Visible', {
          valuePropName: 'checked',
          initialValue: zone.Visible,
        })(
          <Checkbox />
        )}
      </Form.Item>
      {submitOnEnter && <Button htmlType="submit" style={commonStyles.hidden} />}
    </Form>
  );
}
