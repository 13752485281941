import { httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_FIELDS = [
  'TopicID',
  'Name',
  'CreatorID',
  'CreatedDate',
  'ZoneID',
  'Description',
];

const DEFAULT_LOOKUP = [];

const topicApi = {
  get(id) {
    return httpGet(`/topics/get/item?TopicID=${id}`);
  },

  getList({ limit = 20, offset = 0, sortBy, fields = [], lookup = [], filters }) {
    const qs = {
      limit,
      offset,
      sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      lookup: [...DEFAULT_LOOKUP, ...lookup],
      ...filters,
    };

    return httpGet('/topics/get/items-by-range', { qs });
  },

  getListAll() {
    return httpGet('/topics/get/items');
  },

  getListDistributable() {
    return httpGet('/topics/get/distributable-items');
  },

  create(data) {
    return httpPost('/topics/post/insert', data);
  },

  update(data) {
    return httpPost('/topics/post/update', data);
  },

  delete(id) {
    return httpPost('/topics/post/delete', { TopicID: id });
  },

  checkout(id) {
    return httpPost('/topics/post/checkout', { TopicID: id });
  },

  checkin(id) {
    return httpPost('/topics/post/checkin', { TopicID: id });
  },

  forceCheckout(id) {
    return httpPost('/topics/post/force-checkout', { TopicID: id });
  },
};

export default topicApi;
