import React, { Fragment } from 'react';
import useDialog from 'core/hooks/useDialog';
import { Modal } from 'antd';
import useAsyncAction from 'core/hooks/useAsyncAction';

export default function ConfirmButton({
  anchorElement: AnchorElement,
  dialogTitleText,
  dialogBodyText,
  dialogActionText,
  danger,
  handleSubmit,
  disabled = false,
}) {
  const { open, handleOpen, handleClose } = useDialog();
  const { isLoading, performAction } = useAsyncAction();

  function submit() {
    handleClose();
    performAction('', () => handleSubmit(), { silent: true });
  }

  return (
    <Fragment>
      <AnchorElement onClick={handleOpen} loading={isLoading} disabled={disabled} />
      <Modal
        title={dialogTitleText}
        visible={open}
        onOk={submit}
        onCancel={handleClose}
        okText={dialogActionText}
        okType={danger ? 'danger' : 'primary'}
        cancelText="Quay lại"
      >
        <p>{dialogBodyText}</p>
      </Modal>
    </Fragment>
  );

}
