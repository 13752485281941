import { Form } from 'antd';
import commonStyles from 'core/common/commonStyles';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import settingApi from 'core/engine/setting/api';
import React, { useEffect, useMemo } from 'react';

const useStyles = makeStyles({
  groupLabel: {
    fontSize: 18,
    fontWeight: 'bold',
  },
});

export default function SettingForm({ form, onSubmit, settings }) {
  const classes = useStyles();
  const { getFieldDecorator, setFieldsValue } = form;

  const allKeys = useMemo(() => lodash.compact(lodash.flatMapDeep([
    settings.map(lodash.property('key')),
    settings.map(lodash.property('subKeys'))])), [settings]);

  useEffect(() => {
    settingApi.multiGet(allKeys).then((resp) => {
      const fieldMap = resp.data.reduce((previuos, current) => {
        previuos[current.Key] = current.Value;
        return previuos;
      }, {});

      setFieldsValue(fieldMap);
    });
  }, []);

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      {settings.map(setting => (
        setting.separator ? (
          <Form.Item key={setting.separator} label={setting.separator} colon={false} className={classes.groupLabel} />
        ) : (
          <Form.Item key={setting.key} label={setting.label} {...setting.props}>
            {getFieldDecorator(setting.key, {
              initialValue: '',
              ...setting.fieldOptions,
            })(setting.input)}
          </Form.Item>
        )
      ))}
    </Form>
  );
}
