import { Form, InputNumber } from 'antd';
import React from 'react';
import { useParsedMeta } from 'core/modules/operations/widgets/hooks';

export default function WidgetMetaCountFormItem({ widget, form }) {
  const meta = useParsedMeta(widget);

  return (
    <Form.Item label="Số tin">
      {form.getFieldDecorator('Meta.Count', {
        initialValue: meta.Count,
      })(
        <InputNumber
          placeholder="Số tin"
          min={1}
        />
      )}
    </Form.Item>
  );
}
