import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { sortingToSortBy } from 'core/common/listUtils';
import Container from 'core/components/common/Container';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import ZoneTopicList from './WidgetDistributionsList';
import { Button, Dropdown, Menu } from 'antd';
import { MAX_INTEGER } from 'core/constants';
import { ObjectTypeLabel } from 'core/engine/common/constants';
import widgetDistributionApi from 'core/engine/widget-distribution/api';
import lodash from 'core/common/lodash';
import useIdGenerate from 'core/hooks/useIdGenerate';
import { asyncAction } from 'core/common/async';
import widgetApi from 'core/engine/widget/api';
import WidgetLayoutSelector from './common/WidgetLayoutSelector';
import WidgetPositionSelector from './common/WidgetPositionSelector';
import { layoutRegistry, widgetRegistry } from '../registry';
import FlexPushRight from 'core/components/common/FlexPushRight';
import WidgetObjectSelector from './common/WidgetObjectSelector';
import PreDefinedWidgetQuickInsertDialog from './widgets/PreDefinedWidgetQuickInsertDialog';
import WidgetFormDialogButton from './WidgetFormDialogButton';
import AsyncButton from 'core/components/common/AsyncButton';
import ConfirmButton from 'core/components/common/ConfirmButton';
import makeStyles from 'core/common/makeStyles';
import commonStyles from 'core/common/commonStyles';

const { Divider, Item } = Menu;

function listFn({ offset, filters, sorting }) {
  return widgetDistributionApi.getList({
    limit: MAX_INTEGER,
    offset,
    sortBy: sortingToSortBy(sorting),
    lookup: ['Widget.WidgetID', 'Widget.Title', 'Widget.Type', 'Widget.PreDefined', 'Widget.Meta'],
    filters,
  });
}

const useStyles = makeStyles(() => ({
  blurMask: {
    ...commonStyles.flexAllCentered,
    flexDirection: 'column',
    backgroundColor: 'rgb(0, 0, 0, 0.1)',
    zIndex: 2,
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  container: {
    position: 'relative',
  },
  helper: {
    textAlign: 'center',
    padding: 20,
    fontSize: 18,
    backgroundColor: 'rgba(255,255,255)',
    borderRadius: 4,
    '& > *': {
      fontSize: 18,
    },
  },
  link: {
    padding: 0,
  },
}));

export default function WidgetDistributionsView() {
  const classes = useStyles();
  const [isModified, setIsModified] = useState(false);
  const idGenerate = useIdGenerate(-1, true);
  const [upsertWidgets, setUpsertWidgets] = useState([]);
  const [distributions, setDistributions] = useState([]);

  const [
    layoutMap,
    defaultLayoutId,
    defaultPositionId,
    defaultObjectType,
    defaultObjectId,
  ] = useMemo(() => {
    const layouts = layoutRegistry.getAll();
    const [layout] = layouts;
    const [position] = layout.positions;
    return [lodash.keyBy(layouts, 'id'), layout.id, position.id, 0, 0];
  }, []);

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultFilters: {
      Layout: defaultLayoutId,
      Position: defaultPositionId,
      ObjectType: defaultObjectType,
      ObjectID: defaultObjectId,
    },
    defaultSorting: {
      Order: 'ASC',
    },
  });

  const layout = useMemo(() => {
    return layoutMap[listProps.filters.Layout] || {};
  }, [layoutMap, listProps.filters.Layout]);

  const availableWidgets = useMemo(() => {
    return widgetRegistry.getAll().filter(widget => {
      for (const record of widget.displayIn) {
        if (record.layout === listProps.filters.Layout
          && record.position === listProps.filters.Position) {
          return true;
        }
      }

      return false;
    });
  }, [listProps.filters]);

  const quickInsertWidgets = useMemo(() => {
    return availableWidgets.filter(widget => widget.QuickInsertDialog);
  }, [availableWidgets]);

  const availableObjectTypeLabel = useMemo(() => getAvailableObjectTypeLabel(layout.objectTypes), [layout]);
  const availablePositions = useMemo(() => layout.positions || [], [layout]);

  const insertDialogRef = useRef([]);
  const [displayDefaultMask, setDisplayDefaultMask] = useState(false);

  useEffect(() => {
    if (!isModified) {
      setUpsertWidgets([]);
    }

    if (listProps.isFetching) {
      return;
    }

    if (listProps.filters.ObjectID > 0 && listProps.items.length === 0) {
      if (isModified) {
        setDisplayDefaultMask(false);
      } else {
        setDisplayDefaultMask(true);
      }

      return;
    }

    if (!isModified) {
      setDisplayDefaultMask(false);
      setDistributions(listProps.items.slice());
    }
  }, [listProps.isFetching, listProps.filters, listProps.items, isModified]);

  useEffect(() => {
    if (displayDefaultMask) {
      widgetDistributionApi.getList({
        limit: MAX_INTEGER,
        sortBy: 'Order:ASC',
        lookup: ['Widget.WidgetID', 'Widget.Title', 'Widget.Type', 'Widget.PreDefined', 'Widget.Meta'],
        filters: { ...listProps.filters, ObjectID: defaultObjectId },
      }).then((resp) => {
        setDistributions(resp.data.items);
      });
    }
  }, [displayDefaultMask]);

  const quickInsertMenus = (
    <Menu onClick={item => insertDialogRef.current[item.key].open()}>
      <Item key="new">Tạo ...</Item>
      <Item key="predefined">Chọn ...</Item>
      <Divider />
      {quickInsertWidgets.map(widget => <Item key={widget.type}>{widget.name}</Item>)}
    </Menu>
  );

  return (
    <Container pageTitle="Giao diện">
      <TableToolbarWrapper>
        <WidgetLayoutSelector
          value={listProps.filters.Layout}
          onChange={handleLayoutChange}
          width={200}
        />
        <WidgetPositionSelector
          value={listProps.filters.Position}
          availablePositions={availablePositions}
          onChange={(value) => onFilterChange('Position', value)}
          width={150}
        />
        <WidgetObjectSelector
          value={{ objectType: listProps.filters.ObjectType, objectId: listProps.filters.ObjectID }}
          onChange={handleObjectChange}
          objectTypes={availableObjectTypeLabel}
        />
        <FlexPushRight />
        {!displayDefaultMask && (
          <Fragment>
            {isModified && (
              <Button
                onClick={() => {
                  listProps.refresh();
                  setIsModified(false);
                }}
                title="Hủy"
              >Hủy</Button>
            )}
            <Button
              type="primary"
              icon="save"
              onClick={handleSave}
              title="Lưu"
              disabled={!isModified}
            >Lưu</Button>
            <Dropdown overlay={quickInsertMenus} trigger={['click']}>
              <Button title="Thêm widget" icon="plus">Thêm widget</Button>
            </Dropdown>
          </Fragment>
        )}
        <WidgetFormDialogButton
          onInsert={handleInsert}
          widget={{ PreDefined: false }}
          availableWidgets={availableWidgets}
          hasButton={false}
          wrappedComponentRef={el => insertDialogRef.current.new = el}
        />
        <PreDefinedWidgetQuickInsertDialog
          ref={el => insertDialogRef.current.predefined = el}
          availableWidgets={availableWidgets}
          onInsert={handleInsert}
        />
        {quickInsertWidgets.map(widget => {
          return (
            <widget.QuickInsertDialog
              key={widget.type}
              ref={el => insertDialogRef.current[widget.type] = el}
              onInsert={handleInsert}
              widget={widget}
            />
          );
        })}
        {!displayDefaultMask && listProps.filters.ObjectID > 0 && listProps.items.length > 0 && (
          <ConfirmButton
            anchorElement={(props) => (
              <AsyncButton
                title="Đặt lại giao diện tùy chỉnh này về giao diện mặc định"
                icon="rollback" {...props}>
                Đặt lại
              </AsyncButton>
            )}
            dialogTitleText="Đăt lại giao diện tùy chỉnh về giao diện mặc định?"
            dialogBodyText="Giao diện sau khi đặt lại sẽ sử dụng giao diện mặc định."
            handleSubmit={handleClean}
          />
        )}
      </TableToolbarWrapper>
      <div className={classes.container}>
        {displayDefaultMask && (
          <div className={classes.blurMask}>
            <div className={classes.helper}>
              Chưa có tùy chỉnh cho giao diện này, giao diện mặc định sẽ được sử dụng.
              <br />
              Xem&nbsp;
              <Button
                type="link"
                onClick={() => onFilterChange('ObjectID', defaultObjectId)}
                className={classes.link}
              >
                giao diện mặc định
              </Button>
              &nbsp;hoặc&nbsp;
              <ConfirmButton
                anchorElement={(props) => (
                  <AsyncButton
                    title="Tùy chỉnh"
                    type="link"
                    className={classes.link}
                    {...props}
                  >
                    tùy chỉnh giao diện
                  </AsyncButton>
                )}
                dialogTitleText="Tùy chỉnh giao diện này?"
                dialogBodyText="Giao diện tùy chỉnh sẽ được sử dụng thay cho giao diện mặc định."
                handleSubmit={handleCustomize}
              />
              &nbsp;này
            </div>
          </div>
        )}
        <ZoneTopicList
          {...useListProps(listProps)}
          list={distributions}
          onMove={handleMove}
          onDelete={handleDelete}
          onUpdate={handleUpdate}
        />
      </div>
    </Container>
  );

  function handleUpdate(widget, distributionId) {
    if (!widget.PreDefined) {
      setIsModified(true);
      const upsertId = lodash.findIndex(upsertWidgets, { WidgetID: widget.WidgetID });
      const index = lodash.findIndex(distributions, { DistributionID: distributionId });
      const newUpserts = upsertWidgets.slice();

      if (index > -1) {
        if (upsertId > -1) {
          newUpserts.splice(upsertId, 1, widget);
        } else {
          newUpserts.push(widget);
        }

        const newDistributions = distributions.slice();
        const newDistribution = {
          DistributionID: idGenerate(),
          Layout: listProps.filters.Layout,
          Position: listProps.filters.Position,
          ObjectType: listProps.filters.ObjectType,
          ObjectID: listProps.filters.ObjectID,
          Order: 0,
          WidgetID: widget.WidgetID,
          Widget: widget,
        };

        newDistributions.splice(index, 1, newDistribution);
        setDistributions(newDistributions);

        setUpsertWidgets(newUpserts);
      }
    }
  }

  function handleDelete(widgetDistribution) {
    setIsModified(true);
    const newList = distributions.slice();
    lodash.remove(newList, { DistributionID: widgetDistribution.DistributionID });
    setDistributions(newList);

    if (widgetDistribution.WidgetID < 0) {
      const newUpsertWidgets = upsertWidgets.slice();
      const deletes = lodash.remove(newUpsertWidgets, { WidgetID: widgetDistribution.WidgetID });
      deletes.length && setUpsertWidgets(newUpsertWidgets);
    }
  }

  function onFilterChange(fieldName, value) {
    setIsModified(false);
    listProps.addFilter(fieldName, value);
  }

  function handleInsert(widgets) {
    const newDistributions = widgets.map((widget) => {
      if (!widget.WidgetID) {
        widget.WidgetID = idGenerate();
      }

      return {
        DistributionID: idGenerate(),
        Layout: listProps.filters.Layout,
        Position: listProps.filters.Position,
        ObjectType: listProps.filters.ObjectType,
        ObjectID: listProps.filters.ObjectID,
        Order: 0,
        WidgetID: widget.WidgetID,
        Widget: widget,
      };
    });

    setIsModified(true);
    setDistributions([...distributions, ...newDistributions]);
    setUpsertWidgets([...upsertWidgets, ...widgets.filter(widget => widget.WidgetID < 0)]);
  }

  function handleSave() {
    const data = {
      Layout: listProps.filters.Layout,
      Position: listProps.filters.Position,
      ObjectType: listProps.filters.ObjectType,
      ObjectID: listProps.filters.ObjectID,
      distributions: JSON.stringify(distributions.map((distribution) => {
        delete distribution.Widget;
        delete distribution.DistributionID;
        delete distribution.Order;
        return distribution;
      })),
      upsertWidgets: JSON.stringify(upsertWidgets),
    };

    return asyncAction('Cập nhật giao diện', () => {
      return widgetApi.setDistributions(data).then(() => {
        listProps.refresh();
        setIsModified(false);
      });
    });
  }

  function handleMove(newData) {
    let modify = isModified;
    newData.forEach((item, i) => {
      if (item.Order !== i + 1) {
        modify = true;
        item.Order = i + 1;
      }
    });
    setIsModified(modify);

    setDistributions(newData);
  }

  function getAvailableObjectTypeLabel(objectTypes) {
    return objectTypes ? Object.assign({},
      ...objectTypes.map(type => ({ [type]: ObjectTypeLabel[type] }))) : null;
  }

  function handleLayoutChange(value) {
    const { objectTypes, positions } = layoutMap[value];
    const curObjTypeLabel = getAvailableObjectTypeLabel(objectTypes);
    onFilterChange('Layout', value);
    onFilterChange('Position', positions[0].id);

    if (!curObjTypeLabel) {
      listProps.filters.ObjectType !== defaultObjectType && onFilterChange('ObjectType', defaultObjectType);
      listProps.filters.ObjectID !== defaultObjectId && onFilterChange('ObjectID', defaultObjectId);
    } else if (!curObjTypeLabel[listProps.filters.ObjectType]) {
      onFilterChange('ObjectType', objectTypes[0]);
    }
  }

  function handleObjectChange({ objectType, objectId }) {
    onFilterChange('ObjectType', objectType);
    onFilterChange('ObjectID', objectId);
  }

  function handleClean() {
    return asyncAction('Cài lại giao diện về mặc định', () => {
      return widgetApi.setDistributions({
        Layout: listProps.filters.Layout,
        Position: listProps.filters.Position,
        ObjectType: listProps.filters.ObjectType,
        ObjectID: listProps.filters.ObjectID,
        distributions: [],
      }).then(listProps.refresh);
    });
  }

  function handleCustomize() {
    setIsModified(true);
    distributions.forEach((distribution) => {
      distribution.ObjectID = listProps.filters.ObjectID;
    });
  }
}
