import { Button } from 'antd';
import useAuth from 'core/hooks/useAuth';
import useServerFeature from 'core/hooks/useServerFeature';
import React, { useMemo, useState } from 'react';
import LoadingWrapper from '../common/LoadingWrapper';

const PUSH_ZOA_COMMAND = 'content.push-zoa';

export function ContentPushZOAButton({ contentId, onClick, disabled = false }) {
  const { loggedInUser, hasExtendedRight } = useAuth();
  const hasOAMedia = useServerFeature(PUSH_ZOA_COMMAND, false);
  const [isLoading, setIsLoading] = useState(false);

  const canPushZOA = useMemo(() => {
    return hasOAMedia && (loggedInUser.isAdmin || hasExtendedRight(PUSH_ZOA_COMMAND));
  }, [hasOAMedia, loggedInUser, hasExtendedRight]);

  return canPushZOA && (
    <LoadingWrapper loading={isLoading}>
      <Button
        icon="global"
        title={disabled ? 'Tin đã được gửi Zalo' : 'Gửi tin Zalo'}
        onClick={handleClick}
        disabled={disabled}
      />
    </LoadingWrapper>
  );

  function handleClick() {
    setIsLoading(true);

    Promise.resolve()
      .then(() => onClick(contentId))
      .then(() => setIsLoading(false));
  }
}
