import { Button } from 'antd';
import { asyncAction } from 'core/common/async';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import AsyncButton from 'core/components/common/AsyncButton';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import Selector from 'core/components/common/Selector';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import TopicSelector from 'core/components/topic/TopicSelector';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import adsOptionApi from 'core/engine/ads-option/api';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import AdsOptionFormDialogButton from './AdsOptionFormDialogButton';
import AdsOptionList from './AdsOptionList';

const zoneFilterExtendOptions = [{ value: 0, label: 'Trang chủ' }];
const expirationOptions = {
  'expired': 'Hết hạn',
  'active': 'Còn hạn',
  '1 week': 'Còn 1 tuần',
  '2 weeks': 'Còn 2 tuần',
  '3 weeks': 'Còn 3 tuần',
  '4 weeks': 'Còn 4 tuần',
};

function listFn({ limit, offset, filters, sorting }) {
  return adsOptionApi.getList({
    limit,
    offset,
    lookup: [
      'AdsOptionStat.OptionID',
      'AdsOptionStat.ClickCount',
      'AdsTopic.OptionID',
      'AdsTopic.TopicID',
    ],
    sortBy: sortingToSortBy(sorting),
    filters: buildFilter(filters),
  });
}

function buildFilter(filters) {
  const listFilters = { ...filters };

  if (listFilters.expiration) {
    if (listFilters.expiration === 'active') {
      listFilters.EndDate = `gt:${moment().format('x')}`;
    } else if (listFilters.expiration === 'expired') {
      listFilters.EndDate = `lt:${moment().format('x')}`;
    } else {
      const [num, unit] = listFilters.expiration.split(' ');
      listFilters.EndDate = dateRangeToFilter([moment(), moment().add(num, unit)]);
    }
  }

  delete listFilters.expiration;

  return listFilters;
}

export default function AdsListView() {
  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: {
      OptionID: 'DESC',
    },
    defaultFilters: {
      Enabled: true,
      expiration: 'active',
    },
  });

  return (
    <Container pageTitle="Quảng cáo">
      <TableToolbarWrapper>
        <AdsOptionFormDialogButton isEditMode={false} onDone={listProps.refresh} />
        <Link to="/operations/adspositions" target="_blank">
          <Button icon="unordered-list" title="Danh sách vị trí">Danh sách vị trí</Button>
        </Link>
        <ZoneSelector
          value={listProps.filters.ZoneID}
          onChange={useCreateOnFilterChange(listProps, 'ZoneID')}
          emptyLabel="-- Chuyên mục --"
          extendItems={zoneFilterExtendOptions}
          width={200}
          allowClear
        />
        <TopicSelector
          value={listProps.filters.TopicID}
          onChange={useCreateOnFilterChange(listProps, 'TopicID')}
          emptyLabel="-- Chủ đề --"
          width={300}
          allowClear
        />
        <Selector
          value={listProps.filters.expiration}
          onChange={useCreateOnFilterChange(listProps, 'expiration')}
          options={expirationOptions}
          emptyLabel="-- Tất cả --"
          width={120}
          allowClear
        />
        <Selector
          value={listProps.filters.Enabled}
          onChange={useCreateOnFilterChange(listProps, 'Enabled')}
          options={[{ value: true, label: 'Hiển thị' }, { value: false, label: 'Không hiển thị' }]}
          width={140}
          emptyLabel="-- Trạng thái --"
          allowClear
        />
        <FlexPushRight />
        <AsyncButton
          icon="export"
          type="primary"
          onClick={handleExport}
          title="Xuất Excel"
        >
          Xuất Excel
        </AsyncButton>
      </TableToolbarWrapper>
      <AdsOptionList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdate={listProps.refresh}
        onDelete={handleDelete}
      />
    </Container>
  );

  function handleDelete(vote) {
    return asyncAction('Xóa quảng cáo', () => {
      return adsOptionApi.delete(vote.OptionID).then(() => {
        listProps.removeItem(vote, 'OptionID');
      });
    });
  }

  function handleExport() {
    return asyncAction('Xuất danh sách quảng cáo', () => {
      return adsOptionApi.export({
        sortBy: sortingToSortBy(listProps.sorting),
        filters: buildFilter(listProps.filters),
        filename: 'Danh sách quảng cáo.xlsx',
        lookup: [
          'AdsOptionStat.OptionID',
          'AdsOptionStat.ClickCount',
        ],
      });
    });
  }
}
