import React, { memo } from 'react';
import makeStyles from 'core/common/makeStyles';
import AccountDisplay from 'core/components/account/AccountDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    textAlign: 'center',
  },
  name: {
    fontWeight: 700,
    wordBreak: 'break-word',
  },
  date: {
    fontSize: 'small',
    fontStyle: 'italic',
  },
}));

function CommentApproverDisplay({
  approverId,
  approvalDate,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.name}>
        <AccountDisplay id={approverId} />
      </div>
      <span className={classes.date}>
        <DateTimeDisplay timestamp={approvalDate} />
      </span>

    </div>
  );
}

export default memo(CommentApproverDisplay);
