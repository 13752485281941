import { ContentTypeOptions } from 'core/engine/content/constants';
import React, { forwardRef } from 'react';
import Selector from '../common/Selector';

export default forwardRef(function ContentTypeSelector({ value, onChange, ...props }, ref) {

  return (
    <Selector
      ref={ref}
      options={ContentTypeOptions}
      value={value}
      onChange={onChange}
      valueIsNumber={true}
      {...props}
    />
  );
});
