import { Config } from '@zdocs/core';
import domUtils from 'core/common/domUtils';
import lodash from 'core/common/lodash';
import { defaultButtons } from 'core/components/editor/plugins/InlinePopupPlugin';

const popUpExt = {
  live: defaultButtons,
  interview: defaultButtons,
};

popUpExt.img = [...Config.prototype.popup.img];
const leftButton = lodash.find(popUpExt.img, { name: 'left' });
const superLeftExec = leftButton.exec;
leftButton.list.push('Tràn ngang');

leftButton.exec = (zdocs, image, control) => {
  superLeftExec(zdocs, image, control);

  if (control.args) {
    const table = getSelectionImageTable(zdocs);

    if (table) {
      if (control.args[0] === 4) {
        table.classList.add('full-width');
        table.removeAttribute('align');
      } else {
        table.classList.remove('full-width');
      }
    }
  }

  zdocs.events.fire('hidePopup');
};

function getSelectionImageTable(zdocs) {
  const current = zdocs.selection.current();

  if (!current) {
    return null;
  }

  let element = current.parentNode;

  if (domUtils.hasClass(element, 'before-after-image-box')) {
    element = element.parentNode;
  }

  if (domUtils.hasClass(element, 'pic') && element.tagName.toLowerCase() === 'td') {
    element = element.parentNode;

    if (element && element.tagName.toLowerCase() === 'tr') {
      element = element.parentNode;

      if (element && element.tagName.toLowerCase() === 'tbody') {
        element = element.parentNode;

        if (domUtils.hasClass(element, 'picture') && element.tagName.toLowerCase() === 'table') {
          return element;
        }
      }
    }
  }

  return null;
}

Object.assign(Config.prototype.popup, popUpExt);
