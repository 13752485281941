import lodash from 'core/common/lodash';
import topicApi from './api';

export const getListTopicByTypeCache = lodash.memoize((topicType) => {
  return topicApi.getList({ limit: 1000, filters: { Type: topicType } })
    .then(resp => resp.data.items);
});

export const getListTopicCache = lodash.memoize(() =>
  topicApi.getListAll().then(resp => resp.data.items)
);

export const getMapTopicCache = lodash.memoize(() =>
  getListTopicCache().then(items => lodash.keyBy(items, 'TopicID'))
);

export const getTopicNameCache = lodash.memoize((topicId) =>
  getMapTopicCache().then(topicMap => lodash.get(topicMap, `${topicId}.Name`, '#'))
);
