import { getAuthorCache } from 'core/engine/author/caching';
import React, { forwardRef, useEffect, useState } from 'react';
import AuthorSelector from './AuthorSelector';

const EMPTY_AUTHOR = { AuthorID: 0, FullName: '-- Chọn tác giả --' };

export default forwardRef(function AuthorIdSelector({ value, onChange, ...props }, ref) {
  const [author, setAuthor] = useState(EMPTY_AUTHOR);

  useEffect(() => {
    if (!value || value === EMPTY_AUTHOR.AuthorID) {
      setAuthor(EMPTY_AUTHOR);
    } else {
      getAuthorCache(value).then(author => {
        setAuthor(author);
      });
    }
  }, [value]);

  return (
    <AuthorSelector
      ref={ref}
      value={author}
      onChange={(author) => onChange(author.AuthorID)}
      {...props}
    />
  );
});
