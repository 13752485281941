import { Checkbox } from 'antd';
import React from 'react';
import { commonColumns } from 'core/common/listUtils';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DeleteButton from 'core/components/common/DeleteButton';
import CheckableTable from 'core/components/table/CheckableTable';
import ContentSourceFormDialogButton from './ContentSourceFormDialogButton';

export default function ContentSourceList({
  onUpdate,
  onDelete,
  selectedIds,
  onSelectedChange,
  ...props
}) {
  const columns = [
    commonColumns.order,
    {
      ...commonColumns.allCentered,
      Header: 'ID',
      accessor: 'SourceID',
      maxWidth: 80,
      minWidth: 60,
    },
    {
      Header: 'Tên',
      accessor: 'Name',
      minWidth: 200,
    },
    {
      Header: 'URL',
      accessor: 'Url',
      minWidth: 200,
    },
    {
      Header: 'Sử dụng',
      accessor: 'Enabled',
      Cell: ({ original }) => <Checkbox checked={original.Enabled} />,
      maxWidth: 80,
      minWidth: 60,
      ...commonColumns.allCentered,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <ContentSourceFormDialogButton contentSource={original} onDone={onUpdate} />
          <DeleteButton title={`Xóa nguồn tin: ${original.Name}?`} onDelete={() => onDelete(original)} />
        </ActionsCellWrapper>
      ),
      maxWidth: 110,
    },
  ];

  return (
    <CheckableTable
      idField="SourceID"
      columns={columns}
      selectedIds={selectedIds}
      onSelectedChange={onSelectedChange}
      {...props}
    />
  );
}
