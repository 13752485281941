import Container from 'core/components/common/Container';
import { TopicType } from 'core/engine/topic/constants';
import useParamId from 'core/hooks/useParamId';
import React from 'react';
import TopicDetailView from '../TopicDetailView';
import TopicListDisplay from '../TopicListDisplay';

export default function SystemTopicListView() {
  const topicId = useParamId();

  if (topicId) {
    return <TopicDetailView topicId={topicId} />;
  }

  return (
    <Container pageTitle="Chủ đề hệ thống">
      <TopicListDisplay type={TopicType.SYSTEM} />
    </Container>
  );
}
