import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { asyncAction } from 'core/common/async';
import AsyncButton from 'core/components/common/AsyncButton';
import Container from 'core/components/common/Container';
import RangeDateSelector from 'core/components/common/RangeDateSelector';
import { commonColumns } from 'core/common/listUtils';
import Selector from 'core/components/common/Selector';
import ZoneDisplay from 'core/components/zone/ZoneDisplay';
import reportApi from 'core/engine/report/api';
import { getListEnabledZoneCache } from 'core/engine/zone/caching';
import useList from 'core/hooks/useList';
import BaseTable from 'core/components/table/BaseTable';
import useListProps from 'core/hooks/useListProps';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import FeatureFlip from 'core/components/common/FeatureFlip';
import FlexPushRight from 'core/components/common/FlexPushRight';
import { Sites } from 'core/engine/site/constants';

const defaultColumns = [
  commonColumns.order,
  {
    style: { fontWeight: 'bold' },
    Header: 'Ngày',
    accessor: 'Date',
    sortable: false,
    width: 90,
  },
  {
    ...commonColumns.formatNumber,
    style: { fontWeight: 'bold' },
    Header: 'Tổng mục',
    accessor: 'Total',
    width: 90,
  },
];

const defaultKeys = [
  'mobile_article',
  'web_article',
];

const listTargets = [{
  label: '-- Xem chi tiết theo key --',
  value: defaultKeys.join(),
}, {
  label: 'mobile',
  value: 'mobile_article',
}, {
  label: 'web',
  value: 'web_article',
}];

function listFn({ filters }) {
  return reportApi.getGroupByZone({
    keys: filters.keys,
    startDate: filters.rangeDate[0].format('YYYYMMDD'),
    endDate: filters.rangeDate[1].format('YYYYMMDD'),
    siteId: filters.siteId,
  });
}

export default function GroupByZoneView({ siteId = Sites.ALL, rootZoneId = 0, ignoreZoneIds = [] }) {
  const [columns, setColumns] = useState(defaultColumns);

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultFilters: {
      keys: defaultKeys.join(),
      rangeDate: [moment(), moment()],
      siteId: siteId,
    },
  });

  useEffect(() => {
    if (!listProps.items.length) {
      return;
    }

    (getListEnabledZoneCache())
      .then((items) => {
        items = items.filter(item => item.ParentID === rootZoneId && !ignoreZoneIds.includes(item.ZoneID));
        const mapTotal = listProps.items[0];

        items.sort((z1, z2) => mapTotal[z2.ZoneID] - mapTotal[z1.ZoneID]);

        const extraColumns = items.map((zone) => {
          return {
            ...commonColumns.formatNumber,
            Header: () => <ZoneDisplay id={zone.ZoneID} />,
            accessor: String(zone.ZoneID),
          };
        });

        setColumns([...defaultColumns, ...extraColumns]);
      });
  }, [siteId, listProps.items]);

  useEffect(listProps.refresh, []);

  return (
    <Container pageTitle="Thống kê chuyên mục">
      <TableToolbarWrapper>
        <Selector
          value={listProps.filters.keys}
          onChange={useCreateOnFilterChange(listProps, 'keys')}
          options={listTargets}
        />
        <RangeDateSelector
          value={listProps.filters.rangeDate}
          onChange={useCreateOnFilterChange(listProps, 'rangeDate')}
        />
        <FlexPushRight />
        <FeatureFlip name="export-report">
          <AsyncButton
            icon="export"
            type="primary"
            onClick={handleExport}
            title="Xuất Excel"
          >
            Xuất Excel
          </AsyncButton>
        </FeatureFlip>
      </TableToolbarWrapper>
      <BaseTable
        columns={columns}
        {...useListProps(listProps)}
        showPagination={false}
        heightAdjust={42}
      />
    </Container>
  );

  function handleExport() {
    const listFilters = {
      keys: listProps.filters.keys,
      startDate: listProps.filters.rangeDate[0].format('YYYYMMDD'),
      endDate: listProps.filters.rangeDate[1].format('YYYYMMDD'),
      siteId: listProps.filters.siteId,
    };

    return asyncAction('Xuất thống kê theo loại tin bài', () => {
      return reportApi.exportGroupByZone({
        filters: listFilters,
        filename: `Thống kê chuyên mục từ ${listProps.filters.rangeDate[0].format('DD-MM-YYYY')} đến ${listProps.filters.rangeDate[1].format('DD-MM-YYYY')}.xlsx`,
      });
    });
  }
}
