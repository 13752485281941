import React from 'react';
import { isValidElementType } from 'react-is';
import Selector from 'core/components/common/Selector';

export function connectSelectorFilterForList(selectorComponent, selectorProps = {}) {
  let SelectorComponent;

  if (isValidElementType(selectorComponent)) {
    SelectorComponent = selectorComponent;
  } else {
    SelectorComponent = Selector;
    selectorProps = selectorComponent;
  }

  return ({ filter, onChange }) => (
    <SelectorComponent
      value={filter ? filter.value : null}
      selectProps={{ allowClear: true }}
      onChange={onChange}
      {...selectorProps}
    />
  );
}
