import { emptyFunction } from 'core/common/empty';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import ImageDisplay from 'core/components/common/ImageDisplay';
import { Button, Input } from 'antd';
import libreCaptchaApi from 'core/engine/libre-captcha';
import makeStyles from 'core/common/makeStyles';
import { LIBRE_CAPTCHA_URL } from 'core/config';
import { asyncAction } from 'core/common/async';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    '& input,img': {
      width: 'calc(50% - 17px)',
    },
    '& img': {
      height: 40,
      marginLeft: 5,
      border: '1px solid #d9d9d9',
      borderRadius: 4,
    },
  },
  reloadButton: {
    marginLeft: 5,
  },
}));

export default forwardRef(function LibreCaptchaInput({
  value,
  onChange = emptyFunction,
}, ref) {
  const classes = useStyles();
  const { id, answer } = useMemo(() => {
    return value ? JSON.parse(value) : {};
  }, [value]);

  const reloadCaptcha = useCallback(() => {
    return asyncAction('Tạo captcha', () => {
      return libreCaptchaApi.getCaptcha().then(({ id }) => {
        onChange(JSON.stringify({ id }));
      });
    }, { silentSuccess: true, silentProgress: true });
  }, []);

  useImperativeHandle(ref, () => ({
    reset: reloadCaptcha,
  }));

  useEffect(() => {
    reloadCaptcha();
  }, []);

  return (
    <div className={classes.container}>
      <Input
        value={answer}
        placeholder="Nhập captcha"
        onChange={handleAnswerChange}
      />
      {id && (
        <ImageDisplay
          alt="captcha image"
          src={LIBRE_CAPTCHA_URL + '/v2/media?id=' + id}
        />
      )}
      <Button
        size="small"
        className={classes.reloadButton}
        icon="reload"
        onClick={reloadCaptcha}
        title="Tạo Captcha khác"
      />
    </div>
  );

  function handleAnswerChange(event) {
    onChange(JSON.stringify({ id, answer: event.target.value }));
  }
});
