import { Form, Input } from 'antd';
import makeStyles from 'core/common/makeStyles';
import { countChar, countWord } from 'core/common/string';
import useServerConfig from 'core/hooks/useServerConfig';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React, { useMemo } from 'react';
import ContentFormLengthAwareInput, { AwareInputTypeLabels, AwareInputTypes } from '../input/ContentFormLengthAwareInput';

const useStyles = makeStyles(() => ({
  hasLength: {
    '& .ant-form-explain': {
      clear: 'initial',
    },
  },
  titleInput: {
    fontSize: 'large',
    fontWeight: 700,
  },
}));

export default function ContentFormItemTitle({ content, form, maxLength, maxLengthType }) {
  const classes = useStyles();
  const { getFieldDecorator } = form;
  const config = useServerConfig();

  const awareInputType = useMemo(() => maxLengthType || config.LimitedInputType || AwareInputTypes[0], [config]);

  const countFn = useMemo(() => {
    return awareInputType === AwareInputTypes.CHAR ? countChar : countWord;
  }, [awareInputType, countChar, countWord]);

  return (
    <Form.Item className={classes.hasLength} title={ContentFieldLabel.Title}>
      {getFieldDecorator('Title', {
        initialValue: content.Title,
        rules: [
          { required: true, message: 'Bắt buộc!' },
          { validator },
        ],
      })(
        <ContentFormLengthAwareInput
          autoFocus
          placeholder={ContentFieldLabel.Title}
          Component={Input.TextArea}
          maxLength={maxLength}
          maxLengthType={maxLengthType}
          className={classes.titleInput}
          autoSize
        />
      )}
    </Form.Item>
  );

  function validator(rule, value, callback) {
    if (!parseInt(maxLength)) {
      return callback();
    }

    if (countFn(value) > maxLength) {
      return callback(new Error(`Tối đa ${maxLength} ${AwareInputTypeLabels[awareInputType].toLowerCase()}`));
    }

    callback();
  }
}
