import { Button, Form, Icon, Input, Typography } from 'antd';
import { asyncAction } from 'core/common/async';
import commonStyles from 'core/common/commonStyles';
import authApi from 'core/engine/auth/api';
import useAuth from 'core/hooks/useAuth';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import BaseDialog from './BaseDialog';
import CaptchaInput from './CaptchaInput';
import LibreCaptchaInput from './LibreCaptchaInput';
import FeatureFlip from './FeatureFlip';

const RefreshSessionDialog = forwardRef(({ form }, ref) => {
  const { getFieldDecorator } = form;
  const { open, handleClose, handleOpen } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  const { loggedInUser } = useAuth();
  const [sessionExpiry, setSessionExpiry] = useState(0);
  const [countdown, setCountdown] = useState(0);

  const captchaRef = useRef();

  useImperativeHandle(ref, () => ({
    resetCaptcha() {
      captchaRef.current && captchaRef.current.reset();
    },
  }));

  const loadData = useCallback(() => {
    authApi.getSessionExpiration().then(resp => {
      setSessionExpiry(resp.data.sessionExpiration);
    });
  }, []);

  useEffect(() => {
    if (!loggedInUser) {
      return;
    }

    const intervalId = setInterval(loadData, 300000);
    loadData();

    return () => clearInterval(intervalId);
  }, [loggedInUser]);

  useEffect(() => {
    if (sessionExpiry > 0) {
      handleOpen();
    } else if (open) {
      handleClose();
    }
  }, [sessionExpiry]);

  useEffect(() => {
    if (open) {
      setCountdown(sessionExpiry);

      const countdownInterval = setInterval(() => setCountdown(seconds => {
        if (--seconds < 0) {
          handleClose();
        }

        return seconds;
      }), 1000);

      const checkInterval = setInterval(loadData, 5000);

      return () => {
        clearInterval(countdownInterval);
        clearInterval(checkInterval);
      };
    }
  }, [open]);

  if (!loggedInUser) {
    return null;
  }

  return (
    <BaseDialog
      visible={open}
      title="Gia hạn phiên làm việc"
      onCancel={handleClose}
      onOk={handleSubmit}
      okText="Gia hạn"
      width={600}
      confirmLoading={isSubmitting}
    >
      <Typography.Text type="danger">
        <Icon type="warning" /> Phiên làm việc của bạn sẽ hết hạn
        trong <strong>{`${Math.floor(countdown / 60)} phút : ${countdown % 60} giây`}</strong> nữa. <br />
        Hãy nhập mật khẩu để gia hạn phiên làm việc, tránh bị mất dữ liệu đang soạn thảo.
      </Typography.Text>
      <br />
      <Form {...commonStyles.formItemLayout} onSubmit={handleSubmit}>
        <Form.Item label="Mật khẩu">
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Bạn chưa nhập mật khẩu.',
              },
              {
                max: 250,
                message: 'Tối đa 250 kí tự!',
              },
            ],
          })(
            <Input.Password autoFocus />
          )}
        </Form.Item>
        <FeatureFlip name="captcha">
          <Form.Item label="Captcha">
            {getFieldDecorator('captcha')(
              <CaptchaInput ref={captchaRef} />
            )}
          </Form.Item>
        </FeatureFlip>
        <FeatureFlip name="libre-captcha">
          <Form.Item label="Captcha">
            {getFieldDecorator('libreCaptcha')(
              <LibreCaptchaInput ref={captchaRef} />
            )}
          </Form.Item>
        </FeatureFlip>
        <FeatureFlip name="otp">
          <Form.Item label="OTP">
            {getFieldDecorator('otpCode')(
              <Input autoComplete="off" />
            )}
          </Form.Item>
        </FeatureFlip>
        <Button style={commonStyles.hidden} htmlType="submit" />
      </Form>
    </BaseDialog>
  );

  function submitFn({ formData }) {
    return asyncAction('Gia hạn phiên làm việc', () => {
      return authApi.refreshSession(formData)
        .then(() => {
          handleClose();
        });
    });
  }
});

export default Form.create({ name: 'RefreshSessionDialog' })(RefreshSessionDialog);

