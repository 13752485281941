import { blue } from '@ant-design/colors';
import { Button, Pagination, message } from 'antd';
import { asyncAction } from 'core/common/async';
import commonStyles from 'core/common/commonStyles';
import { emptyFunction } from 'core/common/empty';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import makeStyles from 'core/common/makeStyles';
import AccountSelector from 'core/components/account/AccountSelector';
import FlexPushRight from 'core/components/common/FlexPushRight';
import albumApi from 'core/engine/album/api';
import fileApi from 'core/engine/file/api';
import { FileType } from 'core/engine/file/constants';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import { getCurrentStore } from 'core/redux/store';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RangeDateSelector from '../common/RangeDateSelector';
import FileChooserButton from './FileChooserButton';
import FileGrid from './FileGrid';
import FileManagerDragAndDrop from './FileManagerDragAndDrop';
import FileManagerSearchField from './FileManagerSearchField';
import FileManagerSidebar from './FileManagerSidebar';
import FileManagerTree from './FileManagerTree';
import FileManagerUploadButton from './FileManagerUploadButton';
import FileManagerAddWatermarkDialogButton from './FileManagerAddWatermarkDialogButton';
import FeatureFlip from '../common/FeatureFlip';

function listFn({ limit, offset, sorting, filters }) {
  let listFilters = { ...filters };

  const rangeDate = filters.customRangeDate || filters.rangeDate;

  if (rangeDate && rangeDate[0] && rangeDate[1]) {
    listFilters.CreatedDate = dateRangeToFilter(rangeDate);
  }

  delete listFilters.rangeDate;
  delete listFilters.customRangeDate;

  if (listFilters.customOwnerId) {
    listFilters.OwnerID = listFilters.customOwnerId;
  }

  delete listFilters.customOwnerId;

  if (filters.search.value) {
    if (['FileID', 'OriginID'].includes(filters.search.type)) {
      listFilters = {}; // just ignore any other filters
    }

    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return fileApi.getList({
    offset,
    limit,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    height: 32,

    '& > .ant-select': {
      maxWidth: 200,
    },

    '& > *:not(:last-child)': {
      marginRight: 10,
    },
  },
  inner: {
    ...commonStyles.flexRow,
    height: 'calc(100% - 42px)',
  },
  content: {
    ...commonStyles.flexColumn,
    width: 'calc(100% - 520px)',
    minWidth: 292, // fit for two columns
    border: '1px solid #d4d4d4',
    borderRadius: 4,
  },
  grid: {
    width: '100%',
    height: 'calc(100% - 42px)',
    overflow: 'auto',
    padding: 10,
    marginBottom: 10,
  },
  pagination: {
    alignSelf: 'flex-end',
    paddingBottom: 10,
    marginRight: 10,
  },
  albumName: {
    ...commonStyles.ellipsis,
    height: 32,
    maxWidth: 150,
    minWidth: 150,
    padding: '0px 10px',
    lineHeight: '32px',
    border: '1px #d9d9d9 dashed',
    borderRadius: 4,
    fontWeight: 700,
    position: 'relative',
    paddingRight: 20,
  },
  clearIcon: {
    cursor: 'pointer',
    lineHeight: '34px',
    position: 'absolute',
    right: 7,
    color: blue[4],
  },
}));

const defaultRangeDate = [null, null];

const addButtonLabels = {
  [FileType.FILE]: 'Thêm tệp',
  [FileType.IMAGE]: 'Thêm ảnh',
  [FileType.VIDEO]: 'Thêm video',
};

export default function FileManager({
  multiple = false,
  grid = true,
  onSelect = emptyFunction,
  pageSize = 50,
  fileType = FileType.IMAGE,
}) {
  const classes = useStyles();

  // workaround since file-manager can be rendered outside Redux Provider by zdocs plugins.
  const currentUser = useMemo(() => {
    return getCurrentStore().getState().auth.user;
  }, []);

  const [editingFile, setEditingFile] = useState();
  const selectedFilesRef = useRef();
  const uploadButtonRef = useRef();

  const listProps = useList({
    listId: `FileManagerList_${fileType}`,
    listFn,
    defaultPageSize: pageSize,
    defaultSorting: {
      CreatedDate: 'DESC',
    },
    defaultFilters: {
      customOwnerId: currentUser ? currentUser.id : null,
      search: { type: 'Name' },
      rangeDate: defaultRangeDate,
    },
  });

  const handleSelect = useCallback((selectedFiles, isDoubleClick) => {
    selectedFilesRef.current = selectedFiles;
    onSelect(selectedFiles, isDoubleClick);

    if (Array.isArray(selectedFiles)) {
      setEditingFile(selectedFiles[selectedFiles.length - 1]);
    } else {
      setEditingFile(selectedFiles);
    }
  }, [onSelect]);

  const handleSearchFilter = useCreateOnFilterChange(listProps, 'search');
  const handleRangeDateFilter = useCreateOnFilterChange(listProps, 'rangeDate');
  const handleOwnerIdFilter = useCreateOnFilterChange(listProps, 'OwnerID');
  const handleAlbumIdFilter = useCreateOnFilterChange(listProps, 'AlbumID');
  const handleCustomOwnerIdFilter = useCreateOnFilterChange(listProps, 'customOwnerId');
  const handleCustomRangeDateFilter = useCreateOnFilterChange(listProps, 'customRangeDate');

  useEffect(() => {
    listProps.addFilter('Type', fileType);
  }, [fileType]);

  return (
    <div className={classes.container}>
      <div className={classes.toolbar}>
        <Fragment>
          <FileManagerSearchField
            type={listProps.filters.search.type}
            value={listProps.filters.search.value}
            onSearch={handleSearchFilter}
          />
          {!listProps.filters.customOwnerId && (
            <AccountSelector
              allowClear
              emptyLabel="-- Người tạo --"
              value={listProps.filters.OwnerID}
              onChange={handleOwnerIdFilter}
              width={250}
            />
          )}
          {!listProps.filters.customRangeDate && (
            <RangeDateSelector
              value={listProps.filters.rangeDate}
              onChange={handleRangeDateFilter}
            />
          )}
          <FlexPushRight />
          {listProps.filters.AlbumID ? (
            <Fragment>
              <FileChooserButton
                title="Thêm ảnh vào album"
                onSelect={handleAddFilesToAlbum}
                fileType={fileType}
                multiple
                triggerComponent={({ onClick }) => (
                  <Button title={`${addButtonLabels[fileType]} vào album`} icon="plus" onClick={onClick}>{addButtonLabels[fileType]}</Button>
                )}
              />
            </Fragment>
          ) : null}
          <FileManagerUploadButton ref={uploadButtonRef} fileType={fileType} onUploaded={handleUploaded} />
          {fileType === FileType.IMAGE &&
            <FeatureFlip name="add-photos-watermark">
              <FileManagerAddWatermarkDialogButton
                onAddWatermark={(position) => handleAddWatermark(fileApi.addPhotosWatermark, position)}
              />
            </FeatureFlip>
          }
        </Fragment>
      </div>

      <div className={classes.inner}>
        <FileManagerTree
          fileType={fileType}
          onAlbumIdFilter={handleAlbumIdFilter}
          onOwnerIdFilter={handleCustomOwnerIdFilter}
          onRangeDateFilter={handleCustomRangeDateFilter}
        />
        <div className={classes.content}>
          <FileManagerDragAndDrop className={classes.grid} onDrop={handleDrop}>
            <FileGrid
              loading={listProps.isFetching}
              files={listProps.items}
              multiple={multiple}
              onSelect={handleSelect}
              editingFile={editingFile}
              grid={grid}
            />
          </FileManagerDragAndDrop>

          <Pagination
            className={classes.pagination}
            showQuickJumper
            size="small"
            total={listProps.total}
            pageSize={listProps.pageSize}
            showTotal={(total, range) => `${range[0]}-${range[1]} trong số ${total}`}
            current={listProps.pageIndex + 1}
            onChange={page => listProps.onPageChange(page - 1)}
          />
        </div>
        <FileManagerSidebar
          currentFile={editingFile}
          onFilterOwnerId={handleOwnerIdFilter}
          onFilterFileId={handleFilterFileId}
          onFilterOriginId={handleFilterOriginId}
          onFileUpdated={handleFileUpdated}
          onFileCreated={handleFileCreated}
        />
      </div>
    </div>
  );

  function handleDrop(files) {
    uploadButtonRef.current.upload(files);
  }

  function handleFilterFileId(fileId) {
    handleSearchFilter({ type: 'FileID', value: fileId });
  }

  function handleFilterOriginId(fileId) {
    handleSearchFilter({ type: 'OriginID', value: fileId });
  }

  function handleUploaded(file) {
    listProps.addItem({ ...file, isNew: true });

    if (listProps.filters.AlbumID) {
      albumApi.addFiles(listProps.filters.AlbumID, [file.FileID]);
    }
  }

  function handleFileUpdated(file) {
    listProps.replaceItem(file, 'FileID');

    // update selected file if any
    if (selectedFilesRef.current) {
      if (Array.isArray(selectedFilesRef.current)) {
        const index = selectedFilesRef.current.findIndex(item => item.FileID === file.FileID);

        if (index > -1) {
          selectedFilesRef.current.splice(index, 1, file);
          onSelect(selectedFilesRef.current);
        }
      } else if (selectedFilesRef.current.FileID === file.FileID) {
        selectedFilesRef.current = file;
        onSelect(file);
      }
    }
  }

  function handleFileCreated(file) {
    listProps.addItem({ ...file, isNew: true });

    if (listProps.filters.AlbumID) {
      albumApi.addFiles(listProps.filters.AlbumID, [file.FileID]);
    }
  }

  function handleAddWatermark(apiFn, position) {
    if (selectedFilesRef.current) {
      if (Array.isArray(selectedFilesRef.current)) {
        if (selectedFilesRef.current.length === 0) {
          message.error('Chưa chọn ảnh đóng dấu!');
        }

        const fileUrls = selectedFilesRef.current.map(f => f.Path).join(',');

        apiFn({ files: fileUrls, position })
          .then((res) => {
            const files = res.data.items;
            selectedFilesRef.current.length = 0;

            files.forEach(file => {
              listProps.addItem({ ...file, isNew: true });
            });

            listProps.refresh();
          });
      }
    } else {
      message.error('Chưa chọn ảnh đóng dấu!');
    }
  }

  function handleAddFilesToAlbum(files) {
    return asyncAction('Thêm vào album', () => {
      return albumApi.addFiles(listProps.filters.AlbumID, files.map(file => file.FileID))
        .then(() => listProps.refresh());
    });
  }
}
