import React, { useEffect, useState } from 'react';
import { getTagNameCache } from 'core/engine/tag/caching';

export default function TagDisplay({ id, ...props }) {
  const [name, setName] = useState('#');

  useEffect(() => {
    if (id) {
      getTagNameCache(id).then((resp) => setName(resp));
    } else {
      setName('#');
    }
  }, [id]);

  return (
    <span {...props}>{name}</span>
  );
}
