import { green, orange, red } from '@ant-design/colors';
import { padZero } from '@bm/common';
import { Button, Icon, message, Modal, Tree } from 'antd';
import { asyncAction } from 'core/common/async';
import classnames from 'core/common/classnames';
import commonStyles from 'core/common/commonStyles';
import { emptyFunction } from 'core/common/empty';
import lodash from 'core/common/lodash';
import logger from 'core/common/logger';
import makeStyles from 'core/common/makeStyles';
import { buildTree } from 'core/common/utils';
import DeleteButton from 'core/components/common/DeleteButton';
import { getListAccountCache } from 'core/engine/account/caching';
import albumApi from 'core/engine/album/api';
import { FileType } from 'core/engine/file/constants';
import { getCurrentStore } from 'core/redux/store';
import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import FileManagerAlbumDialogButton from './FileManagerAlbumDialogButton';

const { TreeNode, DirectoryTree } = Tree;

export const VirtualFolder = {
  ALL: 'all:',
  BY_ACCOUNT: 'account:',
  BY_DATE: 'date:',
};

export const ALL_FILES_LABLES = {
  [FileType.FILE]: 'Tất cả tệp',
  [FileType.IMAGE]: 'Tất cả ảnh',
  [FileType.VIDEO]: 'Tất cả video',
  [FileType.AUDIO]: 'Tất cả audio',
};

export const MY_FILES_LABLES = {
  [FileType.FILE]: 'Tệp của tôi',
  [FileType.IMAGE]: 'Ảnh của tôi',
  [FileType.VIDEO]: 'Video của tôi',
  [FileType.AUDIO]: 'Audio của tôi',
};

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
    height: '100%',
    width: 200,
    minWidth: 200,
    marginRight: 10,
    border: '1px solid #d4d4d4',
    borderRadius: 4,
  },
  list: {
    height: 'calc(100% - 40px)',
    overflow: 'auto',
    '& li span.ant-tree-node-content-wrapper > span': {
      lineHeight: '24px',
    },
  },
  footer: {
    ...commonStyles.flexRow,
    borderTop: '1px solid #d4d4d4',
    height: 40,
    marginTop: 'auto',
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',

    '& > *:not(:last-child)': {
      marginRight: 5,
    },
  },
  add: {
    color: green[7],
    marginLeft: 'auto',
  },
  remove: {
    color: red[5],
  },
  edit: {
    color: orange[5],
  },
}));

export default function FileManagerTree({
  fileType = FileType.IMAGE,
  onAlbumIdFilter = emptyFunction,
  onOwnerIdFilter = emptyFunction,
  onRangeDateFilter = emptyFunction,
}) {
  const classes = useStyles();

  // workaround since file-manager can be rendered outside Redux Provider by zdocs plugins.
  const currentUser = useMemo(() => {
    return getCurrentStore().getState().auth.user;
  }, []);
  const currentAccountKey = 'account:' + currentUser.id;

  const [treeData, setTreeData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([currentAccountKey]);

  const [albumMap, setAlbumMap] = useState({});

  const selectedAlbum = useMemo(() => {
    if (selectedKeys[0]) {
      const [type, id] = parseKey(selectedKeys[0]);
      return type === 'album' ? albumMap[id] : null;
    }

    return null;
  }, [selectedKeys, albumMap]);

  const initialAlbum = useMemo(() => ({
    Type: fileType,
    ParentID: selectedAlbum ? selectedAlbum.AlbumID : 0,
  }), [fileType, selectedAlbum]);

  const virtualFolders = useMemo(() => {
    return [
      {
        key: VirtualFolder.ALL,
        name: ALL_FILES_LABLES[fileType],
        icon: <Icon type="unordered-list" />,
        isLeaf: true,
      },
      {
        key: currentAccountKey,
        name: MY_FILES_LABLES[fileType],
        icon: <Icon type="user" />,
        isLeaf: true,
      },
      {
        key: VirtualFolder.BY_ACCOUNT,
        name: 'Xem theo tài khoản',
        icon: <Icon type="team" />,
        noselect: true,
      },
      {
        key: VirtualFolder.BY_DATE,
        name: 'Xem theo thời gian',
        icon: <Icon type="calendar" />,
        noselect: true,
      },
    ];
  }, [currentAccountKey, fileType]);

  useEffect(() => {
    albumApi.getList({ limit: 500, filters: { ParentID: 0, Type: fileType } })
      .then((resp) => {
        setAlbumMap(old => ({
          ...old,
          ...lodash.keyBy(resp.data.items, 'AlbumID'),
        }));
      });
  }, []);

  useEffect(() => {
    setTreeData([
      ...virtualFolders,
      ...albumsToFolders(Object.values(albumMap)),
    ]);
  }, [virtualFolders, albumMap]);

  return (
    <div className={classes.container}>
      <DirectoryTree
        className={classnames(classes.list, 'draggable-tree')}
        loadData={handleLoadData}
        selectedKeys={selectedKeys}
        onSelect={handleSelect}
        draggable
        blockNode
        onDrop={handleDrop}
        defaultSelectedKeys={[currentAccountKey]}
        expandAction="doubleClick"
      >
        {renderTreeNodes(treeData)}
      </DirectoryTree>
      <div className={classes.footer}>
        {selectedAlbum ? (
          <Fragment>
            <DeleteButton
              anchorElement={({ onClick }) => (
                <Button
                  size="small"
                  icon="delete"
                  shape="circle"
                  className={classes.remove}
                  title="Xóa album"
                  onClick={onClick}
                />
              )}
              title={`Xóa album: ${selectedAlbum.Name}`}
              onDelete={handleDeleteAlbum}
            />
            <FileManagerAlbumDialogButton
              album={selectedAlbum}
              anchorElement={({ onClick }) => (
                <Button
                  size="small"
                  icon="edit"
                  shape="circle"
                  className={classes.edit}
                  title="Sửa album"
                  onClick={onClick}
                />
              )}
              onDone={handleAlbumUpdated}
            />
          </Fragment>
        ) : null}
        <FileManagerAlbumDialogButton
          isEditMode={false}
          anchorElement={({ onClick }) => (
            <Button
              size="small"
              icon="plus"
              shape="circle"
              className={classes.add}
              title="Tạo album"
              onClick={onClick}
            />
          )}
          album={initialAlbum}
          onDone={handleAlbumCreated}
        />
      </div>
    </div>
  );

  function renderTreeNodes(data) {
    return data.map(item => {
      if (item.children) {
        return (
          <TreeNode
            title={item.name}
            key={item.key}
            dataRef={item}
            isLeaf={Boolean(item.isLeaf)}
            icon={item.icon}
            selectable={!item.noselect}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }

      return (
        <TreeNode
          key={item.key}
          title={item.name}
          dataRef={item}
          isLeaf={Boolean(item.isLeaf)}
          icon={item.icon}
          selectable={!item.noselect}
        />
      );
    });
  }

  function handleLoadData(treeNode) {
    const [type, id] = parseKey(treeNode.props.eventKey);

    if (type === 'album') {
      return albumApi.getList({ limit: 500, filters: { ParentID: id, Type: fileType } })
        .then(resp => {
          setAlbumMap(old => ({
            ...old,
            ...lodash.keyBy(resp.data.items, 'AlbumID'),
          }));
        });
    }

    const item = treeNode.props.dataRef;

    if (item.children) {
      return Promise.resolve();
    }

    return loadFolders(item)
      .then((children) => {
        item.children = children;
        setTreeData([...treeData]);
      })
      .catch((err) => {
        logger.error(`Error while loading file in folder ${item.name}:`, err);
        message.error('Cannot load files in folder');
      });
  }

  function handleSelect(selectedKeys) {
    setSelectedKeys(selectedKeys);

    const [type, id] = parseKey(selectedKeys[0]);

    let albumId = undefined;
    let ownerId = undefined;
    let rangeDate = undefined;

    if (type === 'album' && id) {
      albumId = id;
    } else if (type === 'account' && id) {
      ownerId = id;
    } else if (type === 'date' && id) {
      const [year, month, day] = id.split('/');
      const date = moment(`${year}/${month || 1}${day || 1}`, 'YYYY/MM/DD');
      let unit = 'year';

      if (day) {
        unit = 'day';
      } else if (month) {
        unit = 'month';
      }

      rangeDate = [date.clone().startOf(unit), date.clone().endOf(unit)];
    }

    onAlbumIdFilter(albumId);
    onOwnerIdFilter(ownerId);
    onRangeDateFilter(rangeDate);
  }

  function handleDrop({ node, dragNode }) {
    const [dropType, dropId] = parseKey(node.props.eventKey);
    const [dragType, dragId] = parseKey(dragNode.props.eventKey);

    if (dropType !== 'album' || dragType !== 'album') {
      return;
    }

    const dropAlbum = albumMap[dropId];
    const dragAlbum = albumMap[dragId];

    Modal.confirm({
      title: 'Chuyển album?',
      content: <span>Chuyển album <b>{dragAlbum.Name}</b> vào trong album <b>{dropAlbum.Name}</b>.</span>,
      okText: 'Chuyển',
      onOk() {
        handleMoveAlbum(dragAlbum, dropAlbum);
      },
    });
  }

  function handleMoveAlbum(fromAlbum, toAlbum) {
    asyncAction('Chuyển album', () => {
      return albumApi.update({
        AlbumID: fromAlbum.AlbumID,
        ParentID: toAlbum.AlbumID,
      })
        .then(() => {
          setAlbumMap(old => ({
            ...old,
            [fromAlbum.AlbumID]: {
              ...fromAlbum,
              ParentID: toAlbum.AlbumID,
            },
          }));
        });
    });
  }

  function handleDeleteAlbum() {
    return asyncAction(`Xóa album: ${selectedAlbum.Name}`, () => {
      return albumApi.delete(selectedAlbum.AlbumID)
        .then(() => {
          setAlbumMap(old => {
            const newMap = { ...old };
            delete newMap[selectedAlbum.AlbumID];
            return newMap;
          });
        });
    });
  }

  function handleAlbumUpdated(album) {
    setAlbumMap(old => ({
      ...old,
      [album.AlbumID]: album,
    }));
  }

  function handleAlbumCreated(album) {
    setAlbumMap(old => ({
      ...old,
      [album.AlbumID]: album,
    }));
  }
}

function loadFolders(item) {
  if (item.key === VirtualFolder.BY_ACCOUNT) {
    return getListAccountCache().then((resp) => {
      return sortByName(resp.data.items.map(item => ({
        key: 'account:' + item.AccountID,
        name: item.Username,
        isLeaf: true,
      })));
    });
  }

  const [type, id] = parseKey(item.key);

  if (type === 'date') {
    const folders = [];
    const [year, month, day] = id.split('/');

    if (day) {
      return Promise.resolve([]);
    }

    if (month) {
      const lastDayOfMonth = new Date(parseInt(year), parseInt(month), 0).getDate();

      for (let day = 1; day <= lastDayOfMonth; day++) {
        folders.push({
          key: `date:${year}/${month}/${padZero(day)}`,
          name: `${year}_${month}_${padZero(day)}`,
        });
      }

      return Promise.resolve(folders);
    }

    if (year) {
      for (let month = 1; month <= 12; month++) {
        folders.push({
          key: `date:${year}/${padZero(month)}`,
          name: `${year}_${padZero(month)}`,
        });
      }

      return Promise.resolve(folders);
    }

    const thisYear = new Date().getFullYear();

    for (let year = thisYear; year >= 2000; year--) {
      folders.push({
        key: 'date:' + year,
        name: String(year),
      });
    }

    return Promise.resolve(folders);
  }

  return Promise.resolve([]);
}

function sortByName(files) {
  files.sort((f1, f2) => f1.name.localeCompare(f2.name));
  return files;
}

function parseKey(key) {
  const [type, id] = key.split(':');

  if (type === 'date') {
    return [type, id];
  }

  return [type, parseInt(id, 10)];
}

function albumsToFolders(albums) {
  const folders = albums.map(albumToFolder);

  return buildTree(
    sortByName(folders),
    {
      idField: 'key',
      parentIdField: 'parentKey',
      children: 'children',
      rootId: 'album:0',
    }
  );
}

function albumToFolder(album) {
  return {
    key: 'album:' + album.AlbumID,
    name: album.Name,
    parentKey: album.ParentID ? 'album:' + album.ParentID : 'album:0',
  };
}
