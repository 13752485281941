const parsers = [youtubeParser, facebookParser];

export default function buildEmbedCode(urlOrCode) {
  if (!/^https?:\/\//.test(urlOrCode)) {
    return urlOrCode;
  }

  let html;

  for (let i = 0; i < parsers.length; i++) {
    html = parsers[i](urlOrCode);

    if (html) break;
  }

  if (!html) {
    html = `<iframe width="100%" height="300" src="${urlOrCode}"></iframe>`;
  }

  return html;
}

function facebookParser(url) {
  if ((/facebook\.com/.test(url) && /video|watch/.test(url)) || /fb\.watch/.test(url)) {
    return `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&show_text=false" width="100%" height="300" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency></iframe>`;
  }
}

function youtubeParser(url) {
  const videoId = getYoutubeId(url);

  if (videoId) {
    return `<iframe width="100%" height="300" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  }

}

export function getYoutubeId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|live\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11) ? match[2] : null;
}
