import { Button, Form } from 'antd';
import { emptyFunction, emptyObject } from 'core/common/empty';
import lodash from 'core/common/lodash';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import React, { Fragment } from 'react';
import TopicSystemContentListItemScheduleForm from './TopicSystemContentListItemScheduleForm';

function TopicSystemScheduleButton({ form, topicContent = emptyObject, onDone = emptyFunction }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <Fragment>
      <Button
        icon="clock-circle"
        title="Hẹn giờ"
        onClick={handleOpen}
      />
      <BaseDialog
        visible={open}
        title={`Hẹn giờ: ${lodash.get(topicContent, 'Content.Title', '')}`}
        onCancel={handleClose}
        onOk={handleSubmit}
        width={600}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <TopicSystemContentListItemScheduleForm form={form} topicContent={topicContent} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    handleClose();
    onDone({
      ...topicContent,
      StartDate: formData.StartDate ? formData.StartDate.valueOf() : null,
      EndDate: formData.EndDate ? formData.EndDate.valueOf() : null,
    });

    return Promise.resolve();
  }
}

export default Form.create({ name: 'TopicSystemScheduleButton' })(TopicSystemScheduleButton);
