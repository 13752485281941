import { HookAction } from 'core/constants';
import { ObjectType } from 'core/engine/common/constants';
import actionRegistry from 'core/registries/actions';
import domUtils from './domUtils';
import lodash from './lodash';

const EMBEDED_OBJECT_FILTERS = [
  {
    type: ObjectType.FILE_IMAGE,
    selector: 'img[data-image-id]',
    getId(element) {
      return parseInt(element.getAttribute('data-image-id')) || null;
    },
  },
  {
    type: ObjectType.FILE_VIDEO,
    selector: 'video[data-video-id]',
    getId(element) {
      return parseInt(element.getAttribute('data-video-id')) || null;
    },
  },
  {
    type: ObjectType.CONTENT,
    selector: 'related-content',
    getId(element) {
      return parseInt(element.getAttribute('data-cid')) || null;
    },
  },
  {
    type: ObjectType.TOPIC,
    selector: 'related-topic',
    getId(element) {
      return parseInt(element.getAttribute('data-tid')) || null;
    },
  },
  {
    type: ObjectType.INTERVIEW,
    selector: 'interview',
    getId(element) {
      return parseInt(element.getAttribute('interviewid')) || null;
    },
  },
  {
    type: ObjectType.LIVE,
    selector: '[liveid]',
    getId(element) {
      return parseInt(element.getAttribute('liveid')) || null;
    },
  },
  {
    type: ObjectType.VOTE,
    selector: '[voteid]',
    getId(element) {
      return parseInt(element.getAttribute('voteid')) || null;
    },
  },
  {
    type: ObjectType.QUIZ,
    selector: '[quizid]',
    getId(element) {
      return parseInt(element.getAttribute('quizid')) || null;
    },
  },
];

export function parseEmbededObjects(htmlText) {
  const domparser = new DOMParser();
  const doc = domparser.parseFromString(htmlText || '', 'text/html');
  const output = {};

  if (doc.getElementsByTagName('parsererror').length > 0) {
    return output;
  }

  EMBEDED_OBJECT_FILTERS.forEach((filter) => {
    const elements = doc.querySelectorAll(filter.selector);

    if (elements.length) {
      if (!output[filter.type]) {
        output[filter.type] = [];
      }

      output[filter.type].push(
        ...Array.prototype.map.call(elements, element => filter.getId(element))
      );
    }
  });

  Object.keys(output).forEach((key) => {
    output[key] = lodash.uniq(output[key].filter(Boolean));

    if (output[key].length === 0) {
      delete output[key];
    }
  });

  return output;
}

export function normalizeEditorValue(htmlText) {
  if (!htmlText) {
    return htmlText;
  }

  htmlText = htmlText.replace(/(?:\r\n|\r|\n)/g, ' ');

  const domparser = new DOMParser();
  const doc = domparser.parseFromString(htmlText, 'text/html');

  if (doc.getElementsByTagName('parsererror').length > 0) {
    return htmlText;
  }

  const body = doc.getElementsByTagName('body')[0];

  if (!body) {
    return htmlText;
  }

  const childNodes = Array.prototype.slice.call(body.childNodes);

  Array.prototype.forEach.call(childNodes, (node) => {
    if (node.nodeType === Node.TEXT_NODE || node.tagName === 'P' || node.tagName === 'DIV') {
      if (domUtils.isEmptyNode(node)) {
        node.parentNode.removeChild(node);
      }
    }
  });

  body.querySelectorAll('.MsoNormal').forEach((element) => {
    domUtils.removeClass(element, 'MsoNormal');
  });

  const msTables = body.querySelectorAll('table.MsoNormalTable');

  msTables.forEach((table) => {
    domUtils.cleanAttributes(table, ['style']);

    table.removeAttribute('border');
    table.removeAttribute('cellpadding');

    table.querySelectorAll('*').forEach(element => {
      domUtils.cleanAttributes(element, ['style', 'rowspan', 'colspan']);
      element.removeAttribute('nowrap');
    });

    table.querySelectorAll('td > p:only-child').forEach((paragraph) => {
      domUtils.unwrap(paragraph);
    });
  });

  body.querySelectorAll('video').forEach((element) => {
    element.setAttribute('controls', true);
  });

  actionRegistry.doAction(HookAction.NORMALIZE_HTML, { body, htmlText });

  return body.innerHTML;
}

export function insertButtonAfter(buttons = [], target, button) {
  const targetIndex = buttons.indexOf(target);

  if (buttons.indexOf(button) === -1 && targetIndex > -1) {
    buttons.splice(targetIndex + 1, 0, button);
  }
}

export function insertButtonBefore(buttons = [], target, button) {
  const targetIndex = buttons.indexOf(target);

  if (buttons.indexOf(button) === -1 && targetIndex > -1) {
    buttons.splice(targetIndex, 0, button);
  }
}
