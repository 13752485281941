import { useRef, useEffect } from 'react';

export default function useSyncRef(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}
