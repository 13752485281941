import { Button, Form } from 'antd';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import widgetApi from 'core/engine/widget/api';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import React, { forwardRef, Fragment, useImperativeHandle } from 'react';
import WidgetForm from './WidgetForm';
import { emptyFunction, emptyObject } from 'core/common/empty';

const WidgetFormDialogButton = forwardRef(({
  form,
  widget = emptyObject,
  onDone = emptyFunction,
  isEditMode = false,
  onInsert,
  onUpdate,
  hasButton = true,
  availableWidgets,
}, ref) => {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  const title = isEditMode ? 'Sửa widget: ' + widget.Title : 'Tạo widget';

  return (
    <Fragment>
      {hasButton && (
        <Button
          icon={isEditMode ? 'edit' : 'plus'}
          title={title}
          onClick={handleOpen}
        >
          {isEditMode ? '' : title}
        </Button>
      )}
      <BaseDialog
        visible={open}
        title={title}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText={isEditMode ? 'Lưu' : 'Tạo'}
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <WidgetForm form={form} widget={widget} availableWidgets={availableWidgets} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    if (formData.Meta) {
      formData.Meta = JSON.stringify(formData.Meta);
    }

    if (isEditMode) {
      if (onUpdate) {
        return Promise.resolve()
          .then(() => {
            onUpdate(formData);
            handleDone();
          });
      } else {
        return asyncAction(title, () => {
          return widgetApi.update(formData)
            .then(handleDone);
        });
      }
    }

    if (onInsert) {
      return Promise.resolve()
        .then(() => {
          onInsert([formData]);
          handleDone();
        });
    } else {
      return asyncAction(title, () => {
        return widgetApi.create(formData)
          .then(handleDone);
      });
    }
  }

  function handleDone() {
    handleClose();
    onDone();
  }
});

export default Form.create({ name: 'WidgetFormDialogButton' })(WidgetFormDialogButton);
