import React, { useMemo } from 'react';
import { buildShortContentUrl } from 'core/common/urlHelper';

export function ContentLink({ id, postfix, children, ...props }) {
  const url = useMemo(() => {
    return buildShortContentUrl(id) + (postfix ? postfix : '');
  }, [id, postfix]);

  return (
    <a href={url} rel="noopener noreferrer" target="_blank" {...props}>{children || id}</a>
  );
}
