import { asyncAction } from 'core/common/async';
import { formatDateTime } from 'core/common/datetimeHelper';
import { commonColumns } from 'core/common/listUtils';
import ChartViewer from 'core/components/chart/ChartViewer';
import AsyncButton from 'core/components/common/AsyncButton';
import Container from 'core/components/common/Container';
import FeatureFlip from 'core/components/common/FeatureFlip';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateSelector from 'core/components/common/RangeDateSelector';
import Selector from 'core/components/common/Selector';
import BaseTable from 'core/components/table/BaseTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import { ContentTypeOptions } from 'core/engine/content/constants';
import reportApi from 'core/engine/report/api';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Sites } from 'core/engine/site/constants';

function listFn({ filters }) {
  const listFilters = {
    group: filters.groupBy,
    startDate: filters.rangeDate[0].toDate().getTime(),
    endDate: filters.rangeDate[1].toDate().getTime(),
    siteId: filters.siteId,
  };

  delete listFilters.rangeDate;

  return reportApi.contentByTypes({ filters: listFilters })
    .then((resp) => {
      resp.data.items = formatItems(resp.data.items);
      return resp;
    });
}

export default function ReportContentByTypesView({ siteId = Sites.ALL }) {
  const defaultRangeDate = useMemo(() => [moment().add(-1, 'months'), moment()], []);

  const listProps = useList({
    autoLoad: true,
    listFn,
    defaultFilters: {
      groupBy: 'day',
      rangeDate: defaultRangeDate,
      siteId: siteId,
    },
  });

  const columns = useMemo(() => {
    return [
      {
        Header: 'Thời gian',
        accessor: 'StatsDate',
        width: 100,
      },
      {
        Header: 'Tổng số',
        accessor: 'Total',
        style: { fontWeight: 'bold' },
        width: 80,
        ...commonColumns.formatNumber,
      },
      ...ContentTypeOptions.map((option) => ({
        ...commonColumns.formatNumber,
        Header: option.label,
        accessor: option.label,
      })),
    ];
  }, []);

  const chartData = useMemo(() => {
    return listProps.items.slice().reverse();
  }, [listProps.items]);

  const onGroupByFilter = useCreateOnFilterChange(listProps, 'groupBy');

  return (
    <Container pageTitle="Thống kê theo loại tin bài">
      <TableToolbarWrapper>
        <Selector
          value={listProps.filters.groupBy}
          onChange={onGroupByFilter}
          options={{ day: 'Xem theo ngày', week: 'Xem theo tuần', month: 'Xem theo tháng' }}
          width={150}
        />
        <RangeDateSelector
          value={listProps.filters.rangeDate}
          onChange={onGroupByFilterChange}
        />
        <FlexPushRight />
        <FeatureFlip name="export-report">
          <AsyncButton
            icon="export"
            type="primary"
            onClick={handleExport}
            title="Xuất Excel"
          >
            Xuất Excel
          </AsyncButton>
        </FeatureFlip>
      </TableToolbarWrapper>
      <ChartViewer
        title="Loại tin bài"
        xAxisKey="StatsDate"
        data={chartData}
        sum={false}
      />

      <BaseTable
        columns={columns}
        {...useListProps(listProps)}
        fullHeight={false}
        showPageSizeOptions={false}
        showPageJump={false}
        pages={1}
        pageSize={listProps.total || 1}
      />
    </ Container>
  );

  function onGroupByFilterChange(value) {
    if (value && value.length && listProps.filters.groupBy) {
      const groupBy = listProps.filters.groupBy;
      value = [value[0].startOf(groupBy), value[1].endOf(groupBy)];
    }

    listProps.addFilter('rangeDate', value);
  }

  function handleExport() {
    const listFilters = {
      group: listProps.filters.groupBy,
      startDate: listProps.filters.rangeDate[0].toDate().getTime(),
      endDate: listProps.filters.rangeDate[1].toDate().getTime(),
      siteId: listProps.filters.siteId,
    };

    return asyncAction('Xuất thống kê theo loại tin bài', () => {
      return reportApi.exportContentByTypes({
        filters: listFilters,
        filename: `Thống kê theo loại tin bài từ ${listProps.filters.rangeDate[0].format('DD-MM-YYYY')} đến ${listProps.filters.rangeDate[1].format('DD-MM-YYYY')}.xlsx`,
      });
    });
  }
}

function formatItems(items) {
  return items.map((item) => {
    const newItem = {
      StatsDate: formatDateTime(item.StatsDate, 'DD/MM/YYYY'),
      Total: 0,
    };

    ContentTypeOptions.forEach((option) => {
      newItem[option.label] = item[option.value] || 0;
      newItem.Total += newItem[option.label];
    });

    return newItem;
  });
}
