import { Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import { parseJson } from 'core/common/jsonUtils';
import ImagePickerInput from 'core/components/common/ImagePickerInput';
import Selector from 'core/components/common/Selector';
import SimpleEditor from 'core/components/editor/SimpleEditor';
import SeoEntryForm from 'core/components/seo-entry/SeoEntryForm';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import { SeoEntryType } from 'core/engine/seo-entry/constants';
import { TopicType } from 'core/engine/topic/constants';
import React, { useMemo } from 'react';
import PodcastCategoriesInput from 'src/components/podcast/PodcastCategoriesInput';
import { ThemeLabel } from 'src/engine/theme/constants';

export default function TopicForm({ form, onSubmit, topic, editorRef }) {
  const { getFieldDecorator } = form;

  const meta = useMemo(() => parseJson(topic.Meta, {}), [topic]);

  getFieldDecorator('Type', { initialValue: topic.Type || TopicType.NORMAL });
  getFieldDecorator('Enabled', { initialValue: isNullOrUndefined(topic.Enabled) ? true : topic.Enabled });

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      {topic.TopicID && (
        <Form.Item label="ID">
          {getFieldDecorator('TopicID', {
            initialValue: topic.TopicID,
          })(
            <Input disabled type="number" />
          )}
        </Form.Item>
      )}
      <Form.Item label="Tên chủ đề">
        {getFieldDecorator('Name', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
          initialValue: topic.Name,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Chuyên mục">
        {getFieldDecorator('ZoneID', {
          initialValue: topic.ZoneID || 0,
        })(
          <ZoneSelector emptyLabel="Trang chủ" emptyValue={0} />
        )}
      </Form.Item>
      <Form.Item label="Ảnh đại diện">
        {getFieldDecorator('AvatarUrl', {
          initialValue: topic.AvatarUrl,
        })(
          <ImagePickerInput showImage />
        )}
      </Form.Item>
      <Form.Item label="Mô tả">
        {getFieldDecorator('Description', {
          initialValue: topic.Description || '',
        })(
          <SimpleEditor placeholder="Mô tả" editorRef={editorRef} />
        )}
      </Form.Item>

      <Form.Item label="Ảnh tiêu đề">
        {getFieldDecorator('Meta.titleImage', {
          initialValue: meta.titleImage || '',
        })(
          <ImagePickerInput />
        )}
      </Form.Item>
      <Form.Item label="Background">
        {getFieldDecorator('Meta.backgroundImage', {
          initialValue: meta.backgroundImage || '',
        })(
          <ImagePickerInput />
        )}
      </Form.Item>
      <Form.Item label="Background mobile">
        {getFieldDecorator('Meta.mobileBackgroundImage', {
          initialValue: meta.mobileBackgroundImage || '',
        })(
          <ImagePickerInput />
        )}
      </Form.Item>
      <Form.Item label="Theme">
        {getFieldDecorator('Meta.theme', {
          initialValue: meta.theme || '',
        })(
          <Selector
            emptyValue=""
            emptyLabel="-- Theme --"
            options={ThemeLabel}
          />
        )}
      </Form.Item>
      <Form.Item label="Podcast cover">
        {getFieldDecorator('Meta.podcastCover', {
          initialValue: meta.podcastCover || '',
        })(
          <ImagePickerInput />
        )}
      </Form.Item>
      <Form.Item label="Podcast categories">
        {getFieldDecorator('Meta.podcastCategories', {
          initialValue: meta.podcastCategories,
        })(
          <PodcastCategoriesInput />
        )}
      </Form.Item>

      <SeoEntryForm form={form} type={SeoEntryType.TOPIC} id={topic.TopicID} />

    </Form>
  );
}
