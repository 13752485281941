import { Button, message } from 'antd';
import React, { useRef, Fragment } from 'react';
import BaseDialog from 'core/components/common/BaseDialog';
import groupApi from 'core/engine/group/api';
import useAsyncAction from 'core/hooks/useAsyncAction';
import useDialog from 'core/hooks/useDialog';
import GroupCommandForm from './GroupCommandTable';

export default function GroupCommandFormDialogButton({ group = {} }) {

  const { open, handleOpen, handleClose } = useDialog();
  const { performAction, isLoading } = useAsyncAction();
  const dataRef = useRef();

  return (
    <Fragment>
      <Button
        title="Phân quyền"
        icon={'safety'}
        onClick={handleOpen}>
      </Button>
      <BaseDialog
        visible={open}
        title={'Phân quyền nhóm: ' + group.Name}
        onCancel={handleClose}
        onOk={onOk}
        okText="Lưu"
        width={900}
        confirmLoading={isLoading}
        fullHeightFixed
      >
        <GroupCommandForm groupId={group.GroupID} ref={dataRef} />
      </BaseDialog>
    </Fragment>
  );

  function onOk() {
    const groupCommands = dataRef.current.getPermissions();

    if (!groupCommands) {
      message.error('Bạn chưa thực hiện thay đổi!');
      return;
    }

    return performAction('Phân quyền nhóm', () => {
      return groupApi.setCommandRoles(group.GroupID, groupCommands)
        .then(() => {
          handleClose();
        });
    });
  }
}
