import { IS_MOBILE } from '@bm/common';
import { Icon, Input } from 'antd';
import classnames from 'core/common/classnames';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import { buildTree } from 'core/common/utils';
import { LOGO_TITLE, PUBLIC_URL } from 'core/config';
import { HEADER_HEIGHT } from 'core/constants';
import useSidebar from 'core/hooks/useSidebar';
import { load, selectItemId, setFilter } from 'core/redux/actions/menu';
import { itemsSelector, selectedItemIdSelector, visibleFilteredItemsSelector } from 'core/redux/selectors/menu';
import _ from 'lodash';
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  logo: {
    ...commonStyles.flexAllCentered,
    height: HEADER_HEIGHT,

    '& a': {
      ...commonStyles.flexAllCentered,
      height: 'auto',
      width: 'auto',
    },

    '& span': {
      color: '#FFF',
      fontWeight: 700,
      fontSize: 17,
      paddingLeft: 10,
    },
  },

  filter: {
    '& > input': {
      color: '#ccc',
      backgroundColor: '#132a40',
      border: 'none',
      borderRadius: 0,
      paddingLeft: 20,
      '&:focus': {
        border: 'none',
        outline: 0,
        boxShadow: 'none',
      },
    },

    '& .anticon': {
      color: '#ccc',
    },
  },

  menuList: {
    height: 'calc(100% - 96px)',
    overflow: 'auto',
    color: '#f0f5fab3',
    paddingBottom: 20,
    paddingTop: 5,

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '&:hover::-webkit-scrollbar': {
      display: 'block',
    },

    '& a': {
      color: '#f0f5fab3',
    },

    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
  },
  menuItem: {
    width: '100%',
    padding: '8px 2px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 10,

    '& .anticon': {
      fontSize: 16,
      marginRight: 10,
    },
  },
  menuItemGroup: {
    width: '100%',

    '&:not(:last-child)': {
      borderBottom: '1px solid #132a40',
      marginBottom: 10,
    },
  },
  menuItemRoot: {
    color: '#6f7880',
    fontWeight: 700,
    cursor: 'normal',
  },
  menuItemChild: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#132a40',
    },
  },
  selected: {
    backgroundColor: '#132a40',
    color: '#fff',
  },
}));

const NestMenuItem = memo(function NestMenuItem({ item, selectedItemId }) {
  const classes = useStyles();
  const history = useHistory();
  const sidebar = useSidebar();

  const handleClick = useCallback(() => {
    if (item.Command) {
      history.push(`${item.Command}${item.Parameters ? item.Parameters : ''}`);
    }

    IS_MOBILE && sidebar.toggle();
  }, [item, sidebar.toggle]);

  if (item.Subs.length) {
    return (
      <div className={classes.menuItemGroup}>
        <span className={classnames(classes.menuItem, classes.menuItemRoot)}>
          {item.Name}
        </span>
        {item.Subs.map(subItem => (
          <NestMenuItem key={subItem.CommandID} item={subItem} selectedItemId={selectedItemId} />
        ))}
      </div>
    );
  }

  const renderItem = (
    <Fragment>
      <Icon type={item.Icon || 'desktop'} />
      {item.Name}
    </Fragment>
  );

  return item.RedirectLink ? (
    <a
      href={item.RedirectLink}
      rel="noopener noreferrer"
      target="_blank"
      className={classnames(classes.menuItem, classes.menuItemChild)}
    >
      {renderItem}
    </a>
  ) : (
    <div
      onClick={handleClick}
      className={classnames(classes.menuItem, classes.menuItemChild, selectedItemId === item.CommandID && classes.selected)}
    >
      {renderItem}
    </div>
  );
});

export default function Sidebar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const allMenuItems = useSelector(itemsSelector);
  const menuItems = useSelector(visibleFilteredItemsSelector);
  const selectedItemId = useSelector(selectedItemIdSelector);
  const [sortedMenus, setSortedMenus] = useState([]);
  const sidebar = useSidebar();

  const handleSearch = useCallback((event) => {
    dispatch(setFilter(event.target.value.trim()));
  }, []);

  const handleClick = useCallback(() => IS_MOBILE && sidebar.toggle(), [sidebar.toggle]);

  useEffect(() => {
    dispatch(load());
  }, []);

  useEffect(() => {
    setSortedMenus(buildTree(menuItems, {
      idField: 'CommandID',
    }));
  }, [menuItems]);

  // sync location with sidebar
  useEffect(() => {
    const command = location.pathname;
    const selectedCommand = _.find(allMenuItems, { Command: command });

    if (selectedCommand) {
      dispatch(selectItemId(selectedCommand.CommandID));
    } else {
      dispatch(selectItemId(null));
    }
  }, [allMenuItems, location.pathname]);

  return (
    <Fragment>
      <div className={classnames(classes.logo, 'cms-logo')}>
        <Link to="/" onClick={handleClick}>
          <img src={PUBLIC_URL + '/images/logo.png'} alt="logo" />
          <span>{LOGO_TITLE}</span>
        </Link>
      </div>
      <Input.Search
        className={classes.filter}
        onChange={handleSearch}
        placeholder="Tìm kiếm..."
        allowClear
      />
      <div className={classes.menuList}>
        {sortedMenus.map(item => (
          <NestMenuItem key={item.CommandID} item={item} selectedItemId={selectedItemId} />
        ))}
      </div>
    </Fragment>
  );
}
