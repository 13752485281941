import commonStyles from 'core/common/commonStyles';
import { emptyArray } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import FlexDiv from 'core/components/common/FlexDiv';
import ContentFormRoyaltyInput from 'core/modules/contents/components/form/input/ContentFormRoyaltyInput';
import React, { forwardRef, useMemo } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  list: {
    '& > div:not(:last-child)': {
      marginBottom: 20,
    },
  },
}));

export default forwardRef(function ContentFormItemRoyaltyInput({
  value = emptyArray,
  onChange,
}, ref) {
  const classes = useStyles();
  const royalties = useMemo(() => {
    return value && value.length > 0 ? value : [{}];
  }, [value]);

  return (
    <div ref={ref} className={classes.container}>
      <FlexDiv className={classes.header}>
        <span ref={ref}>Nhuận bút:</span>
      </FlexDiv>
      <div className={classes.list}>
        <ContentFormRoyaltyInput
          value={royalties[0]}
          onChange={handleChange}
        />
      </div>
    </div>
  );

  function handleChange(val) {
    onChange([val]);
  }
});
