import { Form } from 'antd';
import React from 'react';
import ContentFormAttributeTagInput from './ContentFormAttributeTagInput';

export default function ContentFormItemAttributeTag({ content, form, defaultValue }) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item>
      {getFieldDecorator('Attributes', {
        initialValue: content.ContentID ? content.Attributes : (defaultValue || 0),
      })(
        <ContentFormAttributeTagInput />
      )}
    </Form.Item>
  );
}
