import { ContentDisplayTypeOptions } from 'core/engine/content/constants';
import React, { forwardRef } from 'react';
import Selector from '../common/Selector';

export default forwardRef(function ContentDisplayTypeSelector({ value, onChange, ...props }, ref) {

  return ContentDisplayTypeOptions.length ? (
    <Selector
      ref={ref}
      options={ContentDisplayTypeOptions}
      value={value}
      onChange={onChange}
      valueIsNumber={true}
      {...props}
    />
  ) : null;
});
