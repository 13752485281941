import { Checkbox } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { formatDateTime } from 'core/common/datetimeHelper';
import makeStyles from 'core/common/makeStyles';
import contentLogApi from 'core/engine/content-log/api';
import { ContentStatusLabel } from 'core/engine/content/constants';
import lodash from 'core/common/lodash';

const useStyles = makeStyles(() => ({
  item: {
    padding: 7,
    '& > .ant-checkbox-wrapper': {
      fontSize: 16,
    },
    '& > .ant-checkbox-wrapper-checked':
    {
      fontWeight: 600,
    },
  },
}));

export default forwardRef(function ContentLogVersionSelector({ value = [], onChange, contentId }, ref) {
  const classes = useStyles();

  const [checkedList, setCheckedList] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (contentId > 0) {
      contentLogApi.getVersions(contentId).then(resp => resp.data.items).then((resp) => {
        const options = resp.map(function (item, index) {
          const label = `${resp.length - index}. ${item.EditorName} - ${ContentStatusLabel[item.Status]} (${formatDateTime(item.ModifiedDate)})`;
          return { value: item.LogID, label };
        });

        setOptions(options);

        if (value.length === 0) {
          setCheckedList( resp.slice(0, 2).map(item => item.LogID));
        }
      });
    }
  }, [contentId]);

  return (
    <div ref={ref}>
      {options.map(item => (
        <div key={item.value} className={classes.item}>
          <Checkbox checked={checkedList.indexOf(item.value) !== -1} value={item.value} onChange={handleChange}>
            {item.label}
          </Checkbox>
        </div>
      ))
      }
    </div>
  );

  function handleChange(event) {
    const values = [...checkedList];

    if (event.target.checked) {
      if (values.length === 2) {
        values.splice(1, 1, event.target.value);
      } else {
        values.push(event.target.value);
      }
    } else {
      lodash.pull(values, event.target.value);
    }

    setCheckedList(values);
    onChange(values);
  }
});
