import { getSiteZonesCache } from './caching';
import { Sites, SubSiteZone } from './constants';
import lodash from 'core/common/lodash';

const siteUtils = {
  getSiteId(zoneId) {
    return getSiteZonesCache().then((zoneMap) => {
      const subSiteIds = Object.keys(SubSiteZone);

      let siteId = Sites.MAIN;
      subSiteIds.forEach((subSiteId) => {
        if (subSiteId < 0) return;
        if (zoneMap[subSiteId].filter(zone => zone.ZoneID === zoneId).length) siteId = subSiteId;
      });

      return siteId;
    });
  },

  filterZonesByRoot(zones = [], rootZoneId, hasRoot) {
    const filtered = [];
    let index = zones.findIndex(zone => zone.ZoneID === rootZoneId);

    if (index === -1) {
      return filtered;
    }

    const rootZone = zones[index];

    // ignore the first one
    index += 1;

    while (zones[index] && zones[index].ParentID !== 0) {
      filtered.push({
        ...zones[index],
        Level: zones[index].Level - 1,
      });
      index += 1;
    }

    hasRoot && filtered.unshift(rootZone);
    return filtered;
  },

  getSiteZonesMap(zones = [], hasRoot = false) {
    const siteZonesMap = {};
    const subSiteZoneIds = Object.values(SubSiteZone);
    const subSiteIds = Object.keys(SubSiteZone);

    subSiteIds.forEach(siteId => {
      siteZonesMap[siteId] = this.filterZonesByRoot(zones, SubSiteZone[siteId], hasRoot);
    });

    if (subSiteZoneIds.length) {
      const subSiteZones = Object.values(siteZonesMap).flat();
      const subSiteZoneMap = lodash.keyBy(subSiteZones, 'ZoneID');
      siteZonesMap[Sites.MAIN] = zones.filter(zone => !subSiteZoneMap[zone.ZoneID] && !subSiteZoneIds.includes(zone.ZoneID));
    }

    siteZonesMap[Sites.ALL] = zones;
    return siteZonesMap;
  },
};

export default siteUtils;
