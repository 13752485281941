import Container from 'core/components/common/Container';
import useParamId from 'core/hooks/useParamId';
import React from 'react';
import TopicDetailView from './TopicDetailView';
import TopicListDisplay from './TopicListDisplay';

export default function TopicListView() {
  const topicId = useParamId();

  if (topicId) {
    return <TopicDetailView topicId={topicId} />;
  }

  return (
    <Container pageTitle="Chủ đề tin">
      <TopicListDisplay />
    </Container>
  );
}
