import { formatByte } from '@bm/common';
import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { secondsToDurationString } from 'core/common/utils';
import { FileType } from 'core/engine/file/constants';
import DateTimeDisplay from '../common/DateTimeDisplay';
import { formatNumber } from 'core/common/string';

export default function FileItemTooltip({ file, children }) {
  const tooltip = useMemo(() => {
    const extraFields = {
      dimension: null,
      bitrate: null,
      duration: null,
    };

    if (file.Meta) {
      if ([FileType.IMAGE, FileType.VIDEO].includes(file.Type)) {
        extraFields.dimension = `${file.Meta.width || 0}x${file.Meta.height || 0}`;
      }

      if ([FileType.VIDEO, FileType.AUDIO].includes(file.Type)) {
        extraFields.bitrate = file.Meta.bitrate || '0';
        extraFields.duration = secondsToDurationString(file.Meta.duration);
      }
    }

    return (
      <div>
        <div><strong>{file.Name}</strong></div>
        <div>Ngày: <DateTimeDisplay timestamp={file.CreatedDate} /></div>
        <div>Dung lượng: {formatByte(file.Size)}</div>
        {extraFields.duration && <div>Thời lượng: {extraFields.duration}</div>}
        {extraFields.dimension && <div>Kích thước: {extraFields.dimension}</div>}
        {extraFields.bitrate && <div>Bitrate: {formatNumber(extraFields.bitrate, '.')} Kbps</div>}
      </div>
    );
  }, [file]);

  return (
    <Tooltip title={tooltip}>{children}</Tooltip>
  );
}
