import { Checkbox } from 'antd';
import { commonColumns } from 'core/common/listUtils';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import AvatarDisplay from 'core/components/common/AvatarDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import DeleteButton from 'core/components/common/DeleteButton';
import BaseTable from 'core/components/table/BaseTable';
import React from 'react';
import { EPaperTypeLabel } from 'src/engine/epaper/constants';
import EPaperFormDialogButton from './EPaperFormDialogButton';

export default function EPaperList({ onUpdate, onDelete, ...props }) {
  const columns = [
    {
      ...commonColumns.allCentered,
      Header: 'ID',
      accessor: 'EPaperID',
      sortable: true,
      width: 70,
    },
    {
      ...commonColumns.allCentered,
      Header: 'Ảnh đại diện',
      accessor: 'AvatarUrl',
      Cell: ({ value }) => (<AvatarDisplay url={value} />),
      width: 90,
    },
    {
      Header: 'Tiêu đề',
      accessor: 'Title',
      minWidth: 220,
    },
    {
      ...commonColumns.allCentered,
      Header: 'Ngày xuất bản',
      accessor: 'Date',
      Cell: ({ value }) => (<DateTimeDisplay timestamp={value} />),
      sortable: true,
      minWidth: 120,
      maxWidth: 200,
    },
    {
      ...commonColumns.allCentered,
      Header: 'Số ra',
      accessor: 'Number',
      maxWidth: 100,
    },
    {
      ...commonColumns.allCentered,
      Header: 'Loại ấn phẩm',
      accessor: 'Type',
      Cell: ({ value }) => EPaperTypeLabel[value],
      maxWidth: 200,
    },
    {
      ...commonColumns.allCentered,
      Header: 'Thứ tự',
      accessor: 'Order',
      sortable: true,
      width: 70,
    },
    {
      ...commonColumns.allCentered,
      Header: 'Sử dụng',
      accessor: 'Enabled',
      Cell: ({ original }) => <Checkbox checked={original.Enabled} />,
      width: 100,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <EPaperFormDialogButton epaper={original} onDone={onUpdate} />
          <DeleteButton title={`Xóa ấn phẩm: ${original.Title}?`} onDelete={() => onDelete(original)} />
        </ActionsCellWrapper>
      ),
      width: 150,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
