import { httpGet, httpPost } from 'core/common/http-client';

const toolApi = {
  check(data) {
    return httpPost('/tools/post/spell-check', data);
  },

  crawl(url) {
    return httpGet('/tools/get/crawl', { qs: { url } });
  },

  triggerEvent(data) {
    return httpPost('/tools/post/event-trigger', data);
  },
};

export default toolApi;
