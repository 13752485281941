import { blue } from '@ant-design/colors';
import { Icon } from 'antd';
import React, { memo, useCallback, useMemo } from 'react';
import classnames from 'core/common/classnames';
import commonStyles from 'core/common/commonStyles';
import { emptyFunction } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import { secondsToDurationString } from 'core/common/utils';
import ImageDisplay from 'core/components/common/ImageDisplay';
import { FileType } from 'core/engine/file/constants';
import FileItemTooltip from './FileItemTooltip';
import FileSelectedDot from './FileSelectedDot';

const useStyles = makeStyles(() => ({
  container: {
    margin: 2,
    width: 128,
    height: 128,
    position: 'relative',
    backgroundColor: '#eeeeee',
    userSelect: 'none',

    '&:not(:hover) i.check-icon': {
      visibility: 'hidden',
    },
  },
  editing: {
    backgroundColor: blue[4],
  },
  image: {
    width: '100%',
    height: '100%',
  },
  video: {
    ...commonStyles.flexAllCentered,
    width: '100%',
    height: '100%',
    backgroundColor: '#333',
  },
  fileIcon: {
    fontSize: 40,
  },
  selected: {
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    transform: 'translateX(10px) translateY(10px)',
  },
  checkIcon: {
    position: 'absolute',
    top: 5,
    left: 5,
    cursor: 'pointer',
    color: '#ffffffb3',
    fontSize: 20,
    zIndex: 5,
  },
  isNew: {
    height: 10,
    width: 10,
    backgroundColor: blue[3],
    borderRadius: '50%',
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 5,
  },
  duration: {
    bottom: 0,
    right: 0,
    padding: 2,
    color: '#fff',
    fontSize: 'small',
    backgroundColor: '#00000052',
    borderTopLeftRadius: 3,
    position: 'absolute',
  },
}));

export default memo(function FileBoxItem({
  file,
  selected = false,
  editing = false,
  onSelect = emptyFunction,
}) {
  const classes = useStyles();

  const handleSelect = useCallback(() => {
    onSelect(file);
  }, [file, onSelect]);

  const handleDoubleClickSelect = useCallback(() => {
    onSelect(file, true);
  }, [file, onSelect]);

  const videoDuration = useMemo(() => {
    if (file.Type === FileType.VIDEO || file.Type === FileType.AUDIO) {
      return secondsToDurationString(file.Meta.duration);
    }

    return null;
  }, [file]);

  const display = useMemo(() => {
    let posterUrl;
    let icon = 'file';

    if (file.Type === FileType.VIDEO) {
      icon = 'video-camera';

      if (file.Meta && file.Meta.avatarUrl) {
        posterUrl = file.Meta.avatarUrl;
      }
    }

    if (file.Type === FileType.AUDIO) {
      icon = 'video-camera';
    }

    if (file.Type === FileType.IMAGE) {
      posterUrl = file.Path;
    }

    if (posterUrl) {
      return (
        <ImageDisplay
          className={classnames(classes.image, selected && classes.selected)}
          src={posterUrl}
          alt={file.FileID}
          width={150}
          height={150}
        />
      );
    }

    return (
      <div className={classnames(classes.video, selected && classes.selected)}>
        <Icon type={icon} className={classes.fileIcon} />
      </div>
    );
  }, [file, selected]);

  return (
    <FileItemTooltip file={file}>
      <div
        className={classnames(classes.container, editing && classes.editing)}
        onClick={handleSelect}
        onDoubleClick={handleDoubleClickSelect}
      >
        {selected ? (
          <FileSelectedDot className={classes.checkIcon} number={selected} />
        ) : (
          <Icon
            className={classnames(classes.checkIcon, 'check-icon')}
            theme="filled"
            type="check-circle"
          />
        )}
        {file.isNew && <span className={classes.isNew}></span>}
        {videoDuration && !selected && <span className={classes.duration}>{videoDuration}</span>}
        {display}
      </div>
    </FileItemTooltip>
  );
});
