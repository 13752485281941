import { Checkbox, Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import AuthorSelector from 'core/components/author/AuthorSelector';
import ImagePickerInput from 'core/components/common/ImagePickerInput';
import SeoEntryForm from 'core/components/seo-entry/SeoEntryForm';
import { SeoEntryType } from 'core/engine/seo-entry/constants';
import React from 'react';

export default function AuthorForm({ form, onSubmit, author = {} }) {
  const { getFieldDecorator } = form;

  getFieldDecorator('AuthorID', { initialValue: author.AuthorID });

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Tên tác giả">
        {getFieldDecorator('FullName', {
          rules: [
            { required: true, message: 'Bắt buộc!' },
            { max: 250, message: 'Tối đa 250 kí tự!' },
          ],
          initialValue: author.FullName,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Ảnh đại diện">
        {getFieldDecorator('AvatarUrl', {
          initialValue: author.AvatarUrl,
        })(
          <ImagePickerInput showImage />
        )}
      </Form.Item>
      <Form.Item label="Tác giả gốc">
        {getFieldDecorator('Parent', {
          initialValue: author.ParentID && author.Parent ? author.Parent : { AuthorID: 0, FullName: '' },
        })(
          <AuthorSelector exclusiveItems={[author]} />
        )}
      </Form.Item>
      <Form.Item label="Mô tả">
        {getFieldDecorator('Description', {
          rules: [{ max: 2000, message: 'Tối đa 2000 kí tự!' }],
          initialValue: author.Description,
        })(
          <Input.TextArea placeholder="Nhập mô tả" />,
        )}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator('Email', {
          rules: [
            { type: 'email', message: 'Email không hợp lệ!' },
            { max: 200, message: 'Tối đa 200 kí tự!' },
          ],
          initialValue: author.Email,
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Facebook">
        {getFieldDecorator('Facebook', {
          rules: [
            { type: 'url', message: 'Đường dẫn không hợp lệ!' },
            { max: 250, message: 'Tối đa 250 kí tự' },
          ],
          initialValue: author.Facebook,
        })(
          <Input placeholder="https://facebook.com/yourname" />
        )}
      </Form.Item>
      <Form.Item label="Twitter">
        {getFieldDecorator('Twitter', {
          rules: [
            { type: 'url', message: 'Đường dẫn không hợp lệ!' },
            { max: 250, message: 'Tối đa 250 kí tự!' },
          ],
          initialValue: author.Twitter,
        })(
          <Input placeholder="https://twitter.com/yourname" />
        )}
      </Form.Item>
      <SeoEntryForm form={form} type={SeoEntryType.AUTHOR} id={author.AuthorID} />
      <Form.Item label="Hoạt động">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: isNullOrUndefined(author.Enabled) ? true : author.Enabled,
        })(
          <Checkbox />
        )}
      </Form.Item>
    </Form>
  );
}
