import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import widgetApi from 'core/engine/widget/api';
import { WidgetSearchOptions } from 'core/engine/widget/constants';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { useMemo } from 'react';
import { widgetRegistry } from '../registry';
import WidgetFormDialogButton from './WidgetFormDialogButton';
import WidgetList from './WidgetList';

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return widgetApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

export default function WidgetListView() {
  const listProps = useList({
    listFn,
    listId: 'WidgetList',
    autoLoad: true,
    defaultFilters: {
      search: { type: WidgetSearchOptions[0].value },
      PreDefined: true,
    },
    defaultSorting: {
      CreatedDate: 'DESC',
    },
  });

  const availableWidgets = useMemo(() => {
    return widgetRegistry.getAll();
  }, []);

  const handleSearchFilter = useCreateOnFilterChange(listProps, 'search');

  return (
    <Container pageTitle="Danh sách Widget">
      <TableToolbarWrapper>
        <WidgetFormDialogButton
          onDone={listProps.refresh}
          availableWidgets={availableWidgets}
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={WidgetSearchOptions}
          search={listProps.filters.search}
          onSearch={handleSearchFilter}
          autoFocus
        />
      </TableToolbarWrapper>
      <WidgetList
        {...useListProps(listProps)}
        heightAdjust={42}
        onUpdated={listProps.refresh}
        onDelete={handleDelete}
      />
    </Container>
  );

  function handleDelete(widget) {
    return asyncAction('Xóa widget', () => {
      return widgetApi.delete(widget.WidgetID)
        .then(() => {
          listProps.removeItem(widget, 'WidgetID');
        });
    });
  }
}
