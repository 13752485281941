import React, { forwardRef } from 'react';
import { Input, Icon } from 'antd';
import OnBlurInput from './OnBlurInput';

export default forwardRef(function LinkInput({
  value,
  onChange,
  withAddon = true,
  onBlurInput = true,
  ...props
}, ref) {
  const ComponentInput = onBlurInput ? OnBlurInput : Input;

  return (
    <ComponentInput
      ref={ref}
      value={value}
      onChange={onChange}
      type="url"
      addonAfter={(withAddon && value) ? (
        <a href={value} title="Mở link" target="_blank" rel="noopener noreferrer" >
          <Icon style={{ transform: 'rotate(90deg)' }} type="select" />
        </a>
      ) : null}
      {...props}
    />
  );
});
