import { Config, ToolbarIcon } from '@zdocs/core';
import FileManagerDialog from 'core/components/file-manager/FileManagerDialog';
import { FileType } from 'core/engine/file/constants';
import fileService from 'core/engine/file/service';
import BaseDialogPlugin from 'core/components/editor/plugins/BaseDialogPlugin';
import React from 'react';
import icon from './icon.svg';
import lodash from 'core/common/lodash';

export default class AddFilePlugin extends BaseDialogPlugin {

  static install() {
    ToolbarIcon.setIcon('AddFile', icon);

    Config.prototype.controls.file = {
      tooltip: 'Chèn tệp',
      icon: 'AddFile',
    };
  }

  get name() {
    return 'AddFilePlugin';
  }

  get command() {
    return 'file';
  }

  get defaultOptions() {
    return {
      template: '<a data-file-id="$FileId" href="$Href" download>$Text</a>',
    };
  }

  renderDialog(props) {
    return (
      <FileManagerDialog
        focusTriggerAfterClose={false}
        title="Chèn tệp"
        multiple
        options={this.options.buttons}
        fileType={FileType.FILE}
        grid={false}
        {...props}
      />
    );
  }

  onSelect(files, buttonId) {
    super.onSelect();

    const button = lodash.keyBy(this.options.buttons, 'id')[buttonId];
    const selection = this.zdocs.selection;
    const template = button ? button.template : this.options.template;

    const html = files.map(file => template
      .replace('$FileId', file.FileID)
      .replace('$Href', fileService.buildDirectUrl(file))
      .replace('$Text', selection.isCollapsed() ? file.Name : selection.sel)).join('<br />');

    this.insertHTMLToEditor(html, true, false);
  }
}
