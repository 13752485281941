import React, { useState, useEffect } from 'react';
import { parseQuery } from 'core/common/urlHelper';
import { useLocation } from 'react-router-dom';
import Container from '../common/Container';
import otpApi from 'core/engine/otp/api';
import { Row, Col } from 'antd';

export default function OTPView() {

  const location = useLocation();
  const username = parseQuery(location.search).user;
  const timestamp = parseQuery(location.search).timestamp;
  const checksum = parseQuery(location.search).checksum;

  const [qrCode, setQRCode] = useState('');
  const [accountName, setAccountName] = useState('');
  const [secretKey, setSecretKey] = useState('');

  useEffect(() => {
    otpApi.getQRCode(username, timestamp, checksum).then((resp) => {
      setQRCode(resp.data.item.qrCode);
      setAccountName(resp.data.item.accountName);
      setSecretKey(resp.data.item.secretKey);
    });
  }, [username, timestamp, checksum]);

  return (
    <Container>
      <Row style={{ marginTop: 50 }}>
        <Col span={14} offset={10}>
          <p> <img width="300" alt="" height="300" src={qrCode} /></p>
        </Col>
      </Row>
      <Row>
        <Col span={14} offset={10}>
          <p>{'AccountName: '}<strong>{accountName}</strong></p>
        </Col>
      </Row>
      <Row>
        <Col span={14} offset={10}>
          <p >{'SecretKey: '}<strong>{secretKey}</strong></p>
        </Col>
      </Row>
      <Row>
        <Col span={14} offset={10}>
          <p >Scan qrcode or config manually account in your mobile!</p>
        </Col>
      </Row>
    </Container>
  );
}
