import { Config } from '@zdocs/core';
import BaseDialogPlugin from '../BaseDialogPlugin';
import React from 'react';
import EmbedDialog from './EmbedDialog';

export default class EmbedPlugin extends BaseDialogPlugin {

  static install() {
    Config.prototype.controls.embed = {
      tooltip: 'Chèn mã nhúng',
      icon: 'embed',
    };
  }

  get name() {
    return 'embed';
  }

  get command() {
    return 'embed';
  }

  get defaultOptions() {
    return {
      template: '$embedCode',
    };
  }

  renderDialog(props) {
    return (
      <EmbedDialog
        title="Mã nhúng"
        {...props}
      />
    );
  }

  onSelect(embedCode) {
    super.onSelect();

    if (embedCode) {
      this.insertHTMLToEditor(this.options.template.replace('$embedCode', embedCode), false, false);
    }
  }
}
