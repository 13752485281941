import React from 'react';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import DeleteButton from 'core/components/common/DeleteButton';
import { InterviewAnswerStatusLabel } from 'core/engine/interview-answer/constants';
import InterviewAnswerFormDialogButton from './InterviewAnswerFormDialogButton';
import CheckableTable from 'core/components/table/CheckableTable';
import BaseTable from 'core/components/table/BaseTable';
import { commonColumns } from 'core/common/listUtils';
import striptags from 'striptags';
import HtmlDisplay from 'core/components/common/HtmlDisplay';
import { stripXss } from 'core/common/string';

function stripAnswer(value, max) {
  const stripValue = striptags(stripXss(value), [], ' ');
  return stripValue.length > max ? stripValue.substring(0, max) + '...' : stripValue;
}

export default function InterviewAnswerList({
  isCheckable = true,
  selectedIds,
  onSelectedChange,
  interview,
  onUpdate,
  onDelete,
  ...props
}) {
  const columns = [
    {
      show: isCheckable,
      Header: 'Câu hỏi',
      accessor: 'InterviewQuestion.Question',
      Cell: ({ value }) => <HtmlDisplay html={value} />,
      minWidth: 150,
    },
    {
      show: isCheckable,
      Header: 'Người trả lời',
      accessor: 'InterviewGuest.Name',
      minWidth: 150,
      maxWidth: 300,
    },
    {
      Header: 'Nội dung',
      accessor: 'HtmlAnswer',
      minWidth: 300,
      Cell: ({ value }) => (
        <HtmlDisplay html={stripAnswer(value, 500)} />
      ),
    },
    {
      Header: 'Trạng thái',
      accessor: 'Status',
      Cell: ({ value }) => (InterviewAnswerStatusLabel[value]),
      width: 130,
      ...commonColumns.allCentered,
    },
    {
      Header: 'Thời gian',
      accessor: 'Date',
      Cell: ({ value }) => (<DateTimeDisplay timestamp={value} />),
      maxWidth: 150,
      minWidth: 120,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <InterviewAnswerFormDialogButton onDone={onUpdate} interview={interview} answer={original} />
          {onDelete ? (
            <DeleteButton
              title={`Xóa câu trả lời: ${stripAnswer(original.HtmlAnswer, 50)}?`}
              onDelete={() => onDelete(original.AnswerID)}
            />
          ) : null}
        </ActionsCellWrapper>
      ),
      width: onDelete ? 100 : 50,
    },
  ];

  const TableComponent = isCheckable ? CheckableTable : BaseTable;

  return (
    <TableComponent
      selectOnLeft
      idField="AnswerID"
      columns={columns}
      selectedIds={selectedIds}
      onSelectedChange={onSelectedChange}
      {...props}
    />
  );
}
