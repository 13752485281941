import { Button, Tabs } from 'antd';
import React, { useEffect, useRef, useState, Fragment } from 'react';
import makeStyles from 'core/common/makeStyles';
import BaseDialog from 'core/components/common/BaseDialog';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import accountApi from 'core/engine/account/api';
import groupApi from 'core/engine/group/api';
import useAsyncAction from 'core/hooks/useAsyncAction';
import useDialog from 'core/hooks/useDialog';
import AccountCommandTable from './AccountCommandTable';
import AccountGroupsCheckbox from './AccountGroupsCheckbox';
import AccountGroupsTable from './AccountGroupsTable';

const { TabPane } = Tabs;

export default function AccountRightsDialogButton({ account }) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const { open, handleOpen, handleClose } = useDialog();
  const { performAction, isLoading: isSubmitting } = useAsyncAction();

  const [customByZoneGroups, setCustomByZoneGroups] = useState([]);
  const [openForAllGroups, setOpenForAllGroups] = useState([]);

  const [customByZoneAccountGroups, setCustomByZoneAccountGroups] = useState([]);
  const [openForAllAccountGroups, setOpenForAllAccountGroups] = useState([]);

  const accountCommandsRef = useRef();
  const accountGroupsTableRef = useRef();
  const accountGroupsCheckboxRef = useRef();

  useEffect(() => {
    if (open) {
      setIsLoading(true);

      Promise.all([
        groupApi.getListAll().then(resp => resp.data.items),
        accountApi.getGroups(account.AccountID).then(resp => resp.data),
      ])
        .then(([groups, accountGroups]) => {
          const customByZoneGroups = groups.filter(group => group.CustomByZone);
          const openForAllGroups = groups.filter(group => !group.CustomByZone);

          setCustomByZoneGroups(customByZoneGroups);
          setOpenForAllGroups(openForAllGroups);

          setCustomByZoneAccountGroups(filterAccountGroups(customByZoneGroups, accountGroups));
          setOpenForAllAccountGroups(filterAccountGroups(openForAllGroups, accountGroups));

          setIsLoading(false);
        });
    }
  }, [open]);

  return (
    <Fragment>
      <Button
        title="Phân quyền"
        icon="safety"
        onClick={handleOpen}>
      </Button>
      <BaseDialog
        fullHeightFixed
        paddingTop={0}
        paddingBottom={16}
        visible={open}
        title={'Phân quyền tài khoản: ' + account.Username}
        onCancel={handleClose}
        onOk={onOk}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
      >
        <LoadingWrapper loading={isLoading}>
          <Tabs className={classes.tab}>
            <TabPane tab="Phân quyền theo chuyên mục" key="groups-by-zone">
              <AccountGroupsTable groups={customByZoneGroups} accountGroups={customByZoneAccountGroups} ref={accountGroupsTableRef} />
            </TabPane>
            <TabPane tab="Phân quyền không theo chuyên mục" key="groups">
              <AccountGroupsCheckbox groups={openForAllGroups} accountGroups={openForAllAccountGroups} ref={accountGroupsCheckboxRef} />
            </TabPane>
            <TabPane tab="Chức năng mở rộng" key="commands">
              <AccountCommandTable accountId={account.AccountID} ref={accountCommandsRef} />
            </TabPane>
          </Tabs>
        </LoadingWrapper>
      </BaseDialog>
    </Fragment>
  );

  function onOk() {
    return performAction('Phân quyền tài khoản', () => {
      const accountCommands = accountCommandsRef.current && accountCommandsRef.current.getPermissions();
      const customByZoneAccountGroupsData = accountGroupsTableRef.current && accountGroupsTableRef.current.getData();
      const openForAllAccountGroupsData = accountGroupsCheckboxRef.current && accountGroupsCheckboxRef.current.getData();
      let accountGroups;

      if (customByZoneAccountGroupsData || openForAllAccountGroupsData) {
        accountGroups = [
          ...(customByZoneAccountGroupsData || customByZoneAccountGroups),
          ...(openForAllAccountGroupsData || openForAllAccountGroups),
        ];
      }

      return Promise.all([
        accountCommands && accountApi.setCommandRoles(account.AccountID, accountCommands),
        accountGroups && accountApi.setGroups(account.AccountID, accountGroups),
      ])
        .then(() => handleClose());
    });
  }
}

const useStyles = makeStyles(() => ({
  tab: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > .ant-tabs-content': {
      height: 'calc(100% - 60px)',
    },
  },
}));

function filterAccountGroups(groups, accountGroups) {
  const groupIds = groups.map(group => group.GroupID);
  return accountGroups.filter(item => groupIds.includes(item.GroupID));
}
