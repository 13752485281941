import React, { Fragment, useEffect } from 'react';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import AccountSelector from 'core/components/account/AccountSelector';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateWithTypeSelector from 'core/components/common/RangeDateWithTypeSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import useContentColumns from 'core/hooks/useContentColumns';
import SelectableTable from 'core/components/table/SelectableTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import contentApi from 'core/engine/content/api';
import { ContentSearchOptions } from 'core/engine/content/constants';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import useContentFilterHandlers from 'core/modules/contents/hooks/useContentFilterHandlers';
import lodash from 'core/common/lodash';
import { TopicType } from 'core/engine/topic/constants';
import TopicSelector from 'core/components/topic/TopicSelector';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import { Sites } from 'core/engine/site/constants';

const defaultRangeDate = [null, null];

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };
  const rangeDate = filters.rangeDate;
  const listSorting = { [rangeDate.type]: rangeDate.desc ? 'DESC' : 'ASC', ...sorting };

  if (rangeDate.range[0] && rangeDate.range[1]) {
    listFilters[rangeDate.type] = dateRangeToFilter(rangeDate.range);
  }

  delete listFilters.rangeDate;

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return contentApi.getDeployedList({
    limit,
    offset,
    sortBy: sortingToSortBy(listSorting),
    fields: ['Description', 'ViewCount'],
    filters: listFilters,
  });
}

export default function SelectContentTab({ value, onChange, hideToolbar, siteId = Sites.ALL }) {
  const listProps = useList({
    listId: 'RelatedContentList',
    listFn,
    defaultPageSize: 20,
    defaultFilters: {
      SiteID: siteId,
      search: { type: ContentSearchOptions[0].value },
      rangeDate: { type: 'DistributionDate', range: defaultRangeDate, desc: true },
    },
  });
  const ContentColumns = useContentColumns();

  const columns = [
    ContentColumns.Avatar,
    ContentColumns.Title,
    ContentColumns.DistributionDate,
    ContentColumns.ViewCount,
  ];

  const filterHandlers = useContentFilterHandlers(listProps);
  const handleFilterTopic = useCreateOnFilterChange(listProps, 'TopicID');

  useEffect(() => {
    setTimeout(listProps.refresh, 300); // after tab transition
  }, []);

  return (
    <Fragment>
      <TableToolbarWrapper hidden={hideToolbar}>
        <ZoneSelector
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
          siteId={siteId}
          allowClear
        />
        <AccountSelector
          emptyLabel="-- Người tạo --"
          value={listProps.filters.WriterID}
          onChange={filterHandlers.onWriterFilter}
          allowClear
        />
        <FlexPushRight />
        <RangeDateWithTypeSelector
          value={listProps.filters.rangeDate}
          options={{ CreatedDate: 'Ngày tạo', ModifiedDate: 'Ngày sửa', DistributionDate: 'Ngày đăng' }}
          onChange={filterHandlers.onRangeDateFilter}
        />
      </TableToolbarWrapper>
      <TableToolbarWrapper hidden={hideToolbar}>
        <TopicSelector
          emptyLabel="-- Chủ đề --"
          value={listProps.filters.TopicID}
          onChange={handleFilterTopic}
          topicType={TopicType.NORMAL}
          allowClear
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={ContentSearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
      </TableToolbarWrapper>
      <SelectableTable
        {...useListProps(listProps)}
        idField="ContentID"
        onSelect={onSelect}
        onUnselect={onUnselect}
        columns={columns}
        selected={value}
        heightAdjust={hideToolbar ? 0 : 84}
      />
    </Fragment>
  );

  function onSelect(item) {
    if (lodash.findIndex(value, { ContentID: item.ContentID }) === -1) {
      onChange([...value, item]);
    }
  }

  function onUnselect(item) {
    const newValue = value.slice();
    lodash.remove(newValue, { ContentID: item.ContentID });
    onChange(newValue);
  }
}
