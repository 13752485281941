import React from 'react';
import { Sites } from 'core/engine/site/constants';
import ContentFormRelatedTopicsInput from './ContentFormRelatedTopicsInput';
import { Form } from 'antd';

export default function ContentFormItemRelatedTopics({ form, content, siteId = Sites.ALL }) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item>
      {getFieldDecorator('RelatedTopics', {
        initialValue: content.RelatedTopics,
      })(
        <ContentFormRelatedTopicsInput siteId={siteId} />
      )}
    </Form.Item>
  );

}
