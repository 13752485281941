import { Select } from 'antd';
import React, { forwardRef, useState, useEffect, useMemo } from 'react';
import { buildTreeList } from 'core/common/utils';
import zoneApi from 'core/engine/zone/api';
import { emptyArray } from 'core/common/empty';

const { Option } = Select;

export default forwardRef(function ZoneFormParentZoneInput({
  value,
  onChange,
  allowEmpty,
  extendItems = emptyArray,
  excludedIds = emptyArray,
  excludeDescendant = true,
  ...props
}, ref) {
  const [zones, setZones] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const displayZones = useMemo(() => {
    let descendantIds = [];

    if (excludeDescendant) {
      excludedIds.forEach((id) => {
        descendantIds.push(id);
        descendantIds.push(...getDescendantIds(zones, id));
      });
    } else {
      descendantIds = excludedIds;
    }

    return zones.filter(zone => descendantIds.indexOf(zone.ZoneID) === -1);
  }, [excludeDescendant, zones, excludedIds]);

  useEffect(() => {
    if (open) {
      loadOptions();
    }
  }, [open]);

  useEffect(loadOptions, []);

  return (
    <Select
      ref={ref}
      showSearch
      onChange={value => onChange(parseInt(value))}
      style={{ width: '100%' }}
      value={value}
      open={open}
      onDropdownVisibleChange={setOpen}
      loading={isLoading}
      optionFilterProp="children"
      {...props}
    >
      {allowEmpty && <Option key="0" value={0} style={{ fontStyle: 'italic' }}>-- Mục gốc --</Option>}
      {extendItems.map((item) => (
        <Option key={item.value} value={item.value}>{item.text}</Option>
      ))}

      {displayZones.map((zone) => (
        <Option key={zone.ZoneID} value={zone.ZoneID}>{' ---- '.repeat(zone.Level) + zone.Name}</Option>
      ))}
    </Select>
  );

  function loadOptions() {
    setZones([]);
    setIsLoading(true);

    zoneApi.getListAll()
      .then((resp) => {
        return buildTreeList(resp.data.items, { idField: 'ZoneID' });
      })
      .then(setZones)
      .then(() => setIsLoading(false));
  }
});

function getDescendantIds(zones, zoneId) {
  const descendantIds = [];
  const toScanIds = [zoneId];

  while (toScanIds.length > 0) {
    const currentId = toScanIds.shift();
    const childrenIds = zones.filter(zone => zone.ParentID === currentId).map(child => child.ZoneID);

    descendantIds.push(...childrenIds);
    toScanIds.push(...childrenIds);
  }

  return descendantIds;
}
