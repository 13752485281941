import { httpGet, httpPost } from 'core/common/http-client';

const epaperApi = {
  getList({ limit = 20, offset = 0, sortBy, fields = [], filters }) {
    const qs = { limit, offset, sortBy, fields, ...filters };
    return httpGet('/epapers/get/items-by-range', { qs });
  },

  create(data) {
    return httpPost('/epapers/post/insert', data);
  },

  update(data) {
    return httpPost('/epapers/post/update', data);
  },

  delete(id) {
    return httpPost('/epapers/post/delete', { EPaperID: id });
  },
};

export default epaperApi;
