import { Config, ToolbarIcon } from '@zdocs/core';
import BaseDialogPlugin from 'core/components/editor/plugins/BaseDialogPlugin';
import React from 'react';
import InterviewDialog from './InterviewDialog';
import icon from './icon.svg';

export default class InterviewPlugin extends BaseDialogPlugin {
  static install() {
    ToolbarIcon.setIcon('Interview', icon);

    Config.prototype.controls.interview = {
      tooltip: 'Chèn giao lưu',
      icon: 'Interview',
    };
  }

  get name() {
    return 'interview';
  }

  get command() {
    return 'interview';
  }

  get defaultOptions() {
    return {
      template: '<interview interviewId="$Id"><strong>Giao lưu trực tuyến</strong><hr>$Title</interview>',
    };
  }

  renderDialog(props) {
    return (
      <InterviewDialog
        title="Chèn giao lưu trực tuyến"
        {...props}
      />
    );
  }

  onSelect(select) {
    super.onSelect();

    if (select.InterviewID) {
      this.insertHTMLToEditor(
        this.options.template
          .replace('$Id', select.InterviewID)
          .replace('$Title', select.Title),
        false, true);
    }
  }
}
