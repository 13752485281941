import { insertButtonAfter } from 'core/common/editor';
import { EditorHookAction } from 'core/components/editor/constants';
import actionRegistry from 'core/registries/actions';
import routesRegistry from 'core/registries/routes';
import routes from './routes';

export {
  start,
};

function start() {
  routesRegistry.registerMultiple(routes);
  actionRegistry.addAction(EditorHookAction.BEFORE_RENDER_EDITOR, beforeRenderEditor);
}

function beforeRenderEditor({ options }) {
  insertButtonAfter(options.buttons, 'vote', 'interview');
}
