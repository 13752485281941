import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

export default function InterviewAnswerButtonForList({ interviewId, disabled }) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push({
      pathname: '/operations/interviews/answers',
      search: `?interviewId=${interviewId}`,
    });
  }, [interviewId]);

  return (
    <Button title="Duyệt trả lời" icon="check" onClick={handleClick} disabled={disabled} />
  );
}
