import Selector from 'core/components/common/Selector';
import React, { forwardRef, useEffect, useState } from 'react';

export default forwardRef(function WidgetPositionSelector({ availablePositions = [], ...props }, ref) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const options = availablePositions.map((position) => ({
      value: position.id,
      label: position.name,
    }));

    setOptions(options);
  }, [availablePositions]);

  return (
    <Selector
      ref={ref}
      options={options}
      valueIsNumber
      {...props}
    />
  );
});
