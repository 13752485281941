import { Button, Checkbox, Form, Input, InputNumber } from 'antd';
import React, { useEffect, Fragment, useState, useContext } from 'react';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import DeleteButton from 'core/components/common/DeleteButton';
import FlexDiv from 'core/components/common/FlexDiv';
import FlexPushRight from 'core/components/common/FlexPushRight';
import useFormHandler from 'core/hooks/useFormHandler';
import ZoneFormParentZoneInput from './ZoneFormParentZoneInput';
import SeoEntryForm from 'core/components/seo-entry/SeoEntryForm';
import { SeoEntryType } from 'core/engine/seo-entry/constants';
import seoEntryApi from 'core/engine/seo-entry/api';
import ImagePickerInput from 'core/components/common/ImagePickerInput';
import { CustomComponentContext } from 'core/components/layout';

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 8,
  },
}));

export function ZoneFormRaw({ form, zone, onSubmit, submitOnEnter = true }) {
  const customComponentContext = useContext(CustomComponentContext);

  const { getFieldDecorator, resetFields } = form;
  const [seoEntry, setSeoEntry] = useState({});

  useEffect(() => resetFields(), [zone]);

  useEffect(() => {
    if (!zone.ZoneID) {
      setSeoEntry({});
    }

    seoEntryApi.get(SeoEntryType.ZONE, zone.ZoneID)
      .then(resp => setSeoEntry(resp.data.item))
      .catch(() => setSeoEntry({}));
  }, [zone.ZoneID]);

  if (customComponentContext && customComponentContext.ExtZoneFormRaw) {
    return (
      <customComponentContext.ExtZoneFormRaw
        form={form}
        zone={zone}
        onSubmit={onSubmit}
        submitOnEnter={submitOnEnter}
      />
    );
  }

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      {zone.ZoneID && (
        <Form.Item label="ID">
          {getFieldDecorator('ZoneID', {
            initialValue: zone.ZoneID,
          })(
            <Input disabled />
          )}
        </Form.Item>
      )}
      <Form.Item label="Tên">
        {getFieldDecorator('Name', {
          initialValue: zone.Name,
          rules: [{ required: true, message: 'Bắt buộc' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item label="Slug">
        {getFieldDecorator('Slug', {
          initialValue: zone.Slug || '',
          rules: [
            { required: true, message: 'Bắt buộc' },
            { pattern: /^[a-zA-Z0-9-/]+$/, message: 'Chỉ được phép dùng các kí tự không dấu, số và dấu gạch ngang, gạch chéo xuôi' },
          ],
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item label="Mục cha">
        {getFieldDecorator('ParentID', { initialValue: zone.ParentID || 0 })(
          <ZoneFormParentZoneInput allowEmpty excludedIds={zone.ZoneID ? [zone.ZoneID] : []} />
        )}
      </Form.Item>
      <Form.Item label="URL">
        {getFieldDecorator('Url', {
          initialValue: zone.Url || '',
          rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <Input placeholder="URL" />
        )}
      </Form.Item>
      <Form.Item label="Ảnh đại diện">
        {getFieldDecorator('SeoEntry.ImageUrl', {
          initialValue: seoEntry.ImageUrl || '',
          rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <ImagePickerInput onChange={(value) => setSeoEntry({ ...seoEntry, ImageUrl: value })} />
        )}
      </Form.Item>

      <SeoEntryForm form={form} type={SeoEntryType.ZONE} id={zone.ZoneID} defaultValue={zone.SeoEntry} />

      <Form.Item label="Thứ tự">
        {getFieldDecorator('Order', {
          initialValue: zone.Order || 0,
        })(
          <InputNumber />
        )}
      </Form.Item>
      <Form.Item label="Sử dụng">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: zone.Enabled,
        })(
          <Checkbox />
        )}
      </Form.Item>
      <Form.Item label="Hiển thị">
        {getFieldDecorator('Visible', {
          valuePropName: 'checked',
          initialValue: zone.Visible,
        })(
          <Checkbox />
        )}
      </Form.Item>
      {submitOnEnter && <Button htmlType="submit" style={commonStyles.hidden} />}
    </Form>
  );
}

function ZoneForm({
  form,
  zone,
  submitFn,
  onDelete,
  onCancel,
}) {
  const classes = useStyles();
  const { handleSubmit, handleReset, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <Fragment>
      <ZoneFormRaw form={form} zone={zone} onSubmit={handleSubmit} />
      <Form.Item {...commonStyles.tailFormItemLayout}>
        <FlexDiv>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={!form.isFieldsTouched()}
            title={zone.ZoneID ? 'Lưu' : 'Tạo'}
          >
            {zone.ZoneID ? 'Lưu' : 'Tạo'}
          </Button>
          <Button className={classes.button} onClick={handleReset} disabled={isSubmitting} title="Đặt lại">
            Đặt lại
          </Button>
          {zone.ZoneID &&
          <Button className={classes.button} onClick={onCancel} title="Tạo mới">Tạo mới</Button>}
          {zone.ZoneID &&
          <Fragment>
            <FlexPushRight />
            <DeleteButton
              title={`Xóa chuyên mục: ${zone.Name}?`}
              onDelete={onDelete}
            />
          </Fragment>
          }
        </FlexDiv>
      </Form.Item>
    </Fragment>
  );
}

export default Form.create({ name: 'ZoneForm' })(ZoneForm);
