import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import makeStyles from 'core/common/makeStyles';
import { Input } from 'antd';
import HtmlDisplay from 'core/components/common/HtmlDisplay';

const useStyles = makeStyles(() => ({
  textarea: {
    minWidth: 400,
  },
  preview: {
    textAlign: 'center',
    marginTop: 10,
    border: '1px solid #e5e5e5',
    padding: 10,
    borderRadius: 3,
  },
}));

export default forwardRef(function Dialog({ title, onSelect, onCancel }, ref) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const { open, handleClose, handleOpen } = useDialog();

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  const embedCode = useMemo(() => {
    return /^https?:\/\//.test(value) ? `<iframe src="https://www.facebook.com/plugins/video.php?href=${escape(value)}&show_text=false" width="100%" height="300" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency></iframe>` : value;
  }, [value]);

  return (
    <BaseDialog
      focusTriggerAfterClose={false}
      keyboard
      maskClosable
      visible={open}
      title={title}
      width="auto"
      okText="Chèn"
      okButtonProps={{ disabled: !embedCode }}
      onCancel={onCancel}
      onOk={() => onSelect(embedCode)}
      fullHeight
    >
      <Input.TextArea
        className={classes.textarea}
        placeholder="Nhập mã nhúng hoặc URL..."
        name="embedCode"
        rows={4}
        value={value}
        onChange={e => setValue(e.target.value)}
        autoFocus
      />
      {embedCode && (
        <HtmlDisplay className={classes.preview} html={embedCode} />
      )}
    </BaseDialog>
  );
});
