import React, { useEffect, useMemo, memo } from 'react';
import makeStyles from 'core/common/makeStyles';
import useDrag from 'core/components/dialog/hooks/useDrag';
import useResize from 'core/components/dialog/hooks/useResize';
import lodash from 'core/common/lodash';
import useDraggableDialog from 'core/components/dialog/hooks/useDraggableDialog';
import BaseDialog from 'core/components/common/BaseDialog';

const useStyles = makeStyles(() => ({
  draggableWrapper: {
    pointerEvents: 'none',
    overflow: 'hidden !important',

    '& .ant-modal': {
      display: 'flex',
      maxWidth: 'none',
      transformOrigin: '50% 50% !important',
    },

    '& .ant-modal-content': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      border: '2px solid black',
    },

    '& .ant-modal-header': {
      flex: 0,
    },

    '& .ant-modal-footer': {
      flex: 0,
    },

    '& .ant-modal-body': {
      flex: 1,
      overflowY: 'auto',
    },
  },

  draggableTitle: {
    cursor: 'move',
  },

  resizeHandle: {
    right: -10,
    width: 44,
    bottom: -10,
    cursor: 'se-resize',
    height: 44,
    position: 'absolute',
  },
  resizeHandleInner: {
    width: 12,
    right: 14,
    border: '2px solid grey',
    height: 12,
    bottom: 14,
    position: 'absolute',
    borderTop: 0,
    borderLeft: 0,
  },
}));

const DraggableDialog = ({
  children,
  title,
  visible,
  width: initWidth,
  height: initHeight,
  ...props
}) => {
  const classes = useStyles();
  const {
    x, y, width, height, onMount, onDragModal, onResizeModal, onWindowResize,
  } = useDraggableDialog({
    width: initWidth,
    height: initHeight,
    visible,
  });

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    const onWindowResizeDebounce = lodash.debounce(onWindowResize, 100);
    window.addEventListener('resize', onWindowResizeDebounce);
    onWindowResize();

    return () => window.removeEventListener('resize', onWindowResizeDebounce);
  }, [visible]);

  const modalStyle = useMemo(() => {
    const style = { margin: 0, pointerEvents: 'auto' };

    Object.assign(style, {
      top: y,
      left: x,
      height,
    });

    return style;
  }, [x, y, height]);

  const onMouseDrag = useDrag(x, y, onDragModal);
  const onMouseResize = useResize({ x, y, width, height, onResize: onResizeModal });

  const titleElement = useMemo(() => (
    <div
      className={classes.draggableTitle}
      onMouseDown={onMouseDrag}
    >
      {title}
    </div>
  ), [onMouseDrag, title]);

  return (
    <BaseDialog
      wrapClassName={classes.draggableWrapper}
      visible={visible}
      title={titleElement}
      style={modalStyle}
      width={width}
      mask={false}
      centered={false}
      {...props}
    >
      {children}
      <div className={classes.resizeHandle} onMouseDown={onMouseResize}>
        <div className={classes.resizeHandleInner} />
      </div>
    </BaseDialog>
  );
};

export default memo(DraggableDialog);
