import { httpGet, httpPost } from 'core/common/http-client';

const liveApi = {
  get(id) {
    return httpGet(`/lives/get/item?LiveID=${id}`);
  },

  getList({ limit = 20, offset = 0, sortBy, filters }) {
    const qs = {
      limit,
      offset,
      sortBy,
      ...filters,
    };

    return httpGet('/lives/get/items-by-range', { qs });
  },

  create(data) {
    return httpPost('/lives/post/insert', data);
  },

  update(data) {
    return httpPost('/lives/post/update', data);
  },

  delete(id) {
    return httpPost('/lives/post/delete', { LiveID: id });
  },
};

export default liveApi;
