import { green, red } from '@ant-design/colors';
import classnames from 'core/common/classnames';
import makeStyles from 'core/common/makeStyles';
import { countChar, countCharAfterStripHtml, countWord, countWordAfterStripHtml } from 'core/common/string';
import { ALLOW_INPUT_OVERFLOW, CONTENT_INPUT_SHOW_WORD_COUNT } from 'core/config';
import useServerConfig from 'core/hooks/useServerConfig';
import useSyncRef from 'core/hooks/useSyncRef';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles(() => ({
  length: {
    float: 'right',
    lineHeight: 'normal',
    fontSize: 'small',
    marginBottom: 5,
  },
  valid: {
    color: green[7],
  },
  invalid: {
    color: red.primary,
  },
}));

export const AwareInputTypes = {
  CHAR: 'char',
  WORD: 'word',
};

export const AwareInputTypeLabels = {
  [AwareInputTypes.CHAR]: 'Ký tự',
  [AwareInputTypes.WORD]: 'Từ',
};

export default forwardRef(function ContentFormLengthAwareInput({
  value,
  onChange,
  Component,
  maxLength,
  maxLengthType,
  richTextEditor = false,
  showWordCount = CONTENT_INPUT_SHOW_WORD_COUNT,
  editorRef,
  ...props
}, ref) {
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState();
  const valueRef = useSyncRef(value);
  const config = useServerConfig();

  const awareInputType = useMemo(() => maxLengthType || config.LimitedInputType || AwareInputTypes[0], [maxLengthType, config]);

  const charCount = useMemo(() => {
    return richTextEditor ? countCharAfterStripHtml(internalValue) : countChar(internalValue);
  }, [internalValue, richTextEditor]);

  const wordCount = useMemo(() => {
    return richTextEditor ? countWordAfterStripHtml(internalValue) : countWord(internalValue);
  }, [internalValue, showWordCount, richTextEditor]);

  const countToCompare = useMemo(() => {
    return awareInputType === AwareInputTypes.CHAR ? charCount : wordCount;
  }, [awareInputType, charCount, wordCount]);

  const handleChange = useCallback((eventOrValue) => {
    const changeValue = eventOrValue.target ? eventOrValue.target.value : eventOrValue;

    if (!richTextEditor && !ALLOW_INPUT_OVERFLOW && countToCompare > maxLength && awareInputType === AwareInputTypes.CHAR) {
      setInternalValue(changeValue.slice(0, parseInt(maxLength)));
    } else {
      setInternalValue(changeValue);
    }
  }, [maxLength]);

  const handleBlur = useCallback(() => {
    if (valueRef.current !== internalValue) {
      onChange(internalValue);
    }
  }, [onChange, internalValue]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <div ref={ref}>
      {richTextEditor ? (
        <Component value={value} onValueChange={handleChange} editorRef={editorRef} {...props} />
      ) : (
        <Component value={internalValue} onChange={handleChange} onBlur={handleBlur} {...props} />
      )}
      {!!parseInt(maxLength) && (
        <span
          className={classnames(classes.length, maxLength > 0 && countToCompare > maxLength ? classes.invalid : classes.valid)}
        >
          {showWordCount ? `${wordCount} từ` :
            countToCompare <= maxLength ?
              `Còn ${maxLength - countToCompare} ${AwareInputTypeLabels[awareInputType].toLowerCase()}`
              :
              `Thừa ${countToCompare - maxLength} ${AwareInputTypeLabels[awareInputType].toLowerCase()}`
          }
        </span>
      )}
    </div>
  );
});
