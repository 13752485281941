import React from 'react';
import { CONTENT_CLASS } from 'core/constants';
import classnames from 'core/common/classnames';
import HtmlDisplay from 'core/components/common/HtmlDisplay';

export default function ContentBodyDisplay({ className, content }) {
  return (
    <HtmlDisplay
      className={classnames(className, CONTENT_CLASS)}
      html={content}
    />
  );
}
