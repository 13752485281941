import ZDocs from '@zdocs/core';
import BasePlugin from 'core/components/editor/plugins/BasePlugin';

const Dom = ZDocs.modules.Dom;

/**
 * Process command - `formatblock`
 *
 * @param {zdocs} editor
 */
export default function formatBlock(editor) {
  editor.registerCommand(
    'formatblock',
    (command, second, third) => {
      editor.selection.focus();
      let work = false;
      let className = '';
      let wrapEl = null;
      let replaceAndAppend = false;
      let wrapAll = false;
      let addNewLine = false;

      switch (third) {
        case 'notebox_c':
          className = 'notebox ncenter';
          third = 'notebox';
          replaceAndAppend = true;
          wrapAll = true;
          addNewLine = true;
          break;
        case 'notebox_l':
          className = 'notebox nleft';
          third = 'notebox';
          replaceAndAppend = true;
          wrapAll = true;
          addNewLine = true;
          break;
        case 'notebox_r':
          className = 'notebox nright';
          third = 'notebox';
          replaceAndAppend = true;
          wrapAll = true;
          addNewLine = true;
          break;
        case 'blockquote':
          third = 'blockquote';
          replaceAndAppend = true;
          wrapAll = true;
          addNewLine = true;
          break;
        default:
          break;
      }

      editor.selection.eachSelection((current) => {
        const selectionInfo = editor.selection.save();

        let currentBox = current
          ? (Dom.up(
            current,
            node => Dom.isBlock(node, editor.editorWindow),
            editor.editor,
          ))
          : false;

        if ((!currentBox || currentBox.nodeName === 'LI') && current) {
          currentBox = Dom.wrapInline(
            current,
            editor.options.enter,
            editor,
          );
        }

        if (!currentBox || Dom.isEmpty(currentBox)) {
          Dom.safeRemove(currentBox);
          editor.selection.restore(selectionInfo);
          return;
        }

        if (currentBox.tagName.match(/TD|TH|TBODY|TABLE|THEAD/i)) {
          if (editor.selection.isCollapsed()) {
            wrapEl = Dom.wrapInline(current, third, editor);
          } else {
            editor.selection.select(currentBox);
            editor.selection.applyCSS({}, third);
          }
        } else if (
          third === editor.options.enterBlock.toLowerCase() &&
          currentBox.parentNode &&
          currentBox.parentNode.nodeName === 'LI'
        ) {
          Dom.unwrap(currentBox);
        } else if (replaceAndAppend) {
          if (wrapAll) {
            if (!wrapEl) {
              wrapEl = Dom.replace(
                currentBox,
                third,
                false,
                true,
                editor.editorDocument,
              );
            }
          } else {
            wrapEl = Dom.replace(
              currentBox,
              third,
              false,
              true,
              editor.editorDocument,
            );
          }

          wrapEl.appendChild(currentBox);
        } else {
          wrapEl = Dom.replace(
            currentBox,
            third,
            true,
            false,
            editor.editorDocument,
          );

          if (className) {
            wrapEl.removeAttribute('class');
            wrapEl.className = className;
          }
        }

        work = true;
        editor.selection.restore(selectionInfo);
      });

      if (!work) {
        const currentBox = editor.editorDocument.createElement(third);

        currentBox.innerHTML = ZDocs.INVISIBLE_SPACE;
        editor.selection.insertNode(currentBox, false);
        editor.selection.setCursorIn(currentBox);
      }

      if (addNewLine) {
        editor.selection.setCursorAfter(wrapEl);
        editor.selection.insertNode(editor.create.inside.element(editor.options.enter));
      }

      if (wrapEl) {
        wrapEl.removeAttribute('class');

        if (className) {
          wrapEl.className = className;
        }
      }

      editor.setEditorValue();

      BasePlugin.cleanAfterSetCursor(editor, Dom);

      return false;
    },
  );
}
