import { green, yellow } from '@ant-design/colors';
import { Button, Icon } from 'antd';
import { emptyArray } from 'core/common/empty';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import AsyncButton from 'core/components/common/AsyncButton';
import CheckableTable from 'core/components/table/CheckableTable';
import { CommentStatus } from 'core/engine/comment/constants';
import { GroupRoleUp } from 'core/engine/group/constants';
import React from 'react';
import CommentApproverDisplay from './CommentApproverDisplay';
import CommentDisplay from './CommentDisplay';
import CommentFormDialogButton from './CommentFormDialogButton';
import CommentSenderDisplay from './CommentSenderDisplay';

const useStyles = makeStyles(() => ({
  approveIcon: {
    color: green.primary,
  },
  hideIcon: {
    color: yellow[6],
  },
}));

export default function CommentList({
  showApprover = false,
  selectedIds,
  approveZoneIds = emptyArray,
  onSelectedChange,
  onApprove,
  onReject,
  onFilterObjectId,
  onFilterZoneId,
  onFilterUserName,
  onFilterUserEmail,
  onContentChange,
  onChange,
  ...props
}) {
  const classes = useStyles();

  const columns = [
    {
      Header: 'Tác giả',
      maxWidth: 200,
      headerStyle: { justifyContent: 'center' },
      Cell: ({ original }) => (
        <CommentSenderDisplay
          name={original.UserDisplayName}
          email={original.UserEmail}
          createdDate={original.CreatedDate}
          onFilterUserName={onFilterUserName}
          onFilterUserEmail={onFilterUserEmail}
        />
      ),
    },
    {
      Header: 'Bình luận',
      Cell: ({ original }) => (
        <CommentDisplay
          comment={original}
          onFilterObjectId={onFilterObjectId}
          onContentChange={onContentChange}
          onFilterZoneId={onFilterZoneId}
        />
      ),
    },
    {
      Header: 'Người duyệt',
      width: 150,
      show: showApprover,
      Cell: ({ original }) => (
        <CommentApproverDisplay
          approverId={original.ApproverID}
          approvalDate={original.ApprovalDate}
        />
      ),
    },
    {
      Cell: ({ original }) => {
        const disabled = GroupRoleUp.COMMENT_DEPLOYER.length && !approveZoneIds.includes(lodash.get(original, 'Object.ZoneID'));

        return (
          <Button.Group>
            <CommentFormDialogButton comment={original} onDone={onChange} />
            <AsyncButton onClick={() => onApprove(original)} disabled={original.Status === CommentStatus.APPROVED || disabled}>
              <Icon type="arrow-up" className={classes.approveIcon} /> Duyệt
            </AsyncButton>
            <AsyncButton onClick={() => onReject(original)} disabled={original.Status === CommentStatus.DROPPED || disabled}>
              <Icon type="arrow-down" className={classes.hideIcon} /> Ẩn
            </AsyncButton>
          </Button.Group>
        );
      },
      width: 260,
    },
  ];

  return (
    <CheckableTable
      idField="CommentID"
      highlight={false}
      columns={columns}
      selectedIds={selectedIds}
      onSelectedChange={onSelectedChange}
      {...props}
    />
  );
}
