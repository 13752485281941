import { emptyObject } from 'core/common/empty';
import useAuth from 'core/hooks/useAuth';
import { useMemo } from 'react';
import { LiveStreamStatus } from 'core/engine/live-stream/constants';

export function useLiveStreamAuthz(liveStream = emptyObject) {
  const { loggedInUser, hasExtendedRight } = useAuth();
  const canApproveLiveStream = hasExtendedRight('approve-livestream');

  const canViewLog = useMemo(() =>
    loggedInUser.isAdmin || hasExtendedRight('livestream.view-log')
  , [loggedInUser, hasExtendedRight]);

  const isApprover = useMemo(() => {
    return loggedInUser.isAdmin || canApproveLiveStream;
  }, [canApproveLiveStream, loggedInUser]);

  const canEdit = useMemo(() => {
    return isApprover ||
      (liveStream.Status === LiveStreamStatus.DRAFT && liveStream.CreatorID === loggedInUser.id);
  }, [loggedInUser, isApprover, liveStream]);

  const canDelete = useMemo(() => {
    return canEdit && liveStream.Status !== LiveStreamStatus.DELETED;
  }, [canEdit, liveStream]);

  return { canEdit, canDelete, canViewLog, isApprover };
}
