export const LiveStreamStatus = {
  DRAFT: 0,
  APPROVED: 1,
  DELETED: 2,
};

export const LiveStreamStatusLabel = {
  [LiveStreamStatus.DRAFT]: 'Chưa duyệt',
  [LiveStreamStatus.APPROVED]: 'Đã duyệt',
  [LiveStreamStatus.DELETED]: 'Đã xóa',
};

export const LiveStreamSearchOptions = [
  { label: 'Nội dung', value: 'HtmlDetail' },
  { label: 'Tiêu đề', value: 'Title' },
];

export const LiveStreamAction = {
  CHECKIN: 'checkin',
  CHECKOUT: 'checkout',
};
