import useServerConfig from 'core/hooks/useServerConfig';

export default function useContentConfig() {
  const config = useServerConfig();

  return {
    deployedListDefaultDateOrder: config.Content_DeployedListDefaultDateOrder,
    myListDefaultStatusFilter: config.Content_MyListDefaultStatusFilter,
  };
}
