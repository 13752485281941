import { Form } from 'antd';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React from 'react';
import ContentFormItemVideoUrlInput from './ContentFormItemVideoUrlInput';

export default function ContentFormItemVideoUrl({ content, form }) {
  const { getFieldDecorator } = form;

  getFieldDecorator('VideoDuration', { initialValue: content.VideoDuration || 0 });

  return (
    <Form.Item label={ContentFieldLabel.VideoUrl}>
      {getFieldDecorator('VideoUrl', {
        initialValue: content.VideoUrl,
      })(
        <ContentFormItemVideoUrlInput
          form={form}
          duration={form.getFieldValue('VideoDuration')}
          onDurationChange={handleDurationChange}
        />
      )}
    </Form.Item>
  );

  function handleDurationChange(newDuration) {
    form.setFieldsValue({ VideoDuration: newDuration });
  }
}
