import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import locationService from 'core/common/locationService';

const ignorePathNames = [
  '/login',
  '/forbidden',
  '/otp',
  '/contents/compose',
];

export default function useGoBack() {
  const history = useHistory();

  return useCallback(() => {
    const lastLocation = locationService.getLastLocation();

    if (lastLocation && !ignorePathNames.includes(lastLocation.pathname)) {
      history.goBack();
    } else {
      history.replace({ pathname: '/' });
    }
  }, [history]);
}
