import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';

export default function useContentFilterHandlers(listProps) {
  const onZoneFilter = useCreateOnFilterChange(listProps, 'ZoneID');
  const onEditorFilter = useCreateOnFilterChange(listProps, 'EditorID');
  const onWriterFilter = useCreateOnFilterChange(listProps, 'WriterID');
  const onDeployerFilter = useCreateOnFilterChange(listProps, 'DeployerID');
  const onStatusFilter = useCreateOnFilterChange(listProps, 'Status');
  const onContentTypeFilter = useCreateOnFilterChange(listProps, 'ContentType');
  const onDisplayTypeFilter = useCreateOnFilterChange(listProps, 'DisplayType');
  const onSearchFilter = useCreateOnFilterChange(listProps, 'search');
  const onRangeDateFilter = useCreateOnFilterChange(listProps, 'rangeDate');

  return {
    onZoneFilter,
    onEditorFilter,
    onWriterFilter,
    onDeployerFilter,
    onStatusFilter,
    onContentTypeFilter,
    onDisplayTypeFilter,
    onSearchFilter,
    onRangeDateFilter,
  };
}
