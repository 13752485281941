import { green } from '@ant-design/colors';
import { IS_MOBILE } from '@bm/common';
import { Badge, Button, Tabs } from 'antd';
import React, { Fragment, useState } from 'react';
import makeStyles from 'core/common/makeStyles';
import Separator from 'core/components/common/Separator';
import SelectableRelatedContentsTab from './SelectableRelatedContentsTab';
import SelectedRelatedContentsTab from './SelectedRelatedContentsTab';
import SuggestRelatedContentsTab from './SuggestRelatedContentsTab';
import { Sites } from 'core/engine/site/constants';

const { TabPane } = Tabs;

const useStyles = makeStyles(() => ({
  tab: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > .ant-tabs-content': {
      height: 'calc(100% - 71px)',
    },
  },
  button: {
    position: 'absolute',
    top: '5px',
    right: '60px',
    lineHeight: 1.5,
  },
}));

export default function RelatedContentsSelector({ value, onChange, getSuggestedContent, siteId = Sites.ALL }) {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Tabs
      defaultActiveKey={value.length > 0 ? 'list' : 'add'}
      className={classes.tab}
      tabBarStyle={{ width: 'calc(100% - 32px)' }}
    >
      <TabPane
        tab={
          <Fragment>
            Danh sách tin liên quan đã chọn
            <Separator />
            <Badge
              count={value.length}
              style={{ backgroundColor: green[5] }}
            />
          </Fragment>
        }
        key="list"
      >
        <SelectedRelatedContentsTab value={value} onChange={onChange} />
      </TabPane>
      <TabPane tab="Gợi ý tin liên quan" key="suggest">
        <SuggestRelatedContentsTab
          value={value} onChange={onChange}
          getSuggestedContent={getSuggestedContent}
        />
      </TabPane>
      <TabPane tab="Thêm tin liên quan" key="add">
        {!IS_MOBILE && (
          <Button
            title={collapsed ? 'Hiện tìm kiếm' : 'Ẩn tìm kiếm'}
            className={classes.button}
            onClick={toggleCollapsed}
          >
            {collapsed ? 'Hiện tìm kiếm' : 'Ẩn tìm kiếm'}
          </Button>
        )}
        <SelectableRelatedContentsTab
          value={value}
          onChange={onChange}
          hideToolbar={collapsed}
          siteId={siteId}
        />
      </TabPane>
    </Tabs>
  );

  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }
}
