import React, { useState, useEffect } from 'react';
import { Input } from 'antd';

export function OnEnterFilter({ value, onChange, ...props }) {
  const [current, setCurrent] = useState(value);

  useEffect(() => {
    setCurrent(value);
  }, [value]);

  return (
    <Input
      {...props}
      onPressEnter={(event) => {
        onChange(event.target.value);
      }}
      onChange={(event) => {
        setCurrent(event.target.value);
      }}
      value={current}
    />
  );
}

export function OnEnterFilterForList({ filter, onChange }) {
  return (
    <OnEnterFilter value={filter ? filter.value : ''} onChange={onChange} />
  );
}
