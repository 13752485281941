import { layoutRegistry, widgetRegistry } from 'core/modules/operations/widgets/registry';
import ZoneWidgetQuickInsertDialog
  from 'core/modules/operations/widgets/components/widgets/zone/ZoneWidgetQuickInsertDialog';
import { ObjectType } from 'core/engine/common/constants';
import ZoneWidgetFormItemMeta from 'core/modules/operations/widgets/components/widgets/zone/ZoneWidgetFormItemMeta';
import AdsWidgetQuickInsertDialog
  from 'core/modules/operations/widgets/components/widgets/ads/AdsWidgetQuickInsertDialog';
import AdsWidgetFormItemMeta from 'core/modules/operations/widgets/components/widgets/ads/AdsWidgetFormItemMeta';
import WidgetDefaultQuickInsertDialog
  from 'core/modules/operations/widgets/components/common/WidgetDefaultQuickInsertDialog';
import WidgetFormItemEmptyMeta from 'core/modules/operations/widgets/components/common/WidgetFormItemEmptyMeta';
import WidgetMetaCountFormItem from 'core/modules/operations/widgets/components/common/form/WidgetMetaCountFormItem';
import TopicWidgetQuickInsertDialog from 'core/modules/operations/widgets/components/widgets/topic/TopicWidgetQuickInsertDialog';
import TopicWidgetFormItemMeta from 'core/modules/operations/widgets/components/widgets/topic/TopicWidgetFormItemMeta';

export {
  start,
};

const WidgetLayout = {
  HOME: 1,
  LIST: 2,
  DETAIL: 3,
  HOME_BDS: 10,
};

const WidgetPosition = {
  SIDEBAR_1: 2,
  SIDEBAR_2: 3,
  SIDEBAR_3: 4,

  TRENDING_1: 10,
  TRENDING_2: 11,
  MOBILE_HOME: 12,
};

const WidgetType = {
  ZONE: 1,
  TOPIC: 2,
  ADS: 3,
  MOST_READ: 5,

  UTILITY: 20,
  EPAPER: 21,
  ADS_TEXTLINK: 22,
  ADS_TEXTLINK2: 23,
};

function start() {
  layoutRegistry.registerMultiple([
    {
      id: WidgetLayout.HOME,
      name: 'Trang chủ',
      positions: [
        { id: WidgetPosition.SIDEBAR_1, name: 'Cột phải 1' },
        { id: WidgetPosition.SIDEBAR_2, name: 'Cột phải 2' },
        { id: WidgetPosition.SIDEBAR_3, name: 'Cột phải 3' },
        { id: WidgetPosition.MOBILE_HOME, name: 'Mobile' },
      ],
    },
    {
      id: WidgetLayout.LIST,
      name: 'Trang danh sách',
      positions: [
        { id: WidgetPosition.SIDEBAR_1, name: 'Cột phải 1' },
        { id: WidgetPosition.SIDEBAR_2, name: 'Cột phải 2' },
      ],
      objectTypes: [
        ObjectType.ZONE,
      ],
    },
    {
      id: WidgetLayout.DETAIL,
      name: 'Trang chi tiết',
      positions: [
        { id: WidgetPosition.SIDEBAR_1, name: 'Cột phải' },
      ],
      objectTypes: [
        ObjectType.ZONE,
      ],
    },
    {
      id: WidgetLayout.HOME_BDS,
      name: 'Trang chủ Bất động sản',
      positions: [
        { id: WidgetPosition.TRENDING_1, name: 'Nổi bật 1' },
        { id: WidgetPosition.TRENDING_2, name: 'Nổi bật 2' },
        { id: WidgetPosition.SIDEBAR_1, name: 'Cột phải' },
      ],
    },
  ]);

  widgetRegistry.registerMultiple([
    {
      type: WidgetType.ZONE,
      name: 'Chuyên mục',
      QuickInsertDialog: ZoneWidgetQuickInsertDialog,
      FormItemMeta: ZoneWidgetFormItemMeta,
      displayIn: [
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_3 },
        { layout: WidgetLayout.HOME, position: WidgetPosition.MOBILE_HOME },
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.DETAIL, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.HOME_BDS, position: WidgetPosition.SIDEBAR_1 },
      ],
      WidgetTemplate: {
        'ZoneBoxRight.html': 'Dạng cột phải',
        'ZoneBoxRight1.html': 'Dạng cột phải 1',
        'ZoneBoxRight2.html': 'Dạng cột phải 2',
        'ZoneBoxRight3.html': 'Dạng cột phải Hot',
        'bds-zone-right1': '[BĐS] Dạng cột phải',
      },
    },
    {
      type: WidgetType.TOPIC,
      name: 'Chủ đề',
      QuickInsertDialog: TopicWidgetQuickInsertDialog,
      FormItemMeta: TopicWidgetFormItemMeta,
      displayIn: [
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.HOME, position: WidgetPosition.MOBILE_HOME },
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.DETAIL, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.HOME_BDS, position: WidgetPosition.TRENDING_1 },
        { layout: WidgetLayout.HOME_BDS, position: WidgetPosition.TRENDING_2 },
        { layout: WidgetLayout.HOME_BDS, position: WidgetPosition.SIDEBAR_1 },
      ],
      WidgetTemplate: {
        'TopicBoxRight.html': 'Dạng cột phải',
        'TopicBoxRight1.html': 'Dạng cột phải 1',
        'TopicBoxRight2.html': 'Dạng cột phải 2',
        'TopicBoxRight3.html': 'Dạng cột phải Hot',
      },
    },
    {
      type: WidgetType.ADS,
      name: 'Vị trí quảng cáo',
      QuickInsertDialog: AdsWidgetQuickInsertDialog,
      FormItemMeta: AdsWidgetFormItemMeta,
      displayIn: [
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_3 },
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.DETAIL, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.HOME_BDS, position: WidgetPosition.SIDEBAR_1 },
      ],
    },
    {
      type: WidgetType.MOST_READ,
      name: 'Đọc nhiều',
      QuickInsertDialog: WidgetDefaultQuickInsertDialog,
      FormItemMeta: WidgetMetaCountFormItem,
      displayIn: [
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.DETAIL, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.HOME_BDS, position: WidgetPosition.SIDEBAR_1 },
      ],
    },
    {
      type: WidgetType.UTILITY,
      name: 'Thông tin hằng ngày',
      QuickInsertDialog: WidgetDefaultQuickInsertDialog,
      FormItemMeta: WidgetFormItemEmptyMeta,
      displayIn: [
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_2 },
      ],
    },
    {
      type: WidgetType.EPAPER,
      name: 'E-PAPER',
      QuickInsertDialog: WidgetDefaultQuickInsertDialog,
      FormItemMeta: WidgetFormItemEmptyMeta,
      displayIn: [
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.DETAIL, position: WidgetPosition.SIDEBAR_1 },
      ],
    },
    {
      type: WidgetType.ADS_TEXTLINK,
      name: 'Quảng cáo Netlink',
      QuickInsertDialog: WidgetDefaultQuickInsertDialog,
      FormItemMeta: WidgetFormItemEmptyMeta,
      displayIn: [
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.HOME_BDS, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.HOME_BDS, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.DETAIL, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.DETAIL, position: WidgetPosition.SIDEBAR_2 },
      ],
    },
    {
      type: WidgetType.ADS_TEXTLINK2,
      name: 'Quảng cáo Hapo',
      QuickInsertDialog: WidgetDefaultQuickInsertDialog,
      FormItemMeta: WidgetFormItemEmptyMeta,
      displayIn: [
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.HOME, position: WidgetPosition.SIDEBAR_3 },
        { layout: WidgetLayout.HOME_BDS, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.HOME_BDS, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.LIST, position: WidgetPosition.SIDEBAR_2 },
        { layout: WidgetLayout.DETAIL, position: WidgetPosition.SIDEBAR_1 },
        { layout: WidgetLayout.DETAIL, position: WidgetPosition.SIDEBAR_2 },
      ],
    },
  ]);
}
