import makeStyles from 'core/common/makeStyles';
import FlexDiv from 'core/components/common/FlexDiv';
import Separator from 'core/components/common/Separator';
import { Button } from 'antd';
import React, { useMemo } from 'react';
import AuthorSelector from 'core/components/author/AuthorSelector';
import ContentFormItemAuthorFreeAuthorInput from './ContentFormItemAuthorFreeAuthorInput';

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function ContentFormItemAuthorInput({
  value,
  onChange,
  onDelete,
  free = false,
}) {
  const classes = useStyles();

  const selectorValue = useMemo(() => ({ ...value, FullName: value.AuthorName }), [value]);

  return (
    <FlexDiv className={classes.container}>
      {free ? (
        <ContentFormItemAuthorFreeAuthorInput
          value={value.AuthorName}
          onChange={handleFreeAuthorChange}
        />
      ) : (
        <AuthorSelector
          value={selectorValue}
          onChange={handleManagedAuthorChange}
        />
      )}
      <Separator />
      <Button icon="delete" size="small" onClick={onDelete} title="Xóa" />
    </FlexDiv>
  );

  function handleFreeAuthorChange(val) {
    onChange({ ...value, AuthorName: val });
  }

  function handleManagedAuthorChange(val) {
    onChange({ ...value, ...val });
  }
}
