export const ObjectType = {
  ZONE: 2,
  TOPIC: 3,
  REGION: 4,
  AUTHOR: 5,
  USER: 6,

  CONTENT: 20,
  LIVE: 21,
  LIVE_STREAM: 22,
  INTERVIEW: 23,
  INTERVIEW_QUESTION: 24,
  QUIZ: 25,
  VOTE: 26,
  VOTE_OPTION: 27,
  COMMENT: 28,

  FILE_IMAGE: 53,
  FILE_VIDEO: 54,
  ACCOUNT: 55,
};

export const ObjectTypeLabel = {
  [ObjectType.ZONE]: 'Chuyên mục',
  [ObjectType.TOPIC]: 'Chủ đề',
  [ObjectType.REGION]: 'Địa phương',
  [ObjectType.AUTHOR]: 'Tác giả',
};

export const Platform = {
  ALL: 0,
  WEB: 1,
  MOBILE: 2,
};

export const PlatformLabel = {
  [Platform.ALL]: 'All',
  [Platform.WEB]: 'Web',
  [Platform.MOBILE]: 'Mobile',
};

export const PageLayoutType = {
  LEFT: 1,
  RIGHT: 2,
  CENTER: 3,
};

export const PageLayoutTypeLabel = {
  [PageLayoutType.LEFT]: 'Cột chính bên phải', // sidebar trái
  [PageLayoutType.RIGHT]: 'Cột chính bên trái', // sidebar phải
  [PageLayoutType.CENTER]: 'Cột chính nằm giữa',
};
