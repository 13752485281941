import React, { forwardRef, useEffect, useState } from 'react';
import Selector from '../common/Selector';
import adsPositionApi from 'core/engine/ads-position/api';

export default forwardRef(function AdsPositionSelector({ value, onChange, ...props }, ref) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    adsPositionApi.getListAll().then(resp => resp.data.items).then((resp) => {
      const options = resp.map((item) => ({ value: item.PositionID, label: item.Name }));
      setOptions(options);
    });
  }, []);

  return (
    <Selector
      ref={ref}
      options={options}
      value={value}
      onChange={onChange}
      valueIsNumber={true}
      {...props}
    />
  );
});

export function AdsPositionFilterForList({ filter, onChange }) {
  return (
    <AdsPositionSelector value={filter ? filter.value : null} selectProps={{ allowClear: true }} onChange={onChange} />
  );
}
