import React from 'react';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import useContentColumns from 'core/hooks/useContentColumns';
import ContentEditButtonForList from 'core/components/content/ContentEditButtonForList';
import ContentFilterableAccount from 'core/components/content/ContentFilterableAccount';
import ContentLogButtonForList from 'core/components/content/ContentLogButtonForList';
import BaseTable from 'core/components/table/BaseTable';
import ContentPreviewButtonForList from 'core/components/content/ContentPreviewButtonForList';
import { ContentStatus } from 'core/engine/content/constants';
import { Button } from 'antd';

export default function ContentAllList({
  onEditorSelected,
  onWriterSelected,
  onDeployerSelected,
  ...props
}) {
  const ContentColumns = useContentColumns();

  const columns = [
    ContentColumns.ContentID,
    ContentColumns.Avatar,
    ContentColumns.Title,
    ContentColumns.Status,
    {
      Header: 'Người tạo',
      accessor: 'WriterID',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <ContentFilterableAccount
            id={original.WriterID}
            onClick={() => onWriterSelected(original.WriterID)}
            title="Lọc tin tạo bởi tài khoản này"
          />
          <br />
          <i><DateTimeDisplay timestamp={original.CreatedDate} /></i>
        </div>
      ),
    },
    {
      Header: 'Người đăng',
      accessor: 'DeployerID',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <ContentFilterableAccount
            id={original.DeployerID}
            onClick={() => onDeployerSelected(original.DeployerID)}
            title="Lọc tin đăng bởi tài khoản này"
          />
          <br />
          <i><DateTimeDisplay timestamp={original.DistributionDate} /></i>
        </div>
      ),
    },
    {
      Header: 'Người sửa',
      accessor: 'EditorID',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <ContentFilterableAccount
            id={original.EditorID}
            onClick={() => onEditorSelected(original.EditorID)}
            title="Lọc tin sửa bởi tài khoản này"
          />
          <br />
          <i><DateTimeDisplay timestamp={original.ModifiedDate} /></i>
        </div>
      ),
    },
    {
      ...ContentColumns.ViewCount,
      sortable: true,
    },
    ContentColumns.CheckIn,
    {
      width: 135,
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <ContentEditButtonForList content={original} />
          <ContentLogButtonForList contentId={original.ContentID} />
          {original.Status === ContentStatus.DEPLOYED ? (
            <Button title="Xem trước" icon="eye" disabled />
          ) : (
            <ContentPreviewButtonForList noiseId={original.NoiseID} />
          )}
        </ActionsCellWrapper>
      ),
    },
  ];

  return (
    <BaseTable
      idField="ContentID"
      columns={columns}
      {...props}
    />
  );
}
