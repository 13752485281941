import { Button, Form } from 'antd';
import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import InterviewQuestionForm from './InterviewQuestionForm';
import interviewQuestionApi from 'core/engine/interview-question/api';
import InterviewAnswerList from '../interview-answers/InterviewAnswerList';
import useList from 'core/hooks/useList';
import { InterviewAnswerStatusLabel } from 'core/engine/interview-answer/constants';
import interviewAnswerApi from 'core/engine/interview-answer/api';
import { sortingToSortBy } from 'core/common/listUtils';
import useListProps from 'core/hooks/useListProps';
import InterviewAnswerFormDialogButton from '../interview-answers/InterviewAnswerFormDialogButton';
import FlexPushRight from 'core/components/common/FlexPushRight';
import lodash from 'core/common/lodash';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import useInterviewGuestLoader from '../../hooks/useInterviewGuestLoader';
import Selector from 'core/components/common/Selector';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';

function listFn({ limit, offset, filters, sorting }) {
  return interviewAnswerApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

function InterviewQuestionFormDialogButton({ form, question, interview, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });
  const editorRef = useRef();
  const { guests } = useInterviewGuestLoader(interview);

  const title = useMemo(() => isEditMode ? 'Thay đổi câu hỏi' : 'Thêm câu hỏi', [isEditMode]);

  const listProps = useList({
    listFn,
    defaultSorting: {
      AnswerID: 'ASC',
    },
    defaultFilters: {
      InterviewID: interview.InterviewID,
    },
  });

  useEffect(() => {
    listProps.addFilter('QuestionID', question.QuestionID);
  }, [question.QuestionID]);

  const listData = useListProps(listProps);

  const handleStatusFilter = useCreateOnFilterChange(listProps, 'Status');

  return (
    <Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={title}
        onClick={() => {
          listProps.refresh();
          handleOpen();
        }}
      >
        {isEditMode ? '' : title}
      </Button>
      <BaseDialog
        visible={open}
        title={title}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        fullHeight
        confirmLoading={isSubmitting}
      >
        <InterviewQuestionForm form={form} interview={interview} question={question} editorRef={editorRef} />
        {isEditMode ? (
          <Fragment>
            <TableToolbarWrapper>
              <span>Câu trả lời: </span>
              <FlexPushRight />
              <Selector
                width={150}
                emptyLabel="-- Trạng thái --"
                value={listProps.filters.Status}
                options={InterviewAnswerStatusLabel}
                onChange={handleStatusFilter}
                showDisabledOptions
                allowClear
                showSearch={false}
                valueIsNumber
              />
              <InterviewAnswerFormDialogButton
                onDone={listProps.refresh}
                isEditMode={false}
                interview={interview}
                answer={{
                  InterviewID: interview.InterviewID,
                  QuestionID: question.QuestionID,
                  InterviewQuestion: question,
                  GuestID: findGuest(lodash.get(interview, 'Guests[0].GuestID')),
                }}
                label="Thêm câu trả lời"
              />
            </TableToolbarWrapper>
            <InterviewAnswerList
              {...listData}
              interview={interview}
              onUpdate={listProps.refresh}
              showPagination={false}
              fullHeight={false}
              pageSize={listProps.items.length}
              isCheckable={false}
            />
          </Fragment>
        ) : null}
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    if (formData.Date) {
      formData.Date = formData.Date.toDate().getTime();
    }

    if (formData.Guests) {
      formData.Guests = formData.Guests.join();
    }

    formData.Question = editorRef.current.getNormalizedValue();

    if (isEditMode) {
      return asyncAction(title, () => {
        return interviewQuestionApi.update(formData)
          .then(handleDone);
      });
    } else {
      return asyncAction(title, () => {
        return interviewQuestionApi.create(formData)
          .then(handleDone);
      });
    }
  }

  function handleDone() {
    handleClose();
    onDone();
  }

  function findGuest(guestId) {
    return lodash.find(guests, { GuestID: guestId }) ? guestId : lodash.get(guests, '[0].GuestID');
  }
}

export default Form.create({ name: 'InterviewQuestionFormDialogButton' })(InterviewQuestionFormDialogButton);
