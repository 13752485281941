import lodash from 'core/common/lodash';

const actions = {};

const actionRegistry = {
  addAction,
  doAction,
  doActionPromise,
};

export default actionRegistry;

function addAction(name, callbackFn, priority = 0) {
  if (!actions[name]) {
    actions[name] = [];
  }

  actions[name].push({ callbackFn, priority });

  lodash.sortBy(actions[name], action => -action.priority);
}

function doAction(name, options) {
  if (!actions[name]) {
    return [];
  }

  return actions[name].map(action => action.callbackFn(options));
}

function doActionPromise(name, options) {
  if (!actions[name]) {
    return Promise.resolve([]);
  }

  return Promise.all(actions[name].map(action => action.callbackFn(options)));
}
