import { useEffect, useMemo, useState } from 'react';
import lodash from 'core/common/lodash';
import contentApi from 'core/engine/content/api';
import { ContentAction } from 'core/engine/content/constants';

export default function useContentVisibleActions({ form, content }) {
  const [distributions, setDistributions] = useState();
  const [contentType, setContentType] = useState(content.ContentType);

  useEffect(() => {
    setContentType(form.getFieldValue('ContentType'));

    if (!form.getFieldError('Distributions')) {
      setDistributions(form.getFieldValue('Distributions'));
    }
  }, [form]); // have to use useEffect along with form deps because form.getFieldValue won't call useEffect after Component mounted.

  const [visibleActions, setVisibleActions] = useState([]);

  const distributionsString = useMemo(() => {
    return JSON.stringify(
      (distributions || []).filter(dist => dist.ZoneID).map(dist => ({ ZoneID: dist.ZoneID }))
    );
  }, [distributions]);

  const primaryZoneId = useMemo(() => {
    return lodash.get(distributions, '[0].ZoneID');
  }, [distributions]);

  useEffect(() => {
    contentApi.getActions({
      ContentID: content.ContentID,
      ZoneID: primaryZoneId,
      Status: content.Status,
      Distributions: distributionsString,
      ContentType: contentType,
    }).then((resp) => {
      const actions = resp.data.actions;

      actions.push(ContentAction.CHECKOUT); // frontend checkout
      actions.push(ContentAction.SAVE); // friendly UX
      actions.push(ContentAction.PREVIEW); // friendly UX

      if (!content.ContentID) {
        actions.push(ContentAction.RESET);
      }

      setVisibleActions(lodash.uniq(resp.data.actions));
    });
  }, [content.ContentID, content.Status, distributionsString, contentType]);

  return visibleActions;
}
