import { IS_MOBILE } from '@bm/common';
import { Button } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { commonColumns } from 'core/common/listUtils';
import makeStyles from 'core/common/makeStyles';
import AccountDisplay from 'core/components/account/AccountDisplay';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import CodeTag from 'core/components/common/CodeTag';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import Separator from 'core/components/common/Separator';
import ContentAvatar from 'core/components/content/ContentAvatar';
import ContentListTitleDisplay from 'core/components/content/ContentTitleDisplayForList';
import DraggableTable from 'core/components/table/DraggableTable';
import useContentColumns from 'core/hooks/useContentColumns';
import React from 'react';
import TopicSystemContentListItemScheduleButton from './TopicSystemContentListItemScheduleButton';
import ContentEditButtonForList from 'core/components/content/ContentEditButtonForList';
import { CONTENT_LIST_CTR_STATS } from 'core/config';

const useStyles = makeStyles(() => ({
  rangeDate: {
    ...commonStyles.flexRow,
    justifyContent: 'space-between',
    width: '100%',
    padding: '2px 5px',
  },
}));

function RangeDateDisplay({ fromDate, toDate }) {
  const classes = useStyles();

  return (
    <div className={classes.rangeDate}>
      {fromDate ? (
        <CodeTag title="Thời gian bắt đầu"><DateTimeDisplay timestamp={fromDate} /></CodeTag>
      ) : <span />}
      {fromDate && toDate && <Separator text="~" />}
      {toDate ? (
        <CodeTag title="Thời gian kết thúc"><DateTimeDisplay timestamp={toDate} /></CodeTag>
      ) : <span />}
    </div>
  );
}

export default function TopicSystemContentList({
  list,
  onDelete,
  onMove,
  onDateChange,
  isModifying,
  contentEditable = false,
  ...props
}) {
  const ContentColumns = useContentColumns();

  const columns = [
    {
      ...commonColumns.allCentered,
      accessor: 'Order',
      width: 50,
    },
    {
      show: !IS_MOBILE,
      Header: 'Ảnh',
      width: 64,
      accessor: 'Content',
      Cell: ({ value }) => (<ContentAvatar content={value} />),
      style: { justifyContent: 'center' },
    },
    {
      Header: 'Tiêu đề',
      accessor: 'Content',
      Cell: ({ value }) => (
        <ContentListTitleDisplay content={value} />
      ),
      minWidth: 250,
    },
    {
      show: !IS_MOBILE,
      Header: 'Hẹn giờ',
      width: 220,
      Cell: ({ original }) => (
        <RangeDateDisplay fromDate={original.StartDate} toDate={original.EndDate} />
      ),
    },
    {
      show: !IS_MOBILE,
      Header: 'Người tạo / đăng',
      width: 170,
      accessor: 'Content',
      style: { justifyContent: 'left' },
      Cell: ({ value }) => (
        <div>
          <AccountDisplay id={value && value.WriterID} />
          <Separator text="/" />
          <AccountDisplay id={value && value.DeployerID} />
          <br />
          <i><DateTimeDisplay timestamp={value.DistributionDate} /></i>
        </div>
      ),
    },
    ContentColumns.ViewCount,
    CONTENT_LIST_CTR_STATS && ContentColumns.CTR,
    DraggableTable.MOVE_COLUMN,
    !isModifying && contentEditable && {
      width: 170,
      accessor: 'Content',
      Cell: ({ value }) => (
        <ActionsCellWrapper>
          <ContentEditButtonForList content={value} />
        </ActionsCellWrapper>
      ),
    },
    isModifying && {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <TopicSystemContentListItemScheduleButton topicContent={original} onDone={onDateChange} />
          <Button
            title="Xóa Tin Bài khỏi Box tin?"
            icon="delete"
            onClick={() => onDelete(original)}
          />
        </ActionsCellWrapper>
      ),
      width: 110,
    },
  ].filter(Boolean);

  return (
    <DraggableTable
      isDragDisabled={!isModifying}
      columns={columns}
      data={list}
      onMove={onMove}
      fullHeight={false}
      maxHeightAdjust={42}
      {...props}
    />
  );
}
