import { Button, Form } from 'antd';
import React, { Fragment } from 'react';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import accountApi from 'core/engine/account/api';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import { AccountFormRaw } from './AccountForm';

function AccountFormDialogButton({ form, account = {}, onDone, isEditMode = true }) {

  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <Fragment>
      <Button
        title={isEditMode ? 'Sửa tài khoản' : 'Tạo tài khoản'}
        icon={isEditMode ? 'edit' : 'plus'}
        onClick={handleOpen}
      >
        {isEditMode ? '' : 'Tạo tài khoản'}
      </Button>
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa tài khoản' : 'Tạo tài khoản'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText={isEditMode ? 'Sửa' : 'Tạo'}
        width={900}
        confirmLoading={isSubmitting}
        keyboard={!form.isFieldsTouched()}
        maskClosable={!form.isFieldsTouched()}
        fullHeight
      >
        <AccountFormRaw form={form} onSubmit={handleSubmit} account={account} isEditMode={isEditMode} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    if (isEditMode) {
      return asyncAction('Sửa tài khoản', () => {
        return accountApi.update(formData)
          .then(handleDone);
      });
    }

    return asyncAction('Tạo tài khoản', () => {
      return accountApi.create(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }

}

export default Form.create({ name: 'AccountFormDialogButton' })(AccountFormDialogButton);
