import React from 'react';
import { ReactTableDefaults } from 'react-table';

Object.assign(ReactTableDefaults, {
  noDataText: 'Không có dữ liệu',
  loadingText: 'Đang tải...',
  getNoDataProps: ({ loading }) => ({ loading}),
  NoDataComponent,
});

function NoDataComponent({ children, loading }) {
  return loading ? null : <div className="rt-noData">{children}</div>;
}
