import { useEffect, useMemo, useRef, useReducer, useCallback } from 'react';
import { replaceArrayItem } from 'core/common/utils';
import logger from 'core/common/logger';
import { isNullOrUndefined } from 'core/common/is';
import { addArrayItem } from 'core/common/arrayHelper';
import { emptyObject } from 'core/common/empty';
import locationService from 'core/common/locationService';
import lodash from 'core/common/lodash';
import moment from 'moment';

const DEFAULT_PAGE_SIZE = 20;
const INACTIVE_CHECK_INTERVAL = 60000; // 1 minute
const INACTIVE_TIMEOUT = 30 * 60000; // 30 minutes
const DEFAULT_STATE = {
  pageIndex: 0,
  pageSize: 0,
  filters: {},
  sorting: {},
  items: [],
  total: 0,
  error: null,
  isFetching: false,
  refreshObject: {},
};

const allListStates = {};

function buildInitialState({
  listId,
  defaultPageSize,
  defaultFilters,
  defaultSorting,
}) {
  const initialState = {
    ...DEFAULT_STATE,
    listId,
    pageSize: defaultPageSize,
    filters: defaultFilters,
    sorting: defaultSorting,
  };

  if (!listId) {
    logger.info('listId is not specified, your list state will not be persistent. The list is at:', locationService.getPathName());
    return initialState;
  }

  if (!allListStates[listId]) {
    allListStates[listId] = initialState;
  }

  return allListStates[listId];
}

const ActionType = {
  SET_PAGE_INDEX: 1,
  SET_PAGE_SIZE: 2,
  SET_FILTERS: 3,
  SET_SORTING: 4,
  FETCH_REQUEST: 5,
  FETCH_SUCCESS: 6,
  FETCH_ERROR: 7,
  ADD_ITEM: 8,
  REMOVE_ITEM: 9,
  REPLACE_ITEMS: 10,
  REFRESH: 11,
  CLEAR_ITEMS: 12,
  ADD_FILTER: 13,
};

function reducer(state, action) {
  let newState;

  switch (action.type) {
    case ActionType.SET_PAGE_INDEX:
      newState = { ...state, pageIndex: action.pageIndex };
      break;
    case ActionType.SET_PAGE_SIZE:
      newState = { ...state, pageSize: action.pageSize };
      break;
    case ActionType.SET_FILTERS:
      // we would like to load 1st page on filter change
      newState = { ...state, filters: action.filters, pageIndex: 0 };
      break;
    case ActionType.ADD_FILTER:
      const { id, value } = action;
      const newFilters = { ...state.filters };

      if (isNullOrUndefined(value)) {
        delete newFilters[id];
      } else {
        newFilters[id] = value;
      }

      // we would like to load 1st page on filter change
      newState = { ...state, filters: newFilters, pageIndex: 0 };
      break;
    case ActionType.SET_SORTING:
      // we would like to load 1st page on sorting change
      newState = { ...state, sorting: action.sorting, pageIndex: 0 };
      break;
    case ActionType.FETCH_REQUEST:
      newState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
    case ActionType.FETCH_SUCCESS:
      newState = {
        ...state,
        isFetching: false,
        total: action.data.total,
        items: action.data.items,
        extraData: lodash.omit(action.data, ['items', 'total']),
      };
      break;
    case ActionType.FETCH_ERROR:
      newState = {
        ...state,
        isFetching: false,
        total: 0,
        items: [],
        extraData: null,
        error: action.error,
      };
      break;
    case ActionType.ADD_ITEM:
      newState = {
        ...state,
        items: addArrayItem(state.items.slice(), action.item, action.position),
        total: state.total + 1,
      };
      break;
    case ActionType.REMOVE_ITEM:
      newState = {
        ...state,
        items: replaceArrayItem(state.items.slice(), null, { [action.idField]: action.item[action.idField] }),
        total: state.total - 1,
      };
      break;
    case ActionType.REPLACE_ITEMS:
      newState = {
        ...state,
        items: action.items.reduce((currentItems, itemToReplace) => {
          return replaceArrayItem(currentItems, itemToReplace, action.idField);
        }, state.items.slice()),
      };
      break;
    case ActionType.REFRESH:
      newState = {
        ...state,
        refreshObject: {}, // to cause useEffect to fetch data again (refresh)
      };
      break;
    case ActionType.CLEAR_ITEMS:
      newState = {
        ...state,
        items: [],
        total: 0,
        extraData: null,
      };
      break;
    default:
      throw new Error('Invalid action.type: ' + action.type);
  }

  if (state.listId) {
    allListStates[state.listId] = newState;
  }

  return newState;
}

export default function useList({
  listFn,
  listId,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  defaultFilters = emptyObject,
  defaultSorting = emptyObject,
  clearOnUnmount = true,
  refreshOnInactive = true,
  autoLoad = false,
}) {
  const isFirstRun = useRef(true);
  const lastFetchTimeRef = useRef(0);

  const [state, dispatch] = useReducer(
    reducer,
    { listId, defaultPageSize, defaultFilters, defaultSorting },
    buildInitialState,
  );
  const { pageIndex, pageSize, filters, sorting, total, items, isFetching, error, extraData, refreshObject } = state;

  const totalPage = useMemo(() => Math.ceil(total / pageSize), [total, pageSize]);
  const filtered = useMemo(() => {
    return Object.keys(filters).map(id => ({ id, value: filters[id] }));
  }, [filters]);
  const sorted = useMemo(() => {
    return Object.keys(sorting).map(id => ({ id, desc: sorting[id] === 'DESC' }));
  }, [sorting]);

  const refresh = useCallback(() => {
    dispatch({ type: ActionType.REFRESH });
  }, []);

  const onPageChange = useCallback((pageIndex = 0, forceRefresh = false) => {
    dispatch({ type: ActionType.SET_PAGE_INDEX, pageIndex });
    if (forceRefresh) refresh();
  }, [refresh]);

  const onPageSizeChange = useCallback((pageSize, pageIndex) => {
    dispatch({ type: ActionType.SET_PAGE_INDEX, pageIndex });
    dispatch({ type: ActionType.SET_PAGE_SIZE, pageSize });
  }, []);

  const onFilteredChange = useCallback((filtered = []) => {
    const filters = {};

    filtered.forEach((filter) => {
      if (!isNullOrUndefined(filter.value)) {
        filters[filter.id] = filter.value;
      }
    });

    dispatch({ type: ActionType.SET_FILTERS, filters });
  }, []);

  const onSortedChange = useCallback((sorted = []) => {
    const sorting = {};

    sorted.forEach((sort) => {
      sorting[sort.id] = sort.desc ? 'DESC' : 'ASC';
    });

    dispatch({ type: ActionType.SET_SORTING, sorting });
  }, []);

  const addFilter = useCallback((id, value) => {
    dispatch({ type: ActionType.ADD_FILTER, id, value });
  }, [filters]);

  const replaceItem = useCallback((item, idField = 'id') => {
    dispatch({ type: ActionType.REPLACE_ITEMS, items: [item], idField });
  }, []);

  const replaceItems = useCallback((items, idField = 'id') => {
    dispatch({ type: ActionType.REPLACE_ITEMS, items, idField });
  }, []);

  const removeItem = useCallback((item, idField = 'id') => {
    dispatch({ type: ActionType.REMOVE_ITEM, item, idField });
  }, []);

  const addItem = useCallback((item, position = 0) => {
    dispatch({ type: ActionType.ADD_ITEM, item, position });
  }, []);

  const listProps = {
    isFetching,
    error,
    items,
    total,
    pageIndex,
    pageSize,
    filters,
    sorting,
    extraData,
    // computed values
    totalPage,
    filtered,
    sorted,
  };

  useEffect(() => {
    // skip the first run on component did mount
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    lastFetchTimeRef.current = moment.now();

    dispatch({ type: ActionType.FETCH_REQUEST });

    listFn({ ...listProps, limit: pageSize, offset: pageIndex * pageSize })
      .then((resp) => {
        dispatch({ type: ActionType.FETCH_SUCCESS, data: resp.data });
      })
      .catch((err) => {
        logger.error('Error while fetching list', err);
        dispatch({ type: ActionType.FETCH_ERROR, error: err });
      });
  }, [pageIndex, pageSize, filters, sorting, lastFetchTimeRef, refreshObject]);

  // to refresh the last after being active for a time
  useEffect(() => {
    if (!refreshOnInactive) {
      return;
    }

    const intervalId = setInterval(() => {
      if (lastFetchTimeRef.current && moment.now() - lastFetchTimeRef.current > INACTIVE_TIMEOUT) {
        logger.debug('Refreshing the list after being inactive for', INACTIVE_TIMEOUT / 1000, 'seconds');
        refresh();
      }
    }, INACTIVE_CHECK_INTERVAL);

    return () => clearInterval(intervalId);
  }, [lastFetchTimeRef, refresh, refreshOnInactive]);

  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        dispatch({ type: ActionType.CLEAR_ITEMS });
      }
    };
  }, [clearOnUnmount]);

  useEffect(() => {
    if (autoLoad) {
      refresh();
    }
  }, [refresh]);

  return {
    ...listProps,
    refresh,
    onPageChange,
    onPageSizeChange,
    onFilteredChange,
    onSortedChange,
    addFilter,
    replaceItem,
    replaceItems,
    removeItem,
    addItem,
  };
}
