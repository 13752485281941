import ZDocs, { Config } from '@zdocs/core';
import domUtils from 'core/common/domUtils';
import lodash from 'core/common/lodash';
import NewLinePlugin from 'core/components/editor/plugins/NewLinePlugin';
import BasePlugin from 'core/components/editor/plugins/BasePlugin';

const Dom = ZDocs.modules.Dom;

export function getSelectionImageTable(zdocs) {
  const current = zdocs.selection.current();

  if (!current) {
    return null;
  }

  let element = current.parentNode;

  if (domUtils.hasClass(element, 'before-after-image-box')) {
    element = element.parentNode;
  }

  if (element.tagName.toLowerCase() === 'td') {
    element = element.parentNode;

    if (element && element.tagName.toLowerCase() === 'tr') {
      element = element.parentNode;

      if (element && element.tagName.toLowerCase() === 'tbody') {
        element = element.parentNode;

        if (element.tagName.toLowerCase() === 'table') {
          !domUtils.hasClass(element, 'picture') && domUtils.addClass(element, 'picture');
          return element;
        }
      }
    }
  }

  return null;
}

export const defaultButtons = [
  'bin',
  'newline',
];

const alignNoteboxButton = {
  name: 'left',
  list: ['Left', 'Right', 'Center'],
  tooltip: 'Căn dọc',
  exec: (zdocs, node, control) => {
    const command = control.args && typeof control.args[1] === 'string' ? control.args[1].toLowerCase() : '';

    domUtils.removeClass(node, 'nleft');
    domUtils.removeClass(node, 'nright');
    domUtils.removeClass(node, 'ncenter');
    domUtils.addClass(node, 'n' + command);
    node.setAttribute('align', command);
    zdocs.events.fire('hidePopup');
  },
};

const alignBlockquoteButton = {
  name: 'left',
  list: ['Left', 'Right', 'Center'],
  tooltip: 'Căn dọc',
  exec: (zdocs, node, control) => {
    const command = control.args && typeof control.args[1] === 'string' ? control.args[1].toLowerCase() : '';

    domUtils.removeClass(node, 'qleft');
    domUtils.removeClass(node, 'qright');
    domUtils.removeClass(node, 'qcenter');
    domUtils.addClass(node, 'q' + command);
    node.setAttribute('align', command);
    zdocs.events.fire('hidePopup');
  },
};

const popUpExt = {
  blockquote: [
    ...defaultButtons,
    alignBlockquoteButton,
    {
      name: 'eraser',
      tooltip: 'Xóa format',
      exec: (zdocs, node) => {
        if (node) {
          ZDocs.modules.Dom.unwrap(node);
        }

        zdocs.events.fire('hidePopup');
      },
    },
    'deleteContent',
  ],
  notebox: [
    ...defaultButtons,
    alignNoteboxButton,
    {
      name: 'eraser',
      tooltip: 'Xóa format',
      exec: (zdocs, node) => {
        if (node) {
          ZDocs.modules.Dom.unwrap(node);
        }

        zdocs.events.fire('hidePopup');
      },
    },
    'deleteContent',
  ],
  vote: defaultButtons,
  'related-topic': defaultButtons,
  'related-content': [
    ...defaultButtons,
    alignNoteboxButton,
  ],
};

popUpExt.img = [...Config.prototype.popup.img];
const leftButton = lodash.find(popUpExt.img, { name: 'left' });
const superLeftExec = leftButton.exec;

leftButton.exec = (zdocs, image, control) => {
  superLeftExec(zdocs, image, control);

  let command;

  if (control.args) {
    switch (control.args[0]) {
      case 0:
        command = 'left';
        break;
      case 1:
        command = 'right';
        break;
      case 2:
        command = 'center';
        break;
      case 3:
        command = 'normal';
        break;
      default:
        command = '';
    }
  }

  const table = getSelectionImageTable(zdocs);

  if (table) {
    table.style.width = null;
    table.setAttribute('align', command);
  }
};

popUpExt.img.push(
  {
    name: 'cover',
    tooltip: 'Chọn làm ảnh đại diện',
    exec(zdocs, current) {
      zdocs.events.fire('change-avatar', current);
      zdocs.events.fire('hidePopup');
    },
  },
  {
    name: 'insert-caption',
    tooltip: 'Ẩn / Hiện caption',
    icon: 'quote',
    exec(zdocs, node) {
      const tdElement = Dom.closest(zdocs.selection.current(), 'TD', zdocs.editor);

      if (tdElement) {
        const rowElement = tdElement.parentNode;

        if (rowElement && rowElement.tagName.toLowerCase() === 'tr') {
          const bodyElement = rowElement.parentNode;

          if (bodyElement && bodyElement.tagName.toLowerCase() === 'tbody') {
            const nextRow = Dom.next(rowElement, node => {
              return node && node.tagName && node.tagName.toLowerCase() === 'tr';
            }, bodyElement);

            if (nextRow) {
              // hide caption
              if (nextRow.childNodes) {
                if (nextRow.querySelector('.caption')) {
                  Dom.safeRemove(nextRow);
                }
              }
            } else {
              // insert caption
              const newRow = zdocs.create.inside.element('tr');
              newRow.innerHTML =
                `<td class="caption">
                  <p></p>
                </td>`;
              bodyElement.appendChild(newRow);
            }
          }
        }
      } else {
        // generate caption
        const imgTable =
          `<table class="picture" align="center">
            <tbody>
              <tr>
                <td class="pic">${node.outerHTML}</td>
              </tr>
              <tr>
                <td class="caption"><p></p></td>
              </tr>
            </tbody>
          </table>`;

        zdocs.selection.insertHTML(imgTable);
        zdocs.events.fire('hidePopup');
        const crNode = zdocs.selection.current();
        const table = crNode.previousElementSibling;

        if (table) {
          const caption = table.querySelector('.caption');
          const range = zdocs.editorDocument.createRange();
          range.selectNodeContents(caption);
          zdocs.selection.selectRange(range);
        }
      }

      zdocs.events.fire('hidePopup');
      BasePlugin.cleanAfterSetCursor(zdocs, Dom);
    },
  },
);

const topNewlineButton = {
  name: 'top-newline',
  icon: 'topNewline',
  tooltip: 'Thêm dòng trên',
  exec: (zdocs, node) => {
    NewLinePlugin.addNewLine(zdocs, node, NewLinePlugin.Position.TOP);
  },
};

const bottomNewlineButton = {
  name: 'bottom-newline',
  icon: 'bottomNewline',
  tooltip: 'Thêm dòng dưới',
  exec: (zdocs, node) => {
    NewLinePlugin.addNewLine(zdocs, node, NewLinePlugin.Position.BOTTOM);
  },
};

popUpExt.table = [...Config.prototype.popup.table];
popUpExt.table.splice(4, 0, topNewlineButton);
popUpExt.table.splice(10, 0, bottomNewlineButton);

Config.prototype.popup = { ...Config.prototype.popup, ...popUpExt };
