import { httpDownload, httpGet, httpPost } from 'core/common/http-client';

const activityLogApi = {
  getList({ limit = 20, offset = 0, fields = [], sortBy, filters }) {
    const qs = { limit, offset, field: fields.join(), sortBy, ...filters };
    return httpGet('/activity-logs/get/items-by-range', { qs });
  },

  addLabel(path, label) {
    return httpPost('/activity-logs/post/add-label', { path, label });
  },

  export(filters, filename) {
    return httpDownload('/activity-logs/get/export', { qs: filters, filename });
  },
};

export default activityLogApi;
