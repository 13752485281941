import ReactDOM from 'react-dom';
import { useCallback } from 'react';

export default function useNodeRef() {
  const result = useCallback(node => {
    result.current = ReactDOM.findDOMNode(node);
  }, []);

  return result;
}
