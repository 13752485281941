import { sortingToSortBy } from 'core/common/listUtils';
import BaseDialog from 'core/components/common/BaseDialog';
import SelectableTable from 'core/components/table/SelectableTable';
import useDialog from 'core/hooks/useDialog';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import widgetApi from 'core/engine/widget/api';
import WidgetTypeDisplay from '../common/WidgetTypeDisplay';
import AccountDisplay from 'core/components/account/AccountDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import lodash from 'core/common/lodash';
import { message } from 'antd';

const columns = [
  {
    Header: 'Tên',
    accessor: 'Title',
    minWidth: 150,
    maxWidth: 300,
  },
  {
    Header: 'Loại',
    Cell: ({ original }) => <WidgetTypeDisplay type={original.Type} />,
  },
  {
    Header: 'Người tạo',
    width: 120,
    Cell: ({ original }) => (
      <div>
        <AccountDisplay id={original.CreatorID} />
        <br />
        <i><DateTimeDisplay timestamp={original.CreatedDate} /></i>
      </div>
    ),
  },
  {
    Header: 'Người sửa',
    width: 120,
    Cell: ({ original }) => (
      <div>
        <AccountDisplay id={original.EditorID} />
        <br />
        <i><DateTimeDisplay timestamp={original.ModifiedDate} /></i>
      </div>
    ),
  },
];

function listFn({ limit, offset, filters, sorting }) {
  return widgetApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default forwardRef(function PreDefinedWidgetQuickInsertDialog({ onInsert, availableWidgets }, ref) {
  const [widgets, setWidgets] = useState([]);
  const { open, handleClose, handleOpen } = useDialog();

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultFilters: {
      PreDefined: true,
    },
    defaultSorting: {
      CreatedDate: 'DESC',
    },
  });

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  return (
    <BaseDialog
      keyboard
      maskClosable
      fullHeightFixed
      paddingTop={0}
      visible={open}
      title="Thêm widget"
      width={900}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Thêm"
      okButtonProps={{ disabled: !widgets.length }}
    >
      <SelectableTable
        {...useListProps(listProps)}
        idField="WidgetID"
        selected={widgets}
        onUnselect={onUnselect}
        onSelect={handleSelect}
        columns={columns}
      />
    </BaseDialog>
  );

  function handleSelect(item) {
    if (lodash.find(availableWidgets, { type: item.Type })) {
      setWidgets([...widgets, item]);
    } else {
      message.error('Widget không được hỗ trợ trong giao diện này!');
    }
  }

  function onUnselect(item) {
    const newWidgets = widgets.slice();
    lodash.remove(newWidgets, item);
    setWidgets(newWidgets);
  }

  function handleOk() {
    onInsert(widgets);
    handleClose();
    setWidgets([]);
  }

  function handleCancel() {
    handleClose();
    setWidgets([]);
  }
});
