import BasePlugin from '../BasePlugin';
import { Config } from '@zdocs/core';

export default class CustomUnlinkPlugin extends BasePlugin {

  static install() {
    Config.prototype.controls.unlink = {
      tooltip: 'Xóa liên kết',
      icon: 'unlink',
      exec: (zdocs, current) => {
        zdocs.execCommand('unlink', current);
      },
    };
  }

  constructor(zdocs) {
    super(zdocs);

    this.callback = this.callback.bind(this);

    zdocs.registerCommand('unLink', this.callback);
  }

  get name() {
    return 'CustomUnlinkPlugin';
  }

  callback(command, current) {
    if (this.zdocs.selection.isCollapsed()) {
      const currentNode = this.Dom.closest(current, 'A', this.zdocs.editor);

      if (currentNode) {
        this.Dom.unwrap(currentNode);
        this.zdocs.events.fire('hidePopup');
      }
    } else {
      this.zdocs.selection.eachSelection((curr) => {
        this.Dom.all(curr, (node) => {
          if (node && node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'A') {
            this.Dom.unwrap(node);
          }
        });
      });
    }
  }
}
