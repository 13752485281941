import React, { memo, useMemo } from 'react';
import { Button } from 'antd';
import { buildPreviewUrlById } from 'core/common/urlHelper';
import useAuth from 'core/hooks/useAuth';

function ContentPreviewButtonForList({ noiseId }) {
  const { loggedInUser, hasExtendedRight } = useAuth();

  const canPreview = useMemo(() => (
    loggedInUser.isAdmin || hasExtendedRight('preview')
  ), [loggedInUser, hasExtendedRight]);

  return canPreview ? (
    <a href={buildPreviewUrlById('content_id', noiseId)} target="_blank" rel="noopener noreferrer">
      <Button title="Xem trước" icon="eye"></Button>
    </a>
  ) : (
    <Button title="Xem trước" icon="eye" disabled></Button>
  );
}

export default memo(ContentPreviewButtonForList);
