
export const ContentFieldLabel = {
  Title: 'Tiêu đề bài viết',
  SubTitle: 'Tiêu đề phụ',
  Description: 'Mô tả bài viết',
  HtmlBody: 'Nội dung bài viết',
  Distributions: 'Chuyên mục',
  Keywords: 'Từ khóa',
  DistributionDate: 'Ngày giờ đăng',
  avatar: 'Ảnh đại diện',
  LongformAvatar: 'Ảnh đại diện Longform',
  SeoEntry: 'Thông tin SEO',
  Source: 'Nguồn tin',
  Author: 'Tác giả',
  RelatedContents: 'Tin liên quan',
  RelatedTopics: 'Chủ đề liên quan',
  Royalty: 'Nhuận bút',
  contentNote: 'Ghi chú bài viết',
  RedirectLink: 'Redirect Link',
  FrameLink: 'Frame Link',
  DisplayType: 'Kiểu hiển thị',
  ContentType: 'Loại tin',
  spellChecker: 'Kiểm tra chính tả',
};

export const ContentHookAction = {
  VALIDATE_BY_ACTION: 'CONTENT:VALIDATE_BY_ACTION',
  BEFORE_GET_CONTENT: 'CONTENT:BEFORE_GET_CONTENT',
  FORMDATA_TO_CONTENT: 'CONTENT:FORMDATA_TO_CONTENT',
  FORM_INPUTS: 'CONTENT:FORM_INPUTS',
  EXT_TOOLBAR_ACTIONS: 'CONTENT:EXT_TOOLBAR_ACTIONS',
};
