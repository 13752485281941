import { Select } from 'antd';
import lodash from 'core/common/lodash';
import React, { forwardRef } from 'react';

const { Option } = Select;

const allCategories = [
  'Arts',
  'Business',
  'Comedy',
  'Education',
  'Fiction',
  'Government',
  'History',
  'Health & Fitness',
  'Kids & Family',
  'Leisure',
  'Music',
  'News',
  'Religion & Spirituality',
  'Science',
  'Society & Culture',
  'Sports',
  'Technology',
  'True Crime',
  'TV & Film',
];

export default forwardRef(function PodcastCategoriesInput({
  value,
  onChange,
}, ref) {

  return (
    <Select
      ref={ref}
      showSearch
      allowClear
      autoClearSearchValue
      mode="tags"
      placeholder="Podcast categories"
      value={value}
      onChange={handleChange}
      filterOption={true}
      tokenSeparators={[',', ';']}
    >
      {allCategories.map(option => (
        <Option key={option}
          value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );

  function handleChange(value) {
    onChange(lodash.uniq(value.map(v => v.trim()).filter(Boolean)));
  }

});
