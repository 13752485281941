import { formatByte } from '@bm/common';
import { Tooltip } from 'antd';
import React, { Fragment, useCallback, useRef } from 'react';
import commonStyles from 'core/common/commonStyles';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import ImageDisplay from 'core/components/common/ImageDisplay';
import FileManagerDialog from 'core/components/file-manager/FileManagerDialog';

const useStyles = makeStyles(() => ({
  imageFrame: {
    width: '50%',
    border: 'dashed grey 2px',
    overflow: 'hidden',
    textAlign: 'center',
  },
  imageContainer: {
    ...commonStyles.flexAllCentered,
    height: '100%',
    cursor: 'pointer',
    '&:hover img': {
      webkitFilter: 'blur(5px)',
      filter: 'blur(5px)',
      transition: '.3s ease-in-out',
    },
    '& > div': {
      display: 'none',
    },
    '&:hover > div': {
      ...commonStyles.flexAllCentered,
      flexDirection: 'column',
      alignItems: 'center',
      position: 'absolute',
      transition: '.3s ease-in-out',
    },
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  info: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderRadius: 3,
    backgroundColor: '#FFFFFF66',
    padding: '0 5px',
    marginBottom: 10,
  },
  placeholder: {
    display: 'inline-block !important',
    color: 'grey',
    fontSize: 36,
  },
}));

export default function ImageBox({ placeholder, image, onChangeImage }) {
  const classes = useStyles();
  const photoDialogRef = useRef();

  const handleClick = useCallback(() => {
    photoDialogRef.current.open();
  }, []);

  return (
    <div className={classes.imageFrame}>
      <Tooltip title="Bấm để chọn ảnh khác" mouseEnterDelay={0.3} mouseLeaveDelay={0}>
        <div className={classes.imageContainer} onClick={handleClick}>
          {image ? (
            <Fragment>
              <ImageDisplay
                src={image.Path}
                alt={image.Name}
                className={classes.image}
              />
              <div>
                <span className={classes.info}>{image.Name}</span>
                <span className={classes.info}>
                  {`${lodash.get(image, 'Meta.width') || 0}px x ${lodash.get(image, 'Meta.height') || 0}px`}
                </span>
                <span className={classes.info}>{formatByte(image.Size)}</span>
              </div>
            </Fragment>
          ) : (
            <div className={classes.placeholder}><span>{placeholder}</span></div>
          )}
        </div>
      </Tooltip>
      <FileManagerDialog
        title={'Thay đổi ' + placeholder}
        ref={photoDialogRef}
        onSelect={onChangeImage}
      />
    </div>
  );
}
