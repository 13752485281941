import { Config } from '@zdocs/core';
import BaseDialogPlugin from 'core/components/editor/plugins/BaseDialogPlugin';
import React from 'react';
import Dialog from './Dialog';

export default class SpellCheckerPlugin extends BaseDialogPlugin {

  static install() {
    Config.prototype.controls.SpellChecker = {
      tooltip: 'Kiểm tra chính tả',
      icon: 'spellcheck',
    };
  }

  get name() {
    return 'SpellCheckerPlugin';
  }

  get command() {
    return 'SpellChecker';
  }

  get defaultOptions() {
    return {
      getContent() {
        return {
          form: undefined,
          Title: '',
          Description: '',
          HtmlBody: '',
          AvatarDescription: '',
        };
      },
    };
  }

  renderDialog(props) {
    const content = this.options.getContent();

    return (
      <Dialog
        {...props}
        content={content}
      />
    );
  }
}
