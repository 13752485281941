import React, { useMemo } from 'react';
import lodash from 'core/common/lodash';
import ZoneDisplay from '../zone/ZoneDisplay';

export default function TopicZoneDisplay({ topic }) {
  const zoneId = useMemo(() => {
    return lodash.get(topic, 'ZoneTopic[0].ZoneID');
  }, [topic]);

  return (
    <ZoneDisplay id={zoneId} />
  );
}
