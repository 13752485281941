
import { httpGet, httpPost } from 'core/common/http-client';

const adsPositionApi = {
  get(id) {
    return httpGet(`/ads-positions/get/item?PositionID=${id}`);
  },
  getList({ limit = 20, offset = 0, sortBy, filters }) {
    const qs = { limit, offset, sortBy, ...filters };
    return httpGet('/ads-positions/get/items-by-range', { qs });
  },
  getListAll() {
    return httpGet('/ads-positions/get/items');
  },
  create(data) {
    return httpPost('/ads-positions/post/insert', data);
  },

  update(data) {
    return httpPost('/ads-positions/post/update', data);
  },

  delete(id) {
    return httpPost('/ads-positions/post/delete', { PositionID: id });
  },
};

export default adsPositionApi;
