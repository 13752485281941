import { Button, Checkbox, Form, Input, InputNumber } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import DeleteButton from 'core/components/common/DeleteButton';
import CommandSelector from './CommandSelector';
import FlexPushRight from 'core/components/common/FlexPushRight';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
  },
  button: {
    marginLeft: 8,
  },
}));

function CommandForm({
  form,
  command,
  handleSubmit,
  handleDelete,
  handleCancel,
}) {
  const classes = useStyles();
  const [isSubmitting, setIsSubbmiting] = useState(false);
  const { getFieldDecorator, validateFields } = form;

  useEffect(() => form.resetFields(), [command]);

  const onSubmit = useCallback(e => {
    e.preventDefault();

    setIsSubbmiting(true);

    validateFields((err, values) => {
      if (err) {
        setIsSubbmiting(false);
      } else {
        handleSubmit({ ...values, CommandID: command.CommandID })
          .then(() => setIsSubbmiting(false));
      }
    });
  }, [validateFields, setIsSubbmiting, command, handleSubmit]);

  const onDelete = useCallback(() => {
    handleDelete(command);
  }, [command]);

  const onReset = useCallback(() => form.resetFields(), [form]);

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      {command.CommandID && (
        <Form.Item label="ID">
          {getFieldDecorator('CommandID', {
            initialValue: command.CommandID,
          })(
            <Input disabled />
          )}
        </Form.Item>
      )}
      <Form.Item label="Tên">
        {getFieldDecorator('Name', {
          initialValue: command.Name,
          rules: [{ required: true, message: 'Required!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Mục cha">
        {getFieldDecorator('ParentID', { initialValue: command.ParentID || 0 })(
          <CommandSelector allowEmpty excludedIds={command.CommandID ? [command.CommandID] : []} />
        )}
      </Form.Item>
      <Form.Item label="Mã lệnh">
        {getFieldDecorator('Command', {
          initialValue: command.Command,
          rule: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Tham biến">
        {getFieldDecorator('Parameters', {
          initialValue: command.Parameters,
          rule: [{ max: 500, message: 'Tối đa 500 kí tự!' }],
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Liên kết">
        {getFieldDecorator('RedirectLink', {
          initialValue: command.RedirectLink,
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Icon">
        {getFieldDecorator('Icon', {
          initialValue: command.Icon,
          rule: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Thứ tự">
        {getFieldDecorator('Order', {
          initialValue: command.Order || 0,
          rules: [{ required: true, message: 'Required!' }],
        })(
          <InputNumber placeholder="Order" />
        )}
      </Form.Item>
      <Form.Item label="Sử dụng">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: command.Enabled,
        })(<Checkbox />)}
      </Form.Item>
      <Form.Item label="Hiển thị">
        {getFieldDecorator('Visible', {
          valuePropName: 'checked',
          initialValue: command.Visible,
        })(<Checkbox />)}
      </Form.Item>
      <Form.Item {...commonStyles.tailFormItemLayout}>
        <div className={classes.toolbar}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            title={command.CommandID ? 'Lưu' : 'Tạo'}
          >{command.CommandID ? 'Lưu' : 'Tạo'}</Button>
          <Button className={classes.button} onClick={onReset} disabled={isSubmitting} title="Đặt lại">
            Đặt lại
          </Button>
          {command.CommandID && (
            <Button className={classes.button} onClick={handleCancel} title="Tạo mới">Tạo mới</Button>
          )}
          <FlexPushRight />
          {command.CommandID && (
            <DeleteButton
              title={`Xóa chức năng: ${command.Name}?`}
              onDelete={onDelete}
            />
          )}
        </div>
      </Form.Item>
    </Form>
  );
}

export default Form.create({ name: 'CommandForm' })(CommandForm);
