import { Button } from 'antd';
import makeStyles from 'core/common/makeStyles';
import AsyncButton from 'core/components/common/AsyncButton';
import AuthzCheck from 'core/components/common/AuthzCheck';
import FeatureFlip from 'core/components/common/FeatureFlip';
import FlexPushRight from 'core/components/common/FlexPushRight';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import { ExtendedRight } from 'core/engine/extended-right/constants';
import React from 'react';
import ContentSelectionToolbarDeleteButton from './ContentSelectionToolbarDeleteButton';
import ContentSelectionToolbarUnpublishButton from './ContentSelectionToolbarUnpublishButton';

const useStyles = makeStyles(() => ({
  selectedText: {
    whiteSpace: 'nowrap',
  },
}));

export default function ContentSelectionToolbar({
  selectedIds,
  contents,
  onClear,
  onCheckout,
  onUnpublish,
  onDelete,
  onUnlockEdit,
}) {
  const classes = useStyles();

  return (
    <TableToolbarWrapper>
      <span className={classes.selectedText}><strong>{selectedIds.length}</strong> tin đã được chọn</span>
      <FlexPushRight />
      <Button onClick={onClear}>Hủy</Button>

      {onCheckout && (
        <AuthzCheck extendedRight={ExtendedRight.FORCE_CHECKOUT}>
          <AsyncButton type="primary" onClick={onCheckout}>Đóng tin</AsyncButton>
        </AuthzCheck>
      )}

      <FeatureFlip name="content-lock-edit">
        {onUnlockEdit ? (
          <AuthzCheck extendedRight="content.unlock-edit">
            <AsyncButton type="primary" onClick={onUnlockEdit}>Bật sửa bài</AsyncButton>
          </AuthzCheck>
        ) : null}
      </FeatureFlip>

      {onDelete && (
        <AuthzCheck extendedRight={ExtendedRight.DELETE}>
          <ContentSelectionToolbarDeleteButton
            selectedIds={selectedIds}
            contents={contents}
            onDelete={onDelete}
          />
        </AuthzCheck>
      )}

      {onUnpublish && (
        <AuthzCheck extendedRight={ExtendedRight.UNPUBLISH}>
          <ContentSelectionToolbarUnpublishButton
            selectedIds={selectedIds}
            contents={contents}
            onUnpublish={onUnpublish}
          />
        </AuthzCheck>
      )}
    </TableToolbarWrapper>
  );
}
