import { Config, ToolbarIcon } from '@zdocs/core';
import BaseDialogPlugin from '../BaseDialogPlugin';
import React from 'react';
import Dialog from './Dialog';
import icon from './icon.svg';

export default class FacebookVideoPlugin extends BaseDialogPlugin {

  static install() {
    ToolbarIcon.setIcon('facebook', icon);

    Config.prototype.controls.facebookVideo = {
      tooltip: 'Chèn Facebook video',
      icon: 'facebook',
    };
  }

  get name() {
    return 'facebookVideo';
  }

  get command() {
    return 'facebookVideo';
  }

  renderDialog(props) {
    return (
      <Dialog
        title="Chèn Facebook video"
        {...props}
      />
    );
  }

  onSelect(embedCode) {
    super.onSelect();

    if (embedCode) {
      this.insertHTMLToEditor(embedCode, false, false);
    }
  }
}
