import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { getTreeListEnabledZoneCache } from 'core/engine/zone/caching';
import { Checkbox, Transfer } from 'antd';
import makeStyles from 'core/common/makeStyles';
import DraggableTable from 'core/components/table/DraggableTable';
import ZoneTree from 'core/components/zone/ZoneTree';
import lodash from 'core/common/lodash';

const useStyles = makeStyles(() => ({
  transfer: {
    display: 'block',
    '& > .ant-transfer-operation > button': {
      width: '100px',
    },
    '& .ant-transfer-list-body-customize-wrapper': {
      padding: 0,
    },
  },
  zoneTree: {
    overflowY: 'auto',
    maxHeight: 260,
  },
  dragTable: {
    maxHeight: 260,
  },
}));

export default forwardRef(function ZoneTransfer({ value, onChange, rootZoneId = 0, lazy = false, ...props }, ref) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const zonesRef = useRef([]);
  const zoneIds = useMemo(() => value ? value.split(',').map(item => parseInt(item)) : [], [value]);

  useEffect(() => {
    getTreeListEnabledZoneCache(rootZoneId).then((items) => {
      zonesRef.current = items;
      setOptions(items.map(item => ({ value: item.ZoneID, label: '----'.repeat(item.Level) + item.Name })));
    });
  }, [rootZoneId]);

  return (
    <Transfer
      ref={ref}
      className={classes.transfer}
      lazy={lazy}
      showSearch={false}
      dataSource={options}
      rowKey={item => item.value}
      render={item => item.label}
      targetKeys={zoneIds}
      onChange={handleChange}
      operations={['Thêm (+)', 'Xóa (-)']}
      listStyle={{
        width: 300,
        height: 300,
        flex: 'unset',
      }}
      locale={{
        itemsUnit: ' chuyên mục',
        notFoundContent: 'Không tìm thấy',
        searchPlaceholder: 'Tìm kiếm...',
      }}
      {...props}
    >
      {({
        direction,
        filteredItems,
        onItemSelect,
        selectedKeys,
      }) => {
        if (direction === 'right') {
          const columns = [
            {
              Cell: ({ original }) => (
                <Checkbox
                  name={original.value + ''}
                  checked={selectedKeys.includes(original.value)}
                />
              ),
              width: 30,
              style: {
                justifyContent: 'center',
                paddingLeft: 11,
                paddingRight: 3,
                border: 'none',
              },
            },
            {
              accessor: 'label',
            },
          ];

          const handleRowClick = (event, rowInfo) => {
            const { value } = rowInfo.original;
            onItemSelect(value, !selectedKeys.includes(value));
          };

          return (
            <DraggableTable
              idField="value"
              data={filteredItems}
              columns={columns}
              onMove={(data) => handleChange(data.map(item => item.value))}
              TheadComponent={() => null}
              className={classes.dragTable}
              fullHeight={false}
              onRowClick={handleRowClick}
              showDragIcon="right"
            />
          );
        } else if (direction === 'left') {
          const checkedKeys = [...selectedKeys, ...zoneIds];

          return (
            <ZoneTree
              className={classes.zoneTree}
              blockNode
              checkable
              checkStrictly
              defaultExpandAll
              checkedKeys={checkedKeys}
              onCheck={handleCheck}
              onSelect={handleCheck}
              disabledKeys={zoneIds}
              rootZoneId={rootZoneId}
            />
          );

          function handleCheck(keys, event) {
            const eventKey = parseInt(lodash.get(event, 'node.props.eventKey'));
            onItemSelect(eventKey, !isChecked(checkedKeys, eventKey));
          }

          function isChecked(selectedKeys, eventKey) {
            return selectedKeys.indexOf(eventKey) !== -1;
          }
        }
      }}
    </Transfer>
  );

  function handleChange(newValue, direction, moveKeys) {
    if (direction === 'right' && moveKeys) {
      let addedZones = zonesRef.current.filter(zone => moveKeys.includes(zone.ZoneID));
      newValue.splice(0, moveKeys.length);

      addedZones = addedZones.filter(zone => {
        const zoneId = zone.ZoneID;
        const parentId = zone.ParentID;

        if (parentId > 0) {
          const parentIndex = newValue.findIndex(id => id === parentId);

          if (parentIndex > -1) {
            newValue.splice(parentIndex + 1, 0, zoneId);
            return false;
          }
        }

        return true;
      });

      newValue.unshift(addedZones.map(zone => zone.ZoneID));
    }

    onChange(newValue.join(','));
  }
});
