import { Empty, Spin } from 'antd';
import React, { useState } from 'react';
import makeStyles from 'core/common/makeStyles';
import commonStyles from 'core/common/commonStyles';
import classnames from 'core/common/classnames';
import { blue } from '@ant-design/colors';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexRow,
    alignItems: 'center',
    overflowX: 'auto',
    width: '100%',
    height: 98,

    '& > *:not(:first-child)': {
      marginLeft: 2,
    },
    '& > *:not(:last-child)': {
      marginRight: 2,
    },
  },
  item: {
    width: 160,
    height: 90,
    cursor: 'pointer',
    flex: 'none',

    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  selected: {
    padding: 10,
    backgroundColor: blue.primary,
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  empty: {
    margin: 0,
  },
}));

export default function VideoPosterSuggestionSelector({ loading, posters, onSelect }) {
  const classes = useStyles();

  const [selectedUrl, setSelectedUrl] = useState();

  if (loading) {
    return (
      <div className={classnames(classes.container, classes.spaceAround)}><Spin /></div>
    );
  }

  if (posters.length === 0) {
    return (
      <div className={classnames(classes.container, classes.spaceAround)}>
        <Empty className={classes.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} description="Không có ảnh nào được đề xuất." />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {posters.map(poster => (
        <div
          key={poster.url}
          className={classnames(classes.item, poster.url === selectedUrl && classes.selected)}
        >
          <img src={poster.url} alt="" onClick={() => handleSelect(poster.url)} />
        </div>
      ))}
    </div>
  );

  function handleSelect(url) {
    setSelectedUrl(url);
    onSelect(url);
  }
}
