
import { httpDownload, httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_LOOKUP = [
  'AdsZone.OptionID',
  'AdsZone.ZoneID',
];

const adsOptionApi = {
  get(id) {
    return httpGet(`/ads-options/get/item?OptionID=${id}`);
  },

  getList({ limit = 20, offset = 0, sortBy, lookup = [], filters }) {
    const qs = {
      limit, offset, sortBy,
      lookup: [...DEFAULT_LOOKUP, ...lookup],
      ...filters,
    };

    return httpGet('/ads-options/get/items-by-range', { qs });
  },

  export({ sortBy, lookup = [], filters, filename }) {
    const qs = { sortBy, lookup: [...DEFAULT_LOOKUP, ...lookup], ...filters };
    return httpDownload('/ads-options/get/export', { qs, filename });
  },

  submit(data) {
    return httpPost('/ads-options/post/submit', data);
  },

  delete(id) {
    return httpPost('/ads-options/post/delete', { OptionID: id });
  },
};

export default adsOptionApi;
