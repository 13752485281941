import InterviewListView from './components/InterviewListView';
import InterviewGuestListView from './components/interview-guests/InterviewGuestListView';
import InterviewQuestionListView from './components/interview-questions/InterviewQuestionListView';
import InterviewAnswerListView from './components/interview-answers/InterviewAnswerListView';

export default [
  { path: '/operations/interviews', component: InterviewListView },
  { path: '/operations/interviews/guests', component: InterviewGuestListView },
  { path: '/operations/interviews/questions', component: InterviewQuestionListView },
  { path: '/operations/interviews/answers', component: InterviewAnswerListView },
];
