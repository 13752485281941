import { useMemo } from 'react';
import { widgetRegistry } from 'core/modules/operations/widgets/registry';

export default function WidgetTypeDisplay({ type }) {
  return useMemo(() => {
    const widget = widgetRegistry.get(type);

    return widget ? widget.name : (type || null);
  }, [type]);
}
