export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

export function isString(value) {
  return typeof value === 'string';
}

/**
 * Check if a string is html or not
 *
 * @method isHTML
 * @param {string} str
 * @return {boolean}
 */
export const isHTML = (str) =>
  isString(str) &&
  /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/m.test(
    str.replace(/[\r\n]/g, '')
  );
