import { Form } from 'antd';
import { emptyArray } from 'core/common/empty';
import ContentFormTagsInput from 'core/modules/contents/components/form/keywords/ContentFormTagsInput';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React from 'react';

export default function ContentFormItemKeywords({ content, form }) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={ContentFieldLabel.Keywords}>
      {getFieldDecorator('Keywords', {
        initialValue: content.Keywords ? content.Keywords.split(',') : emptyArray,
        rules: [{ validator: keywordsValidator }],
      })(
        <ContentFormTagsInput />
      )}
    </Form.Item>
  );

  function keywordsValidator(rule, value, callback) {
    if (!value || !value.length) {
      return callback(new Error('Chưa nhập từ khóa!'));
    }

    if (value.length > 2000) {
      return callback(new Error('Tối đa 2000 kí tự!'));
    }

    callback();
  }
}
