import React, { useEffect } from 'react';
import { Input, Select } from 'antd';
import makeStyles from 'core/common/makeStyles';
import useForm from 'core/hooks/useForm';

const { Group, Search } = Input;
const { Option } = Select;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex !important',
    width: 360,
  },
  select: {
    width: 120,
  },
  input: {
    width: '240px !important',
  },
}));

export default function CommentSearcField({
  type,
  value,
  onSearch,
}) {
  const classes = useStyles();
  const { formData, setFormData, handleChange } = useForm();

  useEffect(() => {
    setFormData({ type, value });
  }, [type, value]);

  function handleSearch(value) {
    onSearch({ ...formData, value });
  }

  return (
    <Group compact className={classes.container}>
      <Select className={classes.select} value={formData.type} onChange={value => handleChange('type', value)}>
        <Option value="Title">Tiêu đề</Option>
        <Option value="ObjectID">ID bài viết</Option>
        <Option value="UserDisplayName">Tên tác giả</Option>
        <Option value="UserEmail">Email tác giả</Option>
      </Select>
      <Search
        name="value"
        placeholder="Tìm kiếm..."
        className={classes.input}
        value={formData.value}
        onChange={handleChange}
        onSearch={handleSearch}
        allowClear
      />
    </Group>

  );
}
