import lodash from 'core/common/lodash';
import authorApi from './api';

export const getListAuthorCache = lodash.memoize(() => {
  return authorApi.getList({
    limit: 1000,
    fields: ['AuthorID', 'Pseudonym', 'FullName', 'ParentID', 'Enabled'],
  }).then(resp => resp.data.items);
});

export const getAuthorCache = lodash.memoize((authorId) =>
  authorApi.get(authorId).then(resp => resp.data.item)
);
