import { Checkbox } from 'antd';
import { commonColumns, sortingToSortBy } from 'core/common/listUtils';
import BaseDialog from 'core/components/common/BaseDialog';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import { OnEnterFilterForList } from 'core/components/common/OnEnterFilter';
import SelectableTable from 'core/components/table/SelectableTable';
import liveApi from 'core/engine/live/api';
import useDialog from 'core/hooks/useDialog';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { forwardRef, useImperativeHandle } from 'react';

const columns = [
  {
    Header: 'ID',
    accessor: 'LiveID',
    maxWidth: 75,
    filterable: true,
    Filter: OnEnterFilterForList,
    ...commonColumns.allCentered,
  },
  {
    Header: 'Tên',
    accessor: 'Title',
    filterable: true,
    Filter: OnEnterFilterForList,
  },
  {
    id: 'StartDate',
    Header: 'Từ ngày',
    sortable: true,
    Cell: ({ original }) => <DateTimeDisplay timestamp={original.StartDate} />,
    maxWidth: 120,
  },
  {
    id: 'EndDate',
    Header: 'Đến ngày',
    sortable: true,
    Cell: ({ original }) => original.EndDate ? <DateTimeDisplay timestamp={original.EndDate} /> : null,
    maxWidth: 120,
  },
  {
    Header: 'Kích hoạt',
    Cell: ({ original }) => <Checkbox checked={original.Enabled} />,
    width: 80,
    ...commonColumns.allCentered,
  },
];

function listFn({ limit, offset, filters, sorting }) {
  return liveApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default forwardRef(function LiveDialog({ title, onSelect, onCancel }, ref) {
  const { open, handleClose, handleOpen } = useDialog();

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultSorting: {
      LiveID: 'DESC',
    },
  });

  return (
    <BaseDialog
      focusTriggerAfterClose={false}
      keyboard
      maskClosable
      fullHeightFixed
      paddingTop={0}
      visible={open}
      title={title}
      width={900}
      onCancel={onCancel}
      footer={null}
    >
      <SelectableTable
        {...useListProps(listProps)}
        idField="LiveID"
        onSelect={onSelect}
        columns={columns}
      />
    </BaseDialog>
  );
});
