import { Button } from 'antd';
import { commonColumns } from 'core/common/listUtils';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import DeleteButton from 'core/components/common/DeleteButton';
import BaseTable from 'core/components/table/BaseTable';
import React from 'react';
import { Link } from 'react-router-dom';
import LiveEmbeddedContentListButtonForList from './LiveEmbeddedContentListButtonForList';
import LiveFormDialogButton from './LiveFormDialogButton';

export default function LiveList({ onUpdate, onDelete, ...props }) {
  const columns = [
    commonColumns.order,
    {
      Header: 'ID',
      accessor: 'LiveID',
      maxWidth: 100,
    },
    {
      Header: 'Tên',
      accessor: 'Title',
    },
    {
      id: 'StartDate',
      Header: 'Từ ngày',
      sortable: true,
      Cell: ({ original }) => <DateTimeDisplay timestamp={original.StartDate} />,
      maxWidth: 150,
    },
    {
      id: 'EndDate',
      Header: 'Đến ngày',
      sortable: true,
      Cell: ({ original }) => original.EndDate ? <DateTimeDisplay timestamp={original.EndDate} /> : null,
      maxWidth: 150,
    },
    {
      Header: 'Trạng thái',
      Cell: ({ original }) => original.Enabled ? 'Đã kích hoạt' : 'Chưa kích hoạt',
      maxWidth: 150,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper align="right">
          <Link to={'/operations/live-streams?liveId=' + original.LiveID}>
            <Button
              title="Chi tiết tường thuật"
              icon="ordered-list"
            />
          </Link>
          <LiveEmbeddedContentListButtonForList liveId={original.LiveID} />
          <LiveFormDialogButton live={original} onDone={onUpdate} />
          <DeleteButton title={`Xóa tường thuật: ${original.Title}?`} onDelete={() => onDelete(original)} />
        </ActionsCellWrapper>
      ),
      width: 175,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
