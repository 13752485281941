import { httpGet, httpPost } from 'core/common/http-client';

const regionApi = {
  get(id) {
    return httpGet(`/regions/get/item?RegionID=${id}`);
  },

  getList({ limit = 1000, offset = 0, sortBy, filters }) {
    const qs = { limit, offset, sortBy, ...filters };
    return httpGet('/regions/get/items-by-range', { qs });
  },

  getListAll() {
    return httpGet('/regions/get/items');
  },

  create(data) {
    return httpPost('/regions/post/insert', data);
  },

  update(data) {
    return httpPost('/regions/post/update', data);
  },

  delete(id) {
    return httpPost('/regions/post/delete', { RegionID: id });
  },
};

export default regionApi;
