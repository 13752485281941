import BasePlugin from './BasePlugin';
import ReactDOM from 'react-dom';
import React, { PureComponent, createRef } from 'react';
import { Provider } from 'react-redux';
import { getCurrentStore } from 'core/redux/store';

export default class BaseDialogPlugin extends BasePlugin {
  constructor(zdocs) {
    super(zdocs);

    this.callback = this.callback.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onCancel = this.onCancel.bind(this);

    zdocs.registerCommand(this.command, this.callback);
    this.dialogRef = createRef();
    this.store = getCurrentStore();
  }

  get command() {
    return '';
  }

  get dialog() {
    return this.dialogRef.current;
  }

  callback() {
    this.selectionInfo = this.zdocs.selection.save();
    this.openDialog();

    return false;
  }

  renderDialog(props) {
    return (<PureComponent {...props} />);
  }

  openDialog(customProps) {
    if (this.dialog) {
      ReactDOM.unmountComponentAtNode(this.mountNode);
    }

    ReactDOM.render(
      <Provider store={this.store}>
        {this.renderDialog({
          ref: this.dialogRef,
          onSelect: this.onSelect,
          onCancel: this.onCancel,
          ...customProps,
        })}
      </Provider>
      , this.mountNode);

    this.dialog.open();
  }

  onSelect() {
    if (this.dialog) {
      this.dialog.close();
    }

    if (this.selectionInfo) {
      this.zdocs.selection.restore(this.selectionInfo);
    }
  }

  onCancel() {
    if (this.dialog) {
      this.dialog.close();
    }

    if (this.selectionInfo) {
      this.zdocs.selection.restore(this.selectionInfo);
    }
  }

  afterInit() {
    super.afterInit();

    this.mountNode = document.createElement('div');
    document.body.appendChild(this.mountNode);
  }

  destruct() {
    super.destruct();

    const unmountResult = ReactDOM.unmountComponentAtNode(this.mountNode);

    if (unmountResult && this.mountNode.parentNode) {
      this.mountNode.parentNode.removeChild(this.mountNode);
    }
  }
}
