import { AutoComplete } from 'antd';
import React, { forwardRef, Fragment, useEffect, useMemo, useState } from 'react';
import makeStyles from 'core/common/makeStyles';
import contentSourceApi from 'core/engine/content-source/api';
import useDebounce from 'core/hooks/useDebounce';
import LinkInput from 'core/components/common/LinkInput';
import lodash from 'core/common/lodash';
import { CONTENT_FORM_SIDEBAR_INPUT_LIST } from 'core/config';

const { Option } = AutoComplete;

const useStyles = makeStyles(() => ({
  container: {
    '& .ant-select-search__field__mirror': {
      whiteSpace: 'normal',
    },
  },
}));

export default forwardRef(function ContentFormSourceInput({
  value,
  onChange,
  form,
}, ref) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    if (debouncedSearch) {
      getApi(debouncedSearch)
        .then((resp) => {
          if (resp.data) {
            setOptions(resp.data);
          } else {
            setOptions([]);
          }
        });
    } else {
      setOptions([]);
    }
  }, [debouncedSearch]);

  const contentSourceUrl = useMemo(() => {
    return CONTENT_FORM_SIDEBAR_INPUT_LIST.includes('SourceUrl');
  }, [CONTENT_FORM_SIDEBAR_INPUT_LIST]);

  return (
    <Fragment>
      <AutoComplete
        ref={ref}
        allowClear
        placeholder="Nhập nguồn tin"
        value={value}
        dataSource={search && options.map(renderOption)}
        onChange={handleChange}
        onSelect={handleChange}
        onSearch={handleSearch}
        onBlur={onBlur}
        className={classes.container}
      >
      </AutoComplete>
      {contentSourceUrl && (
        <LinkInput
          value={form.getFieldValue('SourceUrl')}
          onChange={value => form.setFieldsValue({ SourceUrl: value })}
          placeholder="Đường dẫn"
        />
      )}
    </Fragment>
  );

  function renderOption(item) {
    return (
      <Option key={item.Name}>
        {item.Name}
      </Option>
    );
  }

  function getApi(keyword) {
    return contentSourceApi.suggest(keyword);
  }

  function handleChange(value) {
    if (value) {
      onChange(value);

      const option = options.filter(item => item.Name === value);
      const newUrl = lodash.get(option, '0.Url');

      if (contentSourceUrl && newUrl) {
        form.setFieldsValue({ SourceUrl: newUrl });
      }
    } else {
      onChange('');
    }
  }

  function handleSearch(value) {
    setSearch(value);
  }

  function onBlur() {
    setSearch('');
  }
});
