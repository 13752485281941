import { IS_MOBILE } from '@bm/common';
import { Checkbox } from 'antd';
import { connectSelectorFilterForList } from 'core/common/hocs';
import { commonColumns } from 'core/common/listUtils';
import AdsOptionTypeDisplay from 'core/components/ads-option/AdsOptionTypeDisplay';
import AdsPositionDisplay from 'core/components/ads-option/AdsPositionDisplay';
import AdsPositionSelector from 'core/components/ads-option/AdsPositionSelector';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import AvatarDisplay from 'core/components/common/AvatarDisplay';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import DeleteButton from 'core/components/common/DeleteButton';
import { OnEnterFilterForList } from 'core/components/common/OnEnterFilter';
import BaseTable from 'core/components/table/BaseTable';
import React, { useMemo } from 'react';
import AdsOptionFormDialogButton from './AdsOptionFormDialogButton';
import makeStyles from 'core/common/makeStyles';
import { red } from '@ant-design/colors';
import classnames from 'core/common/classnames';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  expired: {
    color: red[7],
  },
}));

export default function AdsOptionList({ onUpdate, onDelete, ...props }) {
  const classes = useStyles();

  const now = useMemo(() => moment.now(), []);

  const columns = [
    {
      show: !IS_MOBILE,
      Header: 'Ảnh',
      width: 64,
      Cell: ({ original }) => (<AvatarDisplay url={original.ImageUrl} />),
      ...commonColumns.allCentered,
    },
    {
      Header: 'Tên quảng cáo',
      accessor: 'Title',
      filterable: true,
      Filter: OnEnterFilterForList,
      minWidth: 120,
    },
    {
      id: 'PositionID',
      Header: 'Vị trí',
      filterable: true,
      Filter: connectSelectorFilterForList(AdsPositionSelector),
      Cell: ({ original }) => <AdsPositionDisplay id={original.PositionID} />,
      maxWidth: 300,
      minWidth: 150,
    },
    {
      id: 'Width',
      Header: 'Rộng',
      accessor: 'Width',
      maxWidth: 80,
      minWidth: 50,
    },
    {
      id: 'Height',
      Header: 'Cao',
      accessor: 'Height',
      maxWidth: 80,
      minWidth: 50,
    },
    {
      id: 'StartDate',
      Header: 'Từ ngày',
      sortable: true,
      Cell: ({ original }) => <DateTimeDisplay timestamp={original.StartDate} />,
      width: 120,
    },
    {
      id: 'EndDate',
      Header: 'Đến ngày',
      sortable: true,
      Cell: ({ original }) => original.EndDate && (
        <span className={classnames(original.EndDate < now && classes.expired)}>
          <DateTimeDisplay timestamp={original.EndDate} />
        </span>
      ),
      width: 120,
    },
    {
      id: 'Type',
      Header: 'Kiểu',
      sortable: true,
      Cell: ({ original }) => <AdsOptionTypeDisplay type={original.Type} useIcon />,
      maxWidth: 60,
      ...commonColumns.allCentered,
    },
    {
      Header: 'Thứ tự',
      accessor: 'Order',
      maxWidth: 70,
      minWidth: 60,
      ...commonColumns.allCentered,
    },
    {
      Header: 'Click',
      accessor: 'AdsOptionStat.ClickCount',
      maxWidth: 80,
      minWidth: 50,
    },
    {
      Header: 'Hiển thị',
      accessor: 'Enabled',
      Cell: ({ original }) => <Checkbox checked={original.Enabled} />,
      width: 60,
      ...commonColumns.allCentered,
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <AdsOptionFormDialogButton adsOption={original} onDone={onUpdate} />
          <DeleteButton title={`Xóa quảng cáo: ${original.Title}?`} onDelete={() => onDelete(original)} />
        </ActionsCellWrapper>
      ),
      maxWidth: 120,
      minWidth: 100,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
