import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Container from 'core/components/common/Container';
import authApi from 'core/engine/auth/api';
import { LoginStatus } from 'core/constants';
import { Spin, message, Typography, Card } from 'antd';
import LoginForm from '../common/LoginForm';
import commonStyles from 'core/common/commonStyles';
import useAuth from 'core/hooks/useAuth';

const { Title } = Typography;

const Status = {
  CHECKING: 1,
  LOGGING_IN: 2,
  LOGGED_IN: 3,
  NOT_LOGGED_IN: 4,
};

const styles = {
  content: {
    ...commonStyles.flexAllCentered,
    flexDirection: 'column',
    minHeight: '50vh',
  },
  body: {
    minWidth: 300,
    maxWidth: 450,
    width: '100%',
  },
};

export default function LoginView() {
  const { login } = useAuth();
  const [status, setStatus] = useState(Status.CHECKING);
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    _checkLoginStatus();
  }, []);

  let content;

  switch (status) {
    case Status.LOGGED_IN:
      return <Redirect to={from} />;

    case Status.CHECKING:
      content = (
        <div style={styles.content}>
          <Title level={4}>Đang kiểm tra thông tin đăng nhập...</Title>
          <Spin size="large" />
        </div>
      );
      break;

    case Status.LOGGING_IN:
      content = (
        <div style={styles.content}>
          <Title level={4}>Đang đăng nhập...</Title>
          <Spin size="large" />
        </div>
      );
      break;

    case Status.NOT_LOGGED_IN:
      content = (
        <Card style={styles.content} bodyStyle={styles.body}>
          <Title level={4}>Đăng nhập để tiếp tục...</Title>
          <LoginForm submitFn={submitFn} />
        </Card>
      );
      break;

    default:
      break;
  }

  return (
    <Container>{content}</Container>
  );

  function _checkLoginStatus() {
    authApi.getLoginStatus().then((resp) => {
      if (resp.status === LoginStatus.LOGGED_IN) {
        login(resp.user);
        setStatus(Status.LOGGED_IN);
      } else {
        setStatus(Status.NOT_LOGGED_IN);
      }
    });
  }

  function submitFn({ formData }) {
    return authApi.login(formData)
      .then((user) => {
        login(user);
        setStatus(Status.LOGGED_IN);
      })
      .catch((err) => {
        message.error(err.error_message);
      });
  }
}
