import { message } from 'antd';
import logger from 'core/common/logger';

export function asyncAction(title, action, {
  logError = true,
  showErrorDetail = true,
  slowTimeout = 1000,
  silent = false,
  silentError = false,
  silentSuccess = false,
  silentProgress = false,
  rejectOnError = false,
} = {}) {
  let hideLoading;
  const timeoutId = setTimeout(() => {
    if (!silent && !silentProgress) {
      hideLoading = message.loading(`Đang ${lowerCaseFirstChar(title)}...`, 0);
    }
  }, slowTimeout);

  return Promise.resolve()
    .then(() => action())
    .then((data) => {
      clearTimeout(timeoutId);
      hideLoading && hideLoading();
      (!silent && !silentSuccess) && message.success(`${title} thành công!`);

      return data;
    })
    .catch((err) => {
      clearTimeout(timeoutId);
      hideLoading && hideLoading();
      logError && logger.error(`${title} failed:`, err);

      const errorMessage = err.error_message || err.message;

      if (showErrorDetail && errorMessage) {
        (!silent && !silentError) && message.error(`${title} thất bại: ${errorMessage}`);
      } else {
        (!silent && !silentError) && message.error(`${title} thất bại!`);
      }

      if (rejectOnError) {
        return Promise.reject(err);
      }
    });
}

function lowerCaseFirstChar(str = '') {
  return str[0].toLocaleLowerCase() + str.substring(1);
}
