function isElement(object) {
  if (!object) {
    return false;
  }

  return object instanceof Element || object instanceof HTMLElement || object instanceof HTMLDocument;
}

function createElementFromHtml(htmlString) {
  if (isElement(htmlString)) {
    return htmlString;
  }

  const div = document.createElement('div');
  div.innerHTML = htmlString;

  return div.firstChild;
}

function unwrap(wrapper) {
  // place childNodes in document fragment
  const docFrag = document.createDocumentFragment();

  while (wrapper.firstChild) {
    const child = wrapper.removeChild(wrapper.firstChild);
    docFrag.appendChild(child);
  }

  // replace wrapper with document fragment
  wrapper.parentNode.replaceChild(docFrag, wrapper);
}

function cleanAttributes(element, excluded = []) {
  Array.prototype.forEach.call(element.attributes, (attr) => {
    if (!excluded.includes(attr.name) && !attr.name.startsWith('data-')) {
      element.removeAttribute(attr.name);
    }
  });
}

function addClass(element, className) {
  if (!element.classList.contains(className)) {
    element.classList.add(className);
  }
}

function removeClass(element, className) {
  if (element.classList.contains(className)) {
    element.classList.remove(className);
  }
}

function toggleClass(element, className) {
  element && element.classList && element.classList.toggle(className);
}

function hasClass(element, className) {
  return element && element.classList && element.classList.contains(className);
}

function isEmptyNode(node, containers = ['P', 'DIV']) {
  if (!node) {
    return true;
  }

  if (node.tagName === 'BR') {
    return true;
  }

  if (node.nodeType === Node.TEXT_NODE) {
    return isEmptyString(node.nodeValue);
  }

  if (isEmptyTextNode(node)) {
    return true;
  }

  if (containers.includes(node.tagName)) {
    return Array.prototype.every.call(node.childNodes, child => isEmptyNode(child, containers));
  }

  return false;
}

function isEmptyTextNode(node) {
  if (node.childNodes.length === 0) {
    return ['EM', 'STRONG', 'BR', 'A', 'B', 'I', 'SPAN', 'U', 'S', 'SUP', 'SUB'].includes(node.tagName) && isEmptyString(node.innerText);
  }

  return Array.prototype.every.call(node.childNodes, child => isEmptyTextNode(child));
}

function isEmptyString(str) {
  return !str || (typeof str === 'string' && str.replace(/&nbsp;/g, '').trim() === '');
}

const domUtils = {
  createElementFromHtml,
  unwrap,
  cleanAttributes,
  addClass,
  removeClass,
  toggleClass,
  hasClass,
  isEmptyNode,
  isEmptyString,
};

export default domUtils;
