import PrivateRoute from 'core/components/common/PrivateRoute';
import routesRegistry from 'core/registries/routes';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ForbiddenView from '../views/ForbiddenView';
import HomeView from '../views/HomeView';
import LoginView from '../views/LoginView';
import NotFoundView from '../views/NotFoundView';
import OTPView from '../views/OTPView';

export default function Routes() {
  return (
    <Switch>
      <PrivateRoute exact authzCheck={false} path="/" component={HomeView} />
      <PrivateRoute authzCheck={false} path="/forbidden" component={ForbiddenView} />
      <Route path="/login" component={LoginView} />
      <Route path="/otp" component={OTPView} />
      {routesRegistry.getAll().map((route) => (
        <PrivateRoute key={route.path} exact {...route} />
      ))}
      <Route path="*" component={NotFoundView} />
    </Switch>
  );
}
