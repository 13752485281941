import ZDocs from '@zdocs/core';
import BasePlugin from '../BasePlugin';
import { IS_MOBILE } from '@bm/common';
import lodash from 'core/common/lodash';

const ContextMenuModule = ZDocs.modules.ContextMenu;

export default class ContextMenuPlugin extends BasePlugin {
  get defaultOptions() {
    return {
      tagsName: [
        'p',
        'h1',
        'h2',
        'h3',
        'h4',
      ],
    };
  }

  static Events = {
    ADD_BUTTON: 'ContextMenuPlugin_addButton',
  };

  constructor(zdocs) {
    super(zdocs);

    this.menu = null;
    this.container = null;

    this.onContext = this.onContext.bind(this);
    this.addButton = this.addButton.bind(this);

    zdocs.events.on(zdocs.editor, 'contextmenu', this.onContext);
    zdocs.events.on(ContextMenuPlugin.Events.ADD_BUTTON, this.addButton);

    this.buttons = [
      {
        order: 10,
        icon: 'notebox',
        title: 'Box thông tin',
        exec: () => {
          this.zdocs.execCommand('formatblock', null, 'notebox_c');
        },
      },
      {
        order: 20,
        icon: 'quote',
        title: 'Trích dẫn',
        exec: () => {
          this.zdocs.execCommand('formatblock', null, 'blockquote');
        },
      },
      {
        order: 30,
        icon: 'eraser',
        title: 'Xóa format',
        exec: () => {
          this.zdocs.execCommand('formatblock', null, 'p');
        },
      },
    ];
  }

  addButton(button) {
    this.buttons.push(button);
    this.buttons = lodash.orderBy(this.buttons, 'order');
  }

  onContext(event) {
    !IS_MOBILE && event.preventDefault();
    if (this.zdocs.options.contextMenu === false) return true;

    if (this.zdocs.getRealMode() !== ZDocs.MODE_WYSIWYG || this.zdocs.getReadOnly()) {
      return true;
    }

    const target = event.target;
    const tagName = target.tagName.toLowerCase();

    if (!target || this.options.tagsName.indexOf(tagName) <= -1) {
      return true;
    }

    const parentNode = target.parentNode;

    if (!parentNode || parentNode !== this.zdocs.editor) {
      return true;
    }

    if (!this.menu) {
      this.menu = new ContextMenuModule(this.zdocs);
    }

    setTimeout(() => {
      this.menu.show(event.clientX, event.clientY, this.buttons);
    }, 200);

    return IS_MOBILE;
  }

  destruct() {
    this.menu = null;
    this.container = null;
  }
}
