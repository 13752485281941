import { blue, green } from '@ant-design/colors';
import React, { memo, useCallback } from 'react';
import classnames from 'core/common/classnames';
import commonStyles from 'core/common/commonStyles';
import { emptyFunction } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import ByteDisplay from '../common/ByteDisplay';
import DateTimeDisplay from '../common/DateTimeDisplay';
import FlexPushRight from '../common/FlexPushRight';
import MimeTypeIcon from '../common/MimeTypeIcon';
import Separator from '../common/Separator';
import FileSelectedDot from './FileSelectedDot';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexRow,
    width: '100%',
    cursor: 'pointer',
    padding: '5px 2px',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: blue[0],
    },

    '&:not(:last-child)': {
      borderBottom: '1px solid #e8e8e8',
    },
  },
  editing: {
    backgroundColor: blue[1],
  },
  isNew: {
    backgroundColor: green[0],
  },
  fileName: {
    ...commonStyles.ellipsis,
  },
  fileIcon: {
    fontSize: 18,
  },
  checkIcon: {
    fontSize: 18,
    color: blue.primary,
  },
  date: {
    width: 120,
    flexShrink: 0,
  },
  byte: {
    width: 100,
    '@media screen and (max-width: 723px)': {
      display: 'none',
    },
    flexShrink: 0,
    textAlign: 'right',
  },
}));

export default memo(function FileRowItem({
  file,
  selected = false,
  editing = false,
  onSelect = emptyFunction,
}) {
  const classes = useStyles();

  const handleSelect = useCallback(() => {
    onSelect(file);
  }, [file, onSelect]);

  return (
    <div className={classnames(classes.container, editing && classes.editing, file.isNew && classes.isNew)} onClick={handleSelect}>
      {selected ? (
        <FileSelectedDot number={selected} />
      ) : (
        <MimeTypeIcon mimeType={file.MimeType} className={classes.fileIcon} />
      )}

      <Separator />
      <span title={file.Name} className={classes.fileName}>{file.Name}</span>
      <FlexPushRight />
      <span className={classes.date}><DateTimeDisplay timestamp={file.CreatedDate} /></span>
      <Separator />
      <span className={classes.byte}><ByteDisplay byte={file.Size} /></span>
    </div>
  );
});
