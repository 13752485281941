import React, { useEffect, useState, forwardRef } from 'react';
import Selector from '../common/Selector';
import { getLiveStreamTypesCache } from 'core/engine/live-stream/caching';

export default forwardRef(function LiveStreamTypeSelector({ value, onChange, ...props }, ref) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getLiveStreamTypesCache().then((resp) => {
      setOptions(resp.data.items);
    });
  }, []);

  return (
    <Selector
      ref={ref}
      valueField="id"
      labelField="value"
      options={options}
      value={value}
      onChange={onChange}
      valueIsNumber
      {...props}
    />
  );
});
