import { Layout, message, Spin } from 'antd';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import { BASE_DIR, BROWSER_ROUTER } from 'core/config';
import { HEADER_HEIGHT, MediaQuery } from 'core/constants';
import useLoadServerConfig from 'core/hooks/useLoadServerConfig';
import useSidebar from 'core/hooks/useSidebar';
import React, { useCallback } from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import BreadCrump from '../common/BreadCrump';
import ErrorLogger from '../common/ErrorLogger';
import LoginDialog from '../common/LoginDialog';
import RefreshSessionDialog from '../common/RefreshSessionDialog';
import RefreshClientDialog from '../common/RefreshClientDialog';
import SyncLastLocation from '../common/SyncLastLocation';
import Header from '../header/Header';
import Routes from './Routes';
import Sidebar from './Sidebar';

const Router = BROWSER_ROUTER ? BrowserRouter : HashRouter;

const useStyles = makeStyles(() => ({
  main: {
    height: '100vh',
  },
  sidebar: {
    height: '100vh',
  },
  header: {
    height: HEADER_HEIGHT,
  },
  contentWrapper: {
    padding: '0 12px 0 12px',
    background: '#fff',
    overflow: 'auto',
    borderBottomColor: '#fff',
    borderBottomStyle: 'solid',
    borderBottomWidth: 12,
    [MediaQuery.md]: {
      padding: '0 24px 0 24px',
      borderBottomWidth: 24,
    },
  },
  content: {
    margin: 0,
    minHeight: 300,

    [MediaQuery.md]: {
      minHeight: 400,
    },
  },
  emptyPage: {
    ...commonStyles.flexAllCentered,
    width: '100%',
    height: '100vh',
  },
}));

const SIDEBAR_WIDTH = 200;

function Slider({ className }) {
  const sidebar = useSidebar();

  const onSidebarCollapse = useCallback((collapsed) => {
    sidebar.setOpened(!collapsed);
  }, [sidebar]);

  if (sidebar.disabled) {
    return null;
  }

  return (
    <Layout.Sider
      collapsedWidth={0}
      collapsed={!sidebar.opened}
      onCollapse={onSidebarCollapse}
      breakpoint="xl"
      width={SIDEBAR_WIDTH}
      className={className}
      trigger={null}
    >
      <Sidebar />
    </Layout.Sider>
  );
}

export default function App() {
  const classes = useStyles();

  const configLoaded = useLoadServerConfig();

  if (!configLoaded) {
    return (
      <div className={classes.emptyPage}><Spin /></div>
    );
  }

  return (
    <Router basename={BASE_DIR}
      getUserConfirmation={(msg, callback) => {
        message.error(msg, 2);
        callback();
      }}>
      <Layout className={classes.main}>
        <SyncLastLocation />
        <ErrorLogger />
        <Slider className={classes.sidebar} />
        <Layout>
          <Layout.Header className={classes.header}><Header /></Layout.Header>
          <Layout className={classes.contentWrapper} data-sticky-container>
            <BreadCrump />
            <div className={classes.content}>
              <Routes />
              <LoginDialog />
              <RefreshSessionDialog />
              <RefreshClientDialog />
            </div>
          </Layout>
        </Layout>
      </Layout>
    </Router>
  );
}
