import { httpGet } from 'core/common/http-client';

const liveStreamLog = {
  getList({ limit = 20, offset = 0, fields = [], sortBy, filters }) {
    const qs = {
      limit,
      offset,
      field: fields.join(),
      sortBy,
      ...filters,
    };

    return httpGet('/live-stream-logs/get/items-by-range', { qs });
  },
};

export default liveStreamLog;
