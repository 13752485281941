export const FileType = {
  FOLDER: 0,
  FILE: 1,
  IMAGE: 2,
  VIDEO: 3,
  AUDIO: 4,
};

export const AUDIO_EXTENSIONS = [
  '.mp3',
  '.wav',
  '.wma',
];

export const VIDEO_EXTENSIONS = [
  '.mp4',
  '.mov',
];

export const AUDIO_MIME_TYPES = [
  'audio/mpeg',
  'audio/wav',
  'audio/x-ms-wma',
];
