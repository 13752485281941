import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { Checkbox, Transfer } from 'antd';
import makeStyles from 'core/common/makeStyles';
import DraggableTable from 'core/components/table/DraggableTable';
import { getListTopicByTypeCache } from 'core/engine/topic/caching';

const useStyles = makeStyles(() => ({
  transfer: {
    display: 'block',
    '& > .ant-transfer-operation > button': {
      width: '100px',
    },
    '& .ant-transfer-list-body-customize-wrapper': {
      padding: 0,
    },
  },
  dragTable: {
    maxHeight: 260,
  },
  checkBox: {
    justifyContent: 'center',
    paddingLeft: 11,
    paddingRight: 3,
    border: 'none',
  },
}));

export default forwardRef(function TopicTransfer({ value, onChange, topicType, lazy = false, ...props }, ref) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const topicIds = useMemo(() => value ? value.split(',').map(item => parseInt(item)) : [], [value]);

  useEffect(() => {
    getListTopicByTypeCache(topicType).then((items) => {
      setOptions(items.map(item => ({ value: item.TopicID, label: item.Name })));
    });
  }, [topicType]);

  return (
    <Transfer
      ref={ref}
      className={classes.transfer}
      dataSource={options}
      rowKey={item => item.value}
      render={item => item.label}
      targetKeys={topicIds}
      onChange={handleChange}
      operations={['Thêm (+)', 'Xóa (-)']}
      lazy={lazy}
      listStyle={{
        width: 300,
        height: 300,
        flex: 'unset',
      }}
      locale={{
        itemsUnit: ' chủ đề',
        notFoundContent: 'Không tìm thấy',
        searchPlaceholder: 'Tìm kiếm...',
      }}
      {...props}
    >
      {({
        direction,
        filteredItems,
        onItemSelect,
        selectedKeys,
      }) => {
        if (direction === 'right') {
          const columns = [
            {
              Cell: ({ original }) => <Checkbox checked={selectedKeys.includes(original.value)} />,
              width: 30,
              className: classes.checkBox,
            },
            {
              accessor: 'label',
            },
          ];

          const handleRowClick = (event, rowInfo) => {
            const { value } = rowInfo.original;
            onItemSelect(value, !selectedKeys.includes(value));
          };

          return (
            <DraggableTable
              idField="value"
              data={filteredItems}
              columns={columns}
              onMove={(data) => handleChange(data.map(item => item.value))}
              TheadComponent={() => null}
              className={classes.dragTable}
              fullHeight={false}
              onRowClick={handleRowClick}
              showDragIcon="right"
            />
          );
        }
      }}
    </Transfer>
  );

  function handleChange(newValue) {
    onChange(newValue.join(','));
  }
});
