import { Button, Descriptions } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import { asyncAction } from 'core/common/async';
import { commonColumns, dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import AccountDisplay from 'core/components/account/AccountDisplay';
import AccountSelector from 'core/components/account/AccountSelector';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import Container from 'core/components/common/Container';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import FlexPushRight from 'core/components/common/FlexPushRight';
import MoneyInput from 'core/components/common/MoneyInput';
import NumberDisplay from 'core/components/common/NumberDisplay';
import OnBlurInput from 'core/components/common/OnBlurInput';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import ContentEditLink from 'core/components/content/ContentEditLink';
import ContentFilterableAccount from 'core/components/content/ContentFilterableAccount';
import { ContentLink } from 'core/components/content/ContentLink';
import BaseTable from 'core/components/table/BaseTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneDisplay from 'core/components/zone/ZoneDisplay';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import contentApi from 'core/engine/content/api';
import { ContentSearchOptions } from 'core/engine/content/constants';
import royaltyApi from 'core/engine/royalty/api';
import { useElementRectWithDebounce } from 'core/hooks/useElementRect';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import useNodeRef from 'core/hooks/useNodeRef';
import useContentFilterHandlers from '../../hooks/useContentFilterHandlers';
import RangeDateSelector from 'core/components/common/RangeDateSelector';

const { Item } = Descriptions;

const getFields = [
  'Author',
  'WriterID',
];

const lookup = [
  'Zone.ZoneID',
  'Zone.Name',
  'ContentStat.ContentID',
  'ContentStat.ViewCount',
  'Royalty.ContentID',
  'Royalty.Money',
  'Royalty.Note',
  'Royalty.CreatorID',
  'Royalty.CreatedDate',
];

function buildFilter(filters) {
  const listFilters = { ...filters };

  if (listFilters.rangeDate[0] && listFilters.rangeDate[1]) {
    listFilters.DistributionDate = dateRangeToFilter(listFilters.rangeDate);
  }

  delete listFilters.rangeDate;

  if (listFilters.search.type && listFilters.search.value) {
    listFilters[listFilters.search.type] = listFilters.search.value;
  }

  delete listFilters.search;

  return listFilters;
}

function listFn({ limit, offset, filters, sorting }) {
  return contentApi.getDeployedList({
    limit,
    offset,
    lookup: lookup.join(','),
    fields: getFields,
    sortBy: sortingToSortBy(sorting),
    filters: buildFilter(filters),
  });
}

export default function ContentRoyaltyListView() {
  const defaultRangeDate = useMemo(() => [moment().add(-7, 'days'), moment()], []);

  const listProps = useList({
    listFn,
    autoLoad: true,
    defaultFilters: {
      search: { type: ContentSearchOptions[0].value },
      rangeDate: defaultRangeDate,
    },
    defaultSorting: {
      DistributionDate: 'DESC',
    },
  });

  const filterHandlers = useContentFilterHandlers(listProps);
  const [contentStats, setContentStats] = useState({});
  const [mapDataSource, setMapDataSource] = useState({});
  const descriptionsRef = useNodeRef();
  const descriptionsHeight = useElementRectWithDebounce(descriptionsRef.current, 300).height;

  useEffect(() => {
    const newMapDataSource = {};
    listProps.items.forEach(item => {
      newMapDataSource[item.ContentID] = {
        Money: item.Royalty ? item.Royalty.Money : 0,
        Note: item.Royalty ? item.Royalty.Note : '',
        isUpdated: false,
      };
    });

    setMapDataSource(newMapDataSource);

    Promise.all([
      contentApi.getStats(buildFilter(listProps.filters)).then(resp => resp.data.item),
    ]).then(([item]) => {
      setContentStats(item);
    });
  }, [listProps.items]);

  const columns = [
    {
      Header: 'Tiêu đề',
      Cell: ({ original }) => (
        <ContentLink id={original.ContentID}>{original.Title}</ContentLink>
      ),
      minWidth: 300,
    },
    {
      Header: 'Chuyên mục',
      maxWidth: 160,
      minWidth: 130,
      Cell: ({ original }) => (
        <ZoneDisplay id={original.ZoneID} />
      ),
    },
    {
      Header: 'Người viết',
      width: 120,
      Cell: ({ original }) => (
        <ContentFilterableAccount
          id={original.WriterID}
          onClick={() => filterHandlers.onWriterFilter(original.WriterID)}
          title="Lọc tin tạo bởi tài khoản này"
        />
      ),
    },
    {
      Header: 'Tác giả',
      width: 150,
      accessor: 'Author',
    },
    {
      ...commonColumns.formatNumber,
      Header: 'Lượt xem',
      width: 80,
      accessor: 'ContentStat.ViewCount',
    },
    {
      Header: 'Nhuận bút',
      width: 150,
      Cell: ({ original }) => (
        <MoneyInput
          onChange={value => handleMoneyChange(original.ContentID, value)}
          value={mapDataSource[original.ContentID] ? mapDataSource[original.ContentID].Money : 0}
        />
      ),
    },
    {
      Header: 'Ghi chú',
      width: 220,
      Cell: ({ original }) => (
        <OnBlurInput
          onChange={value => handleNoteChange(original.ContentID, value)}
          value={mapDataSource[original.ContentID] ? mapDataSource[original.ContentID].Note : ''}
        />
      ),
    },
    {
      Header: 'Người chấm',
      width: 100,
      Cell: ({ original }) =>
        (original.Royalty && <AccountDisplay id={original.Royalty ? original.Royalty.CreatorID : 0} />),
    },
    {
      Header: 'Ngày chấm',
      width: 130,
      style: { justifyContent: 'center' },
      Cell: ({ original }) =>
        (original.Royalty && <DateTimeDisplay timestamp={original.Royalty ? original.Royalty.CreatedDate : null} />),
    },
    {
      maxWidth: 80,
      minWidth: 50,
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <ContentEditLink contentId={original.ContentID} target="_blank">
            <Button icon="edit" title="Sửa tin" />
          </ContentEditLink>
        </ActionsCellWrapper>
      ),
    },
  ];

  return (
    <Container pageTitle="Nhuận bút">
      <TableToolbarWrapper>
        <ZoneSelector
          allowClear
          emptyLabel="-- Chuyên mục --"
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
        />
        <AccountSelector
          emptyLabel="-- Người tạo --"
          value={listProps.filters.WriterID}
          onChange={filterHandlers.onWriterFilter}
          allowClear
        />
        <RangeDateSelector
          value={listProps.filters.rangeDate}
          onChange={filterHandlers.onRangeDateFilter}
        />
        <SearchFieldWithType
          typeOptions={ContentSearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />
        <FlexPushRight />
        <Button icon="save" type="primary" onClick={handleSave} title="Lưu nhuận bút">Lưu nhuận bút</Button>
        <Button icon="export" type="primary" onClick={handleExport} title="Xuất excel">Xuất excel</Button>
      </TableToolbarWrapper>
      <Descriptions bordered={true} ref={descriptionsRef}>
        <Item label="Số lượng"><NumberDisplay value={contentStats ? contentStats.ContentCount : 0} /></Item>
        <Item label="Tổng xem"><NumberDisplay value={contentStats ? contentStats.ViewCount : 0} /></Item>
        <Item label="Tổng tiền"><NumberDisplay value={contentStats ? contentStats.TotalMoney : 0} />{' VNĐ'}</Item>
      </Descriptions>
      <br />
      <BaseTable
        columns={columns}
        {...useListProps(listProps)}
        heightAdjust={63 + descriptionsHeight}
      />
    </Container>
  );

  function handleSave() {
    const updateItems = Object.keys(mapDataSource)
      .map(contentId => {
        if (mapDataSource[contentId].isUpdated) {
          return { ...mapDataSource[contentId], ContentID: contentId };
        }

        return null;
      })
      .filter(Boolean);

    return asyncAction('Lưu nhuận bút', () => {
      return royaltyApi.submit(JSON.stringify(updateItems))
        .then(() => {
          listProps.refresh();
        });
    });

  }

  function handleExport() {
    return asyncAction('Xuất dữ liệu nhuận bút', () => {
      return contentApi.exportRoyalties(buildFilter(listProps.filters), 'nhuanbut.xlsx');
    });
  }

  function handleMoneyChange(contentId, value) {
    const mapDataSourceItem = { ...mapDataSource[contentId], Money: value ? value : 0, isUpdated: true };
    const newMapDataSource = { ...mapDataSource };

    newMapDataSource[contentId] = mapDataSourceItem;
    setMapDataSource(newMapDataSource);
  }

  function handleNoteChange(contentId, value) {
    const mapDataSourceItem = { ...mapDataSource[contentId], Note: value, isUpdated: true };
    const newMapDataSource = { ...mapDataSource };

    newMapDataSource[contentId] = mapDataSourceItem;
    setMapDataSource(newMapDataSource);
  }
}
