import React from 'react';
import makeStyles from 'core/common/makeStyles';
import classnames from 'core/common/classnames';
import { Link } from 'react-router-dom';
import { commonColors } from 'core/common/commonStyles';

const cardStyles = makeStyles(() => ({
  card: {
    marginTop: 10,
    marginBottom: 15,
    width: '100%',
    borderRadius: 4,
    padding: 10,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
  cardTitle: {
    paddingLeft: 0,
    paddingBottom: 5,
    border: 0,
    fontWeight: 'bold',
    fontSize: 18,

    '&::after': {
      paddingTop: 5,
      bottom: 0,
      left: 0,
      width: 40,
      content: '""',
      display: 'block',
      borderBottom: '4px solid #673AB7',
    },

    '& a': {
      color: commonColors.text,
    },
  },
  cardContent: {
    padding: 5,
    overflow: 'hidden',
    overflowY: 'auto',
  },
}));

export default function CardWidget({ title, titleLink, children, bgColor, height = 300, className }) {
  const classes = cardStyles();

  return (
    <div className={classnames(classes.card, className)} style={{ backgroundColor: bgColor }}>
      {title && (
        <div className={classes.cardTitle}>
          {titleLink ? (
            <Link to={titleLink}>{title}</Link>
          ) : (
            <span>{title}</span>
          )}
        </div>
      )}
      <div className={classes.cardContent} style={{ height: height }}>
        {children}
      </div>
    </div>
  );
}
