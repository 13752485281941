import { httpGet, httpPost } from 'core/common/http-client';

const otpApi = {
  getQRCode(username, timestamp, checksum) {
    return httpGet('/otps/get/qrcode?username=' + username + '&timestamp=' + timestamp + '&checksum=' + checksum);
  },

  getQrCodeLink(username) {
    return httpGet('/otps/get/view-qrcode?username=' + username);
  },

  sendMail(username, email) {
    return httpPost('/otps/post/send-mail', { username: username, email: email });
  },
};

export default otpApi;
