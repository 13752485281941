import { emptyFunction } from 'core/common/empty';
import useDialog from 'core/hooks/useDialog';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import BaseDialog from '../common/BaseDialog';
import PhotoEditor from './PhotoEditor';
import Compressor from 'compressorjs';

export default forwardRef(function PhotoEditorDialog({
  title = 'Sửa ảnh',
  okText = 'Lưu',
  src,
  onSave = emptyFunction,
}, ref) {
  const editorRef = useRef();
  const { open, handleOpen, handleClose } = useDialog();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOk = useCallback(() => {
    setIsSubmitting(true);

    Promise.resolve()
      .then(() => compressImage(editorRef.current.getImage().data))
      .then((compressedImage) => {
        const data = editorRef.current.getImage();
        data.data = compressedImage;
        return onSave(data);
      })
      .then((data) => {
        setIsSubmitting(false);
        handleClose();
        return data;
      })
      .catch((err) => {
        setIsSubmitting(false);
        return Promise.reject(err);
      });
  }, [onSave, handleClose]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <BaseDialog
      visible={open}
      title={title}
      onCancel={handleClose}
      onOk={handleOk}
      okText={okText}
      width="90%"
      confirmLoading={isSubmitting}
      fullHeightFixed
      noPadding
    >
      <PhotoEditor src={src} ref={editorRef} />
    </BaseDialog>
  );

  function convertBase64toBlob(_base64) {
    return new Promise((resolve) =>
      fetch(_base64)
        .then((base64Response) => resolve(base64Response.blob()))
    );
  }

  function convertBlobtoBase64(_blob) {
    return new Promise((resolve, reject) => {

      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(_blob);
    });
  }

  function compressImage(_base64) {
    return new Promise((resolve, reject) => {
      convertBase64toBlob(_base64)
        .then((blob) => {
          new Compressor(blob, {
            quality: 0.8,
            convertSize: 0,
            success: (result) => {
              convertBlobtoBase64(result)
                .then((resultBase64) => resolve(resultBase64));
            },
            error: reject,
          });
        });
    });
  }
});
