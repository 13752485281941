import { InputNumber } from 'antd';
import TopicSelector from 'core/components/topic/TopicSelector';
import ZoneTransfer from 'core/components/zone/ZoneTransfer';
import { TopicType } from 'core/engine/topic/constants';
import SettingForm from 'core/modules/managements/settings/components/SettingForm';
import React from 'react';

const settings = [
  {
    key: 'StringConfig_HomeHighlightTopicID',
    label: 'Nổi bật trang chủ',
    input: <TopicSelector emptyValue="" topicType={TopicType.SYSTEM} />,
  },
  {
    key: 'StringConfig_HomeTimelineTopicID',
    label: 'Timeline',
    input: <TopicSelector emptyValue="" topicType={TopicType.SYSTEM} />,
  },
  {
    key: 'StringConfig_HomeTimelineTop',
    label: 'Số bài timeline',
    input: <InputNumber />,
  },
  {
    key: 'StringConfig_GuongSangTopicID',
    label: 'Gương sáng',
    input: <TopicSelector emptyValue="" />,
  },
  {
    key: 'StringConfig_TruyenThongChinhSachTopicID',
    label: 'Truyền thông chính sách',
    input: <TopicSelector emptyValue="" />,
  },
  {
    key: 'StringConfig_ContentPushEditorPickTopicID',
    label: 'Google Editor\'s Picks',
    input: <TopicSelector emptyValue="" />,
  },
  {
    key: 'StringConfig_ContentPushFacebookTopicID',
    label: 'Facebook RSS',
    input: <TopicSelector emptyValue="" />,
  },
  {
    key: 'StringConfig_NoTTSZones',
    label: 'Bỏ TTS',
    input: <ZoneTransfer />,
  },
  { separator: 'Desktop' },
  {
    key: 'StringConfig_MainMenu',
    label: 'Menu chính',
    input: <ZoneTransfer />,
  },
  {
    key: 'StringConfig_AllMenu',
    label: 'Tất cả các mục',
    input: <ZoneTransfer />,
  },
  {
    key: 'StringConfig_DesktopHomeCates',
    label: 'Nhóm chuyên mục',
    input: <ZoneTransfer />,
  },
  { separator: 'Mobile' },
  {
    key: 'StringConfig_MobileHomeCates',
    label: 'Nhóm chuyên mục',
    input: <ZoneTransfer />,
  },
];

export default function SettingLayoutForm({ form, onSubmit }) {
  return (
    <SettingForm form={form} onSubmit={onSubmit} settings={settings} />
  );
}
