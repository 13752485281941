import React from 'react';
import { Spin } from 'antd';
import makeStyles from 'core/common/makeStyles';
import commonStyles from 'core/common/commonStyles';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexAllCentered,
    width: '100%',
    height: '100%',
  },
}));

export default function LoadingWrapper({ children, loading, center = false, ...restProps }) {
  const classes = useStyles();

  if (loading) {
    if (center) {
      return (<div className={classes.container}><Spin {...restProps} /></div>);
    }

    return (<Spin {...restProps} />);
  } else {
    return children;
  }
}
