import { Checkbox } from 'antd';
import { connectSelectorFilterForList } from 'core/common/hocs';
import { sortingToSortBy } from 'core/common/listUtils';
import BaseDialog from 'core/components/common/BaseDialog';
import CheckboxFilterForList from 'core/components/common/CheckboxFilterForList';
import { OnEnterFilterForList } from 'core/components/common/OnEnterFilter';
import SelectableTable from 'core/components/table/SelectableTable';
import voteApi from 'core/engine/vote/api';
import { VoteAttributesLabel } from 'core/engine/vote/constants';
import useDialog from 'core/hooks/useDialog';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { forwardRef, useImperativeHandle } from 'react';

const columns = [
  {
    Header: 'ID',
    accessor: 'VoteID',
    maxWidth: 75,
    filterable: true,
    Filter: OnEnterFilterForList,
    headerStyle: { justifyContent: 'center' },
    style: { justifyContent: 'center' },
  },
  {
    Header: 'Tên bình chọn',
    accessor: 'Title',
    filterable: true,
    Filter: OnEnterFilterForList,
    headerStyle: { justifyContent: 'center' },
  },
  {
    Header: 'Loại',
    accessor: 'Attributes',
    Cell: (row) => (VoteAttributesLabel[row.original.Attributes]),
    filterable: true,
    Filter: connectSelectorFilterForList({ options: VoteAttributesLabel, valueIsNumber: true }),
    width: 100,
    headerStyle: { justifyContent: 'center' },
  },
  {
    Header: 'Sử dụng',
    accessor: 'Enabled',
    Cell: ({ original }) => <Checkbox checked={original.Enabled} />,
    filterable: true,
    Filter: CheckboxFilterForList,
    width: 75,
    headerStyle: { justifyContent: 'center' },
    style: { justifyContent: 'center' },
  },
];

function listFn({ limit, offset, filters, sorting }) {
  return voteApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default forwardRef(function VoteDialog({ title, onSelect, onCancel }, ref) {
  const { open, handleClose, handleOpen } = useDialog();

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  const listProps = useList({
    listFn,
    defaultPageSize: 20,
    autoLoad: true,
    defaultSorting: {
      VoteID: 'DESC',
    },
  });

  return (
    <BaseDialog
      focusTriggerAfterClose={false}
      keyboard
      maskClosable
      fullHeightFixed
      paddingTop={0}
      visible={open}
      title={title}
      width={900}
      onCancel={onCancel}
      footer={null}
    >
      <SelectableTable
        {...useListProps(listProps)}
        idField="VoteID"
        onSelect={onSelect}
        columns={columns}
      />
    </BaseDialog>
  );
});
