import { Form } from 'antd';
import makeStyles from 'core/common/makeStyles';
import React from 'react';
import ContentFormItemSeoEntryInput from 'core/modules/contents/components/form/seo-entry/ContentFormItemSeoEntryInput';
import AuthzCheck from 'core/components/common/AuthzCheck';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 10,
  },
}));

const EXTENDED_RIGHT_EDIT_SEO = 'content.edit-seo';

export default function ContentFormItemSeoEntry({ content, form, ...props }) {
  const { getFieldDecorator } = form;
  const classes = useStyles();

  return (
    <AuthzCheck extendedRight={EXTENDED_RIGHT_EDIT_SEO}>
      <Form.Item className={classes.container}>
        {getFieldDecorator('SeoEntry', {
          initialValue: content.SeoEntry,
        })(
          <ContentFormItemSeoEntryInput {...props} />,
        )}
      </Form.Item>
    </AuthzCheck>
  );
}
