import { httpDownload, httpGet } from 'core/common/http-client';

const reportApi = {

  getDaily({ keys, value, startDate, endDate, siteId }) {
    const qs = { keys: keys, value: value, startDate, endDate, siteId };
    return httpGet('/reports/get/daily', { qs });
  },

  getHourly({ keys, value, startTime, endTime, siteId }) {
    const qs = { keys: keys, value, startTime, endTime, siteId };
    return httpGet('/reports/get/hourly', { qs });
  },

  getByIds({ keys, values, startDate, endDate }) {
    const qs = { keys, values, startDate, endDate };
    return httpGet('/reports/get/by-ids', { qs });
  },

  getGroupByZone({ keys, startDate, endDate, siteId }) {
    const qs = { keys, startDate, endDate, siteId };
    return httpGet('/reports/get/group-by-zone', { qs });
  },

  getContentTrending({ offset, limit, filters = {}, sortBy }) {
    const qs = { offset, limit, ...filters, sortBy };
    return httpGet('/reports/get/content-trending', { qs });
  },

  getListZDNApp() {
    return httpGet('/reports/get/zdn-apps');
  },

  getZDNReports({ appId, startTime, endTime }) {
    const qs = { appId, startTime, endTime };
    return httpGet('/reports/get/zdn-reports', { qs });
  },

  contentByTypes({ filters }) {
    const qs = { ...filters };
    return httpGet('/reports/get/content-by-types', { qs });
  },

  contentByAccounts({ accountIds, group = 'day', fromDate, toDate }) {
    const qs = { accountIds, group, fromDate, toDate };
    return httpGet('/reports/get/content-by-accounts', { qs });
  },

  exportTrafficDaily({ filters, filename }) {
    return httpDownload('/reports/get/export-traffic-daily', { qs: filters, filename });
  },

  exportContentByTypes({ filters, filename }) {
    return httpDownload('/reports/get/export-content-by-types', { qs: filters, filename });
  },

  exportContentByAccounts({ filters, filename }) {
    return httpDownload('/reports/get/export-content-by-accounts', { qs: filters, filename });
  },

  exportGroupByZone({ filters, filename }) {
    return httpDownload('/reports/get/export-group-by-zone', { qs: filters, filename });
  },

  exportContentTrending({ filters, filename }) {
    return httpDownload('/reports/get/export-content-trending', { qs: filters, filename });
  },

  exportZDNReports({ filters, filename }) {
    return httpDownload('/reports/get/export-zdn-reports', { qs: filters, filename });
  },
};

export default reportApi;
