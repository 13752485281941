import { Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import Selector from 'core/components/common/Selector';
import { Platform, PlatformLabel } from 'core/engine/common/constants';
import { EmbeddedStyleType, EmbeddedStyleTypeLabel } from 'core/engine/embedded-styles/constants';
import React from 'react';

export default function EmbeddedStyleForm({ form, onSubmit, style, type }) {
  const { getFieldDecorator } = form;

  getFieldDecorator('StyleID', { initialValue: style.StyleID });

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      <Form.Item label="Tên">
        {getFieldDecorator('Title', {
          rules: [
            { required: true, message: 'Bắt buộc!' },
            { max: 250, message: 'Tối đa 250 kí tự!' },
          ],
          initialValue: style.Title,
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      {!type && (
        <Form.Item label="Loại">
          {getFieldDecorator('Type', {
            rules: [{ required: true, message: 'Bắt buộc!' }],
            initialValue: style.Type || Object.values(EmbeddedStyleType)[0],
          })(
            <Selector
              options={EmbeddedStyleTypeLabel}
              valueIsNumber
            />
          )}
        </Form.Item>
      )}
      <Form.Item label="Nền tảng">
        {getFieldDecorator('Platform', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: style.Platform || Object.values(Platform)[0],
        })(
          <Selector
            options={PlatformLabel}
            valueIsNumber
          />
        )}
      </Form.Item>
      <Form.Item label="Nội dung">
        {getFieldDecorator('Style', {
          rules: [{ required: true, message: 'Bắt buộc!' }],
          initialValue: style.Style,
        })(
          <Input.TextArea autoSize={{ minRows: 5, maxRows: 20 }} />
        )}
      </Form.Item>
    </Form>
  );
}
