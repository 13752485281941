import { Button, Form } from 'antd';
import moment from 'moment';
import React, { Fragment } from 'react';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import groupApi from 'core/engine/group/api';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import GroupForm from './GroupForm';

function GroupFormDialogButton({ form, group = {}, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <Fragment>
      <Button
        title={isEditMode ? 'Sửa nhóm người dùng' : 'Tạo nhóm người dùng'}
        icon={isEditMode ? 'edit' : 'plus'}
        onClick={handleOpen}
      >
        {isEditMode ? '' : 'Tạo nhóm'}
      </Button>
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa nhóm người dùng' : 'Tạo nhóm người dùng'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText={isEditMode ? 'Lưu' : 'Tạo'}
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
        keyboard={!form.isFieldsTouched()}
        maskClosable={!form.isFieldsTouched()}
      >
        <GroupForm form={form} onSubmit={handleSubmit} group={group} isEditMode={isEditMode} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    if (formData.ExpiredDate) {
      formData.ExpiredDate = moment(formData.ExpiredDate).unix() * 1000;
    }

    if (isEditMode) {
      return asyncAction('Sửa nhóm người dùng', () => {
        return groupApi.update(formData)
          .then(handleDone);
      });
    } else {
      return asyncAction('Tạo nhóm người dùng', () => {
        return groupApi.create(formData)
          .then(handleDone);
      });
    }
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'GroupFormDialogButton' })(GroupFormDialogButton);
