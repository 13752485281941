import React, { Fragment } from 'react';
import { ChangePasswordFormRaw } from '../common/ChangePasswordForm';
import { Modal, Form, Icon } from 'antd';
import useAuth from 'core/hooks/useAuth';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import { asyncAction } from 'core/common/async';
import accountApi from 'core/engine/account/api';

function HeaderUserMenuChangePasswordButton({ form }) {
  const { open, handleClose, handleOpen } = useDialog();
  const { loggedInUser } = useAuth();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <Fragment>
      <span onClick={handleOpen}><Icon type="key" /> Thay đổi mật khẩu</span>
      <Modal
        destroyOnClose
        keyboard={!form.isFieldsTouched()}
        maskClosable={!form.isFieldsTouched()}
        title={`Thay đổi mật khẩu tài khoản: ${loggedInUser.name}`}
        visible={open}
        onCancel={handleClose}
        onOk={handleSubmit}
        confirmLoading={isSubmitting}
        width={600}
      >
        <ChangePasswordFormRaw form={form} onSubmit={handleSubmit} />
      </Modal>
    </Fragment>

  );

  function submitFn({ formData }) {
    return asyncAction('Thay đổi mật khẩu', () => {
      return accountApi.changePassword({
        OldPassword: formData.OldPassword,
        NewPassword: formData.NewPassword,
      }).then(() => handleClose());
    });
  }
}

export default Form.create({ name: 'HeaderUserMenuChangePasswordButton' })(HeaderUserMenuChangePasswordButton);
