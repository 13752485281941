import { formatByte, parseByte } from '@bm/common';
import { Dropdown, Menu, message, Tooltip } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { emptyFunction } from 'core/common/empty';
import logger from 'core/common/logger';
import fileApi from 'core/engine/file/api';
import React, { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { AUDIO_EXTENSIONS, AUDIO_MIME_TYPES, VIDEO_EXTENSIONS } from 'src/engine/file-extend/constants';
import FileUploadFromUrlDialogButton from 'core/components/file-manager/FileUploadFromUrlDialogButton';
import { FileType } from 'core/engine/file/constants';

const DropdownButton = {
  FROM_URL: 'from-url',
};

export default forwardRef(function VideoAudioManagerUploadButton({
  title = 'Tải lên',
  onUploaded = emptyFunction,
  onUploadedDone = emptyFunction,
}, ref) {
  const inputRef = useRef();
  const videoFromUrlDialogRef = useRef();
  const [uploading, setUploading] = useState(false);
  const [uploadInfo, setUploadInfo] = useState({});

  useEffect(() => {
    fileApi.getInfo().then((resp) => setUploadInfo(resp.data));
  }, []);

  const uploader = useMemo(() => {
    return {
      maxSize: parseByte(uploadInfo.maxVideoUploadSize),
      extensions: VIDEO_EXTENSIONS.concat(AUDIO_EXTENSIONS).join(),
      upload(file) {
        if (AUDIO_MIME_TYPES.includes(file.type)) {
          return fileApi.uploadAudio(file, file.name);
        }

        return fileApi.uploadVideo(file, file.name);
      },
    };
  }, [uploadInfo]);

  const tooltip = useMemo(() => {
    const extensions = uploader.extensions;
    const maxSize = formatByte(uploader.maxSize);

    if (extensions) {
      return `Định dạng: ${extensions} / Tối đa: ${maxSize}`;
    }
  }, [uploader]);

  const menu = useMemo(() =>
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={DropdownButton.FROM_URL}>
        Tải video từ nền tảng khác
      </Menu.Item>
    </Menu>
  , []);

  const handleClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  useImperativeHandle(ref, () => ({
    upload(files) {
      handleChange({ target: { files } });
    },
  }));

  return (
    <Tooltip title={tooltip} placement="top">
      <Fragment>
        <Dropdown.Button type="primary" onClick={handleClick} loading={(!!uploading).toString()} overlay={menu}>
          {uploading || title}
        </Dropdown.Button>
        <FileUploadFromUrlDialogButton
          fileType={FileType.VIDEO}
          wrappedComponentRef={videoFromUrlDialogRef}
          onUploaded={onUploaded}
        />
        <input ref={inputRef} style={commonStyles.hidden} type="file" onChange={handleChange} multiple accept={VIDEO_EXTENSIONS.concat(AUDIO_EXTENSIONS).join()} />
      </Fragment>
    </Tooltip>
  );

  function handleMenuClick(e) {
    switch (e.key) {
      case DropdownButton.FROM_URL:
        videoFromUrlDialogRef.current.open();
        return;
      default:
        handleClick();
        return;
    }
  }

  function handleChange(event) {
    const target = event.target;
    const files = Array.prototype.slice.call(target.files);
    const total = files.length;
    const uploadedFiles = [];

    const oversizeFileNames = files.filter(file => file.size > uploader.maxSize).map(file => file.name);

    if (oversizeFileNames.length) {
      message.error('Tệp đã chọn vượt quá dung lượng cho phép (' + formatByte(uploader.maxSize) + '): ' + oversizeFileNames.join(', '), 5);
      return;
    }

    updateStatus();

    const uploadPromise = files.reduce((previous, current) => {
      return previous.then(() => {
        return uploader.upload(current)
          .then(resp => resp.data.item)
          .then((file) => {
            uploadedFiles.push(file);
            updateStatus();
            onUploaded(file);
          });
      });
    }, Promise.resolve());

    uploadPromise.then(() => {
      setUploading(false);
      onUploadedDone(null, uploadedFiles);
      target.value = '';
    }, (err) => {
      logger.error('Error while uploading files:', err);
      message.error(err.error_message
        ? 'Có lỗi xảy ra: ' + err.error_message
        : 'Có lỗi xảy ra khi tải tệp lên!');

      target.value = '';
      setUploading(false);
      onUploadedDone(err, uploadedFiles);
    });

    function updateStatus() {
      setUploading(`Đang tải lên ${uploadedFiles.length}/${total}`);
    }
  }
});
