import { Input } from 'antd';
import makeStyles from 'core/common/makeStyles';
import BaseDialog from 'core/components/common/BaseDialog';
import FlexDiv from 'core/components/common/FlexDiv';
import HtmlDisplay from 'core/components/common/HtmlDisplay';
import useDialog from 'core/hooks/useDialog';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import buildEmbedCode from 'core/common/buildEmbedCode';
import domUtils from 'core/common/domUtils';
import { isNullOrUndefined } from 'core/common/is';

const useStyles = makeStyles(() => ({
  textarea: {
    minWidth: 400,
  },
  preview: {
    textAlign: 'center',
    marginTop: 10,
    border: '1px solid #e5e5e5',
    padding: 10,
    borderRadius: 3,
  },
  dimensionInput: {
    marginTop: 10,
    '& > input': {
      width: 100,
      marginRight: 10,
    },
    '& > span': {
      position: 'relative',
      top: 4,
      marginRight: 10,
    },
  },
}));

export default forwardRef(function EmbedDialog({ title, onSelect, onCancel }, ref) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const { open, handleClose, handleOpen } = useDialog();

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }));

  const embedCode = useMemo(() => {
    const embed = domUtils.createElementFromHtml(buildEmbedCode(value));

    if (!embed) {
      return embed;
    }

    if (isNullOrUndefined(width)) {
      setWidth(embed.getAttribute('width'));
    } else {
      embed.setAttribute('width', width);
    }

    if (isNullOrUndefined(height)) {
      setHeight(embed.getAttribute('height'));
    } else {
      embed.setAttribute('height', height);
    }

    return embed.outerHTML;
  }, [value, width, height]);

  useEffect(() => {
    setWidth();
    setHeight();
  }, [value]);

  return (
    <BaseDialog
      focusTriggerAfterClose={false}
      keyboard
      maskClosable
      visible={open}
      title={title}
      width="auto"
      okText="Chèn"
      okButtonProps={{ disabled: !embedCode }}
      onCancel={onCancel}
      onOk={() => onSelect(embedCode)}
      fullHeight
    >
      <Input.TextArea
        className={classes.textarea}
        placeholder="Nhập mã nhúng hoặc link video Youtube, Facebook..."
        name="embedCode"
        rows={4}
        value={value}
        onChange={e => setValue(e.target.value)}
        autoFocus
      />
      {isUrl(value) && (
        <FlexDiv className={classes.dimensionInput}>
          <span>Chiều dài</span>
          <Input
            value={width}
            onChange={handleWidthChange}
            placeholder="Chiều dài"
          />
          <span>Chiều rộng</span>
          <Input
            value={height}
            onChange={handleHeightChange}
            placeholder="Chiều rộng"
          />
        </FlexDiv>
      )}
      {embedCode && (
        <HtmlDisplay className={classes.preview} html={embedCode} />
      )}
    </BaseDialog>
  );

  function isUrl(value) {
    return /^https?:\/\//.test(value);
  }

  function handleWidthChange(event) {
    setWidth(event.target.value);
  }

  function handleHeightChange(event) {
    setHeight(event.target.value);
  }
});
