import { Button, Result } from 'antd';
import React, { useMemo } from 'react';
import commonStyles from 'core/common/commonStyles';
import makeStyles from 'core/common/makeStyles';
import FlexDiv from 'core/components/common/FlexDiv';
import { ServerErrorCode } from 'core/constants';
import useGoBack from 'core/hooks/useGoBack';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexAllCentered,
    width: '100%',
    height: '100%',
  },
}));

export default function ContentComposeErrorBox({ error }) {
  const classes = useStyles();
  const goBack = useGoBack();

  const normalizedError = useMemo(() => {
    if (error.error_code === ServerErrorCode.NOT_EXIST || error.error_code === ServerErrorCode.NOT_FOUND) {
      return {
        type: 'error',
        title: 'Tin bài không tồn tại!',
      };
    }

    if (error.error_code === ServerErrorCode.PERMISSION_DENIED) {
      return {
        type: 'warning',
        title: error.error_message,
      };
    }

    return {
      type: 'error',
      title: 'Có lỗi xảy ra khi mở tin!',
      message: error.error_message || error.message,
    };
  }, [error]);

  return (
    <FlexDiv className={classes.container}>
      <Result
        status={normalizedError.type}
        title={normalizedError.title}
        subTitle={normalizedError.message}
        extra={<Button type="primary" onClick={goBack}>Quay lại</Button>}
      />
    </FlexDiv>
  );
}
