import { Result } from 'antd';
import GoBackButton from 'core/components/common/GoBackButton';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import settingApi from 'core/engine/setting/api';
import topicApi from 'core/engine/topic/api';
import { TopicType } from 'core/engine/topic/constants';
import useDataLoader from 'core/hooks/useDataLoader';
import React from 'react';
import TopicNormalContentListDisplay from './topic-normal/TopicNormalContentListDisplay';
import TopicSystemContentListDisplay from './topic-system/TopicSystemContentListDisplay';

export default function TopicDisplayPanel({ settingKey, systemExtButtons }) {
  const { data: topic, isLoading } = useDataLoader({
    loadFn: () => {
      return settingApi.get(settingKey)
        .then(resp => resp.data.item)
        .then(setting => {
          if (setting && setting.Value) {
            return parseInt(setting.Value, 10);
          }

          return Promise.reject(new Error('No setting for key: ' + settingKey));
        })
        .then(topicId => topicApi.get(topicId))
        .then(resp => resp.data.item);
    },
  });

  return (
    <LoadingWrapper center loading={isLoading}>
      {topic ? (
        topic.Type === TopicType.SYSTEM ?
          <TopicSystemContentListDisplay topic={topic} systemExtButtons={systemExtButtons} /> :
          <TopicNormalContentListDisplay topic={topic} />
      ) : (
        <Result status="404" title="Chủ đề không tồn tại" extra={<GoBackButton />} />
      )}
    </LoadingWrapper>
  );
}
