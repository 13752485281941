import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { Transfer } from 'antd';
import { getListSortedAccountCache } from 'core/engine/account/caching';
import { sortingToSortBy } from 'core/common/listUtils';

export default forwardRef(function AccountTransfer({ value, showSearch = false, onChange, ...props }, ref) {
  const [options, setOptions] = useState([]);

  const accountIds = useMemo(() => {
    return sortValueByName(value ? value.split(',').map(item => parseInt(item)) : []);
  }, [options, value]);

  useEffect(() => {
    getListSortedAccountCache(sortingToSortBy({ Username: 'ASC' })).then((resp) => {
      setOptions(resp.data.items);
    });
  }, []);

  return (
    <Transfer
      ref={ref}
      showSearch={showSearch}
      dataSource={options}
      rowKey={item => item.AccountID}
      render={item => item.Username}
      targetKeys={accountIds}
      onChange={handleChange}
      listStyle={{
        width: 275,
        height: 200,
      }}
      locale={{
        itemUnit: ' tài khoản',
        itemsUnit: ' tài khoản',
        notFoundContent: 'Không tìm thấy',
      }}
      {...props}
    />
  );

  function handleChange(newValue) {
    onChange(sortValueByName(newValue).join(','));
  }

  function sortValueByName(value) {
    return options.filter(option => value.includes(option.AccountID)).map(option => option.AccountID);
  }
});
