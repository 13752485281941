import { Button } from 'antd';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import React, { Fragment } from 'react';
import TopicLog from './TopicLog';

export default function TopicLogDialogButton({ topic }) {
  const { open, handleOpen, handleClose } = useDialog();

  return (
    <Fragment>
      <Button icon="history" title="Truy vết dòng sự kiện" onClick={handleOpen} />
      <BaseDialog
        visible={open}
        title={`Truy vết dòng sự kiện: ${topic.Name}`}
        onCancel={handleClose}
        width={1050}
        footer={[
          <Button key="ok" type="primary" onClick={handleClose}>OK</Button>,
        ]}
        fullHeightFixed={true}
      >
        <TopicLog topicId={topic.TopicID} />
      </BaseDialog>
    </Fragment>
  );
}
