import React, { useMemo } from 'react';
import { stripXss } from 'core/common/string';

export default function HtmlDisplay({ tagName = 'div', html, ...props }) {
  const safeHtml = useMemo(() => stripXss(html), [html]);

  return (
    React.createElement(tagName, {dangerouslySetInnerHTML: { __html: safeHtml }, ...props})
  );
}

