import { useState, useEffect, useCallback } from 'react';

export default function useResize({ x, y, width, height, onResize }) {
  const [dragging, setDragging] = useState(false);
  const [initialDragState, setInitialDragState] = useState({
    initX: 0,
    initY: 0,
    initWidth: 0,
    initHeight: 0,
    mouseDownX: 0,
    mouseDownY: 0,
  });

  const onMouseDown = useCallback((event) => {
    event.preventDefault();
    setInitialDragState({
      initX: x,
      initY: y,
      initWidth: width,
      initHeight: height,
      mouseDownX: event.clientX,
      mouseDownY: event.clientY,
    });
    setDragging(true);
  }, [width, height, x, y]);

  useEffect(() => {
    const onMouseMove = (event) => {
      if (dragging) {
        const {
          initX,
          initY,
          initWidth,
          mouseDownX,
          initHeight,
          mouseDownY,
        } = initialDragState;
        const dx = event.clientX - mouseDownX;
        const dy = event.clientY - mouseDownY;
        const width = initWidth + dx;
        const height = initHeight + dy;

        return onResize(initX, initY, width, height);
      }
    };

    window.addEventListener('mousemove', onMouseMove, { passive: true });

    return () => window.removeEventListener('mousemove', onMouseMove);
  }, [initialDragState, dragging, onResize]);

  useEffect(() => {
    const onMouseUp = () => {
      setDragging(false);
    };

    window.addEventListener('mouseup', onMouseUp);

    return () => window.removeEventListener('mouseup', onMouseUp);
  }, []);

  return onMouseDown;
}
