import { Config, ToolbarIcon } from '@zdocs/core';
import { buildCdnPhotoUrl } from 'core/common/cdn';
import lodash from 'core/common/lodash';
import React from 'react';
import BaseDialogPlugin from '../BaseDialogPlugin';
import BeforeAfterImageDialog from './BeforeAfterImageDialog';
import icon from './icon.svg';

export default class BeforeAfterImagePlugin extends BaseDialogPlugin {

  static install() {
    ToolbarIcon.setIcon('BeforeAfterImage', icon);

    Config.prototype.controls.BeforeAfterImage = {
      icon: 'BeforeAfterImage',
      tooltip: 'Chèn ảnh trước sau',
    };
  }

  get name() {
    return 'BeforeAfterImagePlugin';
  }

  get command() {
    return 'BeforeAfterImage';
  }

  get defaultOptions() {
    return {
      template:
        `<table class="picture" align="center" data-type="before-after">
          <tbody>
            <tr>
              <td class="pic">
                <div class="before-after-image-box">
                  <img data-image-id="$ImageId1" alt="" src="$Src1" $ExtAttributes1>
                  <img data-image-id="$ImageId2" alt="" src="$Src2" $ExtAttributes2>
                </div>
              </td>
            </tr>
            <tr>
              <td class="caption"><p></p></td>
            </tr>
          </tbody>
        </table>`,
    };
  }

  renderDialog(props) {
    return (<BeforeAfterImageDialog title="Chèn ảnh trước sau" {...props} />);
  }

  onSelect(images) {
    super.onSelect();

    const extAttributes = images.map(image => {
      const width = lodash.get(image, 'Meta.width');
      const height = lodash.get(image, 'Meta.height');

      return width && height && `data-width="${width}" data-height="${height}"`;
    });

    const html = this.options.template
      .replace('$ExtAttributes1', extAttributes[0] || '')
      .replace('$ExtAttributes2', extAttributes[1] || '')
      .replace('$ImageId1', images[0].FileID)
      .replace('$ImageId2', images[1].FileID)
      .replace('$Src1', buildCdnPhotoUrl(images[0].Path))
      .replace('$Src2', buildCdnPhotoUrl(images[1].Path));

    this.insertHTMLToEditor(html, false, false, '.caption');
  }

  callback(command, control) {
    this.selectionInfo = this.zdocs.selection.save();
    this.openDialog({ control });
  }
}
