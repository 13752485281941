import { Form } from 'antd';
import React, { Fragment } from 'react';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import WidgetMetaTemplateFormItem from '../../common/form/WidgetMetaTemplateFormItem';
import WidgetMetaCountFormItem from '../../common/form/WidgetMetaCountFormItem';
import { useParsedMeta } from 'core/modules/operations/widgets/hooks';

export default function ZoneWidgetFormItemMeta({ widget, form, widgetOption }) {
  const { getFieldDecorator } = form;
  const meta = useParsedMeta(widget);

  return (
    <Fragment>
      <Form.Item label="Chuyên mục">
        {getFieldDecorator('Meta.ZoneID', {
          initialValue: meta.ZoneID,
        })(
          <ZoneSelector
            emptyLabel="-- Chuyên mục --"
            emptyValue={0}
          />
        )}
      </Form.Item>
      <WidgetMetaTemplateFormItem widget={widget} form={form} widgetOption={widgetOption} />
      <WidgetMetaCountFormItem widget={widget} form={form} />
    </Fragment>
  );
}
