export const CommentStatus = {
  WAITING: 1,
  APPROVED: 2,
  DROPPED: 3,
};

export const CommentStatusLabel = {
  [CommentStatus.WAITING]: 'Chờ duyệt',
  [CommentStatus.APPROVED]: 'Đã duyệt',
  [CommentStatus.DROPPED]: 'Đã ẩn',
};
