import { parseJson } from 'core/common/jsonUtils';
import { commonColumns, sortingToSortBy } from 'core/common/listUtils';
import AccountDisplay from 'core/components/account/AccountDisplay';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import HtmlDisplay from 'core/components/common/HtmlDisplay';
import BaseTable from 'core/components/table/BaseTable';
import ZoneDisplay from 'core/components/zone/ZoneDisplay';
import topicLogApi from 'core/engine/topic-log/api';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { Fragment, useEffect } from 'react';
import TopicContentLogDialogButton from './TopicContentLogDialogButton';

const columns = [
  {
    Header: 'Tên chủ đề',
    accessor: 'Name',
    minWidth: 150,
  },
  {
    Header: 'Chuyên mục',
    accessor: 'ZoneID',
    Cell: ({ value }) => value === 0 ? 'Trang chủ' : <ZoneDisplay id={value} />,
    maxWidth: 180,
    minWidth: 100,
  },
  {
    Header: 'Mô tả',
    accessor: 'Description',
    Cell: ({ value }) => <HtmlDisplay html={value} />,
    minWidth: 150,
  },
  {
    ...commonColumns.allCentered,
    Header: 'STT',
    accessor: 'Order',
    width: 50,
  },
  {
    Header: 'SEO',
    accessor: 'SeoEntry',
    style: { flexDirection: 'column', alignItems: 'baseline' },
    Cell: ({ value }) => (
      <Fragment>
        {value.Title && <span><b>Title: </b> {value.Title}</span>}
        {value.Description && <span><b>Description: </b> {value.Description}</span>}
        {value.Keywords && <span><b>Keywords: </b> {value.Keywords}</span>}
      </Fragment>
    ),
    minWidth: 250,
  },
  {
    Header: 'Người sửa',
    accessor: 'EditorID',
    Cell: ({ value }) => <AccountDisplay id={value} />,
    maxWidth: 150,
  },
  {
    Header: 'Ngày sửa',
    width: 130,
    accessor: 'ModifiedDate',
    Cell: ({ value }) => <DateTimeDisplay timestamp={value} />,
  },
  {
    Cell: ({ original }) => (
      <ActionsCellWrapper>
        <TopicContentLogDialogButton topic={original} modifiedDate={original.ModifiedDate} />
      </ActionsCellWrapper>
    ),
    width: 65,
  },
];

function listFn({ offset, limit, filters, sorting }) {
  const listFilters = { ...filters };

  return topicLogApi.getList({
    offset,
    limit,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  }).then((resp) => {
    resp.data.items = resp.data.items.map((item) => {
      return { ...item, SeoEntry: parseJson(item.SeoEntry, {}) };
    });

    return resp;
  });
}

export default function TopicLog({ topicId }) {
  const listProps = useList({
    listFn,
    listId: 'ListTopicLog',
    defaultSorting: { ModifiedDate: 'DESC' },
  });

  useEffect(() => {
    listProps.addFilter('TopicID', topicId);
  }, [topicId]);

  return (
    <Fragment>
      <BaseTable
        {...useListProps(listProps)}
        columns={columns}
      />
    </Fragment>
  );
}
