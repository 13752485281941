import { Checkbox } from 'antd';
import makeStyles from 'core/common/makeStyles';
import { ContentAttributeOptions } from 'core/engine/content/constants';
import useAuth from 'core/hooks/useAuth';
import React, { forwardRef, useMemo } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    '& > label': {
      marginLeft: '0 !important', // override ant's style
      marginRight: 8,
    },
  },
}));

export default forwardRef(function ContentFormAttributeInput({
  value: sum,
  onChange,
}, ref) {
  const classes = useStyles();
  const { loggedInUser, hasExtendedRight } = useAuth();

  const permissions = useMemo(() => {
    const ret = {};
    ContentAttributeOptions.forEach(option => {
      ret[option.value] = loggedInUser.isAdmin || !option.cmd || hasExtendedRight(option.cmd);
    });

    return ret;
  }, [loggedInUser, hasExtendedRight]);

  return (
    <div className={classes.container} ref={ref}>
      {ContentAttributeOptions.map(option => {
        return permissions[option.value] && (
          <Checkbox
            key={option.label}
            checked={(sum & option.value) === option.value}
            value={option.value}
            onChange={handleChange}
          >
            {option.label}
          </Checkbox>
        );
      })}
    </div>
  );

  function handleChange(event) {
    const { value, checked } = event.target;

    if (checked) {
      onChange(addBit(sum, value));
    } else {
      onChange(removeBit(sum, value));
    }
  }
});

function removeBit(sum, bit) {
  if ((sum & bit) === bit) {
    return sum - bit;
  }

  return sum;
}

function addBit(sum, bit) {
  if ((sum & bit) === bit) {
    return sum;
  }

  return sum + bit;
}
