import React, { useMemo } from 'react';
import { Icon } from 'antd';
import { orange, red, gold, cyan, green, blue } from '@ant-design/colors';

const regexMap = [
  { type: 'file-jpg', color: orange[7], regex: /jpe?g/ },
  { type: 'file-image', color: cyan[7], regex: /image/ },
  { type: 'file-pdf', color: red[8], regex: /pdf/ },
  { type: 'file-zip', color: gold[9], regex: /(tar|zip|rar)/ },
  { type: 'video-camera', regex: /video/ },
  { type: 'global', regex: /html/ },
  { type: 'file-excel', color: green[7], regex: /(excel|spreadsheet)/ },
  { type: 'file-word', color: blue[5], regex: /(word|wordprocessing)/ },
  { type: 'file-ppt', color: orange[7], regex: /(powerpoint|presentation)/ },
];

export default function MimeTypeIcon({ mimeType = '', ...restProps }) {
  const icon = useMemo(() => {
    let matchIcon = { type: 'file' };

    regexMap.some((icon) => {
      if (icon.regex.test(mimeType)) {
        matchIcon = icon;
        return true;
      }

      return false;
    });

    return matchIcon;
  }, [mimeType]);

  return <Icon title={mimeType} type={icon.type} style={{ color: icon.color }} {...restProps} />;
}
