import React, { memo, useMemo } from 'react';
import { buildTopicUrl } from 'core/common/urlHelper';

export default memo(function TopicViewLink({ id, name, children, ...props }) {
  const url = useMemo(() => {
    return buildTopicUrl(id, name);
  }, [id, name]);

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" {...props}>{children}</a>
  );
});
