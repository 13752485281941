import { Tree } from 'antd';
import { emptyArray, emptyFunction } from 'core/common/empty';
import lodash from 'core/common/lodash';
import { buildTree } from 'core/common/utils';
import zoneApi from 'core/engine/zone/api';
import { getListEnabledZoneCache, getListZoneCache } from 'core/engine/zone/caching';
import { getSubsByMapRootId } from 'core/engine/zone/utils';
import useDataLoader from 'core/hooks/useDataLoader';
import React, { useMemo } from 'react';
import LoadingWrapper from '../common/LoadingWrapper';

const { TreeNode } = Tree;

function getTreeData(rootZoneId = 0, cache = true, onlyEnabled = true) {
  const promise = cache ?
    (onlyEnabled ?
      getListEnabledZoneCache()
      :
      getListZoneCache()
    )
    :
    (onlyEnabled ?
      zoneApi.getListEnabled().then(resp => resp.data.items)
      :
      zoneApi.getListAll().then(resp => resp.data.items)
    );

  return promise.then(items => {
    const zones = rootZoneId ? getSubsByMapRootId(lodash.keyBy(items, 'ZoneID'), rootZoneId) : items;
    return buildTree(zones, { idField: 'ZoneID' });
  });
}

export default function ZoneTree({
  cache = true,
  onlyEnabled = true,
  onSelect = emptyFunction,
  disabledKeys = emptyArray,
  rootZoneId,
  ...props
}) {
  const disabledMap = useMemo(() => {
    const map = {};
    disabledKeys.forEach(key => map[key] = true);
    return map;
  }, [disabledKeys]);

  const {
    data,
    isLoading,
  } = useDataLoader({
    defaultData: [],
    loadFn: () => getTreeData(rootZoneId, cache, onlyEnabled),
  });

  return (
    <LoadingWrapper center loading={isLoading}>
      <Tree onSelect={onSelect} {...props}>
        {data.map(item => buildNodes(item))}
      </Tree>
    </LoadingWrapper>
  );

  function buildNodes(item) {
    const disabled = disabledMap[item.ZoneID];

    if (item.Subs.length) {
      return (
        <TreeNode key={item.ZoneID} title={item.Name} disabled={disabled}>
          {item.Subs.map(subItem => buildNodes(subItem))}
        </TreeNode>
      );
    }

    return (
      <Tree.TreeNode key={item.ZoneID} title={item.Name} disabled={disabled} isLeaf />
    );
  }
}
