import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import useDialog from 'core/hooks/useDialog';
import BaseDialog from 'core/components/common/BaseDialog';
import makeStyles from 'core/common/makeStyles';
import commonStyles from 'core/common/commonStyles';
import { emptyFunction } from 'core/common/empty';
import { WATERMARK_DEFAULT_POSITION } from 'core/config';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexAllCentered,
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
    position: 'relative',
  },
  watermark: {
    width: '100%',
    cursor: 'pointer',
    border: '1px solid #333',
    marginBottom: 20,

    '&:hover': {
      borderColor: '#4c4b4b',
    },
  },
  positionBox: {
    width: 150,
    height: 150,
    border: '1px solid #d4d4d485',
    position: 'relative',
    alignSelf: 'center',
    margin: '10px 0',

    '& > span': {
      position: 'absolute',
      width: 40,
      height: 40,
      backgroundColor: '#d4d4d485',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: '#d4d4d4',
      },

      '&.active': {
        backgroundColor: '#d4d4d4',
      },
    },

    '& > span:nth-child(1)': {
      top: 2,
      left: 2,
    },
    '& > span:nth-child(2)': {
      top: 2,
      right: 2,
    },
    '& > span:nth-child(3)': {
      bottom: 2,
      left: 2,
    },
    '& > span:nth-child(4)': {
      bottom: 2,
      right: 2,
    },
    '& > span:nth-child(5)': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
}));

export default function FileManagerAddWatermarkButton({
  onAddWatermark = emptyFunction,
}) {
  const classes = useStyles();

  const [uploading, setUploading] = useState(false);

  const { open, handleOpen, handleClose } = useDialog();

  const [selectedPos, setSelectedPos] = useState(WATERMARK_DEFAULT_POSITION);

  return (
    <Tooltip title="Đóng dấu" placement="top">
      <Button type="primary" icon="picture" onClick={handleOpen}>
        Đóng dấu
      </Button>
      <BaseDialog
        visible={open}
        title="Chọn vị trí"
        onCancel={handleClose}
        onOk={handleOk}
        okText="Đóng dấu"
        confirmLoading={uploading}
        keyboard={false}
        maskClosable={false}
        width={600}
        fullHeight
        destroyOnClose
      >
        <div className={classes.container}>
          <div className={classes.positionBox} onClick={handleSelectPosition}>
            <span data-position="tl" className={selectedPos === 'tl' ? 'active' : ''}></span>
            <span data-position="tr" className={selectedPos === 'tr' ? 'active' : ''}></span>
            <span data-position="bl" className={selectedPos === 'bl' ? 'active' : ''}></span>
            <span data-position="br" className={selectedPos === 'br' ? 'active' : ''}></span>
            <span data-position="c" className={selectedPos === 'c' ? 'active' : ''}></span>
          </div>
        </div>
      </BaseDialog>
    </Tooltip>
  );

  function handleSelectPosition(event) {
    const position = event.target.dataset.position;

    if (!position) {
      return;
    }

    setSelectedPos(position);
  }

  function handleOk() {
    Promise.resolve()
      .then(() => setUploading(true))
      .then(() => onAddWatermark(selectedPos))
      .then(() => {
        setUploading(false);
        handleClose();
      })
      .catch(() => setUploading(false));
  }
}
