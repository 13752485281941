import { Button, message } from 'antd';
import { asyncAction } from 'core/common/async';
import { sortingToSortBy } from 'core/common/listUtils';
import { parseQuery } from 'core/common/urlHelper';
import AsyncButton from 'core/components/common/AsyncButton';
import Container from 'core/components/common/Container';
import FlexPushRight from 'core/components/common/FlexPushRight';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import Selector from 'core/components/common/Selector';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import liveStreamApi from 'core/engine/live-stream/api';
import { LiveStreamSearchOptions, LiveStreamStatus, LiveStreamStatusLabel } from 'core/engine/live-stream/constants';
import LiveStreamFormButton from './LiveStreamFormButton';
import LiveStreamList from './LiveStreamList';
import AuthzCheck from 'core/components/common/AuthzCheck';
import lodash from 'core/common/lodash';
import liveApi from 'core/engine/live/api';
import makeStyles from 'core/common/makeStyles';

const useStyles = makeStyles({
  title: {
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 0,
    marginLeft: 5,
  },
});

function listFn({ limit, offset, filters, sorting }) {
  const listFilters = { ...filters };

  if (filters.search.type && filters.search.value) {
    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return liveStreamApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

export default function LiveStreamListView() {
  const classes = useStyles();
  const location = useLocation();
  const { search } = location;

  const [selectedIds, setSelectedIds] = useState([]);
  const [live, setLive] = useState(null);

  const liveId = useMemo(() => {
    return parseQuery(search).liveId;
  }, [search]);

  const listProps = useList({
    listFn,
    defaultSorting: {
      StreamID: 'DESC',
    },
    defaultFilters: {
      search: { type: LiveStreamSearchOptions[0].value },
      Status: LiveStreamStatus.DRAFT,
    },
  });

  useEffect(() => {
    liveApi.get(liveId).then(res => setLive(res.data.item));
    listProps.addFilter('LiveID', liveId);
  }, [liveId]);

  const handleStatusFilter = useCreateOnFilterChange(listProps, 'Status');
  const handleSearchFilter = useCreateOnFilterChange(listProps, 'search');

  return (
    <Container pageTitle="Quản lý nội dung tường thuật">
      {live !== null &&
        <p className={classes.title}>
          {live.Title}
        </p>
      }
      <LiveStreamFormButton onDone={listProps.refresh} />
      {selectedIds.length > 0 ? (
        <TableToolbarWrapper>
          <span>{selectedIds.length} tin đã chọn</span>
          <FlexPushRight />
          <Button onClick={handleClearSelectedItems}>Hủy</Button>
          <AuthzCheck extendedRight="approve-livestream">
            <AsyncButton title="Duyệt các tin đã chọn" type="primary" onClick={handleApproveSelectedItems}>Duyệt {selectedIds.length} tin đã chọn</AsyncButton>
          </AuthzCheck>
        </TableToolbarWrapper>
      ) : (
        <TableToolbarWrapper>
          <Selector
            options={LiveStreamStatusLabel}
            valueIsNumber
            emptyLabel="Tất cả"
            value={listProps.filters.Status}
            onChange={handleStatusFilter}
          />

          <SearchFieldWithType
            typeOptions={LiveStreamSearchOptions}
            search={listProps.filters.search}
            onSearch={handleSearchFilter}
          />
        </TableToolbarWrapper>
      )}
      <LiveStreamList
        {...useListProps(listProps)}
        heightAdjust={52}
        onUpdate={listProps.refresh}
        onDelete={handleDelete}
        fullHeight={false}
        selectedIds={selectedIds}
        onSelectedChange={setSelectedIds}
      />
    </Container>
  );

  function handleDelete(liveStream) {
    return asyncAction('Xóa nội dung tường thuật', () => {
      return liveStreamApi.update({
        StreamID: liveStream.StreamID,
        Status: LiveStreamStatus.DELETED,
      })
        .then(() => listProps.removeItem(liveStream, 'StreamID'));
    });
  }

  function handleClearSelectedItems() {
    setSelectedIds([]);
  }

  function handleApproveSelectedItems() {
    const itemMap = lodash.keyBy(listProps.items, 'StreamID');

    const toApproveIds = selectedIds.filter(id =>
      itemMap[id] && itemMap[id].Status !== LiveStreamStatus.APPROVED
    );

    if (toApproveIds.length > 0) {
      return asyncAction('Duyệt các tin đã chọn', () => {
        return Promise.all(
          toApproveIds.map(id =>
            liveStreamApi.update({
              StreamID: id,
              Status: LiveStreamStatus.APPROVED,
            }))
        )
          .then(() => listProps.refresh())
          .then(() => setSelectedIds([]));
      });
    }

    message.success('Toàn bộ tin được chọn đã được duyệt');
    setSelectedIds([]);
  }
}
