import { Checkbox, DatePicker, Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import FilePickerInput from 'core/components/common/FilePickerInput';
import ImagePickerInput from 'core/components/common/ImagePickerInput';
import Selector from 'core/components/common/Selector';
import moment from 'moment';
import React, { useMemo } from 'react';
import { EPaperType, EPaperTypeLabel } from 'src/engine/epaper/constants';

export default function EPaperForm({ form, epaper }) {
  const { getFieldDecorator } = form;

  const date = useMemo(() => {
    return epaper.Date ? moment(parseInt(epaper.Date)) : moment();
  }, [epaper.Date]);

  getFieldDecorator('EPaperID', { initialValue: epaper.EPaperID });

  return (
    <Form {...commonStyles.formItemLayout}>
      <Form.Item label="Tiêu đề">
        {getFieldDecorator('Title', {
          initialValue: epaper.Title,
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <Input autoFocus />
        )}
      </Form.Item>
      <Form.Item label="Loại">
        {getFieldDecorator('Type', {
          initialValue: isNullOrUndefined(epaper.Type) ? EPaperType.DAILY : epaper.Type,
          rules: [{ required: true, message: 'Bắt buộc!' }],
        })(
          <Selector options={EPaperTypeLabel} valueIsNumber />
        )}
      </Form.Item>
      <Form.Item label="Ảnh đại diện">
        {getFieldDecorator('AvatarUrl', {
          initialValue: epaper.AvatarUrl,
          rules: [{ max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <ImagePickerInput />
        )}
      </Form.Item>
      <Form.Item label="Đường dẫn file">
        {getFieldDecorator('Url', {
          initialValue: epaper.Url,
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
        })(
          <FilePickerInput />
        )}
      </Form.Item>
      <Form.Item label="Ngày xuất bản">
        {getFieldDecorator('Date', {
          initialValue: date,
          rules: [{ required: true, message: 'Bắt buộc!' }],
        })(
          <DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
        )}
      </Form.Item>
      <Form.Item label="Số ra">
        {getFieldDecorator('Number', {
          initialValue: epaper.Number || 0,
          rules: [{ required: true, message: 'Bắt buộc!' }],
        })(
          <Input type="number" min={1} />
        )}
      </Form.Item>
      <Form.Item label="Mô tả">
        {getFieldDecorator('Description', {
          initialValue: epaper.Description,
          rules: [{ max: 2000, message: 'Tối đa 2000 ký tự!' }],
        })(
          <Input.TextArea autoSize />
        )}
      </Form.Item>
      <Form.Item label="Thứ tự">
        {getFieldDecorator('Order', {
          initialValue: epaper.Order || 0,
          rules: [{ required: true, message: 'Bắt buộc!' }],
        })(
          <Input type="number" min={0} />
        )}
      </Form.Item>
      <Form.Item label="Sử dụng">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: isNullOrUndefined(epaper.Enabled) ? true : epaper.Enabled,
        })(
          <Checkbox />
        )}
      </Form.Item>
    </Form>
  );
}
