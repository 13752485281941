import { httpGet, httpPost } from 'core/common/http-client';

const DEFAULT_FIELDS = [
  'CommentID',
  'ObjectType',
  'ObjectID',
  'Comment',
  'UserID',
  'UserDisplayName',
  'UserEmail',
  'Status',
  'ApproverID',
  'CreatedDate',
  'ApprovalDate',
];

const DEFAULT_LOOKUP = [
  'ContentStat.ContentID',
  'ContentStat.CommentCount',
];

const commentApi = {
  get(id, { fields = [] } = {}) {
    const qs = {
      CommentID: id,
      fields: [...DEFAULT_FIELDS, ...fields],
    };

    return httpGet('/comments/get/item', { qs });
  },

  getList({ limit = 20, offset = 0, sortBy, fields = [], filters }) {
    const qs = {
      limit, offset, sortBy,
      fields: [...DEFAULT_FIELDS, ...fields],
      lookup: [...DEFAULT_LOOKUP],
      ...filters,
    };

    return httpGet('/comments/get/items-by-range', { qs });
  },

  approve(data) {
    const qs = { action: 'approve' };
    return httpPost('/comments/post/update', data, { qs });
  },

  reject(data) {
    const qs = { action: 'reject' };
    return httpPost('/comments/post/update', data, { qs });
  },

  update(data) {
    const qs = { action: 'change' };
    return httpPost('/comments/post/update', data, { qs });
  },

  changeContent(data) {
    const qs = { action: 'changecontent' };
    return httpPost('/comments/post/update', data, { qs });
  },

  multiApprove(ids, approve) {
    return httpPost('/comments/post/multi-approve', { commentIds: ids, approve });
  },

  multiDelete(ids) {
    return httpPost('/comments/post/multi-delete', { commentIds: ids });
  },
};

export default commentApi;
