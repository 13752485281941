import { Spin } from 'antd';
import classnames from 'classnames';
import makeStyles from 'core/common/makeStyles';
import { setTitle } from 'core/redux/actions/ui';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  allCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Container({ pageTitle, loading, style, className, children }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle(pageTitle));
  }, [pageTitle]);

  return (
    <div
      style={style}
      className={classnames(classes.container, className, loading && classes.allCentered)}
    >
      <ErrorBoundary simple={false}>
        {loading ? <Spin /> : children}
      </ErrorBoundary>

    </div>
  );
}
