import { IS_MOBILE } from '@bm/common';
import { Button, Dropdown, Icon, Menu } from 'antd';
import React from 'react';
import useAuth from 'core/hooks/useAuth';
import HeaderUserMenuChangePasswordButton from './HeaderUserMenuChangePasswordButton';
import HeaderUserMenuChangeProfile from './HeaderUserMenuChangeProfile';

const { Item, Divider } = Menu;

export default function HeaderUserMenu({ className }) {
  const { loggedInUser, logout } = useAuth();

  if (!loggedInUser) {
    return null;
  }

  const menu = (
    <Menu>
      {IS_MOBILE && (
        <Item>
          <span>Hi, {loggedInUser.name} </span>
        </Item>
      )}
      {IS_MOBILE && (
        <Divider />
      )}
      <Item>
        <HeaderUserMenuChangeProfile />
      </Item>
      <Item>
        <HeaderUserMenuChangePasswordButton />
      </Item>
      <Divider />
      <Item onClick={logout}>
        <Icon type="logout" />
        Đăng xuất
      </Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} className={className}>
      {IS_MOBILE ? (
        <Button icon="user" />
      ) : (
        <Button>Hi, {loggedInUser.name} <Icon type="down" /></Button>
      )}
    </Dropdown>
  );
}
