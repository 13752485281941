import { Button, Form, message } from 'antd';
import { asyncAction } from 'core/common/async';
import makeStyles from 'core/common/makeStyles';
import { parseQuery } from 'core/common/urlHelper';
import useFormHandler from 'core/hooks/useFormHandler';
import React, { useCallback, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import liveStreamApi from 'core/engine/live-stream/api';
import LiveStreamForm from './LiveStreamForm';

const useStyles = makeStyles(() => ({
  container: {
    padding: '10px 5px',
    marginBottom: 20,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  toolbar: {
    display: 'flex',
    marginTop: 10,
  },
  button: {
    marginLeft: 8,
  },
}));

function LiveStreamFormButton({ form, onDone }) {
  const classes = useStyles();

  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  const { search } = useLocation();
  const liveId = useMemo(() => parseQuery(search).liveId, [search]);
  const onReset = useCallback(() => {
    form.resetFields();
    editorRef.current.setValue('<p></p>');
  }, [form]);

  const editorRef = useRef();

  return (
    <div className={classes.container}>
      <div className={classes.title}>Thêm mới nội dung tường thuật</div>
      <LiveStreamForm form={form} editorRef={editorRef} />
      <div className={classes.toolbar}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
          title="Tạo mới"
          onClick={handleSubmit}
        >Tạo mới</Button>
        <Button
          className={classes.button}
          onClick={onReset}
          disabled={isSubmitting}
          title="Đặt lại">
          Đặt lại
        </Button>
      </div>
    </div>
  );

  function submitFn({ formData }) {
    if (liveId <= 0) {
      message.error('Bạn chưa chọn tường thuật');
      return;
    }

    formData.LiveID = liveId;

    if (formData.Date) {
      formData.Date = formData.Date.toDate().getTime();
    }

    formData.HtmlDetail = editorRef.current.getNormalizedValue();

    return asyncAction('Tạo nội dung tường thuật', () => {
      return liveStreamApi.create(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    onDone();
    onReset();
  }
}

export default Form.create({ name: 'LiveStreamFormButton' })(LiveStreamFormButton);
