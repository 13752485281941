import React, { useMemo, memo } from 'react';

const styles = {
  small: {
    paddingRight: 2,
    paddingLeft: 2,
  },
  medium: {
    paddingRight: 5,
    paddingLeft: 5,
  },
  large: {
    paddingRight: 10,
    paddingLeft: 10,
  },
};

function Separator({ tagName = 'span', text = '', size = 'medium' }) {
  const TagName = tagName;

  const style = useMemo(() => {
    if (typeof size === 'number') {
      return {
        paddingRight: size / 2,
        paddingLeft: size / 2,
      };
    }

    return styles[size];
  }, [size]);

  return (
    <TagName style={style}>{text}</TagName>
  );
}

export default memo(Separator, () => true);
