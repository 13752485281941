
import { httpGet, httpPost } from 'core/common/http-client';

const commandApi = {
  get(id) {
    return httpGet(`/commands/get/item?CommandID=${id}`);
  },

  getList({ limit = 20, offset = 0 }) {
    const qs = { limit: limit, offset: offset };
    return httpGet('/commands/get/items-by-range', { qs });
  },

  getListAll() {
    return httpGet('/commands/get/items');
  },
  getListAuthorized() {
    return httpGet('/commands/get/items-by-account');
  },
  create(data) {
    return httpPost('/commands/post/insert', data);
  },

  update(data) {
    return httpPost('/commands/post/update', data);
  },

  delete(id) {
    return httpPost('/commands/post/delete', { CommandID: id });
  },
};

export default commandApi;
