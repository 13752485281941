import { blue } from '@ant-design/colors';
import { Button, Icon, Pagination } from 'antd';
import { asyncAction } from 'core/common/async';
import commonStyles from 'core/common/commonStyles';
import { emptyFunction } from 'core/common/empty';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import makeStyles from 'core/common/makeStyles';
import AccountSelector from 'core/components/account/AccountSelector';
import FlexPushRight from 'core/components/common/FlexPushRight';
import RangeDateSelector from 'core/components/common/RangeDateSelector';
import FileChooserButton from 'core/components/file-manager/FileChooserButton';
import FileGrid from 'core/components/file-manager/FileGrid';
import FileManagerAlbumDialogButton from 'core/components/file-manager/FileManagerAlbumDialogButton';
import FileManagerAlbumGrid from 'core/components/file-manager/FileManagerAlbumGrid';
import FileManagerDragAndDrop from 'core/components/file-manager/FileManagerDragAndDrop';
import FileManagerSearchField from 'core/components/file-manager/FileManagerSearchField';
import albumApi from 'core/engine/album/api';
import fileApi from 'core/engine/file/api';
import { FileType } from 'core/engine/file/constants';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';
import useList from 'core/hooks/useList';
import { getCurrentStore } from 'core/redux/store';
import moment from 'moment';
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import VideoAudioManagerSidebar from './VideoAudioManagerSidebar';
import VideoAudioManagerUploadButton from './VideoAudioManagerUploadButton';

function listFn({ limit, offset, sorting, filters }) {
  let listFilters = { ...filters };

  if (!listFilters.AlbumID && filters.rangeDate[0] && filters.rangeDate[1]) {
    listFilters.CreatedDate = dateRangeToFilter(filters.rangeDate);
  }

  delete listFilters.rangeDate;

  if (filters.search.value) {
    if (['FileID', 'OriginID'].includes(filters.search.type)) {
      listFilters = {}; // just ignore any other filters
    }

    listFilters[filters.search.type] = filters.search.value;
  }

  delete listFilters.search;

  return fileApi.getList({
    offset,
    limit,
    sortBy: sortingToSortBy(sorting),
    filters: listFilters,
  });
}

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
    height: '100%',
    minWidth: 626,
    '@media screen and (max-width: 723px)': {
      paddingRight: 24,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    height: 32,

    '& > .ant-select': {
      maxWidth: 200,
    },

    '& > *:not(:last-child)': {
      marginRight: 10,
    },
  },
  inner: {
    ...commonStyles.flexRow,
    height: 'calc(100% - 42px)',
  },
  content: {
    ...commonStyles.flexColumn,
    width: 'calc(100% - 298px)',
    border: '1px solid #d4d4d4',
    borderRadius: 4,
  },
  grid: {
    width: '100%',
    height: 'calc(100% - 42px)',
    overflow: 'auto',
    padding: 10,
    marginBottom: 10,
  },
  pagination: {
    alignSelf: 'flex-end',
    paddingBottom: 10,
    marginRight: 10,
  },
  albumName: {
    ...commonStyles.ellipsis,
    height: 32,
    maxWidth: 150,
    minWidth: 150,
    padding: '0px 10px',
    lineHeight: '32px',
    border: '1px #d9d9d9 dashed',
    borderRadius: 4,
    fontWeight: 700,
    position: 'relative',
    paddingRight: 20,
  },
  clearIcon: {
    cursor: 'pointer',
    lineHeight: '34px',
    position: 'absolute',
    right: 7,
    color: blue[4],
  },
}));

const defaultRangeDate = [moment().add(-1, 'year'), moment().add(1, 'day')];

export default function VideoAudioManager({
  onSelect = emptyFunction,
  pageSize = 50,
}) {
  const classes = useStyles();

  // workaround since file-manager can be rendered outside Redux Provider by zdocs plugins.
  const currentUser = useMemo(() => {
    return getCurrentStore().getState().auth.user;
  }, []);

  const [editingFile, setEditingFile] = useState();
  const selectedFilesRef = useRef();
  const uploadButtonRef = useRef();

  const [showAlbums, setShowAlbums] = useState(false);
  const [currentAlbum, setCurrentAlbum] = useState();
  const albumGridRef = useRef();

  const listProps = useList({
    listFn,
    defaultPageSize: pageSize,
    defaultSorting: {
      CreatedDate: 'DESC',
    },
    defaultFilters: {
      OwnerID: currentUser ? currentUser.id : null,
      search: { type: 'Name' },
      rangeDate: defaultRangeDate,
      Type: `in:${[FileType.AUDIO, FileType.VIDEO].join()}`,
    },
    autoLoad: true,
  });

  const handleSelect = useCallback((selectedFiles, isDoubleClick) => {
    selectedFilesRef.current = selectedFiles;
    onSelect(selectedFiles, isDoubleClick);

    if (Array.isArray(selectedFiles)) {
      setEditingFile(selectedFiles[selectedFiles.length - 1]);
    } else {
      setEditingFile(selectedFiles);
    }
  }, [onSelect]);

  const handleFilterOnwerId = useCreateOnFilterChange(listProps, 'OwnerID');
  const handleSearchFilter = useCreateOnFilterChange(listProps, 'search');
  const handleRangeDateFilter = useCreateOnFilterChange(listProps, 'rangeDate');

  let toolbar;

  if (showAlbums) {
    toolbar = (
      <Fragment>
        <Button icon="arrow-left" onClick={handleShowFiles}>Tất cả video</Button>
        <FlexPushRight />
        <FileManagerAlbumDialogButton album={{ Type: FileType.VIDEO }} isEditMode={false} onDone={handleAlbumAdded} />
      </Fragment>
    );
  } else {
    toolbar = (
      <Fragment>
        <Button icon="book" onClick={handleShowAlbums}>Album</Button>
        {currentAlbum && (
          <div className={classes.albumName}>
            {currentAlbum.Name}
            <Icon title="Xóa bộ lọc album" type="close-circle" className={classes.clearIcon} onClick={handleShowFiles} />
          </div>
        )}
        <AccountSelector
          allowClear
          emptyLabel="-- Người tạo --"
          value={listProps.filters.OwnerID}
          onChange={handleFilterOnwerId}
        />
        <FileManagerSearchField
          type={listProps.filters.search.type}
          value={listProps.filters.search.value}
          onSearch={handleSearchFilter}
        />
        {!currentAlbum && (
          <RangeDateSelector
            value={listProps.filters.rangeDate}
            onChange={handleRangeDateFilter}
          />
        )}
        <FlexPushRight />
        {currentAlbum && (
          <Fragment>
            <FileChooserButton
              title="Thêm video vào album"
              onSelect={handleAddFilesToAlbum}
              fileType={FileType.VIDEO}
              multiple={false}
              triggerComponent={({ onClick }) => (
                <Button title="Thêm video vào album" icon="plus" onClick={onClick}>Thêm video vào album</Button>
              )}
            />
          </Fragment>
        )}
        <VideoAudioManagerUploadButton ref={uploadButtonRef} onUploaded={handleUploaded} />
      </Fragment>
    );
  }

  return (
    <FileManagerDragAndDrop onDrop={handleDrop} className={classes.container}>
      <div className={classes.toolbar}>
        {toolbar}
      </div>
      {showAlbums ? (
        <div className={classes.inner}>
          <FileManagerAlbumGrid
            ref={albumGridRef}
            selectedId={listProps.filters.AlbumID}
            onAlbumSelect={handleAlbumSelect}
            type={FileType.VIDEO}
          />
        </div>
      ) : (
        <div className={classes.inner}>
          <div className={classes.content}>
            <div className={classes.grid}>
              <FileGrid
                loading={listProps.isFetching}
                files={listProps.items}
                multiple={false}
                onSelect={handleSelect}
                editingFile={editingFile}
                grid={true}
              />
            </div>
            <Pagination
              className={classes.pagination}
              showQuickJumper
              total={listProps.total}
              pageSize={listProps.pageSize}
              showTotal={(total, range) => `${range[0]}-${range[1]} trong số ${total}`}
              current={listProps.pageIndex + 1}
              onChange={page => listProps.onPageChange(page - 1)}
            />
          </div>
          <VideoAudioManagerSidebar
            currentFile={editingFile}
            onFilterOwnerId={handleFilterOnwerId}
            onFileUpdated={handleFileUpdated}
          />
        </div>
      )}
    </FileManagerDragAndDrop>
  );

  function handleDrop(files) {
    uploadButtonRef.current.upload(files);
  }

  function handleUploaded(file) {
    listProps.addItem({ ...file, isNew: true });

    if (currentAlbum) {
      albumApi.addFiles(currentAlbum.AlbumID, [file.FileID]);
    }
  }

  function handleFileUpdated(file) {
    listProps.replaceItem(file, 'FileID');

    // update selected file if any
    if (selectedFilesRef.current) {
      if (Array.isArray(selectedFilesRef.current)) {
        const index = selectedFilesRef.current.findIndex(item => item.FileID === file.FileID);

        if (index > -1) {
          selectedFilesRef.current.splice(index, 1, file);
          onSelect(selectedFilesRef.current);
        }
      } else if (selectedFilesRef.current.FileID === file.FileID) {
        selectedFilesRef.current = file;
        onSelect(file);
      }
    }
  }

  function handleShowFiles() {
    setShowAlbums(false);
    listProps.addFilter('AlbumID', null);
    setCurrentAlbum();
    setEditingFile();
  }

  function handleShowAlbums() {
    setShowAlbums(true);
  }

  function handleAlbumSelect(album) {
    setShowAlbums(false);
    listProps.addFilter('AlbumID', album.AlbumID);
    setCurrentAlbum(album);
    setEditingFile();
  }

  function handleAddFilesToAlbum(files) {
    return asyncAction('Thêm vào album', () => {
      return albumApi.addFiles(currentAlbum.AlbumID, files.map(file => file.FileID))
        .then(() => listProps.refresh());
    });
  }

  function handleAlbumAdded() {
    return albumGridRef.current.refresh();
  }
}
