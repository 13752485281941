import { Checkbox, DatePicker, Form, Input } from 'antd';
import commonStyles from 'core/common/commonStyles';
import { isNullOrUndefined } from 'core/common/is';
import moment from 'moment';
import React, { useMemo } from 'react';

export default function LiveForm({ form, onSubmit, live }) {
  const { getFieldDecorator } = form;
  const defaultStartDate = useMemo(() => moment(), []);
  const defaultEndDate = useMemo(() => moment().add(1, 'days'), []);

  return (
    <Form {...commonStyles.formItemLayout} onSubmit={onSubmit}>
      {live.LiveID && (
        <Form.Item label="ID">
          {getFieldDecorator('LiveID', {
            initialValue: live.LiveID,
          })(
            <Input disabled type="number" />
          )}
        </Form.Item>
      )}
      <Form.Item label="Tiêu đề">
        {getFieldDecorator('Title', {
          rules: [{ required: true, message: 'Bắt buộc!' }, { max: 250, message: 'Tối đa 250 kí tự!' }],
          initialValue: live.Title,
        })(
          <Input autoFocus />
        )}
      </Form.Item>

      <Form.Item label="Ngày bắt đầu">
        {getFieldDecorator('StartDate', {
          initialValue: live.StartDate ? moment(parseInt(live.StartDate)) : defaultStartDate,
          rules: [
            { required: true, message: 'Bắt buộc!' },
          ],
        })(<DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />)}
      </Form.Item>

      <Form.Item label="Ngày kết thúc">
        {getFieldDecorator('EndDate', {
          initialValue: live.EndDate ? moment(parseInt(live.EndDate)) : defaultEndDate,
        })(<DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />)}
      </Form.Item>

      <Form.Item label="Kích hoạt">
        {getFieldDecorator('Enabled', {
          valuePropName: 'checked',
          initialValue: isNullOrUndefined(live.Enabled) ? true : live.Enabled,
        })(
          <Checkbox />
        )}
      </Form.Item>
    </Form>
  );
}
