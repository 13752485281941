import { Select } from 'antd';
import lodash from 'core/common/lodash';
import tagApi from 'core/engine/tag/api';
import useDebounce from 'core/hooks/useDebounce';
import React, { forwardRef, useEffect, useState } from 'react';

const { Option } = Select;

export default forwardRef(function ContentFormTagsInput({
  value,
  onChange,
}, ref) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 200);

  useEffect(() => {
    if (debouncedSearch) {
      setLoading(true);
      tagApi.suggest(debouncedSearch, value)
        .then((resp) => {
          if (resp.data) {
            setOptions(resp.data);
          } else {
            setOptions([]);
          }

          setLoading(false);
        });
    } else {
      setOptions([]);
    }
  }, [debouncedSearch, value]);

  return (
    <Select
      ref={ref}
      showSearch
      allowClear
      autoClearSearchValue
      mode="tags"
      placeholder="Từ khóa bài viết"
      value={value}
      loading={loading}
      onSearch={handleSearch}
      onChange={handleChange}
      onBlur={onBlur}
      filterOption={false}
      tokenSeparators={[',', ';']}
    >
      {search && options.map(option => (
        <Option key={option}
          value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );

  function handleChange(value) {
    onChange(lodash.uniq(value.map(v => v.trim()).filter(Boolean)));
  }

  function handleSearch(value) {
    setSearch(value);
  }

  function onBlur() {
    setSearch('');
  }

});
