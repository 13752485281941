import React, { forwardRef, useMemo } from 'react';
import { Select } from 'antd';
import { converToSelections } from 'core/common/utils';

const { Option } = Select;

export default forwardRef(function Selector({
  options,
  emptyLabel,
  emptyValue = null,
  value = emptyValue,
  onChange,
  valueField = 'value',
  labelField = 'label',
  idField = valueField,
  valueIsNumber = false,
  width = 0,
  renderLabel,
  selectProps,
  style,
  className,
  excludedValues,
  disabledOptionValues,
  disabledInValues,
  showDisabledOptions = false,
  ...props
}, ref) {
  const initValue = useMemo(() => value, []);

  const selectStyle = useMemo(() => {
    return {
      width: width ? width : '100%',
      ...style,
    };
  }, [width, style]);

  const selections = useMemo(() => {
    let output;

    if (Array.isArray(options)) {
      output = options;
    } else {
      output = converToSelections(options);
    }

    if (!showDisabledOptions) {
      output = output.filter(option => option[valueField] === initValue || !option.disabled);
    }

    return output;
  }, [options, showDisabledOptions, initValue, valueField]);

  const emptySelection = useMemo(() => {
    if (emptyLabel) {
      return {
        value: emptyValue,
        label: emptyLabel,
      };
    }
  }, [emptyLabel, emptyValue]);

  return (
    <Select
      ref={ref}
      style={selectStyle}
      className={className}
      showSearch
      onChange={onChange}
      value={value}
      optionFilterProp="children"
      disabled={disabledInValues && disabledInValues.includes(value)}
      {...selectProps}
      {...props}
    >
      {emptySelection && <Option key={emptySelection.value} value={emptySelection.value}>{emptySelection.label}</Option>}
      {selections
        .reduce((previous, current) => {
          previous.push({
            key: current[idField],
            disabled: Boolean(current.disabled),
            label: renderLabel ? renderLabel(current) : current[labelField],
            value: valueIsNumber ? parseInt(current[valueField]) : current[valueField],
          });

          return previous;
        }, [])
        .filter((option) => !excludedValues || !excludedValues.includes(option.value))
        .map((option) => (
          <Option
            disabled={option.disabled || (disabledOptionValues && disabledOptionValues.includes(option.value))}
            key={option.key}
            value={option.value}
            title={option.label}
          >
            {option.label}
          </Option>
        ))}
    </Select>
  );
});
