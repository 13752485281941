import { IS_MOBILE } from '@bm/common';
import { Col, Row, Spin, Tree } from 'antd';
import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { asyncAction } from 'core/common/async';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import { buildTree } from 'core/common/utils';
import Container from 'core/components/common/Container';
import zoneApi from 'core/engine/zone/api';
import useDataLoader from 'core/hooks/useDataLoader';
import ZoneForm from './ZoneForm';

const { TreeNode } = Tree;
const EMPTY_ITEM = { Enabled: true, Visible: true };

const useStyles = makeStyles(() => ({
  tree: {
    borderRight: '1px solid #d4d4d4',
    minHeight: '70vh',
  },
  mobileTree: {
    minHeight: 'auto',
  },
  mobileForm: {
    borderTop: '1px solid #d4d4d4',
    paddingTop: 10,
  },
  allCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function loadZoneMap() {
  return zoneApi.getListAll()
    .then(resp => lodash.keyBy(resp.data.items, 'ZoneID'));
}

export default function ZonesView() {
  const classes = useStyles();
  const [itemTree, setItemTree] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const {
    data: itemMap,
    setData: setItemMap,
    isLoading,
  } = useDataLoader({ defaultData: {}, loadFn: loadZoneMap });

  useEffect(() => {
    setItemTree(buildTree(Object.values(itemMap), { idField: 'ZoneID' }));
  }, [itemMap]);

  useEffect(() => {
    setSelectedItem((item) => {
      return item && itemMap[item.ZoneID];
    });
  }, [itemMap]);

  const onSelect = useCallback((selectedKeys) => {
    setSelectedItem(itemMap[selectedKeys[0]]);
  }, [itemMap]);

  return (
    <Container pageTitle="Chuyên mục">
      <Row>
        <Col xs={24} sm={8} className={classnames(IS_MOBILE ? classes.mobileTree : classes.tree, isLoading && classes.allCentered)}>
          {isLoading ? (
            <Spin />
          ) : (
            <Tree onSelect={onSelect}>
              {itemTree.map(item => buildNodes(item))}
            </Tree>
          )}
        </Col>
        <Col xs={24} sm={16} className={classnames(IS_MOBILE ? classes.mobileForm : null)}>
          <ZoneForm
            zone={selectedItem || EMPTY_ITEM}
            submitFn={submitFn}
            onDelete={handleDelete}
            onCancel={handleCancel}
          />
        </Col>
      </Row>
    </Container>
  );

  function submitFn({ formData }) {
    formData.SeoEntry = JSON.stringify(formData.SeoEntry);

    if (formData.Meta) {
      formData.Meta = JSON.stringify(formData.Meta);
    }

    if (formData.ZoneID !== undefined) {
      return asyncAction('Cập nhật chuyên mục', () => {
        return zoneApi.update(formData).then(() => {
          const newItem = { ...itemMap[formData.ZoneID], ...formData };

          setItemMap({
            ...itemMap,
            [newItem.ZoneID]: newItem,
          });
        });
      });
    }

    return asyncAction('Tạo chuyên mục', () => {
      return zoneApi.create(formData).then((resp) => {
        const itemId = resp.data.generatedId;
        const newItem = { ...formData, ZoneID: itemId };

        setSelectedItem(newItem);
        setItemMap({
          ...itemMap,
          [itemId]: newItem,
        });
      });
    });
  }

  function handleDelete() {
    return asyncAction('Xóa chuyên mục', () => {
      return zoneApi.delete(selectedItem.ZoneID).then(() => {
        setItemMap(itemMap => {
          const newItemMap = { ...itemMap };
          delete newItemMap[selectedItem.ZoneID];
          return newItemMap;
        });
      });
    });
  }

  function handleCancel() {
    setSelectedItem();
  }
}

function buildNodes(item) {
  if (item.Subs.length) {
    return (
      <TreeNode key={item.ZoneID} title={item.Name}>
        {item.Subs.map(subItem => buildNodes(subItem))}
      </TreeNode>
    );
  }

  return (
    <Tree.TreeNode key={item.ZoneID} title={item.Name} isLeaf />
  );
}
