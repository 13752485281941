import { Button } from 'antd';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import React, { Fragment } from 'react';
import ContentKeywordSelector from './ContentKeywordSelector';

export default function ContentAddKeywordDialogButton({ keyword }) {
  const { open, handleClose, handleOpen } = useDialog();

  return (
    <Fragment>
      <Button title="Thêm tin" icon="plus" onClick={handleOpen}></Button>
      <BaseDialog
        fullHeightFixed
        visible={open}
        width={900}
        onCancel={handleClose}
        footer={null}
      >
        <ContentKeywordSelector keyword={keyword} />
      </BaseDialog>
    </Fragment>
  );
}
