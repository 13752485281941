import { Form } from 'antd';
import AuthzCheck from 'core/components/common/AuthzCheck';
import React from 'react';
import ContentFormItemDistributionTopicsInput from './ContentFormItemDistributionTopicsInput';

export default function ContentFormItemDistributionTopics({ content, form, defaultTopicIds }) {
  const { getFieldDecorator } = form;

  return (
    <AuthzCheck extendedRight="content.edit-distribution-topics">
      <Form.Item>
        {getFieldDecorator('DistributionTopics', {
          initialValue: content.DistributionTopics || defaultTopicIds,
        })(
          <ContentFormItemDistributionTopicsInput />
        )}
      </Form.Item>
    </AuthzCheck>
  );
}
