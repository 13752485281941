import _ from 'lodash';
import accountApi from 'core/engine/account/api';

export const getListAccountCache = _.memoize(accountApi.getListAll);
export const getListSortedAccountCache = _.memoize((sortBy) => accountApi.getList({ limit: 3000, sortBy }));
export const getMapAccountCache = _.memoize(getMapAccount);
export const getAccountNameCache = _.memoize((id) => getAccountName(id));
export const getAccountSiteCache = _.memoize((id) => getAccountSite(id));

function getMapAccount() {
  return getListAccountCache().then(resp => {
    return resp.data.items.reduce((previous, current) => {
      previous[current.AccountID] = current;
      return previous;
    }, {});
  });
}

function getAccountName(id) {
  return getMapAccountCache().then((resp => resp[id] ? resp[id].Username : '#'));
}

function getAccountSite(id) {
  return getMapAccountCache().then((resp => resp[id] ? resp[id].Site : ''));
}
