import { Button, Form } from 'antd';
import React, { Fragment } from 'react';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import adsOptionApi from 'core/engine/ads-option/api';
import { AdsOptionType } from 'core/engine/ads-option/constants';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import AdsOptionForm from './AdsOptionForm';

function AdsOptionFormDialogButton({ form, adsOption = {}, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={isEditMode ? 'Sửa quảng cáo' : 'Tạo quảng cáo'}
        onClick={handleOpen}>
        {isEditMode ? '' : 'Tạo quảng cáo'}
      </Button>
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa quảng cáo' : 'Tạo quảng cáo'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
        fullHeightFixed
      >
        <AdsOptionForm form={form} adsOption={adsOption} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {

    if (formData.Type === AdsOptionType.Image) {
      formData.BodyCode = '';
    } else if (formData.Type === AdsOptionType.Script) {
      formData.ImageUrl = '';
    }

    if (formData.StartDate) {
      formData.StartDate = formData.StartDate.toDate().getTime();
    }

    if (formData.EndDate) {
      formData.EndDate = formData.EndDate.toDate().getTime();
    }

    return asyncAction(isEditMode ? 'Sửa quảng cáo' : 'Thêm quảng cáo', () => {
      return adsOptionApi.submit(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'AdsOptionFormDialogButton' })(AdsOptionFormDialogButton);
