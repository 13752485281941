import React, { forwardRef, useMemo } from 'react';
import { buildCdnPhotoUrl } from 'core/common/cdn';
import makeStyles from 'core/common/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles(() => ({
  img: {
    width: 'fit-content',
    maxWidth: '100%',
  },
}));

export default forwardRef(function ImageDisplay({ src, alt, width = 0, height = 0, className, ...props }, ref) {
  const classes = useStyles();

  const url = useMemo(() => {
    if (/^http/.test(src)) {
      return src;
    }

    return buildCdnPhotoUrl(src, width, height);
  }, [src, width, height]);

  return (
    <img ref={ref} src={url} alt={alt} className={classnames(className, classes.img)} {...props} />
  );
});
