import { Col, Row } from 'antd';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'core/common/classnames';
import makeStyles from 'core/common/makeStyles';
import { parseQuery } from 'core/common/urlHelper';
import Container from 'core/components/common/Container';
import ContentLogVersionSelector from 'core/components/content-log/ContentLogVersionSelector';
import ContentBodyDisplay from 'core/components/content/ContentBodyDisplay';
import contentLogApi from 'core/engine/content-log/api';
import ImageDisplay from 'core/components/common/ImageDisplay';
import { blue } from '@ant-design/colors';
import HtmlDisplay from 'core/components/common/HtmlDisplay';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  button: {
    marginTop: 20,
  },
  box: {
    border: '1px solid #ccc',
    borderRadius: 4,
    background: '#fafafa',
    padding: 20,
    marginBottom: 10,
  },
  boxSticky: {
    //position: '-webkit-sticky', /* Safari */
    position: 'sticky',
    top: 0,
  },
  detail: {
    width: 660,
    margin: '0 auto',
    textAlign: 'left',
    fontSize: 16,

    '& img': {
      maxWidth: '100%',
    },
    '& del, & del a': {
      textDecoration: 'line-through',
      color: 'red',
    },
    '& del img, & del video, & del iframe': {
      border: 'solid 1px red',
      padding: 1,
    },
    '& ins, & ins a, & ins > *': {
      background: 'SpringGreen',
    },
    '& ins': {
      textDecoration: 'none',
    },
    '& ins .caption': {
      background: 'transparent !important',
    },
    '& ins img, & ins video, & ins iframe': {
      border: 'solid 1px green',
      padding: 1,
    },
  },
  detailHeadline: {
    marginBottom: 10,
    fontSize: 30,
    fontWeight: 700,
  },
  detailTime: {
    float: 'left',
    marginRight: 10,
  },
  avatar: {
    marginBottom: 20,
  },
  detailAvatar: {
    display: 'flex',
    clear: 'both',
  },
  avatarCaption: {
    padding: '10px 20px 8px',
    textAlign: 'center',
    fontSize: 14,
    fontStyle: 'italic',
  },
  detailSapo: {
    fontWeight: 600,
    marginBottom: 20,
  },
  detailKeywords: {
    fontWeight: 500,
  },
  avatarNew: {
    display: 'inline',
    border: '3px solid SpringGreen',
  },
  avatarOld: {
    display: 'inline',
    border: '3px solid red',
  },
  entry: {
    '& p': {
      marginBottom: 8,
    },
    '& [data-label]': {
      fontWeight: 700,
      paddingLeft: 5,
      borderLeft: '5px solid ' + blue.primary,
    },
  },
}));

export default function ContentLogVersionCompareView() {
  const classes = useStyles();
  const [selectedVersions, setSelectedVersions] = useState([]);
  const [diffVersion, setDiffVersion] = useState(null);

  const location = useLocation();
  const { search } = location;

  const contentId = useMemo(() => {
    return parseQuery(search).ContentID;
  }, [search]);

  useEffect(() => {
    if (contentId) {
      contentLogApi.getDiff(contentId, 0, 0)
        .then(resp => {
          setDiffVersion(resp.data.diffVersion);
        });
    }
  }, [contentId]);

  const handleVesionChange = useCallback((values) => {
    if (values.length !== 2) {
      return;
    }

    const versions = values.slice().sort((a, b) => b - a);

    setSelectedVersions(versions);

    return contentLogApi.getDiff(contentId, versions[0], versions[1])
      .then(resp => {
        setDiffVersion(resp.data.diffVersion);
      });

  }, []);

  return (
    <Container pageTitle="So sánh phiên bản">
      <Row gutter={24} type="flex">
        <Col span={16}>
          {diffVersion && (
            <Fragment>
              <div className={classes.box}>
                <div className={classes.detail}>
                  <HtmlDisplay tagName="h1" className={classes.detailHeadline} html={diffVersion.Title} />
                  <div className={classes.container}>
                    <div>
                      <HtmlDisplay tagName="div" html={`<b>Chuyên mục:</b> ${diffVersion.Zones}`} />
                      <HtmlDisplay tagName="div" html={`<b>Sở hữu:</b> ${diffVersion.OwnerName}`} />
                    </div>
                    <div>
                      {diffVersion.DistributionDate && (
                        <HtmlDisplay
                          className={classes.detailTime}
                          html={`<b>Ngày đăng:</b> ${diffVersion.DistributionDate}`}
                        />
                      )}
                      {diffVersion.Author && (
                        <HtmlDisplay tagName="span" html={`<b>Tác giả:</b> ${diffVersion.Author}`} />
                      )}
                    </div>
                  </div>
                  {diffVersion.AvatarUrl && (
                    <div className={classes.avatar}>
                      <div className={classes.detailAvatar}>
                        {diffVersion.AvatarUrl.Old && (
                          <ImageDisplay
                            src={diffVersion.AvatarUrl.Old}
                            alt="avatar old"
                            className={classes.avatarOld}
                          />
                        )}
                        {diffVersion.AvatarUrl.New && (
                          <ImageDisplay
                            src={diffVersion.AvatarUrl.New}
                            alt="avatar new"
                            className={classes.avatarNew}
                          />
                        )}
                        {!diffVersion.AvatarUrl.Old && !diffVersion.AvatarUrl.New && (
                          <ImageDisplay src={diffVersion.AvatarUrl} alt="avatar new" />
                        )}
                      </div>
                      {diffVersion.AvatarDescription && (
                        <HtmlDisplay
                          className={classes.avatarCaption}
                          html={diffVersion.AvatarDescription}
                        />
                      )}
                    </div>
                  )}
                  <HtmlDisplay
                    className={classes.detailSapo}
                    html={diffVersion.Description}
                  />
                  <ContentBodyDisplay content={diffVersion.HtmlBody} />
                  {diffVersion.Source && (
                    <HtmlDisplay
                      className={classes.detailSource}
                      html={`<b>Nguồn:</b> ${diffVersion.Source}`}
                    />
                  )}
                  {diffVersion.Keywords && (
                    <HtmlDisplay
                      className={classes.detailKeywords}
                      html={`<b>Từ khóa:</b> ${diffVersion.Keywords}`}
                    />
                  )}
                  {diffVersion.SeoEntry && (
                    <HtmlDisplay
                      className={classes.entry}
                      html={diffVersion.SeoEntry}
                    />
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </Col>
        <Col span={8}>
          <div className={classnames(classes.box, classes.boxSticky)}>
            <ContentLogVersionSelector
              contentId={contentId}
              value={selectedVersions}
              onChange={handleVesionChange}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
