import { Form, message } from 'antd';
import { asyncAction } from 'core/common/async';
import { parseQuery } from 'core/common/urlHelper';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import React, { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import liveStreamApi from 'core/engine/live-stream/api';
import LiveStreamForm from './LiveStreamForm';
import moment from 'moment';
import AsyncButton from 'core/components/common/AsyncButton';

function LiveStreamFormDialogButton({ form, liveStream = {}, onDone, isEditMode = true, disabled = false }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });
  const editorRef = useRef();

  const { search } = useLocation();
  const liveId = useMemo(() => parseQuery(search).liveId, [search]);

  return (
    <React.Fragment>
      <AsyncButton
        icon={isEditMode ? 'edit' : 'plus'}
        title={isEditMode ? 'Sửa nội dung tường thuật' : 'Tạo nội dung tường thuật'}
        onClick={isEditMode ? handleCheckin : handleOpen}
        disabled={disabled}>
        {isEditMode ? '' : 'Tạo nội dung tường thuật'}
      </AsyncButton>
      <BaseDialog
        visible={open && liveId > 0}
        title={isEditMode ? 'Sửa nội dung tường thuật' : 'Tạo nội dung tường thuật'}
        onCancel={isEditMode ? handleCheckout : handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={935}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <LiveStreamForm
          form={form}
          liveStream={liveStream}
          onSubmit={handleSubmit}
          editorRef={editorRef}
        />
      </BaseDialog>
    </React.Fragment>
  );

  function submitFn({ formData }) {
    if (liveId <= 0) {
      message.error('Bạn chưa chọn tường thuật');
      return;
    }

    formData.LiveID = liveId;

    if (formData.Date) {
      formData.Date = formData.Date.toDate().getTime();
    } else {
      formData.Date = moment.now();
    }

    formData.HtmlDetail = editorRef.current.getNormalizedValue();

    if (isEditMode) {
      return asyncAction('Sửa nội dung tường thuật', () => {
        return liveStreamApi.update(formData)
          .then(handleCheckout)
          .then(handleDone);
      });
    }

    return asyncAction('Tạo nội dung tường thuật', () => {
      return liveStreamApi.create(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }

  function handleCheckin() {
    return liveStreamApi.checkin(liveStream.StreamID)
      .then(handleOpen)
      .catch((err) => {
        message.error(err.error_message, 5);
      });
  }

  function handleCheckout() {
    return liveStreamApi.checkout(liveStream.StreamID)
      .then(handleClose)
      .catch((err) => {
        message.error(err.error_message, 5);
      });
  }
}

export default Form.create({ name: 'LiveStreamFormDialogButton' })(LiveStreamFormDialogButton);
