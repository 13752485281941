import { Button, Form } from 'antd';
import { emptyArray } from 'core/common/empty';
import makeStyles from 'core/common/makeStyles';
import FlexDiv from 'core/components/common/FlexDiv';
import contentApi from 'core/engine/content/api';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import React from 'react';
import ContentFormTagsInput from './ContentFormTagsInput';

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function ContentFormItemKeywords({ form, content, bodyEditorRef }) {
  const classes = useStyles();
  const { getFieldDecorator } = form;

  return (
    <Form.Item>
      <FlexDiv className={classes.header}>
        <span>{ContentFieldLabel.Keywords}:</span>
        <Button
          title="Gợi ý từ khóa"
          type="primary"
          shape="circle"
          size="small"
          icon="bulb"
          onClick={getKeywords}
        />
      </FlexDiv>
      {getFieldDecorator('Keywords', {
        initialValue: content.Keywords ? content.Keywords.split(',') : emptyArray,
        rules: [
          { validator: keywordsValidator },
        ],
      })(
        <ContentFormTagsInput />
      )}
    </Form.Item>
  );

  function getKeywords() {
    const text = bodyEditorRef.current.getValueAsText();
    contentApi.getKeywords(10, text).then((resp) => {
      if (resp.data.keywords) {
        form.setFieldsValue({ 'Keywords': resp.data.keywords });
      }
    });
  }
}

function keywordsValidator(rule, value, callback) {
  if (!value || !value.length) {
    return callback(new Error('Chưa nhập từ khóa!'));
  }

  if (value.length > 2000) {
    return callback(new Error('Tối đa 2000 kí tự!'));
  }

  callback();
}
