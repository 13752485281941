import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import logger from 'core/common/logger';
import { parseQuery } from 'core/common/urlHelper';
import contentApi from 'core/engine/content/api';
import { loadContentForForm } from '../services';

export default function useContentLocationSync({
  content,
  setContent,
  resetContent,
}) {
  const location = useLocation();
  const history = useHistory();
  const contentIdRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    setError(null);

    const { search, state } = location;

    const { ignoreCheckin, content: stateContent } = state || {};
    const { contentId } = parseQuery(search);

    if (stateContent) {
      setIsLoading(false);
      setContent(stateContent);
      return;
    }

    if (contentId && parseInt(contentId) === contentIdRef.current) {
      return;
    }

    if (contentId) {
      logger.debug('Loading content from contentId in URL:', contentId);

      setIsLoading(true);

      const checkinPromise = ignoreCheckin ? Promise.resolve() : contentApi.checkin(contentId);

      checkinPromise
        .then(() =>
          loadContentForForm(contentId)
            .then((content) => {
              setContent(content);
              setIsLoading(false);
            })
        )
        .catch((err) => {
          setIsLoading(false);
          setError(err);
          logger.error('Có lỗi xảy ra khi tải tin:', err);
        });
    } else {
      resetContent();
      setIsLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (content.ContentID) {
      history.replace({
        search: '?contentId=' + content.ContentID,
      });
    }
  }, [content.ContentID, history]);

  useEffect(() => {
    contentIdRef.current = content.ContentID;
  }, [content.ContentID]);

  return {
    isLoading,
    error,
  };
}
