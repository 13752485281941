/* eslint-disable no-console */

import { LOG_LEVEL } from 'core/config';

const noop = () => {};

const debug = LOG_LEVEL > 3 ? console.debug.bind(console, '%c[DEBUG]', 'color: #313131; font-weight: bold') : noop;
const info = LOG_LEVEL > 2 ? console.log.bind(console, '%c[INFO]', 'color: #0000ff; font-weight: bold') : noop;
const warn = LOG_LEVEL > 1 ? console.warn.bind(console, '%c[WARN]', 'color: #ff8000; font-weight: bold') : noop;
const error = LOG_LEVEL > 0 ? console.log.bind(console, '%c[ERROR]', 'color: #ff0000; font-weight: bold') : noop;

export default { debug, info, warn, error };
