import { Tag, Typography, Divider } from 'antd';
import { parseJson } from 'core/common/jsonUtils';
import makeStyles from 'core/common/makeStyles';
import BaseDialog from 'core/components/common/BaseDialog';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import ContentBodyDisplay from 'core/components/content/ContentBodyDisplay';
import useDialog from 'core/hooks/useDialog';
import React, { Fragment, useMemo } from 'react';
import { emptyFunction } from 'core/common/empty';
import { blue } from '@ant-design/colors';
import { formatDateTime } from 'core/common/datetimeHelper';
import HtmlDisplay from 'core/components/common/HtmlDisplay';

const useStyles = makeStyles(() => ({
  tag: {
    cursor: 'pointer',
  },
  wrapper: {
    width: 660,
    display: 'block',
    margin: '0 auto',
  },
}));

export default function ContentAutoDraftRestoreButton({
  draft,
  onRestore = emptyFunction,
}) {
  const classes = useStyles();

  const { open, handleClose, handleOpen } = useDialog();

  const content = useMemo(() => {
    if (!open) {
      return null;
    }

    return parseJson(draft.JsonData, {});
  }, [open, draft]);

  const dateString = useMemo(() => formatDateTime(draft.ModifiedDate), [draft]);

  return (
    <Fragment>
      <Tag title="Xem bản lưu" onClick={handleOpen} className={classes.tag} color={blue[4]}>
        <DateTimeDisplay timestamp={draft.ModifiedDate} />
      </Tag>
      <BaseDialog
        visible={open}
        title={`Bản lưu tự động lúc ${dateString}`}
        onCancel={handleClose}
        onOk={handleOk}
        okText="Khôi phục"
        width={900}
        fullHeightFixed
        keyboard
        maskClosable
      >
        {content && (
          <Fragment>
            <Typography.Text className={classes.wrapper} type="secondary" strong>{content.SubTitle}</Typography.Text>
            <Typography.Title className={classes.wrapper} level={3}>{content.Title}</Typography.Title>
            <Divider />
            <Typography.Text className={classes.wrapper} strong>
              <HtmlDisplay html={content.Description} />
            </Typography.Text>
            <ContentBodyDisplay content={content.HtmlBody} className={classes.body} />
          </Fragment>
        )}
      </BaseDialog>
    </Fragment>
  );

  function handleOk() {
    handleClose();
    onRestore(draft);
  }
}
