import ZDocs, { Config } from '@zdocs/core';
import BasePlugin from '../BasePlugin';

export default class BinPlugin extends BasePlugin {
  static install() {
    Config.prototype.controls.bin = {
      tooltip: 'Xóa',
      icon: 'bin',
      exec: (zdocs, node) => {
        const currentBox = ZDocs.modules.Dom.closest(
          node,
          (element) => element && element.parentNode === zdocs.editor,
          zdocs.editor
        );

        if (node && currentBox) {
          ZDocs.modules.Dom.safeRemove(currentBox);
        }

        zdocs.events.fire('hidePopup');
      },
    };
  }

  get name() {
    return 'BinPlugin';
  }
}
