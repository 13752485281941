import React from 'react';
import Container from '../common/Container';
import DashBoard from '../dashboard/DashBoard';
import { ExtendedRight } from 'core/engine/extended-right/constants';
import { Typography } from 'antd';
import makeStyles from 'core/common/makeStyles';
import commonStyles from 'core/common/commonStyles';
import { CMS_TITLE } from 'core/config';
import AuthzCheck from 'core/components/common/AuthzCheck';
import useServerFeature from 'core/hooks/useServerFeature';

const { Title } = Typography;

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexAllCentered,
    minHeight: '50vh',
  },
}));

export default function HomeView() {
  const classes = useStyles();
  const hasAuthzDashboard = useServerFeature('authz-dashboard');

  return (
    <Container>
      <AuthzCheck extendedRight={hasAuthzDashboard && ExtendedRight.VIEW_DASHBOARD}>
        {(isAuthorized) => isAuthorized ? (
          <DashBoard />
        ) : (
          <div className={classes.container}><Title>Chào mừng bạn đến với {CMS_TITLE}</Title></div>
        )}
      </AuthzCheck>
    </Container>
  );
}
