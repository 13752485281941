import { Icon } from 'antd';
import commonStyles, { commonColors } from 'core/common/commonStyles';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import EditableDiv from 'core/components/common/EditableDiv';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import Separator from 'core/components/common/Separator';
import { ContentLink } from 'core/components/content/ContentLink';
import ZoneDisplay from 'core/components/zone/ZoneDisplay';
import { ObjectType } from 'core/engine/common/constants';
import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { emptyFunction } from 'core/common/empty';
import TopicViewLink from 'core/components/topic/TopicViewLink';
import { COMMENT_MAX_ROWS_DISPLAY } from 'core/config';

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
    height: '100%',
    width: '100%',
  },
  header: {
    ...commonStyles.flexRow,
    flexWrap: 'wrap',
    marginBottom: 10,
  },
  title: {
    color: commonColors.link,
    cursor: 'pointer',
  },
  filterIcon: {
    color: commonColors.link,
    cursor: 'pointer',
    fontSize: 16,
    alignSelf: 'center',
  },
  body: {
    fontSize: 'large',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': COMMENT_MAX_ROWS_DISPLAY,
    '-webkit-box-orient': 'vertical',
  },
  zone: {
    fontWeight: 700,
    fontSize: 'small',
    cursor: 'pointer',
  },
}));

function CommentDisplay({
  comment,
  allowEdit = true,
  onFilterObjectId = emptyFunction,
  onFilterZoneId = emptyFunction,
  onContentChange = emptyFunction,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const objectId = useMemo(() => comment ? comment.ObjectID : 0, [comment]);

  const title = useMemo(() => {
    if (comment.ObjectType === ObjectType.CONTENT) {
      return lodash.get(comment, 'Object.Title') || '#';
    }

    if (comment.ObjectType === ObjectType.TOPIC) {
      return lodash.get(comment, 'Object.Name') || '#';
    }
  }, [comment]);

  const commentCount = useMemo(() => lodash.get(comment, 'ContentStat.CommentCount') || 0, [comment]);

  const zoneId = useMemo(() => {
    return comment.Object ? comment.Object.ZoneID : 0;
  }, [comment]);

  const handleFilterClick = useCallback(() => {
    onFilterObjectId(objectId);
  }, [onFilterObjectId, objectId]);

  const handleZoneClick = useCallback(() => {
    onFilterZoneId(zoneId);
  }, [onFilterZoneId, zoneId]);

  const handleContentChange = useCallback((value) => {
    if (value !== comment.Comment) {
      setIsLoading(true);
      onContentChange({ ...comment, Comment: value }).then(() => setIsLoading(false));
    }
  }, [comment, onContentChange]);

  const objectLink = useMemo(() => {
    if (objectId > 0) {
      if (comment.ObjectType === ObjectType.CONTENT) {
        return (
          <ContentLink id={objectId} postfix={'#comment' + objectId} className={classes.title} title="Xem bài viết">
            {title}
          </ContentLink>
        );
      }

      if (comment.ObjectType === ObjectType.TOPIC) {
        return (
          <TopicViewLink id={objectId} name={title} title="Xem chủ đề">{title}</TopicViewLink>
        );
      }
    }

    return title;
  }, [comment.ObjectType, objectId, title]);

  const objectName = useMemo(() => {
    switch (comment.ObjectType) {
      case ObjectType.CONTENT:
        return 'tin';
      case ObjectType.TOPIC:
        return 'chủ đề';
      default:
        return 'mục';
    }
  }, [comment.ObjectType]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {objectLink}
        {comment.ObjectType === ObjectType.CONTENT && (
          <Fragment>
            <Separator size="small" />
            ({commentCount})
          </Fragment>
        )}
        {zoneId > 0 && (
          <Fragment>
            <Separator text="|" />
            <ZoneDisplay
              className={classes.zone}
              title="Lọc theo chuyên mục này"
              onClick={handleZoneClick}
              id={zoneId}
            />
          </Fragment>
        )}
        {objectId > 0 && (
          <Fragment>
            <Separator text="|" />
            <Icon
              className={classes.filterIcon}
              type="filter"
              title={'Lọc theo ' + objectName + ' này'}
              onClick={handleFilterClick}
            />
          </Fragment>
        )}
      </div>
      {allowEdit ? (
        <LoadingWrapper loading={isLoading}>
          <EditableDiv
            className={classes.body}
            text={comment.Comment}
            onSubmit={handleContentChange}
            title={comment.Comment}
          >
            {comment.Comment}
          </EditableDiv>
        </LoadingWrapper>
      ) : (
        <div className={classes.body} title={comment.Comment}>{comment.Comment}</div>
      )}
    </div>
  );
}

export default memo(CommentDisplay);
