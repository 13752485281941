import { commonColumns } from 'core/common/listUtils';
import AccountDisplay from 'core/components/account/AccountDisplay';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import DeleteButton from 'core/components/common/DeleteButton';
import BaseTable from 'core/components/table/BaseTable';
import React, { useMemo } from 'react';
import { widgetRegistry } from '../registry';
import WidgetTypeDisplay from './common/WidgetTypeDisplay';
import WidgetFormDialogButton from './WidgetFormDialogButton';

export default function WidgetList({ onUpdated, onDelete, ...props }) {
  const availableWidgets = useMemo(() => {
    return widgetRegistry.getAll();
  }, []);

  const columns = [
    {
      ...commonColumns.allCentered,
      Header: 'ID',
      accessor: 'WidgetID',
      width: 80,
    },
    {
      Header: 'Tên',
      accessor: 'Title',
      minWidth: 150,
      maxWidth: 300,
    },
    {
      Header: 'Loại',
      Cell: ({ original }) => <WidgetTypeDisplay type={original.Type} />,
    },
    {
      Header: 'Người tạo',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <AccountDisplay id={original.CreatorID} />
          <br />
          <i><DateTimeDisplay timestamp={original.CreatedDate} /></i>
        </div>
      ),
    },
    {
      Header: 'Người sửa',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <AccountDisplay id={original.EditorID} />
          <br />
          <i><DateTimeDisplay timestamp={original.ModifiedDate} /></i>
        </div>
      ),
    },
    {
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          <WidgetFormDialogButton
            widget={original}
            onDone={onUpdated}
            availableWidgets={availableWidgets}
            isEditMode
          />
          <DeleteButton title={`Xóa widget: ${original.Title}?`} onDelete={() => onDelete(original)} />
        </ActionsCellWrapper>
      ),
      width: 150,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      {...props}
    />
  );
}
