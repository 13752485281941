import React, { memo, useMemo } from 'react';
import { buildShortContentUrl, buildPreviewUrlById } from 'core/common/urlHelper';
import moment from 'moment';
import { Sites } from 'core/engine/site/constants';

export default memo(function ContentViewLink({ content, children, siteId = Sites.ALL }) {
  const url = useMemo(() => {
    if (content.DistributionDate > moment.now()) {
      return buildPreviewUrlById('content_id', content.NoiseID, siteId);
    }

    return buildShortContentUrl(content.ContentID, siteId);
  }, [content, siteId]);

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">{children}</a>
  );
});
