import React, { forwardRef, Fragment, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { emptyArray, emptyFunction } from 'core/common/empty';
import lodash from 'core/common/lodash';
import useDialog from 'core/hooks/useDialog';
import BaseDialog from '../common/BaseDialog';
import FileManager from './FileManager';
import { Button, Dropdown, Menu } from 'antd';
import makeStyles from 'core/common/makeStyles';
import { CustomComponentContext } from '../layout';

const useStyles = makeStyles(() => ({
  buttonGroup: {
    marginLeft: '8px !important',
  },
}));

// sử dụng useContext trong forwardRef bị clear value nên cần lưu vào biến ở ngoài Component
let ExtFileManagerDialog;

export default forwardRef(function FileManagerDialog({
  title = 'Chọn tệp',
  okText = 'Chọn',
  multiple = false,
  grid = true,
  onSelect = emptyFunction,
  onCancel = emptyFunction,
  fileType,
  focusTriggerAfterClose = true,
  options = emptyArray,
  currentFileUrl,
  ...props
}, ref) {
  const parentRef = useRef();
  const customComponentContext = useContext(CustomComponentContext);

  useEffect(() => {
    if (!ExtFileManagerDialog) {
      ExtFileManagerDialog = customComponentContext && customComponentContext.ExtFileManagerDialog;
    }
  }, []);

  const classes = useStyles();
  const { open, handleOpen, handleClose } = useDialog();
  const selectedFilesRef = useRef();
  const [isSelected, setIsSelected] = useState(false);

  const handleOk = useCallback(() => {
    onSelect(selectedFilesRef.current);
    handleClose();
  }, [handleClose, onSelect]);

  const handleMenuClick = useCallback(({ key }) => {
    onSelect(selectedFilesRef.current, key);
    handleClose();
  }, [onSelect, handleClose]);

  const handleCancel = useCallback(() => {
    handleClose();
    onCancel();
  }, [handleClose, onCancel]);

  const handleSelect = useCallback((selected, isDoubleClick) => {
    selectedFilesRef.current = selected;
    setIsSelected(selected && (!multiple || selected.length));

    if (isDoubleClick) {
      handleOk();
    }
  }, [multiple, handleOk]);

  useImperativeHandle(ExtFileManagerDialog ? parentRef : ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  useEffect(() => {
    if (open) {
      setIsSelected(false);
      selectedFilesRef.current = undefined;
    }
  }, [open]);

  const [dialogWidth, setDialogWidth] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  useEffect(() => {
    if (!open) {
      return;
    }

    const handleResize = () => {
      const originalWidth = Math.floor(window.innerWidth * 0.9);
      const paddingMarginBorderAdjust = 48 + 310 + 2 + 26;
      const boxSize = 132;
      let columns = Math.floor((originalWidth - paddingMarginBorderAdjust) / boxSize);
      let rows = 5;

      columns = columns < 1 ? 1 : columns;

      while (rows * columns < 50) {
        rows += 1;
      }

      const desiredWidth = columns * boxSize + paddingMarginBorderAdjust;

      setPageSize(rows * columns);
      setDialogWidth(desiredWidth);
    };

    const handleResizeDebounced = lodash.debounce(handleResize, 100);

    handleResize();
    window.addEventListener('resize', handleResizeDebounced);

    return () => window.removeEventListener('resize', handleResizeDebounced);
  }, [open]);

  const okMenu = options.length < 2 ? null : (
    <Menu onClick={handleMenuClick}>
      {options.map((option) => (
        <Menu.Item
          disabled={!option.isValid(selectedFilesRef.current)}
          key={option.id}
          title={option.tooltip}
        >
          {option.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const footer = (
    <Fragment>
      <Button onClick={handleCancel}>
        Hủy
      </Button>
      {okMenu ? (
        <Dropdown.Button
          onClick={handleOk}
          overlay={okMenu}
          disabled={!isSelected}
          type="primary"
          className={classes.buttonGroup}
        >
          {okText}
        </Dropdown.Button>
      ) : (
        <Button onClick={handleOk} disabled={!isSelected} type="primary">
          {okText}
        </Button>
      )}
      {options.length === 1 ? (
        <Button
          onClick={() => handleMenuClick({ key: options[0].id })}
          disabled={!options[0].isValid(selectedFilesRef.current)}
          type="primary"
          title={options[0].tooltip}
        >
          {options[0].name}
        </Button>
      ) : null}
    </Fragment>
  );

  if (ExtFileManagerDialog) {
    return (
      <ExtFileManagerDialog
        title={title}
        okText={okText}
        multiple={multiple}
        grid={grid}
        onSelect={onSelect}
        onCancel={onCancel}
        fileType={fileType}
        focusTriggerAfterClose={focusTriggerAfterClose}
        options={options}
        currentFileUrl={currentFileUrl}
        ref={ref}
        {...props}
      />
    );
  }

  return (
    <BaseDialog
      visible={open}
      title={title}
      onCancel={handleCancel}
      footer={footer}
      width={dialogWidth}
      fullHeightFixed
      paddingTop={10}
      paddingBottom={10}
      focusTriggerAfterClose={focusTriggerAfterClose}
    >
      <FileManager multiple={multiple} grid={grid} onSelect={handleSelect} pageSize={pageSize} fileType={fileType} currentFileUrl={currentFileUrl} />
    </BaseDialog>
  );
});
