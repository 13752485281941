import React, { useEffect } from 'react';
import { Input, Select } from 'antd';
import makeStyles from 'core/common/makeStyles';
import useForm from 'core/hooks/useForm';

const { Group, Search } = Input;
const { Option } = Select;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex !important',
    width: 320,
  },
  select: {
    width: 100,
  },
  input: {
    width: '220px !important',
  },
}));

export default function SearchFieldWithType({
  typeOptions,
  search,
  onSearch,
  ...props
}) {
  const classes = useStyles();
  const { formData, setFormData, handleChange } = useForm();

  useEffect(() => {
    setFormData(search);
  }, [search.type, search.value]);

  function handleSearch(value) {
    onSearch({ ...formData, value });
  }

  return (
    <Group compact className={classes.container}>
      <Select className={classes.select} value={formData.type} onChange={value => handleChange('type', value)}>
        {typeOptions.map(option => (
          <Option key={option.value} value={option.value}>{option.label}</Option>
        ))}
      </Select>
      <Search
        name="value"
        placeholder="Tìm kiếm..."
        className={classes.input}
        value={formData.value}
        onChange={handleChange}
        onSearch={handleSearch}
        allowClear
        {...props}
      />
    </Group>

  );
}
