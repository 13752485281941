import { Form } from 'antd';
import { arrayHasDuplicates } from 'core/common/arrayHelper';
import { isNullOrUndefined } from 'core/common/is';
import { ContentType } from 'core/engine/content/constants';
import { PrContentTypes } from 'core/engine/pr-content/constants';
import useAuth from 'core/hooks/useAuth';
import { ContentFieldLabel } from 'core/modules/contents/constants';
import { parseDistributions } from 'core/modules/contents/services';
import React, { useMemo } from 'react';
import ContentFormMultipleZoneInput from '../input/ContentFormMultipleZoneInput';

export default function ContentFormItemDistributions({ content, form, ...props }) {
  const { getFieldDecorator } = form;
  const { hasExtendedRight } = useAuth();

  const parsedDistributions = useMemo(() => {
    return content.Distributions ? parseDistributions(content.Distributions) : [];
  }, [content.Distributions]);

  const isNewContent = isNullOrUndefined(content.ContentType) || !content.ContentID;
  const showAllZones = (hasExtendedRight('content.pr-writer') || hasExtendedRight('content.pr-deployer'))
    && (
      PrContentTypes.includes(form.getFieldValue('ContentType')) ||
      (form.getFieldValue('ContentType') === ContentType.OTHERS && isNewContent)
    );

  return (
    <Form.Item label={ContentFieldLabel.Distributions}>
      {getFieldDecorator('Distributions', {
        initialValue: parsedDistributions,
        rules: [
          { validator: distributionsValidator },
        ],
      })(
        <ContentFormMultipleZoneInput filteredByRole={!showAllZones} {...props} />
      )}
    </Form.Item>
  );
}

function distributionsValidator(rule, value, callback) {
  if (value && value.length && value[0].disabled) {
    return callback(new Error('Chuyên mục chính đã bị vô hiệu hóa, hãy chọn một chuyên mục chính khác'));
  }

  if (!value || !value.length || !value[0].ZoneID) {
    return callback(new Error('Chưa chọn chuyên mục chính!'));
  }

  if (arrayHasDuplicates(value.map(val => val.ZoneID).filter(Boolean))) {
    return callback(new Error('Chuyên mục đã chọn bị trùng lặp!'));
  }

  callback();
}
