import React, { useEffect, useMemo, useState } from 'react';
import { asyncAction } from 'core/common/async';
import Container from 'core/components/common/Container';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import GoBackButton from 'core/components/common/GoBackButton';
import { sortingToSortBy } from 'core/common/listUtils';
import interviewAnswerApi from 'core/engine/interview-answer/api';
import InterviewAnswerList from './InterviewAnswerList';
import { InterviewAnswerStatusLabel } from 'core/engine/interview-answer/constants';
import InterviewAuthz from '../InterviewAuthz';
import FlexPushRight from 'core/components/common/FlexPushRight';
import { Button } from 'antd';
import AsyncButton from 'core/components/common/AsyncButton';
import makeStyles from 'core/common/makeStyles';
import useInterviewLoader from '../../hooks/useInterviewLoader';
import { useLocation } from 'react-router-dom';
import { parseQuery } from 'core/common/urlHelper';
import Selector from 'core/components/common/Selector';
import useCreateOnFilterChange from 'core/hooks/useCreateOnFilterChange';

const useStyles = makeStyles(() => ({
  selectedText: {
    whiteSpace: 'nowrap',
  },
}));

function listFn({ limit, offset, filters, sorting }) {
  return interviewAnswerApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

export default function InterviewAnswerListView() {
  const classes = useStyles();
  const location = useLocation();
  const interviewId = useMemo(() => parseQuery(location.search).interviewId, []);
  const [selectedIds, setSelectedIds] = useState([]);

  const { interview, isLoading } = useInterviewLoader(interviewId, true);

  useEffect(() => {
    if (!isLoading) {
      listProps.addFilter('InterviewID', interviewId);
    }
  }, [isLoading, interviewId]);

  const listProps = useList({
    listFn,
    defaultSorting: {
      Date: 'DESC',
    },
  });

  const handleStatusFilter = useCreateOnFilterChange(listProps, 'Status');

  const toolbar = selectedIds.length ? (
    <TableToolbarWrapper>
      <span className={classes.selectedText}><strong>{selectedIds.length}</strong> câu trả lời đã được chọn</span>
      <FlexPushRight />
      <Button onClick={handleClearSelectedAnswers}>Hủy</Button>
      <AsyncButton type="primary" onClick={handleApprovedSelectedAnswers}>
        Duyệt những câu trả lời đã chọn
      </AsyncButton>
    </TableToolbarWrapper>
  ) : (
    <TableToolbarWrapper>
      <GoBackButton />
      <Selector
        width={200}
        emptyLabel="-- Trạng thái --"
        value={listProps.filters.Status}
        options={InterviewAnswerStatusLabel}
        onChange={handleStatusFilter}
        showDisabledOptions
        allowClear
        showSearch={false}
        valueIsNumber
      />
    </TableToolbarWrapper>
  );

  return (
    <Container pageTitle="Duyệt trả lời" loading={isLoading}>
      <InterviewAuthz interview={interview} requireAdmin>
        <div style={{ marginBottom: 10 }}>Giao lưu trực tuyến: {interview.Title}</div>
        {toolbar}
        <InterviewAnswerList
          {...useListProps(listProps)}
          selectedIds={selectedIds}
          onSelectedChange={setSelectedIds}
          heightAdjust={42}
          interview={interview}
          onUpdate={listProps.refresh}
          onDelete={handleDelete}
          isCheckable
        />
      </InterviewAuthz>
    </Container>
  );

  function handleDelete(answer) {
    return asyncAction('Xóa trả lời khỏi giao lưu trực tuyến', () => {
      interviewAnswerApi.delete(answer).then(listProps.refresh);
    });
  }

  function handleClearSelectedAnswers() {
    setSelectedIds([]);
  }

  function handleApprovedSelectedAnswers() {
    return asyncAction('Duyệt những câu trả lời đã chọn', () => {
      return interviewAnswerApi.multiApprove(selectedIds)
        .then(() => {
          setSelectedIds([]);
          listProps.refresh();
        });
    });
  }
}
