import React, { useState, useEffect, Fragment } from 'react';
import { ChangeProfileForm } from '../common/ChangeProfileForm';
import { Modal, Form, Icon } from 'antd';
import useAuth from 'core/hooks/useAuth';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import { asyncAction } from 'core/common/async';
import accountApi from 'core/engine/account/api';
import LoadingWrapper from '../common/LoadingWrapper';

function HeaderUserMenuChangeProfile({ form }) {
  const { open, handleClose, handleOpen } = useDialog();
  const { loggedInUser } = useAuth();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });
  const [account, setAccount] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      accountApi.get(loggedInUser.id)
        .then(resp => {
          setAccount(resp.data.item);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setAccount({});
        });
    }
  }, [loggedInUser, open]);

  return (
    <Fragment>
      <span onClick={handleOpen}><Icon type="user" /> Cập nhật thông tin</span>
      <Modal
        destroyOnClose
        keyboard={!form.isFieldsTouched()}
        maskClosable={!form.isFieldsTouched()}
        title={`Cập nhật thông tin tài khoản: ${loggedInUser.name}`}
        visible={open}
        onCancel={handleClose}
        onOk={handleSubmit}
        confirmLoading={isSubmitting}
        width={600}
      ><LoadingWrapper center loading={isLoading}>
          <ChangeProfileForm form={form} account={account} onSubmit={handleSubmit} />
        </LoadingWrapper>
      </Modal>
    </Fragment>
  );

  function submitFn({ formData }) {
    return asyncAction('Cập nhật thông tin người dùng', () => {
      return accountApi.updateProfile({
        ...formData,
      }).then(() => handleClose());
    });
  }
}

export default Form.create({ name: 'HeaderUserMenuChangeProfile' })(HeaderUserMenuChangeProfile);
