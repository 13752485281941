import { Button, Descriptions, message } from 'antd';
import { asyncAction } from 'core/common/async';
import commonStyles from 'core/common/commonStyles';
import { dateRangeToFilter, sortingToSortBy } from 'core/common/listUtils';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import AccountDisplay from 'core/components/account/AccountDisplay';
import AccountSelector from 'core/components/account/AccountSelector';
import AsyncButton from 'core/components/common/AsyncButton';
import Container from 'core/components/common/Container';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import FlexDiv from 'core/components/common/FlexDiv';
import FlexPushRight from 'core/components/common/FlexPushRight';
import MoneyInput from 'core/components/common/MoneyInput';
import NumberDisplay from 'core/components/common/NumberDisplay';
import OnBlurInput from 'core/components/common/OnBlurInput';
import RangeDateSelector from 'core/components/common/RangeDateSelector';
import SearchFieldWithType from 'core/components/common/SearchFieldWithType';
import Selector from 'core/components/common/Selector';
import Separator from 'core/components/common/Separator';
import ContentEditLink from 'core/components/content/ContentEditLink';
import ContentTitleDisplayForList from 'core/components/content/ContentTitleDisplayForList';
import BaseTable from 'core/components/table/BaseTable';
import TableToolbarWrapper from 'core/components/table/TableToolbarWrapper';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import { MediaQuery } from 'core/constants';
import { ContentSearchOptions, ContentTypeOptions } from 'core/engine/content/constants';
import royaltyApi from 'core/engine/royalty/api';
import { Sites } from 'core/engine/site/constants';
import siteUtils from 'core/engine/site/utils';
import { getTreeListEnabledZoneCache } from 'core/engine/zone/caching';
import useContentColumns from 'core/hooks/useContentColumns';
import useList from 'core/hooks/useList';
import useListProps from 'core/hooks/useListProps';
import useContentFilterHandlers from 'core/modules/contents/hooks/useContentFilterHandlers';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { royaltyExtendApi } from 'src/engine/royalty-extend/api';

const defaultRangeDate = [moment().add(-1, 'month'), moment()];

const { Item } = Descriptions;

const useStyles = makeStyles(() => ({
  secondToolbar: {
    alignItems: 'center',
    marginBottom: 10,
  },
  actions: {
    ...commonStyles.flexColumn,
    width: '100%',
    alignItems: 'center',

    '& > *:not(:last-child)': {
      marginBottom: 5,
    },

    [MediaQuery.xxl]: {
      ...commonStyles.flexRow,
      justifyContent: 'center',

      '& > :not(:last-child)': {
        marginRight: 5,
        marginBottom: 0,
      },
    },
  },
}));

export default function ContentRoyaltyListView({ siteId = Sites.ALL }) {
  const classes = useStyles();
  const [defaultZoneFilter, setDefaultZoneFilter] = useState();

  const listProps = useList({
    listFn,
    defaultFilters: {
      search: { type: ContentSearchOptions[0].value },
      rangeDate: defaultRangeDate,
    },
    defaultSorting: {
      DistributionDate: 'DESC',
      ContentID: 'DESC',
    },
  });

  const { stats } = listProps.extraData || {};
  const ContentColumns = useContentColumns();
  const filterHandlers = useContentFilterHandlers(listProps);
  const [mapDataSource, setMapDataSource] = useState({});

  useEffect(() => {
    const newMapDataSource = {};
    listProps.items.forEach(item => {
      newMapDataSource[item.ContentID] = {
        ...item,
        isUpdated: false,
      };
    });

    setMapDataSource(newMapDataSource);
  }, [listProps.items]);

  useEffect(() => {
    getTreeListEnabledZoneCache()
      .then((zones) => siteUtils.getSiteZonesMap(zones)[siteId] || zones)
      .then(childZones => {
        const zoneFilter = 'in:' + childZones.map(zone => zone.ZoneID).join();
        setDefaultZoneFilter(zoneFilter);
        listProps.addFilter('ZoneID', zoneFilter);
      });
  }, [siteId]);

  const columns = [
    {
      Header: 'Tiêu đề',
      Cell: ({ original }) => (
        <ContentTitleDisplayForList content={original} withAttributes={false} />
      ),
      minWidth: 200,
    },
    {
      Header: 'Đăng ngày',
      width: 80,
      Cell: ({ original }) => <DateTimeDisplay timestamp={original.DistributionDate} />,
    },
    {
      ...ContentColumns.ViewCount,
      width: 50,
    },
    {
      ...ContentColumns.ContentType,
      width: undefined,
      minWidth: 80,
      maxWidth: 150,
    },
    {
      Header: 'Tạo - Đăng',
      minWidth: 80,
      maxWidth: 150,
      headerStyle: { justifyContent: 'center' },
      style: { flexDirection: 'column' },
      Cell: ({ original }) => (
        <Fragment>
          <AccountDisplay id={original.WriterID} />
          <b><AccountDisplay id={original.DeployerID} /></b>
        </Fragment>
      ),
    },
    {
      Header: 'Tác giả',
      accessor: 'Author',
      minWidth: 80,
      maxWidth: 150,
    },
    {
      Header: 'Nhuận bút',
      width: 120,
      Cell: ({ original }) => (
        <MoneyInput
          showCurrency={false}
          onChange={value => handleMoneyChange(original.ContentID, value)}
          value={mapDataSource[original.ContentID] ? mapDataSource[original.ContentID].RoyaltyMoney : 0}
        />
      ),
    },
    {
      Header: 'Ghi chú',
      width: 220,
      Cell: ({ original }) => (
        <OnBlurInput
          onChange={value => handleNoteChange(original.ContentID, value)}
          value={mapDataSource[original.ContentID] ? mapDataSource[original.ContentID].RoyaltyNote : ''}
        />
      ),
    },
    {
      width: 60,
      Cell: ({ original }) => (
        <div className={classes.actions}>
          <ContentEditLink contentId={original.ContentID} target="_blank">
            <Button icon="edit" title="Sửa tin" />
          </ContentEditLink>
        </div>
      ),
    },
  ].filter(Boolean);

  return (
    <Container pageTitle="Nhuận bút">
      <TableToolbarWrapper>
        <ZoneSelector
          allowClear
          emptyLabel="-- Chuyên mục --"
          emptyValue={defaultZoneFilter}
          value={listProps.filters.ZoneID}
          onChange={filterHandlers.onZoneFilter}
          siteId={siteId}
        />
        <AccountSelector
          emptyLabel="-- Người tạo --"
          value={listProps.filters.WriterID}
          onChange={filterHandlers.onWriterFilter}
          allowClear
        />
        <Selector
          emptyLabel="-- Loại tin --"
          value={listProps.filters.ContentType}
          onChange={filterHandlers.onContentTypeFilter}
          options={ContentTypeOptions}
          allowClear
        />
        <RangeDateSelector
          value={listProps.filters.rangeDate}
          onChange={filterHandlers.onRangeDateFilter}
        />
        <FlexPushRight />
        <SearchFieldWithType
          typeOptions={ContentSearchOptions}
          search={listProps.filters.search}
          onSearch={filterHandlers.onSearchFilter}
        />

      </TableToolbarWrapper>

      <FlexDiv className={classes.secondToolbar}>
        <Descriptions size="small" bordered={true}>
          <Item label="Số tin"><NumberDisplay value={stats ? stats.ContentCount : 0} /></Item>
          <Item label="Tổng xem"><NumberDisplay value={stats ? stats.TotalViewCount : 0} /></Item>
          <Item label="Tổng tiền"><NumberDisplay value={stats ? stats.TotalMoney : 0} />{' VNĐ'}</Item>
        </Descriptions>
        <FlexPushRight />
        <AsyncButton icon="save" type="primary" onClick={handleSave} title="Lưu nhuận bút">Lưu nhuận bút</AsyncButton>
        <Separator />
        <AsyncButton icon="export" type="primary" onClick={handleExport} title="Xuất excel">Xuất Excel</AsyncButton>
      </FlexDiv>

      <BaseTable
        {...useListProps(listProps)}
        columns={columns}
        fullHeight={false}
      />
    </Container>
  );

  function handleSave() {
    const allRoyalties = Object.values(mapDataSource);

    const updatedContentIds = lodash.uniq(allRoyalties
      .filter(item => item.isUpdated)
      .map(item => item.ContentID));

    if (updatedContentIds.length === 0) {
      message.error('Bạn chưa thực hiện thay đổi!');
      return;
    }

    const toUpdateMap = {};

    allRoyalties.forEach((item) => {
      if (updatedContentIds.includes(item.ContentID)) {
        toUpdateMap[item.ContentID] = toUpdateMap[item.ContentID] || [];
        toUpdateMap[item.ContentID].push({
          RoyaltyID: item.RoyaltyID,
          ContentID: item.ContentID,
          Money: item.RoyaltyMoney,
          Note: item.RoyaltyNote,
        });
      }
    });

    Object.keys(toUpdateMap).forEach((key) => {
      toUpdateMap[key] = JSON.stringify(toUpdateMap[key]);
    });

    return asyncAction('Lưu nhuận bút', () => {
      return royaltyExtendApi.submit(JSON.stringify(toUpdateMap)).then(listProps.refresh);
    });
  }

  function handleExport() {
    return asyncAction('Xuất dữ liệu nhuận bút', () => {
      const filters = buildFilter(listProps.filters);
      return royaltyApi.export(filters, 'Royalty.xlsx');
    });
  }

  function handleMoneyChange(index, value) {
    const mapDataSourceItem = { ...mapDataSource[index], RoyaltyMoney: value || 0, isUpdated: true };
    const newMapDataSource = { ...mapDataSource };

    newMapDataSource[index] = mapDataSourceItem;
    setMapDataSource(newMapDataSource);
  }

  function handleNoteChange(index, value) {
    const mapDataSourceItem = { ...mapDataSource[index], RoyaltyNote: value, isUpdated: true };
    const newMapDataSource = { ...mapDataSource };

    newMapDataSource[index] = mapDataSourceItem;
    setMapDataSource(newMapDataSource);
  }
}

function listFn({ limit, offset, filters, sorting }) {
  return royaltyApi.getContentList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters: buildFilter(filters),
  });
}

function buildFilter(filters) {
  const listFilters = { ...filters };

  if (listFilters.rangeDate[0] && listFilters.rangeDate[1]) {
    listFilters.DistributionDate = dateRangeToFilter(listFilters.rangeDate);
  }

  delete listFilters.rangeDate;

  if (listFilters.search.type && listFilters.search.value) {
    listFilters[listFilters.search.type] = listFilters.search.value;
  }

  delete listFilters.search;

  return listFilters;
}
