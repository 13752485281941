import ContentAllListView from './components/all/ContentAllListView';
import ContentApprovalWaitingListView from './components/approval-waiting/ContentApprovalWaitingListView';
import ContentApprovedReturnListView from './components/approved-return/ContentApprovedReturnListView';
import ContentApprovedListView from './components/approved/ContentApprovedListView';
import ContentComposeView from './components/compose/ContentComposeView';
import ContentDeletedListView from './components/deleted/ContentDeletedListView';
import ContentDeployWaitingListView from './components/deploy-waiting/ContentDeployWaitingListView';
import ContentDeployedListView from './components/deployed/ContentDeployedListView';
import ContentDiscontinuedListView from './components/discontinued/ContentDiscontinuedListView';
import ContentLogFormView from './components/log/ContentLogFormView';
import ContentLogListView from './components/log/ContentLogListView';
import ContentLogVersionCompareView from './components/log/ContentLogVersionCompareView';
import ContentMyListView from './components/my-list/ContentMyListView';
import ContentRoyaltyListView from './components/royalty/ContentRoyaltyListView';
import ContentSubmittedReturnListView from './components/submitted-return/ContentSubmittedReturnListView';

export default [
  { path: '/contents/compose', component: ContentComposeView },
  { path: '/contents/alllist', component: ContentAllListView },
  { path: '/contents/mylist', component: ContentMyListView },
  { path: '/contents/approvalwaitinglist', component: ContentApprovalWaitingListView },
  { path: '/contents/approvedreturnlist', component: ContentApprovedReturnListView },
  { path: '/contents/approvedlist', component: ContentApprovedListView },
  { path: '/contents/deploywaitinglist', component: ContentDeployWaitingListView },
  { path: '/contents/deployedlist', component: ContentDeployedListView },
  { path: '/contents/deletedlist', component: ContentDeletedListView },
  { path: '/contents/submitedreturnlist', component: ContentSubmittedReturnListView },
  { path: '/contents/discontinuedlist', component: ContentDiscontinuedListView },
  { path: '/contents/loglist', component: ContentLogListView },
  { path: '/contents/log', component: ContentLogFormView },
  { path: '/contents/royaltylist', component: ContentRoyaltyListView },
  { path: '/contents/versioncompare', component: ContentLogVersionCompareView },
];
