export const Sites = {
  ALL: -1,
  MAIN: 0,
};

export const SubSiteZone = {
};

export const SubSiteDomain = {
};
