import { formatByte, parseByte } from '@bm/common';
import { Button, Dropdown, Menu, message, Tooltip } from 'antd';
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import commonStyles from 'core/common/commonStyles';
import { emptyFunction } from 'core/common/empty';
import { getImageDimensionsFromFile } from 'core/common/imageUtils';
import logger from 'core/common/logger';
import {
  FILE_DOWNLOAD_FROM_URL,
  FILE_MAX_UPLOAD_SIZE,
  HIGH_BITRATE_WARNING_THRESHOLD,
  PHOTO_MAX_UPLOAD_SIZE,
} from 'core/config';
import fileApi from 'core/engine/file/api';
import { FileType } from 'core/engine/file/constants';
import FileUploadFromUrlDialogButton from './FileUploadFromUrlDialogButton';
import { confirmationUpload, getVideoBitrateFromFile } from 'core/common/videoUtils';

const DropdownButton = {
  FROM_URL: 'from-url',
};

export default forwardRef(function FileManagerUploadButton({
  title = 'Tải lên',
  onUploaded = emptyFunction,
  onUploadedDone = emptyFunction,
  fileType = FileType.IMAGE,
}, ref) {
  const inputRef = useRef();
  const videoFromUrlDialogRef = useRef();
  const [uploading, setUploading] = useState(false);
  const [uploadInfo, setUploadInfo] = useState({});

  useEffect(() => {
    fileApi.getInfo().then((resp) => setUploadInfo(resp.data));
  }, []);

  const uploaders = useMemo(() => {
    return {
      [FileType.AUDIO]: {
        maxSize: parseByte(uploadInfo.maxVideoUploadSize),
        extensions: '.mp3, .wav, .wma',
        upload(file) {
          return fileApi.uploadAudio(file, file.name);
        },
      },

      [FileType.VIDEO]: {
        maxSize: parseByte(uploadInfo.maxVideoUploadSize),
        extensions: '.mp4, .mov',
        upload(file) {
          return getVideoBitrateFromFile(file).then(({ bitrate }) =>
            bitrate > HIGH_BITRATE_WARNING_THRESHOLD
              ? confirmationUpload(file, null, bitrate) : fileApi.uploadVideo(file, file.name),
          );
        },
      },

      [FileType.IMAGE]: {
        maxSize: PHOTO_MAX_UPLOAD_SIZE,
        extensions: '.jpg, .jpeg, .png, .gif, .tiff',
        upload(file) {
          return getImageDimensionsFromFile(file)
            .then((dimensions) => {
              return fileApi.uploadPhoto(file, file.name, dimensions);
            });
        },
      },

      [FileType.FILE]: {
        maxSize: FILE_MAX_UPLOAD_SIZE,
        extensions: '.doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .zip, .rar',
        upload(file) {
          return fileApi.uploadFile(file, file.name);
        },
      },
    };
  }, [uploadInfo]);

  const uploader = useMemo(() => uploaders[fileType], [uploaders, fileType]);
  const acceptType = useMemo(() => uploader.extensions, [uploader]);

  const tooltip = useMemo(() => {
    const extensions = uploader.extensions;
    const maxSize = formatByte(uploader.maxSize);

    if (extensions) {
      return `Định dạng: ${extensions} / Tối đa: ${maxSize}`;
    }
  }, [uploader]);

  const menu = useMemo(() =>
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={DropdownButton.FROM_URL}>
        Tải từ nền tảng khác
      </Menu.Item>
    </Menu>
  , []);

  const handleClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  useImperativeHandle(ref, () => ({
    upload(files) {
      handleChange({ target: { files } });
    },
  }));

  return (
    <Tooltip title={tooltip} placement="top">
      {fileType === FileType.VIDEO || fileType === FileType.IMAGE ? (
        <Fragment>
          {FILE_DOWNLOAD_FROM_URL ? (
            <Dropdown.Button type="primary" onClick={handleClick} loading={!!uploading} overlay={menu}>
              {uploading || title}
            </Dropdown.Button>
          ) : (
            <Button type="primary" onClick={handleClick} loading={!!uploading}>
              {uploading || title}
            </Button>
          )}
          <FileUploadFromUrlDialogButton
            fileType={fileType}
            wrappedComponentRef={videoFromUrlDialogRef}
            onUploaded={onUploaded}
          />
        </Fragment>
      ) : (
        <Button type="primary" icon="cloud-upload" onClick={handleClick}
          loading={!!uploading}>{uploading || title}</Button>
      )}
      <input ref={inputRef} style={commonStyles.hidden} type="file" onChange={handleChange} multiple
        accept={acceptType} />
    </Tooltip>
  );

  function handleMenuClick(e) {
    switch (e.key) {
      case DropdownButton.FROM_URL:
        videoFromUrlDialogRef.current.open();
        return;
      default:
        handleClick();
        return;
    }
  }

  function handleChange(event) {
    const target = event.target;
    const files = Array.prototype.slice.call(target.files);
    const total = files.length;
    const uploadedFiles = [];

    const oversizeFileNames = files.filter(file => file.size > uploader.maxSize).map(file => file.name);

    if (oversizeFileNames.length) {
      message.error('Tệp đã chọn vượt quá dung lượng cho phép (' + formatByte(uploader.maxSize) + '): ' + oversizeFileNames.join(', '), 5);
      return;
    }

    updateStatus();

    const uploadPromise = files.reduce((previous, current) => {
      return previous.then(() => {
        return uploader.upload(current)
          .then(resp => resp.data.item)
          .then((file) => {
            uploadedFiles.push(file);
            updateStatus();
            onUploaded(file);
          });
      });
    }, Promise.resolve());

    uploadPromise.then(() => {
      setUploading(false);
      onUploadedDone(null, uploadedFiles);
      target.value = '';
    }, (err) => {
      logger.error('Error while uploading files:', err);
      message.error(err.error_message
        ? 'Có lỗi xảy ra: ' + err.error_message
        : 'Có lỗi xảy ra khi tải tệp lên!');

      target.value = '';
      setUploading(false);
      onUploadedDone(err, uploadedFiles);
    });

    function updateStatus() {
      setUploading(`Đang tải lên ${uploadedFiles.length}/${total}`);
    }
  }
});
