import { Icon } from 'antd';
import classnames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import makeStyles from 'core/common/makeStyles';
import ImageDisplay from './ImageDisplay';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    lineHeight: 0, // to avoid unexpected padding
  },
  poster: {
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'pointer',
    height: '100%',
  },
  play: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    cursor: 'pointer',
    fontSize: 50,
    color: '#fff',
    transform: 'translateX(-50%) translateY(-50%)',
    pointerEvents: 'none',
  },
  hide: {
    display: 'none',
  },
}));

export default forwardRef(function VideoPlayer({ src, poster, className, videoProps = {} }, ref) {
  const classes = useStyles();
  const videoRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  useImperativeHandle(ref, () => ({
    getVideo() {
      return videoRef.current;
    },
  }));

  useEffect(() => {
    setIsPlaying(false);
  }, [src]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const video = videoRef.current;

    function onVideoStopped() {
      setIsPlaying(false);
    }

    function onVideoStarted() {
      setIsPlaying(true);
    }

    if (videoRef.current) {
      video.addEventListener('pause', onVideoStopped);
      video.addEventListener('ended', onVideoStopped);
      video.addEventListener('play', onVideoStarted);
    }

    return () => {
      if (video) {
        video.removeEventListener('pause', onVideoStopped);
        video.removeEventListener('ended', onVideoStopped);
        video.removeEventListener('play', onVideoStarted);
      }
    };
  }, []);

  return (
    <div className={classnames(classes.container, className)}>
      <video
        ref={videoRef}
        src={src}
        controls
        width="100%"
        preload="metadata"
        {...videoProps}
      />
      {poster && (
        <ImageDisplay
          onClick={onPosterClicked}
          className={classnames(classes.poster, isPlaying && classes.hide)}
          alt="video poster"
          src={poster}
          width="100%"
        />
      )}
      <Icon className={classnames(classes.play, isPlaying && classes.hide)} type="play-circle" />
    </div>
  );

  function onPosterClicked() {
    videoRef.current.play();
  }
});
