import { Button, Form } from 'antd';
import React, { Fragment } from 'react';
import { asyncAction } from 'core/common/async';
import BaseDialog from 'core/components/common/BaseDialog';
import contentSourceApi from 'core/engine/content-source/api';
import useDialog from 'core/hooks/useDialog';
import useFormHandler from 'core/hooks/useFormHandler';
import ContentSourceForm from './ContentSourceForm';

function ContentSourceFormDialogButton({ form, contentSource = {}, onDone, isEditMode = true }) {
  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, isSubmitting } = useFormHandler({ form, submitFn });

  return (
    <Fragment>
      <Button
        icon={isEditMode ? 'edit' : 'plus'}
        title={isEditMode ? 'Sửa nguồn tin' : 'Tạo nguồn tin'}
        onClick={handleOpen}>
        {isEditMode ? '' : 'Tạo nguồn tin'}
      </Button>
      <BaseDialog
        visible={open}
        title={isEditMode ? 'Sửa nguồn tin' : 'Tạo nguồn tin'}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Lưu"
        width={900}
        confirmLoading={isSubmitting}
        fullHeight
      >
        <ContentSourceForm form={form} contentSource={contentSource} />
      </BaseDialog>
    </Fragment>
  );

  function submitFn({ formData }) {
    if (isEditMode) {
      return asyncAction('Sửa nguồn tin', () => {
        return contentSourceApi.update(formData)
          .then(handleDone);
      });
    }

    return asyncAction('Tạo nguồn tin', () => {
      return contentSourceApi.create(formData)
        .then(handleDone);
    });
  }

  function handleDone() {
    handleClose();
    onDone();
  }
}

export default Form.create({ name: 'ContentSourceFormDialogButton' })(ContentSourceFormDialogButton);
