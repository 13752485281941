import { Form } from 'antd';
import authorApi from 'core/engine/author/api';
import useAuth from 'core/hooks/useAuth';
import React, { useEffect, useState } from 'react';
import ContentFormItemAuthorMultipleInput from './ContentFormItemAuthorMultipleInput';

export default function ContentFormItemAuthor({ content, form }) {
  const { getFieldDecorator } = form;
  const { loggedInUser } = useAuth();

  const [defaultAuthors, setDefaultAuthors] = useState([]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.pseudonym) {
      const pseudonym = loggedInUser.pseudonym.split(',').map(pseu => pseu.trim());

      authorApi.getList({
        fields: ['AuthorID', 'FullName'],
        filters: { Enabled: true, FullName: `in:${pseudonym.join()}` },
      }).then(resp => setDefaultAuthors(resp.data.items));
    }
  }, [loggedInUser]);

  return (
    <Form.Item>
      {getFieldDecorator('contentAuthors', {
        initialValue: content.ContentAuthor || [{ AuthorID: 0, isManaged: true }, { AuthorID: 0 }],
      })(
        <ContentFormItemAuthorMultipleInput defaultAuthors={defaultAuthors} />
      )}
    </Form.Item>
  );
}
