import { useLocation } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import { setSidebarOpened } from 'core/redux/actions/ui';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarOpenedSelector } from 'core/redux/selectors/ui';
import useAuth from './useAuth';

export default function useSidebar() {
  const location = useLocation();
  const opened = useSelector(sidebarOpenedSelector);
  const dispatch = useDispatch();
  const { loggedInUser } = useAuth();

  const disabled = useMemo(() => {
    return !loggedInUser || ['/forbidden', '/login', '/otp'].includes(location.pathname);
  }, [location.pathname, loggedInUser]);

  const setOpened = useCallback((opened) => {
    dispatch(setSidebarOpened(opened));
  }, [dispatch]);

  const toggle = useCallback(() => {
    setOpened(!opened);
  }, [setOpened, opened]);

  return useMemo(() => ({
    disabled,
    opened,
    setOpened,
    toggle,
  }), [disabled, opened, setOpened, toggle]);
}
