import TopicSelector from 'core/components/topic/TopicSelector';
import ZoneSelector from 'core/components/zone/ZoneSelector';
import SettingForm from 'core/modules/managements/settings/components/SettingForm';
import React from 'react';

const settings = [
  {
    key: 'StringConfig_HomeTopicIDs',
    label: 'Topic trang chủ',
    input: <TopicSelector emptyValue={[]} mode="multiple" />,
    fieldOptions: {
      normalize: (value) => typeof value === 'string'
        ? value.split(',').filter(Boolean).map(Number)
        : value,
    },
  },
  {
    key: 'StringConfig_HomeZoneIDs',
    label: 'Chuyên mục trang chủ',
    input: <ZoneSelector emptyValue={[]} mode="multiple" />,
    fieldOptions: {
      normalize: (value) => typeof value === 'string'
        ? value.split(',').filter(Boolean).map(Number)
        : value,
    },
  },
];

export default function SettingAdminForm({ form, onSubmit }) {
  return (
    <SettingForm form={form} onSubmit={onSubmit} settings={settings} />
  );
}
