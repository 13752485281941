import React, { Fragment } from 'react';
import { emptyArray } from 'core/common/empty';
import { Alert } from 'antd';
import AccountDisplay from 'core/components/account/AccountDisplay';
import makeStyles from 'core/common/makeStyles';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import HtmlDisplay from 'core/components/common/HtmlDisplay';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 10,
  },
  account: {
    fontWeight: 700,
  },
  noteItem: {
    '&:not(:last-child)': {
      paddingBottom: 5,
      marginBottom: 5,
      borderBottom: '1px solid #91d5ff8f',
    },
  },
  noteMessage: {
    marginLeft: 5,
  },
}));

export default function ContentFormNotesDisplay({ notes = emptyArray }) {
  const classes = useStyles();

  if (notes.length === 0) {
    return null;
  }

  return (
    <Alert
      className={classes.container}
      type="info"
      message="Ghi chú"
      description={
        <Fragment>
          {notes.map(note => (
            <div key={note.NoteID} className={classes.noteItem}>
              <i><DateTimeDisplay timestamp={note.CreatedDate} /></i> bởi <AccountDisplay id={note.CreatorID} className={classes.account} />:
              <HtmlDisplay tagName="span" html={note.Note} className={classes.noteMessage} />
            </div>
          ))}
        </Fragment>
      }
    />
  );
}
