import { padZero } from '@bm/common';
import lodash from './lodash';
import { isNullOrUndefined } from './is';

export function replaceArrayItem(items, replaceItem, match) {
  if (typeof match === 'string') {
    const key = match;
    match = (item1, item2) => item1[key] === item2[key];
  } else if (Array.isArray(match)) {
    match = (item1, item2) => {
      return match.every(key => item1[key] === item2[key]);
    };
  } else if (typeof match === 'object') {
    const object = match;

    match = (item1) => {
      return Object.keys(object).every(key => (item1[key] === object[key]));
    };
  } else if (typeof match !== 'function') {
    match = (item1, item2) => item1 === item2;
  }

  const index = items.findIndex(item => match(item, replaceItem));

  if (index > -1) {
    if (replaceItem) {
      items.splice(index, 1, replaceItem);
    } else {
      items.splice(index, 1);
    }
  }

  return items;
}

export function buildTreeList(items = [], { idField, parentIdField = 'ParentID', orderField = 'Order' }) {
  const treeItems = [];

  function loadSubItems(parentId, level) {
    items.filter(x => x[parentIdField] === parentId)
      .sort((item1, item2) => item1[orderField] - item2[orderField])
      .forEach((item) => {
        treeItems.push(
          {
            ...item,
            Level: level + 1,
          }
        );
        loadSubItems(item[idField], level + 1);
      });
  }

  function comparator(item1, item2) {
    return item1[orderField] - item2[orderField];
  }

  items.filter(x => x[parentIdField] === 0)
    .sort(comparator)
    .forEach((item) => {

      treeItems.push(
        {
          ...item,
          Level: 0,
        }
      );
      loadSubItems(item[idField], 0);

    });

  // append orphan items
  const itemMap = lodash.keyBy(items, idField);
  const orphanItems = items.filter((item) => {
    return item[parentIdField] && !itemMap[item[parentIdField]];
  });

  orphanItems.sort(comparator);

  treeItems.push(...orphanItems);

  return treeItems;
}

export function buildTree(items = [], {
  idField,
  parentIdField = 'ParentID',
  orderField = 'Order',
  children = 'Subs',
  rootId = 0,
  keepOrphans = true,
}) {
  const treeItems = [];

  function loadChildren(cur, parentId, level) {
    cur[children] = [];
    items.filter(x => x[parentIdField] === parentId)
      .sort((item1, item2) => item1[orderField] - item2[orderField])
      .forEach((item) => {
        const n = { ...item, Level: level + 1 };
        loadChildren(n, n[idField], n.Level);
        cur[children].push(n);
      });
  }

  function comparator(item1, item2) {
    return item1[orderField] - item2[orderField];
  }

  items
    .filter(x => x[parentIdField] === rootId)
    .sort(comparator)
    .forEach((item) => {

      const n = { ...item, Level: 0 };
      loadChildren(n, n[idField], n.Level);
      treeItems.push(n);
    });

  if (keepOrphans) {
    const itemMap = lodash.keyBy(items, idField);
    const orphanItems = items.filter((item) => {
      return item[parentIdField] !== rootId && isNullOrUndefined(itemMap[item[parentIdField]]);
    }).map(item => ({ ...item, [children]: [] }));

    treeItems.push(...orphanItems);
  }

  return treeItems;
}

export function keysToValues(object = {}, transform = lodash.identity) {
  const result = {};

  if (transform === 'lowercase') {
    transform = lodash.lowerCase;
  } else if (transform === 'uppercase') {
    transform = lodash.upperCase;
  } else if (transform === 'capitalize') {
    transform = lodash.capitalize;
  } else if (transform === 'cap2space') {
    transform = key => key.split(/(?=[A-Z])/g).join(' ');
  } else if (transform === 'constant') {
    transform = (name) => name.split('_').map(chunk => lodash.capitalize(chunk)).join(' ');
  } else if (typeof transform !== 'function') {
    transform = lodash.identity;
  }

  Object.keys(object).forEach((key) => {
    result[object[key]] = transform(key);
  });

  return result;
}

export function converToSelections(object, transform = lodash.identity) {
  const result = [];

  Object.keys(object).forEach((key) => {
    result.push({ value: transform(key), label: object[key] });
  });

  return result;
}

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
}

export function durationStringToSeconds(duration = '') {
  if (!duration) {
    return 0;
  }

  const fields = duration.split(':');

  while (fields.length < 3) {
    fields.unshift('00');
  }

  const [hours, minutes, seconds] = fields.map(str => parseInt(str) || 0);

  return hours * 3600 + minutes * 60 + seconds;
}

export function secondsToDurationString(seconds = 0, trimZeroHour = true) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsStr = Math.round(seconds % 60);

  const fields = (trimZeroHour && hours === 0) ? [minutes, secondsStr] : [hours, minutes, secondsStr];

  return fields.map(padZero).join(':');
}

export function dataToCsvContent(data) {
  if (!data || !data.length) {
    return 'No data :(';
  }

  const columns = Object.keys(data[0]);//.sort();

  let text = columns.join('\t');

  data.forEach((row) => {
    text += '\n' + columns.map(column => row[column]).join('\t');
  });

  return text;
}
