import { Checkbox } from 'antd';
import { arrayShallowEqual } from 'core/common/arrayHelper';
import { emptyArray, emptyFunction } from 'core/common/empty';
import lodash from 'core/common/lodash';
import makeStyles from 'core/common/makeStyles';
import FlexDiv from 'core/components/common/FlexDiv';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import TopicSelectButton from 'core/components/topic/select-button/TopicSelectButton';
import TopicViewLink from 'core/components/topic/TopicViewLink';
import topicApi from 'core/engine/topic/api';
import useSyncRef from 'core/hooks/useSyncRef';
import React, { forwardRef, Fragment, useEffect, useState } from 'react';

const ID_FIELD = 'TopicID';

const useStyles = makeStyles(() => ({
  list: {
    paddingLeft: 20,

    '& > li': {
      lineHeight: 'initial',
    },

    '& > li:not(:last-child)': {
      marginBottom: 5,
    },
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default forwardRef(function AdsPositionFormTopicsInput({
  value = emptyArray,
  onChange = emptyFunction,
}, ref) { // eslint-disable-line no-unused-vars

  const classes = useStyles();
  const [displayItems, setDisplayItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const displayItemsRef = useSyncRef(displayItems);
  const [checkedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    if (value.length === 1 && value[0] === -1) {
      setCheckedAll(true);
      return;
    }

    if (value.length > 0) {
      const ids = value;

      if (arrayShallowEqual(ids.slice().sort(), displayItemsRef.current.map(item => item[ID_FIELD]).sort())) {
        return;
      }

      setIsLoading(true);
      topicApi.getList({
        limit: ids.length,
        filters: {
          [ID_FIELD]: `in:${ids.join()}`,
        },
      })
        .then(resp => {
          // sort items to correct order because server result is not following original order
          setDisplayItems(lodash.sortBy(resp.data.items, item => ids.indexOf(item[ID_FIELD])));
          setIsLoading(false);
        });
    } else {
      setDisplayItems([]);
    }
  }, [value]);

  return (
    <Fragment>
      <FlexDiv>
        <Checkbox checked={checkedAll} onChange={handleCheckAll}>Tất cả chủ đề</Checkbox>
        {!checkedAll && <TopicSelectButton value={displayItems} onChange={handleSelectChange} />}
      </FlexDiv>

      {!checkedAll && (
        <LoadingWrapper loading={isLoading} center>
          {displayItems.length > 0 ? (
            <ul className={classes.list}>
              {displayItems.map(item => (
                <li key={item[ID_FIELD]}>
                  <TopicViewLink id={item[ID_FIELD]} title={item.Name}>{item.Name}</TopicViewLink>
                </li>
              ))}
            </ul>
          ) : null}
        </LoadingWrapper>
      )}
    </Fragment>
  );

  function handleSelectChange(value) {
    setDisplayItems(value);
    onChange(value.map(c => c[ID_FIELD]));
  }

  function handleCheckAll(event) {
    const { checked } = event.target;

    setCheckedAll(checked);

    if (checked) {
      onChange([-1]);
    } else {
      onChange(displayItems.map(c => c[ID_FIELD]));
    }
  }
});
