import React from 'react';
import { ContentHookAction } from 'core/modules/contents/constants';
import actionRegistry from 'core/registries/actions';
import routesRegistry from 'core/registries/routes';
import ContentFormItemRoyalty from './components/content/ContentFormItemRoyalty';
import routes from './routes';

export {
  start,
};

function start() {
  routesRegistry.registerMultiple(routes);

  actionRegistry.addAction(ContentHookAction.FORM_INPUTS, formInputsAction);
}

function formInputsAction({ content, form, inputMap }) {
  inputMap.Royalty = (
    <ContentFormItemRoyalty content={content} form={form} />
  );
}
