export function sum(arr = []) {
  return arr.reduce((sum, value) => (sum + value), 0);
}

export function addArrayItem(arr = [], item, position = 0) {
  arr.splice(position, 0, item);
  return arr;
}

export function arrayShallowEqual(array1 = [], array2 = []) {
  if (array1 === array2) {
    return true;
  }

  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every((value, index) => value === array2[index]);
}

export function arrayHasDuplicates(arr = []) {
  if (arr.length < 2) {
    return false;
  }

  return new Set(arr).size !== arr.length;
}

export function arrayRemoveItemByValue(arr = [], item) {
  const index = arr.indexOf(item);

  if (index !== -1) {
    arr.splice(index, 1);
  }
}
