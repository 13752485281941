import { Button } from 'antd';
import BaseDialog from 'core/components/common/BaseDialog';
import { ObjectType } from 'core/engine/common/constants';
import useDialog from 'core/hooks/useDialog';
import React from 'react';
import ContentEmbedList from 'core/components/content/ContentEmbedList';

export default function LiveEmbeddedContentListButtonForList({ liveId }) {
  const { open, handleOpen, handleClose } = useDialog();

  return (
    <React.Fragment>
      <Button title="Danh sách tin được nhúng tường thuật" icon="database" onClick={handleOpen} />
      <BaseDialog
        visible={open}
        title="Danh sách tin được nhúng tường thuật"
        onCancel={handleClose}
        width={900}
        fullHeightFixed
        keyboard
        maskClosable
        footer={[<Button onClick={handleClose}>Đóng</Button>]}
      >
        <ContentEmbedList objectId={liveId} objectType={ObjectType.LIVE} />
      </BaseDialog>
    </React.Fragment>
  );
}
