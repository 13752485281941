import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import DateTimeDisplay from 'core/components/common/DateTimeDisplay';
import ContentEditButtonForList from 'core/components/content/ContentEditButtonForList';
import ContentFilterableAccount from 'core/components/content/ContentFilterableAccount';
import ContentGetBackButtonForList from 'core/components/content/ContentGetBackButtonForList';
import ContentLogButtonForList from 'core/components/content/ContentLogButtonForList';
import ContentPreviewButtonForList from 'core/components/content/ContentPreviewButtonForList';
import CheckableTable from 'core/components/table/CheckableTable';
import { ContentStatus } from 'core/engine/content/constants';
import useAuth from 'core/hooks/useAuth';
import useContentColumns from 'core/hooks/useContentColumns';
import React from 'react';

export default function ContentApprovedList({
  onWriterSelected,
  ...props
}) {
  const { loggedInUser } = useAuth();
  const ContentColumns = useContentColumns();

  const columns = [
    ContentColumns.ContentID,
    ContentColumns.Avatar,
    ContentColumns.Title,
    {
      Header: 'Người tạo',
      width: 120,
      Cell: ({ original }) => (
        <div>
          <ContentFilterableAccount
            id={original.WriterID}
            onClick={() => onWriterSelected(original.WriterID)}
            title="Lọc tin tạo bởi tài khoản này"
          />
          <br />
          <i><DateTimeDisplay timestamp={original.CreatedDate} /></i>
        </div>
      ),
    },
    ContentColumns.Editor,
    ContentColumns.Status,
    ContentColumns.CheckIn,
    {
      ...ContentColumns.ViewCount,
      show: true,
    },
    {
      width: 150,
      Cell: ({ original }) => (
        <ActionsCellWrapper>
          {original.Status === ContentStatus.APPROVED && (
            <ContentGetBackButtonForList
              contentId={original.ContentID}
              disabled={original.EditorID !== loggedInUser.id}
            />
          )}
          <ContentEditButtonForList content={original} />
          <ContentLogButtonForList contentId={original.ContentID} />
          <ContentPreviewButtonForList noiseId={original.NoiseID} />
        </ActionsCellWrapper>
      ),
    },
  ];

  return (
    <CheckableTable
      selectOnLeft
      idField="ContentID"
      columns={columns}
      {...props}
    />
  );
}
