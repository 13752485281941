import React, { useState, useEffect } from 'react';
import { getAccountNameCache } from 'core/engine/account/caching';

export default function AccountDisplay({ id, ...props }) {
  const [name, setName] = useState('Loading...');

  useEffect(() => {
    getAccountNameCache(id).then((resp) => setName(resp));
  }, [id]);

  return (
    <span {...props}>{name}</span>
  );
}
