import { httpGet } from 'core/common/http-client';

const topicLogApi = {
  getList({ limit = 20, offset = 0, sortBy, filters }) {
    const qs = { limit, offset, sortBy, ...filters };
    return httpGet('/topic-logs/get/items-by-range', { qs });
  },
};

export default topicLogApi;
