import React, { forwardRef, useEffect, useState } from 'react';
import Selector from 'core/components/common/Selector';
import { getListGroupCache } from 'core/engine/group/caching';

export default forwardRef(function GroupAccountSelector({
  value,
  onChange,
  ...props
}, ref) {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getListGroupCache().then(items => {
      setGroups(items.map(item => ({
        value: item.GroupID,
        label: item.Alias ? item.Alias : item.Name,
      })));
    });
  }, []);

  return (
    <Selector
      ref={ref}
      value={value}
      options={groups}
      onChange={onChange}
      valueIsNumber
      showDisabledOptions
      {...props}
    />
  );
});
