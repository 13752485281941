import { httpClient, httpGet, httpPost } from 'core/common/http-client';
import { getFileNameFromUrl, getImageDimensions, imageUrlToBlob } from 'core/common/imageUtils';

const fileApi = {
  getList({ limit = 20, offset = 0, sortBy, filters } = {}) {
    const qs = {
      limit,
      offset,
      sortBy,
      ...filters,
    };

    return httpGet('/files/get/items-by-range', { qs });
  },

  getInfo() {
    return httpGet('/files/get/upload-info');
  },

  uploadPhoto(file, name, data = {}) {
    const formData = new FormData();

    formData.append('file', file, name);

    return httpClient('/files/post/upload-photo', {
      method: 'POST',
      body: formData,
      qs: data,
    });
  },

  uploadFile(file, name, data = {}) {
    const formData = new FormData();

    formData.append('file', file, name);

    return httpClient('/files/post/upload-file', {
      method: 'POST',
      body: formData,
      qs: data,
    });
  },

  async uploadPhotoFromUrl(url, name = getFileNameFromUrl(url), data) {
    if (!data) {
      data = await getImageDimensions(url);
    }

    const blob = await imageUrlToBlob(url);

    return fileApi.uploadPhoto(blob, name, data);
  },

  uploadVideo(file, name, data = {}) {
    const formData = new FormData();

    formData.append('file', file, name);

    return httpClient('/files/post/upload-video', {
      method: 'POST',
      body: formData,
      qs: data,
    });
  },

  uploadAudio(file, name, data = {}) {
    const formData = new FormData();

    formData.append('file', file, name);

    return httpClient('/files/post/upload-audio', {
      method: 'POST',
      body: formData,
      qs: data,
    });
  },

  uploadVideoFromUrl(url) {
    return httpPost('/files/post/upload-video-url', { url });
  },

  uploadAudioFromUrl(url) {
    return httpPost('/files/post/upload-audio-url', { url });
  },

  suggestVideoPosters(video_url) {
    return httpPost('https://thumb.baomoi.com/gen_thumbnails', { video_url }, { credentials: 'omit' });
  },

  update(data) {
    return httpPost('/files/post/update', data);
  },

  addPhotosWatermark(data) {
    return httpPost('/files/post/add-photos-watermark', data);
  },
};

export default fileApi;
