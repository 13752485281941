import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import makeStyles from 'core/common/makeStyles';
import commonStyles from 'core/common/commonStyles';
import useList from 'core/hooks/useList';
import albumApi from 'core/engine/album/api';
import { sortingToSortBy } from 'core/common/listUtils';
import ImageDisplay from '../common/ImageDisplay';
import classnames from 'core/common/classnames';
import { emptyFunction } from 'core/common/empty';
import { Icon, Pagination, Dropdown, Menu, Button, Spin, Empty } from 'antd';
import { blue } from '@ant-design/colors';
import { FileType } from 'core/engine/file/constants';
import DeleteButton from '../common/DeleteButton';
import Separator from '../common/Separator';
import FileManagerAlbumDialogButton from './FileManagerAlbumDialogButton';
import { asyncAction } from 'core/common/async';

function listFn({ limit, offset, filters, sorting }) {
  return albumApi.getList({
    limit,
    offset,
    sortBy: sortingToSortBy(sorting),
    filters,
  });
}

const useStyles = makeStyles(() => ({
  container: {
    ...commonStyles.flexColumn,
    padding: 20,
    paddingBottom: 0,
    width: '100%',
    border: '1px solid #d4d4d4',
    borderRadius: 4,
  },
  emptyContainer: {
    ...commonStyles.flexAllCentered,
    border: '1px solid #d4d4d4',
    borderRadius: 4,
    width: '100%',
    height: '100%',
  },

  grid: {
    ...commonStyles.flexRow,
    width: '100%',
    height: 'calc(100% - 42px)',
    overflow: 'auto',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  pagination: {
    alignSelf: 'flex-end',
    paddingBottom: 10,
    marginRight: 10,
  },

  item: {
    margin: 10,
    width: 150,
    cursor: 'pointer',
  },

  itemInner: {
    width: 150,
    height: 150,
    backgroundColor: '#eeeeee',
    position: 'relative',

    '&:hover .action-icon': {
      display: 'block',
    },
  },

  itemAvatar: {
    width: '100%',
    height: '100%',
  },

  itemNoAvatar: {
    width: '100%',
    height: '100%',
    ...commonStyles.flexAllCentered,

    '& > svg': {
      width: 60,
      height: 60,
    },
  },

  selected: {
    backgroundColor: blue[4],

    '& img, & .cover-icon': {
      width: 'calc(100% - 20px)',
      height: 'calc(100% - 20px)',
      transform: 'translateX(10px) translateY(10px)',
    },
  },

  itemName: {
    ...commonStyles.ellipsis,
    display: 'block',
    fontWeight: 700,
  },

  itemAction: {
    position: 'absolute',
    top: 10,
    right: 10,
    display: 'none',
  },
}));

export default forwardRef(function FileManagerAlbumGrid({
  selectedId,
  type = FileType.IMAGE,
  onAlbumSelect = emptyFunction,
}, ref) {
  const classes = useStyles();
  const listProps = useList({
    listFn,
    defaultPageSize: 50,
    defaultFilters: {
      ParentID: 0,
    },
  });

  useImperativeHandle(ref, () => ({
    refresh: listProps.refresh,
  }));

  useEffect(() => {
    listProps.addFilter('Type', type);
  }, [type]);

  if (listProps.isFetching) {
    return (
      <div className={classes.emptyContainer}><Spin /></div>
    );
  }

  if (listProps.items.length === 0) {
    return (
      <div className={classes.emptyContainer}><Empty /></div>
    );
  }

  return (
    <div ref={ref} className={classes.container}>
      <div className={classes.grid}>
        {listProps.items.map(item => (
          <div
            key={item.AlbumID}
            className={classes.item}
            onClick={() => onAlbumSelect(item)}
          >
            <div className={classnames(classes.itemInner, item.AlbumID === selectedId && classes.selected)}>
              {item.AvatarUrl ? (
                <ImageDisplay
                  className={classnames(classes.itemAvatar)}
                  src={item.AvatarUrl}
                  alt={item.AlbumID}
                  width={150}
                />
              ) : (
                <Icon type="book" className={classnames('cover-icon', classes.itemNoAvatar)} />
              )}
              <Dropdown
                className={classnames('action-icon', classes.itemAction)}
                overlay={(
                  <Menu onClick={handleMenuClick}>
                    <Menu.Item key="edit">
                      <FileManagerAlbumDialogButton
                        album={item}
                        anchorElement={({ onClick }) => (
                          <div onClick={onClick}>
                            <Icon type="edit" /><Separator />Sửa album
                          </div>
                        )}
                        onDone={listProps.refresh}
                      />
                    </Menu.Item>
                    <Menu.Item key="delete">
                      <DeleteButton
                        anchorElement={({ onClick}) => (
                          <div onClick={onClick}>
                            <Icon type="delete" /><Separator />Xóa album
                          </div>
                        )}
                        title={`Xóa album: ${item.Name}?`}
                        onDelete={() => handleDelete(item)}
                      />
                    </Menu.Item>
                  </Menu>
                )}
              >
                <Button icon="ellipsis" onClick={event => event.stopPropagation()}/>
              </Dropdown>
            </div>
            <span className={classes.itemName} title={item.Name}>{item.Name}</span>
          </div>
        ))}
      </div>

      <Pagination
        className={classes.pagination}
        showQuickJumper
        total={listProps.total}
        pageSize={listProps.pageSize}
        showTotal={(total, range) => `${range[0]}-${range[1]} trong số ${total}`}
        current={listProps.pageIndex + 1}
        onChange={page => listProps.onPageChange(page - 1)}
      />
    </div>
  );

  function handleMenuClick(event) {
    event.domEvent.stopPropagation();
  }

  function handleDelete(album) {
    return asyncAction('Xóa album', () => {
      return albumApi.delete(album.AlbumID)
        .then(() => listProps.removeItem(album, 'AlbumID'));
    });
  }
});
