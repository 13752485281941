import { Button } from 'antd';
import { formatDateTime } from 'core/common/datetimeHelper';
import { parseJson } from 'core/common/jsonUtils';
import BaseDialog from 'core/components/common/BaseDialog';
import useDialog from 'core/hooks/useDialog';
import React, { Fragment, useMemo } from 'react';

export default function ActivityLogDetailDialogButton({ body, browser }) {
  const { open, handleOpen, handleClose } = useDialog();

  const bodyJson = useMemo(() => parseJson(body, {}), [body]);

  return (
    <Fragment>
      <Button icon="bars" title="Chi tiết" onClick={handleOpen} />
      <BaseDialog
        visible={open}
        title="Chi tiết hành động"
        onCancel={handleClose}
        width={800}
        footer={[
          <Button key="ok" type="primary" onClick={handleClose}>OK</Button>,
        ]}
        fullHeightFixed={true}
      >
        {Object.keys(bodyJson).map(key => (
          <Fragment key={key}>
            <b>{`${key}: `}</b><span>{nomalizeBody(key, bodyJson[key])}</span><br />
          </Fragment>
        ))}
        <b>Trình duyệt: </b><span>{browser}</span><br />
      </BaseDialog>
    </Fragment>
  );

  function nomalizeBody(key, body) {
    if (key.toLowerCase().includes('date')) {
      return `${body} (${formatDateTime(body, 'DD/MM/YYYY HH:mm')})`;
    }

    if (typeof body === 'string') {
      return body;
    }

    return JSON.stringify(body);
  }
}
