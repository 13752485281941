import React from 'react';
import useContentColumns from 'core/hooks/useContentColumns';
import SelectableTable from 'core/components/table/SelectableTable';
import ActionsCellWrapper from 'core/components/common/ActionsCellWrapper';
import AsyncButton from 'core/components/common/AsyncButton';
import contentAuthorApi from 'core/engine/content-author/api';
import { asyncAction } from 'core/common/async';
import lodash from 'core/common/lodash';

const getTitle = isHome => isHome ? 'Gỡ Nổi bật Trang chủ' : 'Nổi bật Trang chủ';

export default function AuthorSelectedContentList({ authorId, onUpdated, ...props }) {
  const ContentColumns = useContentColumns();

  const columns = [
    ContentColumns.Avatar,
    ContentColumns.Title,
    ContentColumns.DistributionDate,
    {
      width: 50,
      Cell: ({ original }) => {
        const authors = original.ContentAuthor || [];
        const author = lodash.find(authors, { AuthorID: authorId }) || {};

        return (
          <ActionsCellWrapper>
            <AsyncButton
              onClick={() => handlePushHome(author)}
              title={getTitle(original.IsHome)}
              icon="home"
              style={{ color: author.IsHome ? 'red' : 'green' }}
            />
          </ActionsCellWrapper>
        );
      },
    },
  ];

  return (
    <SelectableTable
      idField="ContentID"
      columns={columns}
      {...props}
    />
  );

  function handlePushHome(author) {
    return asyncAction(getTitle(author.IsHome), () => {
      return contentAuthorApi.update({
        ContentAuthorID: author.ContentAuthorID,
        ContentID: author.ContentID,
        AuthorID: authorId,
        IsHome: !author.IsHome,
      }).then(onUpdated);
    });
  }
}

