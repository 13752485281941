import _ from 'lodash';
import tagApi from './api';

export const getTagNameCache = _.memoize((id) => getTagName(id));

function getTagName(id) {
  return tagApi.get(id)
    .then(resp => resp.data)
    .then((resp) => resp.item ? resp.item.Name : '#');
}
