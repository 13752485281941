import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { buildTreeList } from 'core/common/utils';
import LoadingWrapper from 'core/components/common/LoadingWrapper';
import PermissionTable from 'core/components/common/PermissionTable';
import commandApi from 'core/engine/command/api';

const rights = [
  { id: 'AllowCreate', label: 'Tạo mới' },
  { id: 'AllowChange', label: 'Cập nhật' },
  { id: 'AllowDelete', label: 'Xóa' },
];

export default forwardRef(function CommandPermissionTable({ permissions }, ref) {
  const [commandTreeList, setCommandTreeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const permissionMap = useMemo(() => arrayToPermissionMap(permissions), [permissions]);
  const dataRef = useRef();

  const onChange = useCallback((data) => {
    dataRef.current = data;
  }, []);

  useImperativeHandle(ref, () => ({
    getPermissions() {
      return dataRef.current && permissionMapToArray(dataRef.current);
    },
  }), []);

  useEffect(() => {
    setIsLoading(true);

    commandApi.getListAll()
      .then(resp => resp.data.items)
      .then((commands) => {
        setCommandTreeList(buildTreeList(commands, { idField: 'CommandID' }));
        setIsLoading(false);
      });
  }, []);

  return (
    <LoadingWrapper center loading={isLoading}>
      <PermissionTable
        commandIdField="CommandID"
        commands={commandTreeList}
        rights={rights}
        checkAllRightsLabel="Truy cập"
        defaultCheckedMap={permissionMap}
        onChange={onChange}
      />
    </LoadingWrapper>
  );
});

function arrayToPermissionMap(permissions) {
  const map = {};

  permissions.forEach((permission) => {
    map[permission.CommandID] = true;
    rights.forEach((right) => {
      if (permission[right.id]) {
        map[`${permission.CommandID}.${right.id}`] = true;
      }
    });
  });

  return map;
}

function permissionMapToArray(permissionMap) {
  const map = {};

  Object.keys(permissionMap).forEach((key) => {
    if (!permissionMap[key]) {
      return;
    }

    const [commandId, rightId] = key.split('.');

    if (!map[commandId]) {
      map[commandId] = { CommandID: parseInt(commandId), AllowCreate: false, AllowChange: false, AllowDelete: false };
    }

    if (rightId) {
      map[commandId][rightId] = true;
    }
  });

  return Object.values(map);
}
