import {
  SET_ITEMS,
  SELECT_ITEM,
  SET_FILTER,
} from 'core/redux/actions/menu';

const initialState = {
  loaded: false,
  items: [],
  selectedItemId: null,
  filter: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ITEMS:
      return {
        ...state,
        loaded: true,
        items: action.items,
      };

    case SELECT_ITEM:
      return {
        ...state,
        selectedItemId: action.id,
      };

    case SET_FILTER:
      return {
        ...state,
        filter: action.filter,
      };

    default:
      return state;
  }
}
