import { Button } from 'antd';
import React from 'react';
import useGoBack from 'core/hooks/useGoBack';

export default function GoBackButton({ text = 'Quay lại', title = 'Quay lại', ...props }) {
  const goBack = useGoBack();

  return (
    <Button icon="arrow-left" onClick={goBack} title={title} {...props}>{text}</Button>
  );
}
