import { useMemo } from 'react';
import useAuth from 'core/hooks/useAuth';

export default function AuthzCheck({ children, requireAdmin = false, extendedRight = null }) {
  const { loggedInUser, hasExtendedRight } = useAuth();

  const isAuthorized = useMemo(() => {
    if (!loggedInUser) {
      return false;
    }

    if (loggedInUser.isAdmin) {
      return true;
    }

    if (requireAdmin && !loggedInUser.isAdmin) {
      return false;
    }

    return !extendedRight || hasExtendedRight(extendedRight);
  }, [loggedInUser, hasExtendedRight, requireAdmin, extendedRight]);

  if (typeof children === 'function') {
    return children(isAuthorized);
  }

  if (isAuthorized) {
    return children;
  }

  return null;
}
