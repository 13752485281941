import TemplatePlugin from './TemplatePlugin';
import ContextMenuPlugin from './ContextMenuPlugin';
import LinkContentPlugin from './LinkContentPlugin';
import VotePlugin from './VotePlugin';
import AddPhotoPlugin from './AddPhotoPlugin';
import AddAudioPlugin from './AddAudioPlugin';
import AddVideoPlugin from './AddVideoPlugin';
import AddFilePlugin from './AddFilePlugin';
import EmbedPlugin from './EmbedPlugin';
import CustomPastePlugin from './CustomPastePlugin';
import CustomUnlinkPlugin from './CustomUnlinkPlugin';
import './InlinePopupPlugin';
import formatBlock from './FormatBlockPlugin';
import ReformatPlugin from './ReformatPlugin';
import BeforeAfterImagePlugin from './BeforeAfterImagePlugin';
import FacebookVideoPlugin from './FacebookVideoPlugin';
import SpellCheckerPlugin from './SpellCheckerPlugin';
import InterviewPlugin from './InterviewPlugin';
import LivePlugin from './LivePlugin';
import NewLinePlugin from './NewLinePlugin';
import BinPlugin from './BinPlugin';
import DeleteContentPlugin from './DeleteContentPlugin';
import SearchPlugin from './SearchPlugin';

export default {
  formatBlock,
  TemplatePlugin,
  ContextMenuPlugin,
  LinkContentPlugin,
  VotePlugin,
  AddPhotoPlugin,
  AddAudioPlugin,
  AddVideoPlugin,
  AddFilePlugin,
  EmbedPlugin,
  CustomPastePlugin,
  CustomUnlinkPlugin,
  ReformatPlugin,
  BeforeAfterImagePlugin,
  FacebookVideoPlugin,
  SpellCheckerPlugin,
  InterviewPlugin,
  LivePlugin,
  NewLinePlugin,
  BinPlugin,
  DeleteContentPlugin,
  SearchPlugin,
};
